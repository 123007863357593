(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateAssignDialogController', EvaluationTemplateAssignDialogController);

    EvaluationTemplateAssignDialogController.$inject = ['$timeout', 'typePatientProcess', 'chart', '$uibModalInstance', 'EvaluationTemplate', 'Evaluation',
        'toastr', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$filter', '$compile', '$scope', 'DataTablesService'];

    function EvaluationTemplateAssignDialogController($timeout, typePatientProcess, chart, $uibModalInstance, EvaluationTemplate, Evaluation,
                                                      toastr, DTOptionsBuilder, DTColumnBuilder, $q, $filter, $compile, $scope, DataTablesService) {
        var vm = this;
        vm.chart = chart;

        vm.typePatientProcess = typePatientProcess;
        vm.urgentIssues = [];
        vm.urgentIssue = {};
        vm.dtInstance = {};
        vm.reload = false;

        vm.selected = {};
        vm.selectAll = false;

        vm.clear = clear;
        vm.assign = assign;

        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            if (vm.chart.typeLevelCare != null) {
                var defer = $q.defer();
                if (!vm.searchQuery || vm.searchQuery == '') {
                    EvaluationTemplate.findAllByPatientProcess({
                        id: chart.id,
                        process: typePatientProcess.id
                    }, function (result) {
                        vm.evaluationTemplate = result;
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve($filter('filter')(vm.evaluationTemplate, vm.searchQuery, undefined));
                }

                return defer.promise;
            }
        }).withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('aaSorting', [[1, 'asc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Template Name'),
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function assign() {
            var assign = {
                chart: chart,
                ids: vm.getSelectedIds(vm.selected)
            }

            if (assign.ids.length > 0) {
                vm.isSaving = true;

                save(assign);
            }
        }

        function save(assign) {
            Evaluation.assign(assign, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                $uibModalInstance.close(true);
                toastr.success("Care & Delivery Add to the Chart");
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        }
    }
})();

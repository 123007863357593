/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CaseLoadBoxController', CaseLoadBoxController);

    CaseLoadBoxController.$inject = ['facility', '$scope', '$state', 'CoreService', 'Chart'];

    function CaseLoadBoxController(facility, $scope, $state, CoreService, Chart) {
        var vm = this;

        vm.countCaseLoad = null;
        vm.count = 0;

        vm.openCharts = openCharts;
        loadData();

        function loadData() {
            vm.countCaseLoad = Chart.countCaseLoad({id: CoreService.getCurrentFacility().id}, function (result) {
                _.forEach(result, function (el) {
                    vm.count += el.count;
                });
            });
        }
        function openCharts(employee){
            $state.go('charts-caseload', {employee: employee.id});
        }
    }
})();

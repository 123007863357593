(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentTypeController', IncidentTypeController);

    IncidentTypeController.$inject = ['GenericEntityDatatableService', 'CoreService', 'IncidentType', '$q', '$uibModal',
        'ConfirmationService'];

    function IncidentTypeController(GenericEntityDatatableService, CoreService, IncidentType, $q, $uibModal,
                                    ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Incident Type',
            newButtonTitle: 'New Incident Type',
            entityClassHumanized: 'Incident Types',
            entityStateName: 'incident-type',
            service: IncidentType,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Incident Type',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/incident-type/update/incident-type-dialog.html',
                controller: 'IncidentTypeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/incident-type/update/incident-type-dialog.html',
                controller: 'IncidentTypeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['IncidentType', function (IncidentType) {
                        return IncidentType.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Incident Type').result.then(function () {
                    IncidentType.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportNoteTemplateCloneController',IncidentReportNoteTemplateCloneController);

    IncidentReportNoteTemplateCloneController.$inject = ['$uibModalInstance', 'entity', 'IncidentReportNoteTemplate'];

    function IncidentReportNoteTemplateCloneController($uibModalInstance, entity, IncidentReportNoteTemplate) {
        var vm = this;

        vm.incidentReportTemplate = entity;
        vm.clear = clear;
        vm.confirmClone = confirmClone;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmClone (id) {
            IncidentReportNoteTemplate.clone({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationAllergyDialogController', EvaluationAllergyDialogController);

    EvaluationAllergyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'EvaluationAllergy', 'CoreService', 'GUIUtils', 'Allergen', 'Reaction',
        'Severity'];

    function EvaluationAllergyDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                               EvaluationAllergy, CoreService, GUIUtils, Allergen, Reaction,
                                               Severity) {
        var vm = this;

        vm.evaluationAllergy = entity;
        vm.allergies = vm.evaluationAllergy.allergy;

        vm.chart = entity.evaluation.chart;
        vm.onset = GUIUtils.getOnset();
        vm.severities = Severity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.allergen = Allergen.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.reaction = Reaction.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.form = {};

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.reactionDate = false;

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;

        vm.currentDate = {
            maxDate: new Date()
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.evaluationAllergy.id !== null) {
                EvaluationAllergy.update(vm.evaluationAllergy, onSaveSuccess, onSaveError);
            } else {
                EvaluationAllergy.save(vm.evaluationAllergy, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateCloneController',EvaluationTemplateCloneController);

    EvaluationTemplateCloneController.$inject = ['$uibModalInstance', 'entity', 'EvaluationTemplate'];

    function EvaluationTemplateCloneController($uibModalInstance, entity, EvaluationTemplate) {
        var vm = this;

        vm.evaluationTemplate = entity;
        vm.clear = clear;
        vm.confirmClone = confirmClone;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmClone (id) {
            EvaluationTemplate.clone({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientResultCriticalChartDialogController', PatientResultCriticalChartDialogController);

    PatientResultCriticalChartDialogController.$inject = ['$uibModalInstance', 'result', 'PatientResult', 'moment', 'PatientResultDet'];

    function PatientResultCriticalChartDialogController($uibModalInstance, result, PatientResult, moment, PatientResultDet) {
        var vm = this;

        vm.criticalResult = result;
        vm.testResult = result.result + ' (' + result.status + ')';
        vm.acknowledgeBy = result.name + ' ' + result.lastName;

        vm.clear = clear;
        vm.getResults = getResults;
        vm.acknowledge = acknowledge;

        getResults();

        function getResults() {
            PatientResultDet.get6M({detailId: result.id}, function (results) {
                _.forEach(results, function (result) {
                    vm.labels.push(moment(result.date).format('MM/DD/YYYY'));
                    vm.data[0].push(result.result);
                    var style = getPointStyle(result);
                    vm.colors[0].pointBackgroundColor.push(style.pointBackgroundColor);
                    vm.colors[0].pointRadius.push(style.pointRadius)
                });
            });
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function acknowledge() {
            PatientResultDet.acknowledge({ids: [result.id]}, function () {
                $uibModalInstance.close(true);
            }, function () {
            })
        };

        vm.labels = [];
        vm.series = ['Results'];
        vm.data = [
            []
        ];
        vm.datasetOverride = [{yAxisID: 'y-axis-1'}, {yAxisID: 'y-axis-2'}];
        vm.options = {
            scales: {
                yAxes: [
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        display: true,
                        position: 'left'
                    }
                ]
            }
        };
        vm.colors = [{
            fillColor: "rgba(151,187,205,0.2)",
            strokeColor: "rgba(151,187,205,1)",
            pointColor: "rgba(151,187,205,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(151,187,205,1)",
            pointBackgroundColor: [],
            pointRadius: []
        }];

        function getPointStyle(result) {
            switch (result.status) {
                case 'LL':
                case 'HH':
                    return {pointBackgroundColor: 'red', pointRadius: 12};
                case 'L':
                case 'H':
                    return {pointBackgroundColor: 'blue', pointRadius: 10};
                default :
                    return {pointBackgroundColor: 'green', pointRadius: 6};
            }
        }
    }
})();

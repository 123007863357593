(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationWeightController', EvaluationWeightController);

    EvaluationWeightController.$inject = ['$scope', '$state', 'EvaluationWeight', 'entity', '$uibModal', 'CoreService'];

    function EvaluationWeightController($scope, $state, EvaluationWeight, entity, $uibModal, CoreService) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationWeight = null;

        vm.loadAll = loadAll;
        vm.add = add;
        vm.history = history;
        vm.getHeightInchesToFeetInches = getHeightInchesToFeetInches;

        loadAll();

        function loadAll() {
            if (!vm.evaluation.weight) {
                return;
            }

            EvaluationWeight.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                if (result.length > 0) {
                    vm.evaluationWeight = result[0];
                    vm.evaluationWeight.bmi = _.ceil(vm.evaluationWeight.bmi, 2)
                }
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/weight/update/weight-dialog.html',
                controller: 'EvaluationWeightDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            evaluation: {id: vm.evaluation.id},
                            weight: {
                                name: null,
                                id: null,
                                chart: vm.evaluation.chart
                            }
                        };
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/weight/history/weight.html',
                controller: 'WeightController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.evaluation.chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function getHeightInchesToFeetInches(height) {

            return CoreService.getHeightInchesToFeetInches(height);
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationVital', EvaluationVital);

    EvaluationVital.$inject = ['$resource'];

    function EvaluationVital($resource) {
        var resourceUrl = 'api/evaluation-vitals/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByEvaluation': {method: 'GET', url: 'api/evaluation-vitals/by-evaluation/:id', isArray: true}
        });
    }
})();

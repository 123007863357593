(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabCompendiumCommentController', LabCompendiumCommentController);

    LabCompendiumCommentController.$inject = ['$uibModalInstance', 'entity', 'LabCompendium', 'CoreService', 'Facility',
        'LabCompendiumComment', '$scope'];

    function LabCompendiumCommentController($uibModalInstance, entity, LabCompendium, CoreService, Facility,
                                            LabCompendiumComment, $scope) {
        var vm = this;

        vm.labCompendiumComment = entity;

        Facility.getAll(function (facilities) {
            vm.facilities = facilities;
        });

        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.labCompendiumComment.id !== null) {
                LabCompendiumComment.update(vm.labCompendiumComment, onSaveSuccess, onSaveError);
            } else {
                LabCompendiumComment.save(vm.labCompendiumComment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:labCompendiumCommentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

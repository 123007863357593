(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmInsuranceController', CrmInsuranceController);

    CrmInsuranceController.$inject = ['$scope', '$state', '$uibModal', 'CrmInsurance', 'entity', 'AlertService'];

    function CrmInsuranceController ($scope, $state, $uibModal, CrmInsurance, entity, AlertService) {
        var vm = this;

        vm.crmOpportunity = entity;
        vm.page = 1;
        vm.itemsPerPage = 6;
        vm.crmPrimaryInsurance = null;
        vm.crmSecondaryInsurance = null;

        vm.add = add;
        vm.edit = edit;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll () {
            CrmInsurance.findOneByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                insuranceOrder: 'PRIMARY'
            }, function (data) {
                vm.crmPrimaryInsurance = data;
            }, onError);

            CrmInsurance.findOneByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                insuranceOrder: 'SECONDARY'
            }, function (data) {
                vm.crmSecondaryInsurance = data;
            }, onError);

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function add(order) {

            $uibModal.open({
                templateUrl: 'app/entities/crm-insurance/crm-insurance-dialog.html',
                controller: 'CrmInsuranceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            policyNumber: null,
                            groupNumber: null,
                            preCertPhone: null,
                            address: null,
                            address2: null,
                            zipCode: null,
                            city: null,
                            name: null,
                            dob: null,
                            phone: null,
                            socialSecurity: null,
                            insuranceOrder: order,
                            countryState: null,
                            id: null,
                            crmOpportunity: vm.crmOpportunity,
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-insurance/crm-insurance-dialog.html',
                controller: 'CrmInsuranceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CrmInsurance', function (CrmInsurance) {
                        return CrmInsurance.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }
    }
})();

/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PhysicianReviewLabOrdersController', PhysicianReviewLabOrdersController);

    PhysicianReviewLabOrdersController.$inject = ['moment', '$q', 'PatientOrder', 'CoreService', 'PopupService',
        'toastr', '$rootScope', 'Chart', 'physicianCredentials',
        'GenericEntityDatatableService'];

    function PhysicianReviewLabOrdersController(moment, $q, PatientOrder, CoreService, PopupService,
                                                toastr, $rootScope, Chart, physicianCredentials,
                                                GenericEntityDatatableService) {
        var vm = this;

        vm.facility = CoreService.getCurrentFacility();

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Lab Orders',
            entityClassHumanized: 'Lab Orders',
            service: PatientOrder,
            serviceMethod: 'findAllPhysicianReview',
            params: params,
            pagination: 'remote',
            newAction: false,
            withSelectionColumn: true,
            isVisibleSelectionColumn: function () {
                return CoreService.currentEmployeeHaveAnyCredential(physicianCredentials);
            },
            options: [
                {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            ],
            selectionColumn: 'id',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'patientName',
                    title: 'Name',
                    options: [{key: 'width', value: '170px'}],
                    render: function (data) {
                        return data.lastName + ', ' + data.firstName;
                    }
                },
                {
                    name: 'mrNo',
                    title: 'MrNo'
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'orderStatus',
                    title: 'Status',
                },
                {
                    name: 'startDate',
                    title: 'Start Date',
                    render: function (data) {
                        return moment(data.startDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'endDate',
                    title: 'End Date',
                    render: function (data) {
                        return moment(data.endDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Entered By',
                    render: function (data) {
                        return data.lastNameEmployee + " " + data.firstNameEmployee;
                    }
                },
                {
                    name: null,
                    title: 'Signed By',
                    render: function (data) {
                        return data.firstNameSignedBy + " " + data.lastNameSignedBy;
                    }
                },
                {
                    name: null,
                    title: 'Discontinue By',
                    render: function (data) {
                        return data.firstNameDiscontinueBy ? (data.firstNameDiscontinueBy + " " + data.lastNameDiscontinueBy) : "";
                    }
                },
                {
                    name: null,
                    title: 'Discontinue Date',
                    render: function (data) {
                        return data.discontinueDate ? moment(data.discontinueDate).format("MM/DD/YYYY") : "";
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        vm.showPatientOrderDetailsPopup(data.id);
                    },
                }),
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getSignAction(), {
                            name: 'Sign Selected',
                            disabledOnEmpty: true,
                            ngClass: function () {
                                return ['bg-green']
                            },
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.signPatientOrder(selected);
                                }
                            },
                            ngIf: function () {
                                return CoreService.currentEmployeeHaveAnyCredential(physicianCredentials);
                            },
                        }),
                    ]
                },
            ],
            filters: [
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true,
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
                },
                {
                    name: 'mrNo',
                    // title: 'Barcode',
                    placeholder: 'MrNo...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'dob',
                    // title: 'Day of Birth',
                    placeholder: 'Day of Birth...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                }
            ]
        }

        vm.showPatientOrderDetailsPopup = showPatientOrderDetailsPopup;
        vm.signPatientOrder = signPatientOrder;

        function showPatientOrderDetailsPopup(id) {
            PopupService.showPatientOrderDetailsPopup(id);
        }

        function signPatientOrder(selected) {
            var ids = _.map(selected, function (item) {
                return item.id;
            });

            return $q(function (resolve, reject) {
                PopupService.showSignaturePopup().result.then(function (data) {
                    PatientOrder.sign({
                        signature: data.employeeSignature,
                        pin: data.employeeSignaturePin,
                        ids: ids,
                        facilityId: vm.facility.id
                    }, function (result) {
                        if (result) {
                            toastr.success("The Patient Result was signed successfully.");
                        } else {
                            toastr.error('Some patient results could not be signed.');
                        }

                        resolve();
                    }, function () {
                        toastr.error('Some patient results could not be signed.');

                        resolve();
                    });
                });
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionHistoryAuditController', GroupSessionHistoryAuditController);

    GroupSessionHistoryAuditController.$inject = ['GroupSessionDetails', '$uibModalInstance', '$timeout', 'groupSession'];

    function GroupSessionHistoryAuditController(GroupSessionDetails, $uibModalInstance, $timeout, groupSession) {
        var vm = this;
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.groupSessionsDetailsHistory = [];
        vm.groupSession = groupSession;

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        loadAll();

        function loadAll() {
            GroupSessionDetails.getHistory({
                id: groupSession.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.groupSessionsDetailsHistory = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }
    }
})();

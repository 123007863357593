(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillDialogController', SuperBillDialogController);

    SuperBillDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SuperBill', 'Evaluation', 'Employee', 'InsuranceCarrier', 'InsuranceType', 'InsuranceLevel', 'InsuranceRelation', 'CountryState', 'Chart'];

    function SuperBillDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SuperBill, Evaluation, Employee, InsuranceCarrier, InsuranceType, InsuranceLevel, InsuranceRelation, CountryState, Chart) {
        var vm = this;

        vm.superbill = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.evaluations = Evaluation.query();
        vm.employees = Employee.query();
        vm.insurancecarriers = InsuranceCarrier.query();
        vm.insurancetypes = InsuranceType.query();
        vm.insurancelevels = InsuranceLevel.query();
        vm.insurancerelations = InsuranceRelation.query();
        vm.countrystates = CountryState.query();
        vm.charts = Chart.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.superbill.id !== null) {
                SuperBill.update(vm.superbill, onSaveSuccess, onSaveError);
            } else {
                SuperBill.save(vm.superbill, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:superBillUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateOfService = false;
        vm.datePickerOpenStatus.billDate = false;
        vm.datePickerOpenStatus.insuranceDob = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('global-message', {
                parent: 'entity',
                url: '/global-message',
                data: {
                    authorities: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM],
                    pageTitle: 'GlobalMessages'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/global-message/list/global-messages.html',
                        controller: 'GlobalMessageController',
                        controllerAs: 'vm'
                    }
                },
            })
    }
})();

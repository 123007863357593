(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window'];

    function DataUtils($window) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            toBase64ToFileObject: toBase64ToFileObject,
            downloadFile: downloadFile
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function toBase64ToFileObject(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb({
                    file: base64Data,
                    fileContentType: file.type,
                    name: file.name,
                    size: bytesToSize(file.size)
                });
            };
        }

        function downloadFile(data, headers) {
            if (data && headers) {
                var contentType = headers('Content-Type');
                var contentDisp = headers('Content-Disposition');
                if (contentDisp) {
                    var index = (contentDisp) ? contentDisp.indexOf('filename="') : -1;

                    var filename = "filename";

                    if (index != -1) {
                        var i = index + 10;
                        while (contentDisp[i] != '"') {
                            i++;
                        }

                        filename = contentDisp.substring(index + 10, i);
                    }

                    var file = new Blob([data], {type: contentType});
                    var fileURL = URL.createObjectURL(file);

                    switch (contentType) {
                        case "image/png":
                        case "image/jpeg":
                        case "application/pdf": {
                            var myWindow = $window.open(fileURL);
                            setTimeout(function () {
                                myWindow.document.title = filename;
                            }, 2000);

                            myWindow.onload = function () {
                                setTimeout(function () {
                                    $(myWindow.document).find('title').replaceWith('<title>' + filename + '</title>');
                                }, 500);
                            };

                            break;
                        }
                        case "application/csv" : {
                            var fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.download = filename;
                            fileLink.click();

                            break;
                        }
                        default: {
                            var fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.download = filename;
                            fileLink.click();
                        }
                    }
                }
            }
        }

        function bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed(3) + ' ' + sizes[i];
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmNote', CrmNote);

    CrmNote.$inject = ['$resource'];

    function CrmNote ($resource) {
        var resourceUrl =  'api/crm-notes/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByFilter': {url: 'api/crm-notes/filter', method: 'GET', isArray: true},
            'pin': {url: 'api/crm-notes/pin', method: 'POST'}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AuthItemController', AuthItemController);

    AuthItemController.$inject = ['AuthItem', 'entityType', 'ACTIONS', 'CoreService', 'AUTH_ITEM_TYPE',
        'ROLES', 'GenericEntityDatatableService'];

    function AuthItemController(AuthItem, entityType, ACTIONS, CoreService, AUTH_ITEM_TYPE,
                                ROLES, GenericEntityDatatableService) {
        var vm = this;

        vm.employee = CoreService.getCurrentEmployee();
        vm.isSystemAdmin = _.filter(vm.employee.authorities, {name: ROLES.ROLE_SYSTEM_ADMIN}).length > 0;

        var params = {
            type: entityType.type,
            id: CoreService.getCorporation().id,
            exclude: entityType.type === AUTH_ITEM_TYPE.TYPE_ROLE && vm.isSystemAdmin ? null : [ROLES.ROLE_USER, ROLES.ROLE_SYSTEM],
        };

        vm.descriptor = {
            title: entityType.entityClassHumanized,
            newButtonTitle: 'New ' + entityType.entityClassHumanized,
            entityClassHumanized: entityType.entityClassHumanized,
            entityStateName: entityType.entityStateName,
            service: AuthItem,
            serviceMethod: 'queryByType',
            params: params,
            newAction: entityType.type === AUTH_ITEM_TYPE.TYPE_ROLE,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'description',
                    title: 'Description',
                },
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction(entityType.entityStateName),
                GenericEntityDatatableService.getDeleteAction(entityType.entityStateName)
            ],
        }
    }
})();

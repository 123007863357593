(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('GroupSession', GroupSession);

    GroupSession.$inject = ['$resource', 'DateUtils', 'OdsFormService'];

    function GroupSession($resource, DateUtils, OdsFormService) {
        var resourceUrl = 'api/group-sessions/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.stop = DateUtils.convertDateTimeFromServer(data.stop);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        // data.leaderSignature = data.leaderSignature?data.leaderSignature:{signature: ''};
                        // data.reviewerSignature = data.reviewerSignature?data.reviewerSignature:{signature: ''};
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.stop = DateUtils.convertDateTimeFromServer(data.stop);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        // data.leaderSignature = data.leaderSignature?data.leaderSignature:{signature: ''};
                        // data.reviewerSignature = data.reviewerSignature?data.reviewerSignature:{signature: ''};
                    }
                    return data;
                }
            },
            'sign': {url: 'api/group-sessions/sign', method: 'PUT'},
            'getSignature': {
                method: 'GET', url: 'api/group-sessions/get-signature/:id/:type', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }

                    return data;
                }
            },
            'leaderSign': {
                method: 'POST', url: 'api/group-sessions/leader-sign',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }

                    return data;
                }
            },
            'reviewerSign': {
                method: 'POST', url: 'api/group-sessions/reviewer-sign',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }

                    return data;
                }
            },
            'getJsonTemplate': {
                url: 'api/group-sessions/get-json-template/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        // data = angular.fromJson(data);
                        data = OdsFormService.convertFormSchemaFromServer(data);
                        // data = angular.fromJson(data);
                    }

                    return data;
                },
            },
            'masterSignature': {
                url: 'api/group-sessions/master-signature',
                method: 'POST',
                ignoreLoadingBar: true
            },
        })
    }
})();

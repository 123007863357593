(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('telehealth', {
                parent: 'entity',
                url: '/telehealth',
                data: {
                    authorities: [],
                    pageTitle: 'Telehealth'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/telehealth/list/telehealth-meeting.html',
                        controller: 'TelehealthMeetingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: function (CoreService) {
                        return {
                            facilityId: CoreService.getCurrentFacility().id,
                            status: 'SCHEDULED',
                            topic: null,
                            twilioRoomName: null,
                            hostId: null,
                            startDate: null,
                            endDate: null,
                            duration: null
                        };
                    },
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TELEHEALTH];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TELEHEALTH, access);
                }]
            })
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ChartPicture', ChartPicture);

    ChartPicture.$inject = ['$resource'];

    function ChartPicture ($resource) {
        var resourceUrl =  'api/chart-pictures/:id';

        return $resource(resourceUrl, {}, {
            'create': {url: 'api/chart-pictures/create', method: 'POST'},
            'getOriginalBase64ByChart': {url: 'api/chart-pictures/get-original-base-64/by-chart/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        var result = {image: data};
                    }

                    return result;
                }},
            'getCroppedImageByChart': {url: 'api/chart-pictures/get-cropped-image/by-chart/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        var result = {image: data};
                    }

                    return result;
                }}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionFormTplCloneController',GroupSessionFormTplCloneController);

    GroupSessionFormTplCloneController.$inject = ['$uibModalInstance', 'entity', 'GroupSessionFormTpl'];

    function GroupSessionFormTplCloneController($uibModalInstance, entity, GroupSessionFormTpl) {
        var vm = this;

        vm.evaluationTemplate = entity;
        vm.clear = clear;
        vm.confirmClone = confirmClone;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmClone (id) {
            GroupSessionFormTpl.clone({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

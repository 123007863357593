(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ActivitiesNewTravelDialogController', ActivitiesNewTravelDialogController);

    ActivitiesNewTravelDialogController.$inject = ['$uibModalInstance'];

    function ActivitiesNewTravelDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateDeparting = false;
        vm.datePickerOpenStatus.dateArriving = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BusinessCredentialDialogController', BusinessCredentialDialogController);

    BusinessCredentialDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'BusinessCredential', 'Employee', 'CoreService'];

    function BusinessCredentialDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                                 BusinessCredential, Employee, CoreService) {
        var vm = this;

        vm.businessCredential = entity;
        vm.credentials = Employee.credentials();
        vm.roles = [];

        BusinessCredential.getAvailableBusinessRoles({id: CoreService.getCurrentFacility().id}, function (result) {
            vm.roles = result;

            if (vm.businessCredential.id) {
                vm.roles.push(vm.businessCredential.businessRole);
            }
        });

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.businessCredential.id !== null) {
                BusinessCredential.update(vm.businessCredential, onSaveSuccess, onSaveError);
            } else {
                BusinessCredential.save(vm.businessCredential, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:businessCredentialUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

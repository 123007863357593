(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Facility', Facility);

    Facility.$inject = ['$resource', 'DateUtils'];

    function Facility ($resource, DateUtils) {
        var resourceUrl =  'api/facilities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.annualReview = DateUtils.convertDateTimeFromServer(data.annualReview);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.annualReview = DateUtils.convertLocalDateToServer(copy.annualReview);
                    return angular.toJson(copy);
                }
            },
            'byCorporation': {url: 'api/facilities/corporation/:id', method: 'GET', isArray: true},
            'getAvailableZoneIds': {url: 'api/facilities/get-available-zone-ids', method: 'GET', isArray: true},
            'getAll': {url: 'api/facilities', method: 'GET', isArray: true},
            'getAllPlaceOfServices': {url: 'api/facilities/place-of-service', method: 'GET', isArray: true}
        });
    }
})();

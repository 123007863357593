(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PayerMixBoxController', PayerMixBoxController);

    PayerMixBoxController.$inject = ['$state', 'Chart', 'CoreService'];

    function PayerMixBoxController($state, Chart, CoreService) {
        var vm = this;

        vm.payers = null;
        vm.count = 0;

        vm.openCharts = openCharts;
        vm.payerAdjust = payerAdjust;

        loadData();

        function loadData() {
            vm.payers = Chart.getAllPayers({id: CoreService.getCurrentFacility().id}, function (result) {
                _.forEach(result, function (el) {
                    vm.count += el.count;
                    if (el.insuranceCarrier == null && el.typePayment != null) {
                        el.name = _.upperCase(el.typePayment.name);
                    } else if (el.insuranceCarrier != null && el.typePayment == null) {
                        el.name = el.insuranceCarrier.name;
                    } else {
                        el.name = "UNKNOWN";
                    }
                })
            });
        }

        function openCharts(item) {
            if (item.insuranceCarrier != null && item.typePayment == null) {
                $state.go('charts-payer-mix-ins', {id: item.insuranceCarrier.id});
            } else if (item.insuranceCarrier == null && item.typePayment != null) {
                $state.go('charts-payer-mix-no', {id: item.typePayment.id});
            } else {
                $state.go('charts-payer-mix-no-ins', {id: null});
            }
        }

        function payerAdjust(data) {
            return data && data.length > 0 ? (data && data.length > 35 ? data.substr(0, 35) + " ..." : data) : "-";
        }
    }
})();

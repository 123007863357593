(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationComment', EvaluationComment);

    EvaluationComment.$inject = ['$resource'];

    function EvaluationComment($resource) {
        var resourceUrl = 'api/evaluation-comments/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }

                    return data;
                }
            },
            'update': {method: 'PUT'},
            'addComment': {url: 'api/evaluation-comments/add-comment', method: 'POST', isArray: false},
            'findAllByEvaluation': {
                url: 'api/evaluation-comments/by-evaluation/:id', method: 'GET', isArray: true
            }
        });
    }
})();

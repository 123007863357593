(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReconnectCareCredentialController', ReconnectCareCredentialController);

    ReconnectCareCredentialController.$inject = ['GenericEntityDatatableService', 'ReconnectCareCredential',
        'CoreService', '$q', '$uibModal', 'ConfirmationService'];

    function ReconnectCareCredentialController(GenericEntityDatatableService, ReconnectCareCredential,
                                               CoreService, $q, $uibModal, ConfirmationService) {
        var vm = this;

        vm.totalItems = 0;
        var params = {
            id: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Reconnect Care Credential',
            entityClassHumanized: 'Reconnect Care Credential',
            entityStateName: 'reconnect-care-credential',
            service: ReconnectCareCredential,
            serviceMethod: 'getAllByFacility',
            newAction: false,
            params: params,
            columns: [
                {
                    name: 'username',
                    title: 'Username'
                },
                {
                    name: 'password',
                    title: 'Password'
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Reconnect Care Credential',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0
                            }
                        }),
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/reconnect-care-credential/update/reconnect-care-credential-dialog.html',
                    controller: 'ReconnectCareCredentialDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                id: null,
                                clientId: null,
                                clientSecret: null,
                                username: null,
                                password: null,
                                facility: CoreService.getCurrentFacility()
                            };
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/reconnect-care-credential/update/reconnect-care-credential-dialog.html',
                    controller: 'ReconnectCareCredentialDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['ReconnectCareCredential', function (ReconnectCareCredential) {
                            return ReconnectCareCredential.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('ReconnectCare Credential').result.then(function () {
                    ReconnectCareCredential.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationVitalDialogController', EvaluationVitalDialogController);

    EvaluationVitalDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'EvaluationVital', 'CoreService'];

    function EvaluationVitalDialogController ($timeout, $scope, $stateParams, $uibModalInstance,
                                              entity, EvaluationVital, CoreService) {
        var vm = this;

        vm.evaluationVital = entity;
        vm.vitals = angular.copy(vm.evaluationVital.vital);
        vm.facility = CoreService.getCurrentFacility();

        vm.datePickerOpenStatus = {};
        vm.form = {};

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            var date = moment.tz(moment(vm.vitals.date).format('YYYY-MM-DDTHH:mm:00'), vm.facility.timeZone).format();
            var vitals = angular.copy(vm.vitals);
            vitals.date = date;

            vm.evaluationVital.vital = vitals;

            if (vm.evaluationVital.id !== null) {
                EvaluationVital.update(vm.evaluationVital, onSaveSuccess, onSaveError);
            } else {
                EvaluationVital.save(vm.evaluationVital, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.time = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

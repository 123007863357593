(function () {
    'use strict';

    angular.module('bluebookApp')
        .directive('udtCropImage', udtCropImage);

    udtCropImage.$inject = ['CoreService'];

    function udtCropImage(CoreService) {
        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-crop-image/udt-crop-image.html',
            scope: {//all this scope value defined, are attr for the directive. There use are explained below
                areaType: '@',//form for the image component. Can be square or circle
                resultImageSize: '@', //size of the crop image
                image: '=',//modal field for the original image
                croppedImage: '=', //cropped image
                defaultImage: '@'//avatar
            },
            link: linkFunc
        };
        return directive;

        function linkFunc($scope, element) {
            $scope.resultImageSize = $scope.resultImageSize ? Number($scope.resultImageSize) : 300;
            $scope.areaType = $scope.areaType ? $scope.areaType : 'square';
            $scope.defaultImageModel = $scope.defaultImage ? $scope.defaultImage : CoreService.patientPictureDefault().picture;
            $scope.image =  $scope.image ?  $scope.image : null;

            $scope.handleFileSelect = function (evt, file) {
                $scope.file = file;
                var reader = new FileReader();
                reader.onload = function (evt) {
                    $scope.$apply(function ($scope) {
                        $scope.image = evt.target.result;
                    });
                };
                reader.readAsDataURL(file);
            };
        }
    }
})();


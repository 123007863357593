(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CareCoordinationController', CareCoordinationController);

    CareCoordinationController.$inject = ['$scope', '$state', '$filter', 'CareCoordination', 'chart', 'CoreService',
        'AlertService', 'DateUtils', '$sessionStorage', 'Employee', '$uibModal', 'access', 'ACTIONS',
        'Auth', 'ConfirmationService'];

    function CareCoordinationController($scope, $state, $filter, CareCoordination, chart, CoreService,
                                        AlertService, DateUtils, $sessionStorage, Employee, $uibModal, access, ACTIONS,
                                        Auth, ConfirmationService) {
        var vm = this;

        vm.chart = chart;
        vm.careCoordinations = [];
        vm.page = 1;
        vm.itemsPerPage = 7;

        vm.saveExcecuted = saveExcecuted;
        vm.transition = transition;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.plusDays = plusDays;
        vm.toggleItem = toggleItem;

        function toggleItem() {
            CoreService.togglePanel('careCoordination');
        }

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW, access)) {
                return;
            }

            CareCoordination.findAllWithEagerRelationshipsByChart({
                chartId: chart.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.careCoordinations = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function saveExcecuted(careCoordination) {
            vm.isSaving = true;
            if (careCoordination.id !== null) {
                CareCoordination.get({id: careCoordination.id}, function (result) {
                    if (result) {
                        result.excecuted = careCoordination.excecuted;
                        CareCoordination.update(result, function () {
                            transition();
                            vm.isSaving = false;
                        }, function () {
                            vm.isSaving = false;
                        });
                    }
                });
            }
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/care-coordination/update/care-coordination-dialog.html',
                controller: 'CareCoordinationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            employeeOne: null,
                            dateAndTime: null,
                            id: null,
                            active: true,
                            chart: chart
                        };
                    }
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/care-coordination/update/care-coordination-dialog.html',
                controller: 'CareCoordinationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CareCoordination', function (CareCoordination) {
                        return CareCoordination.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('CareCoordination').result.then(function () {
                CareCoordination.delete({id: id}, function () {
                    vm.page = 1;
                    loadAll();
                });
            })
        }

        function plusDays(careCoordination) {
            var result = new Date(careCoordination.createdDate);
            result.setDate(result.getDate() + careCoordination.days);

            return result;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillDialogController', SuperBillDialogController);

    SuperBillDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'SuperBill', 'chart'];

    function SuperBillDialogController ($timeout, $scope, $stateParams, $uibModalInstance,
                                         SuperBill, chart) {
        var vm = this;

        vm.filter = {
            start: new Date(),
            end: new Date(),
            chartId: chart.id
        };
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.endOptions = {};
        vm.startOptions = {};

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            SuperBill.createInstitutional(vm.filter, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:superBillUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

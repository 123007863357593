(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BlockedTimeReasonDeleteController',BlockedTimeReasonDeleteController);

    BlockedTimeReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'BlockedTimeReason'];

    function BlockedTimeReasonDeleteController($uibModalInstance, entity, BlockedTimeReason) {
        var vm = this;

        vm.blockedTimeReason = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BlockedTimeReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

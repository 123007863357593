(function (){
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxHistoryDialogController', VerifytxHistoryDialogController);

    VerifytxHistoryDialogController.$inject = ['$uibModalInstance', '$compile', '$q', '$scope', '$rootScope', 'Verifytx', 'verifytxVobId',
                                                'DTOptionsBuilder','DTColumnBuilder', 'GUIUtils'];

    function VerifytxHistoryDialogController($uibModalInstance, $compile, $q, $scope, $rootScope, Verifytx, verifytxVobId,
                                                 DTOptionsBuilder, DTColumnBuilder, GUIUtils) {
        var vm = this;

        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.dtInstance = {};

        vm.clear = clear;
        vm.transition = transition;
        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            Verifytx.filter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.verifytxHistories = result;
                defer.resolve(result);
            })

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false).withOption('aaSorting', [[3, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('coverage').withTitle('Coverage').notSortable(),
            DTColumnBuilder.newColumn('insuranceCompany').withTitle('Insurance Company').notSortable(),
            DTColumnBuilder.newColumn('reference').withTitle('Reference').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Request Date').notSortable().renderWith(function (data, type, full) {
                return moment(data.createdDate).format($rootScope.amDateFormat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Failed').notSortable().renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.failed);
            }),
            DTColumnBuilder.newColumn('failedMessage').withTitle('Message').notSortable()
        ];

        function clear() {
            $uibModalInstance.close();
        }

        function transition() {
            vm.dtInstance.reloadData();
        }

        function search() {
            vm.page = 1;

            transition();
        }

        function getFilter() {
            var filter = {};

            filter.verifytxVobId = verifytxVobId;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('incident-report-note-template', {
                parent: 'entity',
                url: '/incident-report-note-template',
                data: {
                    authorities: [ROLES.ROLE_USER],
                    pageTitle: 'IncidentReportNoteTemplates'
                },
                ncyBreadcrumb: {
                    label: 'Incident Report Note Templates',
                    parent: 'entity'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report-note-template/list/incident-report-note-template.html',
                        controller: 'IncidentReportNoteTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('incident-report-note-template.new', {
                parent: 'incident-report-note-template',
                url: '/new',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report-note-template/update/incident-report-note-template-edit.html',
                        controller: 'IncidentReportNoteTemplateEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'New Incident Report Note Template',
                    parent: 'incident-report-note-template'
                },
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            name: null,
                            enabled: true,
                            jsonTemplate: null,
                            id: null,
                            incidentTypes: []
                        };
                    }]
                }
            })
            .state('incident-report-note-template.edit', {
                parent: 'incident-report-note-template',
                url: '/{id}/edit',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                ncyBreadcrumb: {
                    label: 'Edit Incident Report Note Template',
                    parent: 'incident-report-note-template'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report-note-template/update/incident-report-note-template-edit.html',
                        controller: 'IncidentReportNoteTemplateEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['IncidentReportNoteTemplate', '$stateParams', function (IncidentReportNoteTemplate, $stateParams) {
                        return IncidentReportNoteTemplate.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();

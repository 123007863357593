(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DiagnosesDialogController', DiagnosesDialogController);

    DiagnosesDialogController.$inject = ['$timeout', '$scope', 'BUSINESS_ROLES', 'BusinessCredential', '$uibModalInstance',
        'CoreService', 'entity', 'Diagnoses', 'toastr', 'Acuity', 'Icd10Search'];

    function DiagnosesDialogController($timeout, $scope, BUSINESS_ROLES, BusinessCredential, $uibModalInstance,
                                             CoreService, entity, Diagnoses, toastr, Acuity, Icd10Search) {

        var vm = this;

        vm.diagnoses = entity;
        vm.chart = entity.chart;
        vm.acuity = Acuity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.icd10 = [];
        vm.icd10Tmp = [];

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};
        vm.showDelete = false;

        vm.save = save;
        vm.clear = clear;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.getIcd10 = getIcd10;
        vm.confirmDelete = confirmDelete;

        init();

        function init() {
            if (vm.diagnoses.id) {
                vm.icd10Tmp = [vm.diagnoses.icd10];
            }
        }

        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.diagnoses.endDate;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.diagnoses.startDate;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.close('dismiss');
        }

        function save() {
            vm.isSaving = true;

            if (vm.diagnoses.id) {
                Diagnoses.update({diagnoses: vm.diagnoses}, onSaveSuccess, onSaveError);
            } else {
                vm.diagnoses.chart = vm.chart;

                Diagnoses.create({diagnoses: vm.diagnoses, icd10S: vm.icd10Tmp}, onSaveSuccess, onSaveError)
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:diagnosesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function confirmDelete () {
            Diagnoses.delete({id: vm.diagnoses.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();


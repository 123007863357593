(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AuditController', AuditController);

    AuditController.$inject = ['CoreService', 'InsuranceBillingConfig', '$filter', '$uibModal', 'TypeLevelCare',
        'Chart', 'InsuranceCarrier', 'AlertService', 'ParseLinks'];

    function AuditController(CoreService, InsuranceBillingConfig, $filter, $uibModal, TypeLevelCare,
                             Chart, InsuranceCarrier, AlertService, ParseLinks) {
        var vm = this;

        vm.charts = [];

        vm.itemsPerPage = 10;
        vm.page = 1;

        vm.title = 'Billing Audit';
        vm.entityClassHumanized = 'Audit';
        vm.entityStateName = 'audit';

        vm.filteredCharts = [];
        vm.audits = [];
        vm._audits = [];
        vm.dates = [];
        vm.facility = CoreService.getCurrentFacility();
        vm.startDateOptions = {};
        vm.endDateOptions = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.firstDate = false;
        vm.datePickerOpenStatus.lastDate = false;
        vm.typeLevelCares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.carriers = [];
        vm.charts = [];
        vm.searchQuery = "";

        vm.filter = {
            facilityId: CoreService.getCurrentFacility().id,
            startDate: null,
            endDate: null,
            weekly: false,
            show: 'all',
            charts: []
        };

        vm.openDetails = openDetails;
        vm.showFilters = showFilters;
        vm.openCalendar = openCalendar;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.exist = exist;
        vm.loadAll = loadAll;
        vm.splitTime = splitTime;
        vm.filterByHours = filterByHours;
        vm.search = search;
        vm.getInsuranceCarrier = getInsuranceCarrier;
        vm.getCharts = getCharts;
        vm.clear = clear;
        vm.loadCharts = loadCharts;

        angular.element('.filter-box').hide();

        loadCharts();

        function loadCharts() {
            Chart.fullFilter({
                statuses: 'CURRENT',
                facilityId: CoreService.getCurrentFacility().id,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: 'lastName',
                direction: 'ASC'
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.charts = [];
                vm.charts = data;

                loadAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function openDetails(date, audit) {
            $uibModal.open({
                templateUrl: 'app/entities/audit/detail/audit-dialog.html',
                controller: 'AuditDetailsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    data: {
                        audit: audit,
                        date: date
                    },
                    chart: Chart.get({id: audit.chart}).$promise
                }
            });
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.lastDate;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.firstDate;
        }

        function showFilters() {
            angular.element('.filter-box').toggleClass('active');

            if (angular.element('.filter-box').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('.filter-box').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('.filter-box').slideUp();
            }
        }

        function exist(date, billingTableByPatient) {
            return _.filter(billingTableByPatient.dates, {date: date}).length > 0;
            // return true;
        }

        function loadAll() {
            vm.isLoading = true;
            vm.audits = [];
            vm.dates = [];

            var filter = angular.copy(vm.filter);
            filter.charts = filter.charts.length === 0 ? vm.charts : filter.charts;

            InsuranceBillingConfig.byFacility(filter, function (result) {
                vm.audits = [];
                vm.dates = result.dates;

                _.forEach(result.billingTableByPatient, function (byPatient) {
                    //init dates by chart
                    var p = {
                        chart: byPatient.chart,
                        patient: byPatient.patient,
                        mrNo: byPatient.mrNo,
                        dates: []
                    };

                    _.forEach(byPatient.dates, function (date) {
                        p[date.date] = date;
                        p.dates.push(date.date);
                    });

                    vm.audits.push(p);
                });

                vm._audits = angular.copy(vm.audits);
                vm._dates = angular.copy(result.dates);

                vm.isLoading = false;
            }, function () {
                vm.isLoading = false;
            });
        }

        function splitTime(inSeconds) {
            if (inSeconds) {
                var result = moment.duration(Number(inSeconds), 'seconds').days();
                result = result == 0 ? "" : result + ' d ';
                result += moment.duration(Number(inSeconds), 'seconds').hours() + '.' + moment.duration(Number(inSeconds), 'seconds').minutes();

                return result;
            }

            return 0;
        }

        function filterByHours(detail) {
            switch (vm.filter.show) {
                case 'over' :
                    return detail && detail.exceededHours > 0;
                case 'short' :
                    return detail && detail.availableHours > 0;
                case 'all':
                    return true;
            }

            return true;
        }

        function search() {
            if (!vm.searchQuery || vm.searchQuery == '') {
                vm.audits = angular.copy(vm._audits);
                vm.dates = angular.copy(vm._dates);
            } else {
                vm.audits = $filter('filter')(vm._audits, vm.searchQuery, undefined);
                vm.dates = extractDates(vm.audits);
            }

            vm.page = 1;

            if (vm.filter.charts.length === 0) {
                loadCharts()
            } else {
                loadAll();
            }
        }

        function extractDates(audits) {
            var dates = [];

            _.forEach(audits, function (audit) {
                dates = _.union(audit.dates, dates);
            });

            return dates.sort();
        }

        function getInsuranceCarrier(query) {
            if (query && query.length > 2) {
                query = _.lowerCase(query);

                vm.carriers = InsuranceCarrier.query({
                    query: query
                });
            }
        }

        function getCharts(query) {
            var filter = {};

            filter.page = 0;
            filter.size = 20;
            filter.facilityId = vm.facility.id;
            filter.searchQuery = query;
            filter.statuses = ['CURRENT', 'ARCHIVE'];

            Chart.fullFilter(filter, function (result) {
                vm.filteredCharts = result
            });
        }

        function clear() {
            vm.filter = {
                facilityId: CoreService.getCurrentFacility().id,
                startDate: null,
                endDate: null,
                weekly: true,
                show: 'all',
                charts: []
            }

            vm.page = 1

            loadCharts();
        }

        vm.week = week;

        function week() {
            vm.filter.weekly = true;

            loadAll();
        }

        vm.month = month;

        function month() {
            vm.filter.weekly = false;

            loadAll();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConsentBoostAttachmentDialogController', ConsentBoostAttachmentDialogController);

    ConsentBoostAttachmentDialogController.$inject = ['$uibModalInstance', 'evaluationId'];

    function ConsentBoostAttachmentDialogController($uibModalInstance, evaluationId) {
        var vm = this;

        vm.clear = clear;
        vm.evaluationId = evaluationId

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

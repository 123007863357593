(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('GroupSessionTemplate', GroupSessionTemplate);

    GroupSessionTemplate.$inject = ['$resource', 'DateUtils', 'GUIUtils', 'moment'];

    function GroupSessionTemplate($resource, DateUtils, GUIUtils, moment) {
        var resourceUrl = 'api/group-session-templates/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.stop = DateUtils.convertDateTimeFromServer(data.stop);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'schedule': {
                url: 'api/group-sessions-template/schedule', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        var result = [];

                        _.forEach(data, function (item) {
                            item.date = DateUtils.convertLocalDateFromServer(item.date);

                            _.forEach(item.templates, function (template) {
                                template.start = DateUtils.convertDateTimeFromServer(template.start);

                                var dateStart = new Date(angular.copy(item.date));
                                if (template.start) {
                                    dateStart.setHours(template.start.getHours());
                                    dateStart.setMinutes(template.start.getMinutes());
                                }
                                template.status = (moment(dateStart).isBefore(new Date(), 'day')) ? 'DISABLED' : 'PENDING';

                                var event = {
                                    title: template.title + " (" + template.id + ")",
                                    start: dateStart,
                                    color: GUIUtils.groupSessionStatusColor(template.status ? template.status : 'PENDING'),
                                    template: template
                                };

                                result.push(event);
                            });

                            _.forEach(item.groupSessions, function (groupSession) {
                                var event = {
                                    title: groupSession.title + " (" + groupSession.id + ")",
                                    start: groupSession.start ? DateUtils.convertDateTimeFromServer(groupSession.start) : DateUtils.convertDateTimeFromServer(groupSession.startDate),
                                    color: GUIUtils.groupSessionStatusColor(groupSession.status ? groupSession.status : 'PENDING'),
                                    groupSession: groupSession
                                };

                                result.push(event);
                            });
                        });

                        return result;
                    }

                    return null;
                }
            },
            'filter': {url: 'api/group-sessions-template/filter', method: 'GET', isArray: true},
            'byFacility': {url: 'api/group-sessions-template/by-facility/:id', method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgMedicationDeleteController',ProgMedicationDeleteController);

    ProgMedicationDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProgMedication'];

    function ProgMedicationDeleteController($uibModalInstance, entity, ProgMedication) {
        var vm = this;

        vm.progMedication = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProgMedication.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CovidFacilityLab', CovidFacilityLab);

    CovidFacilityLab.$inject = ['$resource'];

    function CovidFacilityLab ($resource) {
        var resourceUrl =  'api/covid-facility-labs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByFacility': {
                url: 'api/covid-facility-lab/by-facility/:id',
                method: 'GET',
                isArray: true
            }
        });
    }
})();

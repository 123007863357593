(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CareCoordinationDialogController', CareCoordinationDialogController);

    CareCoordinationDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance',
        'entity', 'CareCoordination', 'Employee', 'Icd10Search', 'CoreService', 'Notifications'];

    function CareCoordinationDialogController($timeout, $scope, $state, $stateParams, $uibModalInstance,
                                              entity, CareCoordination, Employee, Icd10Search, CoreService, Notifications) {
        var vm = this;

        vm.careCoordination = entity;
        vm.corporation = CoreService.getCorporation();
        vm.employees = [];
        vm.icd10 = [];
        vm.icd10Tmp = [];
        vm.employeeTmp = [];
        vm.showDelete = false;
        vm.chart = entity.chart;

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.confirmDelete = confirmDelete;

        function createNotification(title, description, priorityLevel, stateName) {
            var notification = {};
            notification.title = title;
            notification.description = description;
            notification.priorityLevel = priorityLevel;
            notification.viewed = false;
            notification.reviewedDate = new Date();
            notification.employee = CoreService.getCurrentEmployee();
            notification.state = stateName;
            notification.stateParams = angular.toJson($state.params).toString().replace(/["]/g, '');

            Notifications.save(notification, onSaveSuccessCreateNotification, onSaveErrorCreateNotification);
        }

        function onSaveSuccessCreateNotification(result) {
            $scope.$emit('bluebookApp:notificationUpdate', result);
            $state.go('chart', {'#': 'notification'}, {reload: true});
            vm.isSaving = false;
        }

        function onSaveErrorCreateNotification() {
            vm.isSaving = false;
        }

        if (vm.careCoordination.id) {
            vm.employeeTmp = [vm.careCoordination.employees][0];
            vm.icd10Tmp = [vm.careCoordination.icd10S][0];
        }

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        vm.getIcd10 = function (query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.careCoordination.id !== null) {
                vm.careCoordination.icd10S = vm.icd10Tmp;
                vm.careCoordination.employees = vm.employeeTmp;
                CareCoordination.update(vm.careCoordination, onSaveSuccess, onSaveError);
            } else {
                vm.careCoordination.icd10S = vm.icd10Tmp;
                vm.careCoordination.employees = vm.employeeTmp;
                CareCoordination.save(vm.careCoordination, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:careCoordinationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function confirmDelete () {
            CareCoordination.delete({id: vm.careCoordination.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DrfPracticeInfoController', DrfPracticeInfoController);

    DrfPracticeInfoController.$inject = ['GenericEntityDatatableService', 'DrfPracticeInfo', 'CoreService', '$q',
        '$uibModal', 'ConfirmationService'];

    function DrfPracticeInfoController(GenericEntityDatatableService, DrfPracticeInfo, CoreService, $q,
                                       $uibModal, ConfirmationService) {
        var vm = this;

        var params = {
            corporationId: CoreService.getCorporation().id
        }

        vm.descriptor = {
            title: 'Drf Practice Info',
            newButtonTitle: 'New Drf Practice Info',
            entityClassHumanized: 'Drf Practice Info',
            entityStateName: 'drf-practice-info',
            service: DrfPracticeInfo,
            serviceMethod: 'filter',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'username',
                    title: 'Username'
                },
                {
                    name: 'region',
                    title: 'Region'
                },
                {
                    name: 'vendorUsername',
                    title: 'Vendor Username'
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Drf Practice Info',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/drf-practice-info/update/drf-practice-info-dialog.html',
                controller: 'DrfPracticeInfoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            name: null,
                            username: null,
                            region: null,
                            corporation: CoreService.getCorporation(),
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/drf-practice-info/update/drf-practice-info-dialog.html',
                controller: 'DrfPracticeInfoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['DrfPracticeInfo', function (DrfPracticeInfo) {
                        return DrfPracticeInfo.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('DrfPracticeInfo').result.then(function () {
                    DrfPracticeInfo.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientResultsUnassignedController', PatientResultsUnassignedController);

    PatientResultsUnassignedController.$inject = ['$uibModal', 'CoreService', 'PatientResult', '$q', 'GUIUtils',
        '$rootScope', 'toastr', 'DataTablesService', 'GenericEntityDatatableService',
        'PopupService', 'ConfirmationService'];

    function PatientResultsUnassignedController($uibModal, CoreService, PatientResult, $q, GUIUtils,
                                                $rootScope, toastr, DataTablesService, GenericEntityDatatableService,
                                                PopupService, ConfirmationService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Unassigned Lab Results',
            entityClassHumanized: 'Patient Results',
            entityStateName: 'patient-result',
            service: PatientResult,
            serviceMethod: 'unassigned',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            ],
            selectionColumn: 'id',
            withSelectionColumn: true,
            disableSelectionColumn: function (filter) {
                return filter.assigned !== false;
            },
            disableAllSelectionColumn: function (filter) {
                return filter.assigned !== false;
            },
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'patientName',
                    title: 'Patient Name',
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dob).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Barcode',
                    render: function (data) {
                        return data.accessionNumber ? data.accessionNumber : '';
                    }
                },
                {
                    name: null,
                    title: 'Collection Date',
                    render: function (data) {
                        return moment(data.collectionDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Received Date',
                    render: function (data) {
                        return moment(data.lastModifiedDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Alert',
                    options: [
                        {key: 'width', value: '175px'}
                    ],
                    render: function (data) {
                        if (data.abnormalRange) {
                            return GUIUtils.getStatusTemplate('ABNORMAL', 'danger');
                        } else {
                            return GUIUtils.getStatusTemplate('NORMAL', 'success');
                        }
                    }
                },
                {
                    name: 'status',
                    title: 'Status',
                    render: function (data) {
                        var assignedStatus = data.assigned ? {label: 'Assigned', template: 'success'}
                            : {label: 'Unassigned', template: 'danger'};

                        return GUIUtils.getStatusTemplate(assignedStatus.label, assignedStatus.template);
                    }
                },
                {
                    name: null,
                    title: 'Assigned',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.chartId ? true : false);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        details(data.id);
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Chart to Assign Results',
                            disabledOnEmpty: true,
                            action: function (filter, selected) {
                                return selectChart(selected)
                            }
                        })
                    ]
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'All Results',
                            iClass: 'fa fa-flask',
                            ngClass: function (filter) {
                                return filter.assigned === null ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.assigned = null;
                            }
                        },
                        {
                            name: 'Unassigned',
                            iClass: 'fa fa-circle-o',
                            ngClass: function (filter) {
                                return filter.assigned === false ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.assigned = false;
                            }
                        },
                        {
                            name: 'History',
                            iClass: 'fa fa-spinner',
                            ngClass: function (filter) {
                                return filter.assigned === true ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter, selected) {
                                filter.assigned = true;
                            }
                        }
                    ],
                }
            ],
            filters: [
                {
                    name: 'assigned',
                    visible: false,
                    defaultValue: function () {
                        return false;
                    }
                }
            ]
        }

        function details(id) {
            PopupService.showPatientResultDet(id);
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Unassigned Result').result.then(function () {
                    PatientResult.deleteUnassigned({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function selectChart(selected) {
            var ids = _.map(selected, function (item) {
                return item.id;
            });

            return $q(function (resolve) {
                PopupService.showSelectChartPopup().result.then(function (chartId) {
                    PatientResult.assign({
                        ids: ids, chartId: chartId
                    }, function (result) {
                        if (result.length > 0) {
                            toastr.error('Assign operation fail');
                        } else {
                            toastr.success('Assign operation successfully');
                        }

                        resolve();
                    }, function () {
                        toastr.error('Assign operation fail');
                        resolve();
                    });
                });
            })
        }
    }
})();

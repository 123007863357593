(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('track', {
                parent: 'entity',
                url: '/track',
                data: {
                    authorities: [],
                    pageTitle: 'Tracks'
                },
                ncyBreadcrumb: {
                    label: 'Tracks'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/track/list/track.html',
                        controller: 'TrackController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: function () {
                        return {
                            name: null,
                            description: null
                        };
                    },
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_TRACK];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                        Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_TRACK, access);
                    }]
                }
            });
    }
})();

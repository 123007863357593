(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationHistoryNewController', PatientMedicationHistoryNewController);

    PatientMedicationHistoryNewController.$inject = ['PatientMedication', 'DTOptionsBuilder', 'DTColumnBuilder', 'DataTablesService',
                                             'chart', '$uibModalInstance', '$timeout', 'ParseLinks', 'PopupService', '$uibModalStack',
                                                '$state'];

    function PatientMedicationHistoryNewController(PatientMedication, DTOptionsBuilder, DTColumnBuilder, DataTablesService,
                                           chart, $uibModalInstance, $timeout, ParseLinks, PopupService, $uibModalStack,
                                                   $state) {
        var vm = this;
        vm.chart = chart;

        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.patientMedications = [];

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.addMedicationFromHistory = addMedicationFromHistory;

        loadAll();

        function loadAll() {
            PatientMedication.findAllByFilter({
                chartId: chart.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                discontinued: true
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientMedications = data;
            }

            function onError(error) {
                AlertService.error(error.data);
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }

        function addMedicationFromHistory(patientMedicationId) {
            $uibModalStack.dismissAll();

            PopupService.showPatientMedicationPopup(chart, null, patientMedicationId).result.then(function () {
                $state.reload();
            }, function () {

            });
        }
    }
})();

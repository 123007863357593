(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TypeLevelCareHistoryNewController', TypeLevelCareHistoryNewController);

    TypeLevelCareHistoryNewController.$inject = ['TypeLevelCare', '$state', 'DTOptionsBuilder', 'DTColumnBuilder',
        'DataTablesService', 'chart', 'Chart', '$q', 'GUIUtils', '$filter', 'CoreService', '$compile', '$scope',
        '$timeout', '$uibModalInstance', '$rootScope'];

    function TypeLevelCareHistoryNewController(TypeLevelCare, $state, DTOptionsBuilder, DTColumnBuilder, DataTablesService, chart, Chart,
                                          $q, GUIUtils, $filter, CoreService, $compile, $scope,
                                          $timeout, $uibModalInstance, $rootScope) {
        var vm = this;
        vm.chart = chart;

        vm.facesheetTpls = [];
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.selected = {};
        vm.selectAll = false;
        vm.levelCares = [];

        vm.clear = clear;
        vm.print = print;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.search = search;
        vm.reload = reload;

        loadAll();

        function loadAll() {
            TypeLevelCare.filter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.levelCares = result;
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getFilter() {
            var filter = {};

            filter.chartId = vm.chart.id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function reload() {
            loadAll();
        }
    }
})();

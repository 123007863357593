(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RelationalDialogController', RelationalDialogController);

    RelationalDialogController.$inject = ['$scope', 'entity', 'Relational', '$uibModalInstance', '$timeout', 'CoreService'];

    function RelationalDialogController($scope, entity, Relational, $uibModalInstance, $timeout, CoreService) {
        var vm = this;

        vm.relational = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.relational.id !== null) {
                Relational.update(vm.relational, onSaveSuccess, onSaveError);
            } else {
                Relational.save(vm.relational, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:specimenUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

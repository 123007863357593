(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SendLabCompendiumDialogController', SendLabCompendiumDialogController);

    SendLabCompendiumDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'SendLabCompendium', 'LabCompendium', 'FacilityLab'];

    function SendLabCompendiumDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                               SendLabCompendium, LabCompendium, FacilityLab) {
        var vm = this;

        vm.sendLabCompendium = entity;

        vm.labCompendiums = [];
        vm.page = 1;

        vm.clear = clear;
        vm.save = save;
        vm.getLabCompendium = getLabCompendium;
        vm.changeLaboratory = changeLaboratory;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.sendLabCompendium.id !== null) {
                SendLabCompendium.update(vm.sendLabCompendium, onSaveSuccess, onSaveError);
            } else {
                SendLabCompendium.save(vm.sendLabCompendium, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:sendLabCompendiumUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getLabCompendium(query) {
            LabCompendium.filter({
                laboratoryId: vm.sendLabCompendium.laboratory.id,
                searchQuery: query,
                page: vm.page - 1,
                size: 40,
                sort: 'code,asc',
                facilityId: vm.sendLabCompendium.facility.id
            }, function (result) {
                vm.labCompendiums = result;
            });
        }

        function changeLaboratory() {
            vm.labcompendiums = [];
        }
    }
})();

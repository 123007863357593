(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationFile', EvaluationFile);

    EvaluationFile.$inject = ['$resource', 'DataUtils'];

    function EvaluationFile ($resource, DataUtils) {
        var resourceUrl =  'api/evaluation-files/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByEvaluation': {method: 'GET', url: 'api/evaluation-files/by-evaluation/:id', isArray: true},
            'attachFiles': {url: 'api/evaluation-files/attach-files', method: 'POST', isArray: true},
            'download': {
                url: 'api/evaluation-files/get-file/:id',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('udt')
        .directive('ngSrcAuth', ngSrcAuth);

    ngSrcAuth.$inject = ['$timeout', '$http', 'AuthServerProvider', 'GUIUtils', '$sanitize'];

    function ngSrcAuth($timeout, $http, AuthServerProvider, GUIUtils, $sanitize) {
        var directive = {
            restrict: 'A',
            link: link
        }

        return directive;

        function link($scope, element, attrs) {
            function revokeObjectURL() {
                if ($scope.objectURL) {
                    URL.revokeObjectURL($scope.objectURL);
                }
            }

            $scope.$on('$destroy', function () {
                revokeObjectURL();
            });

            attrs.$set('src', 'data:image/png;base64,' + GUIUtils.getDefaultUserPicture());

            var chart = angular.fromJson(attrs.ngSrcAuth);

            if (chart && chart.pictureRefId) {
                //request file data from server, attaching auth header and token
                var requestConfig = {
                    responseType: 'arraybuffer',
                    method: 'Get',
                    headers: {'Authorization': 'Bearer ' + AuthServerProvider.getToken()},
                    url: '/api/chart-pictures/get-thumbnail' + '/' + chart.id,
                    cache: 'false',
                    transformResponse: function (response, headers) {
                        var blob = new Blob(
                            [response],
                            {type: headers('Content-Type')}
                        );

                        $scope.objectURL = window.URL.createObjectURL(blob);

                        return $scope.objectURL;
                    }
                };

                $http(requestConfig)
                    .success(function (data) {
                        attrs.$set('src', $sanitize(data));
                    });
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('NotificationsDetailController', NotificationsDetailController);

    NotificationsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Notifications', 'Employee'];

    function NotificationsDetailController($scope, $rootScope, $stateParams, previousState, entity, Notifications, Employee) {
        var vm = this;

        vm.notifications = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bluebookApp:notificationsUpdate', function(event, result) {
            vm.notifications = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgEligFacilityController', ProgEligFacilityController);

    ProgEligFacilityController.$inject = ['$scope', '$state', 'ProgEligFacility', 'CoreService', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$q', '$compile'];

    function ProgEligFacilityController ($scope, $state, ProgEligFacility, CoreService, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $q, $compile) {
        var vm = this;

        vm.progEligFacilities = [];
        vm.dtInstance = {};
        vm.title = 'Facility Program Eligibility';
        vm.entityClassHumanized = 'Facility Program Eligibility';
        vm.entityStateName = 'prog-elig-facility';
        vm.facility = CoreService.getCurrentFacility();

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            ProgEligFacility.findAllByFilter({
                facilityId: vm.facility.id,
                searchQuery: vm.searchQuery,
            }, function (result) {
                vm.progEligFacilities = result;
                defer.resolve(vm.progEligFacilities);
            });
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withDisplayLength(15)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.startDate).format('MM/DD/YYYY');
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return moment(data.endDate).format('MM/DD/YYYY');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ' +
                'ui-sref="' + $state.current.name + '.edit({id:' + data.id + '})">' +
                '<i class="fa fa-edit" aria-hidden="true"></i>Edit' +
                '</a>' +
                '</li>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link red" ' +
                'ui-sref="' + $state.current.name + '.delete({id:' + data.id + '})">' +
                '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CorporationController', CorporationController);

    CorporationController.$inject = ['Corporation', 'GenericEntityDatatableService', '$uibModal', 'ConfirmationService',
        '$q', 'GUIUtils', '$state'];

    function CorporationController(Corporation, GenericEntityDatatableService, $uibModal, ConfirmationService,
                                   $q, GUIUtils, $state) {
        var vm = this;

        var entityStateName = 'corporation';

        vm.descriptor = {
            title: 'Corporations',
            entityClassHumanized: 'Corporation',
            entityStateName: entityStateName,
            newButtonTitle: 'New Corporation',
            service: Corporation,
            serviceMethod: 'query',
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    title: 'Active',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.active);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Corporation',
                            action: function () {
                                add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/corporation/create/corporation-wizard-dialog.html',
                controller: 'CorporationWizardDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            id: null,
                            active: true
                        };
                    }
                }
            }).result.then(function () {
                $state.reload();
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/corporation/update/corporation-dialog.html',
                controller: 'CorporationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Corporation', function (Corporation) {
                        return Corporation.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                $state.reload();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Corporation').result.then(function () {
                Corporation.delete({id: id}, function () {
                    $state.reload();
                });
            })
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProcessedSchedulerItemController', ProcessedSchedulerItemController);

    ProcessedSchedulerItemController.$inject = ['ProcessedSchedulerItem', 'CoreService',
        'GenericEntityDatatableService', 'Chart', 'Corporation', 'toastr', '$rootScope'];

    function ProcessedSchedulerItemController(ProcessedSchedulerItem, CoreService,
                                              GenericEntityDatatableService, Chart, Corporation, toastr, $rootScope) {
        var vm = this;

        var params = {
        }

        var entityStateName = 'processed-scheduler-item'

        vm.descriptor = {
            title: 'Processed Patient Orders',
            entityClassHumanized: 'ProcessedSchedulerItem',
            entityStateName: entityStateName,
            service: ProcessedSchedulerItem,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'barcode',
                    title: 'Barcode'
                },
                {
                    name: 'chartId',
                    title: 'Chart',
                    render: function (entity) {
                        return entity.chart ?
                            entity.chart.firstName + " " +
                            (entity.chart.middleName ? entity.chart.middleName + ' ' : '') +
                            entity.chart.lastName : ""
                    }
                },
                {
                    name: 'mrNo',
                    title: 'MrNo',
                    render: function (entity) {
                        return entity.chart ? entity.chart.mrNo : ''
                    }
                },
                {
                    title: 'Corporation',
                    name: 'corporation',
                    render: function (entity) {
                        return entity.corporation ? entity.corporation.name : ""
                    }
                },
                {
                    title: 'Patient Order',
                    name: 'patientOrder',
                    render: function (entity) {
                        return entity.patientOrder ? entity.patientOrder.id : ""
                    }
                },
                {
                    name: 'logStatus',
                    title: 'Log Status',
                    render: function (data) {
                        if (data.logStatus === 'FAIL') {
                            return "<small class='label label-danger'>" + data.logStatus + "</small>";
                        } else {
                            return data.logStatus;
                        }
                    }
                },
                {
                    name: 'createdDate',
                    title: 'Created Date',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
                {
                    name: 'filePath',
                    title: 'File'
                },
                {
                    name: 'errorMessage',
                    title: 'Error Message'
                },
                {
                    name: 'txStatus',
                    title: 'Tx Status'
                },
                {
                    name: 'txAttempts',
                    title: 'Tx Attempts'
                },
                {
                    name: 'lastAttempt',
                    title: 'Last Attempt',
                    render: function (data) {
                        return moment(data.lastAttempt).format($rootScope.amDateFormat);
                    }
                },
                {
                    name: 'txErrorMessage',
                    title: 'Tx Error Message'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getCloneAction(), {
                    name: 'Download HL7 file',
                    action: function (data) {
                        download(data.id);
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                    name: 'Add to pendingPatientOrder again',
                    action: function (data) {
                        return addPendingPatientOrder(data.barcode);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'barcode',
                    placeholder: 'Barcode...',
                    type: 'string'
                },
                {
                    name: 'corporationId',
                    read: function (filter) {
                        return filter.corporationId ? filter.corporationId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        Corporation.query(function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    placeholder: 'Corporation ...',
                    type: 'select',
                },
                {
                    name: 'logStatus',
                    placeholder: 'Log Status...',
                    type: 'select',
                    values: [
                        'SUCCESS', 'FAIL', 'FILE_ERROR'
                    ],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                },
            ]
        }

        function addPendingPatientOrder(barcode) {
            ProcessedSchedulerItem.savePendingPatientOrder({groupNumberId: barcode})
            toastr.success('The ProcessedSchedulerItem was added to Pending Patient Order', 'Success');
        }

        function download(id) {
            ProcessedSchedulerItem.download({id: id});
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GlucoseInterventionController', GlucoseInterventionController);

    GlucoseInterventionController.$inject = ['GenericEntityDatatableService', 'CoreService', 'GlucoseIntervention',
        '$uibModal', 'ConfirmationService', '$q'];

    function GlucoseInterventionController(GenericEntityDatatableService, CoreService, GlucoseIntervention,
                                           $uibModal, ConfirmationService, $q) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Glucose Intervention',
            newButtonTitle: 'New Glucose Intervention',
            entityClassHumanized: 'Glucose Intervention',
            entityStateName: 'glucose-intervention',
            service: GlucoseIntervention,
            serviceMethod: 'byCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Glucose Intervention',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/glucose-intervention/update/glucose-intervention-dialog.html',
                controller: 'GlucoseInterventionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/glucose-intervention/update/glucose-intervention-dialog.html',
                controller: 'GlucoseInterventionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['GlucoseIntervention', function (GlucoseIntervention) {
                        return GlucoseIntervention.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Glucose Intervention').result.then(function () {
                    GlucoseIntervention.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartReviewController', ChartReviewController);

    ChartReviewController.$inject = ['facility', 'Chart', '$q', '$scope', 'CoreService', 'GUIUtils', '$state',
        '$uibModal', 'GenericEntityDatatableService'];

    function ChartReviewController(facility, Chart, $q, $scope, CoreService, GUIUtils, $state,
                                   $uibModal, GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Chart Review - Diagnosis',
            entityClassHumanized: 'Chart Review - Diagnosis',
            entityStateName: 'charts-review',
            service: Chart,
            serviceMethod: 'withoutDiagnosis',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[0, 'desc']]}
            ],
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    },
                    options: [{key: "width", value: "200px"}]
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY")
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: "width", value: "130px"}]
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return moment(data.admissionDate).format("MM/DD/YYYY");
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    title: 'Referral',
                    render: function (data) {
                        return data.referrer ? data.referrer : "-";
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.id});
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getCareTeamAction(), {
                    name: "New Care Team Member",
                    action: function (data) {
                        return addCareTeam(data.id);
                    },
                    ngIf: function () {
                        return vm.descriptor.serviceMethod === 'withoutCareTeam';
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getInsuranceAction(), {
                    name: "New Insurance",
                    action: function (data) {
                        return addInsurance(data.id);
                    },
                    ngIf: function () {
                        return vm.descriptor.serviceMethod === 'withoutInsurance';
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDiagnosisAction(), {
                    name: "New Diagnoses",
                    action: function (data) {
                        return addDiagnosis(data.id);
                    },
                    ngIf: function () {
                        return vm.descriptor.serviceMethod === 'withoutDiagnosis';
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'Diagnosis',
                            iClass: 'fa fa-ambulance',
                            ngClass: function (filter) {
                                return vm.descriptor.serviceMethod === "withoutDiagnosis" ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                vm.descriptor.title = 'Chart Review - Diagnosis';
                                vm.descriptor.serviceMethod = "withoutDiagnosis";
                            }
                        },
                        {
                            name: 'Insurance',
                            iClass: 'fa fa-id-card',
                            ngClass: function (filter) {
                                return vm.descriptor.serviceMethod === "withoutInsurance" ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                vm.descriptor.title = 'Chart Review - Insurance';
                                vm.descriptor.serviceMethod = "withoutInsurance";
                            },
                        },
                        {
                            name: 'Care Team',
                            iClass: 'fa fa-user-md',
                            ngClass: function (filter) {
                                return vm.descriptor.serviceMethod === "withoutCareTeam" ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter, selected) {
                                vm.descriptor.title = 'Chart Review - Care Team'
                                vm.descriptor.serviceMethod = "withoutCareTeam";
                            }
                        }
                    ],
                },
            ],
        }

        function addCareTeam(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/chart-care-team/chart-care-team-dialog.html',
                    controller: 'ChartCareTeamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    windowTopClass: 'custom-dialog-styles',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                allergen: null,
                                allergen_type: null,
                                reaction: null,
                                treatment: null,
                                status: null,
                                id: null,
                                endDate: null,
                                startDate: null,
                                chart: Chart.get({id: id})
                            };
                        }
                    }
                }).result.then(function () {
                    resolve();
                })
            })
        }

        function addInsurance(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/insurance/update/insurance-dialog.html',
                    controller: 'InsuranceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    windowTopClass: 'custom-dialog-styles',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                address: null,
                                address2: null,
                                zipCode: null,
                                city: null,
                                policyNumber: null,
                                groupNumber: null,
                                groupName: null,
                                planNumber: null,
                                lastName: null,
                                firstName: null,
                                middleInitial: null,
                                dob: null,
                                gender: null,
                                phone: null,
                                employer: null,
                                insuranceOrder: 1,
                                id: null,
                                chart: Chart.get({id: id})
                            };
                        },
                        chart: Chart.get({id: id}).$promise
                    }
                }).result.then(function () {
                    resolve();
                });
            })
        }

        function addDiagnosis(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/diagnoses/update/diagnoses-dialog.html',
                    controller: 'DiagnosesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: function () {
                            return {
                                comment: null,
                                id: null,
                                endDate: null,
                                startDate: null,
                                chart: Chart.get({id: id})
                            };
                        }
                    }
                }).result.then(function () {
                    resolve();
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DrugChartController', DrugChartController);

    DrugChartController.$inject = ['chart', 'Drug', 'CoreService', '$uibModal', '$scope', 'Alcohol', 'access', 'ACTIONS', '$window', '$rootScope', 'Auth'];

    function DrugChartController(chart, Drug, CoreService, $uibModal, $scope, Alcohol, access, ACTIONS, $window, $rootScope, Auth) {
        var vm = this;

        vm.chart = chart;
        vm.accessAlcoholView = access[ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW] ? access[ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW].roles.join() : null;
        vm.accessDrugsView = access[ACTIONS.ACTION_PATIENT_DRUGS_VIEW] ? access[ACTIONS.ACTION_PATIENT_DRUGS_VIEW].roles.join() : null;
        vm.activeTab = vm.accessAlcoholView ? 0 : 1;

        vm.keepActiveTab = keepActiveTab;
        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.history = history;
        vm.getAlcoholGraphOpt = getAlcoholGraphOpt;
        vm.getDrugsGraphOpt = getDrugsGraphOpt;

        vm.dataDrug = [];
        vm.dataAlcohol = [];

        vm.valueData = {
            values: [],
            key: 'value',
            color: '#000000'
        };

        var unsubscribe = $rootScope.$on('bluebookApp:alcoholUpdate', function(event, result) {
            getAlcoholData();
        });

        var unsubscribe = $rootScope.$on('bluebookApp:drugUpdate', function(event, result) {
            getDrugData();
        });

        function toggleItem() {
            CoreService.togglePanel('drugs');
        }

        function keepActiveTab(index) {
            vm.activeTab = index;
        }

        loadAll();

        function loadAll() {
            getAlcoholData();
            getDrugData();
        }

        function getAlcoholData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW, access)) {
                return;
            }

            vm.alcohol = Alcohol.findLastByChartId({id: chart.id});

            Alcohol.findLastByChartById({id: chart.id, size: 10}, function (data) {
                vm.dataAlcohol = [];

                vm.valueData = {
                    values: [],
                    key: 'value',
                    color: '#000000'
                };

                for (var i = data.length - 1; i >= 0; i--) {
                    vm.valueData.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value1)});
                }

                vm.dataAlcohol.push(vm.valueData);
            });
        }

        function getDrugData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_DRUGS_VIEW, access)) {
                return;
            }

            Drug.findLastByChart({id: chart.id}, function (data) {
                if (data != null) {
                    vm.drug = data;
                    vm.dataDrug = [];

                    vm.dataDrug = {values: []};
                    if (data.barbiturate) {
                        vm.dataDrug.values.push({label: "BAR", value: data.barbiturate});
                    }

                    if (data.amphetamine) {
                        vm.dataDrug.values.push({label: "AMP", value: data.amphetamine});
                    }

                    if (data.methadone) {
                        vm.dataDrug.values.push({label: "MTD", value: data.methadone});
                    }

                    if (data.opiates) {
                        vm.dataDrug.values.push({label: "OPI", value: data.opiates});
                    }

                    if (data.tetrahydrocannabinol) {
                        vm.dataDrug.values.push({label: "THC", value: data.tetrahydrocannabinol});
                    }

                    if (data.phencyclidine) {
                        vm.dataDrug.values.push({label: "PCP", value: data.phencyclidine});
                    }

                    if (data.benzodiazepine) {
                        vm.dataDrug.values.push({label: "BZO", value: data.benzodiazepine});
                    }

                    if (data.oxycodone) {
                        vm.dataDrug.values.push({label: "OXY", value: data.oxycodone});
                    }

                    if (data.methamphetamine) {
                        vm.dataDrug.values.push({label: "MET", value: data.methamphetamine});
                    }

                    if (data.tricyclicAntidepressants) {
                        vm.dataDrug.values.push({label: "TCA", value: data.tricyclicAntidepressants});
                    }

                    if (data.buprenorphine) {
                        vm.dataDrug.values.push({label: "BUP", value: data.buprenorphine});
                    }

                    if (data.cocaine) {
                        vm.dataDrug.values.push({label: "COC", value: data.cocaine});
                    }

                    if (data.morphine) {
                        vm.dataDrug.values.push({label: "MOP", value: data.morphine});
                    }

                    if (data.methylenedioxymethamphetamine) {
                        vm.dataDrug.values.push({label: "MDMA", value: data.methylenedioxymethamphetamine});
                    }

                    vm.dataDrug = [vm.dataDrug];
                }
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/drug/update/drug-dialog.html',
                controller: 'DrugDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            id: null,
                            chart: chart,
                            barbiturate: 0,
                            amphetamine: 0,
                            methadone: 0,
                            opiates: 0,
                            tetrahydrocannabinol: 0,
                            phencyclidine: 0,
                            benzodiazepine: 0,
                            oxycodone: 0,
                            methamphetamine: 0,
                            tricyclicAntidepressants: 0,
                            buprenorphine: 0,
                            cocaine: 0,
                            morphine: 0,
                            methylenedioxymethamphetamine: 0,
                            date: new Date()
                        };
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/drug/history/drug-history.html',
                controller: 'DrugHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        getAlcoholGraphOpt();

        function getAlcoholGraphOpt() {
            vm.alcoholGraphOpt = {
                chart: {
                    type: 'lineChart',
                    showLegend: false,
                    height: 240,
                    showXAxis: false,
                    margin: {
                        top: 20,
                        right: 50,
                        bottom: 100,
                        left: 50
                    },
                    x: function (d) {
                        return d.x;
                    },
                    y: function (d) {
                        return d.y;
                    },
                    useInteractiveGuideline: true,
                    xAxis: {
                        tickFormat: function (d) {
                            return d3.time.format('%m/%d/%y-%H:%M')(new Date(d));
                        },
                        rotateLabels: (-70)
                    },
                    yAxis: {
                        tickFormat: function (d) {
                            return d3.format('.02f')(d);
                        },
                        showMaxMin: false
                    }
                }
            };
        }

        getDrugsGraphOpt();

        function getDrugsGraphOpt() {
            vm.drugsGraphOpt = {
                chart: {
                    type: 'discreteBarChart',
                    showLegend: false,
                    showYAxis: false,
                    showValues: false,
                    height: 340,
                    forceY: 1,
                    margin: {
                        top: 20,
                        right: 50,
                        bottom: 100,
                        left: 30
                    },
                    x: function (d) {
                        return d.label;
                    },
                    y: function (d) {
                        return d.value;
                    },

                    useInteractiveGuideline: true,
                    xAxis: {
                        rotateLabels: (-70)
                    },
                    yAxis: {
                        axisLabel: 'Result',
                        showMaxMin: false
                    }
                }
            };
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDrugsHistory', function (event, result) {
            history();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConcurrentReviewsController', ConcurrentReviewsController);

    ConcurrentReviewsController.$inject = ['Chart', '$q', 'CoreService', 'GUIUtils', '$uibModal', 'toastr',
        '$rootScope', 'GenericEntityDatatableService'];

    function ConcurrentReviewsController(Chart, $q, CoreService, GUIUtils, $uibModal, toastr,
                                         $rootScope, GenericEntityDatatableService) {

        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Concurrent Reviews',
            entityClassHumanized: 'Concurrent Reviews',
            entityStateName: 'concurrent-reviews',
            service: Chart,
            serviceMethod: 'withoutConcurrentReview',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            ],
            selectionColumn: 'chartId',
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + " " + data.lastName;
                    }
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'mrNo',
                    title: "Mr Number"
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.levelOfCare == null ? " " : data.levelOfCare;
                    }
                },
                {
                    name: null,
                    title: "Insurance Carrier",
                    render: function (data) {
                        return data.insuranceName;
                    }
                },
                {
                    name: "insurancePolicyNumber",
                    title: "Insurance Policy"
                },
                {
                    name: null,
                    title: "Last Date",
                    render: function (data) {
                        return data.expiringDate == null ? "" : moment(data.expiringDate).format($rootScope.dateFormat);
                    }
                },
                {
                    name: "authNum",
                    title: 'Auth Number',
                },
                {
                    name: null,
                    title: "Next Review Date",
                    render: function (data) {
                        return data.nextReviewDate == null ? "" : moment(data.nextReviewDate).format($rootScope.dateFormat);
                    }
                },
                {
                    name: null,
                    title: "LCD",
                    render: function (data) {
                        if (data.lcd) {
                            return '<span class="label label-success">YES</span>';
                        } else {
                            return '<span class="label label-danger">NO</span>';
                        }
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.chartId});
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                    name: "New Concurrent Review",
                    action: function (data) {
                        return add(data);
                    },
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'Without Reviews',
                            iClass: 'fa fa-ambulance',
                            ngClass: function (filter) {
                                return vm.descriptor.serviceMethod === "withoutConcurrentReview" ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                vm.descriptor.serviceMethod = "withoutConcurrentReview";
                            }
                        },
                        {
                            name: 'Expired Reviews',
                            iClass: 'fa fa-id-card',
                            ngClass: function (filter) {
                                return vm.descriptor.serviceMethod === "expiredConcurrentReview" ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                vm.descriptor.serviceMethod = "expiredConcurrentReview";
                            },
                        },
                        {
                            name: 'Expiring Reviews',
                            iClass: 'fa fa-user-md',
                            ngClass: function (filter) {
                                return vm.descriptor.serviceMethod === "expiringConcurrentReview" ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter, selected) {
                                vm.descriptor.serviceMethod = "expiringConcurrentReview";
                            }
                        }
                    ],
                },
            ],
        }

        function add(data) {
            return $q(function (resolve) {
                if (data.primaryId) {
                    $uibModal.open({
                        templateUrl: 'app/entities/concurrent-reviews/update/concurrent-reviews-dialog.html',
                        controller: 'ConcurrentReviewsDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        windowTopClass: 'custom-dialog-styles',
                        resolve: {
                            entity: function () {
                                return {
                                    authorizationDate: null,
                                    numberDates: 0,
                                    frequency: null,
                                    typeLevelCare: null,
                                    startDate: null,
                                    endDate: null,
                                    authorizationNumber: null,
                                    nextReviewDate: null,
                                    insuranceCompany: null,
                                    notes: null,
                                    id: null,
                                    chart: {
                                        id: data.chartId
                                    }
                                }
                            },
                            chart: Chart.get({id: data.chartId}).$promise
                        }
                    }).result.then(function () {
                        resolve();
                    });
                } else {
                    toastr.error("The patient does not have any insurance.");
                }
            })
        }
    }
})();

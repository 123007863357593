(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('FacesheetTpl', FacesheetTpl);

    FacesheetTpl.$inject = ['$resource'];

    function FacesheetTpl($resource) {
        var resourceUrl = 'api/facesheet-tpls/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.groupSessionStatuses) {
                            data.groupSessionStatuses = objectToArray(data.groupSessionStatuses);
                        }
                        if (data.evaluationStatuses) {
                            data.evaluationStatuses = objectToArray(data.evaluationStatuses);
                        }
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'allGroupSessionStatus': {url: 'api/facesheet-tpls/groupSessionStatus', method: 'GET', isArray: true},
            'allEvaluationStatus': {url: 'api/facesheet-tpls/evaluationStatus', method: 'GET', isArray: true},
            'filter': {url: 'api/facesheet-tpls/filter', method: 'GET', isArray: true}
        });

        function objectToArray(inArray) {
            var toArray = [];

            for (var i = 0; i < inArray.length; i++) {
                toArray.push(inArray[i].status);
            }

            return toArray;
        }
    }
})();

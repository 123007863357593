(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('patient-medication-mars', {
                parent: 'entity',
                url: '/pm-mars',
                data: {
                    authorities: [],
                    pageTitle: 'MARs-MORs'
                },
                ncyBreadcrumb: {
                    label: 'MARs'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-medication/mars/list/patient-medication-mars-new.html',
                        controller: 'PatientMedicationMarsNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: ['CoreService', function (CoreService) {
                        // var startDate = new Date();
                        // startDate.setHours(startDate.getHours() - 2);
                        //
                        // var endDate = new Date();
                        // endDate.setHours(endDate.getHours() + 3);

                        return {
                            patientId: null,
                            dob: null,
                            levelOfCare: null,
                            medication: null,
                            frequency: null,
                            physician: null,
                            // startDate: startDate,
                            // endDate: endDate,
                            facilityId: CoreService.getCurrentFacility().id,
                            filterStatus: CoreService.getPatientMedicationFilterStatus().CURRENT,
                            page: 1,
                            size: 10,
                            searchQuery: null
                        };
                    }]
                }
            })
            .state('patient-medication-admission', {
                parent: 'entity',
                url: '/medications-admission',
                data: {
                    authorities: [ROLES.ROLE_USER],
                    pageTitle: 'Admission Medications'
                },
                ncyBreadcrumb: {
                    label: 'Admission Medications'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/medications/medications-admission.html',
                        controller: 'PatientMedicationsAdmissionController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GreetingDialogController', GreetingDialogController);

    GreetingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Greeting', 'Facility'];

    function GreetingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Greeting, Facility) {
        var vm = this;

        vm.greeting = entity;

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.greeting.id !== null) {
                Greeting.update(vm.greeting, onSaveSuccess, onSaveError);
            } else {
                Greeting.save(vm.greeting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:greetingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabCompendiumCommentsController', LabCompendiumCommentsController);

    LabCompendiumCommentsController.$inject = ['$compile', '$scope', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter',
        '$uibModalInstance', 'labCompendiumId', 'LabCompendiumComment', '$rootScope', '$uibModal'];

    function LabCompendiumCommentsController($compile, $scope, $q, DTColumnBuilder, DTOptionsBuilder, $filter,
                                             $uibModalInstance, labCompendiumId, LabCompendiumComment, $rootScope, $uibModal) {
        var vm = this;

        vm.labCompendiumComments = [];
        vm.dtInstance = {};

        vm.edit = edit;
        vm.del = del;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            LabCompendiumComment.findByLabCompendium({labCompendiumId: labCompendiumId}, function (result) {
                vm.labCompendiumComments = result;
                defer.resolve(vm.labCompendiumComments);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('ID').renderWith(function (data, type, full) {
                return data ? data.id : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Comment').renderWith(function (data, type, full) {
                return data ? data.comment.substring(0, 75) + "..." : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Facility').renderWith(function (data, type, full) {
                return data ? data.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="dt-btn warning" ng-click="vm.edit(' + data.id + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            stButtons += '<a class="dt-btn danger" ng-click="vm.del(' + data.id + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/lab-compendium-comment/update/lab-compendium-comment-dialog.html',
                controller: 'LabCompendiumCommentController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['LabCompendiumComment', function (LabCompendiumComment) {
                        return LabCompendiumComment.get({id: id}).$promise
                    }]
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            });
        }

        function del(id) {
            $uibModal.open({
                templateUrl: 'app/entities/glucose/glucose-delete-dialog.html',
                controller: 'GlucoseDeleteController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['Glucose', function (Glucose) {
                        return Glucose.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            });
        }
    }
})();

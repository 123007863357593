(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartsCaseLoadController', ChartsCaseLoadController);

    ChartsCaseLoadController.$inject = ['$uibModal', 'ChartCareTeam', '$scope', '$q', 'facility', 'CoreService',
        'Employee', '$state', 'employee', 'Chart', '$rootScope', 'GenericEntityDatatableService',
        'TypeLevelCare'];

    function ChartsCaseLoadController($uibModal, ChartCareTeam, $scope, $q, facility, CoreService,
                                      Employee, $state, employee, Chart, $rootScope, GenericEntityDatatableService,
                                      TypeLevelCare) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Patient by Care Team',
            entityClassHumanized: 'Patient by Care Team',
            entityStateName: 'charts',
            service: ChartCareTeam,
            serviceMethod: "getAllByFilter",
            params: params,
            pagination: 'remote',
            newAction: false,
            selectionColumn: 'chartId',
            options: [
                {key: 'aaSorting', value: [[0, 'desc']]}
            ],
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    },
                    options: [{key: "width", value: "200px"}]
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY")
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: "width", value: "130px"}]
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return moment(data.admissionDate).format("MM/DD/YYYY");
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: 'Treatment Days',
                    render: function (data) {
                        return status == "ARCHIVE" || status == "CLOSED" ?
                            moment(data.dischargeDate).diff(moment(data.admissionDate), 'days') :
                            moment(new Date()).diff(moment(data.admissionDate), 'days');
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    title: 'Referral',
                    render: function (data) {
                        return data.referrer ? data.referrer : "-";
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.chartId});
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.chartId});
                    },
                })
            ],
            filters: [
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: "employeeId",
                    read: function (filter) {
                        return filter.employeeId ? filter.employeeId.id : null;
                    },
                    values: [],
                    placeholder: 'Select employee...',
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        var filter = {page: 0, size: 20, query: search, facilityId: CoreService.getCurrentFacility().id};

                        Employee.findAllByFilter(filter, function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    type: 'select',
                    defaultValue: function () {
                        return employee;
                    }
                },
                {
                    name: 'typeLevelOfCareId',
                    read: function (filter) {
                        return filter.typeLevelOfCareId ? filter.typeLevelOfCareId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeLevelCare.findAllByFacility(
                            CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Level of Care ...',
                    type: 'select',
                },
                {
                    name: 'referral',
                    type: 'string',
                    placeholder: 'Referral Source...',
                },
                {
                    name: 'fromAdmissionDate',
                    // title: 'Day of Birth',
                    placeholder: 'From Admission Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                },
                {
                    name: 'toAdmissionDate',
                    // title: 'Day of Birth',
                    placeholder: 'To Admission Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                }
            ]
        }
    }
})();

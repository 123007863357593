/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EmptyCareDeliveryBoxController', EmptyCareDeliveryBoxController);

    EmptyCareDeliveryBoxController.$inject = ['DashboardService', 'CoreService'];

    function EmptyCareDeliveryBoxController(DashboardService, CoreService) {

        var vm = this;

        vm.count = 0;
        vm.facility = CoreService.getCurrentFacility();

        DashboardService.countAllByFilter({
            facilityId: vm.facility.id,
            empty: true,
            status: ['Pending', 'Late']
        }, function (data) {
            if (data.count) {
                vm.count = data.count;
            }
        });
    }
})();

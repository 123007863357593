(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartCareTeamDialogController', ChartCareTeamDialogController);

    ChartCareTeamDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'ChartCareTeam', 'entity',
        'TypeSpeciality', 'Employee', 'CoreService'];

    function ChartCareTeamDialogController($timeout, $scope, $uibModalInstance, ChartCareTeam, entity,
                                           TypeSpeciality, Employee, CoreService) {
        var vm = this;

        vm.charCareTeam = entity;
        vm.chart = entity.chart;
        vm.typeSpecialities = TypeSpeciality.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.careEmployees = [];
        vm.form = {};

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};
        vm.corporation = CoreService.getCorporation();
        vm.showDelete = false;

        vm.getCareTeamEmployee = getCareTeamEmployee;
        vm.clear = clear;
        vm.save = save;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.confirmDelete = confirmDelete;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function getCareTeamEmployee(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.careEmployees = result;
                });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.charCareTeam.id !== null) {
                ChartCareTeam.update(vm.charCareTeam, onSaveSuccess, onSaveError);
            } else {
                ChartCareTeam.save(vm.charCareTeam, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:charCareTeamUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.charCareTeam.startDate;
        }

        function validateStartDate(){
            vm.startDateOptions.maxDate = vm.charCareTeam.endDate;
        }

        function confirmDelete () {
            ChartCareTeam.delete({id: vm.charCareTeam.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

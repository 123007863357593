(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidPhysicianDeleteController',CovidPhysicianDeleteController);

    CovidPhysicianDeleteController.$inject = ['$uibModalInstance', 'entity', 'CovidPhysician'];

    function CovidPhysicianDeleteController($uibModalInstance, entity, CovidPhysician) {
        var vm = this;

        vm.covidPhysician = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CovidPhysician.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TrackDialogController', TrackDialogController);

    TrackDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Track', 'Facility', 'GroupSessionTemplate', 'CoreService'];

    function TrackDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                    Track, Facility, GroupSessionTemplate, CoreService ) {
        var vm = this;

        vm.track = entity;
        vm.groupSessionTemplates = GroupSessionTemplate.byFacility({id: CoreService.getCurrentFacility().id});
        vm.isSaving = false;

        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if (vm.track.id !== null) {
                Track.update(vm.track, onSaveSuccess, onSaveError);
            } else {
                Track.save(vm.track, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:trackUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationController', EvaluationController);

    EvaluationController.$inject = ['$uibModal', 'TypePatientProcess', 'CoreService', 'facility', 'Evaluation',
        'chart', 'AlertService', 'toastr', '$state', 'ConfirmationService', 'Auth',
        'params', '$location', 'access', 'ACTIONS', '$rootScope', '$scope', '$uibModalStack', 'TAB'];

    function EvaluationController($uibModal, TypePatientProcess, CoreService, facility, Evaluation,
                                  chart, AlertService, toastr, $state, ConfirmationService, Auth,
                                  params, $location, access, ACTIONS, $rootScope, $scope, $uibModalStack, TAB) {
        var vm = this;

        vm.itemsPerPage = 15;
        vm.patientProcesses = [];
        vm.activeTab = 0;
        vm.chart = chart;
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.EVALUATION);
        vm.access = access;

        vm.masterEdit = access[ACTIONS.ACTION_MASTER_EDIT] ? access[ACTIONS.ACTION_MASTER_EDIT].roles.join() : null;
        vm.accessDeleteCareDelivery = access[ACTIONS.ACTION_DELETE_CARE_DELIVERY] ? access[ACTIONS.ACTION_DELETE_CARE_DELIVERY].roles.join() : null;

        vm.toggleItem = toggleItem;
        vm.transition = transition;
        vm.amended = amended;
        vm.getStatusClass = getStatusClass;
        vm.select = select;
        vm.assign = assign;
        vm.del = del;
        vm.edit = edit;
        vm.loadCareAndDelivery = loadCareAndDelivery;
        vm.openConsentBoost = openConsentBoost;
        vm.search = search;
        vm.assignNew = assignNew;
        vm.generateConsent = generateConsent;
        vm.detail = detail;
        vm.history = history;
        vm.review = review;
        vm.audit = audit;

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_EVALUATION_VIEW, access) || !vm.active) {
                return;
            }

            TypePatientProcess.byFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (data) {
                vm.patientProcesses = _.sortBy(data, ["xrder", "name"]);

                _.forEach(vm.patientProcesses, function (process, key) {
                    process.page = 1;
                    process.loaded = false;
                });

                if (vm.patientProcesses.length > 0) {
                    if (!params || !params.processId) {
                        transition(vm.patientProcesses[0]);
                    } else {
                        var index = _.findIndex(vm.patientProcesses, function (patientProcess) {
                            return patientProcess.id == parseInt(params.processId);
                        });

                        if (index >= 0) {
                            vm.patientProcesses[index].page = params && params.processPage ? parseInt(params.processPage) : 1;
                            //the dual binding of this var trigger the load event :)
                            vm.activeTab = index;
                        }
                    }
                }
            });
        }

        function loadCareAndDelivery(process) {
            Evaluation.assignAutomaticsEvaluationsByPatientProcess({
                chartId: vm.chart.id,
                typePatientProcessId: process.id
            }, function (data) {
                if (data.count > 0) {
                    toastr.success('Care and Delivery automatics have been assigned');
                } else {
                    toastr.warning('There is no Care & Delivery to preload under this patient process');
                }

                process.page = 1;
                transition(process);

            }, function () {
                toastr.error('Care and Delivery automatics can not be assigned');

                process.page = 1;
                transition(process);
            });
        }

        function toggleItem() {
            CoreService.togglePanel('evaluation');
        }

        function transition(process) {
            $location.search('processId', process.id);
            $location.search('processPage', process.page);

            Evaluation.findAllByChartAndPatientProcess({
                chartId: chart.id,
                processId: process.id,
                searchQuery: vm.searchQuery,
                page: process.page - 1,
                size: vm.itemsPerPage,
                deleted: false
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                process.totalItems = headers('X-Total-Count');
                process.evaluations = data;
                process.loaded = true;

                _.forEach(process.evaluations, function (evaluation, key) {
                    evaluation.signed =
                        evaluation.chartSignatureId != null ||
                        evaluation.guarantorSignatureId != null ||
                        evaluation.staffSignatureId != null ||
                        evaluation.staffReviewSignatureId != null;
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getStatusClass(evaluation) {
            return CoreService.getEvaluationStatusClass(evaluation.status);
        }

        function select(process) {
            transition(process);
        }

        function assign(process) {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-template/assign-chart/old/evaluation-template-assign-dialog.html',
                controller: 'EvaluationTemplateAssignDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    typePatientProcess: process,
                    chart: chart
                }
            }).result.then(function () {
                process.page = 1;
                transition(process);
            }, function (result) {
            });
        }

        function del(process, evaluation) {
            ConfirmationService.showDelete('Evaluation').result.then(function () {
                Evaluation.delete({id: evaluation.id}, function () {
                    transition(process);
                });
            })
        }

        function amended(process, evaluation) {
            ConfirmationService.open({
                title: 'Confirm amended operation',
                message: 'Are you sure you want to <strong>AMENDED</strong> this Evaluation?',
                actions: [
                    {
                        name: 'Amended',
                        color: 'btn btn-success',
                        icon: 'fa fa-check-circle',
                        value: 'SUCCESS'
                    },
                    {
                        name: 'Cancel',
                        color: 'btn btn-danger',
                        icon: 'fa fa-ban',
                        value: 'CANCEL'
                    }
                ]
            }).result.then(function () {
                Evaluation.amended({id: evaluation.id}, function () {
                    transition(process);
                })
            });
        }

        function openConsentBoost(process) {
            $state.go('consent-boost', {
                chart: vm.chart,
                process: process,
                chartId: vm.chart.id,
                processId: process.id
            });
        }

        function edit(process, evaluation) {
            $state.go('care-delivery', {id: evaluation.id, processId: process.id});
        }

        function detail(process, evaluation) {
            $state.go('care-delivery-view', {id: evaluation.id, processId: process.id});
        }

        function search(process) {
            process.page = 1;
            transition(process);
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result == TAB.EVALUATION) {
                vm.active = true;
                loadAll();
            }
        });

        $scope.$on('$destroy', unsubscribe);

        function assignNew(process) {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/evaluation-template/assign-chart/new/evaluation-template-assign-new-dialog.html',
                controller: 'EvaluationTemplateAssignNewDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    typePatientProcess: process,
                    chart: chart
                }
            }).result.then(function () {
                process.page = 1;
                transition(process);
            }, function (result) {
            });
        }

        function generateConsent(process) {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/evaluation/consent-boost/generate-consent/consent-boost-generate-consent-dialog.html',
                controller: 'ConsentBoostGenerateConsentDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    chart: chart,
                    process: process
                }
            }).result.then(function () {
                process.page = 1;
                transition(process);
                $rootScope.$broadcast('bluebookApp:loadContacts');
            }, function (result) {
            });
        }

        function history(process) {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/evaluation/history/evaluation-history.html',
                controller: 'EvaluationHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart,
                    process: process
                }
            }).result.then(function () {
                transition(process);
            }, function () {
                transition(process);
            })
        }

        function audit(evaluation) {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/evaluation/history-audit/evaluation-history-audit.html',
                controller: 'EvaluationHistoryAuditController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    evaluation: evaluation
                }
            });
        }

        function review(process, evaluation) {
            ConfirmationService.open({
                title: 'Confirm review operation',
                message: 'Are you sure you want to <strong>REVIEW</strong> this Evaluation?',
                actions: [
                    {
                        name: 'Review',
                        color: 'btn btn-success',
                        icon: 'fa fa-times-circle-o',
                        value: 'SUCCESS'
                    },
                    {
                        name: 'Cancel',
                        color: 'btn btn-danger',
                        icon: 'fa fa-ban',
                        value: 'CANCEL'
                    }
                ]
            }).result.then(function () {
                Evaluation.review({id: evaluation.id}, function () {
                    transition(process);
                })
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Evaluation', Evaluation);

    Evaluation.$inject = ['$resource', 'Utils', 'OdsFormService', 'DateUtils', 'CoreService'];

    function Evaluation($resource, Utils, OdsFormService, DateUtils, CoreService) {
        var resourceUrl = 'api/evaluations/:id';
        var facility = CoreService.getCurrentFacility();

        function transformResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                //TODO fix in the library
                data.staffSignatureCredentials = Utils.objectRoleToArray(data.staffSignatureCredentials);
                data.staffReviewSignatureCredentials = Utils.objectRoleToArray(data.staffReviewSignatureCredentials);

                if (data.billable) {
                    if (data.startBilling) {
                        data.startBilling = new Date(DateUtils.convertDateTimeFromServer(data.startBilling)
                            .toLocaleString("en-US", {timeZone: facility.timeZone}));
                    }

                    if (data.endBilling) {
                        data.endBilling = new Date(DateUtils.convertDateTimeFromServer(data.endBilling)
                            .toLocaleString("en-US", {timeZone: facility.timeZone}));
                    }
                } else {
                    if (data.dayOfService) {
                        data.dayOfService = new Date(DateUtils.convertLocalDateFromServer(data.dayOfService));
                    }
                }

                if (data.vitals && data.vitals.date) {
                    data.vitals.date = DateUtils.convertDateTimeFromServer(data.vitals.date);
                }
            }

            return data;
        }

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET', transformResponse: transformResponse},
            'start': {method: 'GET', url: 'api/evaluations/start/:id', transformResponse: transformResponse},
            'findAllByChartAndPatientProcess': {
                url: 'api/evaluations/by-chart',
                method: 'GET',
                isArray: true
            },
            'findAllLastMeasurementsByChart': {
                url: 'api/evaluations/mars-mors-measurements-by-chart',
                method: 'GET',
                isArray: true
            },
            'findAllByChartAndContact': {
                url: 'api/evaluations/by-chart/:id/contact/:contact',
                method: 'GET',
                isArray: true
            },
            'assign': {url: 'api/evaluations/assign', method: 'POST'},
            'next': {
                method: 'POST', url: 'api/evaluations/next',
                transformResponse: transformResponse,
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    return angular.toJson(copy);
                }
            },
            'hasNext': {
                method: 'POST', url: 'api/evaluations/has-next',
                transformResponse: function (data) {
                    return {result: angular.fromJson(data)};
                },
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    return angular.toJson(copy);
                }
            },
            'previous': {
                method: 'POST', url: 'api/evaluations/previous',
                transformResponse: transformResponse,
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    return angular.toJson(copy);
                }
            },
            'noSignedPendingOrLate': {
                method: 'GET', url: 'api/evaluations/pending-late/:evaluationId/:typePatientProcessId',
                transformResponse: transformResponse
            },
            'hasPrevious': {
                method: 'POST', url: 'api/evaluations/has-previous',
                transformResponse: function (data) {
                    return {result: angular.fromJson(data)};
                },
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    return angular.toJson(copy);
                }
            },
            'chartSign': {method: 'POST', url: 'api/evaluations/chart-sign', isArray: true},
            'guarantorSign': {
                method: 'POST', url: 'api/evaluations/guarantor-sign',
                transformResponse: transformResponse
            },
            'staffSign': {method: 'POST', url: 'api/evaluations/staff-sign', isArray: true},
            'staffReviewSign': {method: 'POST', url: 'api/evaluations/staff-review-sign', isArray: true},
            'autoSaveLoc': {
                url: 'api/evaluations/auto-save-loc',
                method: 'POST',
                ignoreLoadingBar: true,
                transformResponse: transformResponse
            },
            'autoSaveDates': {
                url: 'api/evaluations/auto-save-dates',
                method: 'POST',
                ignoreLoadingBar: true,
                transformResponse: transformResponse
            },
            'autoSave': {
                url: 'api/evaluations/auto-save',
                method: 'POST',
                ignoreLoadingBar: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);

                    return angular.toJson(copy);
                },
                transformResponse: transformResponse
            },
            'amended': {url: 'api/evaluations/amended/:id', method: 'GET'},
            'review': {url: 'api/evaluations/review/:id', method: 'GET'},
            'revoke': {method: 'POST', url: 'api/evaluations/revoke', isArray: true},
            'getEvaluationTokensValues': {
                url: 'api/evaluations/token-values/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'updateCredentials': {url: 'api/evaluations/update-credentials', method: 'POST'},
            'deleteEmpties': {
                url: 'api/evaluations/delete-empty',
                method: 'POST'
            },
            'findAllByFilter': {
                url: 'api/evaluations/find-all-by-filter',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].createdDate = DateUtils.convertDateTimeFromServer(data[i].createdDate);
                        }
                    }
                    return data;
                }
            },
            'findAllPendingByStaffFilter': {
                url: 'api/evaluations/find-all-pending-by-staff-filter',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].createdDate = DateUtils.convertDateTimeFromServer(data[i].createdDate);
                        }
                    }
                    return data;
                }
            },
            'assignAutomaticsEvaluationsByPatientProcess': {
                url: 'api/evaluations/assign-automatics-evaluations-by-patient-process/:chartId/:typePatientProcessId',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = {count: angular.fromJson(data)};
                    }
                    return data;
                }
            },
            'masterAutoSave': {
                url: 'api/evaluations/master-auto-save',
                method: 'POST',
                ignoreLoadingBar: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);

                    return angular.toJson(copy);
                },
                transformResponse: transformResponse
            },
            'masterSignature': {
                url: 'api/evaluations/master-signature',
                method: 'POST',
                ignoreLoadingBar: true
            },
            'findAllChartAndMars': {
                url: 'api/evaluations/by-chart/mars/:id', method: 'GET', isArray: true
            },
            'findAllPendingByIdAndPatientProcess': {
                url: 'api/evaluations/pendings-by-id-patient-process',
                method: 'GET',
                isArray: true
            },
            'assignConsentToContacts': {
                url: 'api/evaluations/assign-consent-to-contacts',
                method: 'POST',
                isArray: true
            },
            'fax': {
                url: 'api/evaluations/fax',
                method: 'POST',
            },
            'getJsonTemplate': {
                url: 'api/evaluations/get-json-template/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = OdsFormService.convertFormSchemaFromServer(data);
                    }

                    return data;
                },
            },
            'retrieve': {url: 'api/evaluations/retrieve/:id', method: 'GET'},
            'restart': {url: 'api/evaluations/restart/:id', method: 'GET'},
            'getHistory': {
                url: 'api/evaluation-histories/history/:id',
                method: 'GET',
                isArray: true
            },
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AlcoholChartController', AlcoholChartController);

    AlcoholChartController.$inject = ['chart', 'Alcohol', '$uibModal', 'access', 'ACTIONS', '$rootScope', '$scope', 'Auth'];

    function AlcoholChartController(chart, Alcohol, $uibModal, access, ACTIONS, $rootScope, $scope, Auth) {
        var vm = this;
        vm.chart = chart;

        vm.add = add;
        vm.history = history;
        vm.loadAll = loadAll;

        vm.getAlcoholGraphOpt = getAlcoholGraphOpt;
        vm.dataAlcohol = [];

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW, access)) {
                return;
            }
            vm.alcohol = Alcohol.findLastByChartId({id: chart.id});
            getAlcoholData();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/alcohol/update/alcohol-dialog.html',
                controller: 'AlcoholDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            status: null,
                            id: null,
                            chart: chart,
                            date: new Date()
                        };
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/alcohol/history/alcohol.html',
                controller: 'AlcoholController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        getAlcoholGraphOpt();

        function getAlcoholGraphOpt() {
            vm.alcoholGraphOpt = {
                chart: {
                    type: 'lineChart',
                    showLegend: false,
                    height: 340,
                    showXAxis: false,
                    margin: {
                        top: 20,
                        right: 50,
                        bottom: 100,
                        left: 50
                    },
                    x: function (d) {
                        return d.x;
                    },
                    y: function (d) {
                        return d.y;
                    },
                    useInteractiveGuideline: true,
                    xAxis: {
                        tickFormat: function (d) {
                            return d3.time.format('%m/%d/%y-%H:%M')(new Date(d));
                        },
                        rotateLabels: (-70)
                    },
                    yAxis: {
                        tickFormat: function (d) {
                            return d3.format('.02f')(d);
                        },
                        showMaxMin: false
                    }
                }
            };
        }

        function getAlcoholData() {
            vm.alcohol = Alcohol.findLastByChartId({id: chart.id});

            Alcohol.findLastByChartById({id: chart.id, size: 10}, function (data) {
                vm.dataAlcohol = [];

                vm.valueData = {
                    values: [],
                    key: 'value',
                    color: '#000000'
                };

                for (var i = data.length - 1; i >= 0; i--) {
                    vm.valueData.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value1)});
                }

                vm.dataAlcohol.push(vm.valueData);
            });
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartAlcoholHistory', function (event, result) {
            history();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxScheduleController', VerifytxScheduleController);

    VerifytxScheduleController.$inject = ['GenericEntityDatatableService', 'CoreService', 'VerifytxSchedule', '$q', '$uibModal',
        'GUIUtils', 'Verifytx', 'toastr', 'Principal', 'ROLES', 'ConfirmationService'];

    function VerifytxScheduleController(GenericEntityDatatableService, CoreService, VerifytxSchedule, $q,
                                        $uibModal, GUIUtils, Verifytx, toastr, Principal, ROLES, ConfirmationService) {
        var vm = this;

        vm.totalItems = 0;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'VerifyTX Schedule',
            newAction: false,
            entityClassHumanized: 'VerifyTX Schedule',
            entityStateName: 'verifytx-schedule',
            service: VerifytxSchedule,
            serviceMethod: 'getAllByCorporation',
            params: params,
            columns: [
                {
                    name: null,
                    title: 'Schedule',
                    render: function (data) {
                        return GUIUtils.weekCodeStringToDays(data.schedule);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New VerifyTX Schedule',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0;
                            }
                        })
                    ]
                },
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getSendAction(), {
                            name: 'Send verifyTX emails',
                            action: function () {
                                sendVobsByEmail();
                            },
                            ngIf: function () {
                                return Principal.hasAnyAuthority([ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN]);
                            }
                        })
                    ]
                }
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/verifytx-schedule/update/verifytx-schedule-dialog.html',
                    controller: 'VerifytxScheduleDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                id: null,
                                schedule: '0000000',
                                corporation: CoreService.getCorporation()
                            };
                        }]
                    }
                }).result.then(function () {
                    toastr.success('VerifyTX Schedule was saved', 'Success');

                    resolve();
                });
            })
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/verifytx-schedule/update/verifytx-schedule-dialog.html',
                    controller: 'VerifytxScheduleDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['VerifytxSchedule', function (VerifytxSchedule) {
                            return VerifytxSchedule.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    toastr.success('VerifyTX Schedule was saved', 'Success');

                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Verifytx Schedule').result.then(function () {
                    VerifytxSchedule.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function sendVobsByEmail() {
            Verifytx.sendVobsByEmail({facilityId: CoreService.getCurrentFacility().id});
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AbnormalAlertController', AbnormalAlertController);

    AbnormalAlertController.$inject = ['GenericEntityDatatableService', 'CoreService', 'AbnormalAlert', 'PopupService',
        '$q', '$uibModal', 'ConfirmationService'];

    function AbnormalAlertController(GenericEntityDatatableService, CoreService, AbnormalAlert, PopupService,
                                     $q, $uibModal, ConfirmationService) {
        var vm = this;

        vm.laboratory = null;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Abnormal Alerts',
            newAction: false,
            entityClassHumanized: 'abnormal-alert',
            entityStateName: 'abnormal-alert',
            service: AbnormalAlert,
            serviceMethod: 'filter',
            pagination: 'remote',
            params: params,
            options: [{key: 'aaSorting', value: [[1, 'asc']]}],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: null,
                    title: 'Test Code',
                    render: function (data) {
                        return data.labCompendium == null ? " " : data.labCompendium.code;
                    }
                },
                {
                    name: null,
                    title: 'Description',
                    render: function (data) {
                        return data.labCompendium == null ? " " : data.labCompendium.description;
                    }
                },
                {
                    title: 'Laboratory',
                    render: function () {
                        return vm.laboratory.name;
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('abnormal-alert'), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Abnormal Alert',
                            action: function () {
                                return add();
                            },
                            disabled: function () {
                                return vm.laboratory === null;
                            }
                        })
                    ]
                }
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Laboratory',
                            action: function (filter) {
                                return $q(function (resolve) {
                                    PopupService.showSelectFacilityLabPopup(filter).result.then(function (result) {
                                        filter.laboratoryId = {id: result.labId, name: result.name};

                                        vm.laboratory = {id: result.labId, name: result.name};
                                        vm.descriptor.title = 'Abnormal Alerts - ' + vm.laboratory.name

                                        resolve();
                                    });
                                })
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'laboratoryId',
                    visible: false,
                    read: function (filter) {
                        return filter.laboratoryId ? filter.laboratoryId.id : null;
                    }
                }
            ],
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/abnormal-alert/update/abnormal-alert-dialog.html',
                    controller: 'AbnormalAlertDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                id: null,
                                facility: CoreService.getCurrentFacility(),
                                laboratory: vm.laboratory
                            };
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Abnormal Alert').result.then(function () {
                    AbnormalAlert.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Tube', Tube);

    Tube.$inject = ['$resource'];

    function Tube ($resource) {
        var resourceUrl =  'api/tubes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByLaboratory': { url: 'api/tubes/by-laboratory', method: 'GET', isArray: true }
        });
    }
})();

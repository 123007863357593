(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientOrderTest', PatientOrderTest);

    PatientOrderTest.$inject = ['$resource', 'DateUtils'];

    function PatientOrderTest ($resource, DateUtils) {
        var resourceUrl =  'api/patient-order-tests/:id';

        return $resource(resourceUrl, {}, {
            'findAllPatientOrderTestByPatientOrder': { 'url': 'api/patient-order-tests/by-patient-order-id/:id', method: 'GET', isArray: true},
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('care-coordination-template', {
                parent: 'entity',
                url: '/care-coordination-template',
                data: {
                    authorities: [],
                    pageTitle: 'CareCoordinationTemplates'
                },
                ncyBreadcrumb: {
                    label: 'Care Coordination Templates'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/care-coordination-template/list/care-coordination-template.html',
                        controller: 'CareCoordinationTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_COORDINATION];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_COORDINATION, access);
                }]
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SignatureDialogController', SignatureDialogController);

    SignatureDialogController.$inject = ['$uibModalInstance', 'CoreService'];

    function SignatureDialogController($uibModalInstance, CoreService) {

        var vm = this;

        vm.employeeSignature = '';
        vm.employeeSignaturePin = null;
        vm.signatureOpts = getSignatureOptions();
        vm.employee = CoreService.getCurrentEmployee();

        vm.clear = clear;
        vm.sign = sign;

        function getSignatureOptions() {

            return {
                width: 620,
                height: 250,
                cssClass: 'signature-canvas',
                color: '#00008B',
                'background-color': '#fff'
            };
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function sign() {
            $uibModalInstance.close({employeeSignature: vm.employeeSignature, employeeSignaturePin: vm.employeeSignaturePin});
        }
    }
})();

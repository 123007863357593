(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Drug', Drug);

    Drug.$inject = ['$resource', 'DateUtils'];

    function Drug ($resource, DateUtils) {
        var resourceUrl =  'api/drugs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.time = DateUtils.convertDateTimeFromServer(data.time);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findLastByChart': {url: 'api/drug/last/by-chart/:id', method: 'GET'},
            'findAllByChart': {url: 'api/drug/by-chart/:id',
                method: 'GET',
                isArray: true
            }
        });
    }
})();

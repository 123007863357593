(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MedicationController', MedicationController);

    MedicationController.$inject = ['$rootScope', 'Medication', 'GenericEntityDatatableService', '$q',
        '$uibModal', 'ConfirmationService']

    function MedicationController($rootScope, Medication, GenericEntityDatatableService, $q,
                                  $uibModal, ConfirmationService) {
        var vm = this;

        var entityStateName = 'medication'

        vm.descriptor = {
            title: 'Medications',
            newButtonTitle: 'New Medication',
            entityClassHumanized: 'Medication',
            entityStateName: entityStateName,
            service: Medication,
            serviceMethod: 'findAllByFilter',
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'createdBy',
                    title: 'Created By'
                },
                {
                    title: 'Created Date',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Medication',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/medication/update/medication-dialog.html',
                controller: 'MedicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Medication').result.then(function () {
                    Medication.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

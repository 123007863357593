(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacesheetTplController', FacesheetTplController);

    FacesheetTplController.$inject = ['GenericEntityDatatableService', 'CoreService', 'FacesheetTpl', 'GUIUtils',
        '$uibModal', 'ConfirmationService', '$q'];

    function FacesheetTplController(GenericEntityDatatableService, CoreService, FacesheetTpl, GUIUtils,
                                    $uibModal, ConfirmationService, $q) {

        var vm = this;

        var params = {facilityId: CoreService.getCurrentFacility().id};

        vm.descriptor = {
            title: 'Facesheets',
            newButtonTitle: 'New Facesheet',
            entityClassHumanized: 'Facesheets',
            entityStateName: 'facesheet-tpl',
            service: FacesheetTpl,
            serviceMethod: 'filter',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name',

                },
                {
                    name: null,
                    title: 'Enable',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.enable);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Facesheet',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facesheet-tpl/update/facesheet-tpl-dialog.html',
                controller: 'FacesheetTplDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            groupSessionStatuses: null,
                            evaluationStatuses: null,
                            facility: CoreService.getCurrentFacility(),
                            name: null,
                            enable: true,
                            id: null
                        };
                    }]
                }
            }).result;;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/facesheet-tpl/update/facesheet-tpl-dialog.html',
                controller: 'FacesheetTplDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['FacesheetTpl', function (FacesheetTpl) {
                        return FacesheetTpl.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Facesheet').result.then(function () {
                    FacesheetTpl.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

/**
 * Created by jesusrodriguez on 2/24/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DischargingPatientsBoxController', DischargingPatientsBoxController);

    DischargingPatientsBoxController.$inject = ['facility', 'Chart', '$state', 'AlertService'];

    function DischargingPatientsBoxController(facility, Chart, $state, AlertService) {
        var vm = this;

        vm.filter = {};
        vm.charts = [];
        vm.facility = facility;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.queryCount = 0;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.dischargeTo = dischargeTo;
        vm.referral = referral;
        vm.openChart = openChart;
        vm.searchByQuery = searchByQuery;

        loadAll();

        function loadAll() {
            Chart.discharging(getFilter(), onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.charts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function getFilter() {
            var filter = {};
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.facilityId = vm.facility.id;
            filter.searchQuery = vm.searchQuery;
            filter.sort = ["dischargeDate,asc", "firstName,asc"];

            return filter;
        }

        function searchByQuery() {
            vm.page = 1;
            vm.filter.status = null;
            vm.transition();
        }

        function openChart(chartId) {
            $state.go('chart', {id: chartId});
        }


        function dischargeTo(data) {
            if (data && data.length > 0) {
                return data && data.length > 15 ? data.substr(0, 15) + "..." : data;
            } else {
                return "-";
            }
        }

        function referral(data) {
            if (data && data.length > 0) {
                return data && data.length > 15 ? data.substr(0, 15) + "..." : data;
            } else {
                return "-";
            }
        }
    }
})();

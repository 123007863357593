(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabRequisitionConsentController', LabRequisitionConsentController);

    LabRequisitionConsentController.$inject = ['$scope', '$state', 'LabRequisitionConsent', 'CoreService',
        'GenericEntityDatatableService', '$uibModal', 'ConfirmationService', '$q'];

    function LabRequisitionConsentController($scope, $state, LabRequisitionConsent, CoreService,
                                             GenericEntityDatatableService, $uibModal, ConfirmationService, $q) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Lab Requisition Consents',
            newButtonTitle: 'New Lab Requisition Consent',
            entityClassHumanized: 'Lab Requisition Consents',
            entityStateName: 'lab-requisition-consent',
            service: LabRequisitionConsent,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'laboratory',
                    title: 'Laboratory',
                },
                {
                    name: 'consentTitle',
                    title: 'Consent Title',
                },
                {
                    name: 'consent',
                    title: 'Consent',
                },
                {
                    name: 'notice',
                    title: 'Notice',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Consent',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/lab-requisition-consent/update/lab-requisition-consent-dialog.html',
                controller: 'LabRequisitionConsentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            consentTitle: null,
                            consent: null,
                            notice: null,
                            id: null
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/lab-requisition-consent/update/lab-requisition-consent-dialog.html',
                controller: 'LabRequisitionConsentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    entity: ['LabRequisitionConsent', function (LabRequisitionConsent) {
                        return LabRequisitionConsent.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Lab Requisition Consent').result.then(function () {
                    LabRequisitionConsent.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ChartNote', ChartNote);

    ChartNote.$inject = ['$resource', 'DateUtils'];

    function ChartNote ($resource, DateUtils) {
        var resourceUrl =  'api/chart-notes/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByChart': {url: 'api/chart-notes/by-chart/:id', method: 'GET', isArray: true},
            'findAllByChartInactive': {url: 'api/chart-notes/by-chart/:id/inactive', method: 'GET', isArray: true},
            'findAllByFilter' : {url: 'api/chart-notes/by-chart', method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionTemplateController', GroupSessionTemplateController);

    GroupSessionTemplateController.$inject = ['$rootScope', 'GroupSessionTemplate', 'CoreService', 'GUIUtils',
        'GenericEntityDatatableService', 'ConfirmationService', '$q', '$uibModal'];

    function GroupSessionTemplateController($rootScope, GroupSessionTemplate, CoreService, GUIUtils,
                                            GenericEntityDatatableService, ConfirmationService, $q, $uibModal) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Group Sessions',
            entityClassHumanized: 'Group Sessions',
            entityStateName: 'group-session-template',
            service: GroupSessionTemplate,
            serviceMethod: 'filter',
            newButtonTitle: 'New Group Session',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'title',
                    title: 'Title'
                },
                {
                    name: 'daysWeek',
                    title: 'Days of Week',
                    render: function (daysWeek) {
                        return daysWeek ? GUIUtils.expandWeekCodeString(daysWeek, ',') : "-";
                    }
                },
                {
                    name: 'start',
                    title: 'Start Time',
                    render: function (start) {
                        return start ? moment(start).format($rootScope.timeFormatA) : "-";
                    }
                },
                {
                    name: 'stop',
                    title: 'End Time',
                    render: function (stop) {
                        return stop ? moment(stop).format($rootScope.timeFormatA) : "-";
                    }
                },
                {
                    name: 'enabled',
                    title: 'Enabled',
                    render: function (enabled) {
                        return GUIUtils.colorHtmlYesNo(!!enabled);
                    }
                },
                {
                    name: 'billable',
                    title: 'Billable',
                    render: function (billable) {
                        return GUIUtils.colorHtmlYesNo(!!billable);
                    }
                }

            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Group Session',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/group-session-template/update/group-session-template-dialog.html',
                controller: 'GroupSessionTemplateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            title: null,
                            description: null,
                            start: null,
                            stop: null,
                            ageBegin: null,
                            ageEnd: null,
                            enabled: true,
                            billable: false,
                            daysWeek: null,
                            id: null
                        };
                    }],
                    filter: ['CoreService', function (CoreService) {
                        var facility = CoreService.getCurrentFacility();
                        return {
                            facilityId: facility.id
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/group-session-template/update/group-session-template-dialog.html',
                controller: 'GroupSessionTemplateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['GroupSessionTemplate', function (GroupSessionTemplate) {
                        return GroupSessionTemplate.get({id: id}).$promise;
                    }],
                    filter: ['CoreService', function (CoreService) {
                        var facility = CoreService.getCurrentFacility();
                        return {
                            facilityId: facility.id
                        };
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('GroupSession Template').result.then(function () {
                    GroupSessionTemplate.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOpportunityDetailController', CrmOpportunityDetailController);

    CrmOpportunityDetailController.$inject = ['$uibModal', 'entity', '$state', 'CrmOutboundReferral'];

    function CrmOpportunityDetailController($uibModal, entity, $state, CrmOutboundReferral) {
        var vm = this;

        vm.crmOpportunity = entity;
        vm.crmOutboundReferral = {};
        vm.floatingMenuStatus = false;
        vm.toggleFloatingMenu = toggleFloatingMenu;

        init();

        function init() {
            vm.title = 'Opportunity Details';
            vm.confirmDelete = false;

            vm.address = vm.crmOpportunity.address ? vm.crmOpportunity.address : "";
            vm.address += vm.crmOpportunity.address2 ? " " + vm.crmOpportunity.address2 : "";
            vm.address += vm.crmOpportunity.city ? ", " + vm.crmOpportunity.city : "";
            vm.address += vm.crmOpportunity.state ? ", " + vm.crmOpportunity.state.stateCode : "";
            vm.address += vm.crmOpportunity.zipCode ? " " + vm.crmOpportunity.zipCode : "";

            vm.crmOutboundReferral = CrmOutboundReferral.findByOpportunity({id: vm.crmOpportunity.id});
        }

        vm.openModalOutboundReferral = openModalOutboundReferral;
        vm.openModalOpportunityPrivatePay = openModalOpportunityPrivatePay;
        vm.openModalDelete = openModalDelete;
        vm.openModalNewNote = openModalNewNote;
        vm.openModalNewTask = openModalNewTask;
        vm.openModalEmailLog = openModalEmailLog;
        vm.openModalCallLog = openModalCallLog;
        vm.openModalTravel = openModalTravel;
        vm.openModalPaymentPlan = openModalPaymentPlan;
        vm.openModalEditOpportunity = openModalEditOpportunity;

        function openModalOutboundReferral() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-outbound-referral/crm-outbound-referral-dialog.html',
                controller: 'CrmOutboundReferralDialogController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CrmOutboundReferral', 'CoreService', function (CrmOutboundReferral, CoreService) {
                        return vm.crmOutboundReferral.id ?
                            CrmOutboundReferral.findByOpportunity({id: vm.crmOpportunity.id}) : {
                                name: null,
                                id: null,
                                doa: null,
                                corporation: CoreService.getCorporation(),
                                crmReferralSource: null,
                                crmReferralContact: null,
                                marketer: null,
                                status: null,
                                crmOpportunity: vm.crmOpportunity
                            };
                    }]
                }
            }).result.then(function () {
                vm.crmOutboundReferral = CrmOutboundReferral.findByOpportunity({id: vm.crmOpportunity.id});
            }, function () {
            });
        }

        function toggleFloatingMenu($event) {
            var btn = angular.element($event.currentTarget);

            vm.floatingMenuStatus = !vm.floatingMenuStatus;
            btn.next().slideToggle();
        }

        function openModalOpportunityPrivatePay() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-opportunity/crm-opportunity-private-pay-dialog.html',
                controller: 'CrmOpportunityPrivatePayDialogController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalDelete() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/delete-activity-dialog.html',
                controller: 'ActivitiesDeleteDialogController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalNewNote() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/new-note-dialog.html',
                controller: 'ActivitiesNewNoteDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalNewTask() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/new-task-dialog.html',
                controller: 'ActivitiesNewTaskDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalEmailLog() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/log-email-dialog.html',
                controller: 'ActivitiesLogEmailDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalCallLog() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/log-calls-dialog.html',
                controller: 'ActivitiesLogEmailDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalTravel() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/travel-dialog.html',
                controller: 'ActivitiesNewTravelDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalPaymentPlan() {
            $uibModal.open({
                templateUrl: 'app/entities/opportunities/activities/payment-plan-dialog.html',
                controller: 'ActivitiesPaymentPlanDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function openModalEditOpportunity() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-opportunity/crm-opportunity-new-dialog.html',
                controller: 'CrmOpportunityNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CrmOpportunity', function (CrmOpportunity) {
                        return CrmOpportunity.get({id: vm.crmOpportunity.id}).$promise;
                    }]
                }
            }).result.then(function () {
                $state.reload();
            }, function () {
            });
        }


        /*### - Google Charts Stuff - ###*/

        google.load('visualization', '1', {packages: ['controls', 'corechart']});
        google.setOnLoadCallback(drawChart);

        var dash,chart,data, control;

        vm.rangeStart = new Date('May 1, 2016');
        vm.rangeEnd = new Date('October 1, 2016');

        vm.updateGChart = updateGChart;

        function drawChart() {
            data = new google.visualization.DataTable();


            data.addColumn({type:'date', label:'Date'});
            data.addColumn({type:'timeofday', label:'Info'});
            data.addColumn({type:'string', role:'annotation'});
            data.addColumn({type:'string', role:'annotationText'});
            data.addColumn({type:'timeofday', label:'Document'});
            data.addColumn({type:'string', role:'annotation'});
            data.addColumn({type:'string', role:'annotationText'});
            data.addColumn({type:'timeofday', label:'Task'});
            data.addColumn({type:'string', role:'annotation'});
            data.addColumn({type:'string', role:'annotationText'});

            data.addRow([new Date(2016, 1,23), [10, 30, 0], 'Info','Admission Date Set',[7, 45, 0],'Document','Pre-Admission Docs',[9, 0, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 2,11), null,null,null,null,null,null,[9, 30, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 3,12), [8, 30, 0], 'Info','Admission Date Set',[16, 45, 0],'Document','Pre-Admission Assessment',[9, 0, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 4,2), [10, 0, 0], 'Info','Admission Date Set',[11, 0, 0],'Document','Pre-Admission Docs',[15, 0, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 5,3), [7, 30, 0], 'Info','Admission Date Set',[14, 30, 0],'Document','Pre-Admission Assessment',[17, 30, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 6,4), [11, 50, 0], 'Info','Admission Date Set',[9, 30, 0],'Document','Pre-Admission Docs',[8, 10, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 7,6), [9, 20, 0], 'Info','Admission Date Set',[14, 50, 0],'Document','Pre-Admission Assessment',[16, 30, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 8,9), [13, 0, 0], 'Info','Admission Date Set',[10, 30, 0],'Document','Pre-Admission Docs',[7, 30, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 9,22), [14, 20, 0], 'Info','Admission Date Set',[11, 10, 0],'Document','Pre-Admission Assessment',[14, 30, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 10,21), [9, 0, 0], 'Info','Admission Date Set',[14, 0, 0],'Document','Pre-Admission Docs',[8, 0, 0], 'Task','Task Assigned' ]);
            data.addRow([new Date(2016, 11,30), [10, 20, 0], 'Info','Admission Date Set',[8, 50, 0],'Document','Pre-Admission Assessment',[16, 30, 0], 'Task','Task Assigned' ]);

            dash = new google.visualization.Dashboard(document.getElementById('dashboard'));

            chart = new google.visualization.ChartWrapper({
                chartType: 'ScatterChart',
                containerId: 'chart_div',
                options: {
                    height: 400,
                    legend: {
                        position: 'top',
                        alignment: 'end',
                        textStyle: {
                            fontSize: 10,
                            bold: true
                        }
                    },
                    pointShape: 'square',
                    chartArea: {
                        left: 50,
                        top: 40,
                        width: '90%',
                        height: '85%'
                    },
                    explorer: {
                        actions: ['dragToZoom', 'rightClickToReset'],
                        axis: 'horizontal',
                        keepInBounds: true
                    },
                    pointSize: 15,
                    annotations: {
                        boxStyle: {
                            stroke: '#aaa',
                            strokeWidth: 1,
                            rx: 4,
                            ry: 4
                        },
                        textStyle: {
                            fontSize: 10,
                            bold: true
                        },
                        style: 'point'
                    },
                    tooltip: {
                        trigger: 'selection',
                        isHtml: true
                    },
                    vAxis: {
                        gridlines: {
                            count: 15
                        },
                        textStyle: {
                            fontSize: 10
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontSize: 10
                        }
                    }
                }
            });

            control = new google.visualization.ControlWrapper({
                controlType: 'ChartRangeFilter',
                containerId: 'control_div',
                options: {
                    filterColumnIndex: 0,
                    ui: {
                        chartType: 'ScatterChart',
                        backgroundColor: '#999',
                        chartOptions: {
                            height:50,
                            annotations: {
                                textStyle: {
                                    fontSize: 9,
                                    opacity: 0
                                }
                            },
                            backgroundColor: '#F3F8FD',
                            chartArea: {
                                width: '90%',
                                backgroundColor: '#ffffff'
                            },
                            pointSize: 5,
                            hAxis: {
                                textStyle: {
                                    fontSize: 10
                                }
                            }
                        },
                        minRangeSize: 6480000000 //milliseconds
                    }
                },
                state: {
                    range: {
                        start: vm.rangeStart,
                        end: vm.rangeEnd
                    }
                }
            });

            // Must wait for the ready event in order to
            // request the chart and subscribe to 'onmouseover'.
            google.visualization.events.addListener(chart, 'ready', onReady);

            dash.bind([control], [chart]);

            dash.draw(data);

            function onReady() {
                google.visualization.events.addListener(chart.getChart(), 'select', usefulHandler);

                chart.getChart().setAction({
                    id: 'tooltipAction1',
                    text: 'See Details 1',
                    action: function() {
                        alert('Tooltip Action 1!');
                    }
                });
                chart.getChart().setAction({
                    id: 'tooltipAction2',
                    text: 'See Details 2',
                    action: function() {
                        alert('Tooltip Action 2!');
                    }
                });
            }

            // Select Event Handler
            function usefulHandler() {

            }
        }

        function updateGChart() {
            control.setState({range:{start: vm.rangeStart, end: vm.rangeEnd}});
            control.draw()
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxScheduleDialogController', VerifytxScheduleDialogController);

    VerifytxScheduleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'VerifytxSchedule', 'GUIUtils', 'Employee'];

    function VerifytxScheduleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                               VerifytxSchedule, GUIUtils, Employee) {
        var vm = this;

        vm.verifytxSchedule = entity;
        vm.days = GUIUtils.getDays();
        vm.schedule = GUIUtils.weekCodeStringToDays(vm.verifytxSchedule.schedule);

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.verifytxSchedule.schedule = GUIUtils.daysToWeekCodeString(vm.schedule);

            vm.isSaving = true;
            if (vm.verifytxSchedule.id !== null) {
                VerifytxSchedule.update(vm.verifytxSchedule, onSaveSuccess, onSaveError);
            } else {
                VerifytxSchedule.save(vm.verifytxSchedule, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:verifytxScheduleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.verifytxSchedule.corporation.id};

                Employee.findAllByCorporation(filter, function (result) {
                    vm.employees = result;
                });
            }
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PdfPrint', PdfPrint);

    PdfPrint.$inject = ['moment', '$rootScope'];

    function PdfPrint(moment, $rootScope) {

        var service = {
            groupSessionPatient: groupSessionPatient
        };

        return service;

        function groupSessionPatient(data, chart) {
            var dd = {
                content: [
                    {text: 'Group Session Details', style: 'header', margin: 5},
                    {text: 'Basic Information: ', style: 'subheader', margin: 5},

                    {text: 'Patient: ', bold: true, margin: 5},
                    {text: chart.lastName + ', ' + chart.firstName + (chart.middleName ? " " + chart.middleName[0] : '') , margin: 5},

                    {text: 'DOB: ', bold: true, margin: 5},
                    {text: moment(chart.dateBirth).format($rootScope.dateFormat) , margin: 5},

                    {text: 'MRN: ', bold: true, margin: 5},
                    {text: chart.mrNo , margin: 5},

                    {text: 'Title: ', bold: true, margin: 5},
                    {text: data.title?data.title:'-', margin: 5},

                    {
                        columns: [
                            [
                                {text: 'Date Started: ', bold: true, margin: 5},
                                {
                                    columns: [
                                        {text: data.startDate?moment(data.startDate).format("MM/DD/YYYY"):'-', margin: 5}
                                    ]
                                }
                            ],
                            [
                                {text: 'Start Time: ', bold: true, margin: 5},
                                {
                                    columns: [
                                        {text: data.start?moment(data.start).format("HH:mm"):'-', margin: 5}
                                    ]
                                }
                            ],
                            [
                                {text: 'End Time: ', bold: true, margin: 5},
                                {
                                    columns: [
                                        {text: data.stop?moment(data.stop).format("HH:mm"):'-', margin: 5}
                                    ]
                                }
                            ]
                        ]
                    },

                    {text: 'Description: ', bold: true, margin: 5},
                    {text: data.description?data.description:'-', margin: 5},

                    {text: data.note?'Note: ':'', bold: true, margin: 5},
                    {text: data.note?data.note:'', margin: 5}


                    // {
                    //     columns: [
                    //         [
                    //             {text: 'Session Type: ', bold: true, margin: 5},
                    //             {
                    //                 columns: [
                    //                     {text: data.sessionType?data.sessionType.name:'-', margin: 5}
                    //                 ]
                    //             }
                    //         ],
                    //         [
                    //             {text: 'Date Started: ', bold: true, margin: 5},
                    //             {
                    //                 columns: [
                    //                     {text: data.startDate?moment(data.startDate).format("MM/DD/YYYY"):'-', margin: 5}
                    //                 ]
                    //             }
                    //         ]
                    //     ]
                    // },
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            var signature = [
                {text: 'Signatures', style: 'subheader'},
                {
                    columns: [
                        data.leaderSignature?([{
                            image: data.leaderSignature.signature,
                            width: 200
                        },
                            'Leader: ',
                            {text: data.leader.firstName +' '+ data.leader.lastName},
                            'Date: ',
                            {text: moment(data.leaderSignature.createdDate).format("MM/DD/YYYY HH:mm")}
                        ]):{},
                        data.reviewerSignature?([{
                            image: data.reviewerSignature.signature,
                            width: 200
                        },
                            'Reviewer: ',
                            {text: data.reviewer?(data.reviewer.firstName +' '+ data.reviewer.lastName):''},
                            'Date: ',
                            {text: moment(data.reviewerSignature.createdDate).format("MM/DD/YYYY HH:mm")}
                        ]):{}
                    ]
                }];

            /* Don't remove, maybe the bosses pee and change their minds*/

            // var employees = [
            //     {text: 'Employees', style: 'subheader'},
            //     {
            //         style: 'tableExample',
            //         table: {
            //             body: []
            //         }
            //     }];
            // employees[1].table.body.push(['First Name', 'Last Name', 'Phone Number', 'Email']);
            // data.employees.forEach(function (item) {
            //     if(item) {
            //         var element = [
            //             {text: item.firstName?item.firstName:'-'},
            //             {text: item.lastName?item.lastName:'-'},
            //             {text: item.mobile?item.mobile:'-'},
            //             {text: item.email?item.email:'-'}
            //         ];
            //         employees[1].table.body.push(element);
            //     }
            // });
            // dd.content.push(employees);
            dd.content.push(signature);

            return dd;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CampaignController', CampaignController);

    CampaignController.$inject = ['GenericEntityDatatableService', 'Campaign', 'CoreService', '$uibModal', '$q',
        'toastr', 'GUIUtils', 'ConfirmationService'];

    function CampaignController(GenericEntityDatatableService, Campaign, CoreService, $uibModal, $q,
                                toastr, GUIUtils, ConfirmationService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Campaigns',
            newButtonTitle: 'New Campaign',
            entityClassHumanized: 'Campaigns',
            entityStateName: 'campaign',
            service: Campaign,
            serviceMethod: 'byFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Enable',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.active);
                    }
                },
                {
                    name: 'gender',
                    title: 'Gender',
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Campaign',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/campaign/update/campaign-dialog.html',
                controller: 'CampaignDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            name: null,
                            active: true,
                            dischargeRangeStart: null,
                            dischargeRangeEnd: null,
                            ageRangeStart: null,
                            ageRangeEnd: null,
                            gender: null,
                            insuranceCarriers: [],
                            typePaymentMethods: [],
                            evaluationTemplates: [],
                            campaignPolicyNumbers: [],
                            campaignChartStatus: [],
                            campaignEvaluationStatus: []
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/campaign/update/campaign-dialog.html',
                controller: 'CampaignDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Campaign', function (Campaign) {
                        return Campaign.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Campaign').result.then(function () {
                    Campaign.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateMarsMorsDialogController', EvaluationTemplateMarsMorsDialogController);

    EvaluationTemplateMarsMorsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EvaluationTemplateMarsMors', 'EvaluationTemplate', 'CoreService'];

    function EvaluationTemplateMarsMorsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EvaluationTemplateMarsMors, EvaluationTemplate, CoreService) {
        var vm = this;

        vm.evaluationTemplateMarsMors = entity;
        vm.evaluationTemplates = [];
        vm.facility = CoreService.getCurrentFacility();
        vm.clear = clear;
        vm.save = save;
        vm.getEvaluationTemplates = getEvaluationTemplates;

        function getEvaluationTemplates(query) {
            if (query) {
                var filter = {page: 0, size: 10, searchQuery: query, facilityId: vm.facility.id, enabled: true};

                EvaluationTemplate.findAllByFilter(filter, function (result) {
                    vm.evaluationTemplates = result;
                });
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.evaluationTemplateMarsMors.id !== null) {
                EvaluationTemplateMarsMors.update(vm.evaluationTemplateMarsMors, onSaveSuccess, onSaveError);
            } else {
                EvaluationTemplateMarsMors.save(vm.evaluationTemplateMarsMors, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:evaluationTemplateMarsMorsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConfirmationDialogController', ConfirmationDialogController);

    ConfirmationDialogController.$inject = ['$uibModalInstance', 'config'];

    function ConfirmationDialogController($uibModalInstance, config) {
        var vm = this;

        vm.config = config;

        vm.clear = clear;
        vm.close = close;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function close(value) {
            $uibModalInstance.close(value);
        }
    }
})();

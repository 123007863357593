(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionDetailsDialogController', GroupSessionDetailsDialogController);

    GroupSessionDetailsDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'toastr',
        'GroupSessionDetails', 'entity', 'GroupSession', '$interval'];

    function GroupSessionDetailsDialogController($timeout, $scope, $uibModalInstance, toastr,
                                                 GroupSessionDetails, entity, GroupSession, $interval) {
        var vm = this;

        vm.groupSessionDetails = entity;
        vm.groupSession = null;
        vm.chart = vm.groupSessionDetails.chart;
        vm.jsonTemplate = null

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.stop = false;
        vm.watchers = [];
        vm.datePickerOpenStatus = {};

        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        init();

        function init() {
            GroupSessionDetails.getJsonTemplate({id: vm.groupSessionDetails.id}, function (jsonTemplate) {
                vm.jsonTemplate = jsonTemplate;

                $timeout(function () {
                    vm.watcher =
                        $scope.$watch('vm.jsonTemplate', function (newValue, oldValue, scope) {
                            if (oldValue !== newValue) {
                                if (!vm.autoSaveId) {
                                    vm.interval = $interval(autoSave, 1500);
                                }

                                vm.lastUpdate = new Date();
                                vm.autoSaveId = Math.random();
                            }
                        }, true);
                }, 2000);
            });

            GroupSession.get({id: vm.groupSessionDetails.groupSessionId}, function (result) {
                vm.groupSession = result;

                if (vm.groupSession.leaderId) {
                    GroupSession.getSignature({id: vm.groupSession.id, type: 'STAFF'}, function (data) {
                        vm.leaderSignature = data ? data : null;
                    });
                }

                if (vm.groupSession.reviewerId) {
                    GroupSession.getSignature({id: vm.groupSession.id, type: 'REVIEW'}, function (data) {
                        vm.reviewerSignature = data ? data : null;
                    });
                }
            });
        }

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.formatDate = formatDate;
        vm.formatDateNew = formatDateNew;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function formatDate(date) {
            return moment(date).format('lll');
        }

        function formatDateNew(date) {
            return moment(date).format("hh:mm a");
        }

        function autoSave() {
            if (vm.isSaving || !vm.autoSaveId) {

                return;
            }

            if (vm.savedId === vm.autoSaveId) {
                vm.autoSaveId = null;
                vm.savedId = null;

                $interval.cancel(vm.interval);

                return;
            }

            var now = new Date();

            if (((now.getTime() - vm.lastUpdate.getTime()) / 1000) < 1.5) {

                return;
            }

            vm.isSaving = true;
            vm.savedId = angular.copy(vm.autoSaveId);

            var jsonTemplate = angular.copy(vm.jsonTemplate);

            GroupSessionDetails.autoSave({
                groupSessionDetails: vm.groupSessionDetails,
                jsonTemplate: jsonTemplate
            }, function () {
                vm.isSaving = false;

                toastr.success("Saved " + vm.groupSession.title + ' - ' +
                    vm.groupSessionDetails.chart.firstName + ' ' +
                    vm.groupSessionDetails.chart.lastName + ' ' + vm.groupSessionDetails.chart.mrNo);
            }, function () {
                vm.isSaving = false;

                toastr.error("Error saving " + vm.groupSession.title + ' - ' +
                    vm.groupSessionDetails.chart.firstName + ' ' +
                    vm.groupSessionDetails.chart.lastName + ' ' + vm.groupSessionDetails.chart.mrNo);
            });
        }

        $scope.$on('$destroy', function () {
            if (vm.watcher) {
                vm.watcher();
            }
        });
    }
})();

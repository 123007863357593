(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AlcoholDialogController', AlcoholDialogController);

    AlcoholDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Alcohol'];

    function AlcoholDialogController ($timeout, $scope, $uibModalInstance, entity, Alcohol) {
        var vm = this;

        vm.alcohol = entity;
        vm.alcohol.date = new Date();
        vm.clear = clear;
        vm.save = save;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.alcohol.id !== null) {
                Alcohol.update(vm.alcohol, onSaveSuccess, onSaveError);
            } else {
                Alcohol.save(vm.alcohol, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:alcoholUpdate', result);
            $scope.$emit('bluebookApp:drugUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.dateOptions = {

            maxDate: new Date()+1
        }


        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.time = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

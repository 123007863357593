(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Glucose', Glucose);

    Glucose.$inject = ['$resource', 'DateUtils', 'CoreService'];

    function Glucose($resource, DateUtils, CoreService) {
        var resourceUrl = 'api/glucoses/:id';
        var facility = CoreService.getCurrentFacility();

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.date = new Date(DateUtils.convertDateTimeFromServer(data.date)
                            .toLocaleString("en-US", {timeZone: facility.timeZone}));
                    }
                    return data;
                }
            },
            'findAllByChart': {url: 'api/glucoses/by-chart/:id', method: 'GET', isArray: true},
            'update': {method: 'PUT'},
            'findLastByChart': {
                url: 'api/glucoses/last/by-chart/:id', method: 'GET', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.date = new Date(DateUtils.convertDateTimeFromServer(data.date)
                            .toLocaleString("en-US", {timeZone: facility.timeZone}));
                    }

                    return data;
                }
            },
            'findAllByChartPageable': {url: 'api/glucoses/last/by-chart/:id/size/:size', method: 'GET', isArray: true},
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationAudit', EvaluationAudit);

    EvaluationAudit.$inject = ['$resource'];

    function EvaluationAudit($resource) {
        var resourceUrl = 'api/evaluation-audits/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByFilter': {url: 'api/evaluation-audits/filter', method: 'GET', isArray: true},
            'findAllByEvaluationAudit': {
                url: 'api/evaluation-audits/evaluation-audit-templates/by-evaluation-audit/:id',
                method: 'GET', isArray: true
            },
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            ncyBreadcrumb: {
                label: 'Home'
            },
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                dashboard: function () {
                    return {
                        unsignedOrdersByDoctor: 0,
                        unsignedOrders: 0,
                        unassignedLabResult: 0,
                        unsignedForms: 0,
                        unsignedEvaluations: 0,
                        concurrentReviews: 0,
                        freeMaleBeds: 0,
                        freeFemaleBeds: 0
                    }
                }
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmActivityController', CrmActivityController);

    CrmActivityController.$inject = ['CrmActivity', 'entity', 'AlertService'];

    function CrmActivityController(CrmActivity, entity, AlertService) {

        var vm = this;

        vm.crmOpportunity = entity;
        vm.crmActivities = [];
        vm.groupedCrmActivities = [];

        loadAll();

        function loadAll() {
            CrmActivity.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id
            }, function (data) {
                vm.crmActivities = data;

                vm.groupedCrmActivities = _.groupBy(vm.crmActivities, function (crmActivity) {
                    return "<strong>" + moment(crmActivity.createdDate).format("MMMM") + "</strong> " +
                        "<span>[" + moment(crmActivity.createdDate).format("YYYY") + "]</span>";
                });
            }, onError);

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();

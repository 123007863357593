(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('WeightChartController', WeightChartController);

    WeightChartController.$inject = ['chart', 'Weight', 'CoreService', '$uibModal', '$scope', '$rootScope', 'access', 'ACTIONS', 'Auth'];

    function WeightChartController(chart, Weight, CoreService, $uibModal, $scope, $rootScope, access, ACTIONS, Auth) {
        var vm = this;
        vm.chart = chart;

        vm.selectGraphData = selectGraphData;
        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.history = history;
        vm.getHeightInchesToFeetInches = getHeightInchesToFeetInches;

        vm.dataW = [];

        vm.weightData = {
            values: [],
            key: 'Weight',
            color: '#ffffff'
        };

        vm.bmiData = {
            values: [],
            key: 'BMI',
            color: '#ffffff'
        };

        vm.optionsW = {
            chart: {
                type: 'lineChart',
                showLegend: false,
                height: 340,
                margin : {
                    top: 20,
                    right: 50,
                    bottom: 100,
                    left: 50
                },
                x: function(d){ return d.x; },
                y: function(d){ return d.y; },
                useInteractiveGuideline: true,
                xAxis: {
                    tickFormat: function (d) {
                        return d3.time.format('%m/%d/%y-%H:%M')(new Date(d));
                    },
                    showMaxMin: false,
                    rotateLabels:(-70)
                },
                yAxis: {
                    tickFormat: function(d){
                        return d3.format('.02f')(d);
                    },
                    showMaxMin: false
                }
            }
        };

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_WEIGHT_VIEW, access)) {
                return;
            }

            Weight.findLastByChart({id: chart.id}, function (result) {
                vm.weight = result;
                vm.weight.bmi = _.ceil(result.bmi, 2)
            });

            Weight.findLastAtt({id: chart.id, size: 10}, function (data) {
                for (var i = data.length - 1; i >= 0; i--) {
                    vm.weightData.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value1)});
                    vm.bmiData.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value2)});
                }

                vm.selectGraphData('weight');
            });
        }

        function selectGraphData(value) {
            switch (value) {
                case 'weight' : {
                    vm.dataW = [];
                    vm.dataW.push(vm.weightData);

                    break;
                }
                case 'bmi' : {
                    vm.dataW = [];
                    vm.dataW.push(vm.bmiData);

                    break;
                }
            }
        }

        function toggleItem() {
            CoreService.togglePanel('weight');
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/weight/update/weight-dialog.html',
                controller: 'WeightDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            id: null,
                            chart: chart,
                            date: new Date()
                        };
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/weight/history/weight.html',
                controller: 'WeightController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function getHeightInchesToFeetInches(height) {

            return CoreService.getHeightInchesToFeetInches(height);
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartWeightHistory', function (event, result) {
            history();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DoseFormController', DoseFormController);

    DoseFormController.$inject = ['GenericEntityDatatableService', 'DoseForm', 'CoreService', '$uibModal',
        'ConfirmationService', '$q'];

    function DoseFormController(GenericEntityDatatableService, DoseForm, CoreService, $uibModal,
                                ConfirmationService, $q) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Dose Form',
            newButtonTitle: 'New Dose Form',
            entityClassHumanized: 'Dose Form',
            entityStateName: 'dose-form',
            service: DoseForm,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Dose Form',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/dose-form/update/dose-form-dialog.html',
                controller: 'DoseFormDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/dose-form/update/dose-form-dialog.html',
                controller: 'DoseFormDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['DoseForm', function (DoseForm) {
                        return DoseForm.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('DoseForm').result.then(function () {
                    DoseForm.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

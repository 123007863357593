(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityBillingController', FacilityBillingController);

    FacilityBillingController.$inject = ['$scope', '$state', 'FacilityBilling', 'DTOptionsBuilder', '$q', '$uibModal',
        'DTColumnBuilder', 'CoreService', '$rootScope', 'GUIUtils', 'DataTablesService',
        'GenericEntityDatatableService', 'ConfirmationService'];

    function FacilityBillingController($scope, $state, FacilityBilling, DTOptionsBuilder, $q, $uibModal,
                                       DTColumnBuilder, CoreService, $rootScope, GUIUtils, DataTablesService,
                                       GenericEntityDatatableService, ConfirmationService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Facility Billings',
            entityClassHumanized: 'Facility Billings',
            newButtonTitle: 'New Invoice',
            service: FacilityBilling,
            serviceMethod: 'filter',
            pagination: 'remote',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'Invoice #'
                },
                {
                    title: 'Date',
                    render: function (data) {
                        return moment(data.date).format('MMM YYYY');
                    }
                },
                {
                    title: "Requested Date",
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
                {
                    title: 'Patients',
                    name: "patients"
                },
                {
                    title: 'Total',
                    render: function (data) {
                        return '$ ' + data.total;
                    }
                },
                {
                    title: "Status",
                    render: function (data) {
                        return data.status ? getStatusStyle(data.status) : "FAIL";
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Invoice',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-billing/update/facility-billing-dialog.html',
                controller: 'FacilityBillingDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            status: 'PENDING',
                            date: null,
                            patients: 0,
                            total: 0,
                            id: null
                        };
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Billing').result.then(function () {
                    FacilityBilling.delete({id: id}, function () {
                        resolve()
                    });
                })
            })
        }

        function getStatusStyle(status) {
            switch (status) {
                case 'PENDING': {
                    return GUIUtils.getStatusTemplate(status, "primary")
                }
                case 'NO_PRICE': {
                    return GUIUtils.getStatusTemplate(status, "danger")
                }
                case 'COMPLETED': {
                    return GUIUtils.getStatusTemplate(status, "success")
                }
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationGlucoseDialogController', EvaluationGlucoseDialogController);

    EvaluationGlucoseDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'EvaluationGlucose', 'GlucoseIntervention', 'TypeCheck', 'CoreService'];

    function EvaluationGlucoseDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                               EvaluationGlucose, GlucoseIntervention, TypeCheck, CoreService) {
        var vm = this;

        vm.evaluationGlucose = entity;
        vm.glucose = angular.copy(vm.evaluationGlucose.glucose);
        vm.glucoseinterventions = GlucoseIntervention.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.corporation = CoreService.getCorporation();
        vm.typeCheck = TypeCheck.findAllByCorporation(CoreService.getFilterByCurrentCorporation());

        vm.datePickerOpenStatus = {};
        vm.form = {};

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            vm.evaluationGlucose.glucose = vm.glucose;

            if (vm.evaluationGlucose.id !== null) {
                EvaluationGlucose.update(vm.evaluationGlucose, onSaveSuccess, onSaveError);
            } else {
                EvaluationGlucose.save(vm.evaluationGlucose, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:evaluationGlucoseUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.time = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssuesDialogController', UrgentIssuesDialogController);

    UrgentIssuesDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'UrgentIssues', 'CoreService', '$stateParams',
        'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile', 'entity', 'UrgentIssueEmployee',
        '$uibModal'];

    function UrgentIssuesDialogController($timeout, $scope, $uibModalInstance, UrgentIssues, CoreService, $stateParams,
                                          DTOptionsBuilder, DTColumnBuilder, $q, $compile, entity, UrgentIssueEmployee,
                                          $uibModal) {
        var vm = this;

        vm.employee = CoreService.getCurrentEmployee();
        vm.facility = CoreService.getCurrentFacility();
        vm.employees = [];
        vm.saveUrgentIssue = false;
        vm.showDetails = false;
        vm.unknowUrgentIssues = UrgentIssues.unknown({id: $stateParams.id});
        vm.urgentIssues = entity;
        vm.selected = {};
        vm.allUrgentIssues = [];
        vm.detailsData = [];
        vm.dtInstance = {};
        vm.chart = vm.urgentIssues.chart;

        vm.clear = clear;
        vm.details = details;
        vm.clearDescription = clearDescription;
        vm.save = save;
        vm.check = check;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        init();

        function init() {
            vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();

                UrgentIssues.byChart({id: $stateParams.id}, function (result) {
                    vm.allUrgentIssues = result;
                    defer.resolve(result);
                });

                return defer.promise;
            }).withPaginationType('full_numbers')
                .withBootstrap()
                .withDOM('tp')
                .withLanguage({
                    "paginate": {
                        "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                        "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                        "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                        "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                    }
                })
                .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
                .withOption('headerCallback', function (header) {
                    if (!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                }).withOption('createdRow', function (row, data, dataIndex) {
                    if (!data.checked) {
                        //here i add a custom class to checked issue
                        angular.element(row).addClass('highlighted')
                    }
                });

            vm.dtColumns = [
                DTColumnBuilder
                    .newColumn(null)
                    .withTitle('')
                    .withOption('class', 'first-col')
                    .notSortable()
                    .renderWith(function (data, type, full, meta) {
                        return '<span class="row-count">' + (meta.row + 1) + '</span>';
                    }),
                DTColumnBuilder
                    .newColumn('description')
                    .withTitle('Description')
                    .withOption('class', 'description-text')
                    .notSortable(),
                DTColumnBuilder
                    .newColumn(null)
                    .withTitle('Actions')
                    .withOption('class', 'last-col')
                    .notSortable()
                    .renderWith(actionsHtml)
            ];
        }

        function loadAll() {
            vm.dtInstance.reloadData();
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            UrgentIssues.save(vm.urgentIssues, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            loadAll();

            clearDescription();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function actionsHtml(data, type, full, meta) {
            var btn = '<a class="btn-details" ng-click="vm.details(' + data.id + ')" title="See Details">' +
                '<i class="fa fa-list-alt" aria-hidden="true"></i></a>';

            if (!data.checked) {
                btn += '<br><input type="checkbox" name="selectIssue" ng-model="vm.selected[' + data.id + ']"' +
                    ' ng-click="vm.check(' + data.id + ')" title="Check Issue">';
            }

            return btn;
        }

        function check(id) {
            UrgentIssues.check({id: id}, function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function details(id) {
            vm.showDetails = !vm.showDetails;
            vm.employees = [];
            UrgentIssueEmployee.getAllEmployeeByUrgentIssue({id: id}, function (result) {
                vm.employees = result;
            });
        }

        function clearDescription() {
            vm.urgentIssues.description = '';
            vm.saveUrgentIssue = !vm.saveUrgentIssue;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcUploadController', RcUploadController);

    RcUploadController.$inject = ['$scope', '$state', 'RcUpload', '$rootScope',
        'DTOptionsBuilder', 'DTColumnBuilder', '$q', 'GUIUtils', '$filter', 'CoreService', '$compile'];

    function RcUploadController($scope, $state, RcUpload, $rootScope,
                                DTOptionsBuilder, DTColumnBuilder, $q, GUIUtils, $filter, CoreService, $compile) {
        var vm = this;

        vm.title = 'Uploads';
        vm.entityClassHumanized = 'Upload';
        vm.entityStateName = 'rc-upload';

        vm.rcUploads = [];
        vm.dtInstance = {};

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            RcUpload.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                vm.rcUploads = result;
                defer.resolve(result);
            });

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withDisplayLength(15)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('aaSorting', [])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('fileName').withTitle('File').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Date Created').notSortable().renderWith(function (data, type) {
                return data.collectedDate ? moment(data.collectedDate).format($rootScope.dateFormat) : ' - ';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').notSortable().renderWith(function (data, type, full) {
                return getStatusStyle(data.rcUploadStatus);
            }),
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        function getStatusStyle(status) {

            switch (status) {
                case 'PENDING': {
                    return GUIUtils.getStatusTemplate(status, "primary")
                }
                case 'ERROR': {
                    return GUIUtils.getStatusTemplate(status, "danger")
                }
                case 'COMPLETED': {
                    return GUIUtils.getStatusTemplate(status, "success")
                }
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FirstDoseSideEffectDialogController', FirstDoseSideEffectDialogController);

    FirstDoseSideEffectDialogController.$inject = ['$uibModalInstance', 'ParseLinks', 'takes', 'toastr',
        'PatientMedicationTakeReaction', 'AlertService', 'CoreService'];

    function FirstDoseSideEffectDialogController($uibModalInstance, ParseLinks, takes, toastr,
                                                 PatientMedicationTakeReaction, AlertService, CoreService) {
        var vm = this;

        vm.title = "First Dose/Side effect";

        vm.page = 1;
        vm.itemsPerPage = 6;
        vm.reactions = [];
        vm.takes = takes;
        vm.message = null;
        vm.facility = CoreService.getCurrentFacility();

        vm.clear = clear;
        vm.transition = transition;
        vm.add = add;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        loadAll();

        function loadAll() {
            if (vm.takes.ids.length == 1) {
                PatientMedicationTakeReaction.findAllByTake({
                        id: takes.ids[0],
                        page: vm.page - 1,
                        size: vm.itemsPerPage
                    },

                    function (data, headers) {
                        vm.links = ParseLinks.parse(headers('link'));
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        vm.reactions = data;
                    },

                    function (error) {
                        AlertService.error(error.data.message);
                    });
            }
        }

        function transition() {
            loadAll();
        }

        function add() {
            vm.isSaving = true;
            PatientMedicationTakeReaction.addFirstDoseSideEffect({
                ids: takes.ids,
                message: vm.message,
                facilityId: vm.facility.id
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            if (result.length > 0) {
                toastr.error('Error Adding First Dose Side Effect, please try again later');
            } else {
                toastr.success('The First Dose Side Effect was added successful');
            }

            if (vm.takes.ids.length == 1) {
                vm.message = null;

                transition();
            } else {
                clear();
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('employee', {
                parent: 'entity',
                url: '/employee',
                data: {
                    authorities: [],
                    pageTitle: 'Employees'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/employee/list/employee.html',
                        controller: 'EmployeeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    employee: ['Employee', function (Employee) {
                        return Employee.employeeAccount().$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE, access);
                }]
            })
            .state('employee-system', {
                parent: 'entity',
                url: '/employee-system',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'Supervisors'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/employee/system/employee-system.html',
                        controller: 'EmployeeSystemController',
                        controllerAs: 'vm'
                    }
                },
            });
    }
})();

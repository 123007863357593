(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('IncidentReportNoteTemplate', IncidentReportNoteTemplate);

    IncidentReportNoteTemplate.$inject = ['$resource'];

    function IncidentReportNoteTemplate ($resource) {
        var resourceUrl =  'api/incident-report-note-templates/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].jsonTemplate = angular.fromJson(data[i].jsonTemplate);
                        }
                    }

                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.jsonTemplate = angular.fromJson(data.jsonTemplate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);
                    return angular.toJson(copy);
                }
            },
            'getAllByFacility': {url: 'api/incident-report-note-templates/facility/:id', method: 'GET', isArray: true},
            'clone': {url: 'api/incident-report-note-templates/clone/:id', method: 'GET'},
            'findAllByFilter': {url: 'api/incident-report-note-templates/filter', method: 'GET', isArray: true}
        });
    }
})();

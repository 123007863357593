(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EmployeeController', EmployeeController);

    EmployeeController.$inject = ['GenericEntityDatatableService', 'Employee', 'GUIUtils', 'CoreService', 'ROLES',
        'Principal', '$uibModal', '$q', 'ConfirmationService'];

    function EmployeeController(GenericEntityDatatableService, Employee, GUIUtils, CoreService, ROLES,
                                Principal, $uibModal , $q, ConfirmationService) {
        var vm = this;

        vm.isAdmin = Principal.hasAnyAuthority([ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN]);

        var params = {
            corporationId: CoreService.getCorporation().id,
        }

        vm.descriptor = {
            title: 'Employees',
            newButtonTitle: 'New Employee',
            entityClassHumanized: 'Employee',
            entityStateName: 'employee',
            service: Employee,
            serviceMethod: 'findAllByFilterAdmin',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: null,
                    title: 'Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    }
                },
                {
                    title: 'Login',
                    render: function (data) {
                        return data.login;
                    }
                },
                {
                    name: null,
                    title: 'Email',
                    render: function (data) {
                        return data.email ? data.email : '-';
                    }
                },
                {
                    name: null,
                    title: 'Mobile',
                    render: function (data) {
                        return data.mobile ? data.mobile : '-';
                    }
                },
                {
                    name: null,
                    title: 'NPI',
                    render: function (data) {
                        return data.npiNumber ? data.npiNumber : '-';
                    }
                },
                {
                    name: null,
                    title: 'DEA',
                    render: function (data) {
                        return data.deaNumber ? data.deaNumber : '-';
                    }
                },
                {
                    name: null,
                    title: 'Activated',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.activated);
                    }
                },
                {
                    name: null,
                    title: 'Deleted',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.delStatus);
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('acuity'), {
                    action: function (data) {
                        return del(data.id);
                    },
                    ngIf: function (data) {
                        return !data.delStatus;
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getRestoreAction(), {
                    name: "Restore",
                    action: function (data) {
                        return restore(data.id);
                    },
                    ngIf: function (data) {
                        return data.delStatus && vm.isAdmin;
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Employee',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'query',
                    type: 'string',
                    visible: false,
                    read: function (filter) {
                        return filter.searchQuery;
                    }
                }
            ]
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/employee/update/employee-dialog.html',
                controller: 'EmployeeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            email: null,
                            mobile: null,
                            npiNumber: null,
                            deaNumber: null,
                            delStatus: false,
                            firstName: null,
                            lastName: null,
                            user: {activated: true},
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/employee/update/employee-dialog.html',
                controller: 'EmployeeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Employee', function (Employee) {
                        return Employee.get({id: id}).$promise;
                    }],
                    authorities: ['AuthItem', 'AUTH_ITEM_TYPE', 'CoreService', function (AuthItem, AUTH_ITEM_TYPE, CoreService) {
                        var corporation = CoreService.getCorporation();

                        return AuthItem.queryByType({type: AUTH_ITEM_TYPE.TYPE_ROLE, id: corporation.id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Employee').result.then(function () {
                    Employee.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function restore(id) {
            return $q(function (resolve) {
                ConfirmationService.open({
                    title: 'Restore Employee',
                    message: 'This action will <strong>restore</strong> a deleted employee. Are you sure you want to <strong>restore</strong> this Employee?',
                    actions: [
                        {
                            name: 'Restore',
                            color: 'btn btn-success',
                            icon: 'fa fa-check-circle',
                            value: 'SUCCESS'
                        },
                        {
                            name: 'Cancel',
                            color: 'btn btn-danger',
                            icon: 'fa fa-ban',
                            value: 'CANCEL'
                        }
                    ]
                }).result.then(function () {
                    Employee.restore(id, function () {
                        resolve();
                    });
                });
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('crm-campaign', {
            parent: 'entity',
            url: '/crm-campaign',
            data: {
                authorities: [],
                pageTitle: 'CrmCampaign'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/crm-campaign/crm-campaign.html',
                    controller: 'CrmCampaignController',
                    controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: 'Crm Campaign'
            },
            resolve: {
                access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                    var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN];
                    return Auth.loadActionsAccess(actions).$promise;
                }]
            },
            onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN, access);
            }]
        })
        .state('crm-campaign.new', {
            parent: 'crm-campaign',
            url: '/new',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN, access)) {
                    $uibModal.open({
                        templateUrl: 'app/entities/crm-campaign/crm-campaign-dialog.html',
                        controller: 'CrmCampaignDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        windowTopClass: 'custom-dialog-styles',
                        resolve: {
                            entity: ['CoreService', function (CoreService) {
                                return {
                                    name: null,
                                    id: null,
                                    corporation: CoreService.getCorporation()
                                };
                            }]
                        }
                    }).result.then(function () {
                        $state.go('crm-campaign', null, {reload: 'crm-campaign'});
                    }, function () {
                        $state.go('crm-campaign');
                    });
                }
            }]
        })
        .state('crm-campaign.edit', {
            parent: 'crm-campaign',
            url: '/{id}/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN, access)) {
                    $uibModal.open({
                        templateUrl: 'app/entities/crm-campaign/crm-campaign-dialog.html',
                        controller: 'CrmCampaignDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        windowTopClass: 'custom-dialog-styles',
                        resolve: {
                            entity: ['CrmCampaign', function (CrmCampaign) {
                                return CrmCampaign.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('crm-campaign', null, {reload: 'crm-campaign'});
                    }, function () {
                        $state.go('^');
                    });
                }
            }]
        })
        .state('crm-campaign.delete', {
            parent: 'crm-campaign',
            url: '/{id}/delete',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_CAMPAIGN, access)) {
                    $uibModal.open({
                        templateUrl: 'app/entities/crm-campaign/crm-campaign-delete-dialog.html',
                        controller: 'CrmCampaignDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        windowTopClass: 'custom-dialog-styles',
                        resolve: {
                            entity: ['CrmCampaign', function (CrmCampaign) {
                                return CrmCampaign.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('crm-campaign', null, {reload: 'crm-campaign'});
                    }, function () {
                        $state.go('^');
                    })
                }
            }]
        });
    }
})();

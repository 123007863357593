(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartFileController', ChartFileController);

    ChartFileController.$inject = ['chart', 'ChartFile', 'ParseLinks', 'CoreService', 'AlertService', '$uibModal',
        'ACTIONS', 'access', 'Auth', 'ConfirmationService'];

    function ChartFileController(chart, ChartFile, ParseLinks, CoreService, AlertService, $uibModal,
                                 ACTIONS, access, Auth, ConfirmationService) {
        var vm = this;
        vm.chart = chart;

        vm.page = 1;
        vm.itemsPerPage = 100;

        vm.toggleItem = toggleItem;
        vm.transition = transition;
        vm.add = add;
        vm.del = del;
        vm.getFileAttached = getFileAttached;

        function toggleItem() {
            CoreService.togglePanel('files');
        }

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_FILE_VIEW, access)) {

                return;
            }

            ChartFile.findAllByChart({
                id: chart.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.chartFile = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/chart-file/update/chart-file-dialog.html',
                controller: 'ChartFileDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function () {
                        return {
                            id: null,
                            chart: chart,
                            file: null
                        }
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('File').result.then(function () {
                ChartFile.delete({id: id}, function () {
                    vm.page = 1;
                    loadAll();
                });
            })
        }

        function getFileAttached(chartFile) {
            ChartFile.download({id: chartFile.id});
        }
    }
})();

/**
 * Created by PpTMUnited on 5/15/2017.
 */
(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('SystemService', SystemService);

    SystemService.$inject = ['$resource'];

    function SystemService($resource) {
        var resourceUrl = 'system/:id';

        return $resource(resourceUrl, {}, {
            'appData': {url: 'api/app-data', method: 'GET'},
        });
    }
})();


(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('admission-medication', {
                parent: 'entity',
                url: '/admission-medication/:id',
                data: {
                    authorities: [],
                    pageTitle: 'AdmissionMedications'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/admission-medication/list/admission-medication.html',
                        controller: 'AdmissionMedicationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    chart: ['Chart', '$stateParams', function (Chart, $stateParams) {

                        return Chart.get({id: $stateParams.id}).$promise;
                    }],
                    filter: function () {
                        return {
                            searchQuery: null,
                            chart: null
                        };
                    }
                }
            })
    }
})();

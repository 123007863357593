(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityController', FacilityController);

    FacilityController.$inject = ['Facility', 'CoreService', 'GenericEntityDatatableService', '$uibModal',
        'ConfirmationService', '$q', '$state'];

    function FacilityController(Facility, CoreService, GenericEntityDatatableService, $uibModal,
                                ConfirmationService, $q, $state) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        var entityStateName = 'facility'

        vm.descriptor = {
            title: 'Facilities',
            entityClassHumanized: 'Facility',
            newButtonTitle: 'New Facility',
            entityStateName: entityStateName,
            service: Facility,
            serviceMethod: 'byCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Facility',
                            action: function () {
                                add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/facility/create/facility-wizard-dialog.html',
                controller: 'FacilityWizardDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            street: null,
                            street_two: null,
                            city: null,
                            state: null,
                            zip: null,
                            county: null,
                            phone: null,
                            fax: null,
                            website: null,
                            logo: null,
                            id: null,
                            corporation: CoreService.getCorporation(),
                            timeZone: null,
                            sendResults: false,
                            reconnectCare: false,
                            autoSignMedication: false,
                            autoSignPatientOrder: false
                        };
                    }
                }
            }).result.then(function () {
                $state.reload();
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/facility/update/facility-dialog.html',
                controller: 'FacilityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Facility', function (Facility) {
                        return Facility.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                $state.reload();
            })
        }

        function del(id) {
            ConfirmationService.showDelete('Facility').result.then(function () {
                Facility.delete({id: id}, function () {
                    $state.reload();
                });
            })
        }
    }
})();

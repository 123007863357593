(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationMaintenanceController', EvaluationMaintenanceController);

    EvaluationMaintenanceController.$inject = ['CoreService', 'EvaluationTemplate', '$compile', 'DTOptionsBuilder', '$q', '$scope', '$filter',
        'DTColumnBuilder', 'GUIUtils', 'Evaluation'];

    function EvaluationMaintenanceController(CoreService, EvaluationTemplate, $compile, DTOptionsBuilder, $q, $scope, $filter,
                                             DTColumnBuilder, GUIUtils, Evaluation) {
        var vm = this;

        vm.openCalendar = openCalendar;
        vm.title = 'Evaluation Maintenance';

        vm.evaluationTemplates = [];
        vm.dtInstance = {};
        vm.filter = {
            startDate: new Date(2016, 7, 7),
            endDate: new Date(),
            updateStatus: true,
            facilityId: CoreService.getCurrentFacility().id,
            evaluationTemplates: []
        };
        vm.isWorking = false;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.updateCredentials = updateCredentials;
        vm.search = search;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                EvaluationTemplate.findAllByFacility({id: CoreService.getCurrentFacility().id}, function (result) {
                    vm.evaluationTemplates = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.evaluationTemplates, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [])
            .withDisplayLength(10)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder
                .newColumn('name')
                .withOption('class', 'names-col')
                .withTitle('Name'),
            DTColumnBuilder
                .newColumn('enabled')
                .withTitle('Enabled')
                .renderWith(function (data, type, full) {
                    return GUIUtils.colorHtmlYesNo(data);
                }),
            DTColumnBuilder
                .newColumn('requiredPatientSignature')
                .withTitle('Patient Signature')
                .renderWith(function (data, type, full) {
                    return GUIUtils.colorHtmlYesNo(data);
                }),
            DTColumnBuilder
                .newColumn('onlyOne')
                .withTitle('Only One')
                .renderWith(function (data, type, full) {
                    return GUIUtils.colorHtmlYesNo(data);
                }),
            DTColumnBuilder
                .newColumn('billable')
                .withTitle('Billable')
                .renderWith(function (data, type, full) {
                    return GUIUtils.colorHtmlYesNo(data);
                })
        ];

        function updateCredentials() {
            vm.isWorking = true;
            vm.filter.evaluationTemplates = getSelectedIds();

            Evaluation.updateCredentials(vm.filter, function (result) {
                vm.isWorking = false;
            });
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        function getSelectedIds() {
            var ids = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    ids.push(id);
                }
            }

            return ids;
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();

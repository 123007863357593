(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UnassignedResultsTopBoxController', UnassignedResultsTopBoxController);

    UnassignedResultsTopBoxController.$inject = ['facility', '$scope', '$state', '$q', 'DTColumnBuilder',
        'PatientResult', 'CoreService'];

    function UnassignedResultsTopBoxController(facility, $scope, $state, $q, DTColumnBuilder,
                                               PatientResult, CoreService) {
        var vm = this;
        vm.unassigned = 0;
        vm.facility = CoreService.getCurrentFacility();

        vm.unassigned = PatientResult.countUnassigned({id: vm.facility.id});
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('LabCompendium', LabCompendium);

    LabCompendium.$inject = ['$resource', 'DateUtils'];

    function LabCompendium($resource, DateUtils) {
        var resourceUrl = 'api/lab-compendiums/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByBarcode': {url: 'api/lab-compendiums/by-barcode/:barcode', method: 'GET', isArray: true},
            'findAllByPatientOrder': {
                url: 'api/lab-compendiums/by-patient-order/:id/:date', method: 'GET', isArray: true
            },
            'filter': {url: 'api/lab-compendiums/by-laboratory/filter', method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ResourceDialogController', ResourceDialogController);

    ResourceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'AuthItem', 'AUTH_ITEM_TYPE', 'CoreService', '$filter'];

    function ResourceDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                      AuthItem, AUTH_ITEM_TYPE, CoreService, $filter) {
        var vm = this;

        vm.authItem = entity;
        vm.children = angular.copy(vm.authItem.children);
        vm.authItemChildren = [];
        vm.facility = CoreService.getCurrentFacility();
        vm.actionFilterText = '';
        vm.selectedAllActions = false;
        vm.actionsLoaded = false;
        vm.corporation = CoreService.getCorporation();
        vm.allActions = getAllActions();

        vm.clear = clear;
        vm.save = save;
        vm.actionFilter = actionFilter;
        vm.selectAllActions = selectAllActions;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function actionFilter() {
            vm.filteredActions = $filter('filter')(vm.allActions, vm.actionFilterText, undefined);
        }

        function selectAllActions() {
            for (var i = 0; i < vm.filteredActions.length; i++) {
                vm.filteredActions[i].checked = vm.selectedAllActions;
            }
        }

        function getAllActions() {

            return AuthItem.queryByType({
                type: AUTH_ITEM_TYPE.TYPE_ACTION,
                id: vm.corporation.id
            }, function (result) {
                //we set all actions the first time.
                vm.filteredActions = result;
                selectAuthItems(vm.authItem, vm.filteredActions);
            });
        }

        function selectAuthItems(authItem, authItems) {
            if (authItem.children && authItem.children.length > 0) {
                for (var i = 0; i < authItem.children.length; i++) {
                    var index = _.findIndex(authItems, function (item) {
                        return item.id === authItem.children[i].child.id;
                    });

                    authItems[index].checked = index !== -1;
                }
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.authItem.type = AUTH_ITEM_TYPE.TYPE_RESOURCE;
            var children =
                _.filter(vm.filteredActions, function (action) {
                    return action.checked;
                });

            vm.authItem.children = _.map(children, function (action) {
                return {
                    id: null,
                    parent: null,
                    child: action
                };
            });

            if (vm.authItem.id !== null) {
                AuthItem.update(vm.authItem, onSaveSuccess, onSaveError);
            } else {
                AuthItem.save(vm.authItem, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:authItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

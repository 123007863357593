(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RoleDialogController', RoleDialogController);

    RoleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'AuthItem', 'AUTH_ITEM_TYPE', '$filter', 'CoreService'];

    function RoleDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                  AuthItem, AUTH_ITEM_TYPE, $filter, CoreService) {

        var vm = this;

        vm.authItem = entity;
        vm.children = angular.copy(vm.authItem.children);
        vm.roleFilterText = '';
        vm.resourceFilterText = '';
        vm.actionFilterText = '';
        vm.selectedAllRoles = false;
        vm.selectedAllResources = false;
        vm.selectedAllActions = false;
        vm.rolesLoaded = false;
        vm.resourcesLoaded = false;
        vm.actionsLoaded = false;
        vm.corporation = CoreService.getCorporation();
        vm.allRoles = getAllRoles();
        vm.allResources = getAllResources();
        vm.allActions = getAllActions();
        vm.showRoles = true;

        vm.clear = clear;
        vm.save = save;
        vm.roleFilter = roleFilter;
        vm.resourceFilter = resourceFilter;
        vm.actionFilter = actionFilter;
        vm.selectRole = selectRole;
        vm.selectAllRoles = selectAllRoles;
        vm.selectResource = selectResource;
        vm.selectAllResources = selectAllResources;
        vm.selectAction = selectAction;
        vm.selectAllActions = selectAllActions;
        vm.blacklist = blacklist;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function roleFilter() {
            vm.filteredRoles = $filter('filter')(vm.allRoles, vm.roleFilterText, undefined);
        }

        function resourceFilter() {
            vm.filteredResources = $filter('filter')(vm.allResources, vm.resourceFilterText, undefined);
        }

        function actionFilter() {
            vm.filteredActions = $filter('filter')(vm.allActions, vm.actionFilterText, undefined);
        }

        function selectAuthItemRecursive(authItem, authItems) {

            if (authItem.children && authItem.children.length > 0) {
                for (var i = 0; i < authItem.children.length; i++) {
                    var index = _.findIndex(authItems, function (item) {
                        return item.id === authItem.children[i].child.id;
                    });
                    if (index !== -1) {
                        authItems[index].checked = true;
                    }
                    selectAuthItemRecursive(authItem.children[i].child, authItems);
                }
            } else {
                var index = _.findIndex(authItems, function (item) {
                    return item.id === authItem.id;
                });
                if (index !== -1) {
                    authItems[index].checked = true;
                }
            }
        }

        function resetModel(authItem, status) {

            //We search if exist and remove it.
            var index = _.findIndex(vm.children, function (item) {
                return item.child.id === authItem.id;
            });
            if (index !== -1) {
                vm.children.splice(index, 1);
            }
            //If th status is true
            if (status) {
                var authItemChild = {
                    id: null,
                    parent: null,
                    child: authItem
                };
                vm.children.push(authItemChild);
            }
        }

        function updateModel(authItem, status) {

            resetModel(authItem, status);
            //We search avery child and remove it in case this exists.
            AuthItem.get({id: authItem.id}, function (res) {
                _.forEach(res.children, function (child) {
                    var i = _.findIndex(vm.children, function (item) {
                        return item.child.id === child.child.id;
                    });
                    if (i !== -1) {
                        vm.children.splice(i, 1);
                    }
                });
            });
        }

        function getAllRoles() {

            return AuthItem.queryByType({
                type: AUTH_ITEM_TYPE.TYPE_ROLE,
                id: vm.corporation.id
            }, function (result) {
                //we set all roles the first time and filter the editing role to avoid infinite loop.
                if (vm.authItem.id) {
                    vm.filteredRoles = _.filter(result, function (role) {
                        return vm.authItem.id !== role.id &&
                            (
                                role.children == null ||
                                !_.some(role.children,function (credentialChild) {
                                    return credentialChild.child != null && credentialChild.child.id === vm.authItem.id;
                                })
                            )
                    });

                    // vm.filteredRoles = $filter('filter')(result, {id: vm.authItem.id}, function (actual, expected) {
                    //     return actual !== expected;
                    // });
                } else {
                    vm.filteredRoles = result;
                }
                // vm.children.push(vm.filteredRoles);
                selectAuthItemRecursive(vm.authItem, vm.filteredRoles);

                vm.showRoles = _.some(vm.filteredRoles, function(item) {
                    return item.checked && item.name !== 'ROLE_USER';
                })
            });
        }

        function getAllResources() {

            return AuthItem.queryByType({
                type: AUTH_ITEM_TYPE.TYPE_RESOURCE,
                id: vm.corporation.id
            }, function (result) {
                //we set all resources the first time.
                vm.filteredResources = result;
                // vm.children.push(vm.filteredResources);
                selectAuthItemRecursive(vm.authItem, vm.filteredResources);
            });
        }

        function getAllActions() {

            return AuthItem.queryByType({
                type: AUTH_ITEM_TYPE.TYPE_ACTION,
                id: vm.corporation.id
            }, function (result) {
                //we set all actions the first time.
                vm.filteredActions = result;
                // vm.children.push(vm.filteredActions);
                selectAuthItemRecursive(vm.authItem, vm.filteredActions);
            });
        }

        function selectRole(role) {

            toggleRole(role);
            for (var i = 0; i < vm.filteredRoles.length; i++) {
                if (!vm.filteredRoles[i].checked) {
                    vm.selectedAllRoles = false;
                    return;
                }
            }
            vm.selectedAllRoles = true;
        }

        function selectAllRoles() {

            for (var i = 0; i < vm.filteredRoles.length; i++) {
                vm.filteredRoles[i].checked = vm.selectedAllRoles;
                toggleRole(vm.filteredRoles[i]);
            }
        }

        function toggleRole(role) {

            updateModel(role, role.checked);
            AuthItem.get({id: role.id}, function (rol) {
                _.forEach(rol.children, function (child) {
                    var index = _.findIndex(vm.filteredResources, function (item) {
                        return item.id === child.child.id;
                    });
                    if(index !== -1){
                        vm.filteredResources[index].checked = role.checked;
                        toggleResource(vm.filteredResources[index]);
                    }
                });
            });
        }

        function selectResource(resource) {

            toggleResource(resource);
            for (var i = 0; i < vm.filteredResources.length; i++) {
                if (!vm.filteredResources[i].checked) {
                    vm.selectedAllResources = false;
                    return;
                }
            }
            vm.selectedAllResources = true;
        }

        function selectAllResources() {

            for (var i = 0; i < vm.filteredResources.length; i++) {
                vm.filteredResources[i].checked = vm.selectedAllResources;
                toggleResource(vm.filteredResources[i])
            }
        }

        function toggleResource(resource) {

            updateModel(resource, resource.checked);
            AuthItem.get({id: resource.id}, function (res) {
                _.forEach(res.children, function (child) {
                    var index = _.findIndex(vm.filteredActions, function (item) {
                        return item.id === child.child.id;
                    });
                    vm.filteredActions[index].checked = resource.checked;
                });
            });
        }

        function selectAction(action) {

            resetModel(action, action.checked);
            for (var i = 0; i < vm.filteredActions.length; i++) {
                if (!vm.filteredActions[i].checked) {
                    vm.selectedAllActions = false;
                    return;
                }
            }
            vm.selectedAllActions = true;
        }

        function selectAllActions() {

            for (var i = 0; i < vm.filteredActions.length; i++) {
                vm.filteredActions[i].checked = vm.selectedAllActions;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.authItem.type = AUTH_ITEM_TYPE.TYPE_ROLE;
            vm.authItem.children = vm.children;
            if (vm.authItem.id !== null) {
                AuthItem.update(vm.authItem, onSaveSuccess, onSaveError);
            } else {
                AuthItem.save(vm.authItem, onSaveSuccess, onSaveError);
            }
            // vm.authItem.children = [];
            // //Loop through roles.
            // _.forEach(vm.filteredRoles, function (role) {
            //     if (role.checked) {
            //         var authItemChild = {
            //             id: null,
            //             parent: null,//vm.authItem,
            //             child: role
            //         };
            //         vm.authItem.children.push(authItemChild);
            //     }
            // });
            // //Loop through resource.
            // _.forEach(vm.filteredResources, function (resource) {
            //     if (resource.checked) {
            //         var authItemChild = {
            //             id: null,
            //             parent: null,//vm.authItem,
            //             child: resource
            //         };
            //         vm.authItem.children.push(authItemChild);
            //     }
            // });
            // //Loop through left actions.
            // _.forEach(vm.filteredActions, function (action) {
            //     if (action.checked) {
            //         var authItemChild = {
            //             id: null,
            //             parent: null,//vm.authItem,
            //             child: action
            //         };
            //         vm.authItem.children.push(authItemChild);
            //     }
            // });
            // //Ready entity for saving
            // if (vm.authItem.id !== null) {
            //     AuthItem.update(vm.authItem, onSaveSuccess, onSaveError);
            // } else {
            //     AuthItem.save(vm.authItem, onSaveSuccess, onSaveError);
            // }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:authItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function blacklist(name) {
            var ROLE_EXIST = _.filter(vm.allRoles, {name: name});

            return ROLE_EXIST.length == 0;
        }
    }
})();

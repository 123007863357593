(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationHistoryNewController', EvaluationHistoryNewController);

    EvaluationHistoryNewController.$inject = ['Evaluation', 'DTOptionsBuilder', 'DTColumnBuilder', 'DataTablesService',
                                             'chart', '$uibModalInstance', '$timeout', 'process'];

    function EvaluationHistoryNewController(Evaluation, DTOptionsBuilder, DTColumnBuilder, DataTablesService,
                                           chart, $uibModalInstance, $timeout, process) {
        var vm = this;
        vm.chart = chart;

        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.evaluationsHistory = [];
        vm.process = process;

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.retrieve = retrieve;

        loadAll();

        function loadAll() {
            Evaluation.findAllByChartAndPatientProcess({
                chartId: chart.id,
                processId:  vm.process.id,
                searchQuery: vm.searchQuery,
                page:  vm.page - 1,
                size: vm.itemsPerPage,
                deleted: true
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.evaluationsHistory = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }

        function retrieve(id) {
            Evaluation.retrieve({id: id}, function () {
                loadAll();
            });
        }
    }
})();

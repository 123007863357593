(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SignController', SignController);

    SignController.$inject = ['JSignature', 'CoreService', '$state', '$rootScope', 'Employee'];

    function SignController(JSignature, CoreService, $state, $rootScope, Employee) {
        var vm = this;

        $rootScope.background = '';
        vm.step = 0;
        vm.signature = {signature : ""};

        //We check if the employee has signed we redirect to the previous state
        vm.employee = CoreService.getCurrentEmployee();

        // if (vm.employee.signed) {
        //     if (!CoreService.getCurrentEmployee().signed) {
        //         CoreService.setCurrentEmployee(employee);
        //     }
        //
        //     $state.go("home");
        // }

        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        }

        vm.save = save;
        vm.next = next;

        function save() {
            vm.isSaving = true;
            Employee.replaceSignature({signature: vm.signature.signature}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;

            if (result && result.signed) {
                CoreService.setCurrentEmployee(result);

                $state.go("home");
            }
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.isError = false;
        }

        function next() {
            if (vm.step == 0) {
                vm.step++;
            } else {
                save();
            }
        }
    }
})();

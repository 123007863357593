(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidLabCompendiumDialogController', CovidLabCompendiumDialogController);

    CovidLabCompendiumDialogController.$inject = ['$timeout', '$scope', 'CovidFacilityLab', '$uibModalInstance', 'entity',
        'CovidLabCompendium', 'LabCompendium', 'CoreService'];

    function CovidLabCompendiumDialogController($timeout, $scope, CovidFacilityLab, $uibModalInstance, entity,
                                                CovidLabCompendium, LabCompendium, CoreService) {
        var vm = this;

        vm.covidLabCompendium = entity;
        vm.labCompendiums = [];
        vm.facility = CoreService.getCurrentFacility();
        vm.covidFacilityLab = null;

        vm.clear = clear;
        vm.save = save;
        vm.getLabCompendium = getLabCompendium;

        CovidFacilityLab.findAllByFacility({id: vm.covidLabCompendium.facility.id}, function (result) {
            if (result.length > 0) {
                vm.covidFacilityLab = result[0];

                vm.getLabCompendium();
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.covidLabCompendium.id !== null) {
                CovidLabCompendium.update(vm.covidLabCompendium, onSaveSuccess, onSaveError);
            } else {
                CovidLabCompendium.save(vm.covidLabCompendium, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:covidLabCompendiumUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getLabCompendium(query) {
            if (vm.covidFacilityLab) {
                LabCompendium.filter({
                    laboratoryId: vm.covidFacilityLab.facilityLab.laboratoryId,
                    searchQuery: query,
                    page: vm.page - 1,
                    size: 40,
                    sort: 'code,asc',
                    facilityId: vm.covidLabCompendium.facility.id
                }, function (result) {
                    vm.labCompendiums = result;
                });
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmReferralSourceDialogController', CrmReferralSourceDialogController);

    CrmReferralSourceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'CrmReferralSource', 'CountryState', 'Employee', 'CrmReferralSourceType', 'Corporation',
        'CrmReferralSourceTypeSearch', 'CoreService', 'TypeLevelCare'];

    function CrmReferralSourceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CrmReferralSource,
                                                CountryState, Employee, CrmReferralSourceType, Corporation,
                                                CrmReferralSourceTypeSearch, CoreService, TypeLevelCare) {
        var vm = this;

        vm.crmReferralSource = entity;
        vm.facility = CoreService.getCurrentFacility();
        vm.countrystates = CountryState.query();
        vm.crmreferralsourcetypes = CrmReferralSourceTypeSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.typelevelcares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.crmReferralSource.id !== null) {
                CrmReferralSource.update(vm.crmReferralSource, onSaveSuccess, onSaveError);
            } else {
                CrmReferralSource.save(vm.crmReferralSource, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:crmReferralSourceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.corporation.id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('crm-outbound-referral.new', {
                parent: 'crm-outbound-referral',
                url: '/new',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-outbound-referral/crm-outbound-referral-dialog.html',
                            controller: 'CrmOutboundReferralDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CoreService', function (CoreService) {
                                    return {
                                        name: null,
                                        id: null,
                                        doa: null,
                                        corporation: CoreService.getCorporation()
                                    };
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-outbound-referral', null, {reload: 'crm-outbound-referral'});
                        }, function () {
                            $state.go('crm-outbound-referral');
                        });
                    }
                }]
            })
            .state('crm-outbound-referral.edit', {
                parent: 'crm-outbound-referral',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OUTBOUND_REFERRAL, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-outbound-referral/crm-outbound-referral-dialog.html',
                            controller: 'CrmOutboundReferralDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CrmOutboundReferral', function (CrmOutboundReferral) {
                                    return CrmOutboundReferral.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-outbound-referral', null, {reload: 'crm-outbound-referral'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            });
    }
})();

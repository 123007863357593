(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('crm-opportunity', {
                parent: 'entity',
                url: '/crm-opportunity',
                data: {
                    authorities: [],
                    pageTitle: 'Opportunities'
                },
                ncyBreadcrumb: {
                    label: 'Opportunities'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/crm-opportunity/crm-opportunity.html',
                        controller: 'CrmOpportunityController',
                        controllerAs: 'vm'
                    },
                    'opportunity@crm-opportunity': {
                        templateUrl: 'app/entities/crm-opportunity/crm-opportunities.html',
                        controller: 'CrmOpportunitiesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: ['CoreService', function (CoreService) {
                        return {
                            searchQuery: null,
                            corporationId: CoreService.getCorporation().id
                        };
                    }]
                }
            })
            .state('crm-opportunity-detail', {
                parent: 'crm-opportunity',
                url: '/{id}/detail',
                data: {
                    authorities: [],
                    pageTitle: 'Crm Opportunity Detail'
                },
                ncyBreadcrumb: {
                    label: 'Opportunities'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/crm-opportunity/crm-opportunity-detail.html',
                        controller: 'CrmOpportunityDetailController',
                        controllerAs: 'vm'
                    },
                    'notes@crm-opportunity-detail': {
                        templateUrl: 'app/entities/crm-note/crm-notes.html',
                        controller: 'CrmNoteController',
                        controllerAs: 'vm'
                    },
                    'calls@crm-opportunity-detail': {
                        templateUrl: 'app/entities/crm-call/crm-calls.html',
                        controller: 'CrmCallController',
                        controllerAs: 'vm'
                    },
                    'tasks@crm-opportunity-detail': {
                        templateUrl: 'app/entities/crm-task/crm-tasks.html',
                        controller: 'CrmTaskController',
                        controllerAs: 'vm'
                    },
                    'emails@crm-opportunity-detail': {
                        templateUrl: 'app/entities/crm-email/crm-emails.html',
                        controller: 'CrmEmailController',
                        controllerAs: 'vm'
                    },
                    'insurance@crm-opportunity-detail': {
                        templateUrl: 'app/entities/crm-insurance/crm-insurances.html',
                        controller: 'CrmInsuranceController',
                        controllerAs: 'vm'
                    },
                    'crm-activity@crm-opportunity-detail': {
                        templateUrl: 'app/entities/crm-activity/crm-activities.html',
                        controller: 'CrmActivityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['CrmOpportunity', '$stateParams', function(CrmOpportunity, $stateParams) {
                        return CrmOpportunity.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
    }
})();

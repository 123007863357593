(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('incident-report', {
                parent: 'entity',
                url: '/incident-report',
                data: {
                    authorities: [],
                    pageTitle: 'IncidentReports'
                },
                ncyBreadcrumb: {
                    label: 'Incident Reports'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report/list/incident-report.html',
                        controller: 'IncidentReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS, access);
                }]
            })
            .state('incident-report.new', {
                parent: 'incident-report',
                url: '/new',
                data: {
                    authorities: []
                },
                ncyBreadcrumb: {
                    label: 'New Incident Report',
                    parent: 'incident-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report/update/incident-report-edit.html',
                        controller: 'IncidentReportEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    entity: ['INCIDENT_REPORT_STATUS', 'CoreService', function (INCIDENT_REPORT_STATUS, CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            id: null,
                            date: new Date(),
                            patientInvolves: [],
                            typeLevelCare: null,
                            incidentDepartments: [],
                            assignReviewers: [],
                            incidentTypes: [],
                            incidentSeverity: null,
                            injury: false,
                            reachClient: false,
                            location: null,
                            buildings: [],
                            employees: [],
                            onDutySupervisors: [],
                            notified: false,
                            notifiedSupervisors: [],
                            status: INCIDENT_REPORT_STATUS.PENDING
                        };
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS, access);
                }]
            })
            .state('incident-report.edit', {
                parent: 'incident-report',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                ncyBreadcrumb: {
                    label: 'Edit Incident Report',
                    parent: 'incident-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report/update/incident-report-edit.html',
                        controller: 'IncidentReportEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    entity: ['IncidentReport', '$stateParams', function (IncidentReport, $stateParams) {
                        return IncidentReport.get({id: $stateParams.id}).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', 'entity', '$state', 'INCIDENT_REPORT_STATUS',
                    function (Auth, ACTIONS, access, entity, $state, INCIDENT_REPORT_STATUS) {
                        Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS, access);

                        if (entity.status === INCIDENT_REPORT_STATUS.FINALIZED) {
                            $state.go('incident-report.detail', {id: entity.id});
                        }
                    }]
            })
            .state('incident-report.detail', {
                parent: 'incident-report',
                url: '/{id}/view',
                data: {
                    authorities: []
                },
                ncyBreadcrumb: {
                    label: 'Detail Incident Report',
                    parent: 'incident-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/incident-report/update/incident-report-edit.html',
                        controller: 'IncidentReportEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    entity: ['IncidentReport', '$stateParams', function (IncidentReport, $stateParams) {
                        return IncidentReport.get({id: $stateParams.id}).$promise;
                    }],
                    disable: [function () {
                        return true;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_INCIDENT_REPORTS, access);
                }]
            })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AbnormalAlertDialogController', AbnormalAlertDialogController);

    AbnormalAlertDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'AbnormalAlert',
        'LabCompendium', 'CoreService'];

    function AbnormalAlertDialogController($timeout, $scope, $uibModalInstance, entity, AbnormalAlert,
                                           LabCompendium, CoreService) {
        var vm = this;

        vm.abnormalAlert = entity;
        vm.testCodes = [];
        vm.facility = CoreService.getCurrentFacility();
        vm.labCompendiums = [];

        vm.getLabCompendium = getLabCompendium;
        vm.changeLaboratory = changeLaboratory;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.abnormalAlert.id !== null) {
                AbnormalAlert.update(vm.abnormalAlert, onSaveSuccess, onSaveError);
            } else {
                vm.ids = [];
                vm.labCompendiums.forEach(function (element) {
                    vm.ids.push(element.id);
                });
                AbnormalAlert.saveAll({ids: vm.ids, facilityId: vm.facility.id}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:abnormalAlertUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getLabCompendium(query) {
            if (query && query.length > 1) {
                LabCompendium.filter({
                    laboratoryId: vm.abnormalAlert.laboratory.id,
                    searchQuery: query,
                    page: vm.page - 1,
                    size: 40,
                    sort: 'code,asc',
                    facilityId: vm.facility.id
                }, function (result, headers) {
                    vm.testCodes = result;
                });
            }
        }

        function changeLaboratory() {
            vm.testCodes = [];
            vm.labCompendiums = [];
        }
    }
})();

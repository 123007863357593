(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PendingByStaffReviewBoxController', PendingByStaffReviewBoxController);

    PendingByStaffReviewBoxController.$inject = ['DashboardService', 'CoreService'];

    function PendingByStaffReviewBoxController(DashboardService, CoreService) {

        var vm = this;

        vm.count = 0;
        vm.facility = CoreService.getCurrentFacility();

        DashboardService.countAllByFilter({
            facilityId: vm.facility.id,
            status: "InReview",
            empty: null,
            forceStaffReviewSignature: true,
            existStaffReviewSignature: false
        }, function (data) {
            if (data.count) {
                vm.count = data.count;
            }
        });
    }
})();

/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PhysicianReviewELabsController', PhysicianReviewELabsController);

    PhysicianReviewELabsController.$inject = ['moment', 'GUIUtils', '$q', 'PatientResult', 'CoreService',
        'PopupService', 'toastr', '$rootScope', 'signPatientResultCredentials',
        'GenericEntityDatatableService'];

    function PhysicianReviewELabsController(moment, GUIUtils, $q, PatientResult, CoreService,
                                            PopupService, toastr, $rootScope, signPatientResultCredentials,
                                            GenericEntityDatatableService) {
        var vm = this;

        vm.facility = CoreService.getCurrentFacility();

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'E Labs',
            entityClassHumanized: 'Patient Results',
            entityStateName: 'patient-result',
            service: PatientResult,
            serviceMethod: 'getAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            withSelectionColumn: true,
            isVisibleSelectionColumn: function () {
                return CoreService.currentEmployeeHaveAnyCredential(signPatientResultCredentials);
            },
            options: [
                {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            ],
            selectionColumn: 'id',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'patientName',
                    title: 'Patient Name',
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dob).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Barcode',
                    render: function (data) {
                        return data.accessionNumber ? data.accessionNumber : '';
                    }
                },
                {
                    name: null,
                    title: 'Collection Date',
                    render: function (data) {
                        return moment(data.collectionDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Received Date',
                    render: function (data) {
                        return moment(data.lastModifiedDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Alert',
                    options: [
                        {key: 'width', value: '175px'}
                    ],
                    render: function (data) {
                        if (data.abnormalRange) {
                            return GUIUtils.getStatusTemplate('ABNORMAL', 'danger');
                        } else {
                            return GUIUtils.getStatusTemplate('NORMAL', 'success');
                        }
                    }
                },
                {
                    name: 'status',
                    title: 'Status',
                    render: function (data) {
                        if (data.status == 'PARTIAL') {
                            return GUIUtils.getStatusTemplate(data.status, 'warning');
                        } else {
                            return GUIUtils.getStatusTemplate(data.status, 'primary');
                        }
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        vm.details(data.id);
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getViewPdfAction(), {
                    action: function (data) {
                        vm.printRangePDFs(data.id);
                    },
                })
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getSignAction(), {
                            name: 'Sign Selected',
                            disabledOnEmpty: true,
                            ngClass: function () {
                                return ['bg-green']
                            },
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.signPatientResult(selected);
                                }
                            },
                            ngIf: function () {
                                return CoreService.currentEmployeeHaveAnyCredential(signPatientResultCredentials);
                            },
                        }),
                    ]
                },
            ],
            filters: [
                {
                    name: 'status',
                    visible: false,
                    defaultValue: function () {
                        return 'FINAL'
                    }
                },
                {
                    name: 'assigned',
                    visible: false,
                    defaultValue: function () {
                        return true
                    }
                },
                {
                    name: 'signed',
                    visible: false,
                    defaultValue: function () {
                        return false;
                    }
                },
                {
                    name: 'patientName',
                    // title: 'Patient Name',
                    placeholder: 'Patient Name...',
                    type: 'string',
                    visible: true,
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
                },
                {
                    name: 'dob',
                    // title: 'Day of Birth',
                    placeholder: 'Day of Birth...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'accessionNumber',
                    // title: 'Barcode',
                    placeholder: 'Barcode...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'startDate',
                    // title: 'Start Date',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.startDate ? moment(filter.startDate).format("YYYY-MM-DD") : null;
                    },

                    visible: true
                },
                {
                    name: 'endDate',
                    // title: 'End Date',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.endDate ? moment(filter.endDate).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
            ]
        }

        vm.printRangePDFs = printRangePDFs;
        vm.signPatientResult = signPatientResult;

        function printRangePDFs(id) {
            var ids;

            if (id) {
                ids = [id];
            }
            if (ids.length > 0) {
                PatientResult.getAllInOneRangePdf({ids: ids});
            }
        }

        function signPatientResult(selected) {
            var ids = _.map(selected, function (item) {
                return item.id;
            });

            return $q(function (resolve, reject) {
                PopupService.showSignaturePopup().result.then(function (data) {
                    PatientResult.sign({
                        signature: data.employeeSignature,
                        pin: data.employeeSignaturePin,
                        ids: ids,
                        facilityId: vm.facility.id
                    }, function (result) {
                        if (result) {
                            toastr.success("The Patient Result was signed successfully.");
                        } else {
                            toastr.error('Some patient results could not be signed.');
                        }

                        resolve();
                    }, function () {
                        toastr.error('Some patient results could not be signed.');
                        resolve();
                    });
                });
            });
        }
    }
})();

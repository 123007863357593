(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .filter('empty', empty);

    function empty() {
        return emptyFilter;

        function emptyFilter(value, field, mask) {
            if ( mask == undefined) {
                mask = "-";
            }

            return value ? (field == undefined ? value : value[field]) : mask;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidQuestionDialogController', CovidQuestionDialogController);

    CovidQuestionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'CovidQuestion', 'CoreService', 'Icd10Search'];

    function CovidQuestionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                            CovidQuestion, CoreService, Icd10Search) {
        var vm = this;

        vm.covidQuestion = entity;

        vm.clear = clear;
        vm.save = save;
        vm.getDiagnoses = getDiagnoses;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.covidQuestion.id !== null) {
                CovidQuestion.update(vm.covidQuestion, onSaveSuccess, onSaveError);
            } else {
                CovidQuestion.save(vm.covidQuestion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:covidQuestionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getDiagnoses(query) {
            if (query && query.length > 1) {
                Icd10Search.query({query: query}, function (result) {
                    vm.diagnoses = result;
                });
            }
        }
    }
})();

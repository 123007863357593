(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('lab-requisition', {
                parent: 'entity',
                url: '/lab-requisition',
                data: {
                    authorities: [],
                    pageTitle: 'Requisitions'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order/lab-requisition/list/lab-requisition.html',
                        controller: 'LabRequisitionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: ['CoreService', function (CoreService) {
                        return {
                            facilityId: CoreService.getCurrentFacility().id,
                            date: null,
                            itemStatus: null,
                            firstName: null,
                            lastName: null,
                            dob: null,
                            barcode: null,
                            laboratory: null,
                            start: null,
                            end: null
                        };
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [
                            ACTIONS.ACTION_ADD_PATIENT_ORDER_LAB_REQUISITION
                        ];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                }
            })
    }
})();

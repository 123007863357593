(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('blue-alerts', {
                parent: 'entity',
                url: '/blue-alerts',
                data: {
                    authorities: [],
                    pageTitle: 'Blue Alerts'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/urgent-issues/alert/urgent-issue-alerts.html',
                        controller: 'UrgentIssueAlertController',
                        controllerAs: 'vm'
                    }
                },
                // resolve: {
                //     access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                //         var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_RACE];
                //         return Auth.loadActionsAccess(actions).$promise;
                //     }]
                // },
                // onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                //     Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_TYPE_RACE, access);
                // }]
            })
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationMedication', EvaluationMedication);

    EvaluationMedication.$inject = ['$resource'];

    function EvaluationMedication ($resource) {
        var resourceUrl =  'api/evaluation-medications/:id';

        return $resource(resourceUrl, {}, {
            'findAllByEvaluation': {method: 'GET', url: 'api/evaluation-medications/by-evaluation/:id', isArray: true}
        });
    }
})();

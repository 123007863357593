(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationDietController', EvaluationDietController);

    EvaluationDietController.$inject = ['$scope', '$state', 'EvaluationDiet', 'entity', '$uibModal', 'Diet'];

    function EvaluationDietController($scope, $state, EvaluationDiet, entity, $uibModal, Diet) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationDiets = [];

        vm.loadAll = loadAll;
        vm.add = add;
        vm.edit = edit;
        vm.history = history;

        loadAll();

        function loadAll() {
            EvaluationDiet.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationDiets = result;
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/diet/update/diet-dialog.html',
                controller: 'EvaluationDietDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            evaluation: {id: vm.evaluation.id},
                            diet: {
                                description: null,
                                id: null,
                                endDate: null,
                                startDate: null,
                                chart: vm.evaluation.chart
                            }
                        }
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function edit(evaluationDiet) {
            $uibModal.open({
                templateUrl: 'app/entities/diet/update/diet-dialog.html',
                controller: 'DietDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Diet', function (Diet) {
                        return Diet.get({id: evaluationDiet.dietId}).$promise;
                    }]
                }
            }).result.then(function (result) {
                Diet.findAllActiveDietsByChart({id: vm.evaluation.chartId}, function (dietsActive) {
                    if (result.id && !_.find(dietsActive, {id: result.id})) {
                        EvaluationDiet.delete({id: evaluationDiet.id}, function () {
                            loadAll();
                        })
                    } else {
                        loadAll();
                    }
                });
            }, function () {
                loadAll();
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-diet/history/evaluation-diets-history.html',
                controller: 'EvaluationDietsHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.evaluation.chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }
    }
})();

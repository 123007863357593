/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReferralSourceBoxController', ReferralSourceBoxController);

    ReferralSourceBoxController.$inject = ['facility', '$scope', '$state', 'CoreService', 'Chart'];

    function ReferralSourceBoxController(facility, $scope, $state, CoreService, Chart) {
        var vm = this;

        vm.countReferrers = null;
        vm.count = 0;

        vm.openCharts = openCharts;
        loadData();


        function loadData() {
            vm.countReferrers = Chart.countReferrers({id: CoreService.getCurrentFacility().id}, function (result) {
                _.forEach(result, function (el) {
                    vm.count += el.count;
                });
            });
        }

        function openCharts(referrer) {
            $state.go('charts-referrer', {referrer: referrer});
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PendingReport', PendingReport);

    PendingReport.$inject = ['$resource'];

    function PendingReport ($resource) {
        var resourceUrl =  'api/pending-reports/:id';

        return $resource(resourceUrl, {}, {
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationDiagnosesHistoryController', EvaluationDiagnosesHistoryController);

    EvaluationDiagnosesHistoryController.$inject = ['$uibModalInstance', 'Diagnoses', 'chart'];

    function EvaluationDiagnosesHistoryController($uibModalInstance, Diagnoses, chart) {
        var vm = this;

        vm.diagnosesInactive = [];
        vm.diagnosesInactive = Diagnoses.findAllInactiveDiagnosesByChart({id: chart.id});

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('loaderWidget', loaderWidget);

    loaderWidget.$inject = [];

    function loaderWidget() {
        return {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-loader/loader.html'
        }
    }
})();

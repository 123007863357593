(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('evaluation-audit', {
            parent: 'entity',
            url: '/evaluation-audit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'EvaluationAudits'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/evaluation-audit/list/evaluation-audit.html',
                    controller: 'EvaluationAuditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                    var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_EVALUATION_AUDIT];
                    return Auth.loadActionsAccess(actions).$promise;
                }]
            },
            onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_EVALUATION_AUDIT, access);
            }]
        });
    }
})();

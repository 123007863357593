(function () {
    'use strict';

    angular
        .module('udt')
        .filter('patientName', patientName);

    function patientName() {
        return patientNameFilter;

        function patientNameFilter(value) {
            return value == null ? "" :
                value.lastName + ", " + value.firstName + (value.middleInitial ? " " + value.middleInitial : "");
        }
    }
})();

/**
 * Created by PpTMUnited on 2/21/2017.
 */
(function () {
    'use strict';

    angular
        .module('udt')
        .controller('UdtCropImageDialogController', UdtCropImageDialogController);

    UdtCropImageDialogController.$inject = ['$scope', '$uibModalInstance', 'CoreService', 'image', 'defaultImage', 'cropType'];

    function UdtCropImageDialogController($scope, $uibModalInstance, CoreService, image, defaultImage, cropType) {

        var vm = this;

        vm.croppedImage = "";
        vm.image = image.image;
        vm.defaultImage = defaultImage ? defaultImage.picture : null;
        vm.cropType = cropType ? cropType : null;

        vm.save = save;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss(null);
        }

        function save() {
            var result = {
                cropped: vm.croppedImage,
                image: vm.image
            };

            $uibModalInstance.dismiss(result);
        }
    }
})();


(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssuesHistoryDialogController', UrgentIssuesHistoryDialogController);

    UrgentIssuesHistoryDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'UrgentIssues', 'chart',
        'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile', 'UrgentIssueEmployee'];

    function UrgentIssuesHistoryDialogController($timeout, $scope, $uibModalInstance, UrgentIssues, chart,
                                          DTOptionsBuilder, DTColumnBuilder, $q, $compile, UrgentIssueEmployee) {
        var vm = this;

        vm.employees = [];
        vm.showDetails = false;
        vm.dtInstance = {};

        vm.clear = clear;
        vm.details = details;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        init();

        function init() {
            vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();

                UrgentIssues.byChart({id: chart.id}, function (result) {
                    vm.allUrgentIssues = result;
                    defer.resolve(result);
                });

                return defer.promise;
            }).withPaginationType('full_numbers')
                .withBootstrap()
                .withDOM('tp')
                .withLanguage({
                    "paginate": {
                        "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                        "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                        "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                        "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                    }
                })
                .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
                .withOption('createdRow', function (row, data, dataIndex) {
                    if (!data.checked) {
                        //here i add a custom class to checked issue
                        angular.element(row).addClass('highlighted')
                    }
                });

            vm.dtColumns = [
                DTColumnBuilder
                    .newColumn(null)
                    .withTitle('')
                    .withOption('class', 'first-col')
                    .notSortable()
                    .renderWith(function (data, type, full, meta) {
                        return '<span class="row-count">' + (meta.row + 1) + '</span>';
                    }),
                DTColumnBuilder
                    .newColumn('description')
                    .withTitle('Description')
                    .withOption('class', 'description-text')
                    .notSortable(),
                DTColumnBuilder
                    .newColumn(null)
                    .withTitle('Actions')
                    .withOption('class', 'last-col')
                    .notSortable()
                    .renderWith(actionsHtml)
            ];
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function actionsHtml(data, type, full, meta) {
            var btn = '<a class="btn-details" ng-click="vm.details(' + data.id + ')" title="See Details">' +
                '<i class="fa fa-list-alt" aria-hidden="true"></i></a>';

            return btn;
        }

        function details(id) {
            vm.showDetails = !vm.showDetails;
            vm.employees = [];

            UrgentIssueEmployee.getAllEmployeeByUrgentIssue({id: id}, function (result) {
                vm.employees = result;
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmEmailDeleteController',CrmEmailDeleteController);

    CrmEmailDeleteController.$inject = ['$uibModalInstance', 'entity', 'CrmEmail'];

    function CrmEmailDeleteController($uibModalInstance, entity, CrmEmail) {
        var vm = this;

        vm.crmEmail = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CrmEmail.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOutboundReferralStatusController', CrmOutboundReferralStatusController);

    CrmOutboundReferralStatusController.$inject = ['$scope', '$state', 'CrmOutboundReferralStatus',
        'CrmOutboundReferralStatusSearch', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', 'GUIUtils', '$filter', 'CoreService', '$compile'];

    function CrmOutboundReferralStatusController($scope, $state, CrmOutboundReferralStatus,
                                                 CrmOutboundReferralStatusSearch, $q, DTColumnBuilder,
                                                 DTOptionsBuilder, GUIUtils, $filter, CoreService, $compile) {
        var vm = this;

        vm.crmOutboundReferralStatuses = [];
        vm.dtInstance = {};

        vm.title = 'Crm Outbound Referral Status';
        vm.entityClassHumanized = 'Crm Outbound Referral Status';
        vm.entityStateName = 'crm-outbound-referral-status';

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery === '') {
                CrmOutboundReferralStatusSearch.query(CoreService.getFilterByCurrentCorporation(), function (result, headers) {
                    vm.crmOutboundReferralStatuses = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.crmOutboundReferralStatuses, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {

            return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ' +
                'ui-sref="' + $state.current.name + '.edit({id:' + data.id + '})">' +
                '<i class="fa fa-edit" aria-hidden="true"></i>Edit' +
                '</a>' +
                '</li>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link red" ' +
                'ui-sref="' + $state.current.name + '.delete({id:' + data.id + '})">' +
                '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabLogController', LabLogController);

    LabLogController.$inject = ['$scope', '$state', 'LabLog', 'CoreService', 'GenericEntityDatatableService',
        '$rootScope'];

    function LabLogController($scope, $state, LabLog, CoreService, GenericEntityDatatableService,
                              $rootScope) {
        var vm = this;

        var params = {
            corporationId: CoreService.getCorporation().id,
        }

        vm.descriptor = {
            title: 'Lab logs',
            entityClassHumanized: 'Lab logs',
            entityStateName: 'lab-log',
            service: LabLog,
            serviceMethod: 'query',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    title: 'Laboratory',
                    render: function (entity) {
                        return entity.laboratory.name;
                    }
                },
                {
                    title: 'Corporation',
                    name: 'corporation',
                    render: function (entity) {
                        return entity.corporation ? entity.corporation.name : ""
                    }
                },
                {
                    name: 'errorMessage',
                    title: 'Error Message'
                },
                {
                    name: 'createdDate',
                    title: 'Created Date',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
            ],
            filters: [

            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ]
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationHistoryAuditController', EvaluationHistoryAuditController);

    EvaluationHistoryAuditController.$inject = ['Evaluation', '$uibModalInstance', '$timeout', 'evaluation'];

    function EvaluationHistoryAuditController(Evaluation, $uibModalInstance, $timeout, evaluation) {
        var vm = this;

        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.evaluationsHistory = [];
        vm.evaluation = evaluation;

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;

        loadAll();

        function loadAll() {
            Evaluation.getHistory({
                id: vm.evaluation.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.evaluationsHistory = result;
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }
    }
})();

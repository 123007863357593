(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('prog-elig-employee', {
            parent: 'entity',
            url: '/prog-elig-employee',
            data: {
                authorities: [],
                pageTitle: 'ProgEligEmployees'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/prog-elig-employee/prog-elig-employees.html',
                    controller: 'ProgEligEmployeeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                    var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY];
                    return Auth.loadActionsAccess(actions).$promise;
                }]
            },
            onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY, access);
            }]
        })
        .state('prog-elig-employee.new', {
            parent: 'prog-elig-employee',
            url: '/new',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'CoreService', 'Auth', 'ACTIONS', 'access', function($stateParams, $state, $uibModal, CoreService, Auth, ACTIONS, access) {
                if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY, access)) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prog-elig-employee/prog-elig-employee-dialog.html',
                        controller: 'ProgEligEmployeeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        windowTopClass: 'custom-dialog-styles',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    startDate: null,
                                    endDate: null,
                                    employee: null,
                                    corporation: CoreService.getCorporation(),
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('prog-elig-employee', null, {reload: 'prog-elig-employee'});
                    }, function () {
                        $state.go('prog-elig-employee');
                    });
                }
            }]
        })
        .state('prog-elig-employee.edit', {
            parent: 'prog-elig-employee',
            url: '/{id}/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY, access)) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prog-elig-employee/prog-elig-employee-dialog.html',
                        controller: 'ProgEligEmployeeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        windowTopClass: 'custom-dialog-styles',
                        size: 'lg',
                        resolve: {
                            entity: ['ProgEligEmployee', function (ProgEligEmployee) {
                                return ProgEligEmployee.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('prog-elig-employee', null, {reload: 'prog-elig-employee'});
                    }, function () {
                        $state.go('^');
                    });
                }
            }]
        })
        .state('prog-elig-employee.delete', {
            parent: 'prog-elig-employee',
            url: '/{id}/delete',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_EMPLOYEE_PROGRAM_ELIGIBILITY, access)) {
                    $uibModal.open({
                        templateUrl: 'app/entities/prog-elig-employee/prog-elig-employee-delete-dialog.html',
                        controller: 'ProgEligEmployeeDeleteController',
                        controllerAs: 'vm',
                        windowTopClass: 'custom-dialog-styles',
                        size: 'md',
                        resolve: {
                            entity: ['ProgEligEmployee', function (ProgEligEmployee) {
                                return ProgEligEmployee.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('prog-elig-employee', null, {reload: 'prog-elig-employee'});
                    }, function () {
                        $state.go('^');
                    });
                }
            }]
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MessageHistoryDetailController', MessageHistoryDetailController);

    MessageHistoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$uibModalInstance',
        'MessageHistoryChart', 'DTOptionsBuilder', 'DTColumnBuilder', '$q',
        '$compile', 'GUIUtils', 'Campaign', 'toastr'];

    function MessageHistoryDetailController($scope, $rootScope, $stateParams, entity, $uibModalInstance,
                                            MessageHistoryChart, DTOptionsBuilder, DTColumnBuilder, $q,
                                            $compile, GUIUtils, Campaign, toastr) {
        var vm = this;

        vm.messageHistory = entity;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.dtInstance = {};

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.resendMessage = resendMessage;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            MessageHistoryChart.getAllByFilter(getFilter(),
                function (result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.messageHistoryCharts = result;
                    defer.resolve(vm.messageHistoryCharts);
                });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false).withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Message').notSortable().renderWith(function (data, type, full) {
                return data.message;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient').notSortable().renderWith(function (data, type, full) {
                return data.patientLastName + ', ' + data.patientFirstName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('To Phone Number').notSortable().renderWith(function (data, type, full) {
                return data.phoneNumber;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Sent').notSortable().renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(!data.twilioApiExceptionId);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {

            return '<div class="btn-group dd-buttons" uib-dropdown>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link blue" ' +
                'ng-click="vm.resendMessage(' + data.id + ')">' +
                '<i class="fa fa-send" aria-hidden="true"></i>Resend' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function getFilter() {
            var filter = {};

            filter.messageHistoryId = vm.messageHistory.id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter
        }

        function search() {
            vm.page = 1;

            transition();
        }

        function transition() {
            vm.dtInstance.reloadData();
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function resendMessage(id) {
            Campaign.resendMessage({id: id}).$promise.then(function (result) {
                transition();
                result.value ? toastr.success('Message have been sent successfully') : toastr.error('Message have not been sent');
            })
        }
    }
})();

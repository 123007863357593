(function (){
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxHistoryNewDialogController', VerifytxHistoryNewDialogController);

    VerifytxHistoryNewDialogController.$inject = ['$uibModalInstance', '$compile', '$q', '$scope', '$rootScope', 'Verifytx', 'verifytxVobId',
                                                'DTOptionsBuilder','DTColumnBuilder', 'GUIUtils'];

    function VerifytxHistoryNewDialogController($uibModalInstance, $compile, $q, $scope, $rootScope, Verifytx, verifytxVobId,
                                                 DTOptionsBuilder, DTColumnBuilder, GUIUtils) {
        var vm = this;

        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.dtInstance = {};

        vm.clear = clear;
        vm.transition = transition;
        vm.search = search;

        loadAll();

        function loadAll() {
            Verifytx.filter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.verifytxHistories = result;
            });
        }

        function clear() {
            $uibModalInstance.close();
        }

        function transition() {
            loadAll();
        }

        function search() {
            vm.page = 1;

            transition();
        }

        function getFilter() {
            var filter = {};

            filter.verifytxVobId = verifytxVobId;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('udt')
        .filter('ssnFormat', function() {
            //this 3 capture groups: the first has 3 digits, the second has 2 digits, the third has 4 digits.
            var ssnFormat = /^(\d{3})?(\d{2})(\d{4})$/;

            return function(ssn) {
                var parsed = ssnFormat.exec(ssn);

                return (!parsed) ? ssn : ((parsed[1]) ? parsed[1] + '-' : '') + parsed[2] + '-' + parsed[3];
           }
        });
})();

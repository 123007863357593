(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PendingByPatientController', PendingByPatientController);

    PendingByPatientController.$inject = ['$state', 'Evaluation', 'GUIUtils', '$q', 'Employee', 'CoreService',
        'TypeLevelCare', '$uibModal', '$rootScope', 'Chart', 'GenericEntityDatatableService'];

    function PendingByPatientController($state, Evaluation, GUIUtils, $q, Employee, CoreService,
                                        TypeLevelCare, $uibModal, $rootScope, Chart, GenericEntityDatatableService) {

        var vm = this;

        vm.credentials = CoreService.getCurrentEmployee().credentials;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
            requiredPatientSignature: true,
            existPatientSignature: false,
            empty: false,
            status: 'Pending'
        }

        vm.descriptor = {
            title: 'Unsigned Care and Delivery by Patients',
            entityClassHumanized: 'Unsigned Care and Delivery by Patients',
            entityStateName: 'evaluation',
            service: Evaluation,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[1, 'asc']]}
            ],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: null,
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + " " + data.lastName;
                    }
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    options: [{key: 'width', value: '130px'}],
                    render: function (data) {
                        return moment(data.dob).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: 'width', value: '130px'}],
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    name: 'name',
                    title: "Care and Delivery"
                },
                {
                    name: null,
                    title: "Status",
                    render: function (data) {
                        return '<span class="' + CoreService.getEvaluationStatusLabelClass(data.status) + '">' + data.status + '</span>';
                    }
                },
                {
                    title: 'Created Date',
                    options: [{key: 'width', value: '200px'}],
                    render: function (data) {
                        return data.createdDate ? moment(data.createdDate).format($rootScope.dateFormat) : "";
                    }
                },
                {
                    title: 'Created By',
                    options: [{key: 'width', value: '200px'}],
                    render: function (data) {
                        return data.createdByEmployee ? data.createdByEmployee.firstName + " " + data.createdByEmployee.lastName : '-';
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        $state.go('care-delivery', {id: data.id});
                    },
                })
            ],
            filters: [
                {
                    name: 'staffCredentials',
                    visible: false,
                    read: function (filter) {
                        if (vm.all || vm.others) {
                            return null;
                        }

                        if (vm.mine) {
                            return vm.credentials;
                        }
                    }
                },
                {
                    name: 'excludeStaffCredentials',
                    visible: false,
                    read: function (filter) {
                        if (vm.all || vm.mine) {
                            return null;
                        }

                        if (vm.others) {
                            return vm.credentials;
                        }
                    }
                },
                {
                    name: 'name',
                    type: 'string',
                    placeholder: 'Care & Delivery Name...',
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12',
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                },
                {
                    name: 'mrno',
                    type: 'string',
                    placeholder: 'Mr Number',
                },
                {
                    name: 'dob',
                    // title: 'Day of Birth',
                    placeholder: 'Day of Birth...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                },
                {
                    name: 'createdDate',
                    // title: 'Day of Birth',
                    placeholder: 'Created date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.createdDate ? moment(filter.createdDate).format("YYYY-MM-DD") : null;
                    },
                },
                {
                    name: 'typeLevelCare',
                    read: function (filter) {
                        return filter.typeLevelCare ? filter.typeLevelCare.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeLevelCare.findAllByFacility(
                            CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Level of Care ...',
                    type: 'select',
                },
                {
                    name: 'createdByEmployeeId',
                    read: function (filter) {
                        return filter.createdByEmployeeId ? filter.createdByEmployeeId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        var filter = {page: 0, size: 10, query: search, facilityId: CoreService.getCurrentFacility().id};

                        Employee.findAllByFilter(filter, function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    placeholder: 'Select Employee ...',
                    type: 'select',
                },
            ]
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('NotificationsController', NotificationsController);

    NotificationsController.$inject = ['$scope', '$state', 'Notifications', 'ParseLinks', 'AlertService', 'CoreService'];

    function NotificationsController ($scope, $state, Notifications, ParseLinks, AlertService, CoreService) {
        var vm = this;

        vm.title = 'Notificacions';

        vm.notifications = [];
        vm.notificationsCount = 0;
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.saveViewed = saveViewed;

        if (CoreService.getCurrentEmployee().id !== null) {
            loadAll();
        }

        //createNotification("YEAHHHH", "DIGIT", "HIGH");

        function createNotification(title, description, priorityLevel) {
            var notification = {};
            notification.title = title;
            notification.description = description;
            notification.priorityLevel = priorityLevel;
            notification.viewed = false;
            notification.reviewedDate = new Date();
            notification.employee = CoreService.getCurrentEmployee();
            notification.state = $state.current.name;
            notification.stateParams = $state.params;

            Notifications.save(notification, onSaveSuccessCreateNotification, onSaveErrorCreateNotification);
        }

        function onSaveSuccessCreateNotification (result) {
            $scope.$emit('bluebookApp:notificationUpdate', result);
            $state.go('chart', {'#': 'notification'}, {reload: true});
            vm.isSaving = false;
        }

        function onSaveErrorCreateNotification () {
            vm.isSaving = false;
        }

        function loadAll () {

            Notifications.findAll({
                employeeId: -1,
                page: vm.page,
                size: 15,
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.notifications.push(data[i]);
                }
                vm.notificationsCount = vm.notifications.length;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.notifications = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function clear () {
            vm.notifications = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function saveViewed (notification) {
            vm.isSaving = true;
            if ((notification.id !== null) && (notification.viewed == false)) {
                notification.viewed = true;
                Notifications.update(notification, onSaveSuccess, onSaveError);
                $state.reload();
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:notificationsUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.notifications = [];
            vm.links = {
                last: 0
            };
            vm.page = 0;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartNoteDialogController', ChartNoteDialogController);

    ChartNoteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ChartNote', 'Employee', 'Chart'];

    function ChartNoteDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ChartNote, Employee, Chart) {
        var vm = this;

        vm.chartNote = entity;
        vm.chart = entity.chart;
        vm.showDelete = false;

        vm.clear = clear;
        vm.save = save;
        vm.setValidationClass = setValidationClass;
        vm.confirmDelete = confirmDelete;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.chartNote.id !== null) {
                ChartNote.update(vm.chartNote, onSaveSuccess, onSaveError);
            } else {
                ChartNote.save(vm.chartNote, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:chartNoteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function confirmDelete () {
            ChartNote.delete({id: vm.chartNote.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

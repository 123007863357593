(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionFormTplEditController', GroupSessionFormTplEditController);

    GroupSessionFormTplEditController.$inject = ['entity', '$rootScope', 'GroupSessionFormTpl', 'OdsFormService', '$state'];

    function GroupSessionFormTplEditController ( entity, $rootScope, GroupSessionFormTpl, OdsFormService, $state) {
        var vm = this;

        vm.title = 'Group Session Form Tpls';

        vm.groupSessionFormTpl = entity;
        vm.save = save;
        vm.updateTitle = updateTitle;

        vm.groupSessionFormTpl.jsonTemplate = !vm.groupSessionFormTpl.jsonTemplate ?
            OdsFormService.newSchema() : vm.groupSessionFormTpl.jsonTemplate;

        vm.debugMode = !$rootScope.inProduction ? true : false;

        function save () {
            vm.isSaving = true;
            if (vm.groupSessionFormTpl.id !== null) {
                GroupSessionFormTpl.update(vm.groupSessionFormTpl, onSaveSuccess, onSaveError);
            } else {
                GroupSessionFormTpl.save(vm.groupSessionFormTpl, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go('group-session-form-tpl', {}, {reload: true});
        }

        function onSaveError(error) {
            console.log('Problem saving this element' + error);
        }
        function updateTitle() {
            vm.groupSessionFormTpl.jsonTemplate.label = angular.copy(vm.groupSessionFormTpl.name);
        }
    }
})();

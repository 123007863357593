(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ProgEligCorp', ProgEligCorp);

    ProgEligCorp.$inject = ['$resource', 'DateUtils'];

    function ProgEligCorp ($resource, DateUtils) {
        var resourceUrl =  'api/prog-elig-corps/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
           'findAllByFilter': {
                url: 'api/prog-elig-corps/filter',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                },
                isArray: true
            }

        });
    }
})();

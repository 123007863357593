(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportNoteDialogController', IncidentReportNoteDialogController);

    IncidentReportNoteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'IncidentReportNote', 'toastr', 'jsonTemplate'];

    function IncidentReportNoteDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                                IncidentReportNote, toastr, jsonTemplate) {
        var vm = this;

        vm.incidentReportNote = entity;
        // vm.originalTemplate = angular.copy(vm.incidentReportNote.jsonTemplate);
        vm.jsonTemplate = jsonTemplate;
        vm.intervalId = null;

        vm.clear = clear;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        init();

        function init() {
            $timeout(function () {
                vm.watcher = $scope.$watch('vm.jsonTemplate', function (newValue, oldValue, scope) {
                    if (oldValue !== newValue) {
                        if (vm.lastUpdate) {
                            clearInterval(vm.intervalId);
                            vm.intervalId = setInterval(autoSave, 1500);
                        } else {
                            vm.lastUpdate = new Date();
                            vm.intervalId = setInterval(autoSave, 1500);
                        }
                    }
                }, true);
            }, 1000);
        }

        function clear() {
            $uibModalInstance.dismiss('dismiss');
        }

        function autoSave() {
            var intervalId = angular.copy(vm.intervalId);

            vm.lastUpdate = null;
            vm.intervalId = null;

            vm.isSaving = true;

            IncidentReportNote.autoSave({incidentReportNote: vm.incidentReportNote, jsonTemplate: vm.jsonTemplate}, function (data) {
                toastr.success("Auto save data");

                vm.isSaving = false;
            }, function () {
                toastr.error("Failure auto save data");

                vm.isSaving = false;
            });

            clearInterval(intervalId);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('CrmReferralSourceSearch', CrmReferralSourceSearch);

    CrmReferralSourceSearch.$inject = ['$resource'];

    function CrmReferralSourceSearch($resource) {
        var resourceUrl =  'api/_search/crm-referral-sources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

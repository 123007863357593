(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TelehealthMeetingInvitationController', TelehealthMeetingInvitationController);

    TelehealthMeetingInvitationController.$inject = ['$uibModalInstance', 'entity', '$rootScope', '$window'];

    function TelehealthMeetingInvitationController($uibModalInstance, entity, $rootScope, $window) {
        var vm = this;

        vm.telehealthMeeting = entity;
        var endpoint = ($window.origin).includes('localhost') ? 'http://localhost:3000/meeting/' : 'https://twvideo.bluebookehr.com/meeting/';
        var origin = ($window.origin).includes('localhost') ? 'localhost' : ($window.origin).substr(8);

        vm.invitation = vm.telehealthMeeting.status === 'SCHEDULED' ? ('\n' +
            vm.telehealthMeeting.host.firstName + ' ' + vm.telehealthMeeting.host.lastName + ' is inviting you to a scheduled meeting.\n' +
            '\n' +
            'Topic: ' + vm.telehealthMeeting.topic + '\n' +
            'Time: ' + moment(vm.telehealthMeeting.startDate).format($rootScope.amDateFormat) + ' ' + vm.telehealthMeeting.timeZone + '\n' +
            '\n' +
            'Join Meeting\n' +
            endpoint + vm.telehealthMeeting.twilioRoomName + '/' + origin + '\n' +
            '\n' +
            (vm.telehealthMeeting.password ? 'Password: ' + vm.telehealthMeeting.password : '')) :
            ('\n' +
                vm.telehealthMeeting.host.firstName + ' ' + vm.telehealthMeeting.host.lastName + ' is inviting you to a live meeting.\n' +
                '\n' +
                'Topic: ' + vm.telehealthMeeting.topic + '\n' +
                '\n' +
                'Join Meeting\n' +
                endpoint + vm.telehealthMeeting.twilioRoomName+ '/' + origin + '\n' +
                '\n' +
                (vm.telehealthMeeting.password ? 'Password: ' + vm.telehealthMeeting.password : ''));

        vm.clear = clear;
        vm.copy = copy;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function copy() {
            document.querySelector("#textarea_field").select();
            document.execCommand('copy');
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationTemplate', EvaluationTemplate);

    EvaluationTemplate.$inject = ['$resource'];

    function EvaluationTemplate($resource) {
        var resourceUrl = 'api/evaluation-templates/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].jsonTemplate = angular.fromJson(data[i].jsonTemplate);
                        }
                    }

                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.jsonTemplate = angular.fromJson(data.jsonTemplate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);
                    return angular.toJson(copy);
                }
            },
            'findAllByFilter': {url: 'api/evaluation-templates/filter', method: 'GET', isArray: true},
            'findAllByFacility': {url: 'api/evaluation-templates/by-facility/:id', method: 'GET', isArray: true},
            'levelCare': {url: 'api/evaluation-templates/level-care/:ppId/:facId/:lcId', method: 'GET', isArray: true},
            'readmissionBasedOn': {url: 'api/evaluation-templates/readmission-based-on', method: 'GET', isArray: true},
            'findAllByPatientProcess': {url: 'api/evaluation-templates/by-chart/:id/patient-process/:process', method: 'GET', isArray: true},
            'findAllByRelease': {url: 'api/evaluation-templates/by-chart/:id', method: 'GET', isArray: true},
            'findAll': {url: 'api/evaluation-templates/', method: 'GET', isArray: true },
            'ckeditorSuggestions': {url: 'api/evaluation-templates/ckeditor-suggestions', method: 'GET', isArray: true},
            'clone': {url: 'api/evaluation-templates/clone/:id', method: 'GET'},
            'cloneToFacility': {url: 'api/evaluation-templates/clone/:id/facility/:facilityId', method: 'GET'},
            'findAllByMars': {url: 'api/evaluation-templates/by-chart/mars/:id', method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AbnormalResultsTopBoxController', AbnormalResultsTopBoxController);

    AbnormalResultsTopBoxController.$inject = ['facility', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult'];

    function AbnormalResultsTopBoxController(facility, $scope, $state, $q, DTColumnBuilder, PatientResult) {
        var vm = this;
        vm.abnormal = 0;

        vm.abnormal = PatientResult.countAbnormal({id: facility.id});
    }
})();

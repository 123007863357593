(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabCompendiumController', LabCompendiumController);

    LabCompendiumController.$inject = ['LabCompendium', '$state', 'DTOptionsBuilder', 'DTColumnBuilder', '$q',
        'GUIUtils', '$filter', '$compile', '$scope', 'Laboratory', 'ConfirmationService',
        '$uibModal', 'CoreService', 'GenericEntityDatatableService', 'PopupService'];

    function LabCompendiumController(LabCompendium, $state, DTOptionsBuilder, DTColumnBuilder, $q,
                                     GUIUtils, $filter, $compile, $scope, Laboratory, ConfirmationService,
                                     $uibModal, CoreService, GenericEntityDatatableService, PopupService) {
        var vm = this;

        vm.laboratory = null;

        vm.descriptor = {
            title: 'Lab Compendiums',
            newAction: false,
            entityClassHumanized: 'Lab Compendiums',
            entityStateName: 'lab-compendium',
            service: LabCompendium,
            serviceMethod: 'filter',
            pagination: 'remote',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: null,
                    title: 'Code',
                    render: function (data) {
                        return data.code ? data.code : "";
                    }
                },
                {
                    title: 'Internal Code',
                    render: function (data) {
                        return data.intCode ? data.intCode : "";
                    }
                },
                {
                    title: 'Description',
                    render: function (data) {
                        return data.description ? data.description : "";
                    }
                },
                {
                    title: 'Abbr',
                    render: function (data) {
                        return data.abbr ? data.abbr : "";
                    }
                },
                {
                    title: 'Units',
                    render: function (data) {
                        return data.units ? data.units : "";
                    }
                },
                {
                    title: 'Cut Off',
                    render: function (data) {
                        return data.cutOff ? data.cutOff : "";
                    }
                },
                {
                    title: 'W Group',
                    render: function (data) {
                        return data.wGroup ? data.wGroup : "";
                    }
                },
                {
                    title: 'Rep Group',
                    render: function (data) {
                        return data.compendiumReportGroup ? data.compendiumReportGroup.name : "";
                    }
                },
                {
                    title: 'Laboratory',
                    render: function () {
                        return vm.laboratory.name;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction('lab-compendium'), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('lab-compendium'), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getCommentAction(), {
                    action: function (data) {
                        return comment(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getAddCommentAction(), {
                    name: 'Add Comment',
                    action: function (data) {
                        return addComment(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Lab Compendium',
                            action: function () {
                                return add();
                            },
                            disabled: function () {
                                return vm.laboratory === null;
                            }
                        })
                    ]
                }
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Laboratory',
                            action: function (filter) {
                                return $q(function (resolve) {
                                    PopupService.showSelectLaboratoryPopup(filter).result.then(function (result) {
                                        filter.laboratoryId = result;

                                        vm.laboratory = result;
                                        vm.descriptor.title = 'Lab Compendiums - ' + vm.laboratory.name

                                        resolve();
                                    });
                                })
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'laboratoryId',
                    visible: false,
                    read: function (filter) {
                        return filter.laboratoryId ? filter.laboratoryId.id : null;
                    }
                }
            ],
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-compendium/update/lab-compendium-dialog.html',
                    controller: 'LabCompendiumDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null,
                                laboratory: vm.laboratory
                            };
                        },
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-compendium/update/lab-compendium-dialog.html',
                    controller: 'LabCompendiumDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['LabCompendium', function (LabCompendium) {
                            return LabCompendium.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Lab Compendium').result.then(function () {
                    LabCompendium.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function addComment(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-compendium-comment/update/lab-compendium-comment-dialog.html',
                    controller: 'LabCompendiumCommentController',
                    controllerAs: 'vm',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: {
                            id: null,
                            labCompendium: {id: id},
                            comment: null,
                            facility: null
                        },
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function comment(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-compendium-comment/list/lab-compendium-comments.html',
                    controller: 'LabCompendiumCommentsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        labCompendiumId: id
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }
    }
})();

/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CriticalResultsTopBoxController', CriticalResultsTopBoxController);

    CriticalResultsTopBoxController.$inject = ['facility', 'PatientResult', '$uibModal','openModalCritical','CoreService'];

    function CriticalResultsTopBoxController(facility, PatientResult, $uibModal, openModalCritical, CoreService) {
        var vm = this;
        vm.critical = 0;
        vm.openModalCritical = openModalCritical;
        vm.modalCriticalResults = modalCriticalResults;

        PatientResult.countCritical({id: facility.id}, function (result) {
            vm.critical = result;

            if (result.value > 0 && vm.openModalCritical == undefined ) {
                modalCriticalResults(vm.critical);
                CoreService.setStorage('openModalCritical', true);
            }
        });

        function modalCriticalResults(critical) {
            $uibModal.open({
                templateUrl: 'app/dashboard2/layout/critical-results/alert/patient-result-critical-alert-dialog.html',
                controller: 'PatientResultCriticalAlertDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    critical: critical
                }
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidFacilityDialogController', CovidFacilityDialogController);

    CovidFacilityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'CovidFacility', 'Facility', 'DataUtils', 'CoreService', 'LabCompendium', 'CovidFacilityLab'];

    function CovidFacilityDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                           CovidFacility, Facility, DataUtils, CoreService, LabCompendium,
                                           CovidFacilityLab) {
        var vm = this;

        vm.covidFacility = entity;
        vm.facilityLogo = null;
        vm.pharmacyLogo = null;
        vm.facility = CoreService.getCurrentFacility();
        vm.labCompendiums = [];
        vm.covidFacilityLab = null;

        vm.clear = clear;
        vm.save = save;
        vm.handleFileSelect = handleFileSelect;
        vm.getLabCompendium = getLabCompendium;
        vm.handleFileSelectPharmacy = handleFileSelectPharmacy;

        init();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function init() {
            CovidFacilityLab.findAllByFacility({id: vm.facility.id}, function (result) {
                vm.flag = result.length > 0;

                vm.covidFacilityLab = result.length > 0 ? result[0] : null;
            });
        }

        function save() {
            vm.isSaving = true;

            var model = {
                covidFacility: vm.covidFacility,
                facilityLogo: vm.facilityLogo,
                pharmacyLogo: vm.pharmacyLogo
            };

            if (vm.covidFacility.id !== null) {
                CovidFacility.update(model, onSaveSuccess, onSaveError);
            } else {
                CovidFacility.save(model, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:covidFacilityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function handleFileSelect(evt, file) {
            if (file) {
                DataUtils.toBase64(file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.facilityLogo = base64Data;
                    });
                });
            }
        }

        function handleFileSelectPharmacy(evt, file) {
            if (file) {
                DataUtils.toBase64(file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.pharmacyLogo = base64Data;
                    });
                });
            }
        }

        function getLabCompendium(query) {
            if (vm.covidFacilityLab && query && query.length > 1) {
                LabCompendium.filter({
                    laboratoryId: vm.covidFacilityLab.facilityLab.laboratoryId,
                    searchQuery: query,
                    page: vm.page - 1,
                    size: 40,
                    sort: 'code,asc',
                    facilityId: vm.facility.id
                }, function (result) {
                    vm.labCompendiums = result;
                });
            }
        }
    }
})();

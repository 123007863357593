(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxCredentialController', VerifytxCredentialController);

    VerifytxCredentialController.$inject = ['GenericEntityDatatableService', 'CoreService', 'VerifytxCredential', '$q',
        '$uibModal', 'toastr', 'ConfirmationService'];

    function VerifytxCredentialController (GenericEntityDatatableService, CoreService, VerifytxCredential, $q,
                                           $uibModal, toastr, ConfirmationService) {
        var vm = this;

        vm.totalItems = 0;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'VerifyTX Credential',
            newAction: false,
            entityClassHumanized: 'VerifyTX Credential',
            entityStateName: 'verifytx-credential',
            service: VerifytxCredential,
            serviceMethod: 'getAllByCorporation',
            params: params,
            columns: [
                {
                    name: 'clientId',
                    title: 'Client Id',
                },
                {
                    name: 'clientSecret',
                    title: 'Client Secret',
                },
                {
                    name: 'username',
                    title: 'Username',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New VerifyTX Credential',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0;
                            }
                        })
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/verifytx-credential/update/verifytx-credential-dialog.html',
                    controller: 'VerifytxCredentialDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                id: null,
                                clientId: null,
                                clientSecret: null,
                                username: null,
                                password: null,
                                corporation: CoreService.getCorporation()
                            };
                        }]
                    }
                }).result.then(function () {
                    toastr.success('VerifyTX Credential was saved', 'Success');

                    resolve();
                });
            })
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/verifytx-credential/update/verifytx-credential-dialog.html',
                    controller: 'VerifytxCredentialDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['VerifytxCredential', function (VerifytxCredential) {
                            return VerifytxCredential.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    toastr.success('VerifyTX Credential was saved', 'Success');

                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('VerifytxCredential').result.then(function () {
                    VerifytxCredential.delete({id: id}, function () {
                        toastr.success('VerifyTX Credential was deleted', 'Success');

                        resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxNewDialogController', VerifytxNewDialogController);

    VerifytxNewDialogController.$inject = ['$uibModalInstance', 'Verifytx', 'CoreService', 'chartId', 'toastr'];

    function VerifytxNewDialogController($uibModalInstance, Verifytx, CoreService, chartId, toastr) {
        var vm = this;

        vm.clear = clear;
        vm.getPayers = getPayers;
        vm.newVerifytx = newVerifytx;

        Verifytx.getFacilities({corporationId: CoreService.getCorporation().id}, function (result) {
            vm.facilities = result.verifytxDTOs;

            if (result.fails && result.fails.length > 0) {
                toastr.error(_.join(result.fails, ","));
            }
        });

        function getPayers(payer) {
            if (payer && payer.length > 2) {
                Verifytx.getPayers({corporationId: CoreService.getCorporation().id, payer: payer}, function (result) {
                    vm.payers = result.verifytxDTOs;

                    if (result.fails && result.fails.length > 0) {
                        toastr.error(_.join(result.fails, ","));
                    }
                })
            }
        }

        function clear() {
            $uibModalInstance.dismiss(null);
        }

        function newVerifytx() {
            vm.isSaving = true;
            Verifytx.new({
                chartId: chartId,
                facilityId: vm.facility.id,
                payerId: vm.payer.id
            }, function (result) {
                vm.isSaving = false;

                if (result.fails && result.fails.length > 0) {
                    toastr.error(_.join(result.fails, ","));
                } else {
                    toastr.success('Verification of Benefits has been done');
                    $uibModalInstance.close(result);
                }

            }, function (error) {
                vm.isSaving = false;
                $uibModalInstance.dismiss(error);
            })
        }
    }
})();

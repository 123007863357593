(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReactionController', ReactionController);

    ReactionController.$inject = ['GenericEntityDatatableService', 'Reaction', 'CoreService', '$q', '$uibModal',
        'ConfirmationService'];

    function ReactionController(GenericEntityDatatableService, Reaction, CoreService, $q, $uibModal,
                                ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Reaction',
            newButtonTitle: 'New Reaction',
            entityClassHumanized: 'Reaction',
            entityStateName: 'reaction',
            service: Reaction,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Reaction',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/reaction/update/reaction-dialog.html',
                controller: 'ReactionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/reaction/update/reaction-dialog.html',
                controller: 'ReactionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Reaction', function (Reaction) {
                        return Reaction.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Reaction').result.then(function () {
                    Reaction.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();


(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ContactsController', ContactsController);

    ContactsController.$inject = ['chart', 'Contacts', 'CoreService', 'ParseLinks', 'AlertService', '$uibModal',
        'Evaluation', 'PopupService', '$rootScope', '$scope', 'access', 'ACTIONS', 'Auth',
        'ConfirmationService'];

    function ContactsController(chart, Contacts, CoreService, ParseLinks, AlertService, $uibModal,
                                Evaluation, PopupService, $rootScope, $scope, access, ACTIONS, Auth,
                                ConfirmationService) {
        var vm = this;
        vm.chart = chart;

        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.evaluationPage = 1;
        vm.evaluationPerPage = 10;
        vm.released = null;
        vm.contactSelected = null;

        vm.toggleItem = toggleItem;
        vm.transition = transition;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.assign = assign;
        vm.closeEvaluationPanel = closeEvaluationPanel;
        vm.loadAllByContact = loadAllByContact;
        vm.evaluationDel = evaluationDel;
        vm.evaluationAmended = evaluationAmended;
        vm.evaluationTransition = evaluationTransition;
        vm.openEvaluationPanel = openEvaluationPanel;

        function toggleItem() {
            CoreService.togglePanel('contacts');
        }

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CONTACTS_VIEW, access)) {

                return;
            }

            Contacts.filter({
                chartId: chart.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.contacts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/contacts/update/contacts-dialog.html',
                controller: 'ContactsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            phone: null,
                            email: null,
                            address: null,
                            notes: null,
                            city: null,
                            state: null,
                            zip: null,
                            supportSystem: null,
                            fullName: null,
                            altPhone: null,
                            addressTwo: null,
                            id: null,
                            guarantor: false,
                            chart: chart
                        };
                    },
                    guarantor: ['Contacts', function (Contacts) {
                        return Contacts.guarantor({id: chart.id}).$promise;
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll()
            }, function () {
            });
        }

        function edit(id) {
            PopupService.showContactPopup(id).result.then(function (result) {
                vm.page = 1;
                loadAll()
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Contacts').result.then(function () {
                Contacts.delete({id: id}, function () {
                    vm.page = 1;
                    loadAll()
                });
            })
        }

        function assign(contactId) {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-template/contact-assign/evaluation-template-contact-assign-dialog.html',
                controller: 'EvaluationTemplateContactAssignDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    chart: chart,
                    contact: ['Contacts', function (Contacts) {
                        return Contacts.get({id: contactId}).$promise;
                    }]
                }
            }).result.then(function () {
                if (vm.contactSelected) {
                    loadAllByContact(contactId);
                }
            });
        }

        function openEvaluationPanel(contactId) {
            vm.evaluationPage = 1;
            vm.contactSelected = contactId;

            loadAllByContact();
        }

        function closeEvaluationPanel() {
            vm.contactSelected = null;
            vm.evaluationPage = 1;
            vm.evaluationTotalItems = 0;
            vm.evaluations = [];
        }

        function evaluationTransition() {
            loadAllByContact();
        }

        function loadAllByContact() {
            Evaluation.findAllByChartAndContact({
                id: chart.id,
                contact: vm.contactSelected,
                page: vm.evaluationPage - 1,
                size: vm.evaluationPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.evaluations = data;
                vm.evaluationTotalItems = headers('X-Total-Count');
            }

            function onError(error) {
                vm.evaluationTotalItems = 0;
                AlertService.error(error.data.message);
            }
        }

        function evaluationDel(evaluation) {
            ConfirmationService.showDelete('Evaluation').result.then(function () {
                Evaluation.delete({id: evaluation.id}, function () {
                    evaluationTransition();
                });
            })
        }

        function evaluationAmended(id) {
            Evaluation.amended({id: id}, function (evaluation) {
                vm.evaluationTransition();
            })
        }

        var unsubscribe = $rootScope.$on('bluebookApp:loadContacts', function (event, result) {
            loadAll();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AutoFaxController', AutoFaxController);

    AutoFaxController.$inject = ['GenericEntityDatatableService', 'AutoFax', 'CoreService', '$q', '$uibModal'];

    function AutoFaxController(GenericEntityDatatableService, AutoFax, CoreService, $q, $uibModal) {
        var vm = this;

        vm.totalItems = 0;

        var params = {
            id: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'AutoFax',
            entityClassHumanized: 'AutoFax',
            entityStateName: 'auto-fax',
            service: AutoFax,
            serviceMethod: 'getByFacility',
            newAction: false,
            params: params,
            columns: [
                {
                    name: null,
                    title: 'Contact Address',
                    render: function (data) {
                        return data ? data.contactAddress.name : '';
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return editAutoFaxes(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return deleteAutoFaxes(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Auto Fax',
                            ngIf: function (filter) {
                                return vm.totalItems === 0
                            },
                            action: function () {
                                return newAutoFaxes();
                            }
                        }),
                    ],
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function newAutoFaxes() {
            return $uibModal.open({
                templateUrl: 'app/entities/auto-fax/update/auto-fax-dialog.html',
                controller: 'AutoFaxDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function editAutoFaxes(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/auto-fax/update/auto-fax-dialog.html',
                controller: 'AutoFaxDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['AutoFax', function (AutoFax) {
                        return AutoFax.get({id: id}).$promise;
                    }]
                }
            }).result;
        }


        function deleteAutoFaxes(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('AutoFax').result.then(function () {
                    AutoFax.delete({id: id}, function () {
                        resolve();
                    });
                })
            })
        }
    }
})();

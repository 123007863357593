(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('SuperBill', SuperBill);

    SuperBill.$inject = ['$resource', 'DateUtils'];

    function SuperBill ($resource, DateUtils) {
        var resourceUrl =  'api/super-bills/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateOfService = DateUtils.convertLocalDateFromServer(data.dateOfService);
                        data.billDate = DateUtils.convertLocalDateFromServer(data.billDate);
                        data.insuranceDob = DateUtils.convertLocalDateFromServer(data.insuranceDob);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateOfService = DateUtils.convertLocalDateToServer(copy.dateOfService);
                    copy.billDate = DateUtils.convertLocalDateToServer(copy.billDate);
                    copy.insuranceDob = DateUtils.convertLocalDateToServer(copy.insuranceDob);
                    return angular.toJson(copy);
                }
            },
            'byChart': {
                url: 'api/super-bills/by-chart/:id',
                method: 'GET',
                isArray: true
            },
            'update': {method: 'POST', url: 'api/super-bills/update'},
            'createInstitutional': {url: 'api/super-bills/create-institutional', method: 'POST'}
        });
    }
})();

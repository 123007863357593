(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProcessedPatientResultController', ProcessedPatientResultController);

    ProcessedPatientResultController.$inject = ['GenericEntityDatatableService', 'CoreService', '$rootScope',
        'ProcessedPatientResult', 'GUIUtils', 'Corporation', 'PatientResult',
        'Laboratory'];

    function ProcessedPatientResultController(GenericEntityDatatableService, CoreService, $rootScope,
                                              ProcessedPatientResult, GUIUtils, Corporation, PatientResult,
                                              Laboratory) {
        var vm = this;

        var params = {
            corporationId: CoreService.getCorporation().id,
        }

        vm.descriptor = {
            title: 'Processed Patient Results',
            newButtonTitle: 'New Processed Patient Result',
            entityClassHumanized: 'Processed Patient Results',
            entityStateName: 'processed-patient-result',
            service: ProcessedPatientResult,
            serviceMethod: 'query',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'barcode',
                    title: 'Barcode'
                },
                {
                    name: 'chartId',
                    title: 'Chart',
                    render: function (entity) {
                        return entity.chart ?
                            entity.chart.firstName + " " +
                            (entity.chart.middleName ? entity.chart.middleName + ' ' : '') +
                            entity.chart.lastName : ""
                    }
                },
                {
                    name: 'mrNo',
                    title: 'MrNo',
                    render: function (entity) {
                        return entity.chart ? entity.chart.mrNo : ''
                    }
                },
                {
                    title: 'Corporation',
                    name: 'corporation',
                    render: function (entity) {
                        return entity.corporation ? entity.corporation.name : ""
                    }
                },
                {
                    name: 'patientResultId',
                    title: 'Patient Result',
                },
                {
                    name: 'patientOrderId',
                    title: 'Patient Order',
                },
                {
                    name: 'logStatus',
                    title: 'Log Status',
                    render: function (data) {
                        if (data.logStatus === 'SUCCESS') {
                            return data.logStatus;
                        } else {
                            return "<small class='label label-danger'>" + data.logStatus + "</small>";
                        }
                    }
                },
                {
                    name: 'originalFileName',
                    title: 'File',
                },
                {
                    name: 'createdDate',
                    title: 'Created Date',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getCloneAction(), {
                    name: 'Download HL7 file',
                    action: function (data) {
                        download(data.id);
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getViewPdfAction(), {
                    name: 'Download Embedded PDF',
                    action: function (data) {
                        downloadEmbedded(data);
                    },
                })
            ],
            filters: [
                {
                    name: 'barcode',
                    placeholder: 'Barcode...',
                    type: 'string'
                },
                {
                    name: 'corporationId',
                    read: function (filter) {
                        return filter.corporationId ? filter.corporationId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        Corporation.query(function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    placeholder: 'Corporation ...',
                    type: 'select',
                },
                {
                    name: 'logStatus',
                    placeholder: 'Log Status...',
                    type: 'select',
                    values: [
                        'SUCCESS', 'FAIL', 'FILE_ERROR'
                    ],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ]
        }

        function download(id) {
            ProcessedPatientResult.download({id: id});
        }

        function downloadEmbedded(data) {
            PatientResult.getAllInOneRangePdf({ids: [data.patientResultId]});
        }
    }
})();

/**
 * Created by jesusrodriguez on 2/24/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PrintRequisitionBoxController', PrintRequisitionBoxController);

    PrintRequisitionBoxController.$inject = ['facility', 'PatientOrder', 'filter', 'DateUtils', 'CoreService',
        '$uibModal', 'AlertService', 'DYMO', 'DataTablesService', 'DTOptionsBuilder',
        'DTColumnBuilder', '$compile', '$q', 'GUIUtils', '$scope', 'PopupService', 'Chart',
        'LabRequisitionSignature', 'toastr'];

    function PrintRequisitionBoxController(facility, PatientOrder, filter, DateUtils, CoreService,
                                           $uibModal, AlertService, DYMO, DataTablesService, DTOptionsBuilder,
                                           DTColumnBuilder, $compile, $q, GUIUtils, $scope, PopupService, Chart,
                                           LabRequisitionSignature, toastr) {
        var vm = this;

        vm.facility = facility;
        vm.page = 1;
        vm.itemsPerPage = 2;
        vm.queryCount = 0;
        vm.orders = [];
        vm.barcodes = [];
        vm.filter = filter;
        vm.dtInstance = {};
        vm.date = new Date();
        vm.labRequisitions = [];
        vm.selected = {};
        vm.selectAll = false;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.selectedDate = false;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.barcode = null;

        vm.create = create;
        vm.manifest = manifest;
        vm.printLabels = printLabels;
        vm.searchByQuery = searchByQuery;
        vm.labRequisitionPDF = labRequisitionPDF;
        vm.getSelectedBarcodes = getSelectedBarcodes;
        vm.openCalendar = openCalendar;
        vm.printDYMOLabels = printDYMOLabels;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.detail = detail;
        vm.addSignature = addSignature;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            var newFilter = angular.copy(vm.filter);
            newFilter.date = moment(vm.date).format('YYYY-MM-DD');
            PatientOrder.filter(newFilter, onSuccess, onError);

            function onSuccess(data) {
                vm.labRequisitions = data;
                defer.resolve(data);
                vm.orders = setUniqueIds(data);
                vm.selected = {};
                vm.selectAll = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            return defer.promise;
        }).withBootstrap().withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            }).withPaginationType('full_numbers').withBootstrap().withDOM('ltp').withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            }).withDisplayLength(15).withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]]);

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;
                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';

            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').renderWith(function (data, type, full) {
                return data.firstName + (data.middleName == null ? " " : " " + data.middleName + " ") + data.lastName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Order').renderWith(function (data, type, full) {
                return data.patientOrderId;
            }),
            DTColumnBuilder.newColumn(null).withTitle('D.O.B').renderWith(function (data, type, full) {
                return moment(data.dateBirth).format('MM/DD/YYYY');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode ? GUIUtils.getStatusTemplate(data.barcode.toString(), 'primary') : null;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Laboratory').renderWith(function (data, type, full) {
                return getLaboratoryName(data.laboratoryName);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(function (data, type, full) {
                return data.itemStatus ? GUIUtils.colorHtmlStatusRequisition(data.itemStatus) : null;
            }),
            DTColumnBuilder.newColumn('signed').withTitle('Signed').renderWith(function (data) {
                return GUIUtils.colorHtmlYesNo(data);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';
            if (!data.signatureId && data.barcode) {
                stButtons += '<a class="dt-btn danger" ng-click="vm.addSignature(' + data.chartId + ", " + "'" + data.barcode + "'" + ')">' + ' ' +
                    '  <i class="fa fa-edit"></i></a>&nbsp;';
            }
            if (data.barcode) {
                stButtons += '<a class="dt-btn danger" ng-click="vm.labRequisitionPDF(' + "'" + data.barcode + "'" + ')">' + ' ' +
                    '  <i class="fa fa-file-pdf-o"></i></a>&nbsp;';
            }
            stButtons += '<a class="info-btn" ng-click="vm.detail(' + data.patientOrderId + "," +
                (data.barcode ? "'" + data.barcode + "'," : "null,") +
                "'" + data.scheduleDate + "'" + ')">' +
                '  <i class="fa fa-eye"></i></a>&nbsp;';

            return stButtons;
        }

        function setUniqueIds(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].id = data[i].patientOrderId;
            }

            return data;
        }

        function labRequisitionPDF(barcode) {
            PatientOrder.getPDFLabRequisition({barcode: barcode});
        }

        function printLabels() {
            var values = getSelectedBarcodes();

            if (values.length > 0) {
                PatientOrder.pdfBarcodeLabels({facilityId: vm.facility.id, printBarcode: values}, function (result) {
                    vm.dtInstance.reloadData();
                });
            }
        }

        function printDYMOLabels() {
            var values = getSelectedBarcodes();

            if (values.length > 0) {
                PatientOrder.getBarcodeLabels({facilityId: vm.facility.id, printBarcode: values}, function (result) {
                    if (DYMO.loadPrinters()) {
                        _.forEach(result, function (label) {
                            DYMO.printPatientOrderLabel({
                                barcode: label.barcode,
                                name: label.firstName + (label.middleName ? label.middleName + " " : " ") + label.lastName,
                                dob: label.dateBirth,
                                collectedDate: label.scheduleDate,
                                tube: label.tube,
                                account: label.accountNo
                            })
                        });

                        vm.dtInstance.reloadData();
                    } else {
                        toastr.error('No DYMO printers are installed. Install DYMO printers.');
                    }
                });
            }
        }

        function getSelectedBarcodes() {
            var values = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var index = _.findIndex(vm.orders, function (order) {
                        return order.patientOrderId == parseInt(id);
                    });
                    values.push({
                        chartId: vm.orders[index].chartId,
                        date: DateUtils.convertLocalDateToServer(vm.orders[index].scheduleDate),
                        patientOrderId: vm.orders[index].patientOrderId,
                        barcode: vm.orders[index].barcode

                    });
                }
            }

            return values;
        }

        function manifest() {
            var values = _.map(getSelectedBarcodes(), function (value) {
                return value.barcode;
            });

            PatientOrder.getPDFManifest({
                facilityId: vm.facility.id,
                barcodes: values
            }, function (result) {
                vm.dtInstance.reloadData();
            }, function (result) {
                vm.dtInstance.reloadData();
            });

            vm.selected = {};
        }

        function create() {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order/lab-requisition/create/lab-requisition-dialog.html',
                controller: 'LabRequisitionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    date: [function () {
                        return vm.date;
                    }]
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            }, function () {
                vm.dtInstance.reloadData();
            });
        }

        function searchByQuery() {
            vm.dtInstance.reloadData();
        }

        function getLaboratoryName(data) {
            if (data && data.length > 0) {
                return data && data.length > 20 ? data.substr(0, 20) + "..." : data;
            } else {
                return "-";
            }
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function detail(patientOrderId, barcode, date) {
            PopupService.showLabRequisitionDetailPopup(patientOrderId, barcode, date);
        }

        function addSignature(chartId, barcode) {
            Chart.get({id: chartId}, function (chart) {
                if (PopupService.checkSigPlusExtLiteExtension()) {
                    startSign(barcode);
                } else {
                    PopupService.showPatientSignaturePopup(chart).result.then(function (signature) {
                        LabRequisitionSignature.save({
                            barcode: barcode,
                            signature: signature
                        }, onSaveSuccess, onSaveError);
                    });
                }
            })
        }

        function onSaveSuccess (result) {
            vm.dtInstance.reloadData();
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.dtInstance.reloadData();
            vm.isSaving = false;
        }

        function startSign(barcode) {
            vm.barcode = barcode;

            var message = {
                "firstName": "",
                "lastName": "",
                "eMail": "",
                "location": "",
                "imageFormat": 1,
                "imageX": 500,
                "imageY": 100,
                "imageTransparency": false,
                "imageScaling": false,
                "maxUpScalePercent": 0.0,
                "rawDataFormat": "ENC",
                "minSigPoints": 25
            };

            top.document.addEventListener('SignResponse', SignResponse, false);
            var messageData = JSON.stringify(message);
            var element = document.createElement("MyExtensionDataElement");
            element.setAttribute("messageAttribute", messageData);
            document.documentElement.appendChild(element);
            var evt = document.createEvent("Events");
            evt.initEvent("SignStartEvent", true, false);
            element.dispatchEvent(evt);
        }

        function SignResponse(event) {
            var str = event.target.getAttribute("msgAttribute");
            var objResponse = JSON.parse(str);
            var obj = null;

            if (typeof (objResponse) === 'string') {
                obj = JSON.parse(objResponse);
            } else {
                obj = JSON.parse(JSON.stringify(objResponse));
            }

            if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined") {
                toastr.error(obj.errorMsg);
            } else {
                if (obj.isSigned) {
                    LabRequisitionSignature.save({
                        barcode: vm.barcode,
                        signature: {signature: "data:image/jpg;base64," + obj.imageData}
                    }, onSaveSuccess, onSaveError);
                }
            }

            top.document.removeEventListener('SignResponse', SignResponse, false);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationTakeMissedReasonController', PatientMedicationTakeMissedReasonController);

    PatientMedicationTakeMissedReasonController.$inject = ['PatientMedicationTakeMissedReason', 'CoreService',
        'GenericEntityDatatableService', '$q', '$uibModal',
        'ConfirmationService'];

    function PatientMedicationTakeMissedReasonController (PatientMedicationTakeMissedReason, CoreService,
                                                          GenericEntityDatatableService, $q, $uibModal,
                                                          ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation()

        var entityStateName = 'patient-medication-take-missed-reason'

        vm.descriptor = {
            title: 'Patient Medication Missed Reason',
            newButtonTitle: 'New Missed Reason',
            entityClassHumanized: 'Patient Medication Missed Reason',
            entityStateName: entityStateName,
            service: PatientMedicationTakeMissedReason,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Missed Reason',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication-take-missed-reason/update/patient-medication-take-missed-reason-dialog.html',
                controller: 'PatientMedicationTakeMissedReasonDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity:['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication-take-missed-reason/patient-medication-take-missed-reason-dialog.html',
                controller: 'PatientMedicationTakeMissedReasonDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['PatientMedicationTakeMissedReason', function(PatientMedicationTakeMissedReason) {
                        return PatientMedicationTakeMissedReason.get({id : id}).$promise;
                    }]
                }
            }).result
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Missed Reason').result.then(function () {
                    PatientMedicationTakeMissedReason.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

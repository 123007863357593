(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('evaluation-template', {
                parent: 'entity',
                url: '/evaluation-template',
                data: {
                    authorities: [ROLES.ROLE_USER],
                    pageTitle: 'EvaluationTemplates'
                },
                ncyBreadcrumb: {
                    label: 'Evaluations'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation-template/list/evaluation-template.html',
                        controller: 'EvaluationTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY, access);
                }]
            })
            .state('evaluation-template.new', {
                parent: 'evaluation-template',
                url: '/new',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                ncyBreadcrumb: {
                    label: 'New Evaluation',
                    parent: 'evaluation-template'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation-template/update/evaluation-template-edit.html',
                        controller: 'EvaluationTemplateEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            name: null,
                            enabled: true,
                            patientSignature: false,
                            onlyOne: false,
                            billable: false,
                            forceStaffSignature: false,
                            forceStaffReviewSignature: false,
                            staffSignatureAuthority: [],
                            staffReviewSignatureAuthorities: [],
                            loadAutomatic: false,
                            jsonTemplate: null,
                            id: null,
                            requiredFilledByDays: null,
                            validForDays: null,
                            readmissionDays: null,
                            allowRevocation: false,
                            addemdums: false,
                            typePatientProcess: [],
                            readmissionBasedOn: null,
                            requiredGuarantorSignature: false,
                            requiredPatientSignature: false,
                            release: false,
                            summary: null,
                            mars: false,
                            consent: false,
                            attachments: false,
                            vital: false,
                            suggestedMinutes: null,
                            diets: false,
                            diagnosis: false,
                            glucose: false,
                            allergies: false,
                            weight: false,
                            medications: false,
                            stdAudit: false,
                            landscape: false,
                            addTitle: ""
                        };
                    }],
                    suggestions: ['EvaluationTemplate', function (EvaluationTemplate) {

                        return EvaluationTemplate.ckeditorSuggestions().$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY, access);
                }]
            })
            .state('evaluation-template.edit', {
                parent: 'evaluation-template',
                url: '/{id}/edit',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                ncyBreadcrumb: {
                    label: 'Edit Evaluation',
                    parent: 'evaluation-template'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation-template/update/evaluation-template-edit.html',
                        controller: 'EvaluationTemplateEditController',
                        controllerAs: 'vm'
                    },
                    'files@evaluation-template.edit': {
                        templateUrl: 'app/entities/evaluation-template-file/list/evaluation-template-file.html',
                        controller: 'EvaluationTemplateFileController',
                        controllerAs: 'vm'
                    },
                    'pro-claim-conf@evaluation-template.edit': {
                        templateUrl: 'app/entities/pro-claim-conf/pro-claim-confs.html',
                        controller: 'ProClaimConfController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    entity: ['EvaluationTemplate', '$stateParams', function (EvaluationTemplate, $stateParams) {
                        return EvaluationTemplate.get({id: $stateParams.id}).$promise;
                    }],
                    suggestions: ['EvaluationTemplate', function (EvaluationTemplate) {

                        return EvaluationTemplate.ckeditorSuggestions().$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CARE_AND_DELIVERY, access);
                }]
            });
    }
})();

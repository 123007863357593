(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GreetingController', GreetingController);

    GreetingController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Greeting', '$q', '$uibModal',
        'toastr', 'ConfirmationService'];

    function GreetingController(GenericEntityDatatableService, CoreService, Greeting, $q, $uibModal,
                                toastr, ConfirmationService) {
        var vm = this;

        var params = {facilityId: CoreService.getCurrentFacility().id};

        vm.descriptor = {
            title: 'Greeting',
            newButtonTitle: 'New Greeting',
            entityClassHumanized: 'Greeting',
            entityStateName: 'greeting',
            service: Greeting,
            serviceMethod: 'byFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Greeting',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/greeting/update/greeting-dialog.html',
                controller: 'GreetingDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/greeting/update/greeting-dialog.html',
                    controller: 'GreetingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['Greeting', function (Greeting) {
                            return Greeting.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Greeting').result.then(function () {
                    Greeting.delete({id: id}, function () {
                        toastr.success('Greeting was deleted', 'Success');

                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TelehealthMeetingChooseEditController',TelehealthMeetingChooseEditController);

    TelehealthMeetingChooseEditController.$inject = ['$uibModalInstance'];

    function TelehealthMeetingChooseEditController($uibModalInstance) {
        var vm = this;

        vm.value = 'meeting';

        vm.clear = clear;
        vm.edit = edit;

        function clear () {
            $uibModalInstance.close(false);
        }

        function edit () {
            $uibModalInstance.close(vm.value);
        }
    }
})();

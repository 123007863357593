(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionTemplateDialogController', GroupSessionTemplateDialogController);

    GroupSessionTemplateDialogController.$inject = ['$scope', '$uibModalInstance', 'entity',
        'GroupSessionTemplate', 'Facility', 'Building', 'Employee',
        'SessionType', '$rootScope', 'GUIUtils', 'CoreService',
        'GroupSessionFormTpl', 'filter'];

    function GroupSessionTemplateDialogController($scope, $uibModalInstance, entity,
                                                  GroupSessionTemplate, Facility, Building, Employee,
                                                  SessionType, $rootScope, GUIUtils, CoreService,
                                                  GroupSessionFormTpl, filter) {
        var vm = this;

        vm.groupSessionTemplate = entity;
        vm.datePickerOpenStatus = {};
        vm.isSaving = false;
        vm.buildings = Building.byFacility({id: vm.groupSessionTemplate.facility.id});
        vm.employees = vm.groupSessionTemplate.employees;
        vm.corporation = CoreService.getCorporation();

        vm.days = GUIUtils.getDays();
        vm.timeFormat = $rootScope.timeFormat;
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.stop = false;
        vm.filter = filter;

        vm.sessionTypes = SessionType.byCorporation(CoreService.getFilterByCurrentCorporation());

        if (vm.groupSessionTemplate.daysWeek)
            vm.daysWeek = GUIUtils.weekCodeStringToDays(vm.groupSessionTemplate.daysWeek);

        vm.groupSessionFormTpls = [];

        vm.getEmployees = getEmployees;
        vm.getReviewers = getReviewers;
        vm.getGroupSessionFormTpls = getGroupSessionFormTpls;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function save() {
            vm.isSaving = true;

            if (vm.daysWeek) {
                vm.groupSessionTemplate.daysWeek = GUIUtils.daysToWeekCodeString(vm.daysWeek);
            }

            if (vm.groupSessionTemplate.id !== null) {
                GroupSessionTemplate.update(vm.groupSessionTemplate, onSaveSuccess, onSaveError);
            } else {
                GroupSessionTemplate.save(vm.groupSessionTemplate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:groupSessionTemplateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getReviewers(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.reviewers = result;
                });
            }
        }

        function getGroupSessionFormTpls(query) {
            if (query && query.length > 3) {
                GroupSessionFormTpl.filter({
                    page: 0,
                    size: 20,
                    facilityId: vm.groupSessionTemplate.facility.id,
                    searchQuery: query
                }, function (result) {
                    vm.groupSessionFormTpls = result;
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConcurrentReviewsChartController', ConcurrentReviewsChartController);

    ConcurrentReviewsChartController.$inject = ['chart', 'CoreService', 'AlertService', 'toastr', '$uibModal', 'ConcurrentReviews',
        'access', 'ACTIONS', 'Auth', 'ConfirmationService'];

    function ConcurrentReviewsChartController(chart, CoreService, AlertService, toastr, $uibModal, ConcurrentReviews,
                                              access, ACTIONS, Auth, ConfirmationService) {
        var vm = this;

        vm.chart = chart;

        vm.pageActive = 1;
        vm.pageInactive = 1;
        vm.itemsPerPage = 3;

        vm.toggleItem = toggleItem;
        vm.transitionActive = transitionActive;
        vm.transitionInactive = transitionInactive;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;

        loadAllActive();
        loadAllInactive();

        function loadAllActive() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW, access)) {

                return;
            }

            var filter = {
                id: chart.id,
                active: true,
                page: vm.pageActive - 1,
                size: 3
            }

            ConcurrentReviews.findAllByChart(filter, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsActive = headers('X-Total-Count');
                vm.queryCount = vm.totalItemsActive;
                vm.concurrentReviewsActive = data;
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function loadAllInactive() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW, access)) {

                return;
            }

            var filter = {
                id: chart.id,
                active: false,
                page: vm.pageInactive - 1,
                size: 3
            }

            ConcurrentReviews.findAllByChart(filter, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsInactive = headers('X-Total-Count');
                vm.queryCount = vm.totalItemsActive;
                vm.concurrentReviewsInactive = data;
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function transitionActive() {
            loadAllActive();
        }

        function transitionInactive() {
            loadAllInactive();
        }

        function add() {
            if (vm.chart.primary) {
                $uibModal.open({
                    templateUrl: 'app/entities/concurrent-reviews/update/concurrent-reviews-dialog.html',
                    controller: 'ConcurrentReviewsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: function () {
                            return {
                                authorizationDate: null,
                                numberDates: 0,
                                frequency: null,
                                typeLevelCare: null,
                                startDate: null,
                                endDate: null,
                                authorizationNumber: null,
                                nextReviewDate: null,
                                insuranceCompany: null,
                                notes: null,
                                id: null,
                                chart: {
                                    id: chart.id
                                }
                            }
                        },
                        chart: chart
                    }
                }).result.then(function () {
                    vm.pageActive = 1;
                    vm.pageInactive = 1;
                    loadAllActive();
                    loadAllInactive();
                }, function () {
                });
            } else {
                toastr.error("The patient does not have any insurance.");
            }
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/concurrent-reviews/update/concurrent-reviews-dialog.html',
                controller: 'ConcurrentReviewsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ConcurrentReviews', function (ConcurrentReviews) {
                        return ConcurrentReviews.get({id: id}).$promise;
                    }],
                    chart: chart
                }
            }).result.then(function () {
                loadAllActive();
                loadAllInactive();
            }, function () {
                loadAllActive();
                loadAllInactive();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Concurrent Reviews').result.then(function () {
                ConcurrentReviews.delete({id: id}, function () {
                    vm.pageActive = 1;
                    vm.pageInactive = 1;
                    loadAllActive();
                    loadAllInactive();
                }, function () {
                    loadAllActive();
                    loadAllInactive();
                });
            })
        }

        function toggleItem() {
            CoreService.togglePanel('cReviews');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationAuditController', EvaluationAuditController);

    EvaluationAuditController.$inject = ['EvaluationAudit', 'CoreService', '$rootScope', 'ConfirmationService',
        '$uibModal', 'GenericEntityDatatableService', '$q'];

    function EvaluationAuditController(EvaluationAudit, CoreService, $rootScope, ConfirmationService,
                                       $uibModal, GenericEntityDatatableService, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Care & Delivery Audits',
            entityClassHumanized: 'Care & Delivery Audits',
            entityStateName: 'evaluation-audit',
            service: EvaluationAudit,
            serviceMethod: 'findAllByFilter',
            newButtonTitle: 'New Care & Delivery Audit',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [{key: 'aaSorting', value: [[0, 'desc']]}],
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'createdDate',
                    title: 'Created Date',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
                {
                    name: 'createdBy',
                    title: 'Created By'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Care & Delivery Audit',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/evaluation-audit/update/evaluation-audit-dialog.html',
                controller: 'EvaluationAuditDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            facility: CoreService.getCurrentFacility(),
                            evaluationAuditTemplates: [
                                {
                                    evaluationTemplates: [],
                                    quantity: 1
                                }
                            ]
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/evaluation-audit/update/evaluation-audit-dialog.html',
                controller: 'EvaluationAuditDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['EvaluationAudit', function (EvaluationAudit) {
                        return EvaluationAudit.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Care & Delivery Audit').result.then(function () {
                    EvaluationAudit.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ContactsFacilityDialogController', ContactsFacilityDialogController);

    ContactsFacilityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'ContactsFacility', 'TypeContactFacilityType', 'CountryState',
        'CoreService'];

    function ContactsFacilityDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                              entity, ContactsFacility, TypeContactFacilityType, CountryState,
                                              CoreService) {
        var vm = this;

        vm.contactsFacility = entity;
        vm.clear = clear;
        vm.save = save;
        vm.typecontactfacilitytypes = TypeContactFacilityType.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.countrystates = CountryState.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.contactsFacility.id !== null) {
                ContactsFacility.update(vm.contactsFacility, onSaveSuccess, onSaveError);
            } else {
                ContactsFacility.save(vm.contactsFacility, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:contactsFacilityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('DataTablesService', DataTablesService);

    DataTablesService.$inject = [];

    function DataTablesService() {

        var service = {
            toggleAll: toggleAll,
            toggleOne: toggleOne,
            getSelectedIds: getSelectedIds,
            setUniqueIds: setUniqueIds
        };

        return service;

        function toggleAll(selected, selectAll) {
            for (var id in selected) {
                if (selected.hasOwnProperty(id)) {
                    selected[id] = selectAll;
                }
            }
            getSelectedIds(selected)
        }

        function toggleOne(selected, selectAll) {
            for (var id in selected) {
                if (selected.hasOwnProperty(id)) {
                    if (!selected[id]) {
                        selectAll = false;
                        return;
                    }
                }
            }
            selectAll = true;
        }

        function getSelectedIds(selected) {
            var ids = [];

            for (var id in selected) {
                if (selected[id]) {
                    ids.push(id);
                }
            }

            return ids;
        }

        function setUniqueIds(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].id = i;
            }

            return data;
        }
    }
})();

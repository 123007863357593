/**
 * Created by PpTMUnited on 5/23/2017.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('udtAttachEvaluationFile', Attach);

    Attach.$inject = ['$state', '$sessionStorage', 'CoreService', 'toastr', 'DataUtils',
        'EvaluationFile'];

    function Attach($state, $sessionStorage, CoreService, toastr, DataUtils,
                    EvaluationFile) {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-attach-evaluation-file/udt-attach-evaluation-file.html',
            scope: {
                evaluationId: '@',
            },
            link: linkFunc
        };

        return directive;

        function linkFunc($scope) {
            $scope.employeeId = CoreService.getCurrentEmployee().id;
            $scope.showMessageConfirmDelete = false;

            getFiles();

            function getFiles() {
                EvaluationFile.findAllByEvaluation({id: $scope.evaluationId},
                    function (result) {
                        $scope.files = result;
                    });
            }

            $scope.attachFile = function (files) {
                var attachFiles = [];
                var bigFiles = _.remove(files, function (item) {

                    return parseInt(item.size) > 16000000;
                });
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    DataUtils.toBase64ToFileObject(file, function (result) {
                        result.ownerId = parseInt($scope.evaluationId);
                        attachFiles.push(result);
                        if (attachFiles.length == files.length) {
                            attach(attachFiles);
                        }
                    });
                }
                for (var i = 0; i < bigFiles.length; i++) {
                    toastr.error(bigFiles[i].name + ' file is too big. The maximum size is 16MB')
                }
            }

            function attach(result) {
                EvaluationFile.attachFiles({evaluation : {id: $scope.evaluationId}, files: result}, successResult, errorResult);
            }

            $scope.deleteFile = function (fileId) {
                EvaluationFile.delete({id: fileId},
                    function success(data) {
                        getFiles();
                        $scope.showMessageConfirmDelete = false;
                    }, function error(error) {
                        $scope.showMessageConfirmDelete = false;
                    });
            };

            $scope.getFileAttached = function (id) {
                EvaluationFile.download({id: id});
            };

            $scope.getIcon = function(type) {
                switch (type) {
                    case 'application/pdf': return 'fa-file-pdf-o';
                    case 'text/plain': return 'fa-file-text-o';
                    default: return 'fa-file-o';
                }
            };

            function successResult(result) {
                getFiles();
            }

            function errorResult(error) {
            }
        }
    }
})();

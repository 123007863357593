(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateFileController', EvaluationTemplateFileController);

    EvaluationTemplateFileController.$inject = ['entity', 'EvaluationTemplateFile', '$state', 'DTOptionsBuilder',
        'DTColumnBuilder', '$q', 'GUIUtils', 'CoreService', '$compile', '$scope', '$uibModal',
        'ConfirmationService'];

    function EvaluationTemplateFileController (entity, EvaluationTemplateFile, $state, DTOptionsBuilder,
                                               DTColumnBuilder, $q, GUIUtils, CoreService, $compile, $scope, $uibModal,
                                               ConfirmationService) {
        var vm = this;

        vm.evaluationTemplate = entity;
        vm.evaluationTemplateFile = [];
        vm.title = 'Evaluation Template File';
        vm.entityClassHumanized = 'Evaluation Template File';
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.add = add;
        vm.del = del;
        vm.getFileAttached = getFileAttached;

        vm.reload = reload;
        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            EvaluationTemplateFile.findAllByEvaluationTemplate({
                evaluationId: vm.evaluationTemplate.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            },  function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.evaluationTemplateFile = result;
                defer.resolve(result);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', false)
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Name').renderWith(function (data, type, full) {
                return data.file.name + " ["  + data.file.name + "]";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '20px').withOption('class', 'actions-col').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ' +
                'ng-click="vm.getFileAttached(' + data.id + ')">' +
                '<i class="fa fa-list-alt" aria-hidden="true"></i>See details' +
                '</a>' +
                '</li>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link red" ' +
                'ng-click="vm.del(' + data.id + ')">' +
                '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                '</a>' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }
        function search() {
            vm.dtInstance.reloadData();
        }

        function reload() {
            vm.dtInstance.reloadData();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-template-file/update/evaluation-template-file-dialog.html',
                controller: 'EvaluationTemplateFileDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function () {
                        return {
                            id: null,
                            evaluationTemplate: vm.evaluationTemplate,
                            file: null
                        }
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                reload();
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Evaluation Template File').result.then(function () {
                EvaluationTemplateFile.delete({id: id}, function () {
                    vm.page = 1;
                    reload();
                });
            })
        }

        function getFileAttached(id) {
            EvaluationTemplateFile.download({id: id});
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('CrmReferralContactSearch', CrmReferralContactSearch);

    CrmReferralContactSearch.$inject = ['$resource'];

    function CrmReferralContactSearch($resource) {
        var resourceUrl =  'api/_search/crm-referral-contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('WeightController', WeightController);

    WeightController.$inject = ['$compile', '$scope', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter',
        'Weight', '$rootScope', 'GUIUtils', '$uibModal', 'chart', '$uibModalInstance', 'CoreService',
        'ConfirmationService', '$uibModalStack'];

    function WeightController($compile, $scope, $q, DTColumnBuilder, DTOptionsBuilder, $filter, Weight,
                              $rootScope, GUIUtils, $uibModal, chart, $uibModalInstance, CoreService,
                              ConfirmationService, $uibModalStack) {
        var vm = this;
        vm.clear = clear;

        vm.chart = chart;
        vm.weights = [];
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.clear = clear;
        vm.search = search;
        vm.edit = edit;
        vm.del = del;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Weight.findAllByChart(
                {
                    id: vm.chart.id,
                    page: vm.page - 1,
                    size: vm.itemsPerPage
                }, function (result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.weights = result;
                    defer.resolve(vm.weights);
                });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notVisible().renderWith(function (data, type, full) {
                return data ? moment(data.date).format("YYYYMMDD") : "";
            }),
            DTColumnBuilder.newColumn('date').withTitle('Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.amDateFormat) : "";
            }),
            DTColumnBuilder.newColumn('weight').withTitle('Weight').renderWith(function (data, type, full) {
                return data ? _.ceil(data, 2) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Height').renderWith(function (data, type, full) {
                return CoreService.getHeightInchesToFeetInches(data.height);
            }),
            DTColumnBuilder.newColumn('bmi').withTitle('BMI').renderWith(function (data, type, full) {
                return data ? _.ceil(data, 2) : "";
            }),
            DTColumnBuilder.newColumn('createdBy').withTitle('Created By'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="dt-btn warning" ng-click="vm.edit(' + data.id + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            if (vm.chart.status) {
                stButtons += '<a class="dt-btn danger" ng-click="vm.del(' + data.id + ')">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function edit(id) {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/weight/update/weight-dialog.html',
                controller: 'WeightDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['Weight', function (Weight) {
                        return Weight.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Weight').result.then(function () {
                Weight.delete({id: id}, function () {
                    search();
                });
            })
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartNoteController', ChartNoteController);

    ChartNoteController.$inject = ['chart', 'ChartNote', 'CoreService', 'ParseLinks', 'AlertService', '$uibModal',
        '$state', 'ACTIONS', 'access', 'Auth', 'ConfirmationService'];

    function ChartNoteController(chart, ChartNote, CoreService, ParseLinks, AlertService, $uibModal,
                                 $state, ACTIONS, access, Auth, ConfirmationService) {
        var vm = this;
        vm.chart = chart;

        vm.page = 1;
        vm.pageActive = 1;
        vm.pageInactive = 1;
        vm.itemsPerPage = 3;

        vm.toggleItem = toggleItem;
        vm.transitionActive = transitionActive;
        vm.transitionInactive = transitionInactive;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;

        loadAllActive();
        loadAllInactive();

        function loadAllActive() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW, access)) {

                return;
            }

            var filter = {
                id: chart.id,
                active: true,
                page: vm.pageActive - 1,
                size: ($state.current.name === 'chart-new') ? 1000 : 3
            };

            ChartNote.findAllByChart(filter, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsActive = headers('X-Total-Count');
                vm.queryCount = vm.totalItemsActive;
                vm.chartNotesActive = data;
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function loadAllInactive() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW, access)) {
                return;
            }

            var filter = {
                id: chart.id,
                active: false,
                page: vm.pageInactive - 1,
                size: 3
            };

            ChartNote.findAllByChartInactive(filter, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsInactive = headers('X-Total-Count');
                vm.queryCount = vm.totalItemsInactive;
                vm.chartNotesInactive = data;
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function transitionActive() {
            loadAllActive();
        }

        function transitionInactive() {
            loadAllInactive();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/chart-note/update/chart-note-dialog.html',
                controller: 'ChartNoteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            note: null,
                            date: null,
                            id: null,
                            chart: chart,
                            employee: CoreService.getCurrentEmployee()
                        }
                    }]
                }
            }).result.then(function () {
                vm.pageActive = 1;
                vm.pageInactive = 1;
                loadAllActive();
                loadAllInactive();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/chart-note/update/chart-note-dialog.html',
                controller: 'ChartNoteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ChartNote', function (ChartNote) {
                        return ChartNote.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAllActive();
                loadAllInactive();
            }, function () {
                loadAllActive();
                loadAllInactive();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Note').result.then(function () {
                ChartNote.delete({id: id}, function () {
                    loadAllActive();
                    loadAllInactive();
                });
            })
        }

        function toggleItem() {
            CoreService.togglePanel('notes');
        }
    }
})();

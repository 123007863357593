(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationGlucoseController', EvaluationGlucoseController);

    EvaluationGlucoseController.$inject = ['$scope', '$state', 'EvaluationGlucose', 'entity', '$uibModal'];

    function EvaluationGlucoseController($scope, $state, EvaluationGlucose, entity, $uibModal) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationGlucose = null;

        vm.loadAll = loadAll;
        vm.add = add;
        vm.history = history;

        loadAll();

        function loadAll() {
            if (!vm.evaluation.glucose) {
                return;
            }

            EvaluationGlucose.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                if (result.length > 0) {
                    vm.evaluationGlucose = result[0];
                    vm.glucoseInterventions = _.map(vm.evaluationGlucose.glucoseInterventions, function (glucoseIntervention) {
                        return glucoseIntervention.name;
                    }).join(', ');
                }
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/glucose/update/glucose-dialog.html',
                controller: 'EvaluationGlucoseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            evaluation: {id: vm.evaluation.id},
                            glucose: {
                                name: null,
                                id: null,
                                chart: vm.evaluation.chart
                            }
                        }
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/glucose/history/glucose-history.html',
                controller: 'GlucoseHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.evaluation.chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('pending-by-staff', {
                parent: 'entity',
                url: '/pending-by-staff',
                ncyBreadcrumb: {
                    label: 'Unsigned Care and Delivery'
                },
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/dashboard/staff/pending-by-staff.html',
                        controller: 'PendingByStaffController',
                        controllerAs: 'vm'
                    }
                },
            })
            .state('pending-by-staff-review', {
                parent: 'entity',
                url: '/pending-by-staff-review',
                ncyBreadcrumb: {
                    label: 'Pending Care and Delivery by Staff Review'
                },
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/dashboard/review/review.html',
                        controller: 'ReviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function () {
                        return {
                            page: 1,
                            itemsPerPage: 25
                            // ,
                            // sort: $stateParams.sort,
                            // predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            // ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    filter: function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dateBirth: null,
                            status: null,
                            createDate: null,
                            noted: false,
                            orderBy: null,
                            levelCare: null
                        };
                    },
                    employee: ['Employee', function (Employee) {
                        return Employee.employeeAccount().$promise;
                    }]
                }
            })
            .state('empty', {
                parent: 'entity',
                url: '/empty',
                ncyBreadcrumb: {
                    label: 'Empties Care and Delivery'
                },
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/dashboard/empty/empty.html',
                        controller: 'EmptyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function () {
                        return {
                            page: 0,
                            itemsPerPage: 18
                            // ,
                            // sort: $stateParams.sort,
                            // predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            // ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    filter: function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dateBirth: null,
                            status: null,
                            createDate: null,
                            orderBy: null,
                            levelCare: null
                        };
                    },
                    employee: ['Employee', function (Employee) {
                        return Employee.employeeAccount().$promise;
                    }]
                }
            })
            .state('pending-by-patient', {
                parent: 'entity',
                url: '/pending-by-patients',
                ncyBreadcrumb: {
                    label: 'Pending Care and Delivery by Patients'
                },
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/dashboard/patient/pending-by-patient.html',
                        controller: 'PendingByPatientController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: function () {
                        return {
                            name: null,
                            patientName: null,
                            dob: null,
                            createdDate: null,
                            typeLevelCare: null
                        };
                    },
                    employee: ['Employee', function (Employee) {
                        return Employee.employeeAccount().$promise;
                    }]
                }
            })
            .state('late', {
                parent: 'entity',
                url: '/late',
                ncyBreadcrumb: {
                    label: 'Late Care and Delivery'
                },
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/dashboard/late/late.html',
                        controller: 'LateController',
                        controllerAs: 'vm'
                    }
                },
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DrfAccountController', DrfAccountController);

    DrfAccountController.$inject = ['GenericEntityDatatableService', 'CoreService', 'DrfAccount', '$uibModal',
        '$q', 'ConfirmationService'];

    function DrfAccountController(GenericEntityDatatableService, CoreService, DrfAccount, $uibModal,
                              $q, ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Drf Accounts',
            newButtonTitle: 'New Drf Account',
            entityClassHumanized: 'Drf Accounts',
            entityStateName: 'drf-account',
            service: DrfAccount,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'username',
                    title: 'Username',
                },
                {
                    name: 'password',
                    title: 'Password',
                },
                {
                    name: 'signature',
                    title: 'Signature',
                    render: function (data) {
                        return data.signature ? data.signature : "";
                    }
                },
                {
                    name: 'externalId',
                    title: 'ExternalId',
                },
                {
                    name: 'drfAccountType',
                    title: 'Account Type',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New DrfAccount',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/drf-account/update/drf-account-dialog.html',
                controller: 'DrfAccountDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            username: null,
                            password: null,
                            signature: null,
                            externalId: null,
                            drfAccountType: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/drf-account/update/drf-account-dialog.html',
                controller: 'DrfAccountDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['DrfAccount', function (DrfAccount) {
                        return DrfAccount.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('DrfAccount').result.then(function () {
                    DrfAccount.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

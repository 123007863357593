(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PartialResultsTopBoxController', PartialResultsTopBoxController);

    PartialResultsTopBoxController.$inject = ['facility', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult'];

    function PartialResultsTopBoxController(facility, $scope, $state, $q, DTColumnBuilder, PatientResult) {
        var vm = this;
        vm.partial = 0;

        vm.partial = PatientResult.countPartial({id: facility.id});
    }
})();

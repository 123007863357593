(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacesheetSectionController', FacesheetSectionController);

    FacesheetSectionController.$inject = ['GenericEntityDatatableService', 'CoreService', 'FacesheetSection',
        '$uibModal', 'ConfirmationService', '$q'];

    function FacesheetSectionController(GenericEntityDatatableService, CoreService, FacesheetSection,
                                        $uibModal, ConfirmationService, $q) {

        var vm = this;

        var params = CoreService.getFilterByFacility(CoreService.getCurrentFacility());

        vm.descriptor = {
            title: 'Facesheet Sections',
            newButtonTitle: 'New Facesheet Section',
            entityClassHumanized: 'Facesheet Sections',
            entityStateName: 'facesheet-section',
            service: FacesheetSection,
            serviceMethod: 'getAllByFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'title',
                    title: 'Title',
                },
                {
                    name: 'facesheetSectionType',
                    title: 'Facesheet Section Type',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Facesheet Sections',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facesheet-section/update/facesheet-section-dialog.html',
                controller: 'FacesheetSectionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            title: null,
                            facesheetSectionType: null,
                            id: null
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/facesheet-section/update/facesheet-section-dialog.html',
                controller: 'FacesheetSectionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['FacesheetSection', function (FacesheetSection) {
                        return FacesheetSection.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Facesheet Section').result.then(function () {
                    FacesheetSection.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgMedicationController', ProgMedicationController);

    ProgMedicationController.$inject = ['$scope', '$state', 'ProgMedication', 'CoreService',
        'DTColumnBuilder', 'DTOptionsBuilder', '$q', '$compile', '$filter'];

    function ProgMedicationController ($scope, $state, ProgMedication, CoreService,
                                       DTColumnBuilder, DTOptionsBuilder, $q, $compile, $filter ) {
        var vm = this;

        vm.progMedications = [];
        vm.dtInstance = {};
        vm.title = 'Program Eligibility Medication';
        vm.entityClassHumanized = 'Program Eligibility Medication';
        vm.entityStateName = 'prog-medication';
        vm.corporation = CoreService.getCorporation();
        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();

            if (!vm.searchQuery || vm.searchQuery == '') {
                ProgMedication.findAllByFilter({
                    corporationId: vm.corporation.id
                }, function (result) {
                    vm.progMedications = result;
                    defer.resolve(vm.progMedications);
                });
            }else {
                defer.resolve($filter('filter')(vm.progMedications, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withDisplayLength(15)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('aaSorting', [0, 'asc'])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Medication').renderWith(function (data, type, full) {
                return data.medication == null ? " " : data.medication.name;
            }),

            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ' +
                'ui-sref="' + $state.current.name + '.edit({id:' + data.id + '})">' +
                '<i class="fa fa-edit" aria-hidden="true"></i>Edit' +
                '</a>' +
                '</li>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link red" ' +
                'ui-sref="' + $state.current.name + '.delete({id:' + data.id + '})">' +
                '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();

/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartReviewBoxController', ChartReviewBoxController);

    ChartReviewBoxController.$inject = ['facility', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult', 'DashboardService', 'CoreService'];

    function ChartReviewBoxController(facility, $scope, $state, $q, DTColumnBuilder, PatientResult, DashboardService, CoreService) {
        var vm = this;
        vm.total = 0;
        vm.countDiagnosis = 0;
        vm.percentDiagnosis = 0;
        vm.countInsurance = 0;
        vm.percentInsurance = 0;
        vm.countCareTeam = 0;
        vm.percentCareTeam = 0;
        vm.facility = CoreService.getCurrentFacility();

        countDiagnosis();
        countInsurance();
        countCareTeam();

        function countDiagnosis () {
            DashboardService.countWithoutDiagnosis({id:vm.facility.id}, function(data) {
                if (data.value) {
                    vm.countDiagnosis = data.value;
                    total();
                }
            });
        }

        function countInsurance () {
            DashboardService.countWithoutInsurance({id:vm.facility.id}, function(data) {
                if (data.value) {
                    vm.countInsurance = data.value;
                    total();
                }
            });
        }

        function countCareTeam () {
            DashboardService.countWithoutCareTeam({id:vm.facility.id}, function(data) {
                if (data.value) {
                    vm.countCareTeam = data.value;
                    total();
                }
            });
        }

        function total () {
            vm.total = parseInt(vm.countDiagnosis) + parseInt(vm.countInsurance) + parseInt(vm.countCareTeam);
            vm.percentDiagnosis = (parseInt(vm.countDiagnosis) * 100 / parseInt(vm.total));
            vm.percentDiagnosis= vm.percentDiagnosis.toFixed(2);
            vm.percentInsurance = parseInt(vm.countInsurance) * 100 / parseInt(vm.total);
            vm.percentInsurance = vm.percentInsurance.toFixed(2);
            vm.percentCareTeam = parseInt(vm.countCareTeam) * 100 / parseInt(vm.total);
            vm.percentCareTeam = vm.percentCareTeam.toFixed(2);
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmCallController', CrmCallController);

    CrmCallController.$inject = ['CrmCall', 'AlertService', 'entity', '$uibModal', 'CoreService', '$sce'];

    function CrmCallController(CrmCall, AlertService, entity, $uibModal, CoreService, $sce) {
        var vm = this;

        vm.crmOpportunity = entity;
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.crmCalls = [];
        vm.pinCrmCalls = [];
        vm.groupedCrmCalls = [];
        vm.employee = CoreService.getCurrentEmployee();

        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.isOwner = isOwner;
        vm.pin = pin;
        vm.replace = replace;
        vm.getUnpin = getUnpin;
        vm.trustAsHtml = trustAsHtml;

        loadAll();

        function loadAll() {
            CrmCall.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                pin: true,
                unpaged: true
            }, function (data, headers) {
                vm.pinCrmCalls = data;
            }, onError);

            getUnpin();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-call/crm-call-dialog.html',
                controller: 'CrmCallDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            description: null,
                            pin: false,
                            id: null,
                            crmOpportunity: vm.crmOpportunity,
                            employee: CoreService.getCurrentEmployee(),
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-call/crm-call-dialog.html',
                controller: 'CrmCallDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CrmCall', function (CrmCall) {
                        return CrmCall.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function del(id) {
            CrmCall.delete({id: id}, function () {
                loadAll();
            });
        }

        function isOwner(call) {

            return vm.employee.id == call.employee.id;
        }

        function pin(call) {
            CrmCall.pin(call, function () {
                loadAll();
            })
        }

        function replace(str) {

            return str ? str.replace(/_/g,  " ") : "";
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getUnpin() {
            CrmCall.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                pin: false,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, function (data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.crmCalls = data;
            }, onError);
        }

        function trustAsHtml(call) {
            return $sce.trustAsHtml(call);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabRequisitionDetailController', LabRequisitionDetailController);

    LabRequisitionDetailController.$inject = ['$uibModalInstance', 'CoreService', 'DTColumnBuilder', 'DTOptionsBuilder',
        '$q', 'PatientOrder', 'AlertService', 'LabCompendium', 'patientOrder',
        'Chart', 'Insurance', 'barcode'];

    function LabRequisitionDetailController($uibModalInstance, CoreService, DTColumnBuilder, DTOptionsBuilder,
                                            $q, PatientOrder, AlertService, LabCompendium, patientOrder,
                                            Chart, Insurance, barcode) {
        var vm = this;
        vm.date = null;
        vm.chart = null;
        vm.primary = null;
        vm.dtInstance = {};

        vm.clear = clear;

        init();

        function init() {
            Chart.get({id: patientOrder.chartId}, function (chart) {
                vm.chart = chart;

                if (vm.chart.primary) {
                    Insurance.get({id: vm.chart.primary.id}, function (insurance) {
                        vm.primary = insurance;
                    });
                }
            });

            vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();

                if (barcode.barcode && barcode.barcode.length > 0) {
                    LabCompendium.findAllByBarcode({barcode: barcode.barcode}, onSuccess, onError);
                } else {
                    LabCompendium.findAllByPatientOrder({id: patientOrder.id, date: barcode.date}, onSuccess, onError);
                }

                function onSuccess(data) {
                    defer.resolve(data);
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }

                return defer.promise;
            }).withPaginationType('full_numbers').withBootstrap().withDOM('tp').withDisplayLength(5).withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            });

            vm.dtColumns = [
                DTColumnBuilder.newColumn('code').withTitle('Test'),
                DTColumnBuilder.newColumn('description').withTitle('Description')
            ];

            vm.dtInstance1 = {};

            vm.dtOptions1 = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();

                defer.resolve([patientOrder]);

                return defer.promise;
            }).withPaginationType('full_numbers').withBootstrap().withDOM('tp').withDisplayLength(3).withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            });

            vm.dtColumns1 = [
                DTColumnBuilder.newColumn('id').withTitle('# Order'),
                DTColumnBuilder.newColumn('orderType').withTitle('Order Type'),
                DTColumnBuilder.newColumn(null).withTitle('Via').renderWith(function (data, type, full) {
                    return data.via ? data.via.name : "";
                }),
                DTColumnBuilder.newColumn(null).withTitle('Order Type').renderWith(function (data, type, full) {
                    return data.signedBy.firstName + " " + data.signedBy.lastName;
                })
            ];
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

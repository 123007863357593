(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BedSheetAssignDialogController', BedSheetAssignDialogController);

    BedSheetAssignDialogController.$inject = ['$uibModal', '$compile', '$scope', 'moment', 'Chart', 'DateUtils',
        'CoreService', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter',
        '$rootScope', 'toastr', 'DataTablesService', 'bed', '$uibModalInstance', 'Bed'];

    function BedSheetAssignDialogController($uibModal, $compile, $scope, moment, Chart, DateUtils, CoreService,
                                            $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter,
                                            $rootScope, toastr, DataTablesService, bed, $uibModalInstance, Bed) {
        var vm = this;

        vm.dtInstance = {};
        vm.title = "Charts Assign Bed";
        vm.facility = CoreService.getCurrentFacility();
        vm.charts = [];
        vm.disableTitle = false;
        vm.bed = bed;
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.search = search;
        vm.assign = assign;
        vm.clear = clear;
        vm.transition = transition;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            Chart.fullFilter(getFilter(), function (result, headers) {
                vm.charts = result;
                vm.totalItems = headers('X-Total-Count');
                defer.resolve(result);
            });

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [])
            .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false)
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').notSortable().renderWith(function (data, type, full) {
                return data.lastName + ", " + data.firstName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Date Birth').notSortable().renderWith(function (data, type, full) {
                return moment(data.dateBirth).format($rootScope.dateFormat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Mr Number').notSortable().renderWith(function (data, type, full) {
                return data.mrNo;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Admission Date').notSortable().renderWith(function (data, type, full) {
                return moment(data.admissionDate).format($rootScope.dateFormat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Discharge Date').notSortable().renderWith(function (data, type, full) {
                return data.dischargeDate != null ? moment(data.dischargeDate).format($rootScope.dateFormat) : " ";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Referral Source').notSortable().renderWith(function (data, type, full) {
                return data.referrer ? data.referrer : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().renderWith(actionsHtml)

        ];

        function actionsHtml(data, type, full, meta) {

            return '<div class="btn-group dd-buttons" uib-dropdown>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ng-click="vm.assign(' + data.id + ')">' +
                '<i class="fa fa-bed" aria-hidden="true"></i> Assign Bed' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function search() {
            vm.page = 1;
            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var filter = {};

            filter.facilityId = vm.facility.id;
            filter.searchQuery = vm.searchQuery;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.noBed = true;
            filter.statuses = 'CURRENT';

            return filter;
        }

        function assign(idChart) {
            Bed.assignToChart({
                chartId: idChart,
                bedId: vm.bed.id
            }, function () {
                toastr.success('Assign operation successfully');
                $uibModalInstance.close();
            }, function (error) {
                toastr.error('Assign operation fail');
                $uibModalInstance.close();
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function transition() {
            vm.dtInstance.reloadData();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProtocolGroupController', ProtocolGroupController);

    ProtocolGroupController.$inject = ['GenericEntityDatatableService', 'ProtocolGroup', 'CoreService',
        '$q', '$uibModal', 'ConfirmationService'];

    function ProtocolGroupController(GenericEntityDatatableService, ProtocolGroup, CoreService,
                                     $q, $uibModal, ConfirmationService) {

        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Protocol Group',
            newButtonTitle: 'New Protocol Group',
            entityClassHumanized: 'Protocol Group',
            entityStateName: 'protocol-group',
            service: ProtocolGroup,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Protocol Group',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/protocol-group/update/protocol-group-dialog.html',
                controller: 'ProtocolGroupDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/protocol-group/update/protocol-group-dialog.html',
                controller: 'ProtocolGroupDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ProtocolGroup', function(ProtocolGroup) {
                        return ProtocolGroup.get({id : id}).$promise;
                    }]
                }
            }).result
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Protocol Group').result.then(function () {
                    ProtocolGroup.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

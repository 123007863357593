(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationCommentController', EvaluationCommentController);

    EvaluationCommentController.$inject = ['$uibModal', 'entity', 'AlertService', 'EvaluationComment', 'toastr',
        'ConfirmationService'];

    function EvaluationCommentController($uibModal, entity, AlertService, EvaluationComment, toastr,
                                         ConfirmationService) {
        var vm = this;

        vm.evaluation = entity;
        vm.comments = [];
        vm.comment = null;

        vm.page = 1;
        vm.itemsPerPage = 6;

        vm.add = add;
        vm.transition = transition;
        vm.edit = edit;
        vm.del = del;

        loadAll();

        function loadAll() {
            EvaluationComment.findAllByEvaluation(getFilter(),
                function (data, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.comments = data;
                },

                function (error) {
                    AlertService.error(error.data.message);
                });
        }

        function transition() {
            loadAll();
        }

        function search() {
            vm.page = 1;

            loadAll();
        }

        function getFilter() {
            var filter = {
                id: vm.evaluation.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }

            return filter;
        }

        function add() {
            vm.isSaving = true;
            EvaluationComment.save({
                evaluation: {id: vm.evaluation.id},
                chart: {id: vm.evaluation.chart.id},
                comment: vm.comment
            }, function (result) {
                toastr.success('The Comment was added successful');
                vm.comment = null;
                search();
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-comment/update/evaluation-comment-dialog.html',
                controller: 'EvaluationCommentsDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['EvaluationComment', function (EvaluationComment) {
                        return EvaluationComment.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Comment').result.then(function () {
                EvaluationComment.delete({id: id}, function () {
                    search();
                });
            })
        }
    }
})();

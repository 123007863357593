(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CorporationWizardDialogController', CorporationWizardDialogController);

    CorporationWizardDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Corporation', 'InitValue', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile',
        '$q', 'DataTablesService'];

    function CorporationWizardDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                               Corporation, InitValue, DTOptionsBuilder, DTColumnBuilder, $compile,
                                               $q, DataTablesService) {
        var vm = this;

        vm.corporation = entity;
        vm.entities = InitValue.loadEntitiesByType({type: 'CORPORATION'});
        vm.index = -1;
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 1000000;
        vm.selected = {};
        vm.selectAll = false;

        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.entityValues = [];

        init();

        vm.clear = clear;
        vm.save = save;
        vm.loadEntity = loadEntity;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.corporation.id !== null) {
                Corporation.update(vm.corporation, onSaveSuccess, onSaveError);
            } else {
                var mapToReturn = {};
                _.forEach(vm.entityValues, function (value) {
                    mapToReturn[value.name] = value.selectedValues;
                });

                Corporation.save({corporation: vm.corporation, entities: mapToReturn}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:corporationUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
        }

        function init() {
            vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();

                vm.selected = {};
                vm.selectAll = false;

                if (vm.index >= 0) {
                    InitValue.findAllByFilter(getFilter(), function (result, headers) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.initValues = result;
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }).withBootstrap()
                .withDOM('ltp')
                .withOption("bLengthChange", false)
                .withOption("bPaginate", false)
                .withOption('headerCallback', function (header) {
                    if (!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('fnRowCallback',
                    function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        $compile(nRow)($scope);
                    });

            vm.dtColumns = [
                DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                    vm.selected[full.id] = false;
                    var column = '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-change="vm.toggleOne()">';
                    _.forOwn(vm.entityValues, function (value, key) {
                        if (vm.entityValues[key].name === vm.entities[vm.index]) {
                            if (value.selectedValues.length > 0) {
                                _.forEach(value.selectedValues, function (value) {
                                    if (data.id.toString() === value) {
                                        vm.selected[data.id] = true;
                                        column = '<input type="checkbox" ng-checked="true" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
                                    }
                                });
                            }
                        }
                    });

                    return column;
                }),
                DTColumnBuilder.newColumn('name').withTitle('Name')
            ];
        }

        function loadEntity(index) {
            //implement this
            getSelected();
            vm.index = index;

            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var filter = {};

            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.sort = "name";
            filter.type = 'CORPORATION';
            filter.entity = vm.entities[vm.index];

            return filter;
        }

        function getSelected() {
            var selectedValues = [];
            if (_.size(vm.selected) > 0) {
                _.forOwn(vm.selected, function (value, key) {
                    if (value) {
                        selectedValues.push(key);
                    }
                });
            }
            if (_.isEmpty(vm.entityValues) && !_.isEmpty(selectedValues)) {
                vm.entityValues.push({'name': vm.entities[vm.index], 'selectedValues': selectedValues});
            }
            if (!_.isEmpty(vm.entityValues) && !_.isEmpty(selectedValues)) {
                var aux = _.find(vm.entityValues, ['name', vm.entities[vm.index]]);
                if (aux) {
                    aux.selectedValues = selectedValues;
                } else {
                    vm.entityValues.push({'name': vm.entities[vm.index], 'selectedValues': selectedValues});
                }
            } else {
                _.forOwn(vm.entityValues, function (value, key) {
                    if (vm.entityValues[key].name === vm.entities[vm.index]) {
                        _.remove(vm.entityValues, vm.entityValues[key]);
                    }
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PendingByStaffBoxController', PendingByStaffBoxController);

    PendingByStaffBoxController.$inject = ['DashboardService', 'CoreService'];

    function PendingByStaffBoxController(DashboardService, CoreService) {

        var vm = this;

        vm.count = 0;
        vm.facility = CoreService.getCurrentFacility();

        DashboardService.countAllPendingByStaffFilter({
            facilityId: vm.facility.id
        }, function (data) {
            if (data.count) {
                vm.count = data.count;
            }
        });
    }
})();

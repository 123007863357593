(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmEmail', CrmEmail);

    CrmEmail.$inject = ['$resource', 'DateUtils'];

    function CrmEmail ($resource, DateUtils) {
        var resourceUrl =  'api/crm-emails/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByFilter': {url: 'api/crm-emails/filter', method: 'GET', isArray: true},
            'pin': {url: 'api/crm-emails/pin', method: 'POST'}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityWizardDialogController', FacilityWizardDialogController);

    FacilityWizardDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Corporation', 'InitValue', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile',
        '$q', 'DataTablesService', 'DataUtils', 'Facility', 'ContactsFacility', 'CoreService',
        '$rootScope', 'CountryState', '$sessionStorage'];

    function FacilityWizardDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                            Corporation, InitValue, DTOptionsBuilder, DTColumnBuilder, $compile,
                                            $q, DataTablesService, DataUtils, Facility, ContactsFacility, CoreService,
                                            $rootScope, CountryState, $sessionStorage) {
        var vm = this;

        vm.facility = entity;
        vm.states = CountryState.query();
        Facility.getAvailableZoneIds(function (result) {
            vm.timeZones = result;
            vm.zoneId = _.filter(vm.timeZones, {zoneId: vm.facility.timeZone})[0];
        });
        vm.infoTab = $sessionStorage.facilityTab ? $sessionStorage.facilityTab.infoTab : 0;

        vm.entities = InitValue.loadEntitiesByType({type: 'FACILITY'});
        vm.index = -1;
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 1000000;
        vm.selected = {};
        vm.selectAll = false;

        Facility.getAllPlaceOfServices(function (result) {
            vm.placeOfService = result;
        });

        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.entityValues = [];

        init();

        vm.keepTab = keepTab;
        vm.clear = clear;
        vm.save = save;
        vm.loadEntity = loadEntity;
        vm.searchAccount = searchAccount;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.facility) {
                vm.facility.timeZone = vm.zoneId.zoneId;
            }

            var mapToReturn = {};
            _.forEach(vm.entityValues, function (value) {
                mapToReturn[value.name] = value.selectedValues;
            });

            vm.facility.entities = mapToReturn;

            Facility.save(vm.facility, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:facilityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function init() {
            vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();

                vm.selected = {};
                vm.selectAll = false;

                if (vm.index >= 0) {
                    InitValue.findAllByFilter(getFilter(), function (result, headers) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.initValues = result;
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }).withBootstrap()
                .withDOM('ltp')
                .withOption("bLengthChange", false)
                .withOption("bPaginate", false)
                .withOption('headerCallback', function (header) {
                    if (!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('fnRowCallback',
                    function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        $compile(nRow)($scope);
                    });

            vm.dtColumns = [
                DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                    vm.selected[full.id] = false;
                    var column = '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-change="vm.toggleOne()">';
                    _.forOwn(vm.entityValues, function (value, key) {
                        if (vm.entityValues[key].name === vm.entities[vm.index]) {
                            if (value.selectedValues.length > 0) {
                                _.forEach(value.selectedValues, function (value) {
                                    if (data.id.toString() === value) {
                                        vm.selected[data.id] = true;
                                        column = '<input type="checkbox" ng-checked="true" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
                                    }
                                });
                            }
                        }
                    });

                    return column;
                }),
                DTColumnBuilder.newColumn('name').withTitle('Name')
            ];

        }

        function loadEntity(index) {
            getSelected();

            vm.index = index;
            vm.dtInstance.reloadData();

        }

        function getFilter() {
            var filter = {};

            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.sort = "name";
            filter.type = 'FACILITY';
            filter.entity = vm.entities[vm.index];

            return filter;
        }

        function getSelected() {

            var selectedValues = [];
            if (_.size(vm.selected) > 0) {
                _.forOwn(vm.selected, function (value, key) {
                    if (value) {
                        selectedValues.push(key);
                    }
                });
            }
            if (_.isEmpty(vm.entityValues) && !_.isEmpty(selectedValues)) {
                vm.entityValues.push({'name': vm.entities[vm.index], 'selectedValues': selectedValues});
            }
            if (!_.isEmpty(vm.entityValues) && !_.isEmpty(selectedValues)) {
                var aux = _.find(vm.entityValues, ['name', vm.entities[vm.index]]);
                if (aux) {
                    aux.selectedValues = selectedValues;
                } else {
                    vm.entityValues.push({'name': vm.entities[vm.index], 'selectedValues': selectedValues});
                }
            } else {
                _.forOwn(vm.entityValues, function (value, key) {
                    if (vm.entityValues[key].name === vm.entities[vm.index]) {
                        _.remove(vm.entityValues, vm.entityValues[key]);
                    }
                });
            }
        }

        function keepTab(index) {
            $sessionStorage.facilityTab = {infoTab: index};
            vm.infoTab = index;
        }

        function searchAccount(query) {
            if (query && query.length > 2) {
                Facility.existAccount({account: query}, function (result) {
                    vm.accountExist = result.value != null && result.value !== vm.facility.id;
                });
            }
        }
    }
})();

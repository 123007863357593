(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TelehealthMeetingDialogController', TelehealthMeetingDialogController);

    TelehealthMeetingDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'TelehealthMeeting', 'Employee',
        'CoreService', 'GUIUtils', 'Facility'];

    function TelehealthMeetingDialogController($scope, $uibModalInstance, entity, TelehealthMeeting, Employee,
                                               CoreService, GUIUtils, Facility) {
        var vm = this;

        vm.telehealthMeeting = entity;
        vm.showPassword = false;
        vm.customRecurrences = [];
        vm.datePickerOpenStatus = {};
        vm.datePickerOptions = {
            startDateTime: {minDate: new Date()},
            customDateTime: {minDate: new Date(moment(vm.telehealthMeeting.startDate).add(1, 'minutes'))},
            recurrenceEndDate: {minDate: new Date(moment(vm.telehealthMeeting.startDate).add(1, 'days'))}
        };
        vm.customDatePicked = new Date(moment(vm.telehealthMeeting.startDate).add(1, 'minutes'));
        vm.days = GUIUtils.getDays();
        vm.weekDays = GUIUtils.weekCodeStringToDays(vm.telehealthMeeting.telehealthMeetingRecurrence.weekDays);
        Facility.getAvailableZoneIds(function (result) {
            vm.timeZones = _.map(result, function (timeZone) {
                return timeZone.zoneId + ' ' + timeZone.offset;
            });
        });

        vm.save = save;
        vm.cancel = cancel;
        vm.getEmployees = getEmployees;
        vm.openCalendar = openCalendar;
        vm.addCustomDate = addCustomDate;
        vm.deleteCustomDate = deleteCustomDate;
        vm.startDateChanged = startDateChanged;
        vm.recurrenceChanged = recurrenceChanged;

        function save() {
            vm.isSaving = true;
            vm.telehealthMeeting.telehealthMeetingRecurrence.end === 'RECURRENCE' ? vm.telehealthMeeting.telehealthMeetingRecurrence.endDate = null : vm.telehealthMeeting.telehealthMeetingRecurrence.repeat = null;

            switch (vm.telehealthMeeting.telehealthMeetingRecurrence.frequency) {
                case 'NONE' :
                case 'CUSTOM' : {
                    vm.telehealthMeeting.telehealthMeetingRecurrence.weekDays = null;
                    vm.telehealthMeeting.telehealthMeetingRecurrence.end = null;
                    vm.telehealthMeeting.telehealthMeetingRecurrence.repeat = null;
                    break;
                }
                case 'DAILY' :
                case 'MONTHLY' : {
                    vm.telehealthMeeting.telehealthMeetingRecurrence.weekDays = null;
                    break;
                }
                case 'WEEKLY' : {
                    vm.telehealthMeeting.telehealthMeetingRecurrence.weekDays = GUIUtils.daysToWeekCodeString(vm.weekDays);
                }
            }

            TelehealthMeeting.saveAll(getTelehealthMeetingUploadProj(), onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function onSaveError() {
            $uibModalInstance.dismiss('cancel');
            vm.isSaving = false;
        }

        function cancel() {
            $uibModalInstance.close(false);
        }

        function addCustomDate(date) {
            vm.customRecurrences.push(date);
            vm.customDatePicked = new Date(moment(vm.telehealthMeeting.startDate).add(1, 'minutes'));
        }

        function deleteCustomDate(index) {
            vm.customRecurrences.splice(index, 1);
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function startDateChanged() {
            vm.customDatePicked = new Date(moment(vm.telehealthMeeting.startDate));
            vm.datePickerOptions.customDateTime = {minDate: new Date(moment(vm.telehealthMeeting.startDate).add(1, 'minutes'))};

            switch (vm.telehealthMeeting.telehealthMeetingRecurrence.frequency) {
                case 'DAILY' : {
                    recurrenceChanged('days')
                    break;
                }
                case 'WEEKLY' : {
                    recurrenceChanged('weeks')
                    break;
                }
                case 'MONTHLY' : {
                    recurrenceChanged('months')
                    break;
                }
            }
        }

        function getTelehealthMeetingUploadProj() {
            var telehealthMeetingUploadProj = {
                facilityId: CoreService.getCurrentFacility().id,
                telehealthMeetingRecurrence: vm.telehealthMeeting.telehealthMeetingRecurrence,
                telehealthMeetings: []
            }

            addTelehealthMeeting(telehealthMeetingUploadProj, vm.telehealthMeeting.startDate);

            switch (vm.telehealthMeeting.telehealthMeetingRecurrence.frequency) {
                case 'DAILY' : {
                    for (var i = 1; i <= getRepeat('days'); i++) {
                        var auxDate = moment(vm.telehealthMeeting.startDate).add(i, 'days').toDate();

                        addTelehealthMeeting(telehealthMeetingUploadProj, auxDate);
                    }
                    break;
                }
                case 'WEEKLY' : {
                    var aux = [1, 7, 6, 5, 4, 3, 2];
                    var currentDay = moment(vm.telehealthMeeting.startDate).day();
                    var nextMonday = moment(vm.telehealthMeeting.startDate).add(aux[currentDay], 'days').toDate();

                    for (var i = 0; i < 7; i++) {
                        if (vm.telehealthMeeting.telehealthMeetingRecurrence.weekDays.charAt(i) === '1') {
                            var pivotDate = new Date(moment(nextMonday).add(i, 'days').toDate());
                            for (var j = 0; j < getRepeat('weeks'); j++) {
                                var auxDate = moment(pivotDate).add(j, 'weeks').toDate();

                                addTelehealthMeeting(telehealthMeetingUploadProj, auxDate);
                            }
                        }
                    }
                    break;
                }
                case 'MONTHLY' : {
                    for (var i = 1; i <= getRepeat('months'); i++) {
                        var auxDate = moment(vm.telehealthMeeting.startDate).add(i, 'months').toDate();

                        addTelehealthMeeting(telehealthMeetingUploadProj, auxDate);
                    }
                    break;
                }
                case 'CUSTOM' : {
                    _.forEach(vm.customRecurrences, function (customRecurrence) {

                        addTelehealthMeeting(telehealthMeetingUploadProj, customRecurrence);
                    })
                    break;
                }
            }

            return telehealthMeetingUploadProj;
        }

        function addTelehealthMeeting(telehealthMeetingUploadProj, date) {

            telehealthMeetingUploadProj.telehealthMeetings.push({
                status: 'SCHEDULED',
                twilioRoomId: null,
                startDate: new Date(date),
                endDate: new Date(moment(date).add(vm.telehealthMeeting.duration, 'minutes')),
                duration: vm.telehealthMeeting.duration,
                topic: vm.telehealthMeeting.topic,
                description: vm.telehealthMeeting.description,
                timeZone: vm.telehealthMeeting.timeZone,
                password: vm.telehealthMeeting.password,
                host: vm.telehealthMeeting.host,
                facility: vm.telehealthMeeting.facility
            })
        }

        function getRepeat(interval) {

            return vm.telehealthMeeting.telehealthMeetingRecurrence.end === 'RECURRENCE' ?
                vm.telehealthMeeting.telehealthMeetingRecurrence.repeat :
                moment(vm.telehealthMeeting.telehealthMeetingRecurrence.endDate).diff(moment(vm.telehealthMeeting.startDate), interval);
        }

        function recurrenceChanged(interval) {
            var aux = new Date(vm.telehealthMeeting.startDate);
            vm.telehealthMeeting.telehealthMeetingRecurrence.endDate = new Date(moment(aux).add(1, interval));
            vm.datePickerOptions.recurrenceEndDate = {minDate: new Date(moment(aux).add(1, interval))};
        }
    }
})();

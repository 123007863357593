(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmCampaignDeleteController',CrmCampaignDeleteController);

    CrmCampaignDeleteController.$inject = ['$uibModalInstance', 'entity', 'CrmCampaign'];

    function CrmCampaignDeleteController($uibModalInstance, entity, CrmCampaign) {
        var vm = this;

        vm.crmCampaign = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CrmCampaign.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

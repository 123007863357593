(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOpportunityNewDialogController', CrmOpportunityNewDialogController);

    CrmOpportunityNewDialogController.$inject = ['$uibModalInstance', 'entity', 'CoreService', 'CountryState', 'GUIUtils', 'TypeMaritalStatus', 'TypeRace',
        'TypeEthnicity', 'Employee', 'TypeLevelCare', 'TypePaymentMethods', 'FacilitySearch', 'CrmOpportunityStatusSearch',
        'CrmCampaign', 'CrmOpportunity', '$scope', 'CrmReferralSourceSearch'];

    function CrmOpportunityNewDialogController($uibModalInstance, entity, CoreService, CountryState, GUIUtils, TypeMaritalStatus, TypeRace,
                                               TypeEthnicity, Employee, TypeLevelCare, TypePaymentMethods, FacilitySearch, CrmOpportunityStatusSearch,
                                               CrmCampaign, CrmOpportunity, $scope, CrmReferralSourceSearch) {
        var vm = this;
        vm.opportunity = entity;

        vm.facility = CoreService.getCurrentFacility();
        vm.states = CountryState.query();
        vm.gender = GUIUtils.getGender();
        vm.maritalStatus = TypeMaritalStatus.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.race = TypeRace.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.ethnicity = TypeEthnicity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.marketer = Employee.findAllByFilter({page: 0, size: 20, facilityId: CoreService.getCurrentFacility().id});
        vm.typeLevelCare = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.paymentMethod = TypePaymentMethods.byFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.facilities = Facility.byCorporation({id: CoreService.getFilterByCurrentCorporation().id});
        vm.status = CrmOpportunityStatusSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.referrals = CrmReferralSourceSearch.query(CoreService.getFilterByCurrentCorporation());

        vm.getCrmCampaign = getCrmCampaign;

        getCrmCampaign();

        function getCrmCampaign(searchQuery) {
            CrmCampaign.filter(getCrmCampaignFilter(searchQuery), function (result) {
                vm.crmCampaigns = result;
            });
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;
        vm.datePickerOpenStatus.admissionDate = false;
        vm.datePickerOpenStatus.contactDate = false;
        vm.admissionDate = {};
        vm.contactDate = {};
        vm.bodOptions = {
            maxDate: new Date(),
            minDate: new Date(1900, 1, 1)
        };

        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.clear = clear;
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel')
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.opportunity.corporation.id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getCrmCampaignFilter(searchQuery) {
            var filter = {};
            filter.corporationId = CoreService.getCorporation().id;
            filter.searchQuery = searchQuery;
            filter.size = 25;
            filter.page = 0;

            return filter
        }

        function save() {
            vm.isSaving = true;
            if (vm.opportunity.id !== null) {
                CrmOpportunity.update(vm.opportunity, onSaveSuccess, onSaveError);
            } else {
                CrmOpportunity.save(vm.opportunity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:crmOpportunityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmOutboundReferral', CrmOutboundReferral);

    CrmOutboundReferral.$inject = ['$resource', 'DateUtils'];

    function CrmOutboundReferral($resource, DateUtils) {
        var resourceUrl = 'api/crm-outbound-referrals/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.doa = DateUtils.convertDateTimeFromServer(data.doa);
                    }

                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findByOpportunity': {
                url: 'api/crm-outbound-referrals/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.doa = DateUtils.convertDateTimeFromServer(data.doa);
                    }

                    return data;
                }
            }
        });
    }
})();

/**
 * Created by Mwong on 08/21/2019.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('udtChartHeader', ChartHeader);

    ChartHeader.$inject = ['Allergies'];

    function ChartHeader(Allergies) {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-chart-header/udt-chart-header.html',
            scope: {
                chart: '=',
                sidebarLayout: '=?',
                showAllergies: '=?',
                other: '='
            },
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, element) {
            $scope.allergies = [];
            $scope.allergiesStr = "";
            $scope.fullName = $scope.chart.fullName ? $scope.chart.fullName :
                $scope.chart.lastName + ', ' + $scope.chart.firstName + ' ' + ($scope.chart.middleName ? $scope.chart.middleName : '');

            $scope.showAllergies = angular.isDefined($scope.showAllergies) ? $scope.showAllergies : false;
            if ($scope.showAllergies) {
                Allergies.findAllByChartAndStatus({id: $scope.chart.id, status: true}, function (allergies) {
                    if (allergies.length > 0) {
                        $scope.allergiesStr =
                            _.join(
                                _.map(allergies, function (allergy) {
                                    return allergy.name;
                                }), ', ');
                    }
                });
            }
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientMedicationTakeComment', PatientMedicationTakeComment);

    PatientMedicationTakeComment.$inject = ['$resource', 'CoreService', 'DateUtils'];

    function PatientMedicationTakeComment($resource, CoreService, DateUtils) {
        var resourceUrl = 'api/patient-medication-take-comments/:id';
        var facility = CoreService.getCurrentFacility();

        return $resource(resourceUrl, {}, {
            'findAllByTake': {
                url: 'api/patient-medication-take-comments/by-take/:id',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        _.forEach(data, function(comment) {
                            comment.createdDate = new Date(DateUtils.convertDateTimeFromServer(comment.createdDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        });
                    }

                    return data;
                }
            },
            'addComment': {url: 'api/patient-medication-take-comments/add-comment', method: 'POST', isArray: true}
        });
    }
})();

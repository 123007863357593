(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgMedicationDialogController', ProgMedicationDialogController);

    ProgMedicationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProgMedication', 'Corporation', 'MedicationSearch'];

    function ProgMedicationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProgMedication, Corporation, MedicationSearch) {
        var vm = this;

        vm.progMedication = entity;
        vm.medications = [];

        vm.clear = clear;
        vm.save = save;
        vm.getMedications = getMedications;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.progMedication.id !== null) {
                ProgMedication.update(vm.progMedication, onSaveSuccess, onSaveError);
            } else {
                ProgMedication.save(vm.progMedication, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:progMedicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getMedications(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);
                vm.medications = MedicationSearch.query({
                    query: query
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionInitController', GroupSessionInitController);

    GroupSessionInitController.$inject = ['$uibModal', 'entity', 'GroupSession', 'SessionType', 'CoreService', 'Employee',
        '$scope', '$state', 'startDate', '$uibModalInstance', 'BUSINESS_ROLES'];

    function GroupSessionInitController($uibModal, entity, GroupSession, SessionType, CoreService, Employee,
                                         $scope, $state, startDate, $uibModalInstance, BUSINESS_ROLES) {
        var vm = this;

        vm.groupSessionTemplate = entity;

        vm.title = 'Sessions Event';

        vm.employees = vm.groupSessionTemplate.employees;
        vm.sessionTypes = SessionType.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.startDate = startDate;
        vm.corporation = CoreService.getCorporation();

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.getReviewers = getReviewers;

        init();

        function init() {
            if (vm.groupSessionTemplate) {
                vm.groupSession = {};
                vm.groupSession.id = null;
                vm.groupSession.title = vm.groupSessionTemplate.title;
                vm.groupSession.start = getJoinDate(vm.startDate, vm.groupSessionTemplate.start);
                vm.groupSession.stop = getJoinDate(vm.startDate, vm.groupSessionTemplate.stop);
                vm.groupSession.billable = vm.groupSessionTemplate.billable;
                vm.groupSession.ageBegin = vm.groupSessionTemplate.ageBegin;
                vm.groupSession.ageEnd = vm.groupSessionTemplate.ageEnd;
                vm.groupSession.sessionType = vm.groupSessionTemplate.sessionType;
                vm.groupSession.employees = vm.groupSessionTemplate.employees;
                vm.groupSession.reviewer = vm.groupSessionTemplate.reviewer;
                vm.groupSession.description = vm.groupSessionTemplate.description;
                vm.groupSession.facility = vm.groupSessionTemplate.facility;
                vm.groupSession.daysWeek = vm.groupSessionTemplate.daysWeek;
                vm.groupSession.startDate = vm.startDate ? vm.startDate : new Date();
                vm.groupSession.status2 = 'STARTED';
                vm.groupSession.enabled = true;
                vm.groupSession.groupSessionTemplate = vm.groupSessionTemplate;
            }

            if (vm.groupSession && vm.groupSession.start) {
                vm.groupSession.start = moment(vm.groupSession.start).format('YYYY-MM-DDTHH:mm:00');
                vm.groupSession.start = moment(vm.groupSession.start).toDate();
            }

            if (vm.groupSession && vm.groupSession.stop) {
                vm.groupSession.stop = moment(vm.groupSession.stop).format('YYYY-MM-DDTHH:mm:00');
                vm.groupSession.stop = moment(vm.groupSession.stop).toDate();
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.groupSession.id == null) {
                GroupSession.save(vm.groupSession, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:groupSession', result);
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
            $state.go('group-session-edit', {id: result.id});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getReviewers(query) {
            if (query && query.length > 3) {
                var filter = {
                    page: 0,
                    size: 10,
                    query: query,
                    facilityId: CoreService.getCurrentFacility().id,
                    businessRole: BUSINESS_ROLES.GROUP_SESSION_REVIWER
                };

                Employee.findAllByFilter(filter, function (result) {
                    vm.reviewers = result;
                });
            }
        }

        function getJoinDate(gsDate, timeDate) {
            return new Date(
                moment(gsDate).format('YYYY-MM-DD') + 'T' +
                moment(timeDate).format('HH:mm:00')
            );
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Protocol', Protocol);

    Protocol.$inject = ['$resource'];

    function Protocol($resource) {
        var resourceUrl = 'api/protocols/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.protocolFrequencies = _.sortBy(data.protocolFrequencies, 'xorder');

                        _.forEach(data.protocolFrequencies, function (frequency) {

                            frequency.schedules = _.sortBy(frequency.schedules, 'xorder');

                            _.forEach(frequency.schedules, function (schedule) {
                                schedule.date = moment(schedule.date).toDate();
                            });

                            frequency.hideDay = false;

                            switch (frequency.frequency.id) {
                                case 'EVERY_H':
                                case 'EVERY_2H':
                                case 'EVERY_3H':
                                case 'EVERY_4H':
                                case 'EVERY_6H':
                                case 'EVERY_8H':
                                case 'EVERY_12H':
                                case 'EVERY_24H':
                                case 'EVERY_48H':
                                case 'EVERY_WEEK':
                                case 'EVERY_TWO_WEEKS':
                                case 'EVERY_MONTH': {
                                    frequency.hideDay = true;

                                    break;
                                }
                            }
                        })
                    }

                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByFilter': {url: 'api/protocols/filter', method: 'GET', isArray: true},
            'clone': {url: 'api/protocols/clone/:id', method: 'GET'},
            'findAll': {
                url: 'api/protocols/find-all', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        _.forEach(data, function (protocol) {
                            protocol.protocolFrequencies = _.sortBy(protocol.protocolFrequencies, 'xorder');

                            _.forEach(protocol.protocolFrequencies, function (frequency) {
                                frequency.schedules = _.sortBy(frequency.schedules, 'xorder');

                                _.forEach(frequency.schedules, function (schedule) {
                                    schedule.date = moment(schedule.date).toDate();
                                })

                                frequency.hideDay = false;

                                switch (frequency.frequency.id) {
                                    case 'EVERY_H':
                                    case 'EVERY_2H':
                                    case 'EVERY_3H':
                                    case 'EVERY_4H':
                                    case 'EVERY_6H':
                                    case 'EVERY_8H':
                                    case 'EVERY_12H':
                                    case 'EVERY_24H':
                                    case 'EVERY_48H':
                                    case 'EVERY_WEEK':
                                    case 'EVERY_TWO_WEEKS':
                                    case 'EVERY_MONTH': {
                                        frequency.hideDay = true;

                                        break;
                                    }
                                }
                            })
                        });
                    }

                    return data;
                }
            }
        });
    }
})();

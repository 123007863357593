(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationDietsHistoryController', EvaluationDietsHistoryController);

    EvaluationDietsHistoryController.$inject = ['$uibModalInstance', 'Diet', 'chart'];

    function EvaluationDietsHistoryController($uibModalInstance, Diet, chart) {
        var vm = this;

        vm.dietsInactive = [];
        vm.dietsInactive = Diet.findAllInactiveDietsByChart({id: chart.id, status: false});

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

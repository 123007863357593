(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssuesController', UrgentIssuesController);

    UrgentIssuesController.$inject = ['$scope', 'UrgentIssues', 'CoreService', 'chart', '$uibModal', 'ConfirmationService'];

    function UrgentIssuesController($scope, UrgentIssues, CoreService, chart, $uibModal, ConfirmationService) {
        var vm = this;

        vm.urgentIssues = [];
        vm.urgentIssue = {};
        vm.dtInstance = {};
        vm.employee = CoreService.getCurrentEmployee();

        vm.check = check;
        vm.add = add;
        vm.details = details;
        vm.del = del;
        vm.edit = edit;

        loadAll();

        function loadAll() {
            UrgentIssues.byChart({id: chart.id}, function (result) {
                vm.urgentIssues = result;
            });
        }

        function check(urgentIssue) {
            UrgentIssues.check({id: urgentIssue.id}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            loadAll();
            $scope.$emit('bluebookApp:urgentIssuesUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/chart/new/update/urgent-issues-dialog.html',
                controller: 'UrgentIssuesDialogControllerNew',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            status: null,
                            id: null,
                            chart: chart,
                            employee: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/chart/new/update/urgent-issues-dialog.html',
                controller: 'UrgentIssuesDialogControllerNew',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: UrgentIssues.get({id: id}).$promise
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function details(id) {
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/chart/new/detail/urgent-issues-detail.html',
                controller: 'UrgentIssuesDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: [function () {
                        return UrgentIssues.get({id: id}).$promise;
                    }]
                }
            });
        }

        function del(id) {
            ConfirmationService.showDelete('BlueAlert').result.then(function () {
                UrgentIssues.delete({id: id}, function () {
                    loadAll();
                });
            })
        }
    }
})();

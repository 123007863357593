(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Bed', Bed);

    Bed.$inject = ['$resource'];

    function Bed ($resource) {
        var resourceUrl =  'api/beds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllByFacility': {url: 'api/beds/facility/:id', method: 'GET', isArray: true},
            'getWithChart': {url: 'api/beds-chart/:id', method: 'GET'},
            'findAllActive': {url: 'api/beds/find-all-active/:id', method: 'GET', isArray: true},
            'byBuilding': {url: 'api/beds/building/:id', method: 'GET', isArray: true},
            'getBedsByIds': {url:'api/beds/ids', method: 'POST', isArray: true},
            'update': {method:'PUT'},
            'findAllActiveIncludeChartBed': {url: 'api/beds/find-all-active/:id/:idBed', method: 'GET', isArray: true},
            'sheetFilter': {'url': 'api/beds/sheet/filter', method: 'GET', isArray: true},
            'changeStatus': {url: 'api/beds/sheet/change-status', method: 'POST', isArray: false},
            'findAllEmptyAndActive': {url: 'api/beds/find-all-empty-and-active/:id' , method: 'GET', isArray: true},
            'assignToChart': {url: 'api/beds/assign-to-chart', method: 'POST', isArray: false},
            'remove': {url: 'api/beds/remove', method: 'POST'},
            'countAllEmptyAndActive': {
                url: 'api/beds/count-all-empty-and-active/:id', method: 'GET', isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countAllOccupiedAndActive': {
                url: 'api/beds/count-all-occupied-and-active/:id', method: 'GET', isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidPhysicianController', CovidPhysicianController);

    CovidPhysicianController.$inject = ['GenericEntityDatatableService', 'CovidPhysician', 'CoreService', '$state'];

    function CovidPhysicianController (GenericEntityDatatableService, CovidPhysician, CoreService, $state) {
        var vm = this;

        vm.totalItems = 0;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Covid Physician',
            newButtonTitle: 'New Covid Physician',
            entityClassHumanized: 'Covid Physician',
            entityStateName: 'covid-physician',
            service: CovidPhysician,
            serviceMethod: 'findAllByFilter',
            newAction: false,
            params: params,
            columns: [
                {
                    name: null,
                    title: 'Physician',
                    render: function (data) {
                       return data.physician.firstName + ' ' + data.physician.lastName;
                    }
                },
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('covid-physician'),
                GenericEntityDatatableService.getDeleteAction('covid-physician')
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Covid Physician',
                            action: function () {
                                $state.go('covid-physician.new');
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0
                            }
                        }),
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VesselController', VesselController);

    VesselController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Vessel', '$uibModal', 'ConfirmationService',
        '$q'];

    function VesselController(GenericEntityDatatableService, CoreService, Vessel, $uibModal, ConfirmationService,
                              $q) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Vessel',
            newButtonTitle: 'New Vessel',
            entityClassHumanized: 'Vessel',
            entityStateName: 'vessel',
            service: Vessel,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Vessel',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/vessel/update/vessel-dialog.html',
                controller: 'VesselDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/vessel/update/vessel-dialog.html',
                controller: 'VesselDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Vessel', function (Vessel) {
                        return Vessel.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Vessel').result.then(function () {
                    Vessel.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

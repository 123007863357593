(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('OutReachCampaignSelectCampaignDialogController', OutReachCampaignSelectCampaignDialogController);

    OutReachCampaignSelectCampaignDialogController.$inject = ['$uibModalInstance', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$filter', '$compile', '$scope', 'CoreService', 'filter', 'DataTablesService', 'Campaign', '$state'];

    function OutReachCampaignSelectCampaignDialogController($uibModalInstance, DTOptionsBuilder, DTColumnBuilder, $q, $filter, $compile, $scope, CoreService, filter, DataTablesService, Campaign, $state) {
        var vm = this;

        vm.dtInstance = {};
        vm.filter = filter;
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.selectCampaign = selectCampaign;
        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.getCampaignId = getCampaignId;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Campaign.byFilter(getFilter(),
                function (result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.campaigns = result;
                    defer.resolve(result);
                });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false).withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notSortable().renderWith(function (data, type, full, meta) {
                return '<input type="radio" name="campaignSelected" ng-click="vm.getCampaignId(' + data.id + ')">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Name').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Gender').notSortable().renderWith(function (data, type, full) {
                return data.gender ? data.gender : '';
            })
        ];

        function getFilter() {
            var filter = {};

            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;
            filter.facilityId = vm.filter.facilityId;

            return filter;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            vm.campaignId = '';

            transition();
        }

        function transition() {
            vm.dtInstance.reloadData();
        }

        function selectCampaign() {
            $uibModalInstance.close(_.find(vm.campaigns, {id: vm.campaignId}));
        }

        function getCampaignId(id) {
            vm.campaignId = id;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillDiagnosisController', SuperBillDiagnosisController);

    SuperBillDiagnosisController.$inject = ['$scope', '$state', 'SuperBillDiagnosis', '$uibModal', 'entity'];

    function SuperBillDiagnosisController($scope, $state, SuperBillDiagnosis, $uibModal, entity) {
        var vm = this;

        vm.superBill = entity;
        vm.superBillDiagnoses = [];

        vm.loadAll = loadAll;
        vm.onDragEnd = onDragEnd;
        vm.add = add;
        vm.edit = edit;

        loadAll();

        function loadAll() {
            SuperBillDiagnosis.findAllBySuperBill({id: vm.superBill.id}, function (result) {
                vm.superBillDiagnoses = result;
            });
        }

        function onDragEnd(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].xorder !== i + 1) {
                    list[i].xorder = i + 1;
                    SuperBillDiagnosis.reorder({id: list[i].id, xorder: list[i].xorder})

                } else {
                    list[i].xorder = i + 1;
                }
            }
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/super-bill-diagnosis/super-bill-diagnoses-dialog.html',
                controller: 'SuperBillDiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            comment: null,
                            id: null,
                            endDate: null,
                            startDate: null,
                            superBill:  vm.superBill
                        }
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/super-bill-diagnosis/super-bill-diagnoses-dialog.html',
                controller: 'SuperBillDiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['SuperBillDiagnosis', function (SuperBillDiagnosis) {
                        return SuperBillDiagnosis.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }
    }
})();

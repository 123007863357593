(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BillingScheduleDialogController', BillingScheduleDialogController);

    BillingScheduleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'BillingSchedule', 'Employee', 'CoreService'];

    function BillingScheduleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
        BillingSchedule, Employee, CoreService) {
        var vm = this;

        vm.billingSchedule = entity;

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.billingSchedule.id !== null) {
                BillingSchedule.update(vm.billingSchedule, onSaveSuccess, onSaveError);
            } else {
                BillingSchedule.save(vm.billingSchedule, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:billingScheduleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, corporationId: CoreService.getCorporation().id};

                Employee.findAllByCorporation(filter, function (result) {
                    vm.employees = result;
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PdfChartReportController', PdfChartReportController);

    PdfChartReportController.$inject = ['PdfChartReport', '$uibModal', '$q', 'GUIUtils', 'CoreService', '$rootScope',
        'GenericEntityDatatableService', 'ConfirmationService', 'toastr'];

    function PdfChartReportController(PdfChartReport, $uibModal, $q, GUIUtils, CoreService, $rootScope,
                                      GenericEntityDatatableService, ConfirmationService, toastr) {

        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Storage',
            newAction: false,
            entityClassHumanized: 'report',
            entityStateName: 'report',
            service: PdfChartReport,
            serviceMethod: 'filter',
            params: params,
            pagination: 'remote',
            withSelectionColumn: true,
            selectionColumn: 'id',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: "Requested By",
                    render: function (data) {
                        return data.employee.firstName + ' ' + data.employee.lastName;
                    }
                },
                {
                    name: null,
                    title: "Requested Date",
                    render: function (data) {
                        return moment(data.dateTimeRequested).format($rootScope.amDateFormat);
                    }
                },
                {
                    name: null,
                    title: 'Completed At',
                    render: function (data) {
                        return data.completedAt ? moment(data.completedAt).format($rootScope.amDateFormat) : "";
                    }
                },
                {
                    name: null,
                    title: 'Status',
                    render: function (data) {
                        return data.pdfChartReportStatus ? getStatusStyle(data.pdfChartReportStatus) : "FAIL";
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDownloadAction(), {
                    action: function (data) {
                        downloadSelectedReportsDialog([data]);
                    },
                    reload: false,
                    ngIf: function (data) {
                        return data.pdfChartReportStatus === "COMPLETED";
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                    name: "New Report",
                    action: function (data) {
                        return newReport(data.id);
                    },
                    ngIf: function (data) {
                        return (data.chartId != null && data.facesheetTplId != null) || data.reportId != null
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getDownloadAction(), {
                            name: "Download Selected",
                            action: function (filter, selected) {
                                downloadSelectedReportsDialog(selected);
                            },
                            reload: false,
                        })
                    ]
                },
            ]
        }

        function downloadSelectedReportsDialog(selected) {
            _.forEach(selected, function (item) {
                $uibModal.open({
                    templateUrl: 'app/entities/pdf-chart-report/download/pdf-chart-report-download-dialog.html',
                    controller: 'PdfChartReportDownloadController',
                    controllerAs: 'vm',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['PdfChartReport', function (PdfChartReport) {
                            return PdfChartReport.get({id: item.id}).$promise;
                        }]
                    }
                });
            })
        }

        function getStatusStyle(status) {

            switch (status) {
                case 'PENDING': {
                    return GUIUtils.getStatusTemplate(status, "primary")
                }
                case 'STARTED': {
                    return GUIUtils.getStatusTemplate(status, "warning")
                }
                case 'FAIL': {
                    return GUIUtils.getStatusTemplate(status, "danger")
                }
                case 'COMPLETED': {
                    return GUIUtils.getStatusTemplate(status, "success")
                }
            }
        }

        function newReport(id) {
            return $q(function (resolve) {
                ConfirmationService.open({
                    title: 'Renew Chart Report',
                    message: 'Are you sure you want to <strong>renew</strong> this Report?',
                    actions: [
                        {
                            name: 'Renew',
                            color: 'btn btn-success',
                            icon: 'fa fa-times-circle-o',
                            value: 'SUCCESS'
                        },
                        {
                            name: 'Cancel',
                            color: 'btn btn-danger',
                            icon: 'fa fa-ban',
                            value: 'CANCEL'
                        }
                    ]
                }).result.then(function () {
                    PdfChartReport.renew({id: id}, function (result) {
                        toastr.success('Your report is been generated please go to storage to download it');
                        resolve();
                    }, function () {
                        toastr.error("We couldn't generate the report.");
                        resolve();
                    });
                });
            });
        }
    }
})();

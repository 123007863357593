(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InitValueController', InitValueController);

    InitValueController.$inject = ['InitValue','$q', 'GUIUtils', '$uibModal', 'GenericEntityDatatableService',
        'ConfirmationService'];

    function InitValueController(InitValue,  $q, GUIUtils, $uibModal, GenericEntityDatatableService,
                                 ConfirmationService) {
        var vm = this;

        vm.type = 'CORPORATION';

        vm.descriptor = {
            title: 'Init Values',
            newButtonTitle: "New Init Value",
            entityClassHumanized: 'Init Values',
            entityStateName: 'init-value',
            service: InitValue,
            serviceMethod: 'findAllByFilter',
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'entity',
                    title: 'Entity'
                },
                {
                    name: 'type',
                    title: 'Type',
                },
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'Corporation',
                            ngClass: function (filter) {
                                return filter.type === 'CORPORATION'?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.type = 'CORPORATION';
                                filter.entity = null;
                            }
                        },
                        {
                            name: 'Facility',
                            ngClass: function (filter) {
                                return filter.type === 'FACILITY' ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.type = 'FACILITY';
                                filter.entity = null;
                            }
                        }
                    ],
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Init Value',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'type',
                    defaultValue: function () {
                        return 'CORPORATION';
                    },
                    visible: false
                },
                {
                    name: 'entity',
                    values: InitValue.loadEntitiesByType({type: 'CORPORATION'}),
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                    getValue: function (entity) {
                        return entity;
                    },
                    placeholder: 'Entity...',
                    type: 'select',
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12',
                },
            ],
            filterCallback: function (filter) {
                vm.entity = filter.entity;
                vm.type = filter.type;
                vm.descriptor.filters[1].values = InitValue.loadEntitiesByType({type: filter.type});
            }
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/init-value/update/init-value-dialog.html',
                controller: 'InitValueDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            entity: vm.entity,
                            type: vm.type,
                            id: null
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/init-value/update/init-value-dialog.html',
                controller: 'InitValueDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['InitValue', function (InitValue) {
                        return InitValue.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('InitValue').result.then(function () {
                    InitValue.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GlobalMessageController', GlobalMessageController);

    GlobalMessageController.$inject = ['$scope', '$state', 'GlobalMessage', 'GenericEntityDatatableService',
        '$uibModal', 'CoreService', 'ConfirmationService', '$q', 'GUIUtils', '$rootScope'];

    function GlobalMessageController($scope, $state, GlobalMessage, GenericEntityDatatableService,
                                     $uibModal, CoreService, ConfirmationService, $q, GUIUtils, $rootScope) {
        var vm = this;

        vm.descriptor = {
            title: 'Global Message',
            newButtonTitle: 'New Global Message',
            entityClassHumanized: 'GlobalMessage',
            entityStateName: 'global-message',
            service: GlobalMessage,
            serviceMethod: 'query',
            params: {
                active: true
            },
            newAction: false,
            columns: [
                {
                    name: "id",
                    title: "Id"
                },
                {
                    name: 'title',
                    title: 'Title',
                },
                {
                    name: 'description',
                    title: 'Description',
                },
                {
                    name: 'comment',
                    title: 'Comment',
                },
                {
                    title: 'Priority',
                    render: function (data) {
                        return "<div class=\'alert alert-" + data.priority.toLowerCase() + "\'></div>";
                    }
                },
                {
                    name: 'startDate',
                    title: 'Start Date',
                    render: function (data) {
                        return data.startDate ? moment(data.startDate).format($rootScope.amDateFormat) : '-';
                    }
                },
                {
                    name: 'endDate',
                    title: 'End Date',
                    render: function (data) {
                        return data.endDate ? moment(data.endDate).format($rootScope.amDateFormat) : '-';
                    }
                },
                {
                    name: null,
                    title: 'Active',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.active);
                    }
                },
                {
                    name: null,
                    title: 'Closeable',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.closeable);
                    }
                },
                {
                    name: 'employee',
                    title: 'Employee',
                    render: function (data) {
                        return data.employee === null ? " " : data.employee.lastName + ", " + data.employee.firstName;
                    }
                },
                {
                    name: 'corporation',
                    title: 'Corporation',
                    render: function (data) {
                        return data.corporation === null ? " " : data.corporation.name;
                    }
                },
                {
                    name: 'createdDate',
                    title: 'Created Date',
                    render: function (data) {
                        return data.createdDate ? moment(data.createdDate).format($rootScope.amDateFormat) : '-';
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction('global-message'), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('global-message'), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Global Message',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'startDate',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.startDate ? moment(filter.startDate).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'endDate',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.endDate ? moment(filter.endDate).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: "active",
                    values: ['ACTIVE', 'INACTIVE'],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                    read: function (filter) {
                        switch (filter.active) {
                            case 'ACTIVE':
                                return true;
                            case 'INACTIVE':
                                return false;
                            default:
                                return null;
                        }
                    },
                    defaultValue: function () {
                        return 'ACTIVE';
                    },
                    placeholder: 'Status...',
                    type: 'select',
                    visible: true,
                },
            ]
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/global-message/update/global-message-dialog.html',
                controller: 'GlobalMessageDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            title: null,
                            description: null,
                            comment: null,
                            startDate: null,
                            endDate: null,
                            attachment: null,
                            priority: null,
                            id: null,
                            active: true,
                            closeable: true
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/global-message/update/global-message-dialog.html',
                controller: 'GlobalMessageDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['GlobalMessage', function (GlobalMessage) {
                        return GlobalMessage.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Global Message').result.then(function () {
                    GlobalMessage.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PRDetEmployeeCommentDialogController', PRDetEmployeeCommentDialogController);

    PRDetEmployeeCommentDialogController.$inject = ['$scope', '$uibModalInstance', 'prDetEmployeeComment', 'PRDetEmployeeComment', 'toastr'];

    function PRDetEmployeeCommentDialogController($scope, $uibModalInstance, prDetEmployeeComment, PRDetEmployeeComment, toastr) {
        var vm = this;

        vm.prDetEmployeeComment = prDetEmployeeComment;
        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            PRDetEmployeeComment.save(vm.prDetEmployeeComment, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            toastr.success('Employee comment successfully created');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'APP',
        'CoreService', 'User', 'toastr', '$cookies', 'Employee', '$sce'];

    function LoginController($rootScope, $state, $timeout, Auth, APP,
                             CoreService, User, toastr, $cookies, Employee, $sce) {
        var vm = this;
        $rootScope.background = 'background: url(content/images/wallpaper.jpg) no-repeat center center / cover;';

        vm.toastr = toastr;
        vm.$cookies = $cookies;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.username = null;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = false;
        vm.requestResetPassword = requestResetPassword;
        vm.alerts = [];
        vm.addAlert = addAlert;
        vm.closeAlert = closeAlert;
        vm.isCapsLockOn = isCapsLockOn;
        vm.popoverHtml = $sce.trustAsHtml('<span class="popover-text">The username can contain any <strong class="success">letter</strong> or <strong class="success">digit</strong> and the specials characters <strong class="warning">.</strong> or <strong class="warning">_</strong>. Must not exceed <strong class="danger">40</strong> characters long.</span>');

        vm.appName = APP.APP_NAME;
        vm.appSuffixName = APP.APP_SUFFIX_NAME;

        function addAlert(message) {
            if (vm.alerts.length > 0) {
                vm.alerts = []
            }
            vm.alerts.push({type: 'danger', msg: message})
        }

        function closeAlert(index) {
            vm.alerts.splice(index, 1)
        }

        function isCapsLockOn() {

        }

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function login(event) {
            vm.isSaving = true;
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                Employee.employeeAccount(function (data) {
                     CoreService.setCurrentEmployee(data);

                    vm.authenticationError = false;

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is succesful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }

                    $state.go('home');
                }, function () {
                    vm.authenticationError = false;
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('login');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is succesful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                    $state.go('home');
                });


            }).catch(function (result) {
                vm.authenticationError = true;

                vm.username = "";
                vm.password = "";

                vm.addAlert(result.data.AuthenticationException);
                angular.element('#username').focus();
                vm.isSaving = false;
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConcurrentReviewFrequencyDialogController', ConcurrentReviewFrequencyDialogController);

    ConcurrentReviewFrequencyDialogController.$inject = ['$timeout', '$scope', 'GUIUtils', '$uibModalInstance', 'entity', 'ConcurrentReviewFrequency', 'ConcurrentReviews'];

    function ConcurrentReviewFrequencyDialogController ($timeout, $scope, GUIUtils, $uibModalInstance, entity, ConcurrentReviewFrequency, ConcurrentReviews) {
        var vm = this;

        vm.concurrentReviewFrequency = entity;
        if (vm.concurrentReviewFrequency.id)
            vm.concurrentReviewFrequency.days2 = GUIUtils.weekCodeStringToDays(vm.concurrentReviewFrequency.days);

        vm.days = GUIUtils.getDays();

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.concurrentReviewFrequency.days = GUIUtils.daysToWeekCodeString(vm.concurrentReviewFrequency.days2);

            if (vm.concurrentReviewFrequency.id !== null) {
                ConcurrentReviewFrequency.update(vm.concurrentReviewFrequency, onSaveSuccess, onSaveError);
            } else {
                ConcurrentReviewFrequency.save(vm.concurrentReviewFrequency, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:concurrentReviewFrequencyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('compendium-report-group', {
                parent: 'entity',
                url: '/compendium-report-group',
                data: {
                    authorities: [],
                    pageTitle: 'Compendium Report Group'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compendium-report-group/list/compendium-report-group.html',
                        controller: 'CompendiumReportGroupController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_LABORATORY, access);
                }]
            });
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PendingPdfChartReport', PendingPdfChartReport);

    PendingPdfChartReport.$inject = ['$resource', 'DateUtils'];

    function PendingPdfChartReport ($resource, DateUtils) {
        var resourceUrl =  'api/pending-pdf-chart-reports/:id';

        return $resource(resourceUrl, {}, {
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AppointmentEncounterController', AppointmentEncounterController);

    AppointmentEncounterController.$inject = [];

    function AppointmentEncounterController() {
        var vm = this;

        vm.sideBySide = false;
        vm.sideForm = false;
        vm.formIdentical = false;
        vm.openStepSelector = false;

        vm.openSideBySide = openSideBySide;
        vm.toggleStepSelector = toggleStepSelector;
        vm.toggleSideBySide = toggleSideBySide;

        function toggleStepSelector() {
            vm.sideBySide =  false;
            if (vm.openStepSelector) {
                vm.openStepSelector = !vm.openStepSelector;
            } else vm.openStepSelector = true;
        }

        function toggleSideBySide() {
            vm.openStepSelector = false;
            if (vm.sideBySide) {
                vm.sideBySide = !vm.sideBySide;
            } else vm.sideBySide = true;
        }

        function openSideBySide() {
            vm.sideBySide =  false;
            vm.openStepSelector = false;
            vm.sideForm = true;
        }
    }
})();

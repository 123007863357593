(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientMedicationTake', PatientMedicationTake);

    PatientMedicationTake.$inject = ['$resource', 'DateUtils', 'CoreService'];

    function PatientMedicationTake($resource, DateUtils, CoreService) {
        var resourceUrl = 'api/patient-medication-takes/:id';
        var facility = CoreService.getCurrentFacility();

        return $resource(resourceUrl, {}, {
            'changeStatus': {url: 'api/patient-medication-takes/change-status', method: 'POST', isArray: true},
            'signTakeByPatient': {
                url: 'api/patient-medication-takes/sign-take-by-patient',
                method: 'POST',
                isArray: true
            },
            'signTakeByEmployee': {
                url: 'api/patient-medication-takes/sign-take-by-employee',
                method: 'POST',
                isArray: true
            },
            'findAllByPatientMedication': {
                url: 'api/patient-medication-takes/patient-medication/:id',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        _.forEach(data, function (take) {
                            take.scheduleDate = new Date(DateUtils.convertDateTimeFromServer(take.scheduleDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        })
                    }

                    return data;
                }
            },
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('rc-upload', {
            parent: 'entity',
            url: '/rc-upload',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'RcUploads'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/rc-upload/rc-uploads.html',
                    controller: 'RcUploadController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('rc-upload.new', {
            parent: 'rc-upload',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/rc-upload/rc-upload-dialog.html',
                    controller: 'RcUploadDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles'
                }).result.then(function() {
                    $state.go('rc-upload', null, { reload: 'rc-upload' });
                }, function() {
                    $state.go('rc-upload');
                });
            }]
        });
    }

})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportNoteTemplateEditController', IncidentReportNoteTemplateEditController);

    IncidentReportNoteTemplateEditController.$inject = ['entity', '$rootScope', 'OdsFormService',
        'IncidentReportNoteTemplate', '$state', 'IncidentType', 'CoreService'];

    function IncidentReportNoteTemplateEditController(entity, $rootScope, OdsFormService,
                                                      IncidentReportNoteTemplate, $state, IncidentType, CoreService) {
        var vm = this;

        vm.incidentReportTemplate = entity;
        vm.title = 'Incident Report Note Template';
        vm.form = {};

        //init schema if null
        vm.incidentReportTemplate.jsonTemplate = !vm.incidentReportTemplate.jsonTemplate ?
            OdsFormService.newSchema() : vm.incidentReportTemplate.jsonTemplate;

        //Show debug var if it's running in development profile.
        vm.debugMode = !$rootScope.inProduction ? true : false;

        vm.incidentTypes = IncidentType.findAllByCorporation(CoreService.getFilterByCurrentCorporation());

        vm.save = save;
        vm.updateTitle = updateTitle;

        function save() {
            if (vm.incidentReportTemplate.id)
                IncidentReportNoteTemplate.update(vm.incidentReportTemplate, onSaveSuccess, onSaveError);
            else
                IncidentReportNoteTemplate.save(vm.incidentReportTemplate, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $state.go('incident-report-note-template', {}, {reload: true}); //second parameter is for $stateParams
        }

        function onSaveError(error) {
        }

        function updateTitle() {
            vm.incidentReportTemplate.jsonTemplate.label = angular.copy(vm.incidentReportTemplate.name);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProtocolNewDialogController', ProtocolNewDialogController);

    ProtocolNewDialogController.$inject = ['$uibModalInstance', 'protocol', 'MedicationSearch', 'Route', 'PatientMedication',
        'CoreService', 'Relational', 'DoseForm', 'Protocol', '$scope', 'ProtocolGroup',
        'RATIONALE_TYPE'];

    function ProtocolNewDialogController($uibModalInstance, protocol, MedicationSearch, Route, PatientMedication,
                                         CoreService, Relational, DoseForm, Protocol, $scope, ProtocolGroup,
                                         RATIONALE_TYPE) {
        var vm = this;

        vm.protocol = protocol;
        vm.relationals = Relational.findAllByFilter(getFilterByCorporationAndType());
        vm.doseForms = DoseForm.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.routes = Route.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.frequencies = PatientMedication.getAllMedicationFrequency();
        vm.protocolGroup = ProtocolGroup.findAllByCorporation(CoreService.getFilterByCurrentCorporation());

        vm.clear = clear;
        vm.getMedications = getMedications;
        vm.save = save;
        vm.addDay = addDay;
        vm.changeCategory = changeCategory;
        vm.changeFrequency = changeFrequency;
        vm.deleteDay = deleteDay;

        init();

        function init() {
            _.sortBy(vm.protocol.protocolFrequencies, 'xorder');
        }

        function save() {
            vm.isSaving = true;

            var data = angular.copy(vm.protocol);

            _.forEach(data.protocolFrequencies, function (frequency) {
                _.forEach(frequency.schedules, function (schedule) {
                    schedule.date = moment(schedule.date).format('YYYY-MM-DDTHH:mm:00');
                });
            });

            if (vm.protocol.id !== null) {
                Protocol.update(data, onSaveSuccess, onSaveError);
            } else {
                Protocol.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:protocolUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getMedications(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);
                vm.medications = MedicationSearch.query({
                    query: query
                });
            }
        }

        function addDay() {
            vm.protocol.protocolFrequencies.push({
                frequency: null,
                xorder: vm.protocol.protocolFrequencies.length,
                isCollapsed: false
            });

            vm.protocol.durationDays = vm.protocol.protocolFrequencies.length;
        }

        function changeCategory() {
            vm.protocol.protocolFrequencies = [];

            switch (vm.protocol.type) {
                case 'AS_NEEDED':
                case 'CONSISTENT_DOSE': {
                    addDay();

                    break;
                }
                case 'TAPER': {
                    addDay();
                    vm.protocol.durationDays = vm.protocol.protocolFrequencies.length;

                    break;
                }
            }
        }

        function changeFrequency(frequency) {
            frequency.schedules = [];
            frequency.hideDay = false;

            switch (frequency.frequency.id) {
                case 'EVERY_H':
                case 'EVERY_2H':
                case 'EVERY_3H':
                case 'EVERY_4H':
                case 'EVERY_6H':
                case 'EVERY_8H':
                case 'EVERY_12H':
                case 'EVERY_24H':
                case 'EVERY_48H':
                case 'EVERY_WEEK':
                case 'EVERY_TWO_WEEKS':
                case 'EVERY_MONTH': {
                    frequency.hideDay = true;
                    scheduleTakesIntervals(frequency, 1, 0, 1);

                    break;
                }
                case 'ONE_AM': {
                    scheduleTakesIntervals(frequency, 1, 0, 9);

                    break;
                }
                case 'ONE_HS': {
                    scheduleTakesIntervals(frequency, 1, 0, 21);

                    break;
                }
                case 'BID': {
                    scheduleTakesIntervals(frequency, 2, 12, 9);

                    break;
                }
                case 'TID': {
                    scheduleTakesIntervals(frequency, 3, 6, 9);

                    break;
                }
                case 'QID': {
                    scheduleTakesIntervals(frequency, 4, 3, 9);

                    break;
                }
                case 'SID': {
                    scheduleTakesIntervals(frequency, 6, 4, 2);

                    break;
                }
                case 'ONCE': {
                    scheduleTakesIntervals(frequency, 1, 0, 15);

                    break;
                }
                case 'STAT': {
                    frequency.schedules.push({
                        xorder: 0,
                        strength: null,
                        date: moment().startOf('minute').toDate()
                    });

                    break;
                }
            }
        }

        function scheduleTakesIntervals(frequency, count, hours, firstHour) {
            var first = moment().startOf('hour').toDate();
            first.setHours(firstHour);

            for (var i = 0; i < count; i++) {
                frequency.schedules.push({
                    xorder: i,
                    date: angular.copy(first),
                    strength: null
                });

                first.setHours(first.getHours() + hours);
            }
        }

        function deleteDay(index) {
            vm.protocol.protocolFrequencies.splice(index, 1);

            _.forEach(vm.protocol.protocolFrequencies, function (frequency, index) {
                frequency.xorder = index;
            })
        }

        function getFilterByCorporationAndType() {
            var filter = {};

            filter.corporationId = CoreService.getCorporation().id;
            filter.relationalType = RATIONALE_TYPE.MEDICATION;

            return filter
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientOrder', PatientOrder);

    PatientOrder.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function PatientOrder($resource, DateUtils, DataUtils) {
        var resourceUrl = 'api/patient-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByBarcode': {
                method: 'GET',
                url: 'api/patient-orders/by-barcode/:barcode'
            },
            'update': {method: 'PUT'},
            'create': {url: "api/patient-orders/create", method: 'POST', isArray: true},
            'findAllByChart': {'url': "api/patient-orders/by-chart", method: 'GET', isArray: true},
            'getOrderAsNeeded': {
                url: 'api/patient-orders/patient-order-as-needed/by-facility', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);
                    return angular.toJson(copy);
                }
            },
            'pdfBarcodeLabels': {
                url: 'api/patient-orders/pdf-barcode-labels', method: 'POST', responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'getBarcodeLabels': {url: 'api/patient-orders/get-barcode-labels', method: 'POST', isArray: true},
            'getOrderByFacilityIdDateChartIdBarcodePhysician': {
                url: 'api/patient-orders/by-facility-date-chart-physician', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'createRequisitionWithOrderAsNeeded': {
                url: 'api/patient-orders/create-requisition-as-needed/by-order-date', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);
                    return angular.toJson(copy);
                }
            },
            'filter': {'url': 'api/patient-orders/filter', method: 'GET', isArray: true},
            'changeDrawDay': {url: 'api/patient-orders/change-draw-day', method: 'POST', isArray: true},
            'getPDFManifest': {
                url: 'api/patient-orders/get-manifest-pdf', method: 'POST', responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'savePendingSignature': {url: "api/patient-orders/save-sign", method: 'POST'},
            'saveDiscontinuePatientOrder': {url: "api/patient-orders/save-discontinue-sign", method: 'POST'},
            'markDates': {
                url: "api/patient-orders/mark-dates", method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.date) {
                        copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    }
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i] = {date: data[i].date, class: data[i].sent ? 'markSentClass' : 'markNotSentClass'};
                        }
                    }
                    return data;
                }
            },
            'getAllOrderFrequency': {'url': "api/patient-orders/order-frequency", method: 'GET', isArray: true},
            'getPDFLabRequisition': {
                url: 'api/patient-orders/get-lab-requisition-pdf/:barcode', method: 'GET', responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'findAllPhysicianReview': {url: 'api/patient-orders/physician-review', method: 'GET', isArray: true},
            'sign': {url: 'api/patient-orders/sign', method: 'POST'},
            'patientOrderChartCreate': {
                url: "api/patient-orders/chart/create", method: 'POST',
                isArray: true
            },
            'printAndManifest': {url: 'api/patient-orders/print-and-manifest', method: 'POST', isArray: true}
        });
    }
})();

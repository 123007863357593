(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VitalsDialogController', VitalsDialogController);

    VitalsDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Vitals', 'CoreService'];

    function VitalsDialogController ($timeout, $scope,  $uibModalInstance, entity, Vitals, CoreService) {
        var vm = this;
        vm.vitals = entity;
        vm.facility = CoreService.getCurrentFacility();

        vm.datePickerOpenStatus = {};
        vm.form = {};

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            var date = moment.tz(moment(vm.vitals.date).format('YYYY-MM-DDTHH:mm:00'), vm.facility.timeZone).format();
            var vitals = angular.copy(vm.vitals);
            vitals.date = date;

            if (vm.vitals.id !== null) {
                Vitals.update(vitals, onSaveSuccess, onSaveError);
            } else {
                Vitals.save(vitals, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:vitalsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.time = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

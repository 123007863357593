(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GlobalMessageDialogController', GlobalMessageDialogController);

    GlobalMessageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'GlobalMessage', 'Employee', 'Corporation'];

    function GlobalMessageDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                           GlobalMessage, Employee, Corporation) {
        var vm = this;

        vm.globalMessage = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.corporations = Corporation.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.globalMessage.id !== null) {
                GlobalMessage.update(vm.globalMessage, onSaveSuccess, onSaveError);
            } else {
                GlobalMessage.save(vm.globalMessage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:globalMessageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

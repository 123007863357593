(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidLabCompendiumController', CovidLabCompendiumController);

    CovidLabCompendiumController.$inject = ['GenericEntityDatatableService', 'CovidLabCompendium', 'CoreService'];

    function CovidLabCompendiumController (GenericEntityDatatableService, CovidLabCompendium, CoreService) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Covid Lab Compendium',
            newButtonTitle: 'New Covid Lab Compendium',
            entityClassHumanized: 'Covid Lab Compendium',
            entityStateName: 'covid-lab-compendium',
            service: CovidLabCompendium,
            serviceMethod: 'findAllByFacility',
            params: params,
            columns: [
                {
                    name: null,
                    title: 'Code',
                    render: function (data) {
                        return data.labCompendium.code;
                    }
                },
                {
                    name: null,
                    title: 'Description',
                    render: function (data) {
                        return data.labCompendium.description;
                    }
                },
                {
                    name: null,
                    title: 'Laboratory Name',
                    render: function (data) {
                        return data.labCompendium.laboratory.name;
                    }
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('covid-lab-compendium'),
                GenericEntityDatatableService.getDeleteAction('covid-lab-compendium')
            ],
        }

    }
})();


(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConsentBoostGenerateConsentDialogController', ConsentBoostGenerateConsentDialogController);

    ConsentBoostGenerateConsentDialogController.$inject = ['$uibModalInstance', 'chart', 'Evaluation', 'DataTablesService',
        'DTOptionsBuilder', '$q', 'Contacts', '$filter', '$compile', 'DTColumnBuilder', '$scope', 'process'];

    function ConsentBoostGenerateConsentDialogController($uibModalInstance, chart, Evaluation, DataTablesService,
                                                     DTOptionsBuilder, $q, Contacts, $filter, $compile, DTColumnBuilder,
                                                     $scope, process) {
        var vm = this;

        vm.chart = chart;
        vm.dtInstance = {};

        vm.selected = {};
        vm.selectAll = false;

        vm.clear = clear;
        vm.assign = assign;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.search = search;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Contacts.filter({chartId: chart.id, page: 0, size: 1000, searchQuery: vm.searchQuery}, function (result) {
                defer.resolve(result);
            });

            return defer.promise;
        }).withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('aaSorting', [[1, 'asc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn('fullName').withTitle('Contact Name'),
        ];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function assign() {
            Evaluation.assignConsentToContacts({
                ids: vm.getSelectedIds(vm.selected),
                id: process.id,
                chart: vm.chart
            }, function () {
                $uibModalInstance.close('close');
            })
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProcedureDialogController', ProcedureDialogController);

    ProcedureDialogController.$inject = ['$scope','$uibModalInstance', 'CoreService', 'Employee', 'entity', 'DateUtils',
        'Facility', 'CptSearch', 'dialogs', 'Procedure', 'ProcedureDiagnosis', 'ProcedureModifier', 'Diagnoses',
        'Icd10Search', 'ModifierSearch', 'Chart', 'ProClaimConf', 'SuperBill', 'RevCodeSearch', 'superBill'];

    function ProcedureDialogController($scope, $uibModalInstance, CoreService, Employee, entity, DateUtils,
                                       Facility, CptSearch, $dialogs, Procedure, ProcedureDiagnosis, ProcedureModifier,
                                       Diagnoses, Icd10Search, ModifierSearch, Chart, ProClaimConf, SuperBill, RevCodeSearch, superBill) {
        var vm = this;

        vm.procedure = entity;
        vm.superBill = superBill;
        vm.chart = null;
        vm.employees = Employee.byActive({id: CoreService.getCorporation().id});
        vm.icd10Selected = [];
        vm.icd10SearchQuery = '';
        vm.modifierSearchQuery = '';
        vm.modifierSelected = [];
        vm.lastsIcd10ByFacility = [];
        vm.lastsModifiersByFacility = [];
        vm.diagnoses = [];
        vm.modifiers = [];
        vm.showDeleteModifiers = false;
        vm.indexModifier = null;
        vm.indexDiagnose = null;
        vm.revCodes = [];

        vm.getCpts = getCpts;
        vm.selectCpt = selectCpt;
        vm.removeProcedureModifier = removeProcedureModifier;
        vm.removeProcedureDiagnoses = removeProcedureDiagnoses;
        vm.getCpts = getCpts;
        vm.renderIcd10 = renderIcd10;
        vm.getIcd10Options = getIcd10Options;
        vm.getIcd10FrequentlyOptions = getIcd10FrequentlyOptions;
        vm.onSelectModifier = onSelectModifier;
        vm.onSelectIcd10 = onSelectIcd10;
        vm.renderModifier = renderModifier;
        vm.getModifierOptions = getModifierOptions;
        vm.getModifierFrequentlyOptions = getModifierFrequentlyOptions;
        vm.onDragEnd = onDragEnd;
        vm.confirmDeleteModifier = confirmDeleteModifier;
        vm.confirmDeleteDiagnose = confirmDeleteDiagnose;
        vm.getRevCode = getRevCode;
        vm.selectRevCode = selectRevCode;
        vm.removeCpt = removeCpt;

        vm.save = save;
        vm.clear = clear;

        init();

        function init() {
            Chart.get({id: vm.superBill.chartId}, function (result) {
                vm.chart = result;
            });

            if(vm.procedure.id) {
                if (vm.superBill.billingType !== 'PROFESSIONAL') {
                    vm.revCode = vm.procedure.revCode;
                } else {
                    ProcedureDiagnosis.findAllByProcedure({id: vm.procedure.id}, function (procedureDiagnosis) {
                        vm.diagnoses = procedureDiagnosis;
                    });
                }
                ProcedureModifier.findAllByProcedure({id: vm.procedure.id}, function (procedureModifier) {
                    vm.modifiers = procedureModifier;
                });
            }
        }

        vm.icd10TabsConf = [
            {
                name: 'Search Results',
                icon: 'fa fa-list',
                func: getIcd10Options,
                options: []
            },
            {
                name: 'Frequently added',
                icon: 'fa fa-clock-o',
                func: getIcd10FrequentlyOptions,
                options: []
            }
        ];

        vm.modifierTabsConf = [
            {
                name: 'Search Results',
                icon: 'fa fa-list',
                func: getModifierOptions,
                options: []
            },
            {
                name: 'Frequently added',
                icon: 'fa fa-clock-o',
                func: getModifierFrequentlyOptions,
                options: []
            }
        ];

        function getCpts(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.cpts = CptSearch.query({query: query});
            }
        }

        function selectCpt(cpt) {
            vm.procedure.cpt = cpt;
        }

        function getRevCode(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.revCodes = RevCodeSearch.query({query: query});
            }
        }

        function selectRevCode(revCode) {
                vm.procedure.revCode = revCode;
        }

        function removeProcedureModifier(indexModifier) {
            vm.showDeleteModifiers = true;
            vm.indexModifier = indexModifier;
        }

        function confirmDeleteModifier(){
            if(vm.indexModifier!=null) {
                _.pullAt(vm.modifiers, vm.indexModifier);
                vm.showDeleteModifiers = false;
            }
        }

        function removeProcedureDiagnoses(indexDiagnoses) {
            vm.showDeleteDiagnose = true;
            vm.indexDiagnose = indexDiagnoses;
        }

        function confirmDeleteDiagnose(){
            if(vm.indexDiagnose!=null) {
                _.pullAt(vm.diagnoses, vm.indexDiagnose);
                vm.showDeleteDiagnose = false;
            }
        }

        function onSelectIcd10() {
            _.forEach(vm.icd10Selected, function (icd10) {
                if (!_.find(vm.diagnoses, function (o) {
                    return o.icd10.id === icd10.id;
                })) {
                    vm.diagnoses.push({
                        id: null,
                        icd10: icd10,
                        xorder: vm.diagnoses.length + 1
                    })
                }
            });
        }


        function onSelectModifier() {
            _.forEach(vm.modifierSelected, function (modifier) {
                if (!_.find(vm.modifiers, function (o) {
                    return o.modifier.id === modifier.id;
                })) {
                    vm.modifiers.push({
                        id: null,
                        modifier: modifier,
                        xorder: vm.modifiers.length + 1
                    })
                }
            });
        }

        function renderIcd10(element) {
            if (element) {
                return '<strong>' + element['code'] + '</strong> -' + element['description'];
            }
        }

        function getIcd10Options(searchQuery, tab) {
            if (searchQuery && searchQuery.length > 2 && searchQuery !== vm.icd10SearchQuery) {
                Icd10Search.query({query: searchQuery}, function (result) {
                    tab.options = result;
                    vm.icd10SearchQuery = searchQuery;
                });
            }
        }

        function getIcd10FrequentlyOptions(searchQuery, tab) {
            if (!vm.lastsIcd10ByFacility.length > 0) {
                Diagnoses.lastsIcd10UsedByFacility({facilityId: vm.chart.facilityId}, function (icd10s) {
                    vm.lastsIcd10ByFacility = icd10s;
                    tab.options = icd10s;
                });
            }
        }

        function renderModifier(element) {
            if (element) {
                return '<strong>' + element['code'] + '</strong> -' + element['shortDesc'];
            }
        }

        function getModifierOptions(searchQuery, tab) {
            if (searchQuery && searchQuery.length > 0 && searchQuery !== vm.modifierSearchQuery) {
                searchQuery = _.lowerCase(searchQuery);
                ModifierSearch.query({query: searchQuery}, function (result) {
                    tab.options = result;
                    vm.modifierSearchQuery = searchQuery;
                });
            }
        }

        function getModifierFrequentlyOptions(searchQuery, tab) {
            if (!vm.lastsModifiersByFacility.length > 0) {
                ProClaimConf.lastsModifiersUsedByFacility({facilityId: vm.chart.facilityId}, function (modifiers) {
                    vm.lastsModifiersByFacility = modifiers;
                    tab.options = modifiers;
                });
            }
        }
        function removeCpt() {
            vm.procedure.cpt = null;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onDragEnd(list) {
            for (var i = 0; i < list.length; i++) {
                list[i].xorder = i + 1;
            }
        }

        function save () {
            vm.isSaving = true;

            if (!vm.procedure.id) {
                vm.procedure.superBill = vm.superBill;
            }

            if (vm.superBill.billingType !== 'PROFESSIONAL') {
                vm.procedure.unit = 'UNITS';
            }

            var procedure = {
                'procedure': vm.procedure,
                'diagnoses': vm.diagnoses,
                'modifiers': vm.modifiers
            };
            Procedure.updateProcedure(procedure, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:procedureUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

     }
})();

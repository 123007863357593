/**
 * Created by jesusrodriguez on 2/24/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MarsMorsBoxController', MarsMorsBoxController);

    MarsMorsBoxController.$inject = ['ParseLinks', 'PatientMedication', 'CoreService', 'TypeLevelCare', '$uibModal', 'filter', 'Medication', 'facility',
        'PatientMedicationTake', 'toastr'];

    function MarsMorsBoxController(ParseLinks, PatientMedication, CoreService, TypeLevelCare, $uibModal, filter, Medication, facility,
                                   PatientMedicationTake, toastr) {
        var vm = this;

        vm.title = "MARs";
        vm.entityStateName = 'MARs';
        vm.dtInstance = {};
        vm.selected = {};
        vm.searchQuery = "";
        vm.filter = filter;
        vm.takes = [];
        vm.itemsPerPage = 10;
        vm.page = 1;
        vm.facility = facility;
        vm.date = new Date();
        vm.totalItems = 0;

        vm.datePickerOpenStatus = {dob: false};
        vm.physicians = [];
        vm.filterStatus = "CURRENT";
        vm.status = {
            SCHEDULED: 'SCHEDULED',
            SYSTEM_MISS: 'SYSTEM_MISS',
            HOLD: 'HOLD',
            ADMINISTERED: 'ADMINISTERED',
            OBSERVED: 'OBSERVED',
            MISSED: 'MISSED'
        };

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.openAsNeededModal = openAsNeededModal;
        vm.administer = administer;
        vm.observe = observe;
        vm.miss = miss;
        vm.openMissModel = openMissModel;
        vm.hold = hold;

        loadAll();

        function loadAll() {
            var filter = angular.copy(vm.filter);
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            var now = new Date();
            now.setDate(now.getDate() -1);
            filter.startDate = vm.date;
            filter.endDate = vm.date;
            filter.filterStatus= vm.filterStatus;
            filter.pending= true;

            PatientMedication.marsByFacility(filter, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.takes = result;

            });

        }

        function transition() {
            loadAll();
        }

        function openAsNeededModal() {
            $uibModal.open({
                templateUrl: 'app/entities/patient-medication/patient-medication-mars-as-needed-dialog.html',
                controller: 'PatientMedicationMarsAsNeededDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    filter: {
                        facilityId: CoreService.getCurrentFacility().id,
                        page: 1,
                        size: 10
                    }
                }
            }).result.then(function () {
                //apply
            }, function (result) {
                //cancel
            });
        }

        function administer(id) {
            PatientMedicationTake.changeStatus({status: vm.status.ADMINISTERED, take: {id: id}}, function (result) {
                if (result.medicationTakeStatus == vm.status.ADMINISTERED) {
                    loadAll();
                    toastr.success('The medications has been administered');
                } else {toastr.error('Some medications not has been administered');}
            });
        }

        function observe(id) {
            PatientMedicationTake.changeStatus({status: vm.status.OBSERVED, take: {id: id}}, function (result) {
                if (result.medicationTakeStatus == vm.status.OBSERVED) {
                    loadAll();
                    toastr.success('The medications has been observed');
                }else {toastr.error('Some medications not has been observed');}
            });
        }

        function miss(id, chartId) {
            vm.openMissModel(id, chartId);
        }

        function openMissModel(id, chartId) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-medication/patient-medication-mars-miss-wizard-dialog.html',
                controller: 'PatientMedicationMarsMissWizardDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    status: vm.status,
                    take: ['PatientMedicationTake', function (PatientMedicationTake) {
                        return PatientMedicationTake.get({id: id}).$promise;
                    }],
                    entity: function () {
                        return {
                            name: null,
                            id: null,
                            patientMedicationTake: {id: id}
                        };
                    },
                    chart: ['Chart', function (Chart) {
                        return Chart.get({id: chartId}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function (result) {
                //cancel
            });
        }

        function hold(id) {
            PatientMedicationTake.changeStatus({status: vm.status.HOLD, take: {id: id}}, function (result) {
                if (result.medicationTakeStatus == vm.status.HOLD) {
                    loadAll();
                }
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientOrderDiscontinueController', PatientOrderDiscontinueController);

    PatientOrderDiscontinueController.$inject = ['$uibModalInstance', 'patientOrder', 'PatientOrder', 'CoreService',
        'Employee', 'Via', 'BUSINESS_ROLES', 'itHasCredentials'];

    function PatientOrderDiscontinueController($uibModalInstance, patientOrder, PatientOrder, CoreService,
                                               Employee, Via, BUSINESS_ROLES, itHasCredentials) {
        var vm = this;

        vm.pin = "";
        vm.agree = false;
        vm.signature = "";
        vm.via = Via.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.itHasCredentials = itHasCredentials.value;
        vm.patientOrder = patientOrder;
        vm.currentEmployee = CoreService.getCurrentEmployee();
        vm.patientOrder.discontinueBy = vm.currentEmployee;
        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.save = save;
        vm.clear = clear;
        vm.getEmployees = getEmployees;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        credentialsPermits();

        function credentialsPermits() {
            if (vm.itHasCredentials) {
                vm.employees = [vm.currentEmployee];
                vm.patientOrder.discontinueByPhysician = vm.currentEmployee;
            } else {
                vm.getEmployees();
            }
        }

        function save() {
            vm.isSaving = true;

            if (vm.signature != "" || vm.pin != "") {
                vm.patientOrder.discontinueSignature = vm.signature ? {'signature': vm.signature} : null;
                vm.patientOrder.discontinueSignatureDate = new Date();
            }

            var req = {
                patientOrder: vm.patientOrder,
                pin: vm.pin,
                signature: vm.signature
            }

            PatientOrder.saveDiscontinuePatientOrder(req, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (vm.itHasCredentials) {
                return;
            }

            var filter = {
                page: 0,
                size: 20,
                query: query,
                facilityId: CoreService.getCurrentFacility().id,
                businessRole: BUSINESS_ROLES.PHYSICIAN
            };

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

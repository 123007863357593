(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmTask', CrmTask);

    CrmTask.$inject = ['$resource', 'DateUtils'];

    function CrmTask ($resource, DateUtils) {
        var resourceUrl =  'api/crm-tasks/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dueDate = DateUtils.convertDateTimeFromServer(data.dueDate);
                        data.reminder = DateUtils.convertDateTimeFromServer(data.reminder);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByFilter': {url: 'api/crm-tasks/filter', method: 'GET', isArray: true},
            'pin': {url: 'api/crm-tasks/pin', method: 'POST'},
            'completed': {url: 'api/crm-tasks/completed', method: 'POST'}
        });
    }
})();

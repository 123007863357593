(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SelectLaboratoryDialogController', SelectLaboratoryDialogController);

    SelectLaboratoryDialogController.$inject = ['$uibModalInstance', 'DTOptionsBuilder', 'DTColumnBuilder', '$q',
        '$compile', '$scope', 'CoreService', 'Laboratory', 'GUIUtils'];

    function SelectLaboratoryDialogController($uibModalInstance, DTOptionsBuilder, DTColumnBuilder, $q,
                                              $compile, $scope, CoreService, Laboratory, GUIUtils) {
        var vm = this;

        vm.laboratory = null;
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.checkLaboratory = checkLaboratory;
        vm.selectLaboratory = selectLaboratory;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Laboratory.filter({
                page: 0,
                corporationId: CoreService.getCorporation().id
            }, function (laboratories) {
                vm.laboratories = laboratories;

                defer.resolve(vm.laboratories);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false).withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notSortable().renderWith(function (data, type, full, meta) {
                return '<input type="radio" name="laboratory" ng-click="vm.checkLaboratory(' + data.id + ')">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Name').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Active').notSortable().renderWith(function (data, type, full, meta) {
                return GUIUtils.colorHtmlYesNo(data.active);
            }),
        ];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            vm.laboratory = null;

            transition();
        }

        function transition() {
            vm.dtInstance.reloadData();
        }

        function checkLaboratory(id) {
            vm.laboratory = _.find(vm.laboratories, {id: id})
        }

        function selectLaboratory() {
            $uibModalInstance.close(vm.laboratory);
        }
    }
})();

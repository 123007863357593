(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ContactAddressPickupDialogController', ContactAddressPickupDialogController);

    ContactAddressPickupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        '$compile', 'DTColumnBuilder', 'DTOptionsBuilder', '$q', 'ContactAddress', 'CoreService'];

    function ContactAddressPickupDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                                  $compile, DTColumnBuilder, DTOptionsBuilder, $q, ContactAddress, CoreService) {
        var vm = this;

        vm.selected = {};
        vm.selectAll = false;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';
        vm.contactAddresses = [];
        vm.dtInstance = {};
        vm.facility = CoreService.getCurrentFacility();

        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.clear = clear;
        vm.select = select;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                ContactAddress.faxeable({id: vm.facility.id}, function (result) {
                    vm.contactAddresses = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.contactAddresses, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('aaSorting', [[1, 'asc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('phone').withTitle('Phone'),
            DTColumnBuilder.newColumn('fax').withTitle('Fax'),
        ];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }

            vm.selectAll = true;
        }

        function select() {
            var ids = [];

            _.forEach(vm.selected, function (value, key) {
                if (value) {
                    ids.push(parseInt(key));
                }
            })

            $uibModalInstance.close({ids: ids});
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidFacilityDeleteController',CovidFacilityDeleteController);

    CovidFacilityDeleteController.$inject = ['$uibModalInstance', 'entity', 'CovidFacility'];

    function CovidFacilityDeleteController($uibModalInstance, entity, CovidFacility) {
        var vm = this;

        vm.covidFacility = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CovidFacility.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

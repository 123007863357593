(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('OutreachCampaignController', OutreachCampaignController);

    OutreachCampaignController.$inject = ['$scope', '$state', '$q', '$compile', 'Chart', 'CoreService', '$rootScope',
        '$uibModal', 'DataTablesService', 'GenericEntityDatatableService'];

    function OutreachCampaignController($scope, $state, $q, $compile, Chart, CoreService, $rootScope,
                                        $uibModal, DataTablesService, GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Outreach Campaigns',
            entityClassHumanized: 'Outreach Campaigns',
            entityStateName: 'outreach-campaign',
            service: Chart,
            serviceMethod: 'getAllByCampaign',
            params: params,
            pagination: 'remote',
            options: [{key: 'aaSorting', value: [[0, 'desc']]}],
            newAction: false,
            withSelectionColumn: true,
            columns: [
                {
                    name: null,
                    title: 'Patient',
                    render: function (data) {
                        return data.patientLastName + ", " + data.patientFirstName;
                    }
                },
                {
                    name: null,
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dob).format($rootScope.dateFormat);
                    }
                },
                {
                    name: 'mrNo',
                    title: 'MrNo'
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return data.admissionDate ? moment(data.admissionDate).format($rootScope.dateFormat) : '-';
                    }
                },
                {
                    name: null,
                    title: 'Phone Number',
                    render: function (data) {
                        return data.phone ? data.phone : '-';
                    }
                },
                {
                    name: null,
                    title: 'Insurance Carrier',
                    render: function (data) {
                        return data.insuranceCarrier ? data.insuranceCarrier : '-';
                    }
                },
                {
                    name: null,
                    title: 'Policy Number',
                    render: function (data) {
                        return data.phone ? data.phone : '-';
                    }
                },
                {
                    name: null,
                    title: 'Outreach',
                    render: function (data) {
                        return (data.marketerLastName && data.marketerFirstName) ? data.marketerLastName + ", " + data.marketerFirstName : '-';
                    }
                },
                {
                    name: null,
                    title: 'Discharge Type',
                    render: function (data) {
                        return data.dischargeType ? data.dischargeType : '-';
                    }
                },
                {
                    name: null,
                    title: 'Payment Method',
                    render: function (data) {
                        return data.typePaymentMethods ? data.typePaymentMethods : '-';
                    }
                },
                {
                    name: null,
                    title: 'Discharge To',
                    render: function (data) {
                        return data.dischargeTo ? data.dischargeTo : '-';
                    }
                },
                {
                    name: null,
                    title: 'Referrer',
                    render: function (data) {
                        return data.referrer ? data.referrer : '-';
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(),
                    {
                        action: function (data) {
                            $state.go('chart', {id: data.id});
                        },
                        reload: false
                    }
                ),
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Campaign',
                            action: function (filter) {
                                return selectCampaign(filter)
                            }
                        })
                    ]
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getSendAction(), {
                            name: 'Send Campaign',
                            disabledOnEmpty: true,
                            action: function (filter, selected) {
                                return openDialogSendMessage(selected, false)
                            }
                        })
                    ]
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getSendAction(), {
                            name: 'Send Campaign To All ',
                            action: function (filter, selected) {
                                return sendCampaignToAll(selected)
                            },
                            disabled: function (filter) {
                                return filter.campaignId == null;
                            }
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'campaignId',
                    visible: false
                }
            ],
            filterCallback: function (filter) {
                vm.filter = filter;
            }
        }

        vm.transition = transition;

        function transition() {
            if (vm.dtInstance.reloadData) {
                CoreService.setStorage('filterCampaignOutreach', vm.filter);
                vm.dtInstance.reloadData();
            }
        }

        function selectCampaign(filter) {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/campaign/outreach-campaign/outreach-campaign-select-campaign-dialog.html',
                    controller: 'OutReachCampaignSelectCampaignDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        filter: function () {
                            return {
                                facilityId: CoreService.getCurrentFacility().id,
                            };
                        }
                    }
                }).result.then(function (result) {
                    filter.campaignId = result.id;

                    vm.descriptor.title = "Outreach Campaigns - " + result.name;

                    resolve();
                });
            })
        }

        function sendCampaignToAll() {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/campaign/outreach-campaign/message-all-check-dialog.html',
                    controller: 'MessageAllCheckController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        totalCharts: function () {
                            return vm.filter.totalItems;
                        }
                    }
                }).result.then(function () {
                    openDialogSendMessage([], true).then(function () {
                        resolve();
                    });
                });
            })
        }

        function openDialogSendMessage(selected, all) {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/campaign/outreach-campaign/message-campaign-dialog.html',
                    controller: 'MessageCampaignDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        chartIds: function () {
                            return _.map(selected, function (item) {
                                return item.id;
                            });
                        },
                        campaignId: function () {
                            return vm.filter.campaignId;
                        },
                        all: function () {
                            return all;
                        }
                    }
                }).result.then(function () {
                    resolve();
                }, function () {
                    resolve();
                });
            });
        }
    }
})();

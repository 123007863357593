(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmCallDialogController', CrmCallDialogController);

    CrmCallDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CrmCall'];

    function CrmCallDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, CrmCall) {
        var vm = this;

        vm.crmCall = entity;

        vm.clear = clear;
        vm.save = save;
        vm.setValidationClass = setValidationClass;
        vm.openCalendar = openCalendar;
        vm.editorCreated = editorCreated;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateCall = false;
        vm.DateOptions = {};

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.crmCall.id !== null) {
                CrmCall.update(vm.crmCall, onSaveSuccess, onSaveError);
            } else {
                CrmCall.save(vm.crmCall, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:crmCallUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editorCreated(editor, htmlText) {
            editor.root.innerHTML = htmlText;
        }
    }
})();

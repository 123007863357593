(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FaxNumberController', FaxNumberController);

    FaxNumberController.$inject = ['GenericEntityDatatableService', 'FaxNumber', '$q', '$uibModal',
        'ConfirmationService'];

    function FaxNumberController(GenericEntityDatatableService, FaxNumber, $q, $uibModal,
                                 ConfirmationService) {
        var vm = this;

        vm.descriptor = {
            title: 'FaxNumber',
            entityClassHumanized: 'FaxNumber',
            entityStateName: 'fax-number',
            service: FaxNumber,
            serviceMethod: 'byFilter',
            newAction: false,
            params: {},
            pagination: 'remote',
            columns: [
                {
                    name: 'phoneNumber',
                    title: 'Phone Number',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'Order Fax Number',
                            action: function () {
                                return order();
                            }
                        }),
                    ],
                },
            ],
        }

        function order() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/fax-number/order/fax-number-dialog.html',
                    controller: 'FaxNumberDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {}
                }).result.then(function () {
                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Fax Number').result.then(function () {
                    FaxNumber.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOpportunityPrivatePayDialogController', CrmOpportunityPrivatePayDialogController);

    CrmOpportunityPrivatePayDialogController.$inject = ['$uibModalInstance'];

    function CrmOpportunityPrivatePayDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

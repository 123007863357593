(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOpportunitiesController', CrmOpportunitiesController);

    CrmOpportunitiesController.$inject = ['$uibModal', '$state', 'filter', 'CrmOpportunity', 'CoreService', '$scope'];

    function CrmOpportunitiesController($uibModal, $state, filter, CrmOpportunity, CoreService, $scope) {
        var vm = this;

        vm.title = 'Opportunities';
        vm.layoutToggle = 'LIST';

        vm.marketers = [];
        vm.crmOpportunities = [];
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.filter = filter;
        vm.filterTpl = angular.copy(filter);
        vm.quantity = 5;

        vm.toggleFilters = toggleFilters;
        vm.toggleFloatingMenu = toggleFloatingMenu;
        vm.toggleActive = toggleActive;
        vm.openModalNewOpportunity = openModalNewOpportunity;
        vm.goToDetails = goToDetails;
        vm.search = search;
        vm.clear = clear;
        vm.findAllByFilter = findAllByFilter;
        vm.transition = transition;
        vm.displayCrmOpportunity = displayCrmOpportunity;
        vm.createCrmOpportunity = createCrmOpportunity;
        vm.calculateAge = calculateAge;
        vm.getEmployees = getEmployees;

        vm.employeeList = [
            {name: 'Raimel Santos', pic: 'content/images/avatar.png'},
            {name: 'Maykel Wong', pic: 'content/images/avatar.png'},
            {name: 'Jesus Rodriguez', pic: 'content/images/avatar.png'},
            {name: 'Alexander Modesto', pic: 'content/images/avatar.png'}
        ];

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.corporation.id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function toggleFilters($event) {
            var toggle =angular.element($event.currentTarget);
            toggle.next().slideToggle();
        }

        init();

        function init() {
            CrmOpportunity.crmOpportunityMarketers({
                id: CoreService.getCorporation().id
            }, function (result) {
                vm.marketers = result;
                _.forEach(vm.marketers, function (item) {
                    item.avatar = CoreService.getAvatar(item.gender);
                    item.selected = false;
                });
            });

            findAllByFilter();
        }

        function findAllByFilter() {
            CrmOpportunity.findAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.crmOpportunities = result;
            });
        }

        function search() {
            vm.page = 1;
            vm.findAllByFilter();
        }

        function clear() {
            vm.filter = angular.copy(vm.filterTpl);
            vm.searchQuery = null;
            vm.page = 1;

            vm.findAllByFilter();
        }

        function getFilter() {
            vm.marketersSelected = _.filter(vm.marketers, function (section) {
                return section.selected === true;
            });

            var filter = angular.copy(vm.filter);

            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;
            if (vm.marketersSelected.length > 0) {
                filter.employeesIds = _.map(vm.marketersSelected, function (marketerSelected) {

                    return marketerSelected.id;
                });
            }

            return filter
        }

        // for drag and drop layout
        vm.draglists = {"A": [], "B": [], "C": [], "D": [], "E": [], "F": [], "G": []};
        vm.selected = null;

        // Generate initial model
        for (var i = 1; i <= 3; ++i) {
            vm.draglists.A.push({label: "Item A" + i});
            vm.draglists.B.push({label: "Item B" + i});
            vm.draglists.C.push({label: "Item C" + i});
            vm.draglists.D.push({label: "Item D" + i});
            vm.draglists.E.push({label: "Item E" + i});
            vm.draglists.F.push({label: "Item F" + i});
            vm.draglists.G.push({label: "Item G" + i});
        }

        function transition() {
            vm.findAllByFilter();
        }

        function goToDetails(id) {
            $state.go('crm-opportunity-detail', {id: id});
        }

        function openModalNewOpportunity() {
            vm.currentEmployee = CoreService.getCurrentEmployee();
            $uibModal.open({
                templateUrl: 'app/entities/crm-opportunity/crm-opportunity-new-dialog.html',
                controller: 'CrmOpportunityNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            firstName: null,
                            middleName: null,
                            lastName: null,
                            preferredName: null,
                            dob: null,
                            ssn: null,
                            sex: null,
                            admissionDate: null,
                            contactName: null,
                            contactRelationship: null,
                            contactDate: null,
                            address: null,
                            address2: null,
                            city: null,
                            zipCode: null,
                            phone: null,
                            altPhone: null,
                            email: null,
                            occupation: null,
                            employer: null,
                            facility: null,
                            levelCare: null,
                            status: null,
                            campaign: null,
                            paymentMethod: null,
                            state: null,
                            maritalStatus: null,
                            race: null,
                            ethnicity: null,
                            referral: null,
                            marketer: null,
                            assignee: null,
                            corporation: vm.currentEmployee.corporation
                        };
                    }
                }
            }).result.then(function () {
                findAllByFilter();
            }, function () {
            });
        }

        function toggleActive($event, item) {
            var elem = angular.element($event.currentTarget);
            elem.toggleClass('active');
            item.selected = !item.selected;
            transition();
        }

        function displayCrmOpportunity(id) {
            $scope.$emit('bluebookApp:displayCrmOpportunity', id);
        }

        function createCrmOpportunity() {
            $scope.$emit('bluebookApp:createCrmOpportunity', null);
        }

        function toggleFloatingMenu($event) {
            var btn = angular.element($event.currentTarget);
            vm.floatingMenuStatus = !vm.floatingMenuStatus;
            btn.next().slideToggle();
        }

        function calculateAge(dob) {

            return moment().diff(dob, 'years');
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgEligEmployeeDeleteController',ProgEligEmployeeDeleteController);

    ProgEligEmployeeDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProgEligEmployee'];

    function ProgEligEmployeeDeleteController($uibModalInstance, entity, ProgEligEmployee) {
        var vm = this;

        vm.progEligEmployee = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProgEligEmployee.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityLabController', FacilityLabController);

    FacilityLabController.$inject = ['FacilityLab', 'GUIUtils', 'CoreService', 'GenericEntityDatatableService',
        'ConfirmationService', '$q', '$uibModal'];

    function FacilityLabController (FacilityLab, GUIUtils, CoreService, GenericEntityDatatableService,
                                    ConfirmationService, $q, $uibModal) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'facility-lab'

        vm.descriptor = {
            title: 'Lab Integration',
            entityClassHumanized: 'Request',
            entityStateName: entityStateName,
            service: FacilityLab,
            serviceMethod: 'filter',
            params: params,
            newButtonTitle: 'Request',
            pagination: 'remote',
            options: [{key: 'order', value: [[1, 'asc']]}],
            newAction: false,
            columns: [
                {
                    name: 'account',
                    title: 'Account'
                },
                {
                    name: 'statement',
                    title: 'Statement'
                },
                {
                    name: 'enabled',
                    title: 'Enabled',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.enabled);
                    }
                },
                {
                    name: 'name',
                    title: 'Laboratory'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Request',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-lab/create/facility-lab-new-dialog.html',
                controller: 'FacilityLabNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-lab/update/facility-lab-dialog.html',
                controller: 'FacilityLabDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['FacilityLab', function (FacilityLab) {
                        return FacilityLab.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Lab Integration').result.then(function () {
                    FacilityLab.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientOrderDialogController', PatientOrderDialogController);

    PatientOrderDialogController.$inject = ['$uibModalInstance', 'Icd10Search', 'Diagnoses', 'patientOrder',
        'Via', 'Employee', 'CoreService', 'PatientOrder', 'LabCompendium', 'Relational',
        'BusinessCredential', 'BUSINESS_ROLES', 'FacilityLab', '$uibModal', 'RATIONALE_TYPE', 'itHasCredentials'];

    function PatientOrderDialogController($uibModalInstance, Icd10Search, Diagnoses, patientOrder,
                                          Via, Employee, CoreService, PatientOrder, LabCompendium, Relational,
                                          BusinessCredential, BUSINESS_ROLES, FacilityLab, $uibModal, RATIONALE_TYPE,
                                          itHasCredentials) {

        var vm = this;
        vm.patientOrder = patientOrder;
        vm.activeIcd10 = [];

        vm.patientOrderTest = {
            id: null,
            staringDate: null,
            endDate: null,
            icd10s: angular.copy(vm.activeIcd10),
            labCompendium: null,
            orderFrequency: null
        }

        Diagnoses.findAllActiveDiagnosesByChartId({id: vm.patientOrder.chart.id}, function (diagnoses) {
            _.forEach(diagnoses, function (element) {
                var idx = _.findIndex(vm.activeIcd10, function (o) {
                    return o.id === element.icd10.id;
                });

                if (idx == -1) {
                    vm.activeIcd10.push(element.icd10);

                    vm.patientOrderTest.icd10s = angular.copy(vm.activeIcd10);
                }
            })
        });

        vm.facility = CoreService.getCurrentFacility();

        vm.pin = "";
        vm.icd10s = [];
        vm.agree = false;
        vm.signature = "";
        vm.diagnoses = angular.copy(vm.activeIcd10);
        vm.employees = [];
        vm.hideTest = false;
        vm.frequency = null;
        vm.credentials = [];
        vm.via = Via.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.today = new Date();
        vm.labCompendiums = [];
        vm.activateSign = false;
        vm.itHasCredentials = itHasCredentials.value;
        vm.testCodes = [];
        vm.currentEmployee = CoreService.getCurrentEmployee();
        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.endDateOptions = {};
        vm.staringDateOptions = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.staringDate = false;

        vm.relationals = Relational.findAllByFilter(getFilterByCorporationAndType());
        vm.laboratories = [];

        FacilityLab.filter({facilityId: vm.patientOrder.chart.facility.id, page: 0, size: 1000, enabled: true}, function (result) {
            vm.facilityLabs = result;
        });

        vm.staringDateOptions = {
            // customClass: getDayClass,
            minDate: new Date(),
        }

        vm.endDateOptions = {
            // customClass: getDayClass1,
            minDate: new Date(),
        }

        var days = !vm.patientOrder.chart.drawDays || vm.patientOrder.chart.drawDays == '0000000' ? vm.facility.pDrawDays : vm.patientOrder.chart.drawDays;

        vm.save = save;
        vm.clear = clear;
        vm.generateTest = generateTest;
        vm.openCalendar = openCalendar;
        vm.getDiagnoses = getDiagnoses;
        vm.validateEndDate = validateEndDate;
        vm.changeOrderType = changeOrderType;
        vm.getLabCompendium = getLabCompendium;
        vm.changeLaboratory = changeLaboratory;
        vm.removeRelational = removeRelational;
        vm.selectRelational = selectRelational;
        vm.getEmployees = getEmployees;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDayClass(date) {
            if (date.mode === 'day') {
                if (disabled) {
                    return 'highlight-current-date';
                }
                return '';
            }
        }

        function getDayClass1(date) {
            if (date.mode === 'day') {
                if (vm.patientOrderTest.staringDate > date.date) {
                    return 'highlight-current-date';
                }
                return '';
            }
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.patientOrderTest.staringDate
        }

        credentialsPermits();

        function credentialsPermits() {
            if (vm.itHasCredentials) {
                vm.employees = [vm.currentEmployee];
                vm.patientOrder.signedBy = vm.currentEmployee;
                vm.patientOrder.signed = true;
            } else {
                vm.getEmployees();
            }
        }

        function getDiagnoses(query) {
            if (query && query.length > 1) {
                Icd10Search.query({query: query}, function (result) {
                    vm.diagnoses = result;
                });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function generateTest() {
            vm.labCompendiums.forEach(function (element) {
                var testTemp = {
                    id: null,
                    labCompendium: element,
                    icd10s: vm.patientOrderTest.icd10s,
                    labCompendiumName: element.description,
                    staringDate: vm.patientOrderTest.staringDate,
                    relationals: angular.copy(vm.patientOrderTest.relationals),
                    orderFrequency: vm.patientOrderTest.orderFrequency,
                    endDate: vm.patientOrderTest.endDate ? vm.patientOrderTest.endDate : vm.patientOrderTest.staringDate
                };
                vm.patientOrder.patientOrderTests.push(testTemp);
            });

            clearTest();
        }

        function clearTest() {
            vm.labCompendiums = [];
            vm.patientOrderTest = {
                id: null,
                icd10s: vm.activeIcd10,
                endDate: null,
                staringDate: null,
                labCompendium: null,
                orderFrequency: null
            }
        }

        function save() {
            vm.isSaving = true;

            var req = angular.extend({}, vm.patientOrder, {
                chartIds: [vm.patientOrder.chart.id],
                pin: vm.pin,
                signature: vm.signature
            });

            _.forEach(req.patientOrderTests, function (result) {
                result.staringDate = moment(result.staringDate).format('YYYY-MM-DD');
                result.endDate = result.endDate ? moment(result.endDate).format('YYYY-MM-DD') : moment(result.staringDate).format('YYYY-MM-DD');
            });

            //TODO remove
            if (req.patientOrderTests.length > 0) {
                PatientOrder.create(req, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function changeOrderType() {
            PatientOrder.getAllOrderFrequency(function (results) {
                if (results) {
                    if (vm.patientOrder.orderType == 'ONE_TIME')
                        vm.orderFrequencies = _.filter(results, function (result) {
                            return result.id == 'ONCE';
                        });
                    else vm.orderFrequencies = results;
                }
            });
        }

        function getLabCompendium(query) {
            if (vm.patientOrder.facilityLab && query && query.length > 1) {
                LabCompendium.filter({
                    laboratoryId: vm.patientOrder.facilityLab.labId,
                    searchQuery: query,
                    page: vm.page - 1,
                    size: 20,
                    sort: 'code,asc',
                    facilityId: vm.facility.id
                }, function (result) {
                    vm.testCodes = result;
                });
            }
        }

        function changeLaboratory() {
            vm.patientOrder.patientOrderTests = [];
            vm.testCodes = [];
            vm.labCompendiums = [];

            vm.getLabCompendium();
        }

        function removeRelational(relationals, index) {
            relationals.splice(index, 1);
        }

        function selectRelational(patientOrderTest) {
            $uibModal.open({
                templateUrl: 'app/entities/relational/select/relational-select-dialog.html',
                controller: 'RelationalSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl ',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    selectedRelationals: function () {
                        return patientOrderTest.relationals;
                    }
                }
            }).result.then(function (result) {
                patientOrderTest.relationals = _.filter(vm.relationals, function (relational) {
                    return _.find(result, function (id) {
                        return id === relational.id.toString();
                    });
                });
            });
        }

        function getFilterByCorporationAndType() {
            var filter = {};

            filter.corporationId = CoreService.getCorporation().id;
            filter.relationalType = RATIONALE_TYPE.LABORATORY;

            return filter
        }

        function getEmployees(query) {
            if (vm.itHasCredentials) {
                return;
            }

            var filter = {
                page: 0,
                size: 200000,
                query: query,
                facilityId: CoreService.getCurrentFacility().id,
                businessRole: BUSINESS_ROLES.PHYSICIAN
            };

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TelehealthMeetingController', TelehealthMeetingController);

    TelehealthMeetingController.$inject = ['$q', 'TelehealthMeeting', '$uibModal', '$rootScope', 'Employee',
        'CoreService', 'toastr', '$window', 'GenericEntityDatatableService', 'ConfirmationService'];

    function TelehealthMeetingController($q, TelehealthMeeting, $uibModal, $rootScope, Employee,
                                         CoreService, toastr, $window, GenericEntityDatatableService, ConfirmationService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Telehealth Meetings',
            entityClassHumanized: 'Telehealth',
            entityStateName: 'telehealth',
            service: TelehealthMeeting,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [{key: 'aaSorting', value: [[3, 'asc']]}],
            columns: [
                {
                    name: 'twilioRoomName',
                    title: 'Id',
                },
                {
                    name: 'topic',
                    title: 'Topic',
                },
                {
                    name: null,
                    title: 'Host',
                    render: function (data) {
                        return data.employeeLastName + ', ' + data.employeeFirstName;
                    }
                },
                {
                    name: null,
                    title: 'Start Date',
                    render: function (data) {
                        return moment(data.startDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Scheduled Date',
                    render: function (data) {
                        return moment(data.startDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'End Date',
                    render: function (data) {
                        return moment(data.endDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Duration',
                    render: function (data) {
                        return data.duration + ' min';
                    }
                },
                {
                    name: null,
                    title: 'Participants',
                    render: function (data) {
                        return data.participants;
                    }
                },
            ],
            updateColumns: function (dtColumns, filter) {
                switch (filter.status) {
                    case 'SCHEDULED' : {
                        dtColumns[1].withOption("bVisible", true);
                        dtColumns[2].withOption("bVisible", true);
                        dtColumns[3].notVisible();
                        dtColumns[4].withOption("bVisible", true);
                        dtColumns[5].notVisible();
                        dtColumns[6].notVisible();
                        dtColumns[7].notVisible();
                        break;
                    }
                    case 'IN_PROGRESS' : {
                        dtColumns[1].withOption("bVisible", true);
                        dtColumns[2].withOption("bVisible", true);
                        dtColumns[3].withOption("bVisible", true);
                        dtColumns[4].notVisible();
                        dtColumns[5].notVisible();
                        dtColumns[6].notVisible();
                        dtColumns[7].notVisible();
                        break;
                    }
                    case 'COMPLETED' : {
                        dtColumns[1].withOption("bVisible", true);
                        dtColumns[2].withOption("bVisible", true);
                        dtColumns[3].withOption("bVisible", true);
                        dtColumns[4].notVisible();
                        dtColumns[5].withOption("bVisible", true);
                        dtColumns[6].withOption("bVisible", true);
                        dtColumns[7].withOption("bVisible", true);
                        break;
                    }
                }
            },
            rowActions: [
                {
                    name: 'Start Meeting',
                    action: function (data) {
                        return start(data);
                    },
                    aClass: 'dd-link green',
                    iClass: 'fa fa-play',
                    ngIf: function (data, filter) {
                        return filter.status === 'SCHEDULED' && data.hostId === CoreService.getCurrentEmployee().id;
                    }
                },
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return choose(data.id);
                    },
                    ngIf: function (data, filter) {
                        return filter.status === 'SCHEDULED' && data.frequency !== 'NONE' && data.frequency !== 'CUSTOM';
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    },
                    ngIf: function (data, filter) {
                        return filter.status === 'SCHEDULED' && (data.frequency === 'NONE' || data.frequency === 'CUSTOM');
                    }
                }),
                {
                    name: 'Join Meeting',
                    action: function (data) {
                        join(data.id);
                    },
                    aClass: 'dd-link green',
                    iClass: 'fa fa-mail-forward',
                    ngIf: function (data, filter) {
                        return filter.status === 'IN_PROGRESS';
                    },
                    reload: false
                },
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        detail(data.id);
                    },
                    ngIf: function (data, filter) {
                        return filter.status === 'IN_PROGRESS' || filter.status === 'COMPLETED';
                    },
                    reload: false
                }),
                {
                    name: 'Copy Invitation',
                    action: function (data) {
                        invitation(data.id);
                    },
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-copy',
                    ngIf: function (data, filter) {
                        return filter.status === 'SCHEDULED' || filter.status === 'IN_PROGRESS';
                    },
                    reload: false
                },
                {
                    name: 'End Meeting',
                    action: function (data) {
                        return end(data);
                    },
                    aClass: 'dd-link red',
                    iClass: 'fa fa-stop',
                    ngIf: function (data, filter) {
                        return filter.status === 'IN_PROGRESS';
                    }
                },
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    },
                    ngIf: function (data, filter) {
                        return filter.status === 'SCHEDULED';
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'Schedule',
                            iClass: 'fa fa-calendar',
                            ngClass: function (filter) {
                                return filter.status === 'SCHEDULED' ? ['active'] : [];
                            },
                            action: function (filter) {
                                filter.status = 'SCHEDULED';
                            }
                        },
                        {
                            name: 'Live Meetings',
                            iClass: 'fa fa-play',
                            ngClass: function (filter) {
                                return filter.status === 'IN_PROGRESS' ? ['active'] : [];
                            },
                            action: function (filter) {
                                filter.status = 'IN_PROGRESS';
                            }
                        },
                        {
                            name: 'Past Meetings',
                            iClass: 'fa fa-thumbs-up',
                            ngClass: function (filter) {
                                return filter.status === 'COMPLETED' ? ['active'] : [];
                            },
                            action: function (filter, selected) {
                                filter.status = 'COMPLETED';
                            }
                        }
                    ],
                },
                {
                    type: 'group',
                    buttons: [
                        {
                            ngClass: function () {
                                return ['bg-green']
                            },
                            iClass: 'fa fa-plus-circle',
                            action: function (filter) {
                                return add();
                            }
                        },
                    ]
                }
            ],
            filters: [
                {
                    name: 'status',
                    visible: false,
                    defaultValue: function () {
                        return 'SCHEDULED';
                    }
                },
                {
                    name: 'twilioRoomName',
                    type: 'string',
                    placeholder: 'Id...',
                    visible: true
                },
                {
                    name: 'topic',
                    type: 'string',
                    placeholder: 'Topic...',
                    visible: true
                },
                {
                    name: 'hostId',
                    read: function (filter) {
                        return filter.hostId ? filter.hostId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        Employee.findAllByFilter(
                            {
                                page: 0,
                                size: 10,
                                facilityId: CoreService.getCurrentFacility().id,
                                query: search,
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select host...',
                    type: 'select',
                    visible: true,
                },
                {
                    name: 'duration',
                    placeholder: 'Duration...',
                    type: 'number',
                    visible: true
                },
                {
                    name: 'startDate',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.startDate ? moment(filter.startDate).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'endDate',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.endDate ? moment(filter.endDate).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
            ],
        }

        function add() {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/telehealth/create/telehealth-meeting-dialog.html',
                    controller: 'TelehealthMeetingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                id: null,
                                topic: null,
                                description: null,
                                startDate: new Date(),
                                timeZone: null,
                                duration: 1,
                                password: null,
                                telehealthMeetingRecurrence: {
                                    frequency: 'NONE',
                                    repeat: 1,
                                    end: 'RECURRENCE',
                                    endDate: null,
                                    id: null,
                                    weekDays: '0000000',
                                    facility: CoreService.getCurrentFacility()
                                },
                                host: null,
                                facility: CoreService.getCurrentFacility()
                            };
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function choose(id) {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/telehealth/choose/telehealth-meeting-choose-edit-dialog.html',
                    controller: 'TelehealthMeetingChooseEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {}
                }).result.then(function (result) {
                    if (result === 'meeting') {
                        resolve(edit(id));
                    } else if (result === 'allMeetings') {
                        resolve(editMeetingAndRecurrence(id));
                    }
                }, function () {
                    toastr.error('Something went wrong', 'Error');

                    resolve();
                });
            })
        }

        function edit(id) {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/telehealth/edit/telehealth-meeting-edit-dialog.html',
                    controller: 'TelehealthMeetingEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['TelehealthMeeting', function (TelehealthMeeting) {
                            return TelehealthMeeting.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function (result) {
                    if (result) {
                        toastr.success('Meeting was saved', 'Success');
                    }
                    resolve();
                }, function () {
                    toastr.error('Something went wrong', 'Error');
                    resolve();
                });
            });

        }

        function editMeetingAndRecurrence(id) {
            return $q(function (resolve, reject) {
                $uibModal.open({
                    templateUrl: 'app/entities/telehealth/create/telehealth-meeting-dialog.html',
                    controller: 'TelehealthMeetingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['TelehealthMeeting', function (TelehealthMeeting) {
                            return TelehealthMeeting.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function (result) {
                    if (result) {
                        toastr.success('Meetings were saved', 'Success');
                    }

                    resolve();
                }, function () {
                    toastr.error('Something went wrong', 'Error');
                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Telehealth Meeting').result.then(function () {
                    TelehealthMeeting.delete({id: id}, function (result) {
                        if (result) {
                            toastr.success('Meeting was deleted', 'Success');
                        }

                        resolve();
                    }, function () {
                        toastr.success('Something went wrong', 'Error');
                        resolve();
                    })
                })
            });
        }

        function detail(id) {
            $uibModal.open({
                templateUrl: 'app/entities/telehealth/detail/telehealth-meeting-detail-dialog.html',
                controller: 'TelehealthMeetingDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['TelehealthMeeting', function (TelehealthMeeting) {
                        return TelehealthMeeting.get({id: id}).$promise;
                    }]
                }
            });
        }

        function join(telehealthMeeting) {
            var endpoint = ($window.origin).includes('localhost') ? 'http://localhost:3000/meeting/' : 'https://twvideo.bluebookehr.com/meeting/';
            var origin = ($window.origin).includes('localhost') ? 'localhost' : ($window.origin).substr(8);
            var url = endpoint + telehealthMeeting.twilioRoomName + '/' + origin;

            if (telehealthMeeting.hostId === CoreService.getCurrentEmployee().id) {
                url = url + '?username=' + telehealthMeeting.employeeFirstName + ' ' + telehealthMeeting.employeeLastName + '&password=' + telehealthMeeting.password;
            }

            $window.open(url, '_blank');
        }

        function start(telehealthMeeting) {
            return $q(function (resolve, reject) {
                var endpoint = ($window.origin).includes('localhost') ? 'http://localhost:3000/meeting/' : 'https://twvideo.bluebookehr.com/meeting/';
                var origin = ($window.origin).includes('localhost') ? 'localhost' : ($window.origin).substr(8);
                var url = endpoint + telehealthMeeting.twilioRoomName + '/' + origin;

                TelehealthMeeting.startMeeting({twilioRoomName: telehealthMeeting.twilioRoomName}, function (result) {
                    if (result.isValid) {
                        $window.open(url + '?username=' + result.telehealthMeeting.host.firstName + ' ' + result.telehealthMeeting.host.lastName + '&password=' + result.telehealthMeeting.password, '_blank');
                    } else {
                        toastr.error('Something went wrong: ' + result.error, 'Error');
                    }

                    resolve();
                });
            })
        }

        function end(id) {
            return $q(function (resolve, reject) {
                var twilioRoomName = _.filter(vm.telehealthMeetings, ['id', id])[0].twilioRoomName;

                TelehealthMeeting.endMeeting({twilioRoomName: twilioRoomName},
                    function (result) {
                        if (!result.isValid) {
                            toastr.error('Something went wrong: ' + result.error, 'Error');
                        }

                        resolve();
                    }, function () {
                        resolve();
                    });
            });
        }

        function invitation(id) {
            $uibModal.open({
                templateUrl: 'app/entities/telehealth/invitation/telehealth-meeting-invitation-dialog.html',
                controller: 'TelehealthMeetingInvitationController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['TelehealthMeeting', function (TelehealthMeeting) {
                        return TelehealthMeeting.get({id: id}).$promise;
                    }]
                }
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidPhysicianDialogController', CovidPhysicianDialogController);

    CovidPhysicianDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'CovidPhysician', 'Facility', 'Employee', 'CoreService', 'BUSINESS_ROLES',
        'itHasCredentials'];

    function CovidPhysicianDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                            CovidPhysician, Facility, Employee, CoreService, BUSINESS_ROLES,
                                            itHasCredentials) {
        var vm = this;

        vm.covidPhysician = entity;
        vm.itHasCredentials = itHasCredentials.value;
        vm.employees = [];
        vm.credentials = [];
        vm.currentEmployee = CoreService.getCurrentEmployee();

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;

        credentialsPermits();

        function credentialsPermits() {
            if (vm.itHasCredentials) {
                vm.employees = [vm.currentEmployee];
                vm.covidPhysician.physician = vm.currentEmployee;
            } else {
                vm.getEmployees();
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.covidPhysician.id !== null) {
                CovidPhysician.update(vm.covidPhysician, onSaveSuccess, onSaveError);
            } else {
                CovidPhysician.save(vm.covidPhysician, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:covidPhysicianUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (vm.itHasCredentials) {
                return;
            }

            var filter = {
                page: 0,
                size: 20,
                query: query,
                facilityId: CoreService.getCurrentFacility().id,
                businessRole: BUSINESS_ROLES.PHYSICIAN
            };

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

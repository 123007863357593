(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateFileDialogController', EvaluationTemplateFileDialogController);

    EvaluationTemplateFileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EvaluationTemplateFile',  'DataUtils'];

    function EvaluationTemplateFileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EvaluationTemplateFile, DataUtils) {
        var vm = this;

        vm.evaluationTemplateFile = entity;

        vm.clear = clear;
        vm.attachFile = attachFile;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:evaluationTemplateFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function attachFile (files) {
            var attachFiles = [];
            var bigFiles = _.remove(files, function (item) {

                return parseInt(item.size) > 16000000;
            });
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                DataUtils.toBase64ToFileObject(file, function (result) {
                    result.ownerId = parseInt(vm.evaluationTemplateFile.evaluationTemplate.id);
                    attachFiles.push(result);
                    if (attachFiles.length == files.length) {
                        attach(attachFiles);
                    }
                });
            }
            for (var i = 0; i < bigFiles.length; i++) {
                toastr.error(bigFiles[i].name + ' file is too big. The maximum size is 16MB')
            }
        }

        function attach(result) {
            EvaluationTemplateFile.attachFiles({evaluationTemplate : {id: vm.evaluationTemplateFile.evaluationTemplate.id}, files: result}, onSaveSuccess, onSaveError);
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationAuditDialogController', EvaluationAuditDialogController);

    EvaluationAuditDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'EvaluationAudit', 'CoreService', 'EvaluationTemplate'];

    function EvaluationAuditDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                             EvaluationAudit, CoreService, EvaluationTemplate) {
        var vm = this;

        vm.evaluationAudit = entity;
        vm.evaluationAuditTemplates = [];
        vm.form = {};

        vm.clear = clear;
        vm.save = save;
        vm.getEvaluationTemplates = getEvaluationTemplates;
        vm.add = add;
        vm.del = del;

        init();

        function init() {
            if (vm.evaluationAudit.id) {
                EvaluationAudit.findAllByEvaluationAudit({id: vm.evaluationAudit.id}, function (result) {

                    _.forEach(result, function (resultTemplate) {
                        var evaluationAuditTemplate = _.find(vm.evaluationAuditTemplates, function (evaluationAuditTemplate) {

                            return evaluationAuditTemplate.quantity == resultTemplate.quantity;
                        });

                        if (evaluationAuditTemplate) {
                            evaluationAuditTemplate.evaluationTemplates.push(
                                {id: resultTemplate.id, name: resultTemplate.name}
                            );
                        } else {
                            vm.evaluationAuditTemplates.push({
                                evaluationTemplates: [{id: resultTemplate.id, name: resultTemplate.name}],
                                quantity: resultTemplate.quantity
                            });
                        }
                    })
                });
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.evaluationAudit.id !== null) {
                var evaluationAudit = createDto();

                EvaluationAudit.update(evaluationAudit, onSaveSuccess, onSaveError);
            } else {
                var evaluationAudit = createDto();

                EvaluationAudit.save(evaluationAudit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:evaluationAuditUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getFilter(query) {
            var filter = {};

            filter.facilityId = CoreService.getCurrentFacility().id;
            filter.page = 0;
            filter.size = 100000;
            filter.searchQuery = query;
            filter.enabled = true;

            return filter
        }

        function getEvaluationTemplates(query) {
            EvaluationTemplate.findAllByFilter(getFilter(query), function (result) {
                vm.evaluationTemplates = result;
            });
        }

        function add() {
            vm.evaluationAuditTemplates.push({evaluationTemplates: [], quantity: 1});
        }

        function del(index) {
            if (vm.evaluationAuditTemplates.length > 1) {
                vm.evaluationAuditTemplates.splice(index, 1);
            }
        }

        function createDto() {
            var _evaluationAuditTemplates = [];

            _.forEach(vm.evaluationAuditTemplates, function (evaluationAuditTemplate) {
                _.forEach(evaluationAuditTemplate.evaluationTemplates, function (evaluationTemplate) {

                    var temp = _.find(_evaluationAuditTemplates, function (_evaluationAuditTemplate) {
                        return _evaluationAuditTemplate.evaluationTemplate.id === evaluationTemplate.id;
                    });

                    if (temp) {
                        temp.quantity += evaluationAuditTemplate.quantity
                    } else {
                        _evaluationAuditTemplates.push({
                            quantity: evaluationAuditTemplate.quantity,
                            evaluationTemplate: evaluationTemplate
                        });
                    }
                })
            })

            var _evaluationAudit = angular.copy(vm.evaluationAudit);
            _evaluationAudit.evaluationAuditTemplates = _evaluationAuditTemplates;

            return _evaluationAudit;
        }
    }
})();

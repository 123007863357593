(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('patient-result', {
                parent: 'entity',
                url: '/patient-result',
                data: {
                    authorities: [
                        ROLES.ROLE_USER
                    ],
                    pageTitle: 'Patient Results'
                },
                ncyBreadcrumb: {
                    label: 'Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/list/patient-result.html',
                        controller: 'PatientResultController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('sent-lab-result', {
                parent: 'entity',
                url: '/sent-lab-result',
                data: {
                    authorities: [
                        ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN
                    ],
                    pageTitle: 'Sent Lab Results'
                },
                ncyBreadcrumb: {
                    label: 'Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/sent-lab/sent-lab-result.html',
                        controller: 'SentLabResultController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('unassigned-results', {
                parent: 'entity',
                url: '/unassigned-results',
                data: {
                    authorities: [],
                    pageTitle: 'Unassigned Results'
                },
                ncyBreadcrumb: {
                    label: 'Unassigned Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/unassigned/patient-result-unassigned.html',
                        controller: 'PatientResultsUnassignedController',
                        controllerAs: 'vm'
                    }
                },
            })
            .state('partial-result', {
                parent: 'entity',
                url: '/partial-results',
                data: {
                    authorities: [],
                    pageTitle: 'Partial Results'
                },
                ncyBreadcrumb: {
                    label: 'Partial Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/partial/patient-result-partial.html',
                        controller: 'PatientResultPartialController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('critical-result', {
                parent: 'entity',
                url: '/critical-results',
                data: {
                    authorities: [],
                    pageTitle: 'Critical Results'
                },
                ncyBreadcrumb: {
                    label: 'Critical Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/critical/list/patient-result-critical.html',
                        controller: 'PatientResultCriticalController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('abnormal-result', {
                parent: 'entity',
                url: '/abnormal-results',
                data: {
                    authorities: [],
                    pageTitle: 'Abnormal Results'
                },
                ncyBreadcrumb: {
                    label: 'Abnormal Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/abnormal/patient-result-abnormal.html',
                        controller: 'PatientResultAbnormalController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();

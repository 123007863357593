(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DiagnosesDiscontinueDialogController', DiagnosesDiscontinueDialogController);

    DiagnosesDiscontinueDialogController.$inject = ['$uibModalInstance', 'CoreService', 'entity'];

    function DiagnosesDiscontinueDialogController($uibModalInstance, CoreService, entity) {

        var vm = this;

        vm.diagnoses = entity;
        vm.diagnoses.endDate = "";

        vm.employeeSignature = '';
        vm.employeeSignaturePin = null;
        vm.signatureOpts = getSignatureOptions();
        vm.employee = CoreService.getCurrentEmployee();

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.endDateOptions.minDate = vm.diagnoses.startDate;

        vm.clear = clear;
        vm.discontinue = discontinue;

        vm.openCalendar = openCalendar;

        function getSignatureOptions() {
            return {
                width: 620,
                height: 250,
                cssClass: 'signature-canvas',
                color: '#00008B',
                'background-color': '#fff'
            };
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function discontinue() {
            $uibModalInstance.close({
                employeeSignature: vm.employeeSignature,
                employeeSignaturePin: vm.employeeSignaturePin,
                endDate: vm.diagnoses.endDate
            });
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

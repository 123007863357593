(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('GroupSessionDetails', GroupSessionDetails);

    GroupSessionDetails.$inject = ['$resource', 'DateUtils', 'OdsFormService'];

    function GroupSessionDetails($resource, DateUtils, OdsFormService) {
        var resourceUrl = 'api/group-session-details/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.stop = DateUtils.convertDateTimeFromServer(data.stop);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                ignoreLoadingBar: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    return angular.toJson(copy);
                }
            },
            'autoSave': {
                method: 'PUT',
                url: 'api/group-session-details/auto-save',
                ignoreLoadingBar: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);

                    return angular.toJson(copy);
                }
            },
            'pageableDetails': {
                url: 'api/group-session-details/group-session/:id',
                method: 'GET',
                ignoreLoadingBar: true,
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        var result = [];
                        data.forEach(function (element) {
                            element[0].groupSession = element[1];
                            element[0].start = DateUtils.convertDateTimeFromServer(element[0].start);
                            element[0].stop = DateUtils.convertDateTimeFromServer(element[0].stop);
                            result.push(element[0]);
                        });
                        return result;
                    }
                }
            },
            'getAllGroupSessionDetails': {
                url: 'api/group-session-details/list/group-session/:id',
                method: 'GET',
                ignoreLoadingBar: true,
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        var result = [];
                        if (data.length > 0) {
                            data.forEach(function (element) {
                                element[0].groupSession = element[1];
                                element[0].start = DateUtils.convertDateTimeFromServer(element[0].start);
                                element[0].stop = DateUtils.convertDateTimeFromServer(element[0].stop);
                                result.push(element[0]);

                            });
                        }
                        return result;
                    }
                }
            },
            'findAllByGroupSessionAndGroupSessionTemplate': {
                url: 'api/group-session-details/by-group-session/:id',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);

                    return _.map(data, function (item) {
                        item.start = DateUtils.convertDateTimeFromServer(item.start);
                        item.stop = DateUtils.convertDateTimeFromServer(item.stop);

                        return item;
                    });
                }
            },
            'addToGroupSession': {url: 'api/group-session-details/add-to-group-session', method: 'POST'},
            'findAllByFilter': { url: 'api/group-session-details/filter', method: 'GET', isArray: true},
            'getJsonTemplate': {
                url: 'api/group-session-details/get-json-template/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = OdsFormService.convertFormSchemaFromServer(data);
                    }

                    return data;
                },
            },

            'getHistory': {
                url: 'api/group-session-details-histories/history/:id',
                method: 'GET',
                ignoreLoadingBar: true,
                isArray: true
            },
        });
    }
})();

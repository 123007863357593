(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientResultCriticalAlertDialogController', PatientResultCriticalAlertDialogController);

    PatientResultCriticalAlertDialogController.$inject = ['$uibModalInstance', 'critical'];

    function PatientResultCriticalAlertDialogController($uibModalInstance, critical) {
        var vm = this;

        vm.clear = clear;
        vm.critical = critical;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

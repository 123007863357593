(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PendingRequisitionsTopBoxController', PendingRequisitionsTopBoxController);

    PendingRequisitionsTopBoxController.$inject = ['facility', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult'];

    function PendingRequisitionsTopBoxController(facility, $scope, $state, $q, DTColumnBuilder, PatientResult) {
        var vm = this;
        vm.pending = 0;

        vm.pending = PatientResult.countPartial({id: facility.id});
    }
})();

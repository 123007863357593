(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TypeLevelCareController', TypeLevelCareController);

    TypeLevelCareController.$inject = ['GenericEntityDatatableService', 'TypeLevelCare', 'CoreService', '$q',
        '$uibModal', 'ConfirmationService'];

    function TypeLevelCareController(GenericEntityDatatableService, TypeLevelCare, CoreService, $q,
                                     $uibModal, ConfirmationService) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Level of Care',
            newButtonTitle: 'New Level of Care',
            entityClassHumanized: 'Level of Care',
            entityStateName: 'type-level-care',
            service: TypeLevelCare,
            serviceMethod: 'findAllByFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Color',
                    render: function (data) {
                        return '<div style="background-color:' + data.color + '">' +
                            (data.color ? data.color : '') +
                            '</div>';
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Level of Care',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/type-level-care/update/type-level-care-dialog.html',
                controller: 'TypeLevelCareDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/type-level-care/update/type-level-care-dialog.html',
                controller: 'TypeLevelCareDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['TypeLevelCare', function (TypeLevelCare) {
                        return TypeLevelCare.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Level of Care').result.then(function () {
                    TypeLevelCare.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('crm-referral-contact', {
                parent: 'entity',
                url: '/crm-referral-contact',
                data: {
                    authorities: [],
                    pageTitle: 'CrmReferralContact'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/crm-referral-contact/crm-referral-contact.html',
                        controller: 'CrmReferralContactController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT, access);
                }]
            })
            .state('crm-referral-contact.new', {
                parent: 'crm-referral-contact',
                url: '/new',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-referral-contact/crm-referral-contact-dialog.html',
                            controller: 'CrmReferralContactDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CoreService', function (CoreService) {
                                    return {
                                        firstName: null,
                                        lastName: null,
                                        dob: null,
                                        department: null,
                                        phone: null,
                                        mobile: null,
                                        fax: null,
                                        email: null,
                                        note: null,
                                        status: null,
                                        id: null,
                                        corporation: CoreService.getCorporation()
                                    };
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-referral-contact', null, {reload: 'crm-referral-contact'});
                        }, function () {
                            $state.go('crm-referral-contact');
                        });
                    }
                }]
            })
            .state('crm-referral-contact.edit', {
                parent: 'crm-referral-contact',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-referral-contact/crm-referral-contact-dialog.html',
                            controller: 'CrmReferralContactDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CrmReferralContact', function (CrmReferralContact) {
                                    return CrmReferralContact.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-referral-contact', null, {reload: 'crm-referral-contact'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            })
            .state('crm-referral-contact.delete', {
                parent: 'crm-referral-contact',
                url: '/{id}/delete',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_REFERRAL_CONTACT, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-referral-contact/crm-referral-contact-delete-dialog.html',
                            controller: 'CrmReferralContactDeleteController',
                            controllerAs: 'vm',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CrmReferralContact', function (CrmReferralContact) {
                                    return CrmReferralContact.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-referral-contact', null, {reload: 'crm-referral-contact'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            });
    }
})();

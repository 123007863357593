(function () {
    'use strict';

    angular
        .module('udt')
        .filter('clearEnum', clearEnum);

    function clearEnum() {
        return clearEnumFilter;

        function clearEnumFilter(value) {

            return value == null ? "" : value.replace('_', ' ');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabCompendiumRestrictionDialogController', LabCompendiumRestrictionDialogController);

    LabCompendiumRestrictionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'LabCompendiumRestriction', 'LabCompendium', 'CoreService'];

    function LabCompendiumRestrictionDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                                      entity, LabCompendiumRestriction, LabCompendium, CoreService) {
        var vm = this;

        vm.labCompendiumRestriction = entity;
        vm.facility = CoreService.getCurrentFacility();

        vm.clear = clear;
        vm.save = save;
        vm.getLabCompendium = getLabCompendium;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.labCompendiumRestriction.id !== null) {
                LabCompendiumRestriction.update(vm.labCompendiumRestriction, onSaveSuccess, onSaveError);
            } else {
                LabCompendiumRestriction.save(vm.labCompendiumRestriction, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:labCompendiumRestrictionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getLabCompendium(query) {
            LabCompendium.filter({
                laboratoryId: vm.labCompendiumRestriction.laboratory.id,
                searchQuery: query,
                page: 0,
                size: 40,
                sort: 'code,asc'
            }, function (result) {
                vm.labCompendiums = result;
            });
        }
    }
})();

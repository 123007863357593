(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('sample', {
                parent: 'entity',
                url: '/sample',
                data: {
                    authorities: [
                        ROLES.ROLE_ADMIN,
                        ROLES.ROLE_PROGRAM_DIRECTOR,
                        ROLES.ROLE_CASE_MANAGER,
                        ROLES.ROLE_DIRECTOR_NURSE,
                        ROLES.ROLE_REGISTER_NURSE,
                        ROLES.ROLE_LICENSE_PRACTITIONER_NURSE,
                        ROLES.ROLE_PRIMARY_THERAPIST,
                        ROLES.ROLE_OTHER_THERAPIST,
                        ROLES.ROLE_BHT,
                        ROLES.ROLE_CLINICAL_DIRECTOR,
                        ROLES.ROLE_PHYSICIAN_ASSISTANCE
                    ],
                    pageTitle: 'Sample'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sample-files/sample-table-view.html',
                        controller: 'SampleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartsReferrerController', ChartsReferrerController);

    ChartsReferrerController.$inject = ['CoreService', 'Employee', '$state', 'Chart', '$rootScope', '$stateParams',
        'GenericEntityDatatableService', 'TypeLevelCare'];

    function ChartsReferrerController(CoreService, Employee, $state, Chart, $rootScope, $stateParams,
                                      GenericEntityDatatableService, TypeLevelCare) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Patient By Referral',
            entityClassHumanized: 'Patient By Referral',
            entityStateName: 'charts',
            service: Chart,
            serviceMethod: "fullFilter",
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[0, 'desc']]}
            ],
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    },
                    options: [{key: "width", value: "200px"}]
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY")
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: "width", value: "130px"}]
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return moment(data.admissionDate).format("MM/DD/YYYY");
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: 'Treatment Days',
                    render: function (data) {
                        return status == "ARCHIVE" || status == "CLOSED" ?
                            moment(data.dischargeDate).diff(moment(data.admissionDate), 'days') :
                            moment(new Date()).diff(moment(data.admissionDate), 'days');
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    title: 'Referral',
                    placeholder: 'Referral Source...',
                    render: function (data) {
                        return data.referrer ? data.referrer : "-"
                    }
                },
                {
                    name: null,
                    title: 'Outreach',
                    render: function (data) {
                        return data.marketerFirstName != null ? data.marketerLastName + ", " + data.marketerFirstName : " ";
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.id});
                    },
                })
            ],
            filters: [
                {
                    name: 'statuses',
                    defaultValue: function () {
                        return 'CURRENT';
                    },
                    visible: false
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: "marketerId",
                    read: function (filter) {
                        return filter.marketerId ? filter.marketerId.id : null;
                    },
                    values: [],
                    placeholder: 'Select outreach...',
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        var filter = {
                            page: 0,
                            size: 20,
                            query: search,
                            facilityId: CoreService.getCurrentFacility().id
                        };

                        Employee.findAllByFilter(filter, function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    type: 'select',
                },
                {
                    name: 'typeLevelCareId',
                    read: function (filter) {
                        return filter.typeLevelCareId ? filter.typeLevelCareId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeLevelCare.findAllByFacility(
                            CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Level of Care ...',
                    type: 'select',
                },
                {
                    name: 'referrer',
                    type: 'string',
                    defaultValue: function () {
                        return $stateParams.referrer != null ? $stateParams.referrer : null
                    }
                },
                {
                    name: 'admissionDateFrom',
                    // title: 'Day of Birth',
                    placeholder: 'From Admission Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                },
                {
                    name: 'admissionDateTo',
                    // title: 'Day of Birth',
                    placeholder: 'To Admission Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                }
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'All',
                            iClass: 'fa fa-users',
                            ngClass: function (filter) {
                                return filter.status === null ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.status = null;
                            }
                        },
                        {
                            name: 'Current',
                            iClass: 'fa fa-dashboard',
                            ngClass: function (filter) {
                                return filter.status === 'CURRENT' ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.status = 'CURRENT';
                            },
                        },
                        {
                            name: 'Archive',
                            iClass: 'fa fa-archive',
                            ngClass: function (filter) {
                                return filter.status === 'ARCHIVE' ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.status = 'ARCHIVE';
                            },
                        }
                    ]
                }
            ]
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LaboratoryLogoDialogController', LaboratoryLogoDialogController);

    LaboratoryLogoDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity',
        'LaboratoryLogo', 'DataUtils', 'toastr'];

    function LaboratoryLogoDialogController($timeout, $scope, $uibModalInstance, entity,
                                            LaboratoryLogo, DataUtils, toastr) {
        var vm = this;

        vm.laboratory = entity;
        vm.file = {};
        vm.image = null;

        vm.clear = clear;
        vm.save = save;
        vm.handleFileSelect = handleFileSelect;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.laboratory.id !== null && vm.image !== null) {
                LaboratoryLogo.save({
                    laboratoryId: vm.laboratory.id,
                    uuid: vm.image
                }, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            toastr.success("Logo added successfully");
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            toastr.error("Error adding logo");
            vm.isSaving = false;
        }

        function handleFileSelect(evt, file) {
            if (file) {
                DataUtils.toBase64(file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.image = base64Data;
                        vm.file = file;
                    });
                });
            }
        }

    }
})();

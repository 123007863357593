(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('InsuranceBackPicture', InsuranceBackPicture);

    InsuranceBackPicture.$inject = ['$resource'];

    function InsuranceBackPicture ($resource) {
        var resourceUrl =  'api/insurance-back-pictures/:id';

        return $resource(resourceUrl, {}, {
            'upload': {url: 'api/insurance-back-pictures/upload', method: 'POST'},
            'getOriginalBase64ByInsurance': {url: 'api/insurance-back-pictures/get-original-base-64/by-insurance/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        var result = {image: data};
                    }

                    return result;
                }}
        });
    }
})();

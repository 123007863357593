(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientOrderChartController', PatientOrderChartController);

    PatientOrderChartController.$inject = ['chart', '$uibModal', 'PatientOrder', 'ParseLinks', 'AlertService', 'Auth',
        'CoreService', 'PopupService', '$rootScope', '$state', '$scope', 'access', 'ACTIONS',
        'TAB', 'params'];

    function PatientOrderChartController(chart, $uibModal, PatientOrder, ParseLinks, AlertService, Auth,
                                         CoreService, PopupService, $rootScope, $state, $scope, access, ACTIONS,
                                         TAB, params) {
        var vm = this;
        vm.chart = chart;

        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.labCompendium = [];
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.LAB_ORDERS);
        vm.chart = chart;
        vm.access = access;
        vm.patientOrder = [];
        vm.accessCreate = access[ACTIONS.ACTION_ADD_PATIENT_ORDER_CREATE] ? access[ACTIONS.ACTION_ADD_PATIENT_ORDER_CREATE].roles.join() : null;

        vm.add = add;
        vm.del = del;
        vm.detail = detail;
        vm.transition = transition;
        vm.toggleItem = toggleItem;
        vm.search = search;

        function toggleItem() {
            CoreService.togglePanel('orders');
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order/create/patient-order-dialog.html',
                controller: 'PatientOrderDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientOrder: {
                        id: null,
                        chart: chart,
                        signatureDate: null,
                        signed: false,
                        via: null,
                        signedBy: null,
                        discontinueBy: null,
                        discontinueCause: null,
                        staringDate: null,
                        endDate: null,
                        orderType: null,
                        icd10Code: null,
                        patientOrderTests: [],
                        patientOrderSignature: null
                    },
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.PHYSICIAN
                        }).$promise;
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

        function detail(id) {
            PopupService.showPatientOrderDetailsPopup(id).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

        function del(id) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order/discontinue/patient-order-discontinue-dialog.html',
                controller: 'PatientOrderDiscontinueController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientOrder: ['$stateParams', 'PatientOrder', function ($stateParams, PatientOrder) {
                        return PatientOrder.get({id: id}).$promise;
                    }],
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.PHYSICIAN
                        }).$promise;
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW, access) || !vm.active) {
                return;
            }

            PatientOrder.findAllByChart({
                chartId: chart.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientOrder = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        $rootScope.$on('bluebookApp:chartOrdersLabOrders', function (event, result) {
            loadAll();
        });

        function search() {
            vm.page = 1;
            transition();
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result == TAB.LAB_ORDERS) {
                vm.active = true;
                loadAll();
            }
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('charts', {
                parent: 'entity',
                url: '/charts?page&sort&search',
                data: {
                    authorities: [],
                    pageTitle: 'Patients Chart'
                },
                ncyBreadcrumb: {
                    label: 'Charts'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/list/chart.html',
                        controller: 'ChartController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    levelCare: null,
                },
                resolve: {
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    filter: ['facility', '$stateParams', 'CoreService', 'DateUtils', function (facility, $stateParams, CoreService, DateUtils) {
                        var filter = CoreService.getChartFilter();

                        if (filter == null || filter.facilityId != facility.id || $stateParams.levelCare != null) {
                            CoreService.setChartFilter(null);

                            return {
                                facilityId: facility.id,
                                status: 'CURRENT',
                                firstName: null,
                                lastName: null,
                                mrNo: null,
                                dischargeDateFrom: null,
                                dischargeDateTo: null,
                                admissionDateFrom: null,
                                admissionDateTo: null,
                                searchQuery: null,
                                typeLevelCare: $stateParams.levelCare != null ? $stateParams.levelCare : null,
                                sort: "firstName",
                                direction: "ASC",
                                statuses: 'CURRENT',
                                page: 1
                            };
                        } else {
                            filter.dischargeDateFrom = filter.dischargeDateFrom ? DateUtils.convertDateTimeFromServer(filter.dischargeDateFrom) : null;
                            filter.dischargeDateTo = filter.dischargeDateTo ? DateUtils.convertDateTimeFromServer(filter.dischargeDateTo) : null;
                            filter.admissionDateFrom = filter.admissionDateFrom ? DateUtils.convertDateTimeFromServer(filter.admissionDateFrom) : null;
                            filter.admissionDateTo = filter.admissionDateTo ? DateUtils.convertDateTimeFromServer(filter.admissionDateTo) : null;

                            return filter;
                        }
                    }],

                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_PATIENT_LIST, ACTIONS.ACTION_PATIENT_NEW];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_PATIENT_LIST, access);
                }]
            })
            .state('chart', {
                parent: 'entity',
                url: '/chart-r/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'Patient Detail'
                },
                ncyBreadcrumb: {
                    label: 'Patient Details'
                },
                params: {
                    typePatientProcess: null,
                    anchor: null,
                    processId: null,
                    processPage: null,
                    tabId: null
                },
                onEnter: ['$state', '$stateParams', '$rootScope', function ($state, $stateParams, $rootScope) {
                    if ($rootScope.showNewChart) {
                        $state.go('chart-new', {
                            id: $stateParams.id,
                            processId: $stateParams.processId,
                            processPage: $stateParams.processPage,
                            tabId: $stateParams.tabId
                        });
                    } else {
                        $state.go('chart-old', {id: $stateParams.id});
                    }
                }]
            })
            .state('chart-old', {
                parent: 'entity',
                url: '/chart-old/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'Patient Detail'
                },
                ncyBreadcrumb: {
                    label: 'Patient Details'
                },
                params: {
                    typePatientProcess: null,
                    anchor: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/detail/old/chart-detail.html',
                        controller: 'ChartDetailController',
                        controllerAs: 'vm'
                    },
                    'diagnoses@chart-old': {
                        templateUrl: 'app/entities/diagnoses/chart/old/diagnoses.html',
                        controller: 'DiagnosesController',
                        controllerAs: 'vm'
                    },
                    'care-team@chart-old': {
                        templateUrl: 'app/entities/chart-care-team/chart/old/chart-care-team.html',
                        controller: 'ChartCareTeamController',
                        controllerAs: 'vm'
                    },
                    'allergies@chart-old': {
                        templateUrl: 'app/entities/allergies/chart/old/allergies.html',
                        controller: 'AllergiesController',
                        controllerAs: 'vm'
                    },
                    'diet@chart-old': {
                        templateUrl: 'app/entities/diet/chart/old/diet.html',
                        controller: 'DietController',
                        controllerAs: 'vm'
                    },
                    'insurances@chart-old': {
                        templateUrl: 'app/entities/insurance/chart/old/insurance.html',
                        controller: 'InsuranceController',
                        controllerAs: 'vm'
                    },
                    'contacts@chart-old': {
                        templateUrl: 'app/entities/contacts/chart/old/contacts.html',
                        controller: 'ContactsController',
                        controllerAs: 'vm'
                    },
                    'patient-medications@chart-old': {
                        templateUrl: 'app/entities/patient-medication/chart/list/old/patient-medication-chart2.html',
                        controller: 'PatientMedicationChart2Controller',
                        controllerAs: 'vm'
                    },
                    'care-coordination@chart-old': {
                        templateUrl: 'app/entities/care-coordination/chart/old/care-coordination.html',
                        controller: 'CareCoordinationController',
                        controllerAs: 'vm'
                    },
                    /*app/entities/care-coordination
                        Don'T erase this comments until Lindoro gets his way around this
                    */
                    'lab-orders@chart-old': {
                        templateUrl: 'app/entities/patient-order/chart/old/patient-order.html',
                        controller: 'PatientOrderChartController',
                        controllerAs: 'vm'
                    },
                    'e-labs@chart-old': {
                        templateUrl: 'app/entities/patient-result/chart/e-labs/old/e-labs.html',
                        controller: 'ELabsController',
                        controllerAs: 'vm'
                    },
                    // 'actions@chart': {
                    //     templateUrl: 'app/entities/patient-action/chart/patient-actions.html',
                    //     controller: 'PatientActionChartController',
                    //     controllerAs: 'vm'
                    // },
                    // 'mars@chart': {
                    //     templateUrl: 'app/entities/mars/mars.html',
                    //     controller: 'MarsController',
                    //     controllerAs: 'vm'
                    // },
                    // 'schedule@chart': {
                    //     templateUrl: 'app/entities/mars/schedule.html',
                    //     controller: 'ScheduleController',
                    //     controllerAs: 'vm'
                    // },
                    // 'prn@chart': {
                    //     templateUrl: 'app/entities/mars/prn-action.html',
                    //     controller: 'PrnController',
                    //     controllerAs: 'vm'
                    // },
                    'evaluation@chart-old': {
                        templateUrl: 'app/entities/evaluation/chart/old/evaluation.html',
                        controller: 'EvaluationController',
                        controllerAs: 'vm'
                    },
                    'concurrent@chart-old': {
                        templateUrl: 'app/entities/concurrent-reviews/chart/old/concurrent-reviews.html',
                        controller: 'ConcurrentReviewsChartController',
                        controllerAs: 'vm'
                    },
                    'vitals@chart-old': {
                        templateUrl: 'app/entities/vitals/chart/old/vitals.html',
                        controller: 'VitalsChartController',
                        controllerAs: 'vm'
                    },
                    'glucose@chart-old': {
                        templateUrl: 'app/entities/glucose/chart/old/glucose.html',
                        controller: 'GlucoseChartController',
                        controllerAs: 'vm'
                    },
                    'weight@chart-old': {
                        templateUrl: 'app/entities/weight/chart/old/weight.html',
                        controller: 'WeightChartController',
                        controllerAs: 'vm'
                    },
                    'alcohol@chart-old': {
                        templateUrl: 'app/entities/alcohol/chart/old/alcohol.html',
                        controller: 'AlcoholChartController',
                        controllerAs: 'vm'
                    },
                    'drug@chart-old': {
                        templateUrl: 'app/entities/drug/chart/old/drug.html',
                        controller: 'DrugChartController',
                        controllerAs: 'vm'
                    },
                    'gSessionsDetails@chart-old': {
                        templateUrl: 'app/entities/group-session-details/chart/old/group-session-details.html',
                        controller: 'GroupSessionDetailsController',
                        controllerAs: 'vm'
                    },
                    'notes@chart-old': {
                        templateUrl: 'app/entities/chart-note/chart/old/chart-note.html',
                        controller: 'ChartNoteController',
                        controllerAs: 'vm'
                    },
                    'verifytx@chart-old': {
                        templateUrl: 'app/entities/verifytx/chart/list/old/verifytx.html',
                        controller: 'VerifytxController',
                        controllerAs: 'vm'
                    },
                    'files@chart-old': {
                        templateUrl: 'app/entities/chart-file/chart/old/chart-file.html',
                        controller: 'ChartFileController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    chart: ['$stateParams', 'Chart', function ($stateParams, Chart) {
                        return Chart.get({id: $stateParams.id}).$promise;
                    }],
                    employeeMenu: ['EmployeeMenu', 'employee', 'CoreService', function (EmployeeMenu, employee, CoreService) {
                        var menu = CoreService.getEmployeeMenu();

                        return menu ? menu : EmployeeMenu.get({id: employee.id});
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'reaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }],
                    typePatientProcess: ['$stateParams', function ($stateParams) {
                        return $stateParams.typePatientProcess;
                    }],
                    anchor: ['$stateParams', function ($stateParams) {
                        return $stateParams.anchor;
                    }],
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    params: ['$location', '$stateParams', function ($location, $stateParams) {
                        var paramsUrl = $location.search();

                        return {
                            processId: paramsUrl && paramsUrl.processId ? paramsUrl.processId : $stateParams.typePatientProcess,
                            processPage: paramsUrl && paramsUrl.processPage ? paramsUrl.processPage : $stateParams.processPage,
                            tabId: paramsUrl && paramsUrl.tabId ? paramsUrl.tabId : $stateParams.tabId
                        };
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [
                            ACTIONS.ACTION_PATIENT_CLOSE_CHART,
                            ACTIONS.ACTION_PATIENT_EDIT_ID,
                            ACTIONS.ACTION_PATIENT_EDIT_INFO,
                            ACTIONS.ACTION_MASTER_EDIT,
                            ACTIONS.ACTION_PATIENT_INSURANCE_VIEW,
                            ACTIONS.ACTION_PATIENT_CONTACTS_VIEW,
                            ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW,
                            ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW,
                            ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW,
                            ACTIONS.ACTION_PATIENT_DIETS_VIEW,
                            ACTIONS.ACTION_PATIENT_VITALS_VIEW,
                            ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW,
                            ACTIONS.ACTION_PATIENT_WEIGHT_VIEW,
                            ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW,
                            ACTIONS.ACTION_PATIENT_DRUGS_VIEW,
                            ACTIONS.ACTION_PATIENT_FILE_VIEW,
                            ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW,
                            ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW,
                            ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW,
                            ACTIONS.ACTION_PATIENT_CHART_LEVEL_CARE_HISTORY,
                            ACTIONS.ACTION_PATIENT_CHART_DISCHARGE_HISTORY,
                            ACTIONS.ACTION_PATIENT_CHART_TRACK_HISTORY,
                            ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW,
                            ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW,
                            ACTIONS.ACTION_PATIENT_ELABS_VIEW,
                            ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW,
                            ACTIONS.ACTION_PATIENT_EVALUATION_VIEW,
                            ACTIONS.ACTION_PATIENT_MEDICATION_VIEW,
                            ACTIONS.ACTION_ADD_PATIENT_ORDER_CREATE,
                            ACTIONS.ACTION_DELETE_CARE_DELIVERY
                        ];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    discontinued: [function () {
                        return null;
                    }]
                }
            })
            .state('chart-new', {
                parent: 'entity',
                url: '/chart-new/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'Patient Details'
                },
                params: {
                    typePatientProcess: null,
                    processId: null,
                    processPage: null,
                    tabId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/detail/new/chart-detail.html',
                        controller: 'ChartDetailsController',
                        controllerAs: 'vm'
                    },
                    'chart-diagnoses@chart-new': {
                        templateUrl: 'app/entities/diagnoses/chart/new/diagnoses.html',
                        controller: 'DiagnosesController',
                        controllerAs: 'vm'
                    },
                    'chart-allergies@chart-new': {
                        templateUrl: 'app/entities/allergies/chart/new/allergies.html',
                        controller: 'AllergiesController',
                        controllerAs: 'vm'
                    },
                    'chart-diets@chart-new': {
                        templateUrl: 'app/entities/diet/chart/new/diet.html',
                        controller: 'DietController',
                        controllerAs: 'vm'
                    },
                    'chart-vitals@chart-new': {
                        templateUrl: 'app/entities/vitals/chart/new/vitals.html',
                        controller: 'VitalsChartController',
                        controllerAs: 'vm'
                    },
                    'chart-glucose@chart-new': {
                        templateUrl: 'app/entities/glucose/chart/new/glucose.html',
                        controller: 'GlucoseChartController',
                        controllerAs: 'vm'
                    },
                    'chart-weight@chart-new': {
                        templateUrl: 'app/entities/weight/chart/new/weight.html',
                        controller: 'WeightChartController',
                        controllerAs: 'vm'
                    },
                    'chart-alcohol@chart-new': {
                        templateUrl: 'app/entities/alcohol/chart/new/alcohol.html',
                        controller: 'AlcoholChartController',
                        controllerAs: 'vm'
                    },
                    'chart-drug@chart-new': {
                        templateUrl: 'app/entities/drug/chart/new/drug.html',
                        controller: 'DrugChartController',
                        controllerAs: 'vm'
                    },
                    'chart-care-team@chart-new': {
                        templateUrl: 'app/entities/chart-care-team/chart/new/chart-care-team.html',
                        controller: 'ChartCareTeamController',
                        controllerAs: 'vm'
                    },
                    'chart-care-coordination@chart-new': {
                        templateUrl: 'app/entities/care-coordination/chart/new/care-coordination.html',
                        controller: 'CareCoordinationController',
                        controllerAs: 'vm'
                    },
                    'chart-urgent-issue@chart-new': {
                        templateUrl: 'app/entities/urgent-issues/chart/new/list/urgent-issues.html',
                        controller: 'UrgentIssuesController',
                        controllerAs: 'vm'
                    },
                    'chart-notes@chart-new': {
                        templateUrl: 'app/entities/chart-note/chart/new/chart-note.html',
                        controller: 'ChartNoteController',
                        controllerAs: 'vm'
                    },
                    'chart-files@chart-new': {
                        templateUrl: 'app/entities/chart-file/chart/new/chart-file.html',
                        controller: 'ChartFileController',
                        controllerAs: 'vm'
                    },
                    'chart-lab-orders@chart-new': {
                        templateUrl: 'app/entities/patient-order/chart/new/patient-order.html',
                        controller: 'PatientOrderChartController',
                        controllerAs: 'vm'
                    },
                    'chart-e-labs@chart-new': {
                        templateUrl: 'app/entities/patient-result/chart/e-labs/new/e-labs.html',
                        controller: 'ELabsController',
                        controllerAs: 'vm'
                    },
                    'chart-patient-medications@chart-new': {
                        templateUrl: 'app/entities/patient-medication/chart/list/new/patient-medication-chart2.html',
                        controller: 'PatientMedicationChart2Controller',
                        controllerAs: 'vm'
                    },
                    'chart-group-sessions@chart-new': {
                        templateUrl: 'app/entities/group-session-details/chart/new/group-session-details.html',
                        controller: 'GroupSessionDetailsController',
                        controllerAs: 'vm'
                    },
                    'chart-evaluation@chart-new': {
                        templateUrl: 'app/entities/evaluation/chart/new/evaluation.html',
                        controller: 'EvaluationController',
                        controllerAs: 'vm'
                    },
                    'chart-insurance@chart-new': {
                        templateUrl: 'app/entities/insurance/chart/new/insurance.html',
                        controller: 'InsuranceController',
                        controllerAs: 'vm'
                    },
                    'chart-contacts@chart-new': {
                        templateUrl: 'app/entities/contacts/chart/new/contacts.html',
                        controller: 'ContactsController',
                        controllerAs: 'vm'
                    },
                    'chart-concurrent-reviews@chart-new': {
                        templateUrl: 'app/entities/concurrent-reviews/chart/new/concurrent-reviews-new.html',
                        controller: 'ConcurrentReviewsChartNewController',
                        controllerAs: 'vm'
                    },
                    'chart-verifytx@chart-new': {
                        templateUrl: 'app/entities/verifytx/chart/list/new/verifytx.html',
                        controller: 'VerifytxController',
                        controllerAs: 'vm'
                    },
                    'chart-super-bill@chart-new': {
                        templateUrl: 'app/entities/super-bill/super-bill.html',
                        controller: 'SuperBillController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    chart: ['$stateParams', 'Chart', function ($stateParams, Chart) {
                        return Chart.get({id: $stateParams.id}).$promise;
                    }],
                    anchor: ['$stateParams', function ($stateParams) {
                        return $stateParams.anchor;
                    }],
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    params: ['$location', '$stateParams', function ($location, $stateParams) {
                        var paramsUrl = $location.search();

                        return {
                            processId: paramsUrl && paramsUrl.processId ? paramsUrl.processId : $stateParams.typePatientProcess,
                            processPage: paramsUrl && paramsUrl.processPage ? paramsUrl.processPage : $stateParams.processPage,
                            tabId: paramsUrl && paramsUrl.tabId ? paramsUrl.tabId : $stateParams.tabId
                        };
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [
                            ACTIONS.ACTION_PATIENT_CLOSE_CHART,
                            ACTIONS.ACTION_PATIENT_EDIT_ID,
                            ACTIONS.ACTION_PATIENT_EDIT_INFO,
                            ACTIONS.ACTION_MASTER_EDIT,
                            ACTIONS.ACTION_PATIENT_INSURANCE_VIEW,
                            ACTIONS.ACTION_PATIENT_CONTACTS_VIEW,
                            ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW,
                            ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW,
                            ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW,
                            ACTIONS.ACTION_PATIENT_DIETS_VIEW,
                            ACTIONS.ACTION_PATIENT_VITALS_VIEW,
                            ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW,
                            ACTIONS.ACTION_PATIENT_WEIGHT_VIEW,
                            ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW,
                            ACTIONS.ACTION_PATIENT_DRUGS_VIEW,
                            ACTIONS.ACTION_PATIENT_FILE_VIEW,
                            ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW,
                            ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW,
                            ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW,
                            ACTIONS.ACTION_PATIENT_CHART_LEVEL_CARE_HISTORY,
                            ACTIONS.ACTION_PATIENT_CHART_DISCHARGE_HISTORY,
                            ACTIONS.ACTION_PATIENT_CHART_TRACK_HISTORY,
                            ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW,
                            ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW,
                            ACTIONS.ACTION_PATIENT_ELABS_VIEW,
                            ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW,
                            ACTIONS.ACTION_PATIENT_EVALUATION_VIEW,
                            ACTIONS.ACTION_PATIENT_MEDICATION_VIEW,
                            ACTIONS.ACTION_ADD_PATIENT_ORDER_CREATE,
                            ACTIONS.ACTION_DELETE_CARE_DELIVERY
                        ];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    discontinued: [function () {
                        return false;
                    }]
                }
            })
            .state('charts-review', {
                parent: 'entity',
                url: '/charts-review',
                data: {
                    authorities: [],
                    pageTitle: 'Chart Review'
                },
                ncyBreadcrumb: {
                    label: 'Chart Review'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/chart-review.html',
                        controller: 'ChartReviewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: 0,
                            itemsPerPage: 18,
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    filter: ['facility', function (facility) {
                        return {
                            facilityId: facility.id,
                            status: 'CURRENT',
                            firstName: null,
                            lastName: null,
                            mrNo: null,
                            dischargeDateFrom: null,
                            dischargeDateTo: null,
                            admissionDateFrom: null,
                            admissionDateTo: null,
                            searchQuery: null
                        };
                    }]
                }
            })
            .state('charts-discharge', {
                parent: 'entity',
                url: '/charts-discharge?dischargeType',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Discharge'
                },
                ncyBreadcrumb: {
                    label: 'Charts Discharge'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-discharge.html',
                        controller: 'ChartsDischargeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    dischargeType: null
                },
                resolve: {
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    filterCharts: [function () {
                        return {
                            typeDischarge: null,
                            fromDischargeDate: null,
                            toDischargeDate: null,
                            referral: null,
                            chartSelected: null
                        }
                    }],
                    dischargeType: ['TypeDischargeType', '$stateParams', function (TypeDischargeType, $stateParams) {
                        return $stateParams.dischargeType ? TypeDischargeType.get({id: $stateParams.dischargeType}).$promise : null;
                    }]
                }
            })
            .state('charts-marketer', {
                parent: 'entity',
                url: '/charts-marketer?marketer',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Outreach'
                },
                ncyBreadcrumb: {
                    label: 'Charts Outreach'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-marketer.html',
                        controller: 'ChartsMarketerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    marketer: null
                },
                resolve: {
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    filterCharts: [function () {
                        return {
                            marketer: null,
                            fromAdmissionDate: null,
                            toAdmissionDate: null,
                            referral: null,
                            status: "CURRENT",
                            chartSelected: null
                        }
                    }],
                    marketer: ['Employee', '$stateParams', function (Employee, $stateParams) {
                        return $stateParams.marketer ? Employee.get({id: $stateParams.marketer}).$promise : null;
                    }]
                }
            })
            .state('charts-referrer', {
                parent: 'entity',
                url: '/charts-referrer?referrer',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Referrer'
                },
                ncyBreadcrumb: {
                    label: 'Charts Referrer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-referrer.html',
                        controller: 'ChartsReferrerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    referrer: null
                },
            })
            .state('charts-caseload', {
                parent: 'entity',
                url: '/charts-caseload?employee',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Care Team'
                },
                ncyBreadcrumb: {
                    label: 'Charts Care Team'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-caseload.html',
                        controller: 'ChartsCaseLoadController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    id: null
                },
                resolve: {
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    filterCharts: ['$stateParams', function ($stateParams) {
                        return {
                            employeeId: $stateParams.employee,
                            toAdmissionDate: null,
                            fromAdmissionDate: null,
                            referral: null,
                            chartSelected: null
                        }
                    }],
                    employee: ['Employee', '$stateParams', function (Employee, $stateParams) {
                        return $stateParams.employee ? Employee.get({id: $stateParams.employee}).$promise : null;
                    }]
                }
            })
            .state('charts-payer-mix-no', {
                parent: 'entity',
                url: '/charts-payer-mix-no/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Payer'
                },
                ncyBreadcrumb: {
                    label: 'Charts Payer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-payer-mix.html',
                        controller: 'ChartsPayerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    insCarrier: [function () {
                        return null;
                    }],
                    typePay: ['$stateParams', 'TypePaymentMethods', function ($stateParams, TypePaymentMethods) {
                        return TypePaymentMethods.get({id: $stateParams.id}).$promise;
                    }],
                }
            })
            .state('charts-payer-mix-ins', {
                parent: 'entity',
                url: '/charts-payer-mix-ins/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Payer'
                },
                ncyBreadcrumb: {
                    label: 'Charts Payer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-payer-mix.html',
                        controller: 'ChartsPayerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    typePay: [function () {
                        return null;
                    }],
                    insCarrier: ['$stateParams', 'InsuranceCarrier', function ($stateParams, InsuranceCarrier) {
                        return InsuranceCarrier.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('charts-payer-mix-no-ins', {
                parent: 'entity',
                url: '/charts-payer-mix-no-ins/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Charts Payer'
                },
                ncyBreadcrumb: {
                    label: 'Charts Payer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart/dashboard/charts-payer-mix.html',
                        controller: 'ChartsPayerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    typePay: [function () {
                        return null;
                    }],
                    insCarrier: [function () {
                        return null;
                    }]
                }
            })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InsuranceDialogController', InsuranceDialogController);

    InsuranceDialogController.$inject = ['InsuranceCarrier', 'InsuranceType', 'InsuranceLevel', 'InsuranceRelation', 'chart',
        'CountryState', 'InsuranceCarrierSearch', '$uibModalInstance', 'entity', 'Insurance', 'DataUtils', 'fancyboxService', '$state', 'GUIUtils', 'CoreService'];

    function InsuranceDialogController(InsuranceCarrier, InsuranceType, InsuranceLevel, InsuranceRelation, chart,
                                       CountryState, InsuranceCarrierSearch, $uibModalInstance, entity, Insurance, DataUtils, fancyboxService, $state, GUIUtils, CoreService) {
        var vm = this;

        vm.insurance = entity;
        vm.chart = chart;
        vm.patient = vm.chart.patient;
        vm.gender = GUIUtils.getGender();
        vm.showDelete = false;
        //Functions
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getInsuranceCarrier = getInsuranceCarrier;
        vm.openInsuranceCalendar = openInsuranceCalendar;
        vm.carrierTagTransform = carrierTagTransform;
        vm.confirmDelete = confirmDelete;

        //Variables
        vm.insuranceCarriers = [];
        vm.insuranceTypes = InsuranceType.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.insuranceLevels = InsuranceLevel.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.insuranceRelations = InsuranceRelation.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.datePickerOpenStatus = {};
        vm.carriers = [];
        vm.form = {};
        vm.states = CountryState.query();
        vm.currentState = $state.current.name;
        vm.datePickerOpenStatus = {};

        vm.datePickerOpenStatus.insuranceDob = [false, false];

        function openInsuranceCalendar(index) {
            vm.datePickerOpenStatus.insuranceDob[index] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.insurance.chart = chart;

            if (vm.insurance.id !== null) {
                Insurance.update(vm.insurance, onSaveSuccess, onSaveError);
            } else {
                Insurance.save(vm.insurance, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dob = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getInsuranceCarrier(query) {
            if (query) {
                if (query.length > 2) {
                    InsuranceCarrierSearch.query({
                        query: query
                    }, function (result) {
                        if (result.length == 0) {
                            vm.carriers = [carrierTagTransform(query)];
                        } else {
                            vm.carriers = result;
                        }
                    });
                }
            }
        }

        function carrierTagTransform(newTag) {
            return {
                id: null,
                acctno: newTag,
                name: newTag
            };
        }

        function confirmDelete() {
            Insurance.delete({id: vm.insurance.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ShiftDialogController', ShiftDialogController);

    ShiftDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Shift',
        'Building', 'TypeLevelCare', 'ShiftType', 'Employee', 'ShiftChart', 'Chart', 'ShiftNote',
        'CoreService', 'toastr'];

    function ShiftDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Shift,
                                   Building, TypeLevelCare, ShiftType, Employee, ShiftChart, Chart, ShiftNote,
                                   CoreService, toastr) {
        var vm = this;


        vm.entity = entity;
        vm.facility = CoreService.getCurrentFacility();
        vm.shift = vm.entity;
        vm.originalEntity = angular.copy(vm.entity);
        vm.datePickerOpenStatus = {};
        vm.shiftChartPatients = [];
        vm.loadedPatientsByShift = [];
        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };
        vm.newPatients = [];
        vm.newPatientsConverted = [];
        vm.addedPatients = [];
        vm.isShiftEmployee = false;
        vm.isShiftReviewer = false;
        vm.visibilityToggleTable = false;
        vm.shiftEmployeeSignature = '';
        vm.shiftEmployeeSignaturePin = null;
        vm.shiftReviewerSignature = '';
        vm.shiftReviewerSignaturePin = '';
        vm.customNote = "";
        vm.startTime = vm.shift.startTime;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.buildings = Building.byFacility({id: CoreService.getCurrentFacility().id});
        vm.typelevelcares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.shifttypes = ShiftType.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.corporation = CoreService.getCorporation();
        vm.employees = getEmployees();
        vm.employee = CoreService.getCurrentEmployee();
        vm.facilityId = vm.facility.id;
        vm.ids = [];

        //Functions

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saveClose = saveClose;
        vm.loadPatientDetails = loadPatientDetails;
        vm.addPatients = addPatients;
        vm.present = present;
        vm.patientsForm = patientsForm;
        vm.listCreation = listCreation;
        vm.isPinCorrect = isPinCorrect;
        vm.editNote = editNote;
        vm.checkEditableNotes = checkEditableNotes;
        vm.getEditableNotes = getEditableNotes;
        vm.showLastEditableNote = showLastEditableNote;
        vm.cancelNewNote = cancelNewNote;
        vm.crudNote = crudNote;
        vm.addNotesToPatients = addNotesToPatients;
        vm.loadPage = loadPage;
        vm.saveChangedNote = saveChangedNote;
        vm.switchingBack = switchingBack;
        vm.newNoteCreation = newNoteCreation;
        vm.deletePatient = deletePatient;
        vm.deleteNote = deleteNote;
        vm.saveReviewers = saveReviewers;
        vm.closeAllNotes = closeAllNotes;
        vm.load = load;
        vm.removeFromReviewers = removeFromReviewers;
        vm.getEmployees = getEmployees;

        isCurrentEmployee();
        isCurrentReviewer();
        validatingCurrentUser();
        loadPatientDetails();

        function checkEditableNotes(item) {
            var editableNotes = 0;

            if (item && item.shiftNotes) {
                item.shiftNotes.forEach(function (note) {
                    if (!note.delStatus) {
                        editableNotes++;
                    }
                });
            }

            return editableNotes > 0
        }

        function getEditableNotes(item) {
            var editableNotes = 0;
            item.shiftNotes.forEach(function (note) {
                if (!note.delStatus) {
                    editableNotes++;
                }
            });
            return editableNotes
        }

        function showLastEditableNote(item) {
            var lastNote = [];
            for (var i = 0; i < item.shiftNotes.length; i++) {
                if (!item.shiftNotes[i].delStatus) {
                    lastNote.push(item.shiftNotes[i].note)
                }
            }
            return lastNote[0];
        }

        function cancelNewNote(item) {
            item.addCustomNote = true;
            item.closeCustomNote = false;
            item.editVisibility = false;
        }


        function validatingCurrentUser() {
            if (vm.shift.shiftEmployeeSignature) {
                vm.savedWithSign = true;
            } else {
                vm.savedWithSign = false;
            }

            if (vm.isShiftEmployee && !vm.isShiftReviewer) {
                vm.showPages = true;
            } else if (vm.isShiftEmployee === false && vm.isShiftReviewer) {
                vm.showPages = false;
            }
        }

        //Validation to remove reviewer if same as shiftEmployee sign
        function removeFromReviewers() {
            if (vm.shift.shiftEmployee && vm.reviewers) {
                var removeIndex = vm.reviewers.map(function (item) {
                    return item.id;
                }).indexOf(vm.shift.shiftEmployee.id);
                vm.reviewers.splice(removeIndex, 1);
            }
        }


        //function to retrieve all employees with appropriate credentials
        var request = {credentials: ['LCSW', 'LMHC', 'CAP']};
        vm.reviewers = vm.employees;


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            if (vm.shift.id) {
                $scope.$emit('bluebookApp:shiftUpdate', vm.shift);
                $uibModalInstance.close(vm.shift);
            } else {
                $uibModalInstance.dismiss('cancel');
            }
        }

        //SAVING AND UPDATING SHIFT

        function save() {
            vm.visibilityToggleTable = false;
            vm.isSaving = true;
            if (vm.shift.id) {
                load();
                listCreation();
                vm.savedDate = vm.shift.dateCreated;
                if (angular.equals(vm.shift.building, vm.originalEntity.building) &&
                    angular.equals(vm.shift.description, vm.originalEntity.description) &&
                    angular.equals(vm.shift.dateCreated, vm.originalEntity.dateCreated) &&
                    angular.equals(vm.shift.startTime, vm.originalEntity.startTime) &&
                    angular.equals(vm.shift.endTime, vm.originalEntity.endTime) &&
                    angular.equals(vm.shift.shiftType, vm.originalEntity.shiftType) &&
                    angular.equals(vm.shift.typeLevelCares, vm.originalEntity.typeLevelCares) &&
                    angular.equals(vm.shift.employees, vm.originalEntity.employees) &&
                    angular.equals(vm.shift.shiftEmployeeSignature, vm.originalEntity.shiftEmployeeSignature) &&
                    angular.equals(vm.shift.shiftReviewerSignature, vm.originalEntity.shiftReviewerSignature) &&
                    angular.equals(vm.shift.shiftReviewers, vm.originalEntity.shiftReviewers)) {
                    vm.isSaving = false;
                } else {
                    vm.shift.lastModifiedEmployee = vm.employee;
                    Shift.update(vm.shift, onSaveSuccess, onSaveError);
                    vm.originalEntity = angular.copy(vm.shift);
                    toastr.success('The shift was updated', 'Success');
                }

                loadExistingShiftChartToView();
            } else {
                vm.savedDate = vm.shift.dateCreated;
                vm.shift.status = "Pending";
                vm.shift.lastModifiedEmployee = vm.employee;
                Shift.save(vm.shift, onSaveSuccess, onSaveError);
                toastr.success('A new shift is created', 'Success');
            }
        }

        function onSaveSuccess(result) {

            $scope.$emit('bluebookApp:shiftUpdate', result);
            if (vm.shift.shiftReviewerSignature) {
                $uibModalInstance.close(result);
            }

            vm.shift = result;
            vm.entity = angular.copy(vm.shift);
            vm.shift.dateCreated = vm.savedDate;
            loadPatientDetails();
            vm.isSaving = false;
            isCurrentEmployee();
            isCurrentReviewer();
            if (vm.isShiftEmployee && !vm.isShiftReviewer) {
                vm.showPages = true;
            } else if (vm.isShiftEmployee === false && vm.isShiftReviewer) {
                vm.showPages = false;
            }
            vm.addedPatients = [];

            vm.originalEntity = angular.copy(vm.shift);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        // Date picker options
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        //Functions to check if current Employee is a reviewer and/or a shiftEmployee

        function isCurrentReviewer() {

            if (vm.shift.shiftReviewers) {
                vm.shift.shiftReviewers.forEach(function (item) {
                    if (item.id === vm.employee.id) {
                        vm.isShiftReviewer = true;
                        vm.reviewer = item;
                    }
                })
            }
        }

        function isCurrentEmployee() {
            if (vm.shift.id) {
                vm.isShiftEmployee = false;
                vm.isShiftReviewer = false;
                vm.showPages = false;
                vm.shift.employees.forEach(function (item) {
                    if (item.id == vm.employee.id) {
                        vm.isShiftEmployee = true;
                        vm.showPages = false;
                    }
                })
            }
        }

        //Function to Load SIGNATURES into variables used in HTML and assign Employee

        function load() {
            if (vm.shift.shiftEmployeeSignature && !vm.shiftEmployeeSignature) {
                vm.shiftEmployeeSignature = vm.shift.shiftEmployeeSignature.signature;
                vm.shiftEmployeeSignatureAgree = true;
            }
            if (vm.shift.shiftReviewerSignature) {
                vm.shiftReviewerSignature = vm.shift.shiftReviewerSignature.signature;
            }
            if (vm.shift.shiftEmployeeSignature) {
                if (vm.shift.shiftEmployeeSignature.signature === "Ignored field" && vm.shiftEmployeeSignature) {
                    vm.shift.shiftEmployeeSignature.signature = vm.shiftEmployeeSignature;
                }
            }
            if (!vm.shift.shiftEmployee && !vm.shift.shiftEmployeeSignature && vm.isShiftEmployee && !vm.isSaving) {
                vm.shift.shiftEmployee = vm.employee;
            } else if (vm.shift.shiftEmployee && !vm.shift.shiftEmployeeSignature && vm.isShiftEmployee && vm.isSaving) {
                vm.shift.shiftEmployee = null;
            }
        }

        //FUNCTION TO SAVE SIGNATURES

        function isPinCorrect() {
            if (vm.shiftEmployeeSignaturePin && !vm.shiftReviewerSignaturePin && !vm.shiftReviewerSignature) {
                Employee.employeeIsValidPin({pin: vm.shiftEmployeeSignaturePin}, function (result) {
                    if (result.value) {
                        vm.shift.shiftEmployeeSignature = vm.employee.signature;
                        vm.savedWithSign = true;
                    } else {
                        toastr.error('The pin was Incorrect', 'Error');
                        vm.shiftEmployeeSignaturePin = false;
                    }
                }).$promise.then(function (data) {
                    onSignatureSave();
                });
            } else if (vm.shiftEmployeeSignature && !vm.shiftReviewerSignaturePin && !vm.shiftReviewerSignature) {
                Shift.sign(
                    {
                        shiftId: vm.shift.id,
                        type: "EMPLOYEE",
                        signature: vm.shiftEmployeeSignature
                    }).$promise.then(function (data) {
                    vm.shift.shiftEmployeeSignature = data.shiftEmployeeSignature;
                    vm.shiftEmployeeSignature = data.shiftEmployeeSignature.signature;
                    vm.shift.shiftEmployee = data.shiftEmployee;
                });

                vm.savedWithSign = true;
            } else if (vm.shiftReviewerSignaturePin && vm.shiftEmployeeSignature) {
                Employee.employeeIsValidPin({pin: vm.shiftReviewerSignaturePin}, function (result) {
                    if (result.value) {
                        vm.shift.shiftReviewerSignature = vm.employee.signature;
                        vm.savedWithSign = true;
                    } else {
                        toastr.error('The pin was Incorrect', 'Error');
                        vm.shiftReviewerSignaturePin = false;
                    }
                }).$promise.then(function (data) {
                    onSignatureSave();
                });
            } else if (vm.shiftReviewerSignature && vm.shiftEmployeeSignature) {
                Shift.sign(
                    {
                        shiftId: vm.shift.id,
                        type: "REVIEWER",
                        signature: vm.shiftReviewerSignature
                    }).$promise.then(function (data) {
                    vm.shift.shiftReviewerSignature = data.shiftReviewerSignature;
                    vm.shiftReviewerSignature = data.shiftReviewerSignature.signature;
                    $scope.$emit('bluebookApp:shiftUpdate', data);
                    $uibModalInstance.close(data);

                });
                vm.savedWithSign = true;
            }
        }

        function onSignatureSave() {
            if (vm.shiftEmployeeSignaturePin && !vm.shiftReviewerSignaturePin) {
                Shift.sign(
                    {
                        shiftId: vm.shift.id,
                        type: "EMPLOYEE",
                        signature: vm.employee.signature.signature
                    }).$promise.then(function (data) {
                    vm.shift.shiftEmployeeSignature = data.shiftEmployeeSignature;
                    vm.shiftEmployeeSignature = data.shiftEmployeeSignature.signature;
                    vm.shift.shiftEmployee = data.shiftEmployee;
                });
            } else if (vm.shiftReviewerSignaturePin && !vm.shiftEmployeeSignaturePin) {
                Shift.sign(
                    {
                        shiftId: vm.shift.id,
                        type: "REVIEWER",
                        signature: vm.employee.signature.signature
                    }).$promise.then(function (data) {
                    vm.shift.shiftReviewerSignature = data.shiftReviewerSignature;
                    vm.shiftReviewerSignature = data.shiftReviewerSignature.signature;
                    $scope.$emit('bluebookApp:shiftUpdate', data);
                    $uibModalInstance.close(data);

                });
            }

        }


        //FUNCTION TO CLOSE ON SAVING

        function saveClose() {
            if (vm.shift.id) {
                vm.shift.lastModifiedEmployee = vm.employee;
                Shift.update(vm.shift, onSaveSuccessClose, onSaveErrorClose);
            } else {
                vm.shift.status = 'Pending';
                vm.shift.lastModifiedEmployee = vm.employee;
                Shift.save(vm.shift, onSaveSuccessClose, onSaveErrorClose);
            }
        }

        function onSaveSuccessClose(result1) {
            $scope.$emit('bluebookApp:shiftUpdate', result1);
            $uibModalInstance.close(result1);
        }

        function onSaveErrorClose(result) {
            vm.isSaving = false;
        }


        //ALL NOTE RELATED FUNCTIONS

        function deleteNote(item) {
            if (item) {
                ShiftNote.delete(item, onUpdateSuccess, onSaveError);
                toastr.success('Note deleted', 'Success');
            }
        }

        function crudNote(item) {
            if (item) {
                vm.addPatient = false;
                vm.currentChart = [];
                vm.thisChartNotes = [];
                vm.currentChart = item;
                vm.thisChartNotes = item.shiftNotes;
                vm.visibilityToggleTable = true;
                vm.thisChartNotes.forEach(function (value) {
                    value.editVisibility = false;
                })
            }
        }

        function newNoteCreation(item) {
            vm.addPatient = false;
            if (!vm.savedWithSign) {
                if (item.addCustomNote && !item.closeCustomNote) {
                    item.addCustomNote = false;
                    item.closeCustomNote = true;
                    item.note = "";
                } else if (!item.addCustomNote && item.closeCustomNote) {
                    if (item.note) {
                        vm.convertedNote = {
                            shiftChart: null,
                            note: item.note
                        };
                        item.shiftNotes.push(vm.convertedNote);
                        ShiftChart.update(item, onUpdateSuccess, onSaveError);
                        toastr.success('Note added', 'Success');
                    }
                    item.addCustomNote = true;
                    item.closeCustomNote = false;
                }
            }
        }

        function editNote(item) {
            if (item.note) {
                item.editVisibility = true;
            }
        }

        function addNotesToPatients() {
            if (vm.customNote) {
                vm.convertedNote = {
                    shiftChart: null,
                    note: vm.customNote
                };
                vm.currentChart.shift = vm.originalEntity;
                vm.currentChart.shiftNotes.push(vm.convertedNote);
                ShiftChart.update(vm.currentChart, onUpdateSuccess, onSaveError);
                toastr.success('Note added', 'Success');
                vm.thisChartNotes = [];
                vm.customNote = "";
            }
        }

        function saveChangedNote(item) {
            item.editVisibility = false;
            vm.currentChart.shiftNotes.forEach(function (value) {
                if (value.id === item.id) {
                    value.note = item.note;
                }
            });
            vm.currentChart.shift = vm.originalEntity;
            ShiftChart.update(vm.currentChart, onUpdateSuccess, onSaveError);
            toastr.success('Note Updated', 'Success');
            vm.thisChartNotes = [];
        }

        function switchingBack() {
            vm.visibilityToggleTable = false;
            loadPatientDetails();
        }

        function closeAllNotes() {
            if (vm.shift.id) {
                if (vm.shiftChartPatients) {
                    vm.shiftChartPatients.forEach(function (value) {
                        if (value.closeCustomNote) {
                            value.closeCustomNote = false;
                            value.addCustomNote = true;
                        }
                    })
                }
            }

        }

        // ALL PATIENT RELATED FUNCTIONS

        function loadPage() {
            loadPatientDetails();
        }

        function deletePatient(item) {
            if (item) {
                vm.addPatient = false;
                vm.addedPatients = [];
                ShiftChart.delete({id: item.id}, onPatientDeleteSuccess, onSaveError);
            }
        }

        function onPatientDeleteSuccess(result) {
            toastr.success('Patient deleted from shift', 'Success');
            loadPatientDetails();
        }

        //Function to add patients to chart

        function addPatients() {
            if (vm.addedPatients.length) {
                vm.addedPatients.forEach(function (item) {
                    vm.shiftChartPatients.push(item);
                });
                vm.addPatient = !vm.addPatient;
                vm.newPatients = [];
                listCreation();
                Shift.update(vm.shift, onSaveSuccess, onSaveError);
                toastr.success('Patients added to shift', 'Success');
            }
        }

        //Load already added patients from shift chart by shift Id

        function loadPatientDetails() {
            if (vm.shift.id) {
                ShiftChart.pageableByFindAllShiftCharts({
                    id: vm.shift.id,
                    page: vm.page - 1,
                    size: vm.itemsPerPage
                }, function (result, headers) {
                    if (result) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.shift.shiftCharts = result;
                        vm.loadedPatientsByShift = result;
                        if (vm.loadedPatientsByShift.length > 0) {
                            vm.loadedPatientsByShift.forEach(function (value) {
                                value.addCustomNote = true;
                                value.closeCustomNote = false;
                                if (value.shiftNotes && value.shiftNotes.length > 0) {
                                    value.shiftNotes.sort(function (a, b) {
                                        return new Date(b.createdDate) - new Date(a.createdDate);
                                    });
                                }
                            })
                        } else {
                            vm.shiftChartPatients = [];
                        }
                    }
                }).$promise.then(function (data) {
                    loadExistingShiftChartToView();
                });
            }
        }

        //Function to add shifts charts to save json
        function listCreation() {
            if (vm.shift.id) {
                vm.shift.shiftCharts = vm.shiftChartPatients;
            }
        }

        function loadExistingShiftChartToView() {
            if (vm.loadedPatientsByShift.length !== 0) {
                vm.shiftChartPatients = [];
                vm.shiftChartPatients = vm.loadedPatientsByShift;
            }
        }

        // Function to get all current patients and add to newPatients
        function patientsForm() {
            vm.addPatient = !vm.addPatient;
            vm.newPatientsConverted = [];
            vm.newPatients = [];

            Chart.notInShift({shiftId: vm.shift.id}, function (result) {
                if (result) {
                    vm.newPatients = result;
                }
                vm.newPatients.forEach(function (value) {
                    vm.patient = {
                        chart: value,
                        shift: null,
                        headCount: false
                    };
                    vm.newPatientsConverted.push(vm.patient);
                });
            })
        }

        function present(item) {
            vm.shiftChart = item;
            vm.shiftChart.shiftNotes = [];
            ShiftChart.update(vm.shiftChart, onPresentUpdateSuccess, onSaveError);
            toastr.success('Patient updated', 'Success');
        }

        function onPresentUpdateSuccess() {
            loadPatientDetails();
        }

        function onUpdateSuccess(result) {
            if (vm.currentChart) {
                ShiftChart.findOneById({id: vm.currentChart.id}, function (result1) {
                    if (result1) {
                        vm.currentChart = result1;
                        vm.thisChartNotes = vm.currentChart.shiftNotes;
                    }
                });
                vm.lastItemNo = vm.currentChart.shiftNotes.length;
            }
            loadPatientDetails();
        }

        //Function saving Reviewers

        function saveReviewers() {
            Shift.update(vm.shift, onSaveSuccess, onSaveError);
            toastr.success('Reviewers assigned', 'Success');
            vm.originalEntity = angular.copy(vm.shift);
            $scope.$emit('bluebookApp:shiftUpdate', vm.shift);
            $uibModalInstance.close(vm.shift);
        }

        function getEmployees(query) {
            var filter = {page: 0, size: 20, query: query, facilityId: CoreService.getCurrentFacility().id};

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('super-bill', {
            parent: 'entity',
            url: '/super-bill',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'SuperBills'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/superbill/super-bills.html',
                    controller: 'SuperBillController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('super-bill-detail', {
            parent: 'entity',
            url: '/super-bill/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'SuperBill'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/superbill/super-bill-detail.html',
                    controller: 'SuperBillDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'SuperBill', function($stateParams, SuperBill) {
                    return SuperBill.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'superBill',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('super-bill-detail.edit', {
            parent: 'super-bill-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/superbill/super-bill-dialog.html',
                    controller: 'SuperBillDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SuperBill', function(SuperBill) {
                            return SuperBill.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('super-bill.new', {
            parent: 'super-bill',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/superbill/super-bill-dialog.html',
                    controller: 'SuperBillDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                dateOfService: null,
                                billDate: null,
                                status: null,
                                comment: null,
                                amountPaid: null,
                                balance: null,
                                providerFirstName: null,
                                providerLastName: null,
                                providerNpi: null,
                                placeOfSvc: null,
                                priorAuthorizationNumber: null,
                                otherProvider: null,
                                insuranceAddress: null,
                                insuranceAddress2: null,
                                insuranceZipCode: null,
                                insuranceCity: null,
                                insurancePolicyNumber: null,
                                insuranceGroupNumber: null,
                                insuranceGroupName: null,
                                insurancePlanNumber: null,
                                insuranceLastName: null,
                                insuranceFirstName: null,
                                insuranceMiddleInitial: null,
                                insuranceDob: null,
                                insuranceGender: null,
                                insurancePhone: null,
                                insuranceEmployer: null,
                                insuranceOrder: null,
                                insuranceSocialSecurity: null,
                                insurancePreCertPhone: null,
                                insuranceCountryStateId: null,
                                insuranceFrontPictureId: null,
                                insuranceFrontPictureOriginalId: null,
                                insuranceBackPictureId: null,
                                insuranceBackPictureOriginalId: null,
                                insuranceRxBin: null,
                                insurancePcn: null,
                                insuranceMemberId: null,
                                n: null,
                                nn: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('super-bill', null, { reload: 'super-bill' });
                }, function() {
                    $state.go('super-bill');
                });
            }]
        })
        .state('super-bill.edit', {
            parent: 'super-bill',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/superbill/super-bill-dialog.html',
                    controller: 'SuperBillDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SuperBill', function(SuperBill) {
                            return SuperBill.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('super-bill', null, { reload: 'super-bill' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('super-bill.delete', {
            parent: 'super-bill',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/superbill/super-bill-delete-dialog.html',
                    controller: 'SuperBillDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SuperBill', function(SuperBill) {
                            return SuperBill.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('super-bill', null, { reload: 'super-bill' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

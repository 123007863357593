(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientMedication', PatientMedication);

    PatientMedication.$inject = ['$resource', 'DateUtils', 'DataUtils', 'CoreService'];

    function PatientMedication($resource, DateUtils, DataUtils, CoreService) {
        var resourceUrl = 'api/patient-medications/:id';
        var facility = CoreService.getCurrentFacility();

        function transformCountResponse(data) {
            if (data) {
                return {count: data};
            }
            return {count: 0};
        }

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        if (data.startDate) {
                            data.startDate = new Date(DateUtils.convertDateTimeFromServer(data.startDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.endDate) {
                            data.endDate = new Date(DateUtils.convertDateTimeFromServer(data.endDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.createdDate) {
                            data.createdDate = new Date(DateUtils.convertDateTimeFromServer(data.createdDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.discontinueDate) {
                            data.discontinueDate = new Date(DateUtils.convertDateTimeFromServer(data.discontinueDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.signedDate) {
                            data.signedDate = new Date(DateUtils.convertDateTimeFromServer(data.signedDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.faxedTo) {
                            data.faxedTo.createdDate = new Date(DateUtils.convertDateTimeFromServer(data.faxedTo.createdDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.signature) {
                            data.signature.createdDate = new Date(DateUtils.convertDateTimeFromServer(data.signature.createdDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.discontinueSignature) {
                            data.discontinueSignature.createdDate = new Date(DateUtils.convertDateTimeFromServer(data.discontinueSignature.createdDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.discontinueDate) {
                            data.discontinueDate = new Date(DateUtils.convertDateTimeFromServer(data.discontinueDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if(data.frequencies){
                            _.forEach(data.frequencies, function (frequency) {
                                if(frequency.schedules){
                                    _.forEach(frequency.schedules, function (schedule) {
                                        schedule.date = new Date(DateUtils.convertDateTimeFromServer(schedule.date)
                                            .toLocaleString("en-US", {timeZone: facility.timeZone}));
                                    });
                                }
                            });
                        }
                    }

                    return data;
                }
            },
            'findAllByFilter': {
                url: 'api/patient-medications/filter', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        _.forEach(data, function (pm) {
                            if (pm.startDate) {
                                pm.startDate = new Date(DateUtils.convertDateTimeFromServer(pm.startDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (pm.endDate) {
                                pm.endDate = new Date(DateUtils.convertDateTimeFromServer(pm.endDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (pm.createdDate) {
                                pm.createdDate = new Date(DateUtils.convertDateTimeFromServer(pm.createdDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (pm.discontinueDate) {
                                pm.discontinueDate = new Date(DateUtils.convertDateTimeFromServer(pm.discontinueDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (pm.signedDate) {
                                pm.signedDate = new Date(DateUtils.convertDateTimeFromServer(pm.signedDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (pm.faxedToCreatedDate) {
                                pm.faxedToCreatedDate = new Date(DateUtils.convertDateTimeFromServer(pm.faxedToCreatedDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }
                        });
                    }

                    return data;
                }
            },
            'unsigned': {url: "api/patient-medications/unsigned/:id", method: 'GET', isArray: true},
            'all-unsigned': {url: "api/patient-medications/unsigned/all/:id", method: 'GET', isArray: true},
            'sign': {
                url: 'api/patient-medications/sign', method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.signatureDate = DateUtils.convertLocalDateFromServer(data.signatureDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    }
                    return data;
                }
            },
            'patientMedicationByTake': {url: 'api/patient-medication-by-take/:id', method: 'GET'},
            'patientMedicationById': {url: 'api/patient-medication/generate-pres-pdf/:id', method: 'GET'},
            'discontinue2': {url: 'api/patient-medications/discontinue2', method: 'POST'},
            'create': {url: 'api/patient-medications/create', method: 'POST', isArray: false},
            'fax': {url: 'api/patient-medications/fax', method: 'POST', isArray: true},
            'getAllMedicationFrequency': {
                'url': "api/patient-medications/medication-frequency",
                method: 'GET',
                isArray: true
            },
            'note': {
                url: "api/patient-medications/note", method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.notedBy = DateUtils.convertLocalDateToServer(copy.notedDate);

                    return angular.toJson(copy);
                }
            },
            'marsAsNeeded': {url: 'api/patient-medications/mars/as-needed/by-facility', method: 'GET', isArray: true},
            'signObserved': {
                url: 'api/patient-medication-takes/sign-observed', method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.signatureDate = DateUtils.convertLocalDateFromServer(data.signatureDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    }
                    return data;
                }
            },
            'noting': {'url': 'api/patient-medications/noting', method: 'GET', isArray: true},
            'findAllPhysicianReview': {url: 'api/patient-medications/physician-review', method: 'GET', isArray: true},
            'signMany': {url: 'api/patient-medications/sign-many', method: 'POST'},
            'mars': {url: 'api/patient-medications/mars', method: 'GET', isArray: true},
            'marsTakes': {
                url: 'api/patient-medications/mars/takes',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        _.forEach(data, function (take) {
                            if (take.scheduleDate) {
                                take.scheduleDate = new Date(DateUtils.convertDateTimeFromServer(take.scheduleDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (take.administerDate) {
                                take.administerDate = new Date(DateUtils.convertDateTimeFromServer(take.administerDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }

                            if (take.lastScheduleDate) {
                                take.lastScheduleDate = new Date(DateUtils.convertDateTimeFromServer(take.lastScheduleDate)
                                    .toLocaleString("en-US", {timeZone: facility.timeZone}));
                            }
                        });
                    }

                    return data;
                }
            },
            'match': {url: 'api/patient-medications/match/:id', method: 'GET', isArray: true},
            'addAsNeeded': {url: 'api/patient-medications/add-as-needed', method: 'POST', isArray: true},
            'findAllFrequencies': {url: 'api/patient-medications/frequencies/:id', method: 'GET', isArray: true},
            'countByChartAndNotedIsFalse': {
                url: 'api/patient-medications/count-all-by-noted-false/:id',
                method: 'GET',
                isArray: false,
                transformResponse: transformCountResponse
            },
            'changeOnlyChart': {url: 'api/patient-medications/change-only-in-chart', method: 'PUT'},
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DietDialogController', DietDialogController);

    DietDialogController.$inject = ['$timeout', '$scope', 'entity', '$uibModalInstance', 'Diet',
        'TypeFoodDiets', 'CoreService'];

    function DietDialogController($timeout, $scope, entity, $uibModalInstance, Diet,
                                  TypeFoodDiets, CoreService) {
        var vm = this;

        vm.diet = entity;
        vm.chart = vm.diet.chart;
        vm.typeFoodDiets = TypeFoodDiets.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.form = {};
        vm.showDelete = false;

        vm.clear = clear;
        vm.save = save;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};

        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.confirmDelete = confirmDelete;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.diet.id !== null) {
                Diet.update(vm.diet, onSaveSuccess, onSaveError);
            } else {
                Diet.save(vm.diet, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:dietUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.diet.startDate;
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.diet.endDate;
        }

        function confirmDelete () {
            Diet.delete({id: vm.diet.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BedSheetStatusDialogController', BedSheetStatusDialogController);

    BedSheetStatusDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Bed', 'CoreService', 'bed', 'toastr'];

    function BedSheetStatusDialogController($timeout, $scope, $stateParams, $uibModalInstance, Bed, CoreService, bed, toastr) {
        var vm = this;

        vm.status = ['ACTIVE', 'CLEANING', 'MAINTENANCE'];
        vm.bed = bed;
        vm.bedStatus = vm.bed.status;

        vm.clear = clear;
        vm.save = save;

        function save() {
            if (vm.bedStatus !== vm.bed.status) {

                Bed.changeStatus({
                    bedId: vm.bed.id,
                    bedStatus: vm.bedStatus
                }, function (bed) {
                    if (bed != null) {
                        toastr.success('Status bed changed successfully');
                    } else {
                        toastr.error('Status bed changed fail');
                    }
                    $uibModalInstance.close();
                });
            } else {
                vm.clear()
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationAllergyController', EvaluationAllergyController);

    EvaluationAllergyController.$inject = ['$scope', '$state', 'EvaluationAllergy', 'entity', '$uibModal'];

    function EvaluationAllergyController ($scope, $state, EvaluationAllergy, entity, $uibModal) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationAllergies = [];

        vm.loadAll = loadAll;
        vm.add = add;
        vm.edit = edit;
        vm.history = history;

        loadAll();

        function loadAll() {
            if (!vm.evaluation.allergies) {
                return;
            }
            EvaluationAllergy.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationAllergies = result;
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/allergies/update/allergies-dialog.html',
                controller: 'EvaluationAllergyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            evaluation: {id: vm.evaluation.id},
                            allergy: {
                                name: null,
                                reaction: null,
                                treatment: null,
                                id: null,
                                active: true,
                                chart: vm.evaluation.chart
                            }
                        }
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function edit(evaluationAllergy) {
            $uibModal.open({
                templateUrl: 'app/entities/allergies/update/allergies-dialog.html',
                controller: 'AllergiesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Allergies', function (Allergies) {
                        return Allergies.get({id: evaluationAllergy.allergyId}).$promise;
                    }]
                }
            }).result.then(function (result) {
                if (result.id && !result.active) {
                    EvaluationAllergy.delete({id: evaluationAllergy.id}, function () {
                        loadAll();
                    });
                } else {
                    loadAll();
                }
            }, function () {
                loadAll();
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-allergy/history/evaluation-allergies-history.html',
                controller: 'EvaluationAllergiesHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.evaluation.chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }
    }
})();

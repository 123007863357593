(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DietController', DietController);

    DietController.$inject = ['chart', 'Diet', 'CoreService', '$sessionStorage', '$uibModal', 'access', 'ACTIONS',
        'Auth', 'ConfirmationService'];

    function DietController(chart, Diet, CoreService, $sessionStorage, $uibModal, access, ACTIONS,
                            Auth, ConfirmationService) {
        var vm = this;

        vm.chart = chart;
        vm.currentDate = new Date();
        vm.activeTab = $sessionStorage.activePatientTabDiets ? $sessionStorage.activePatientTabDiets.activeTab : 0;
        vm.name = chart.lastName + " " + chart.firstName;
        vm.mrNumber = chart.mrNo;

        vm.toggleItem = toggleItem;
        vm.keepActiveTab = keepActiveTab;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;

        loadData();

        function loadData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_DIETS_VIEW, access)) {

                return;
            }

            vm.dietsActive = Diet.findAllActiveDietsByChart({id: chart.id});
            vm.dietsInactive = Diet.findAllInactiveDietsByChart({id: chart.id});
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/diet/update/diet-dialog.html',
                controller: 'DietDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            id: null,
                            endDate: null,
                            startDate: null,
                            chart: chart
                        };
                    }
                }
            }).result.then(function () {
                loadData()
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/diet/update/diet-dialog.html',
                controller: 'DietDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Diet', function (Diet) {
                        return Diet.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadData()
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Diet').result.then(function () {
                Diet.delete({id: id}, function () {
                    loadData();
                });
            })
        }

        function toggleItem() {
            CoreService.togglePanel('diet');
        }

        function keepActiveTab (index) {
            $sessionStorage.activePatientTabDiets = {activeTab: index};
            vm.activeTab = index;
        }
    }
})();

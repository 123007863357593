(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('bluebookApp')
        .constant('VERSION', "1.0")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('APP', {
	"APP_NAME": "Bluebook",
	"APP_SUFFIX_NAME": "X",
	"APP_SHORT_NAME": "DX"
})
        .constant('TAB', {
	"BASIC_INFORMATION": 0,
	"LAB_ORDERS": 1,
	"E_LAB": 2,
	"MEDICATIONS": 3,
	"GROUP_SESSION": 4,
	"EVALUATION": 5,
	"SUPER_BILL": 6,
	"CONCURRENT_REVIEW": 15
})
        .constant('GROUP_SESSION_DETAIL_PROGRESS', {
	"IN_PROCESS": "InProcess",
	"PENDING_REVIEW": "PendingReview",
	"COMPLETED": "Completed"
})
        .constant('DATA', {
	"SIGNATORIES": {
		"GUARANTOR": "GUARANTOR",
		"PATIENT": "PATIENT"
	},
	"RELATIONSHIP": {
		"GUARANTOR": "Guarantor",
		"GUARANTOR_ID": 5
	}
})
        .constant('IDLE', {
	"idle": 870,
	"timeout": 30
})
        .constant('FREQUENCIES', [
	{
		"value": "Week_Days_Only",
		"text": "Week Days Only"
	},
	{
		"value": "Daily",
		"text": "Daily"
	},
	{
		"value": "Three_Days",
		"text": "Three Days"
	},
	{
		"value": "Sessions",
		"text": "Sessions"
	}
])
        .constant('SERVICES', {
	"CHART": "Chart",
	"PATIENT": "Patient"
})
        .constant('BUSINESS_ROLES', {
	"NURSE": "NURSE",
	"PHYSICIAN": "PHYSICIAN",
	"THERAPIST": "THERAPIST",
	"SIGN_DIAGNOSES": "SIGN_DIAGNOSES",
	"GROUP_SESSION_REVIWER": "GROUP_SESSION_REVIWER",
	"REVIEW_DIAGNOSES": "REVIEW_DIAGNOSES",
	"DISCONTINUE_DIAGNOSES": "DISCONTINUE_DIAGNOSES",
	"ORDER_REVIEWER": "ORDER_REVIEWER",
	"SIGN_PATIENT_RESULT": "SIGN_PATIENT_RESULT"
})
        .constant('REPORT_TYPE', {
	"BASIC_INFORMATION": "BASIC_INFORMATION",
	"FACESHEET": "FACESHEET",
	"MEDICATION_TAKE": "MEDICATION_TAKE",
	"EVALUATION": "EVALUATION",
	"BILLING": "BILLING",
	"EVALUATION_TEMPLATE_FILE": "EVALUATION_TEMPLATE_FILE",
	"GROUP_SESSION_DETAIL": "GROUP_SESSION_DETAIL",
	"PATIENT_MEDICATION": "PATIENT_MEDICATION",
	"INCIDENT_REPORT": "INCIDENT_REPORT"
})
        .constant('RATIONALE_TYPE', {
	"MEDICATION": "MEDICATION",
	"LABORATORY": "LABORATORY"
})
        .constant('INCIDENT_REPORT_STATUS', {
	"PENDING": "PENDING",
	"INREVIEW": "INREVIEW",
	"FINALIZED": "FINALIZED"
})
;
})();

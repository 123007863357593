(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TelehealthMeetingEditController', TelehealthMeetingEditController);

    TelehealthMeetingEditController.$inject = ['$scope', '$uibModalInstance', 'entity', 'TelehealthMeeting', 'Employee',
        'CoreService', 'GUIUtils', 'Facility'];

    function TelehealthMeetingEditController($scope, $uibModalInstance, entity, TelehealthMeeting, Employee,
                                             CoreService, GUIUtils, Facility) {
        var vm = this;

        vm.telehealthMeeting = entity;
        vm.showPassword = false;
        vm.customRecurrences = [];
        vm.datePickerOpenStatus = {};
        vm.datePickerOptions = {
            startDateTime: {minDate: new Date()}
        };
        vm.customDatePicked = new Date(moment(vm.telehealthMeeting.startDate).add(1, 'minutes'));
        Facility.getAvailableZoneIds(function (result) {
            vm.timeZones = _.map(result, function (timeZone) {
                return timeZone.zoneId + ' ' + timeZone.offset;
            });
        });

        vm.save = save;
        vm.cancel = cancel;
        vm.getEmployees = getEmployees;
        vm.openCalendar = openCalendar;

        function save() {
            vm.isSaving = true;

            var telehealthMeeting = {
                id: vm.telehealthMeeting.id,
                status: 'SCHEDULED',
                twilioRoomName: vm.telehealthMeeting.twilioRoomName,
                startDate: new Date(vm.telehealthMeeting.startDate),
                endDate: new Date(moment(vm.telehealthMeeting.startDate).add(vm.telehealthMeeting.duration, 'minutes')),
                duration: vm.telehealthMeeting.duration,
                topic: vm.telehealthMeeting.topic,
                description: vm.telehealthMeeting.description,
                timeZone: vm.telehealthMeeting.timeZone,
                password: vm.telehealthMeeting.password,
                host: vm.telehealthMeeting.host,
                facility: vm.telehealthMeeting.facility,
                telehealthMeetingRecurrence: {
                    id: (vm.telehealthMeeting.telehealthMeetingRecurrence.frequency === 'NONE' ? vm.telehealthMeeting.telehealthMeetingRecurrence.id : null ),
                    frequency: 'NONE',
                    endRecurrenceRepeat: null,
                    endDate: null,
                    weekDays: null,
                    facility: vm.telehealthMeeting.facility
                }
            };

            TelehealthMeeting.update(telehealthMeeting, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            $uibModalInstance.dismiss(true);
            vm.isSaving = false;
        }

        function cancel() {
            $uibModalInstance.close(false);
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }
    }
})();

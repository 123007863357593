(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidIcd10DialogController', CovidIcd10DialogController);

    CovidIcd10DialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CovidIcd10', 'Icd10Search'];

    function CovidIcd10DialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CovidIcd10, Icd10Search) {
        var vm = this;

        vm.covidIcd10 = entity;
        vm.icd10 = vm.covidIcd10.id == null ? getIcd10() : [vm.covidIcd10.icd10];

        vm.clear = clear;
        vm.save = save;
        vm.getIcd10 = getIcd10;


        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.covidIcd10.id !== null) {
                CovidIcd10.update(vm.covidIcd10, onSaveSuccess, onSaveError);
            } else {
                CovidIcd10.save(vm.covidIcd10, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:covidIcd10Update', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationCommentsDialogController', EvaluationCommentsDialogController);

    EvaluationCommentsDialogController.$inject = ['$scope', '$uibModalInstance','entity', 'EvaluationComment'];

    function EvaluationCommentsDialogController( $scope, $uibModalInstance, entity, EvaluationComment ) {

        var vm = this;

        vm.comments = entity;

        vm.save = save;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.close('dismiss');
        }

        function save() {
            vm.isSaving = true;

            if (vm.comments.id) {
                EvaluationComment.update(vm.comments, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:diagnosesUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();


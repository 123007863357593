(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartStatusHistoryDialogController', ChartStatusHistoryDialogController);

    ChartStatusHistoryDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'ChartStatusHistory', 'chart', '$state'];

    function ChartStatusHistoryDialogController($timeout, $scope, $uibModalInstance, entity, ChartStatusHistory, chart, $state) {
        var vm = this;

        vm.chartStatusHistory = entity;
        vm.chartStatusHistory.chart = chart;
        vm.date = new Date();
        vm.current = false;

        vm.clear = clear;
        vm.save = save;
        vm.getTitle = getTitle;
        vm.changeCloseDate = changeCloseDate;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.chartStatusHistory.id == null) {
                beforeSave();
                ChartStatusHistory.save(vm.chartStatusHistory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:chartStatusHistoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getTitle(status) {
            switch (status) {
                case 'CURRENT': {
                    return 'Current';
                }
                case 'ARCHIVE': {
                    return 'Archive';
                }
                case 'REOPEN': {
                    return 'Reopen'
                }
                case 'CLOSED': {
                    return 'Closed'
                }
            }
        }

        function changeCloseDate() {
            if (vm.current) {
                vm.dischargeDate = angular.copy(vm.date);
            } else {
                vm.dischargeDate = angular.copy(vm.chartStatusHistory.chart.dischargeDate);
            }
        }

        function beforeSave() {
            switch (vm.chartStatusHistory.status) {
                case 'REOPEN': {
                    vm.chartStatusHistory.date = angular.copy(vm.date);
                    break;
                }
                case 'CLOSED': {
                    if (!vm.chartStatusHistory.chart.dischargeDate || vm.chartStatusHistory.chart.dischargeDate >= vm.date) {
                        vm.chartStatusHistory.date = angular.copy(vm.date);
                    } else if (vm.current) {
                        vm.chartStatusHistory.date = angular.copy(vm.date);
                    } else {
                        vm.chartStatusHistory.date = angular.copy(vm.chartStatusHistory.chart.dischargeDate);
                    }
                    break;
                }
                case 'CURRENT': {
                    vm.chartStatusHistory.date = angular.copy(vm.date);
                }
            }
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('DymoLabel', DymoLabel);

    DymoLabel.$inject = ['$resource', 'DYMO'];

    function DymoLabel($resource, DYMO) {
        var resourceUrl = 'api/DymoLabels/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                url: 'content/dymo/label.label',
                header: {'Content-Type': 'application/xml; charset=UTF-8'},
                transformResponse: function (data) {
                    if (data) {
                        DYMO.setTemplate(data);

                        return {
                            label: data
                        };
                    }

                    return data;
                }
            }
        });
    }
})();

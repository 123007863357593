(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientMedicationTakeReaction', PatientMedicationTakeReaction);

    PatientMedicationTakeReaction.$inject = ['$resource', 'CoreService', 'DateUtils'];

    function PatientMedicationTakeReaction($resource, CoreService, DateUtils) {
        var resourceUrl = 'api/patient-medication-take-reactions/:id';
        var facility = CoreService.getCurrentFacility();

        return $resource(resourceUrl, {}, {
            'findAllByTake': {
                url: 'api/patient-medication-take-reactions/by-take/:id',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        _.forEach(data, function (reaction) {
                            reaction.createdDate = new Date(DateUtils.convertDateTimeFromServer(reaction.createdDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        });
                    }

                    return data;
                }
            },
            'addFirstDoseSideEffect': {
                url: 'api/patient-medication-take-reactions/add-first-dose-side-effect',
                method: 'POST',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('UrgentIssues', UrgentIssues);

    UrgentIssues.$inject = ['$resource'];

    function UrgentIssues($resource) {
        var resourceUrl = 'api/urgent-issues/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byChart': {url: 'api/urgent-issues/by-chart/:id', method: 'GET', isArray: true},
            'update': {method: 'PUT'},
            'unknown': {url: 'api/urgent-issues/unknown-by-chart/:id', method: 'GET', isArray: true},
            'countUnknown': {
                url: 'api/urgent-issues/count-unknown-by-chart/:id',
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {

                        return {count: data};
                    }

                    return {count: 0};
                }
            },
            'countByChartIds': {url: 'api/urgent-issues/count-by-chart-ids/:chartIds', method: 'GET', isArray: true},
            'check': {url: 'api/urgent-issues/check', method: 'PUT'},
            'count': {
                url: 'api/urgent-issues/count/:id', method: 'GET', isArray: false, transformResponse: function (data) {
                    if (data) {

                        return {count: data};
                    }

                    return {count: 0};
                }
            },
            'byFacility': {url: 'api/urgent-issues/by-facility', method: 'GET', isArray: true},
        });
    }
})();

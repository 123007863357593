(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('laboratory', {
                parent: 'entity',
                url: '/laboratory',
                ncyBreadcrumb: {
                    label: 'Laboratory',
                    parent: 'entity'
                },
                data: {
                    authorities: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM],
                    pageTitle: 'Laboratories'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/laboratory/list/laboratory.html',
                        controller: 'LaboratoryController',
                        controllerAs: 'vm'
                    }
                },
            });
    }
})();

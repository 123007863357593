(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationMarsSystemMissDialogController', PatientMedicationMarsSystemMissDialogController);

    PatientMedicationMarsSystemMissDialogController.$inject = ['$uibModalInstance', 'take', 'takes',
        'PatientMedicationTake', 'chart', 'CoreService'];

    function PatientMedicationMarsSystemMissDialogController($uibModalInstance, take, takes,
                                                             PatientMedicationTake, chart, CoreService) {
        var vm = this;

        vm.title = "Comment on late med";
        vm.take = take;
        vm.takes = takes;
        vm.chart = chart;
        vm.systemMissReason = take == null ? null : take.systemMissReason;
        vm.facility = CoreService.getCurrentFacility();

        vm.addReason = addReason;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function addReason() {
            vm.isSaving = true;

            PatientMedicationTake.changeStatus({
                status: vm.takes.status,
                takes: vm.takes.ids,
                systemMissReason: vm.systemMissReason,
                facilityId: vm.facility.id
            }, function (result) {
                vm.isSaving = false;
                $uibModalInstance.close();
            }, function () {
                vm.isSaving = false;
            });
        }
    }
})();

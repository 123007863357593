(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BillingScheduleController', BillingScheduleController);

    BillingScheduleController.$inject = ['GenericEntityDatatableService', 'BillingSchedule', '$q', '$uibModal',
        'toastr', 'ConfirmationService'];

    function BillingScheduleController(GenericEntityDatatableService, BillingSchedule, $q,
                                        $uibModal, toastr, ConfirmationService) {
        var vm = this;

        vm.totalItems = 0;

        vm.descriptor = {
            title: 'Billing Schedule',
            newAction: false,
            entityClassHumanized: 'Billing Schedule',
            entityStateName: 'billing-schedule',
            service: BillingSchedule,
            serviceMethod: 'query',
            columns: [
                {
                    name: 'emails',
                    title: 'emails'
                },
                {
                    name: null,
                    title: 'Users',
                    render: function (data) {
                        var userList = [];
                        data.employees.forEach(function(item){
                           userList.push(item.user.login);
                        })
                        return userList.join(", ");
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Billing Schedule',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0;
                            }
                        })
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/billing-schedule/update/billing-schedule-dialog.html',
                    controller: 'BillingScheduleDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: [function () {
                            return {
                                id: null
                            };
                        }]
                    }
                }).result.then(function () {
                    toastr.success('Billing Schedule was saved', 'Success');

                    resolve();
                });
            })
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/billing-schedule/update/billing-schedule-dialog.html',
                    controller: 'BillingScheduleDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['BillingSchedule', function (BillingSchedule) {
                            return BillingSchedule.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    toastr.success('Billing Schedule was saved', 'Success');

                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Billing Schedule').result.then(function () {
                    BillingSchedule.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp', [
            'ngStorage',
            // 'tmh.dynamicLocale',
            // 'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'ds.objectDiff',
            'udt',
            'datatables',
            'datatables.factory',
            'datatables.bootstrap',
            'datatables.colreorder',
            'datatables.columnfilter',

            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            // 'ui.grid',
            // 'ui.grid.autoResize',
            // 'ui.grid.selection',
            // 'ui.grid.exporter',
            'ui.checkbox',
            'ncy-angular-breadcrumb',
            'ui.toggle',
            'ui.select',
            'angularMoment',
            'nzToggle',
            'ng-pros.directive.autocomplete',
            'ngSanitize',
            'ui.mask',
            'rmDatepicker',
            'chart.js',
            'nvd3',
            'ngIdle',
            'ngAnimate',
            // 'ngToast', //Remove later
            'toastr',
            // 'ngLodash',
            'fancyboxplus',
            'angularBootstrapFileinput',
            'validation', 'validation.rule', 'app.validation.rule',
            'daterangepicker',
            'dndLists',
            'angular-toArrayFilter',
            'ui.pagination',
            'dialogs.main',
            'dialogs.default-translations',
            'uiCropper',
            'duScroll',
            'ods-lib',
            'mgo-angular-wizard',
            'ui.calendar',
            'xeditable',
            'rm2Datepicker',
            'datatables.buttons',
            'angularUtils.directives.dirPagination',
            // 'ngQuill',
            'credit-cards',
            'ui.validate',
            'angularResizable'
        ])
        .config(['$validationProvider', '$uibTooltipProvider', 'toastrConfig', 'cfpLoadingBarProvider',
            function ($validationProvider, $uibTooltipProvider, toastrConfig, cfpLoadingBarProvider) {
        // .config(['$validationProvider', '$uibTooltipProvider', 'toastrConfig', 'cfpLoadingBarProvider', 'ngQuillConfigProvider',
        //     function ($validationProvider, $uibTooltipProvider, toastrConfig, cfpLoadingBarProvider, ngQuillConfigProvider) {
                $uibTooltipProvider.options({appendToBody: true});
                // cfpLoadingBarProvider.includeBar = false;
                // cfpLoadingBarProvider.spinnerTemplate = '<div class="spinner-layer"><div class="spinner-rings"></div></div>';
                //---------- Toast config ---------------------------
                angular.extend(toastrConfig, {
                    allowHtml: true,
                    closeButton: true,
                    closeHtml: '<button>&times;</button>',
                    extendedTimeOut: 1000,
                    autoDismiss: false,
                    maxOpened: 5,
                    iconClasses: {
                        error: 'toast-error',
                        info: 'toast-info',
                        success: 'toast-success',
                        warning: 'toast-warning'
                    },
                    messageClass: 'toast-message',
                    onHidden: null,
                    onShown: null,
                    onTap: null,
                    progressBar: true,
                    tapToDismiss: true,
                    newestOnTop: true,
                    positionClass: 'toast-bottom-right',
                    timeOut: 5000,
                    titleClass: 'toast-title',
                    toastClass: 'toast'
                });
                // Quill Config
                // ngQuillConfigProvider.set({
                //    modules: {
                //        toolbar: [
                //            [{ 'font': [] }],
                //            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                //            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                //            // ['blockquote'],
                //            [{ 'align': [] }],
                //            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                //            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                //            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                //            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                //            //[{ 'direction': 'rtl' }],                         // text direction
                //
                //            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                //
                //            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                //
                //            //['clean'],                                         // remove formatting button
                //            //['link', 'image', 'video']                         // link and image, video
                //            ['link']                         // link and image, video
                //        ]
                //    },
                //     placeholder: 'Insert your text here...',
                //     scrollContainer: '.ql-editor'
                // });

                //----------- Validators config ---------------------------
                /**
                 * Setup a default messages
                 */
                var defaultMsgs = {
                    required: {
                        error: 'This field is required.',
                        success: ''
                    }
                };

                $validationProvider.setDefaultMsg(defaultMsgs);

                angular.extend($validationProvider, {
                    validCallback: function (element) {
                        //$(element).closest('div.form-group').find('label.has-error').hide();
                        $(element).parents('.form-group:first').removeClass('has-error');
                    },
                    invalidCallback: function (element) {
                        //Add has-error to the first form group
                        $(element).parents('.form-group:first').addClass('has-error');
                    },
                    resetCallback: function (element) {
                        //Add has-error to the first form group
                        $(element).parents('.form-group:first').removeClass('has-error');
                    }

                });

                $validationProvider.setErrorHTML(function (msg) {
                    return '<p class="control-label pull-left has-error">' + msg + '</p>';
                });
                $validationProvider.showSuccessMessage = false;
                //-------------------------------------------------------

            }])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope', 'ProfileService', 'ROLES', 'SystemService', 'DYMO', 'EmployeeMenu', '$window'];

    function run(stateHandler, $rootScope, ProfileService, ROLES, SystemService, DYMO, EmployeeMenu, $window) {
        stateHandler.initialize();

        $rootScope.inputDateFormat = "MM/dd/yyyy";
        $rootScope.inputDateTimeFormat = "MM/dd/yyyy hh:mm";
        $rootScope.inputDateTimeFormatA = "MM/dd/yyyy hh:mm a";
        $rootScope.amDateFormat = "MM/DD/YYYY hh:mm A";
        $rootScope.dateFormat = "MM/DD/YYYY";
        $rootScope.timeFormat = "hh:mm";
        $rootScope.timeFormatA = "hh:mm a";

        $rootScope.ROLES = ROLES;

        // clipboard assistant
        $rootScope.showAssitant = false;
        $rootScope.assistantList = [];

        // new chart
        $rootScope.showNewChart = false;

        //Get running profile
        ProfileService.getProfileInfo().then(function (response) {
            $rootScope.inProduction = response.inProduction;
            // vm.swaggerEnabled = response.swaggerEnabled;
        });

        SystemService.appData().$promise.then(function (response) {
            $rootScope.appData = response;

            setInterval(checkVersion, 300000);
        });

        $rootScope.employeeMenu = {
            menu: {
                careCoordination: {name: 'Care Coordination', value: true},
                diagnoses: {name: 'Diagnoses', value: true},
                careTeam: {name: 'Care Team', value: true},
                allergies: {name: 'Allergies', value: true},
                diet: {name: 'Diet', value: true},
                insurance: {name: 'Insurance', value: true},
                contacts: {name: 'Contacts', value: true},
                cReviews: {name: 'Concurrent Reviews', value: true},
                patientMeds: {name: 'Medications', value: true},
                orders: {name: 'Orders', value: true},
                //mars: {name: 'MARs', value: true},
                evaluation: {name: 'Care & Delivery', value: true},
                vitals: {name: 'Vitals', value: true},
                glucose: {name: 'Glucose', value: true},
                weight: {name: 'Weight', value: true},
                gSessions: {name: 'Group Sessions', value: true},
                eLabs: {name: 'ELabs', value: true},
                notes: {name: 'Notes', value: true},
                drugs: {name: 'Drugs & Alcohol', value: true},
                verificationBenefits: {name: 'Verification of Benefits', value: true},
                files: {name: 'Files', value: true}
            }
        };

        DYMO.loadPrinters();

        function checkVersion() {
            SystemService.appData().$promise.then(function (response) {
                if (response.version != $rootScope.appData.version || response.build != $rootScope.appData.build) {
                    $window.location.reload();
                }
            });
        }
    }
})();

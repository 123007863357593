(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmEmailDialogController', CrmEmailDialogController);

    CrmEmailDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CrmEmail'];

    function CrmEmailDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CrmEmail) {
        var vm = this;

        vm.crmEmail = entity;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.editorCreated = editorCreated;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.crmEmail.id !== null) {
                CrmEmail.update(vm.crmEmail, onSaveSuccess, onSaveError);
            } else {
                CrmEmail.save(vm.crmEmail, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:crmEmailUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editorCreated(editor, htmlText) {
            editor.root.innerHTML = htmlText;
        }
    }
})();

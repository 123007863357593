(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProcedureModifierController', ProcedureModifierController);

    ProcedureModifierController.$inject = ['$scope', '$state', 'ProcedureModifier', 'ProcedureModifierSearch'];

    function ProcedureModifierController ($scope, $state, ProcedureModifier, ProcedureModifierSearch) {
        var vm = this;

        vm.procedureModifiers = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ProcedureModifier.query(function(result) {
                vm.procedureModifiers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ProcedureModifierSearch.query({query: vm.searchQuery}, function(result) {
                vm.procedureModifiers = result;
            });
        }    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RcUploadDialogController', RcUploadDialogController);

    RcUploadDialogController.$inject = ['CoreService', '$uibModal', '$uibModalInstance', 'DataUtils', 'toastr', 'RcUpload'];

    function RcUploadDialogController(CoreService, $uibModal, $uibModalInstance, DataUtils, toastr, RcUpload) {

        var vm = this;

        vm.facility = CoreService.getCurrentFacility();
        vm.fileDTO = {
            facilityId: vm.facility.id,
            files: []
        };
        vm.file = null;
        vm.date = null;
        vm.isSaving = false;
        vm.datePickerOpenStatus = false;

        vm.attachFile = attachFile;
        vm.clear = clear;
        vm.attach = attach;
        vm.openCalendar = openCalendar;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function attachFile(files) {
            vm.file = null;

            var bigFiles = _.remove(files, function (item) {

                return parseInt(item.size) > 16000000;
            });

            vm.file = files[0];

            for (var i = 0; i < bigFiles.length; i++) {
                toastr.error(bigFiles[i].name + ' file is too big. The maximum size is 16MB')
            }
        }

        function attach() {
            vm.isSaving = true;

            vm.fileDTO.files = [];

            DataUtils.toBase64ToFileObject(vm.file, function (result) {
                vm.fileDTO.files.push(result);
                vm.fileDTO.collectedDate = moment(vm.date).format("YYYY-MM-DD");

                RcUpload.save(vm.fileDTO, onSaveSuccess, onSaveError);
            });
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar() {
            vm.datePickerOpenStatus = true;
        }
    }
})();

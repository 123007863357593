/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PhysicianReviewDiagnosesController', PhysicianReviewDiagnosesController);

    PhysicianReviewDiagnosesController.$inject = ['moment', 'toastr', '$q', 'Diagnoses', 'CoreService', 'PopupService',
        '$rootScope', 'Chart', 'signDiagnosesCredentials', 'GenericEntityDatatableService'];

    function PhysicianReviewDiagnosesController(moment, toastr, $q, Diagnoses, CoreService, PopupService,
                                                $rootScope, Chart, signDiagnosesCredentials, GenericEntityDatatableService) {
        var vm = this;

        vm.facility = CoreService.getCurrentFacility();

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Diagnoses',
            service: Diagnoses,
            serviceMethod: 'findAllPhysicianReview',
            params: params,
            pagination: 'remote',
            newAction: false,
            withSelectionColumn: true,
            isVisibleSelectionColumn: function () {
                return CoreService.currentEmployeeHaveAnyCredential(signDiagnosesCredentials);
            },
            options: [
                {key: 'aaSorting', value: [[2, 'desc'], [4, 'asc']]}
            ],
            selectionColumn: 'id',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'patientName',
                    title: 'Name',
                    options: [{key: 'width', value: '170px'}],
                    render: function (data) {
                        return data.lastName + ', ' + data.firstName;
                    }
                },
                {
                    name: 'mrNo',
                    title: 'MrNo'
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dob).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'rank',
                    title: 'Rank'
                },
                {
                    title: 'Code',
                    render: function (data) {
                        return data && data.icd10 ? data.icd10.code : '';
                    }
                },
                {
                    title: 'Description',
                    render: function (data) {
                        return data && data.icd10 ? data.icd10.description : '';
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        vm.viewDetails(data.id);
                    },
                }),
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getSignAction(), {
                            name: 'Sign Selected',
                            disabledOnEmpty: true,
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.signDiagnoses(selected);
                                }
                            },
                            ngIf: function () {
                                return CoreService.currentEmployeeHaveAnyCredential(signDiagnosesCredentials);
                            },
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: vm.facility.id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'mrNo',
                    // title: 'Barcode',
                    placeholder: 'MrNo...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'dob',
                    // title: 'Day of Birth',
                    placeholder: 'Day of Birth...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'rank',
                    placeholder: 'Rank...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'code',
                    placeholder: 'Code...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'description',
                    placeholder: 'Description...',
                    type: 'string',
                    visible: true
                }
            ]
        }

        vm.viewDetails = viewDetails;
        vm.signDiagnoses = signDiagnoses;

        function viewDetails(id) {
            PopupService.showDiagnosesEditPopup(id);
        }

        function signDiagnoses(selected) {
            var ids = _.map(selected, function (item) {
                return item.id;
            });

            return $q(function (resolve, reject) {
                PopupService.showSignaturePopup().result.then(function (data) {
                    Diagnoses.sign({
                        signature: data.employeeSignature,
                        pin: data.employeeSignaturePin,
                        ids: ids,
                        facilityId: vm.facility.id
                    }, function (result) {
                        if (result) {
                            toastr.success("The Patient Result was signed successfully.");
                        } else {
                            toastr.error('Some patient results could not be signed.');
                        }

                        resolve();
                    }, function () {
                        toastr.error('Some patient results could not be signed.');
                        resolve();
                    });
                })
            });
        }
    }
})();

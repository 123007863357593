(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOpportunityController', CrmOpportunityController);

    CrmOpportunityController.$inject = ['$uibModal', '$state', 'filter', 'CrmOpportunity', 'CoreService', '$rootScope',
        'CrmNote', 'AlertService', '$sce', 'CrmTask', 'CrmEmail', 'CrmCall', '$scope', '$timeout',
        'CrmActivity', 'CrmAccount', 'GUIUtils', 'CrmOpportunityStatusSearch', 'CrmTagSearch',
        'CrmTag'];

    function CrmOpportunityController($uibModal, $state, filter, CrmOpportunity, CoreService, $rootScope,
                                      CrmNote, AlertService, $sce, CrmTask, CrmEmail, CrmCall, $scope, $timeout,
                                      CrmActivity, CrmAccount, GUIUtils, CrmOpportunityStatusSearch, CrmTagSearch,
                                      CrmTag) {
        var vm = this;

        vm.title = 'Opportunities';
        vm.layoutToggle = 'LIST';

        vm.marketers = [];
        vm.crmOpportunities = [];
        vm.openCrmOpportunities = [];
        vm.crmOpportunitiesProjs = [];
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.filter = filter;
        vm.filterTpl = angular.copy(filter);
        vm.quantity = 5;
        vm.activeTab = 0;
        vm.floatingMenuStatus = false;
        vm.employee = CoreService.getCurrentEmployee();
        vm.openPopover = 0;
        vm.corporation = CoreService.getCorporation();
        vm.pictureDefault = CoreService.patientPictureDefault();

        vm.accounts = [];

        vm.gender = GUIUtils.getGender();
        vm.status = CrmOpportunityStatusSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.crmTags = [];

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;
        vm.bodOptions = {
            maxDate: new Date(),
            minDate: new Date(1900, 1, 1)
        };

        CrmTagSearch.query(CoreService.getFilterByCurrentCorporation(), function (result) {
            vm.crmTags = result;
        });

        vm.toggleFloatingMenu = toggleFloatingMenu;
        vm.toggleActive = toggleActive;
        vm.goToDetails = goToDetails;
        vm.search = search;
        vm.clear = clear;
        vm.findAllByFilter = findAllByFilter;
        vm.transition = transition;
        vm.trustAsHtml = trustAsHtml;
        vm.isOwner = isOwner;
        vm.pinCrmNote = pinCrmNote;
        vm.addCrmNote = addCrmNote;
        vm.addCrmTask = addCrmTask;
        vm.addCrmCall = addCrmCall;
        vm.addCrmEmail = addCrmEmail;
        vm.pinCrmTask = pinCrmTask;
        vm.pinCrmCall = pinCrmCall;
        vm.pinCrmEmail = pinCrmEmail;
        vm.completedCrmTask = completedCrmTask;
        vm.replace = replace;
        vm.removeTab = removeTab;
        // vm.saveNewCrmOpportunity = saveNewCrmOpportunity;
        vm.openModalPaymentPlan = openModalPaymentPlan;
        vm.findAllByCrmAccountId = findAllByCrmAccountId;
        vm.updateGChart = updateGChart;
        vm.filterCrmAccounts = filterCrmAccounts;
        vm.selectAccount = selectAccount;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getTabName = getTabName;
        vm.format = format;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        init();

        function init() {
            google.load('visualization', '1', {packages: ['controls', 'corechart']});

            CrmOpportunity.crmOpportunityMarketers({
                id: CoreService.getCorporation().id
            }, function (result) {
                vm.marketers = result;
                _.forEach(vm.marketers, function (item) {
                    item.avatar = CoreService.getAvatar(item.gender);
                    item.selected = false;
                });
            });

            findAllByFilter();
        }

        function findAllByFilter() {
            CrmOpportunity.findAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.crmOpportunities = result;
            });
        }

        function findAllByCrmAccountId(crmAccountId, index) {
            CrmOpportunity.getAllOpportunities({id: crmAccountId}, function (result) {
                vm.crmOpportunitiesProjs[index] = result;
            });
        }

        function search() {
            vm.page = 1;
            vm.findAllByFilter();
        }

        function clear() {
            vm.filter = angular.copy(vm.filterTpl);
            vm.searchQuery = null;
            vm.page = 1;

            vm.findAllByFilter();
        }

        function getFilter() {
            vm.marketersSelected = _.filter(vm.marketers, function (section) {
                return section.selected === true;
            });

            var filter = angular.copy(vm.filter);

            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;
            if (vm.marketersSelected.length > 0) {
                filter.employeesIds = _.map(vm.marketersSelected, function (marketerSelected) {

                    return marketerSelected.id;
                });
            }

            return filter
        }

        // for drag and drop layout
        vm.draglists = {"A": [], "B": [], "C": [], "D": [], "E": [], "F": [], "G": []};
        vm.selected = null;

        // Generate initial model
        for (var i = 1; i <= 3; ++i) {
            vm.draglists.A.push({label: "Item A" + i});
            vm.draglists.B.push({label: "Item B" + i});
            vm.draglists.C.push({label: "Item C" + i});
            vm.draglists.D.push({label: "Item D" + i});
            vm.draglists.E.push({label: "Item E" + i});
            vm.draglists.F.push({label: "Item F" + i});
            vm.draglists.G.push({label: "Item G" + i});
        }

        function transition() {
            vm.findAllByFilter();
        }

        function goToDetails(id) {
            $state.go('crm-opportunity-detail', {id: id});
        }

        function toggleActive($event, item) {
            var elem = angular.element($event.currentTarget);
            elem.toggleClass('active');
            item.selected = !item.selected;
            transition();
        }

        function toggleFloatingMenu($event) {
            var btn = angular.element($event.currentTarget);

            vm.floatingMenuStatus = !vm.floatingMenuStatus;

            btn.next().slideToggle();
        }

        function save(data) {
            CrmOpportunity.save(data.crmOpportunity, function (result) {
                vm.openCrmOpportunities.splice(vm.activeTab - 1, 1);
                vm.openCrmOpportunities = angular.copy(vm.openCrmOpportunities);

                findAllByFilter();
                $scope.$emit('bluebookApp:displayCrmOpportunity', result.id);
            });
        }

        $rootScope.$on('bluebookApp:createCrmOpportunity', function (event) {
            var data = {
                pictureRef: CoreService.patientPictureDefault(),
                pictureRefOriginal: CoreService.patientPictureDefault(),
                crmOpportunity: {
                    id: null,
                    crmAccount: {
                        id: null,
                        lastName: null,
                        firstName: null,
                        corporation: CoreService.getCorporation()
                    },
                    corporation: CoreService.getCorporation(),
                    status: null
                },
                notes: {
                    crmNotes: [],
                    page: 1,
                    size: 10,
                    totalItems: 0
                },
                tasks: {
                    crmTasks: [],
                    page: 1,
                    size: 10,
                    totalItems: 0
                },
                calls: {
                    crmCalls: [],
                    page: 1,
                    size: 10,
                    totalItems: 0
                },
                emails: {
                    crmEmails: [],
                    page: 1,
                    size: 10,
                    totalItems: 0
                },
                activities: {
                    startDate: moment(new Date).subtract(1, 'month').toDate(),
                    endDate: new Date(),
                    crmActivities: []
                },
                crmAccount: null
            };

            vm.openCrmOpportunities.push(data);
            activeLastTab();
        });

        $rootScope.$on('bluebookApp:displayCrmOpportunity', function (event, id) {
            var index = _.findIndex(vm.openCrmOpportunities, function (data) {
                return data.crmOpportunity.id == id;
            });
            if (index == -1) {
                CrmOpportunity.get({id: id}, function (crmOpportunity) {
                    var data = {
                        //pictureRef: crmOpportunity.pictureRefId ? Picture.get({id: crmOpportunity.pictureRefId}) : CoreService.patientPictureDefault(),
                        //pictureRefOriginal: crmOpportunity.pictureRefOriginalId ? Picture.get({id: crmOpportunity.pictureRefOriginalId}) : CoreService.patientPictureDefault(),
                        pictureRef: CoreService.patientPictureDefault(),
                        pictureRefOriginal: CoreService.patientPictureDefault(),
                        crmOpportunity: crmOpportunity,
                        notes: {
                            crmNotes: [],
                            page: 1,
                            size: 10,
                            totalItems: 0
                        },
                        tasks: {
                            crmTasks: [],
                            page: 1,
                            size: 10,
                            totalItems: 0
                        },
                        calls: {
                            crmCalls: [],
                            page: 1,
                            size: 10,
                            totalItems: 0
                        },
                        emails: {
                            crmEmails: [],
                            page: 1,
                            size: 10,
                            totalItems: 0
                        },
                        activities: {
                            startDate: moment(new Date).subtract(1, 'month').toDate(),
                            endDate: new Date(),
                            crmActivities: []
                        },
                        tags: CrmTag.findAllByCrmOpportunity({id: id})
                    };

                    vm.openCrmOpportunities.push(data);
                    activeLastTab();

                    getNotes(data);
                    getTasks(data);
                    getEmails(data);
                    getCalls(data);
                    getActivities(data);

                    CrmOpportunity.getAllOpportunities({id: crmOpportunity.crmAccount.id}, function (result) {
                        data.crmOpportunities = result;
                    });
                })
            } else {
                vm.activeTab = index + 1;
            }
        });

        function isOwner(data) {
            return vm.employee.id == data.employee.id;
        }

        function getNotes(data) {
            if (data == null) {
                data = vm.openCrmOpportunities[vm.activeTab - 1];
            }

            CrmNote.findAllByFilter({
                crmOpportunityId: data.crmOpportunity.id,
                corporationId: data.crmOpportunity.corporation.id,
                page: data.notes.page - 1,
                size: data.notes.size
            }, function (result, headers) {
                data.notes.totalItems = headers('X-Total-Count');
                data.notes.crmNotes = result;
            }, onError);
        }

        function getTasks(data) {
            if (data == null) {
                data = vm.openCrmOpportunities[vm.activeTab - 1];
            }

            CrmTask.findAllByFilter({
                crmOpportunityId: data.crmOpportunity.id,
                corporationId: data.crmOpportunity.corporation.id,
                page: data.tasks.page - 1,
                size: data.tasks.size
            }, function (result, headers) {
                data.tasks.totalItems = headers('X-Total-Count');
                data.tasks.crmTasks = result;
            }, onError);
        }

        function getEmails(data) {
            if (data == null) {
                data = vm.openCrmOpportunities[vm.activeTab - 1];
            }

            CrmEmail.findAllByFilter({
                crmOpportunityId: data.crmOpportunity.id,
                corporationId: data.crmOpportunity.corporation.id,
                page: data.emails.page - 1,
                size: data.tasks.size
            }, function (result, headers) {
                data.emails.totalItems = headers('X-Total-Count');
                data.emails.crmEmails = result;
            }, onError);
        }

        function getCalls(data) {
            if (data == null) {
                data = vm.openCrmOpportunities[vm.activeTab - 1];
            }

            CrmCall.findAllByFilter({
                crmOpportunityId: data.crmOpportunity.id,
                corporationId: data.crmOpportunity.corporation.id,
                page: data.calls.page - 1,
                size: data.calls.size
            }, function (result, headers) {
                data.calls.totalItems = headers('X-Total-Count');
                data.calls.crmCalls = result;
            }, onError);
        }

        function getActivities(data) {
            CrmActivity.findAllByFilter({
                corporationId: data.crmOpportunity.corporationId,
                crmOpportunityId: data.crmOpportunity.id
            }, function (result) {
                data.activities.crmActivities = result;

                $timeout(function () {
                    google.setOnLoadCallback(drawChart(data));
                }, 2000);
            });
        }

        function trustAsHtml(note) {
            return $sce.trustAsHtml(note);
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function addCrmNote() {
            var data = vm.openCrmOpportunities[vm.activeTab - 1];

            $uibModal.open({
                templateUrl: 'app/entities/crm-note/crm-note-dialog.html',
                controller: 'CrmNoteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            note: null,
                            pin: false,
                            id: null,
                            crmOpportunity: data.crmOpportunity,
                            employee: CoreService.getCurrentEmployee(),
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                data.notes.page = 1;
                getNotes(data);
            }, function () {
            });
        }

        function pinCrmNote(note) {
            CrmNote.pin(note, function () {
                getNotes();
            })
        }

        function addCrmTask() {
            var data = vm.openCrmOpportunities[vm.activeTab - 1];

            $uibModal.open({
                templateUrl: 'app/entities/crm-task/crm-task-dialog.html',
                controller: 'CrmTaskDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            completed: false,
                            description: null,
                            dueDate: new Date(),
                            reminder: new Date(),
                            pin: false,
                            id: null,
                            crmOpportunity: data.crmOpportunity,
                            assignedTo: null,
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                data.tasks.page = 1;
                getTasks(data);
            }, function () {
            });
        }

        function pinCrmTask(task) {
            CrmTask.pin(task, function () {
                getTasks();
            })
        }

        function addCrmCall() {
            var data = vm.openCrmOpportunities[vm.activeTab - 1];

            $uibModal.open({
                templateUrl: 'app/entities/crm-call/crm-call-dialog.html',
                controller: 'CrmCallDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            description: null,
                            pin: false,
                            id: null,
                            crmOpportunity: data.crmOpportunity,
                            employee: CoreService.getCurrentEmployee(),
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                data.calls.page = 1;
                getCalls(data);
            }, function () {
            });
        }

        function pinCrmCall(call) {
            CrmCall.pin(call, function () {
                getCalls();
            })
        }

        function addCrmEmail() {
            var data = vm.openCrmOpportunities[vm.activeTab - 1];

            $uibModal.open({
                templateUrl: 'app/entities/crm-email/crm-email-dialog.html',
                controller: 'CrmEmailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            date: new Date(),
                            description: null,
                            pin: false,
                            id: null,
                            crmOpportunity: data.crmOpportunity,
                            employee: CoreService.getCurrentEmployee(),
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                data.emails.page = 1;
                getEmails(data);
            }, function () {
            });
        }

        function pinCrmEmail(email) {
            CrmEmail.pin(email, function () {
                getEmails();
            })
        }

        function completedCrmTask(task) {
            CrmTask.completed(task, function () {
                getTasks();
            })
        }

        function replace(str) {

            return str ? str.replace(/_/g, " ") : "";
        }

        function removeTab(index, event) {
            event.preventDefault();
            vm.openCrmOpportunities.splice(index, 1);
            vm.openCrmOpportunities = angular.copy(vm.openCrmOpportunities);

            activeLastTab();
        }

        function openModalPaymentPlan() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-opportunity/activities/payment-plan-dialog.html',
                controller: 'ActivitiesPaymentPlanDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            }).result.then(function () {

            }, function () {
            });
        }

        function drawChart(crmOpportunity) {
            crmOpportunity.data = new google.visualization.DataTable();

            crmOpportunity.data.addColumn({type: 'date', label: 'Date'});
            crmOpportunity.data.addColumn({type: 'timeofday', label: 'Info'});
            crmOpportunity.data.addColumn({type: 'string', role: 'annotation'});
            // crmOpportunity.data.addColumn({type: 'string', role: 'annotationText'});
            // crmOpportunity.data.addColumn({type: 'timeofday', label: 'Document'});
            // crmOpportunity.data.addColumn({type: 'string', role: 'annotation'});
            // crmOpportunity.data.addColumn({type: 'string', role: 'annotationText'});
            // crmOpportunity.data.addColumn({type: 'timeofday', label: 'Task'});
            // crmOpportunity.data.addColumn({type: 'string', role: 'annotation'});
            // crmOpportunity.data.addColumn({type: 'string', role: 'annotationText'});

            _.forEach(getCrmActivityGCData(crmOpportunity), function (row) {
                crmOpportunity.data.addRow(row);
            });

            // crmOpportunity.data.addRow([new Date(2016, 1, 23), [10, 30, 0], 'Info', 'Admission Date Set', [7, 45, 0], 'Document', 'Pre-Admission Docs', [9, 0, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 2, 11), null, null, null, null, null, null, [9, 30, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 3, 12), [8, 30, 0], 'Info', 'Admission Date Set', [16, 45, 0], 'Document', 'Pre-Admission Assessment', [9, 0, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 4, 2), [10, 0, 0], 'Info', 'Admission Date Set', [11, 0, 0], 'Document', 'Pre-Admission Docs', [15, 0, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 5, 3), [7, 30, 0], 'Info', 'Admission Date Set', [14, 30, 0], 'Document', 'Pre-Admission Assessment', [17, 30, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 6, 4), [11, 50, 0], 'Info', 'Admission Date Set', [9, 30, 0], 'Document', 'Pre-Admission Docs', [8, 10, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 7, 6), [9, 20, 0], 'Info', 'Admission Date Set', [14, 50, 0], 'Document', 'Pre-Admission Assessment', [16, 30, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 8, 9), [13, 0, 0], 'Info', 'Admission Date Set', [10, 30, 0], 'Document', 'Pre-Admission Docs', [7, 30, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 9, 22), [14, 20, 0], 'Info', 'Admission Date Set', [11, 10, 0], 'Document', 'Pre-Admission Assessment', [14, 30, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 10, 21), [9, 0, 0], 'Info', 'Admission Date Set', [14, 0, 0], 'Document', 'Pre-Admission Docs', [8, 0, 0], 'Task', 'Task Assigned']);
            // crmOpportunity.data.addRow([new Date(2016, 11, 30), [10, 20, 0], 'Info', 'Admission Date Set', [8, 50, 0], 'Document', 'Pre-Admission Assessment', [16, 30, 0], 'Task', 'Task Assigned']);

            crmOpportunity.dash = new google.visualization.Dashboard(document.getElementById('dashboard_div' + crmOpportunity.crmOpportunity.id));

            crmOpportunity.chart = new google.visualization.ChartWrapper({
                chartType: 'ScatterChart',
                containerId: 'chart_div' + crmOpportunity.crmOpportunity.id,
                options: {
                    height: 400,
                    legend: {
                        position: 'top',
                        alignment: 'end',
                        textStyle: {
                            fontSize: 10,
                            bold: true
                        }
                    },
                    pointShape: 'square',
                    chartArea: {
                        left: 50,
                        top: 40,
                        width: '90%',
                        height: '85%'
                    },
                    explorer: {
                        actions: ['dragToZoom', 'rightClickToReset'],
                        axis: 'horizontal',
                        keepInBounds: true
                    },
                    pointSize: 15,
                    annotations: {
                        boxStyle: {
                            stroke: '#aaa',
                            strokeWidth: 1,
                            rx: 4,
                            ry: 4
                        },
                        textStyle: {
                            fontSize: 10,
                            bold: true
                        },
                        style: 'point'
                    },
                    tooltip: {
                        trigger: 'selection',
                        isHtml: true
                    },
                    vAxis: {
                        gridlines: {
                            count: 15
                        },
                        textStyle: {
                            fontSize: 10
                        }
                    },
                    hAxis: {
                        textStyle: {
                            fontSize: 10
                        }
                    }
                }
            });

            crmOpportunity.control = new google.visualization.ControlWrapper({
                controlType: 'ChartRangeFilter',
                containerId: 'control_div' + crmOpportunity.crmOpportunity.id,
                options: {
                    filterColumnIndex: 0,
                    ui: {
                        chartType: 'ScatterChart',
                        backgroundColor: '#999',
                        chartOptions: {
                            height: 50,
                            annotations: {
                                textStyle: {
                                    fontSize: 9,
                                    opacity: 0
                                }
                            },
                            backgroundColor: '#F3F8FD',
                            chartArea: {
                                width: '90%',
                                backgroundColor: '#ffffff'
                            },
                            pointSize: 5,
                            hAxis: {
                                textStyle: {
                                    fontSize: 10
                                }
                            }
                        },
                        minRangeSize: 6480000000 //milliseconds
                    }
                },
                state: {
                    range: {
                        start: crmOpportunity.activities.startDate,
                        end: crmOpportunity.activities.endDate
                    }
                }
            });

            // Must wait for the ready event in order to
            // request the chart and subscribe to 'onmouseover'.
            google.visualization.events.addListener(crmOpportunity.chart, 'ready', onReady);

            crmOpportunity.dash.bind([crmOpportunity.control], [crmOpportunity.chart]);

            crmOpportunity.dash.draw(crmOpportunity.data);

            function onReady() {
                google.visualization.events.addListener(crmOpportunity.chart.getChart(), 'select', usefulHandler);

                crmOpportunity.chart.getChart().setAction({
                    id: 'tooltipAction1',
                    text: 'See Details 1',
                    action: function () {
                        alert('Tooltip Action 1!');
                    }
                });
                crmOpportunity.chart.getChart().setAction({
                    id: 'tooltipAction2',
                    text: 'See Details 2',
                    action: function () {
                        alert('Tooltip Action 2!');
                    }
                });
            }

            // Select Event Handler
            function usefulHandler() {

            }
        }

        function updateGChart(crmOpportunity) {
            crmOpportunity.control.setState({
                range: {
                    start: crmOpportunity.activities.startDate,
                    end: crmOpportunity.activities.endDate
                }
            });
            crmOpportunity.control.draw();
        }

        function getCrmActivityAnnotation(crmActivity) {
            if (crmActivity.crmNote) {

                return 'Note';
            } else if (crmActivity.crmCall) {

                return 'Call';
            } else if (crmActivity.crmEmail) {

                return 'Email';
            } else if (crmActivity.crmTask) {

                return 'Task';
            }
        }

        function getCrmActivityGCData(crmOpportunity) {

            return _.map(crmOpportunity.activities.crmActivities, function (crmActivity) {

                return [
                    moment(crmActivity.createdDate).toDate(),
                    [
                        parseInt(moment(crmActivity.createdDate).format("HH")),
                        parseInt(moment(crmActivity.createdDate).format("mm")),
                        parseInt(moment(crmActivity.createdDate).format("ss"))
                    ],
                    getCrmActivityAnnotation(crmActivity)
                ];
            })
        }

        function filterCrmAccounts(searchQuery) {
            if (searchQuery) {
                var filter = {
                    searchQuery: searchQuery,
                    page: 0,
                    size: 20,
                    corporationId: vm.corporation.id
                };
                CrmAccount.findAllByFilter(filter, function (result) {
                    vm.accounts = result;
                });
            }
        }

        function selectAccount(data, accountSelected) {
            data.crmOpportunity.crmAccount = accountSelected;
        }

        function getTabName(data) {
            if (data.crmOpportunity.crmAccount && data.crmOpportunity.id) {

                return data.crmOpportunity.crmAccount.lastName + ", " + data.crmOpportunity.crmAccount.firstName +
                    (data.crmOpportunity.crmAccount.middleInitial ? " " + data.crmOpportunity.crmAccount.middleInitial : "");
            } else {

                return "New Opportunity";
            }
        }

        function format(date) {

            return moment(date).format($rootScope.dateFormat)
        }

        function activeLastTab() {
            $timeout(function () {
                vm.activeTab = vm.openCrmOpportunities.length;
            }, 200);
        }
    }
})();

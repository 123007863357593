(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Weight', Weight);

    Weight.$inject = ['$resource', 'DateUtils'];

    function Weight ($resource, DateUtils) {
        var resourceUrl =  'api/weights/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.weight = parseFloat(data.weight);
                        data.height = parseFloat(data.height);
                    }
                    return data;
                }
            },
            'findAllByChart': { url: 'api/weights/by-chart/:id', method: 'GET', isArray: true },
            'update': { method:'PUT' },
            'findLastByChart': {url: 'api/weights/last/by-chart/:id', method: 'GET'},
            'findLastAtt': {url: "api/weights/last/by-chart/:id/size/:size", method: 'GET', isArray: true}
        });
    }
})();

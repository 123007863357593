(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('resource', {
                parent: 'entity',
                url: '/resource?page&sort&search',
                ncyBreadcrumb: {
                    label: 'Resources'
                },
                data: {
                    authorities: [],
                    pageTitle: 'Resources'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/auth-item/resources.html',
                        controller: 'AuthItemController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    entityType: ['AUTH_ITEM_TYPE', function (AUTH_ITEM_TYPE) {
                        return {
                            title: 'Resources',
                            entityClassHumanized: 'Resource',
                            entityStateName: 'resource',
                            type: AUTH_ITEM_TYPE.TYPE_RESOURCE
                        };
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS, access);
                }]
            })
            .state('resource.new', {
                parent: 'resource',
                url: '/new',
                ncyBreadcrumb: {
                    label: 'New Resource'
                },
                data: {
                    authorities: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/auth-item/resource-dialog.html',
                            controller: 'ResourceDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'xl',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CoreService', function (CoreService) {
                                    return {
                                        name: null,
                                        type: null,
                                        description: null,
                                        data: null,
                                        id: null,
                                        children: [],
                                        corporation: CoreService.getCorporation()
                                    };
                                }]
                            }
                        }).result.then(function () {
                            $state.go('resource', null, {reload: 'resource'});
                        }, function () {
                            $state.go('resource');
                        });
                    }
                }]
            })
            .state('resource.edit', {
                parent: 'resource',
                url: '/{id}/edit',
                ncyBreadcrumb: {
                    label: 'Edit Resource'
                },
                data: {
                    authorities: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/auth-item/resource-dialog.html',
                            controller: 'ResourceDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'xl',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['AuthItem', function (AuthItem) {
                                    return AuthItem.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('resource', null, {reload: 'resource'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            })
            .state('resource.delete', {
                parent: 'resource',
                url: '/{id}/delete',
                data: {
                    authorities: [ROLES.ROLE_SYSTEM_ADMIN, ROLES.ROLE_SYSTEM]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MANAGE_ROLE_RESOURCE_AND_ACTIONS, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/auth-item/auth-item-delete-dialog.html',
                            controller: 'AuthItemDeleteController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['AuthItem', function (AuthItem) {
                                    return AuthItem.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('resource', null, {reload: 'resource'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            });
    }
})();

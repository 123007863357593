(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ContactAddressController', ContactAddressController);

    ContactAddressController.$inject = ['ContactAddress', 'CoreService', 'GenericEntityDatatableService', '$uibModal',
        'ConfirmationService', '$q'];

    function ContactAddressController(ContactAddress, CoreService, GenericEntityDatatableService, $uibModal,
                                      ConfirmationService, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'contact-address'

        vm.descriptor = {
            title: 'Contact Address',
            entityClassHumanized: 'Contact Address',
            entityStateName: entityStateName,
            service: ContactAddress,
            serviceMethod: 'findAllByFacility',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'company',
                    title: 'Company'
                },
                {
                    name: 'phone',
                    title: 'Phone'
                },
                {
                    name: 'fax',
                    title: 'Fax'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction('acuity'), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('acuity'), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Contact Address',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/contact-address/update/contact-address-dialog.html',
                controller: 'ContactAddressDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            company: null,
                            alias: null,
                            address: null,
                            address2: null,
                            city: null,
                            zipCode: null,
                            phone: null,
                            fax: null,
                            website: null,
                            note: null,
                            email: null,
                            cellphone: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/contact-address/update/contact-address-dialog.html',
                controller: 'ContactAddressDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ContactAddress', function (ContactAddress) {
                        return ContactAddress.get({id: id}).$promise;
                    }]
                }
            }).result
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Contact Address').result.then(function () {
                    ContactAddress.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

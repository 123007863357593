/**
 * Created by PpTMUnited on 5/23/2017.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('udtGroupSessionDetail', UdtGroupSessionDetail);

    UdtGroupSessionDetail.$inject = ['GroupSessionDetails', 'toastr', '$interval', 'CoreService', 'TypeLevelCare'];

    function UdtGroupSessionDetail(GroupSessionDetails, toastr, $interval, CoreService, TypeLevelCare) {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-group-session-detail/udt-group-session-detail.html',
            scope: {
                groupSessionDetails: '=',
                groupSession: '=',
            },
            link: linkFunc
        };

        return directive;

        function linkFunc($scope) {
            $scope.typeLevelCare = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));

            GroupSessionDetails.getJsonTemplate({id: $scope.groupSessionDetails.id}, function (jsonTemplate) {
                $scope.json = jsonTemplate;

                $scope.watcherJson =
                    $scope.$watch('json', function (newValue, oldValue, scope) {
                        if (oldValue !== newValue) {
                            if (!$scope.autoSaveJsonId) {
                                $scope.intervalJson = $interval($scope.autoSaveJson, 1500);
                            }

                            $scope.lastUpdateJson = new Date();
                            $scope.autoSaveJsonId = Math.random();
                        }
                    }, true);
            });

            $scope.watcher =
                $scope.$watch('groupSessionDetails', function (newValue, oldValue, scope) {
                    if (oldValue !== newValue) {
                        if (!$scope.autoSaveId) {
                            $scope.interval = $interval($scope.autoSave, 1500);
                        }

                        $scope.lastUpdate = new Date();
                        $scope.autoSaveId = Math.random();
                    }
                }, true);

            $scope.groupSessionDetails = $scope.groupSessionDetails ? $scope.groupSessionDetails : null;
            $scope.update = null;
            $scope.json = null;
            $scope.isSaving = false;
            $scope.isSavingJson = false;
            $scope.autoSaveId = null;
            $scope.autoSaveJsonId = null;
            $scope.savedId = null;
            $scope.savedJsonId = null;
            $scope.interval = null;
            $scope.intervalJson = null;

            $scope.changePresentStatus = function () {
                $scope.groupSessionDetails.present = !$scope.groupSessionDetails.present;
            }

            $scope.autoSaveJson = function () {
                if ($scope.isSavingJson || !$scope.autoSaveJsonId) {

                    return;
                }

                if ($scope.savedJsonId === $scope.autoSaveJsonId) {
                    $scope.autoSaveJsonId = null;
                    $scope.savedJsonId = null;

                    $interval.cancel($scope.intervalJson);

                    return;
                }

                var now = new Date();

                if (((now.getTime() - $scope.lastUpdateJson.getTime()) / 1000) < 1.5) {

                    return;
                }

                $scope.isSavingJson = true;
                $scope.savedJsonId = angular.copy($scope.autoSaveJsonId);

                var groupSessionDetails = angular.copy($scope.groupSessionDetails);
                var json = $scope.json

                GroupSessionDetails.autoSave({
                    groupSessionDetails: groupSessionDetails,
                    jsonTemplate: json
                }, function (data) {
                    $scope.isSavingJson = false;

                    toastr.success("Saved " + $scope.groupSession.title + ' - ' +
                        $scope.groupSessionDetails.chart.firstName + ' ' +
                        $scope.groupSessionDetails.chart.lastName + ' ' + $scope.groupSessionDetails.chart.mrNo);
                }, function () {
                    $scope.isSavingJson = false;

                    toastr.error("Error saving " + $scope.groupSession.title + ' - ' +
                        $scope.groupSessionDetails.chart.firstName + ' ' +
                        $scope.groupSessionDetails.chart.lastName + ' ' + $scope.groupSessionDetails.chart.mrNo);
                });
            }

            $scope.autoSave = function () {
                if ($scope.isSaving || !$scope.autoSaveId) {

                    return;
                }

                if ($scope.savedId === $scope.autoSaveId) {
                    $scope.autoSaveId = null;
                    $scope.savedId = null;

                    $interval.cancel($scope.interval);

                    return;
                }

                var now = new Date();

                if (((now.getTime() - $scope.lastUpdate.getTime()) / 1000) < 1.5) {

                    return;
                }

                $scope.isSaving = true;
                $scope.savedId = angular.copy($scope.autoSaveId);

                var groupSessionDetails = angular.copy($scope.groupSessionDetails);

                GroupSessionDetails.update(groupSessionDetails, function (data) {
                    $scope.isSaving = false;

                    toastr.success("Saved " + $scope.groupSession.title + ' - ' +
                        $scope.groupSessionDetails.chart.firstName + ' ' +
                        $scope.groupSessionDetails.chart.lastName + ' ' + $scope.groupSessionDetails.chart.mrNo);
                }, function () {
                    $scope.isSaving = false;

                    toastr.error("Error saving " + $scope.groupSession.title + ' - ' +
                        $scope.groupSessionDetails.chart.firstName + ' ' +
                        $scope.groupSessionDetails.chart.lastName + ' ' + $scope.groupSessionDetails.chart.mrNo);
                });
            }

            $scope.$on('$destroy', function () {
                if ($scope.watcher) {
                    $scope.watcher();
                }

                if ($scope.watcherJson) {
                    $scope.watcherJson();
                }
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProClaimConfController', ProClaimConfController);

    ProClaimConfController.$inject = ['DTOptionsBuilder', '$state', 'ProClaimConf', 'DTColumnBuilder', 'GUIUtils', '$q', '$filter',
        'CoreService', '$compile', '$scope', 'entity', '$uibModal'];

    function ProClaimConfController (DTOptionsBuilder, $state, ProClaimConf, DTColumnBuilder, GUIUtils, $q, $filter,
                                      CoreService, $compile, $scope, entity, $uibModal ) {
        var vm = this;

        vm.proClaimConfs = [];
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.title = 'Pro Claim Confs';
        vm.entityClassHumanized = 'Pro Claim Confs';
        vm.entityStateName = 'pro-claim-conf';
        vm.facility = CoreService.getCurrentFacility();

        vm.evaluationTemplate = entity;

        vm.search = search;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            ProClaimConf.filter({
                facilityId: vm.facility.id,
                evaluationTemplateId: vm.evaluationTemplate.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.proClaimConfs = result;
                defer.resolve(vm.proClaimConfs);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Insurances').renderWith(function (data, type, full) {
                var row = "";

                _.forEach(full.insuranceCarriers, function(carrier) {
                    row += row.length == 0 ? carrier.name : ", " + carrier.name;
                });

                return row;
            }),
            DTColumnBuilder.newColumn('minTime').withTitle('Min Time'),
            DTColumnBuilder.newColumn('maxTime').withTitle('Max Time'),
            DTColumnBuilder
                .newColumn('isDefault')
                .withTitle('Default')
                .renderWith(function (data, type, full) {
                    return GUIUtils.colorHtmlYesNo(data);
                }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ' +
                'ng-click="vm.edit(' + data.id + ')">' +
                '<i class="fa fa-edit" aria-hidden="true"></i>Edit' +
                '</a>' +
                '</li>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link red" ' +
                'ng-click="vm.del(' + data.id + ')">' +
                '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/pro-claim-conf/pro-claim-conf-dialog.html',
                controller: 'ProClaimConfDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: {
                        id: null,
                        isDefault: false,
                        proCptConfs: [{}],
                        facility: vm.facility,
                        evaluationTemplate: vm.evaluationTemplate
                    }
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            }, function (result) {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/pro-claim-conf/pro-claim-conf-dialog.html',
                controller: 'ProClaimConfDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ProClaimConf', function (ProClaimConf) {
                        return ProClaimConf.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            }, function () {
            });
        }

        function del(id) {
            $uibModal.open({
                templateUrl: 'app/entities/pro-claim-conf/pro-claim-conf-delete-dialog.html',
                controller: 'ProClaimConfDeleteController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ProClaimConf', function (ProClaimConf) {
                        return ProClaimConf.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            }, function () {
            });
        }
    }
})();

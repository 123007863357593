(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GlobalMessageNotificationController', GlobalMessageNotificationController);

    GlobalMessageNotificationController.$inject = ['GlobalMessage', 'GlobalMessageViewed', 'CoreService', '$interval'];

    function GlobalMessageNotificationController(GlobalMessage, GlobalMessageViewed, CoreService, $interval) {
        var vm = this;

        vm.globalMessages = [];
        vm.close = close;
        vm.init = init;

        init();

        $interval(init, 30000);

        function init() {
            GlobalMessage.byCorporation({id: CoreService.getCorporation().id}, function(result) {
                vm.globalMessages = result;
            })
        }

        function close(id) {
            GlobalMessageViewed.save({globalMessage: {id: id}}, function () {
                init();
            })
        }
    }
})();

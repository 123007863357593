(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationMedicationController', EvaluationMedicationController);

    EvaluationMedicationController.$inject = ['$scope', '$state', 'EvaluationMedication', 'entity', 'PopupService'];

    function EvaluationMedicationController($scope, $state, EvaluationMedication, entity, PopupService) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationMedications = [];

        vm.loadAll = loadAll;
        vm.add = add;

        loadAll();

        function loadAll() {
            if (!vm.evaluation.medications) {

                return;
            }

            EvaluationMedication.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationMedications = result;
            });
        }

        function add() {
            PopupService.showEvaluationPatientMedicationPopup(vm.evaluation).result.then(function () {
                loadAll();
            });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmReferralContact', CrmReferralContact);

    CrmReferralContact.$inject = ['$resource', 'DateUtils'];

    function CrmReferralContact ($resource, DateUtils) {
        var resourceUrl =  'api/crm-referral-contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob= DateUtils.convertLocalDateFromServer(data.dob);
                    }
                    return data;
                }

            },
            'update': { method:'PUT' },
            'findAllByFilter': {url: 'api/crm-referral-contacts/filter', method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportNoteTemplateController', IncidentReportNoteTemplateController);

    IncidentReportNoteTemplateController.$inject = ['GenericEntityDatatableService', 'CoreService',
        'IncidentReportNoteTemplate', 'GUIUtils', '$uibModal', '$q',
        'ConfirmationService'];

    function IncidentReportNoteTemplateController(GenericEntityDatatableService, CoreService,
                                                  IncidentReportNoteTemplate, GUIUtils, $uibModal, $q,
                                                  ConfirmationService) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Incident Report Note Templates',
            newButtonTitle: 'New Incident Report Note Templates',
            entityClassHumanized: 'Incident Report Note Templates',
            entityStateName: 'incident-report-note-template',
            service: IncidentReportNoteTemplate,
            serviceMethod: 'getAllByFacility',
            params: params,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Enabled',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.enabled);
                    }
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('incident-report-note-template'),
                angular.extend({}, GenericEntityDatatableService.getCloneAction(), {
                    action: function (data) {
                        return clone(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
        }

        function clone(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/incident-report-note-template/clone/incident-report-note-template-clone-dialog.html',
                controller: 'IncidentReportNoteTemplateCloneController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['IncidentReportNoteTemplate', function (IncidentReportNoteTemplate) {
                        return IncidentReportNoteTemplate.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Note Template').result.then(function () {
                    IncidentReportNoteTemplate.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

'use strict';

angular
    .module('bluebookApp')
    .directive('searchSelect', searchSelect);

searchSelect.$inject = [];
function searchSelect() {

    return {
        restrict: 'E',
        templateUrl: 'app/entities/super-bill/search-select/search-select.html',
        scope: {
            name: '@',
            placeholder: '@',
            tabsConf: '=',
            ngModel: '=',
            compareProperty: '=?',
            ngDisabled: '=?',
            ngRequired: '=?',
            onSelect: '&',
            render: '&?'
        },
        link: linkFunc

    };

    /* private helper methods*/

    function linkFunc($scope) {

        $scope.getOptions = getOptions;
        $scope.getSelectTitleValue = getSelectTitleValue;
        $scope.onSelectFn = onSelectFn;

        init();

        function init() {
            $scope.ngModel = [];
            $scope.tabs = angular.copy($scope.tabsConf);
            $scope.searchQuery = '';
            $scope.currentElement = null;
            $scope.currentIndex = null;
            $scope.searchQuery = '';
            $scope.selected = {
                value : null
            };
            $scope.ngDisabled = !!$scope.ngDisabled;
            $scope.ngRequired = !!$scope.ngRequired;
            $scope.isOpen = false;
        }

        function getSelectTitleValue(element) {
            if (element && element.constructor !== Array) {
                if($scope.render){
                    return $scope.render()(element);
                }else {
                    return element;
                }
            }
        }

        function unsetAll(obj, val) {
            _.forEach(Object.keys(obj), function (k) {
                obj[k] = val;
            });
        }

        function onSelectFn() {
            if ($scope.onSelect) {
                $scope.onSelect();
                unsetAll($scope.selected.value, false);
                $scope.ngModel = [];
            } else {
                console.log('You must to define onSelect() function.');
            }
        }

        function  getOptions(element) {
            $scope.currentElement = element;
            if(element && element.func){
                element.func($scope.searchQuery, element);
            }
        }

        $scope.check = function(value, checked) {
            if($scope.compareProperty){
                var idx = _.findIndex($scope.ngModel, function(o) { return o[$scope.compareProperty]=== value[$scope.compareProperty]; });
            } else {
                var idx = $scope.ngModel.indexOf(value);
            }
             //
            if (idx >= 0 && !checked) {
                $scope.ngModel.splice(idx, 1);
            }
            if (idx < 0 && checked) {
                $scope.ngModel.push(value);
            }
        };

        $scope.$watch('searchQuery', function () {
            getOptions($scope.currentElement);
        }, true);

    }
}

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Medication', Medication);

    Medication.$inject = ['$resource'];

    function Medication ($resource) {
        var resourceUrl =  'api/medications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'findAllByFilter': {url: 'api/medications/filter', method: 'GET', isArray: true},
            'frequencies': { method: 'GET', url: "api/medications/frequencies", isArray: true}
        });
    }
})();

(function () {
    'use strict';
    angular.module('app.validation.rule', ['validation'])
        .config(['$validationProvider',
            function ($validationProvider) {
                //Set validation html error
                $validationProvider.setErrorHTML(function (msg) {
                    //console.log(msg)
                    return "<p class=\"pull-left has-error\">" + msg + "</p>";
                });
                $validationProvider.showSuccessMessage = false;

                // Setup `ip` validation
                var expression = {
                    ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                };

                var validMsg = {
                    ip: {
                        error: 'This isn\'t ip address',
                        success: 'It\'s ip'
                    }
                };

                $validationProvider.setExpression(expression) // set expression
                    .setDefaultMsg(validMsg); // set valid message

                // Setup `regexp` validation
                $validationProvider
                    .setExpression({
                        regexp: function (value, scope, element, attrs) {
                            if (scope.hasOwnProperty('validatorIf') && !scope.validatorIf) {
                                return true;
                            }
                            //console.log(attrs)
                            if (!attrs.regexp) {
                                return true;
                            }
                            var regExp = new RegExp(attrs.regexp, "m");
                            return (element.val()) ? regExp.test(element.val()) : true;
                        }
                    })
                    .setDefaultMsg({
                        regexp: {
                            error: 'This should be a valid expression'
                        }
                    });

                // Setup `dateGTN` validation (Date Greater Than Now)
                $validationProvider
                    .setExpression({
                        dateGTN: function (value, scope, element, attrs) {
                            if (scope.hasOwnProperty('validatorIf') && !scope.validatorIf) {
                                return true;
                            }
                            var validatorFormat = (attrs.validatorFormat) ? attrs.validatorFormat : 'MM/DD/YYYY';
                            var dateMoment = moment(value, validatorFormat);
                            return (dateMoment.isValid() && dateMoment.isAfter(moment()));
                        }
                    })
                    .setDefaultMsg({
                        dateGTN: {
                            error: 'The date should be a valid date after now'
                        }
                    });

                // Setup `requiredIf` validation
                $validationProvider
                    .setExpression({
                        requiredIf: function (value, scope, element, attrs) {
                            if (!scope.validatorIf) {
                                return true;
                            }
                            return !!value;
                        }
                    })
                    .setDefaultMsg({
                        requiredIf: {
                            error: 'This field is required.'
                        }
                    });


                // Setup `requiredSignature` validation
                $validationProvider
                    .setExpression({
                        requiredSignature: function (value, scope, element, attrs) {
                            return !!value;
                        }
                    })
                    .setDefaultMsg({
                        requiredSignature: {
                            error: 'The signature is required.'
                        }
                    });
            }
        ]);

}).call(this);

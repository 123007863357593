(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationDietDialogController', EvaluationDietDialogController);

    EvaluationDietDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity',
        'EvaluationDiet', 'Evaluation', 'Diet', 'TypeFoodDiets', 'CoreService'];

    function EvaluationDietDialogController($timeout, $scope, $uibModalInstance, entity,
                                            EvaluationDiet, Evaluation, Diet, TypeFoodDiets, CoreService) {
        var vm = this;

        vm.evaluationDiet = entity;
        vm.diet = vm.evaluationDiet.diet;
        vm.chart = vm.diet.chart;
        vm.typeFoodDiets = TypeFoodDiets.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.form = {};

        vm.clear = clear;
        vm.save = save;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};

        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.evaluationDiet.id !== null) {
                EvaluationDiet.update(vm.evaluationDiet, onSaveSuccess, onSaveError);
            } else {
                EvaluationDiet.save(vm.evaluationDiet, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:evaluationDietUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.diet.startDate;
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.diet.endDate;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssueAlertController', UrgentIssueAlertController);

    UrgentIssueAlertController.$inject = ['$scope', '$state', '$q', '$filter', 'CoreService', 'UrgentIssues'];

    function UrgentIssueAlertController($scope, $state, $q, $filter, CoreService, UrgentIssues) {
        var vm = this;

        vm.title = 'Urgent Issue Alert';
        vm.entityClassHumanized = 'Urgent Issue Alert';
        vm.urgentIssues = [];
        vm.searchQuery = "";

        vm.search = search;

        init();

        function init() {
            UrgentIssues.byFacility({facilityId: CoreService.getCurrentFacility().id, searchQuery: vm.searchQuery}, function (urgentIssues) {
                vm.urgentIssues = _.chain(urgentIssues)
                    .groupBy("chartId")
                    .map(function (urgentIssues, chartId) {
                        return {
                            chart: {
                                id: chartId,
                                pictureRefId: urgentIssues[0].pictureRefId
                            },
                            chartId: chartId,
                            firstName: urgentIssues[0].firstName,
                            lastName: urgentIssues[0].lastName,
                            fullName: urgentIssues[0].firstName + " " + urgentIssues[0].lastName,
                            mrNo: urgentIssues[0].mrNo,
                            typeLevelCare: urgentIssues[0].typeLevelCare,
                            urgentIssues: urgentIssues
                        };
                    })
                    .orderBy("fullName")
                    .value();
            });
        }

        function search() {
            init();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('searchIcd10', searchIcd10);

    searchIcd10.$inject = ['Chart', 'Diagnoses', 'CoreService', 'FacilityIcd10'];

    function searchIcd10(Chart, Diagnoses, CoreService, FacilityIcd10) {

        return {
            restrict: 'E',
            templateUrl: 'app/components/udt/search-icd10/search-icd10.html',
            scope: {
                chart: '=',
                ngModel: '='
            },
            link: linkFunc
        };

        /* private helper methods*/

        function linkFunc($scope, element) {
            // init();

            function init() {
                $scope.ngModel = $scope.ngModel ? $scope.ngModel : [];
                $scope.activeIcd10 = [];
                $scope.facilityIcd10 = [];
                $scope.isOpen = false;
                $scope.selected = {};

                if ($scope.chart) {
                    Diagnoses.findAllActiveDiagnosesByChartId({id: $scope.chart}, function (result) {
                        _.forEach(result, function (element) {
                            var idx = _.findIndex($scope.activeIcd10, function (o) {
                                return o.id === element.icd10.id;
                            });

                            if (idx == -1) {
                                $scope.activeIcd10.push(element.icd10);
                            }
                        });
                    });
                }

                FacilityIcd10.findAllByFilter({facilityId: CoreService.getCurrentFacility().id}, function (result) {
                    _.forEach(result, function (element) {
                        var idx = _.findIndex($scope.facilityIcd10, function (o) {
                            return o.id === element.id;
                        });

                        if (idx == -1) {
                            $scope.facilityIcd10.push(element.icd10);
                        }
                    });
                });

                if ($scope.ngModel.length === 0) {
                    $scope.selected = {};
                } else {
                    $scope.selected = {};
                    _.forEach($scope.ngModel, function (element) {
                        $scope.selected[element.id] = true;
                    });
                }

                $(document).bind('click', function (event) {
                    var isClickedElementChildOfPopup = element
                        .find(event.target)
                        .length > 0;

                    if (isClickedElementChildOfPopup) {
                        return;
                    }

                    $scope.isOpen = false;
                    $scope.$apply();
                });
            }

            $scope.check = function (value, checked) {
                var idx = _.findIndex($scope.ngModel, function (o) {
                    return o.id === value.id;
                });

                if (checked && idx < 0) {
                    $scope.ngModel.push(value);
                } else if (!checked && idx >= 0) {
                    $scope.ngModel.splice(idx, 1);
                }
            };

            $scope.$watch('isOpen', function () {
                if ($scope.isOpen) {
                    init();
                }
            }, true);
        }
    }
})();

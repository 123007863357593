(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartsMarketerController', ChartsMarketerController);

    ChartsMarketerController.$inject = ['$uibModal', '$q', 'CoreService', 'Employee', 'GUIUtils', '$state', 'marketer',
        '$rootScope', 'Chart', 'GenericEntityDatatableService', 'TypeLevelCare'];

    function ChartsMarketerController($uibModal, $q, CoreService, Employee, GUIUtils, $state, marketer,
                                      $rootScope, Chart, GenericEntityDatatableService, TypeLevelCare) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Patient by Outreach',
            entityClassHumanized: 'Patient by Outreach',
            entityStateName: 'charts',
            service: Chart,
            serviceMethod: "marketer",
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[0, 'desc']]}
            ],
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    },
                    options: [{key: "width", value: "200px"}]
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY")
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: "width", value: "130px"}]
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return moment(data.admissionDate).format("MM/DD/YYYY");
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: 'Treatment Days',
                    render: function (data) {
                        return status === "ARCHIVE" || status === "CLOSED" ?
                            moment(data.dischargeDate).diff(moment(data.admissionDate), 'days') :
                            moment(new Date()).diff(moment(data.admissionDate), 'days');
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    title: 'Referral',
                    render: function (data) {
                        return data.referrer ? data.referrer : '-'
                    }
                },
                {
                    name: null,
                    title: 'Outreach',
                    render: function (data) {
                        return data.marketer === null ? " " : data.marketer.lastName + ", " + data.marketer.firstName;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.id});
                    },
                })
            ],
            filters: [
                {
                    name: 'statuses',
                    defaultValue: function () {
                        return 'CURRENT';
                    },
                    visible: false,
                    read: function (filter) {
                        if (filter.statuses === null) {
                            return ['CURRENT', 'ARCHIVE', 'CLOSED'];
                        } else if (filter.statuses === 'ARCHIVE') {
                            return ['ARCHIVE', 'CLOSED'];
                        } else return filter.statuses
                    }
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: "marketerId",
                    read: function (filter) {
                        return filter.marketerId ? filter.marketerId.id : null;
                    },
                    values: [],
                    placeholder: 'Select outreach...',
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        var filter = {page: 0, size: 20, query: search, facilityId: CoreService.getCurrentFacility().id};

                        Employee.findAllByFilter(filter, function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    type: 'select',
                    defaultValue: function () {
                        return marketer;
                    }
                },
                {
                    name: 'typeLevelOfCareId',
                    read: function (filter) {
                        return filter.typeLevelOfCareId ? filter.typeLevelOfCareId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeLevelCare.findAllByFacility(
                            CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Level of Care ...',
                    type: 'select',
                },
                {
                    name: 'referrer',
                    type: 'string',
                    placeholder: 'Referral Source...',
                },
                {
                    name: 'fromAdmissionDate',
                    // title: 'Day of Birth',
                    placeholder: 'From Admission Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                },
                {
                    name: 'toAdmissionDate',
                    // title: 'Day of Birth',
                    placeholder: 'To Admission Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                }
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'All',
                            iClass: 'fa fa-users',
                            ngClass: function (filter) {
                                return filter.statuses === null ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.statuses = null;
                            }
                        },
                        {
                            name: 'Current',
                            iClass: 'fa fa-dashboard',
                            ngClass: function (filter) {
                                return filter.statuses === 'CURRENT' ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.statuses = 'CURRENT';
                            },
                        },
                        {
                            name: 'Archive',
                            iClass: 'fa fa-archive',
                            ngClass: function (filter) {
                                return filter.statuses === 'ARCHIVE' ?
                                    ['active', 'label-success'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.statuses = 'ARCHIVE';
                            },
                        }
                    ]
                }
            ]
        }
    }
})();

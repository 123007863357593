(function () {
    'use strict';

    angular
        .module('udt')
        .filter('employeeName', employeeName);

    function employeeName() {
        return employeeNameFilter;

        function employeeNameFilter(value) {

            return value == null ? "-" :
                value.lastName + ", " + value.firstName;
        }
    }
})();

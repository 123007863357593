(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('crm-referral-source-type', {
            parent: 'entity',
            url: '/crm-referral-source-type',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'CrmReferralSourceTypes'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/crm-referral-source-type/crm-referral-source-types.html',
                    controller: 'CrmReferralSourceTypeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('crm-referral-source-type.new', {
            parent: 'crm-referral-source-type',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-referral-source-type/crm-referral-source-type-dialog.html',
                    controller: 'CrmReferralSourceTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: [ 'CoreService', function (CoreService) {
                            return {
                                name: null,
                                id: null,
                                corporation: CoreService.getCorporation()
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-referral-source-type', null, { reload: 'crm-referral-source-type' });
                }, function() {
                    $state.go('crm-referral-source-type');
                });
            }]
        })
        .state('crm-referral-source-type.edit', {
            parent: 'crm-referral-source-type',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-referral-source-type/crm-referral-source-type-dialog.html',
                    controller: 'CrmReferralSourceTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CrmReferralSourceType', function(CrmReferralSourceType) {
                            return CrmReferralSourceType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-referral-source-type', null, { reload: 'crm-referral-source-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('crm-referral-source-type.delete', {
            parent: 'crm-referral-source-type',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-referral-source-type/crm-referral-source-type-delete-dialog.html',
                    controller: 'CrmReferralSourceTypeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CrmReferralSourceType', function(CrmReferralSourceType) {
                            return CrmReferralSourceType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-referral-source-type', null, { reload: 'crm-referral-source-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

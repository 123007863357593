(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CareCoordinationTemplateDialogController', CareCoordinationTemplateDialogController);

    CareCoordinationTemplateDialogController.$inject = ['$timeout', '$scope', '$stateParams',
        '$uibModalInstance', 'entity', 'CareCoordinationTemplate',
        'Employee', 'Icd10Search', 'CoreService'];

    function CareCoordinationTemplateDialogController ($timeout, $scope, $stateParams,
                                                       $uibModalInstance, entity, CareCoordinationTemplate,
                                                       Employee, Icd10Search, CoreService) {
        var vm = this;

        vm.careCoordinationTemplate = entity;

        vm.icd10S = [];
        vm.employees = [];
        vm.corporation = CoreService.getCorporation();

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.getIcd10S = getIcd10S;

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getIcd10S(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10S = result;
                });
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.careCoordinationTemplate.id !== null) {
                CareCoordinationTemplate.update(vm.careCoordinationTemplate, onSaveSuccess, onSaveError);
            } else {
                CareCoordinationTemplate.save(vm.careCoordinationTemplate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:careCoordinationTemplateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmNoteDialogController', CrmNoteDialogController);

    CrmNoteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CrmNote'];

    function CrmNoteDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, CrmNote) {
        var vm = this;

        vm.crmNote = entity;

        vm.clear = clear;
        vm.save = save;
        vm.setValidationClass = setValidationClass;
        vm.editorCreated = editorCreated;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.crmNote.id !== null) {
                CrmNote.update(vm.crmNote, onSaveSuccess, onSaveError);
            } else {
                CrmNote.save(vm.crmNote, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:crmNoteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function editorCreated(editor, htmlText) {
            editor.root.innerHTML = htmlText;
        }
    }
})();

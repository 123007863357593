(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportNoteTemplateAssignDialogController', IncidentReportNoteTemplateAssignDialogController);

    IncidentReportNoteTemplateAssignDialogController.$inject = ['$timeout', '$uibModalInstance', 'toastr',
        'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$filter', '$compile',
        '$scope', 'IncidentReportNoteTemplate', 'CoreService',
        'IncidentReportNote', 'IncidentReport', 'DataTablesService',
        'incidentReport'];

    function IncidentReportNoteTemplateAssignDialogController($timeout, $uibModalInstance, toastr,
                                                              DTOptionsBuilder, DTColumnBuilder, $q, $filter, $compile,
                                                              $scope, IncidentReportNoteTemplate, CoreService,
                                                              IncidentReportNote, IncidentReport, DataTablesService,
                                                              incidentReport) {

        var vm = this;

        vm.incidentReport = incidentReport;
        vm.assign = [];
        vm.dtInstance = {};
        vm.reload = false;
        vm.selected = {};
        vm.selectAll = false;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.assign = assign;
        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            IncidentReportNoteTemplate.findAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.incidentReportNoteTemplates = result;
                defer.resolve(result);
            });

            return defer.promise;
        }).withDOM('t').withDisplayLength(vm.itemsPerPage).withOption('order', [1, 'asc'])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            }).withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Template Name')
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function assign() {
            IncidentReportNote.create({
                incidentReport: vm.incidentReport,
                incidentReportNotes: _.map(DataTablesService.getSelectedIds(vm.selected), function (id) {
                    return parseInt(id)
                })
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $uibModalInstance.close();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function search() {
            vm.page = 1;

            transition();
        }

        function getFilter() {
            return {
                page: vm.page - 1,
                size: vm.itemsPerPage,
                incidentTypes: _.map(vm.incidentReport.incidentTypes, 'id'),
                enabled: true,
                facilityId: vm.incidentReport.facility.id,
                searchQuery: vm.searchQuery
            }
        }

        function transition() {
            vm.dtInstance.reloadData();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MessageAllCheckController', MessageAllCheckController);

    MessageAllCheckController.$inject = ['$uibModalInstance', 'totalCharts'];

    function MessageAllCheckController($uibModalInstance, totalCharts) {
        var vm = this;

        vm.totalCharts = totalCharts;

        vm.clear = clear;
        vm.confirmSend = confirmSend;

        function confirmSend() {
            $uibModalInstance.close(true);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

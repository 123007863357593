(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Campaign', Campaign);

    Campaign.$inject = ['$resource'];

    function Campaign ($resource) {
        var resourceUrl =  'api/campaigns/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'byFilter': {url: 'api/campaigns/filter', method: 'GET', isArray: true},
            'sendMessage': {url: 'api/campaigns/message', method: 'GET', isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }},
            'resendMessage': { method: 'GET', url: "api/campaigns/resend-message", isArray: false,
                transformResponse: function (data) {
                    if (data === 'true') {
                        return {value: true};
                    }
                    return {value: false};
                }}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FaxNumberDialogController', FaxNumberDialogController);

    FaxNumberDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'FacilityPhoneNumber', 'FaxNumber', 'DTColumnBuilder', 'DTOptionsBuilder', '$q',
        'CountryState', 'DataTablesService', '$compile'];

    function FaxNumberDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                       FacilityPhoneNumber, FaxNumber, DTColumnBuilder, DTOptionsBuilder, $q,
                                       CountryState, DataTablesService, $compile) {
        var vm = this;

        vm.dtInstance = {};
        vm.faxNumber = {};
        vm.phoneNumbers = [];
        vm.countryState = CountryState.query();
        vm.selected = {};
        vm.selectAll = false;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.setUniqueIds = DataTablesService.setUniqueIds;

        vm.clear = clear;
        vm.orderPhoneNumber = orderPhoneNumber;
        vm.getAvailablePhoneNumbers = getAvailablePhoneNumbers;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function orderPhoneNumber() {
            vm.isSaving = true;

            var ids = _.map(vm.getSelectedIds(vm.selected), function (id) {
                return parseInt(id);
            });

            var phoneNumbers =
                _.map(
                    _.filter(vm.phoneNumbers, function (faxNumber) {
                        return ids.indexOf(faxNumber.id) != -1;
                    }), function (faxNumber) {
                        return faxNumber.phoneNumber;
                    });

            FaxNumber.orderFaxNumbers(getFilterOrderNumber(phoneNumbers), function () {
                $uibModalInstance.close();
            }, function () {
                vm.isSaving = false;
            });
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            defer.resolve(vm.phoneNumbers);
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('ltp').withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        })
            .withDisplayLength(5)
            .withOption('lengthMenu', [[5, 15, 25, 50, -1], [5, 15, 25, 50, "All"]])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });


        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle("").withOption('width', '25px').notSortable()
                .renderWith(function (data, type, full, meta) {
                    vm.selected[full.id] = false;

                    return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
                }),
            DTColumnBuilder.newColumn(null).withTitle('Phone Number').renderWith(function (data, type, full) {
                return data.phoneNumber;
            }),
            DTColumnBuilder.newColumn(null).withTitle('State').renderWith(function (data, type, full) {
                return data.region ? data.region : "-";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Postal Code').renderWith(function (data, type, full) {
                return data.postalCode ? data.postalCode : "-";
            }),
        ];

        function getFilter() {
            var filter = {};

            filter.pattern = vm.faxNumber.pattern;
            filter.areaCode = vm.faxNumber.areaCode;
            filter.state = vm.faxNumber.state;

            return filter
        }

        function getFilterOrderNumber(phoneNumbers) {
            var filter = {};

            filter.phoneNumbers = phoneNumbers;

            return filter
        }

        function getAvailablePhoneNumbers() {
            FacilityPhoneNumber.getAvailablePhoneNumbers(getFilter(), function (result) {
                vm.phoneNumbers = vm.setUniqueIds(result);
                transition();
            });
        }

        function transition() {
            vm.dtInstance.reloadData();
        }


    }
})();

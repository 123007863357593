(function () {
    'use strict';

    angular
        .module('udt')
        .directive('ngSrcAuthInsuranceBack', ngSrcAuthInsuranceBack);

    ngSrcAuthInsuranceBack.$inject = ['$timeout', '$http', 'AuthServerProvider', 'CoreService', '$sanitize'];

    function ngSrcAuthInsuranceBack($timeout, $http, AuthServerProvider, CoreService, $sanitize) {
        var directive = {
            restrict: 'A',
            link: link
        }

        return directive;

        function link($scope, element, attrs) {
            function revokeObjectURL() {
                if ($scope.objectURL) {
                    URL.revokeObjectURL($scope.objectURL);
                }
            }

            $scope.$on('$destroy', function () {
                revokeObjectURL();
            });

            attrs.$set('src', CoreService.insuranceBackPictureDefault().picture);

            if (attrs.ngSrcAuthInsuranceBack) {
                //request file data from server, attaching auth header and token
                var requestConfig = {
                    responseType: 'arraybuffer',
                    method: 'Get',
                    headers: {'Authorization': 'Bearer ' + AuthServerProvider.getToken()},
                    url: '/api/insurance-back-pictures/get-thumbnail' + '/' + attrs.ngSrcAuthInsuranceBack,
                    cache: 'false',
                    transformResponse: function (response, headers) {
                        var blob = new Blob(
                            [ response ],
                            { type: headers('Content-Type')}
                        );

                        $scope.objectURL = window.URL.createObjectURL(blob);

                        return $scope.objectURL
                    }
                };

                $http(requestConfig)
                    .success(function (data) {
                        attrs.$set('src', $sanitize(data));
                    });
            }
        }
    }
})();

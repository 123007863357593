(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabCompendiumRestrictionController', LabCompendiumRestrictionController);

    LabCompendiumRestrictionController.$inject = ['$q', 'GUIUtils', 'CoreService', 'LabCompendiumRestriction',
        'GenericEntityDatatableService', 'PopupService', '$uibModal',
        'ConfirmationService']

    function LabCompendiumRestrictionController($q, GUIUtils, CoreService, LabCompendiumRestriction,
                                                GenericEntityDatatableService, PopupService, $uibModal,
                                                ConfirmationService) {
        var vm = this;

        vm.laboratory = null;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Lab Compendium Restrictions',
            newAction: false,
            entityClassHumanized: 'Lab Compendium Restrictions',
            entityStateName: 'lab-compendium-restrictions',
            service: LabCompendiumRestriction,
            serviceMethod: 'filter',
            pagination: 'remote',
            params: params,
            options: [{key: 'aaSorting', value: [[1, 'asc']]}],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'code',
                    title: 'Test Code',
                },
                {
                    name: 'description',
                    title: 'Description',
                },
                {
                    title: 'Laboratory',
                    render: function () {
                        return vm.laboratory.name;
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('send-lab-compendium'), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New LabCompendium Restriction',
                            action: function () {
                                return add();
                            },
                            disabled: function () {
                                return vm.laboratory === null;
                            }
                        })
                    ]
                }
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Laboratory',
                            action: function (filter) {
                                return $q(function (resolve) {
                                    PopupService.showSelectFacilityLabPopup(filter).result.then(function (result) {
                                        filter.laboratoryId = {id: result.labId, name: result.name};

                                        vm.laboratory = {id: result.labId, name: result.name};
                                        vm.descriptor.title = 'Lab Compendium Restriction - ' + vm.laboratory.name

                                        resolve();
                                    });
                                })
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'laboratoryId',
                    visible: false,
                    read: function (filter) {
                        return filter.laboratoryId ? filter.laboratoryId.id : null;
                    }
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/lab-compendium-restriction/update/lab-compendium-restriction-dialog.html',
                controller: 'LabCompendiumRestrictionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            facility: CoreService.getCurrentFacility(),
                            laboratory: vm.laboratory
                        };
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Lab Compendium Restriction').result.then(function () {
                    LabCompendiumRestriction.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('GenericEntityDatatableService', GenericEntityDatatableService);

    GenericEntityDatatableService.$inject = ['$state'];

    function GenericEntityDatatableService($state) {

        var service = {
            getNewAction: getNewAction,
            getDetailsAction: getDetailsAction,
            getEditAction: getEditAction,
            getDeleteAction: getDeleteAction,
            getPrintAction: getPrintAction,
            getViewPdfAction: getViewPdfAction,
            getSendAction: getSendAction,
            getPlayAction: getPlayAction,
            getDownloadAction: getDownloadAction,
            getRefreshAction: getRefreshAction,
            getSignAction: getSignAction,
            getHourGlassAction: getHourGlassAction,
            getCalendarAction: getCalendarAction,
            getCloneAction: getCloneAction,
            getCommentAction: getCommentAction,
            getAddCommentAction: getAddCommentAction,
            getGraphAction: getGraphAction,
            getCheckAction: getCheckAction,
            getViewChartAction: getViewChartAction,
            getInfoAction: getInfoAction,
            getDiagnosisAction: getDiagnosisAction,
            getInsuranceAction: getInsuranceAction,
            getCareTeamAction: getCareTeamAction,
            getRestoreAction: getRestoreAction,
            addLogoAction: addLogoAction
        };

        return service;

        function getNewAction() {

            return {
                aClass: 'dd-link green',
                iClass: 'fa fa-plus-circle',
                ngClass: function() { return ['bg-green'] }
            }
        }

        function getDetailsAction(entity) {

            return {
                name: 'View Details',
                action: function (data) {
                    $state.go(entity + '.details', {id: data.id});
                },
                aClass: 'dd-link blue',
                iClass: 'fa fa-file-text-o'
            }
        }

        function getEditAction(entity) {

            return {
                name: 'Edit',
                action: function (data) {
                    $state.go(entity + '.edit', {id: data.id});
                },
                aClass: 'dd-link orange',
                iClass: 'fa fa-edit'
            }
        }

        function getDeleteAction(entity) {

            return {
                name: 'Delete',
                action: function (data) {
                    $state.go(entity + '.delete', {id: data.id});
                },
                aClass: 'dd-link red',
                iClass: 'fa fa-trash-o'
            }
        }

        function getPrintAction() {

            return {
                name: 'Print',
                aClass: 'dd-link green',
                iClass: 'fa fa-print',
            }
        }

        function getViewPdfAction() {

            return {
                name: 'View Pdf',
                aClass: 'dd-link red',
                iClass: 'fa fa-file-pdf-o',
            }
        }

        function getSendAction() {

            return {
                name: 'Send',
                aClass: 'dd-link blue',
                iClass: 'fa fa-paper-plane'
            }
        }

        function getPlayAction() {

            return {
                name: 'Play',
                aClass: 'dd-link green',
                iClass: 'fa fa-play'
            }
        }

        function getDownloadAction() {

            return {
                name: 'Download',
                aClass: 'dd-link green',
                iClass: 'fa fa-download'
            }
        }

        function getRefreshAction() {

            return {
                name: 'Refresh',
                aClass: 'dd-link green',
                iClass: 'fa fa-refresh',
                ngClass: function() { return ['bg-white'] }
            }
        }

        function getSignAction() {

            return {
                name: 'Sign',
                aClass: 'dd-link green',
                iClass: 'fa fa-pencil',
                ngClass: function() { return ['bg-green'] }
            }
        }

        function getHourGlassAction() {

            return {
                aClass: 'dd-link white',
                iClass: 'fa fa-hourglass-2',
            }
        }

        function getCalendarAction() {

            return {
                aClass: 'dd-link white',
                iClass: 'fa fa-calendar',
            }
        }

        function getCloneAction(entity) {

            return {
                name: 'Clone',
                action: function (data) {
                    $state.go(entity + '.clone', {id: data.id});
                },
                aClass: 'dd-link blue',
                iClass: 'fa fa-clone'
            }
        }

        function getCommentAction(entity) {

            return {
                name: 'Comment',
                aClass: 'dd-link green',
                iClass: 'fa fa-comments-o'
            }
        }

        function getAddCommentAction(entity) {

            return {
                name: 'Comment',
                aClass: 'dd-link green',
                iClass: 'fa fa-comment-o'
            }
        }

        function getGraphAction(entity) {

            return {
                name: 'Graph',
                aClass: 'dd-link red',
                iClass: 'fa fa-bar-chart'
            }
        }

        function getCheckAction(entity) {

            return {
                name: 'Check',
                aClass: 'dd-link white',
                iClass: 'fa fa-check-circle'
            }
        }

        function getViewChartAction(entity) {

            return {
                name: 'View Chart',
                aClass: 'dd-link gray',
                iClass: 'fa fa-user'
            }
        }

        function getInfoAction(entity) {

            return {
                name: 'View Info',
                aClass: 'dd-link blue',
                iClass: 'fa fa-info-circle'
            }
        }

        function getDiagnosisAction(entity) {

            return {
                name: 'Diagnosis',
                aClass: 'dd-link green',
                iClass: 'fa fa-ambulance'
            }
        }

        function getInsuranceAction(entity) {

            return {
                name: 'Insurance',
                aClass: 'dd-link green',
                iClass: 'fa fa-id-card'
            }
        }

        function getCareTeamAction(entity) {

            return {
                name: 'Care Team',
                aClass: 'dd-link green',
                iClass: 'fa fa-user-md'
            }
        }

        function getRestoreAction(entity) {

            return {
                name: 'Restore',
                aClass: 'dd-link green',
                iClass: 'fa fa-check-circle'
            }
        }

        function addLogoAction(entity) {

            return {
                name: 'New Logo',
                aClass: 'dd-link green',
                iClass: 'fa fa-plus'
            }
        }
    }
})();

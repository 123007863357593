(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DocumoController', DocumoController);

    DocumoController.$inject = ['$scope', '$state', 'Documo', '$q', 'ConfirmationService', '$uibModal',
        'GenericEntityDatatableService'];

    function DocumoController ($scope, $state, Documo, $q, ConfirmationService, $uibModal,
                               GenericEntityDatatableService) {
        var vm = this;

        vm.descriptor = {
            title: 'Documo',
            newButtonTitle: 'New Documo',
            entityClassHumanized: 'Documo',
            entityStateName: 'documo',
            service: Documo,
            serviceMethod: 'findAllDocumos',
            newAction: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            enableSearch: false,
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Documo',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0
                            },
                        })
                    ]
                }
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/documo/update/documo-dialog.html',
                controller: 'DocumoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            token: null,
                            api: null,
                            faxNumber: null,
                            id: null
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/documo/update/documo-dialog.html',
                controller: 'DocumoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Documo', function (Documo) {
                        return Documo.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Documo').result.then(function () {
                    Documo.delete({id: id}, function () {
                        resolve();
                    });
                })
            });
        }
    }
})();

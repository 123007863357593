(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationBillingDatesDialogController', EvaluationBillingDatesDialogController);

    EvaluationBillingDatesDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity'];

    function EvaluationBillingDatesDialogController ($timeout, $scope, $uibModalInstance, entity) {
        var vm = this;

        vm.evaluation = angular.copy(entity);

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startBilling = false;
        vm.datePickerOpenStatus.endBilling = false;

        vm.endDateOptions = {};
        vm.startDateOptions = {};

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.getDateDiff = getDateDiff;
        vm.changeStartDate = changeStartDate;
        vm.changeEndDate = changeEndDate;
        vm.calEndDate = calEndDate;

        init();

        function init() {
            getDateDiff();
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            $uibModalInstance.close(vm.evaluation);
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function changeStartDate() {
            if (vm.evaluation.startBilling) {
                vm.endDateOptions.minDate = vm.evaluation.startBilling;

                if (vm.dateDiff > 0) {
                    calEndDate();
                } else if (vm.evaluation.endBilling) {
                    if (vm.evaluation.startBilling > vm.evaluation.endBilling) {
                        vm.evaluation.endBilling = null;
                        vm.dateDiff = null;
                    } else {
                        getDateDiff();
                    }
                }
            } else {
                vm.endDateOptions.minDate = null;
                vm.dateDiff = null;
            }
        }

        function changeEndDate() {
            vm.getDateDiff();
        }

        function getDateDiff() {
            if (vm.evaluation.billable && vm.evaluation.startBilling && vm.evaluation.endBilling) {
                var startD = moment(vm.evaluation.startBilling);
                var endD = moment(vm.evaluation.endBilling);

                vm.dateDiff = endD.diff(startD, 'minutes');
            }
        }

        function calEndDate() {
            if (vm.dateDiff > 0) {
                vm.dateDiff = vm.dateDiff >= 0 ? vm.dateDiff : 0;
                var billingEndDate = moment(vm.evaluation.startBilling)
                    .add(vm.dateDiff, 'minutes')
                    .format("MM/DD/YYYY hh:mm A");
                vm.evaluation.endBilling = new Date(billingEndDate);
            } else {
                vm.evaluation.endBilling = null;
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationEditSignatureDialogController', EvaluationEditSignatureDialogController);

    EvaluationEditSignatureDialogController.$inject = ['$timeout', '$uibModalInstance', 'entity'];

    function EvaluationEditSignatureDialogController ($timeout, $uibModalInstance, entity) {
        var vm = this;

        vm.signature = angular.copy(entity);

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dayOfService = false;

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            $uibModalInstance.close(vm.signature);
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationViewController', EvaluationViewController);

    EvaluationViewController.$inject = ['entity', 'jsonTemplate', 'tokens', 'Evaluation', 'Chart', 'EvaluationComment', '$timeout', '$scope',
        'toastr', '$uibModal', 'AlertService', 'EvaluationDiagnosis', 'EvaluationAllergy',
        'EvaluationDiet', 'EvaluationGlucose', 'EvaluationVital', 'EvaluationWeight',
        'PendingPdfChartReport', 'PopupService', 'CoreService', 'Contacts', '$state', '$window',
        'EvaluationTemplateFile', 'REPORT_TYPE', 'TAB', 'access', 'ACTIONS'];

    function EvaluationViewController(entity, jsonTemplate, tokens, Evaluation, Chart, EvaluationComment, $timeout,
                                      $scope, toastr, $uibModal, AlertService, EvaluationDiagnosis, EvaluationAllergy,
                                      EvaluationDiet, EvaluationGlucose, EvaluationVital, EvaluationWeight,
                                      PendingPdfChartReport, PopupService, CoreService, Contacts, $state, $window,
                                      EvaluationTemplateFile, REPORT_TYPE, TAB, access, ACTIONS) {
        var vm = this;

        vm.evaluation = entity;
        vm.jsonTemplate = jsonTemplate;
        vm.chart = vm.evaluation.chart;
        vm.contact = vm.evaluation.contact;
        vm.evaluationDiagnoses = [];
        vm.evaluationAllergies = [];
        vm.evaluationDiets = [];
        vm.evaluationVitals = [];
        vm.evaluationGlucose = [];
        vm.evaluationWeight = null;
        vm.evaluationTemplateFiles = [];

        vm.TAB = TAB;

        vm.accessCareCoordinationView = access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW] ?
            access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW].roles.join() : null;

        vm.print = print;
        vm.fax = fax;
        vm.showChartRevokeSignPopup = showChartRevokeSignPopup;
        vm.getHeightInchesToFeetInches = getHeightInchesToFeetInches;
        vm.toggleSideBySide = toggleSideBySide;
        vm.togglePdfExport = togglePdfExport;
        vm.search = search;
        vm.open = open;
        vm.getEvaluationFiles = getEvaluationFiles;
        vm.exportPdf = exportPdf;

        init();

        function init() {
            $window.scrollTo(0, 0);

            getDateDiff();

            Chart.get({id: vm.evaluation.chartId}, function (chart) {
                vm.chart = chart;
            });

            if (vm.evaluation.contact) {
                Contacts.get({id: vm.evaluation.contact.id}, function (result) {
                    vm.contact = result;
                })
            }

            EvaluationDiagnosis.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationDiagnoses = result;
            });

            EvaluationAllergy.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationAllergies = result;
            });

            EvaluationDiet.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationDiets = result;
            });

            EvaluationVital.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                if (result.length > 0) {
                    vm.evaluationVitals = result[0];
                }
            });

            EvaluationGlucose.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                if (result.length > 0) {
                    vm.evaluationGlucose = result[0];
                    vm.glucoseInterventions = _.map(vm.evaluationGlucose.glucoseInterventions, function (glucoseIntervention) {
                        return glucoseIntervention.name;
                    }).join(', ');
                }
            });

            EvaluationWeight.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                if (result.length > 0) {
                    vm.evaluationWeight = result[0];
                    vm.evaluationWeight.bmi = _.ceil(vm.evaluationWeight.bmi, 2)
                }
            });

            // if (vm.evaluation.chartSignatureId) {
            //     Evaluation.getSignature({id: vm.evaluation.id, type: 'CHART'}, function (data) {
            //         vm.chartSignature = data;
            //     });
            // }
            //
            // if (vm.evaluation.guarantorSignatureId) {
            //     Evaluation.getSignature({id: vm.evaluation.id, type: 'GUARANTOR'}, function (data) {
            //         vm.guarantorSignature = data;
            //     });
            // }
            //
            // if (vm.evaluation.staffSignatureId) {
            //     Evaluation.getSignature({id: vm.evaluation.id, type: 'STAFF'}, function (data) {
            //         vm.staffSignature = data;
            //     });
            // }
            //
            // if (vm.evaluation.staffReviewSignatureId) {
            //     Evaluation.getSignature({id: vm.evaluation.id, type: 'REVIEW'}, function (data) {
            //         vm.staffReviewSignature = data;
            //     });
            // }
            //
            // if (vm.evaluation.revokeChartSignatureId) {
            //     Evaluation.getSignature({id: vm.evaluation.id, type: 'REVOKE'}, function (data) {
            //         vm.revokeChartSignature = data;
            //     });
            // }

            vm.formCKEditorTokensConfig = {
                ckeditor: {
                    tokens: tokens
                }
            };
        }

        function getDateDiff() {
            if (vm.evaluation.billable && vm.evaluation.startBilling && vm.evaluation.endBilling) {
                var startD = moment(vm.evaluation.startBilling);
                var endD = moment(vm.evaluation.endBilling);

                vm.dateDiff = endD.diff(startD, 'minutes');
            }
        }

        function print() {
            vm.isPrinting = true;

            PendingPdfChartReport.save({
                chart: vm.chart,
                objectId: vm.evaluation.id,
                name: vm.evaluation.name,
                reportType: REPORT_TYPE.EVALUATION
            }, onPrintSuccess, onPrintError);
        }

        function showChartRevokeSignPopup() {
            if (PopupService.checkSigPlusExtLiteExtension()) {
                startSign();
            } else {
                PopupService.showPatientSignaturePopup(vm.chart).result.then(function (data) {
                    signRevoke(data)
                });
            }
        }

        function signRevoke(signature) {
            Evaluation.revoke({ids: [vm.evaluation.id], signature: signature}, function (result) {
                $state.reload();
            })
        }

        function getHeightInchesToFeetInches(height) {

            return CoreService.getHeightInchesToFeetInches(height);
        }

        function fax() {
            PopupService.showContactAddressPickupPopup().result.then(function (result) {
                Evaluation.fax({
                    id: vm.evaluation.id, moreIds: result.ids
                }, function () {
                    toastr.success("Fax in queue");
                }, function () {
                    _.forEach(result, function (contactAddress) {
                        toastr.error('Error sending fax to ' + (contactAddress.name ? contactAddress.name : "") + " Fax: " + contactAddress.fax + ". Try again later.");
                    })
                });
            });
        }

        function toggleSideBySide() {
            vm.sideBySide = !vm.sideBySide;
            vm.pdfExport = false;

            if (vm.sideBySide) {
                vm.searchQuery = vm.evaluation.name;
                vm.search();
            }
        }

        function togglePdfExport() {
            vm.pdfExport = !vm.pdfExport;
            vm.sideBySide = false;

            if (vm.pdfExport) {
                getEvaluationFiles();
            }
        }

        function search() {
            vm.compareWith = null;
            Evaluation.findAllByFilter({
                chartId: vm.evaluation.chart.id,
                facilityId: vm.evaluation.chart.facility.id,
                searchQuery: vm.searchQuery,
                status: 'Finalized',
                page: 0,
                size: 20
            }, function (result) {
                vm.compareWith = null;
                vm.evaluations = result;
            })
        }

        function getEvaluationFiles() {
            vm.pdfExportId = null;
            EvaluationTemplateFile.findAllByEvaluationTemplate({
                evaluationId: vm.evaluation.evaluationTemplateId,
                searchQuery: vm.fileSearchQuery,
                page: 0,
                size: 100
            }, function (result, headers) {
                vm.evaluationTemplateFiles = result;
            });
        }

        function exportPdf() {
            vm.isPrinting = true;

            PendingPdfChartReport.save({
                chart: vm.chart,
                objectId: vm.evaluation.id,
                evaluationTemplateFileId: vm.pdfExportId,
                reportType: REPORT_TYPE.EVALUATION_TEMPLATE_FILE,
                name: vm.evaluation.name
            }, onPrintSuccess, onPrintError);
        }

        function onPrintSuccess(result) {
            vm.isPrinting = false;
            vm.pdfExportId = null;
            vm.compareWith = null;

            toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
        }

        function onPrintError() {
            vm.isPrinting = false;
            toastr.error("We couldn't generate the report.");
        }

        function open() {
            $state.go("care-delivery-view", {id: vm.compareWith});
        }

        function startSign() {
            var message = {
                "firstName": "",
                "lastName": "",
                "eMail": "",
                "location": "",
                "imageFormat": 1,
                "imageX": 500,
                "imageY": 100,
                "imageTransparency": false,
                "imageScaling": false,
                "maxUpScalePercent": 0.0,
                "rawDataFormat": "ENC",
                "minSigPoints": 25
            };

            top.document.addEventListener('SignResponse', SignResponse, false);
            var messageData = JSON.stringify(message);
            var element = document.createElement("MyExtensionDataElement");
            element.setAttribute("messageAttribute", messageData);
            document.documentElement.appendChild(element);
            var evt = document.createEvent("Events");
            evt.initEvent("SignStartEvent", true, false);
            element.dispatchEvent(evt);
        }

        function SignResponse(event) {
            var str = event.target.getAttribute("msgAttribute");
            var objResponse = JSON.parse(str);
            var obj = null;

            if (typeof (objResponse) === 'string') {
                obj = JSON.parse(objResponse);
            } else {
                obj = JSON.parse(JSON.stringify(objResponse));
            }

            if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined") {
                toastr.error(obj.errorMsg);
            } else {
                if (obj.isSigned) {
                    signRevoke("data:image/jpg;base64," + obj.imageData);
                }
            }

            top.document.removeEventListener('SignResponse', SignResponse, false);
        }
    }
})();

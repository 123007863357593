(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('chart-export', {
                parent: 'entity',
                url: '/chart-export',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ChartExports'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart-export/chart-exports.html',
                        controller: 'ChartExportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ChartPictureLicense', ChartPictureLicense);

    ChartPictureLicense.$inject = ['$resource'];

    function ChartPictureLicense ($resource) {
        var resourceUrl =  'api/chart-picture-licenses/:id';

        return $resource(resourceUrl, {}, {
            'create': {url: 'api/chart-pictures-license/create', method: 'POST'},
            'getOriginalBase64ByChart': {url: 'api/chart-pictures-license/get-original-base-64/by-chart/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        var result = {image: data};
                    }

                    return result;
                }},
            'getCroppedImageByChart': {url: 'api/chart-pictures-license/get-cropped-image/by-chart/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        var result = {image: data};
                    }

                    return result;
                }},
            'getOneByChart': {url: 'api/chart-pictures-license/by-chart/:id', method: 'GET'}
        });
    }
})();

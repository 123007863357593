(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgEligEmployeeDialogController', ProgEligEmployeeDialogController);

    ProgEligEmployeeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProgEligEmployee', 'Employee', 'CoreService'];

    function ProgEligEmployeeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProgEligEmployee, Employee, CoreService) {
        var vm = this;

        vm.progEligEmployee = entity;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};
        vm.openCalendar = openCalendar;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.getEmployee = getEmployee;
        vm.corporation = CoreService.getCorporation();

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.progEligEmployee.id !== null) {
                ProgEligEmployee.update(vm.progEligEmployee, onSaveSuccess, onSaveError);
            } else {
                ProgEligEmployee.save(vm.progEligEmployee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:progEligEmployeeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.progEligEmployee.startDate;
        }

        function validateStartDate(){
            vm.startDateOptions.maxDate = vm.progEligEmployee.endDate;
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getEmployee(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.corporation.id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RoomController', RoomController);

    RoomController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Room', '$uibModal', 'ConfirmationService',
        '$q'];

    function RoomController (GenericEntityDatatableService, CoreService, Room, $uibModal, ConfirmationService,
                             $q) {
        var vm = this;

        var params = CoreService.getFilterByFacility(CoreService.getCurrentFacility());

        vm.descriptor = {
            title: 'Rooms',
            newButtonTitle: 'New Room',
            entityClassHumanized: 'Room',
            entityStateName: 'room',
            service: Room,
            serviceMethod: 'byfacility',
            params: params,
            newAction: false,
            columns: [
               {
                    name: 'name',
                    title: 'Name',
               },
               {
                    name: null,
                    title: 'Building',
                    render: function(data) {
                       return data != null ? data.building.name : " ";
                    }
               },
               {
                    name: 'sex',
                    title: 'Gender',
               }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Room',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/room/update/room-dialog.html',
                controller: 'RoomDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            sex: null,
                            notes: null,
                            facility: CoreService.getCurrentFacility(),
                            id: null
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/room/update/room-dialog.html',
                controller: 'RoomDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Room', function (Room) {
                        return Room.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Room').result.then(function () {
                    Room.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

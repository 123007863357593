(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidFacilityController', CovidFacilityController);

    CovidFacilityController.$inject = ['CovidFacility', 'CoreService', 'CovidFacilityLab', '$state',
                                       'GenericEntityDatatableService', 'GUIUtils'];

    function CovidFacilityController (CovidFacility, CoreService, CovidFacilityLab, $state,
                                      GenericEntityDatatableService, GUIUtils) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'covid-facility'

        CovidFacilityLab.findAllByFacility({id: CoreService.getCurrentFacility().id}, function (result) {
            if (result.length > 0) {
                vm.covidFacilityLab = result[0];
            } else {
                vm.descriptor.title = 'Covid Facility (Missing Covid FacilityLab!)';
            }
        });

        vm.descriptor = {
            title: 'Covid Facility',
            entityClassHumanized: 'Covid Facility',
            entityStateName: entityStateName,
            service: CovidFacility,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'logo',
                    title: 'Logo',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(!!data.logo);
                    }
                }
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Covid Facility',
                            action: function () {
                                $state.go('covid-facility.new');
                            },
                            disabled: function (filter) {
                                return !vm.covidFacilityLab;
                            }
                        }),
                    ]
                },
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction(entityStateName),
                GenericEntityDatatableService.getDeleteAction(entityStateName),
            ]
        }
    }
})();

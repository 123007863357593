(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ConcurrentReviews', ConcurrentReviews);

    ConcurrentReviews.$inject = ['$resource', 'DateUtils'];

    function ConcurrentReviews($resource, DateUtils) {
        var resourceUrl = 'api/concurrent-reviews/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.authorizationDate = DateUtils.convertDateTimeFromServer(data.authorizationDate);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                        data.nextReviewDate = DateUtils.convertDateTimeFromServer(data.nextReviewDate);

                        if (data.employee) {
                            data.employeeName = data.employee.firstName + ' ' + data.employee.lastName;
                        }
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByChart': {url: 'api/concurrent-reviews/by-chart', method: 'GET', isArray: true},
            'findLastByChart': {url: 'api/concurrent-reviews/by-chart/:id/last', method: 'GET'}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationMarsCommentDialogController', PatientMedicationMarsCommentDialogController);

    PatientMedicationMarsCommentDialogController.$inject = ['$uibModalInstance', 'PatientMedicationTakeComment',
        'ParseLinks', 'AlertService', 'takes', 'toastr', 'chart', 'CoreService'];

    function PatientMedicationMarsCommentDialogController($uibModalInstance, PatientMedicationTakeComment,
                                                          ParseLinks, AlertService, takes, toastr, chart, CoreService) {
        var vm = this;

        vm.title = "Comments";

        vm.page = 1;
        vm.itemsPerPage = 6;
        vm.comments = [];
        vm.takes = takes;
        vm.message = null;
        vm.chart = chart;
        vm.facility = CoreService.getCurrentFacility();

        vm.clear = clear;
        vm.transition = transition;
        vm.addComment = addComment;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        loadAll();

        function loadAll() {
            if (vm.takes.ids.length == 1) {
                PatientMedicationTakeComment.findAllByTake({
                        id: takes.ids[0],
                        page: vm.page - 1,
                        size: vm.itemsPerPage
                    },

                    function (data, headers) {
                        vm.links = ParseLinks.parse(headers('link'));
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        vm.comments = data;
                    },

                    function (error) {
                        AlertService.error(error.data.message);
                    });
            }
        }

        function transition() {
            loadAll();
        }

        function addComment() {
            vm.isSaving = true;
            PatientMedicationTakeComment.addComment({
                ids: takes.ids,
                message: vm.message,
                facilityId: vm.facility.id,
                chart: vm.chart
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            if (result.length > 0) {
                toastr.error('Error Adding Comment, please try again later');
            } else {
                toastr.success('The Comment was added successful');
            }

            if (vm.takes.ids.length == 1) {
                vm.message = null;

                transition();
            } else {
                clear();
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

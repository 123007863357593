(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AllergiesHistoryNewController', AllergiesHistoryNewController);

    AllergiesHistoryNewController.$inject = ['Allergies', 'DTOptionsBuilder', 'DTColumnBuilder', 'DataTablesService',
                                            'chart', '$timeout', '$uibModalInstance'];

    function AllergiesHistoryNewController(Allergies, DTOptionsBuilder, DTColumnBuilder, DataTablesService,
                                           chart, $timeout, $uibModalInstance) {
        var vm = this;
        vm.chart = chart;
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.allergies = [];

        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;

        loadAll();

        function loadAll() {
            Allergies.findAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.allergies = result;
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getFilter() {
            var filter = {};

            filter.chartId = vm.chart.id;
            filter.active = false;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function transition() {
            loadAll();
        }
    }
})();

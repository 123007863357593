(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartDetailController', ChartDetailController);

    ChartDetailController.$inject = ['fancyboxService', 'CoreService', 'chart', '$state', 'Chart',
        'UrgentIssues', '$timeout', '$stateParams', '$document', 'Diet', 'Allergies', 'anchor',
        'previousState', '$scope', '$window', '$uibModal', 'facility', 'Auth',
        'ACTIONS', 'access', '$location', '$anchorScroll', 'AlertService', 'toastr', 'Evaluation',
        'PopupService', '$rootScope', 'ChartPicture', 'ChartPictureLicense'];

    function ChartDetailController(fancyboxService, CoreService, chart, $state, Chart,
                                   UrgentIssues, $timeout, $stateParams, $document, Diet, Allergies, anchor,
                                   previousState, $scope, $window, $uibModal, facility, Auth,
                                   ACTIONS, access, $location, $anchorScroll, AlertService, toastr, Evaluation,
                                   PopupService, $rootScope, ChartPicture, ChartPictureLicense) {

        var vm = this;

        vm.chart = chart;
        vm.title = 'Patient Details';

        vm.accessCloseChart = access[ACTIONS.ACTION_PATIENT_CLOSE_CHART] ? access[ACTIONS.ACTION_PATIENT_CLOSE_CHART].roles.join() : null;
        vm.accessEditId = access[ACTIONS.ACTION_PATIENT_EDIT_ID] ? access[ACTIONS.ACTION_PATIENT_EDIT_ID].roles.join() : null;
        vm.accessEditInfo = access[ACTIONS.ACTION_PATIENT_EDIT_INFO] ? access[ACTIONS.ACTION_PATIENT_EDIT_INFO].roles.join() : null;
        vm.accessInsuranceView = access[ACTIONS.ACTION_PATIENT_INSURANCE_VIEW] ? access[ACTIONS.ACTION_PATIENT_INSURANCE_VIEW].roles.join() : null;
        vm.accessContactsView = access[ACTIONS.ACTION_PATIENT_CONTACTS_VIEW] ? access[ACTIONS.ACTION_PATIENT_CONTACTS_VIEW].roles.join() : null;
        vm.accessCareTeamView = access[ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW] ? access[ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW].roles.join() : null;
        vm.accessCareCoordinationView = access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW] ? access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW].roles.join() : null;
        vm.accessAllergiesView = access[ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW] ? access[ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW].roles.join() : null;
        vm.accessDietsView = access[ACTIONS.ACTION_PATIENT_DIETS_VIEW] ? access[ACTIONS.ACTION_PATIENT_DIETS_VIEW].roles.join() : null;
        vm.accessVitalsView = access[ACTIONS.ACTION_PATIENT_VITALS_VIEW] ? access[ACTIONS.ACTION_PATIENT_VITALS_VIEW].roles.join() : null;
        vm.accessGlucoseView = access[ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW] ? access[ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW].roles.join() : null;
        vm.accessWeightView = access[ACTIONS.ACTION_PATIENT_WEIGHT_VIEW] ? access[ACTIONS.ACTION_PATIENT_WEIGHT_VIEW].roles.join() : null;
        vm.accessAlcoholView = access[ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW] ? access[ACTIONS.ACTION_PATIENT_ALCOHOL_VIEW].roles.join() : null;
        vm.accessDrugsView = access[ACTIONS.ACTION_PATIENT_DRUGS_VIEW] ? access[ACTIONS.ACTION_PATIENT_DRUGS_VIEW].roles.join() : null;
        vm.accessAlcoholAndDrugsView = vm.accessAlcoholView || vm.accessDrugsView ? _.uniq(vm.accessAlcoholView.split(',').concat(vm.accessDrugsView.split(','))).join() : null;
        vm.accessFileView = access[ACTIONS.ACTION_PATIENT_FILE_VIEW] ? access[ACTIONS.ACTION_PATIENT_FILE_VIEW].roles.join() : null;
        vm.accessChartNoteView = access[ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW] ? access[ACTIONS.ACTION_PATIENT_CHART_NOTE_VIEW].roles.join() : null;
        vm.accessConcurrentReviewView = access[ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW] ? access[ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW].roles.join() : null;
        vm.accessVerifytxView = access[ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW] ? access[ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW].roles.join() : null;
        vm.accessDiagnosesView = access[ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW] ? access[ACTIONS.ACTION_PATIENT_DIAGNOSES_VIEW].roles.join() : null;
        vm.accessLabOrdersView = access[ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW] ? access[ACTIONS.ACTION_PATIENT_LAB_ORDERS_VIEW].roles.join() : null;
        vm.accessELabsView = access[ACTIONS.ACTION_PATIENT_ELABS_VIEW] ? access[ACTIONS.ACTION_PATIENT_ELABS_VIEW].roles.join() : null;
        vm.accessGroupSessionView = access[ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW] ? access[ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW].roles.join() : null;
        vm.accessEvaluationView = access[ACTIONS.ACTION_PATIENT_EVALUATION_VIEW] ? access[ACTIONS.ACTION_PATIENT_EVALUATION_VIEW].roles.join() : null;
        vm.accessMedicationView = access[ACTIONS.ACTION_PATIENT_MEDICATION_VIEW] ? access[ACTIONS.ACTION_PATIENT_MEDICATION_VIEW].roles.join() : null;
        vm.accessDeleteCareDelivery = access[ACTIONS.ACTION_DELETE_CARE_DELIVERY] ? access[ACTIONS.ACTION_DELETE_CARE_DELIVERY].roles.join() : null;

        vm.previousState = previousState;
        vm.unknownUrgentIssue = [];
        vm.urgentIssues = UrgentIssues.byChart({id: $stateParams.id});
        vm.menu = $rootScope.employeeMenu.menu;

        ChartPicture.getCroppedImageByChart({id: chart.id}, function (result) {
            vm.pictureRef = result.image;
        }, function () {
            vm.pictureRef = CoreService.patientPictureDefault().picture;
        });

        // UrgentIssues.unknown({id: chart.id}, function (result) {
        //     if (result && result.length > 0) {
        //         showUnknownIssuesPopup(result);
        //     }
        // });

        UrgentIssues.countUnknown({id: chart.id}, function (result) {
            if (result && result.count > 0) {
                showUnknownIssuesPopup(result);
            }
        });

        ChartPictureLicense.getOneByChart({id: vm.chart.id}, function (result) {
            vm.chartPictureLicense = result;
        });

        vm.imageDetail = imageDetail;
        vm.toggleItem = toggleItem;
        vm.goToItem = goToItem;
        vm.editInfo = editInfo;
        vm.executePrint = loadAllAllergies;
        vm.openReport = openReport;
        vm.showPreviousCharts = showPreviousCharts;
        vm.showBlueAlertPopup = showBlueAlertPopup;
        vm.showFacesheetTpl = showFacesheetTpl;
        vm.showLevelCareHistory = showLevelCareHistory;
        vm.showDischargeDateHistory = showDischargeDateHistory;
        vm.showTrackHistory = showTrackHistory;
        vm.copyChart = copyChart;
        vm.editImage = editImage;
        vm.viewImageLicense = viewImageLicense;
        vm.editImageLicense = editImageLicense;
        vm.openChangeStatusChart = openChangeStatusChart;
        vm.checkField = checkField;

        vm.actionAuth = Auth.rolesActionAuth;

        //Scroll to Top on view loaded
        $scope.$on('$viewContentLoaded', function () {
            if (anchor) {
                $location.hash(anchor);
                $anchorScroll();
            } else {
                $window.scrollTo(0, 0);
            }
        });

        function imageDetail(picture, title) {
            if (picture) {
                fancyboxService.fancyboxPlus()({
                    'href': picture.picture,
                    'title': title,
                    'transitionIn': 'fade',
                    'transitionOut': 'elastic'
                });
            }
        }

        function toggleItem(attr) {
            CoreService.togglePanel(attr);
        }

        function goToItem(attr) {
            var target = angular.element(document.getElementById(attr));
            CoreService.showPanel(attr);
            $document.scrollToElement(target, 80, 400);
        }

        function loadAllAllergies() {
            var dd = {
                content: [],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true
                    },
                    quote: {
                        italics: true
                    },
                    small: {
                        fontSize: 8
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };
            var header = {
                text: 'Food Memo',
                style: 'header'
            };
            var information = {
                text: 'Patient special dietary',
                style: 'subheader',
                alignment: 'center'
            };
            var personalInfo = {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    body: [[{
                        text: 'Name: ',
                        style: 'tableHeader'
                    }, vm.chart.lastName + ", " + vm.chart.firstName],
                        [{text: 'MR Number: ', style: 'tableHeader'}, vm.chart.mrNo]]
                }, layout: 'noBorders'
            };
            var image = {image: vm.pictureRef, alignment: 'center', width: 450, height: 450};
            var foodAllergies = {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: []
                },
                alignment: 'center',
                layout: 'noBorders'
            };
            var activeDiets = {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: []
                },
                alignment: 'center',
                layout: 'noBorders'
            };
            dd.content.push(header);
            dd.content.push(information);
            dd.content.push(personalInfo);
            dd.content.push(image);

            Allergies.findAllAllergiesByChart({id: chart.id, allergenType: 'Food'}, function (result) {
                vm.allergies = result;
                foodAllergies.table.body.push([{
                    text: 'Food Allergies',
                    style: 'tableHeader',
                    decoration: 'underline'
                }]);
                for (var i = 0; i < vm.allergies.length; i++) {
                    foodAllergies.table.body.push([{text: vm.allergies[i].name}]);
                }
                if (vm.allergies.length) {
                    dd.content.push(foodAllergies);
                }

                Diet.findAllActiveDietsByChart({id: chart.id}, function (result) {
                    vm.diets = result;
                    activeDiets.table.body.push([{text: 'Diets', style: 'tableHeader', decoration: 'underline'}]);
                    for (var i = 0; i < vm.diets.length; i++) {
                        activeDiets.table.body.push([{text: vm.diets[i].typeFoodDiets.name}]);
                    }
                    if (vm.diets.length) {
                        dd.content.push(activeDiets);
                    }
                    if (vm.diets.length || vm.allergies.length) {
                        vm.pdf = pdfMake.createPdf(dd);
                        vm.pdf.print();
                    } else
                        alert("this patient does not have diet");
                });
            });
        }

        function editInfo(id) {
            $uibModal.open({
                templateUrl: 'app/entities/chart/update/chart-dialog.html',
                controller: 'ChartDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: ['Chart', function (Chart) {
                        return Chart.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                $state.reload();
            }, function (result) {
            });
        }

        function openReport() {
            $uibModal.open({
                templateUrl: 'app/reports/report-dialog-chart.html',
                controller: 'ReportDialogChartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
            });
        }

        function showPreviousCharts() {
            $uibModal.open({
                templateUrl: 'app/entities/chart/preview/chart-preview-dialog.html',
                controller: 'ChartPreviewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            }).result.then(function (id) {
                $state.go('chart', {id: id}, {reload: true});
            });
        }

        function showFacesheetTpl() {
            $uibModal.open({
                templateUrl: 'app/entities/facesheet-tpl/chart-report/old/facesheet-tpl-report.html',
                controller: 'FacesheetTplReportController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            });
        }

        function showUnknownIssuesPopup() {
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/chart/old/unknown/urgent-issue-unknown.html',
                controller: 'UrgentIssuesUnknownController',
                controllerAs: 'vm',
                windowTopClass: 'custom-dialog-styles with-icon',
                size: 'lg',
                resolve: {
                    chart: chart
                }
            });
        }

        function showBlueAlertPopup() {
            $uibModal.open({
                templateUrl: 'app/entities/urgent-issues/chart/old/list/urgent-issues-dialog.html',
                controller: 'UrgentIssuesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            status: null,
                            id: null,
                            chart: chart,
                            employees: [],
                            facility: CoreService.getCurrentFacility()
                        };
                    }
                }
            })
        }

        function showLevelCareHistory() {
            $uibModal.open({
                templateUrl: 'app/entities/type-level-care/chart/old/type-level-care-history.html',
                controller: 'TypeLevelCareHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showDischargeDateHistory() {
            $uibModal.open({
                templateUrl: 'app/entities/chart/discharge-date-history/old/chart-discharge-date-history.html',
                controller: 'ChartDischargeDateHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function showTrackHistory() {
            $uibModal.open({
                templateUrl: 'app/entities/track/chart/old/track-history.html',
                controller: 'TrackHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function copyChart() {
            $uibModal.open({
                templateUrl: 'app/entities/chart/copy/chart-copy-dialog.html',
                controller: 'ChartCopyController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    chart: vm.chart
                }
            }).result.then(function (facility) {
                PopupService.showCreatePatientPopup(vm.chart, facility).result.then(function (result) {
                    CoreService.setCurrentFacility(facility);
                    $state.transitionTo('chart', {id: result.id}, {
                        location: true,
                        inherit: true,
                        notify: true,
                        reload: true
                    });
                });
            });
        }

        function editImage() {
            ChartPicture.getOriginalBase64ByChart({id: vm.chart.id}, function (result) {
                openCropImagePopup(result);
            }, function () {
                openCropImagePopup(null);
            });
        }

        function openCropImagePopup(result){
            PopupService.showCropImageChartPopup(result).result.then(function (error) {
            }, function (data) {
                if (data) {
                    ChartPicture.create({
                        chartId: vm.chart.id,
                        cropped: data.cropped,
                        image: data.image
                    }, function () {
                        $state.reload();
                    });
                }
            });
        }

        function viewImageLicense() {
            ChartPictureLicense.getCroppedImageByChart({id: vm.chart.id}, function (picture) {
                if (picture) {
                    fancyboxService.fancyboxPlus()({
                        'href': picture.image,
                        'title': 'License Picture',
                        'transitionIn': 'fade',
                        'transitionOut': 'elastic'
                    });
                }
            })
        }

        function editImageLicense() {
            ChartPictureLicense.getOriginalBase64ByChart({id: vm.chart.id}, function (result) {
                openCropImageLicensePopup(result);
            }, function () {
                openCropImageLicensePopup(null);
            });
        }

        function openCropImageLicensePopup(result) {
            PopupService.showCropImageLicensePopup(result).result.then(function (error) {
            }, function (data) {
                if (data) {
                    ChartPictureLicense.create({
                        chartId: vm.chart.id,
                        cropped: data.cropped,
                        image: data.image
                    }, function () {
                        $state.reload();
                    });
                }
            });
        }

        function openChangeStatusChart(status){
            $uibModal.open({
                templateUrl: 'app/entities/chart-status-history/chart-status-history-dialog.html',
                controller: 'ChartStatusHistoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                animation: false,
                resolve: {
                    entity: function () {
                        return {
                            status: status,
                            description: null,
                            date: null,
                            id: null
                        };
                    },
                    chart: vm.chart
                }
            }).result.then(function () {
                $state.reload();
            })
        }

        function checkField(field) {
            return CoreService.enableByFacilityType(vm.chart.facility, field);
        }
    }
})();

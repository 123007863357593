(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('AdmissionMedication', AdmissionMedication);

    AdmissionMedication.$inject = ['$resource', 'DateUtils', 'CoreService'];

    function AdmissionMedication($resource, DateUtils, CoreService) {
        var resourceUrl = 'api/admission-medications/:id';
        var facility = CoreService.getCurrentFacility();

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        if (data.lastDate) {
                            data.lastDate = new Date(DateUtils.convertDateTimeFromServer(data.lastDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        if (data.expirationDate) {
                            data.expirationDate = new Date(DateUtils.convertDateTimeFromServer(data.expirationDate)
                                .toLocaleString("en-US", {timeZone: facility.timeZone}));
                        }

                        data.admissionFrequencies = _.sortBy(data.admissionFrequencies, 'xorder');

                        _.forEach(data.admissionFrequencies, function (frequency) {

                            frequency.schedules = _.sortBy(frequency.schedules, 'xorder');

                            _.forEach(frequency.schedules, function (schedule) {
                                schedule.date = moment(schedule.date).toDate();
                            });

                            frequency.hideDay = false;

                            switch (frequency.frequency.id) {
                                case 'EVERY_H':
                                case 'EVERY_2H':
                                case 'EVERY_3H':
                                case 'EVERY_4H':
                                case 'EVERY_6H':
                                case 'EVERY_8H':
                                case 'EVERY_12H':
                                case 'EVERY_24H':
                                case 'EVERY_48H':
                                case 'EVERY_WEEK':
                                case 'EVERY_TWO_WEEKS':
                                case 'EVERY_MONTH': {
                                    frequency.hideDay = true;

                                    break;
                                }
                            }
                        })
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertDateTimeFromServer(copy.startDate);
                    return angular.toJson(copy);
                }
            },
            'save': {method: 'POST'},
            'findAllByFilter': {
                url: 'api/admission-medications/filter',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.forEach(function (item) {
                            item.loggedFullName = renderName(item.loggedFirstName, item.loggedLastName);
                            item.verifyFullName = renderName(item.verifyFirstName, item.verifyLastName);
                            item.witnessFullName = renderName(item.witnessFirstName, item.witnessLastName);
                        })
                    }

                    function renderName(firstName, lastName) {
                        return (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
                    }

                    return data;
                }
            },
            'verify': {
                method: 'POST',
                url: 'api/admission-medications/verify',
                isArray: true
            },
            'sign': {
                method: 'POST',
                url: 'api/admission-medications/sign'
            },
            'findAllFrequencies': {url: 'api/admission-medications/frequencies/:id', method: 'GET', isArray: true},
        });
    }
})();

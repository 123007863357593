(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportController', IncidentReportController);

    IncidentReportController.$inject = ['$state', 'IncidentReport', 'CoreService', '$rootScope', 'ConfirmationService',
        'GenericEntityDatatableService', '$q', 'GUIUtils', '$uibModal',
        'Employee', 'Chart', 'IncidentType', 'DateUtils', 'toastr', 'IncidentDepartment',
        'INCIDENT_REPORT_STATUS', 'PendingPdfChartReport', 'REPORT_TYPE'];

    function IncidentReportController($state, IncidentReport, CoreService, $rootScope, ConfirmationService,
                                      GenericEntityDatatableService, $q, GUIUtils, $uibModal,
                                      Employee, Chart, IncidentType, DateUtils, toastr, IncidentDepartment,
                                      INCIDENT_REPORT_STATUS, PendingPdfChartReport, REPORT_TYPE) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Incident Reports',
            newButtonTitle: "New Incident Report",
            entityClassHumanized: 'Incident Reports',
            entityStateName: 'incident-report',
            service: IncidentReport,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: true,
            options: [{key: 'aaSorting', value: [[0, 'desc']]}],
            columns: [
                {
                    name: 'id',
                    title: 'Incident Number',
                },
                {
                    name: null,
                    title: 'Date',
                    render: function (data) {
                        return moment(data.date).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: "Assign Reviewers",
                    render: function (data) {
                        var assignRev = "";
                        if (data.assignReviewers.length > 0) {
                            _.forEach(data.assignReviewers, function (value) {
                                assignRev = assignRev + (value.firstName + " " + value.lastName) + ", ";
                            })
                            assignRev = assignRev.substring(0, (assignRev.length) - 2);
                        }
                        return assignRev;
                    }
                },
                {
                    name: null,
                    title: "Incident Types",
                    render: function (data) {
                        var incidentType = "";
                        if (data.incidentTypes.length > 0) {
                            _.forEach(data.incidentTypes, function (value) {
                                incidentType = incidentType + (value.name) + ", ";
                            });
                            incidentType = incidentType.substring(0, (incidentType.length) - 2);
                        }
                        return incidentType;
                    }
                },
                {
                    name: null,
                    title: "Incident Departments",
                    render: function (data) {
                        var incidentDepartment = "";
                        if (data.incidentDepartments.length > 0) {
                            _.forEach(data.incidentDepartments, function (value) {
                                incidentDepartment = incidentDepartment + (value.name) + ", ";
                            });
                            incidentDepartment = incidentDepartment.substring(0, (incidentDepartment.length) - 2);
                        }
                        return incidentDepartment;
                    }
                },
                {
                    name: null,
                    title: "Status",
                    render: function (data) {
                        return statusHtml(data);
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        return $state.go($state.current.name + '.detail', {id: data.id});
                    },
                    reload: false
                }),

                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        $state.go('incident-report.edit', {id: data.id});
                    },
                    ngIf: function (data, filter) {
                        return data.status !== INCIDENT_REPORT_STATUS.FINALIZED;
                    },
                    reload: false
                }),

                angular.extend({}, GenericEntityDatatableService.getPrintAction(), {
                    action: function (data) {
                        print(data.id);
                    },
                    reload: false
                }),

                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    },
                }),
            ],
            filters: [
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Patient involve...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'incidentTypeId',
                    read: function (filter) {
                        return filter.incidentTypeId ? filter.incidentTypeId.id : null;
                    },
                    values: IncidentType.findAllByCorporation(CoreService.getFilterByCurrentCorporation()),
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    placeholder: 'Incident Type...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'date',
                    placeholder: 'Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.date ? moment(filter.date).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'employeeId',
                    read: function (filter) {
                        return filter.employeeId ? filter.employeeId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        Employee.findAllByFilter(
                            {
                                page: 0,
                                size: 10,
                                facilityId: params.facilityId,
                                query: search,
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Assign reviewer...',
                    type: 'select',
                    visible: true,
                },
                {
                    name: 'createdByEmployeeId',
                    read: function (filter) {
                        return filter.createdByEmployeeId ? filter.createdByEmployeeId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        Employee.findAllByFilter(
                            {
                                page: 0,
                                size: 10,
                                facilityId: params.facilityId,
                                query: search,
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Employee...',
                    type: 'select',
                    visible: true,
                },
                {
                    name: "status",
                    values: ['PENDING', 'INREVIEW', 'FINALIZED'],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                    placeholder: 'Status...',
                    type: 'select',
                    visible: true,
                },
                {
                    name: 'incidentDepartmentId',
                    read: function (filter) {
                        return filter.incidentDepartmentId ? filter.incidentDepartmentId.id : null;
                    },
                    values: IncidentDepartment.findAllByCorporation(CoreService.getFilterByCurrentCorporation()),
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    placeholder: 'Incident department...',
                    type: 'select',
                    visible: true,
                },
            ],
        }

        function statusHtml(data) {
            var classType = '';

            switch (data.status) {
                case 'PENDING':
                    classType = 'label-info';
                    break;
                case 'FINALIZED':
                    classType = 'label-success';
                    break;
                case 'INREVIEW':
                    classType = 'label-warning';
                    break;
            }
            return '<span class="label ' + classType + '">' + data.status + '</span>'
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('IncidentReport').result.then(function () {
                    IncidentReport.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function print(id) {
            PendingPdfChartReport.save({
                objectId: id,
                reportType: REPORT_TYPE.INCIDENT_REPORT,
                name: "Incident report " + id
            }, function (result) {
                toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
            }, function () {
                toastr.error("We couldn't generate the report.");
            });
        }
    }
})();

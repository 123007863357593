(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ShiftController', ShiftController);

    ShiftController.$inject = ['$state', 'Shift', 'GenericEntityDatatableService', 'GUIUtils', 'CoreService',
        '$rootScope', 'ConfirmationService', '$q', '$uibModal'];

    function ShiftController($state, Shift, GenericEntityDatatableService, GUIUtils, CoreService,
                             $rootScope, ConfirmationService, $q, $uibModal) {

        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Shifts',
            entityClassHumanized: 'Shifts',
            entityStateName: 'shift',
            service: Shift,
            serviceMethod: 'findAllByFilter',
            params: params,
            newAction: false,
            pagination: 'remote',
            newButtonTitle: 'New Shift',
            options: [
                {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            ],
            selectionColumn: 'id',
            columns: [
                {
                    name: 'description',
                    title: 'Description',
                },
                {
                    title: 'Date Created',
                    render: function (data) {
                        return moment(data.dateCreated).format($rootScope.dateFormat);
                    }
                },
                {
                    title: 'Last Modified By',
                    render: function (data) {
                        return data ? (data.lastModifiedEmployee.firstName + " " + data.lastModifiedEmployee.lastName) : '-';
                    },
                },
                {
                    title: 'Status',
                    render: function (data) {
                        return GUIUtils.shiftStatus(data.status);
                    },
                },
                {
                    title: 'Start Time',
                    render: function (data) {
                        return data.startTime ? moment(data.startTime).format($rootScope.timeFormatA) : ' - '
                    }
                },
                {
                    title: 'End Time',
                    render: function (data) {
                        return data.endTime ? moment(data.endTime).format($rootScope.timeFormatA) : ' - '
                    }
                },
                {
                    name: 'building',
                    title: 'Building',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        return detail(data.id);
                    },
                    ngIf: function (data) {
                        return data.status === 'Finalized'
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getEditAction('shift'), {
                    ngIf: function (data) {
                        return data.status === 'Pending' || data.status === 'Review'
                    },
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('shift'), {
                    ngIf: function (data) {
                        return data.status === 'Pending' || data.status === 'Review'
                    },
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'Pending',
                            iClass: 'fa fa-thumbs-down',
                            ngClass: function (filter) {
                                return filter.status === 'Pending' ?
                                    ['active', 'label-danger'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.status = 'Pending';
                            }
                        },
                        {
                            name: 'Finalized',
                            iClass: 'fa fa-thumbs-up',
                            ngClass: function (filter) {
                                return filter.status === 'Finalized' ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.status = 'Finalized';
                            }
                        },
                    ],
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Shift',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'status',
                    visible: false,
                    defaultValue: function () {
                        return 'Pending';
                    },
                    read: function (filter) {
                        if (filter.status === 'Finalized') {
                            return ['Finalized'];
                        } else {
                            return ['Pending', 'Review'];
                        }
                    }
                }
            ]
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/shift/update/shift-dialog.html',
                controller: 'ShiftDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            description: null,
                            dateCreated: null,
                            status: null,
                            startTime: null,
                            endTime: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/shift/update/shift-dialog.html',
                controller: 'ShiftDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Shift', function (Shift) {
                        return Shift.get({id: id}).$promise;
                    }]
                }
            }).result
        }

        function detail(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/shift/detail/shift-detail.html',
                controller: 'ShiftDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Shift', function (Shift) {
                        return Shift.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Shift').result.then(function () {
                    Shift.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientOrderChartDetailController', PatientOrderChartDetailController);

    PatientOrderChartDetailController.$inject = ['$uibModalInstance', 'ParseLinks', 'patientOrder', 'PatientOrderTest', '$uibModal', 'PatientOrderItem',
        'PatientOrder', 'CoreService', 'AlertService'];

    function PatientOrderChartDetailController($uibModalInstance, ParseLinks, patientOrder, PatientOrderTest, $uibModal, PatientOrderItem,
                                               PatientOrder, CoreService, AlertService) {

        var vm = this;

        vm.icd10s = [];
        vm.sing = false;
        vm.testCode = "";

        vm.relationals = [];
        vm.credentials = "";
        vm.activeBotton = false;
        vm.activateSign = false;
        vm.testDescription = "";
        vm.selectedTetsId = null;
        vm.patientOrderItems = [];

        vm.pin = "";
        vm.agree = false;
        vm.signature = "";
        vm.patientOrder = patientOrder;
        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.pageTest = 1;
        vm.pageItem = 1;
        vm.itemsPerPage = 10;
        vm.testsPerPage = 5;

        vm.selectOptionVia = [patientOrder.via ? patientOrder.via.name : ""];
        vm.physician = vm.patientOrder.signedBy.firstName + " " + vm.patientOrder.signedBy.lastName;
        vm.selectOptionPhysician = [vm.patientOrder.signedBy.firstName + " " + vm.patientOrder.signedBy.lastName];
        vm.selectOptionViaDiscontinued = vm.patientOrder.discontinueVia ? [vm.patientOrder.discontinueVia.name] : null;
        vm.discontinueByPhysician = vm.patientOrder.discontinueByPhysician ? vm.patientOrder.discontinueByPhysician.firstName + " " + vm.patientOrder.discontinueByPhysician.lastName : null;
        vm.selectOptionDiscontinueByPhysician = vm.patientOrder.discontinueByPhysician ? [vm.patientOrder.discontinueByPhysician.firstName + " " + vm.patientOrder.discontinueByPhysician.lastName] : null;

        vm.del = del;
        vm.save = save;
        vm.clear = clear;
        vm.loadItems = loadItems;
        vm.transitionTest = transitionTest;
        vm.transitionItem = transitionItem;

        loadAllTest();

        function loadAllTest() {
            PatientOrderTest.findAllPatientOrderTestByPatientOrder({
                id: vm.patientOrder.id,
                page: vm.pageTest - 1,
                size: vm.testsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalTests = headers('X-Total-Count');
                vm.queryCount = vm.totalTests;
                vm.patientOrderTests = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAllItem() {
            PatientOrderItem.findAllPatientOrderItemByPatientOrderTest({
                id: vm.selectedTetsId,
                page: vm.pageItem - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientOrderItems = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionTest() {
            loadAllTest();
        }

        function transitionItem() {
            loadAllItem();
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadItems(id) {
            vm.patientOrderTests.find(function (element) {
                if (element.id == id) {
                    vm.icd10s = element.icd10s;
                    vm.relationals = element.relationals;
                    vm.testCode = element.labCompendium.code;
                    vm.testDescription = element.labCompendiumName;
                    vm.selectedTetsId = id;
                    loadAllItem();
                }
            });
        }

        function del() {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order/patient-order-delete-dialog.html',
                controller: 'PatientOrderDeleteController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientOrder: vm.patientOrder
                }
            }).result.then(function () {
            }, function () {
            });
        }

        function save() {
            vm.isSaving = true;

            var req = {
                patientOrder: vm.patientOrder,
                pin: vm.pin,
                signature: vm.signature
            }

            PatientOrder.savePendingSignature(req, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        credentialsPermits();
        function credentialsPermits() {
            if (vm.patientOrder.signedBy.id == CoreService.getCurrentEmployee().id) {
                return vm.itHasCredentials = true;
            } else return vm.itHasCredentials = false;
        }

        physicianCredentials();
        function physicianCredentials() {
            for (var i =0; i < vm.patientOrder.signedBy.credentials.length; i++) {
                vm.credentials += vm.patientOrder.signedBy.credentials[i].name + " "
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityIcd10DialogController', FacilityIcd10DialogController);

    FacilityIcd10DialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FacilityIcd10', 'Icd10Search'];

    function FacilityIcd10DialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FacilityIcd10, Icd10Search) {
        var vm = this;

        vm.facilityIcd10 = entity;
        vm.icd10 = vm.facilityIcd10.id == null ? getIcd10() : [vm.facilityIcd10.icd10];

        vm.clear = clear;
        vm.save = save;
        vm.getIcd10 = getIcd10;


        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.facilityIcd10.id !== null) {
                FacilityIcd10.update(vm.facilityIcd10, onSaveSuccess, onSaveError);
            } else {
                FacilityIcd10.save(vm.facilityIcd10, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:facilityIcd10Update', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProcedureDiagnosisDetailController', ProcedureDiagnosisDetailController);

    ProcedureDiagnosisDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProcedureDiagnosis', 'Procedure', 'Icd10'];

    function ProcedureDiagnosisDetailController($scope, $rootScope, $stateParams, previousState, entity, ProcedureDiagnosis, Procedure, Icd10) {
        var vm = this;

        vm.procedureDiagnosis = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bluebookApp:procedureDiagnosisUpdate', function(event, result) {
            vm.procedureDiagnosis = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

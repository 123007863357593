(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('chart-blocked', {
                parent: 'entity',
                url: '/chart-blocked',
                data: {
                    authorities: [],
                    pageTitle: 'ChartBlockeds'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chart-blocked/list/chart-blocked.html',
                        controller: 'ChartBlockedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_CHART_BLOCKED];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CHART_BLOCKED, access);
                }]
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionFormTplController', GroupSessionFormTplController);

    GroupSessionFormTplController.$inject = ['GroupSessionFormTpl', 'CoreService', 'GenericEntityDatatableService',
        'ConfirmationService', '$q', '$uibModal'];

    function GroupSessionFormTplController(GroupSessionFormTpl, CoreService, GenericEntityDatatableService,
                                           ConfirmationService, $q, $uibModal) {

        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Group Session Forms',
            entityClassHumanized: 'Group Session Forms',
            entityStateName: 'group-session-form-tpl',
            service: GroupSessionFormTpl,
            serviceMethod: 'filter',
            newButtonTitle: 'New Group Session Form',
            params: params,
            pagination: 'remote',
            newAction: true,
            options: [{key: 'aaSorting', value: [[0, 'desc']]}],
            columns: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'description',
                    title: 'description'
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('group-session-form-tpl'),
                angular.extend({}, GenericEntityDatatableService.getCloneAction(), {
                    action: function (data) {
                        return clone(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
        }

        function clone(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/group-session-form-tpl/clone/group-session-form-tpl-clone-dialog.html',
                controller: 'GroupSessionFormTplCloneController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['GroupSessionFormTpl', function (GroupSessionFormTpl) {
                        return GroupSessionFormTpl.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('GroupSession Form Tpl').result.then(function () {
                    GroupSessionFormTpl.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('udt')
        .factory('JSignature', JSignature);
    JSignature.$inject = ['$q']
    function JSignature($q) {

        var apinamespace = 'jSignature';

        var exportTypes = {
            DEFAULT: 'default',
            NATIVE: 'native',
            IMAGE: 'image',
            BASE30: 'base30',
            IMAGE_SIGNATURE_BASE30: 'image/jsignature;base30',
            SVG: 'svg',
            SVG_XML: 'image/svg+xml',
            SVG_BASE64: 'svgbase64',
            IMAGE_SVG_XML_BASE64: 'image/svg+xml;base64'
        }

        var importTypes = {
            NATIVE: 'native',
            IMAGE: 'image',
            IMAGE_PNG_BASE64: 'image/png;base64',
            IMAGE_JPEG_BASE64: 'image/jpeg;base64',
            IMAGE_JPG_BASE64: 'image/jpg;base64'
        }

        var object = {
            initialized: false,
            options: {
                width: null,
                height: null,
                color: null,
                'background-color': null,
                lineWidth: null,
                cssclass: null,
                UndoButton: false
            },
            element: null,
            scope: null,
        };

        var service = {
            getObject: getObject,
            exportTypes: exportTypes,
            importTypes: importTypes,
            initialize: initialize,
            reset: reset,
            getData: getData,
            // setData: setData,
            disable: disable,
            enable: enable
        };

        return service;

        function initialize(scope, options) {
            var defer = $q.defer();
            object.initialized = false;
            object.scope = scope;

            if (!object.initialized) {
                var selectorId = scope.jSignatureId ? '#' + scope.jSignatureId : '#jSignature';
                object.element = $(selectorId);
                object.initialized = true;
                object.element.jSignature(options);
                defer.resolve(object.element);
            }
            return defer.promise;
        }

        function reset() {
            var defer = $q.defer();
            defer.resolve(object.element.jSignature('reset'));
            return defer.promise;
        }

        function getData(type) {
            // console.log('getData!!!');
            return object.element.jSignature('getData', type);
        }

        function getDataAsSVG() {
            // console.log('getData!!!');
            // var datapair = object.element.jSignature('getData', 'base30');
            var svg = object.element.jSignature('getData', 'svg');
            return svg;
        }

        function getDataAsBase30() {
            // console.log('getData!!!');
            // var datapair = object.element.jSignature('getData', 'base30');
            var svg = object.element.jSignature('getData', 'svg');
            return svg;
        };

        function setData(sig) {
            // console.log('setData!!!');
            // console.log(sig);
            object.element.jSignature('setData', 'data:' + sig.join(','));
        };

        function disable() {
            // console.log('Disable!!!');
            object.element.jSignature('disable');
        };

        function enable() {
            // console.log('Enable!!!');
            object.element.jSignature('enable');
        };

        function undo() {
            var eventName = apinamespace + '.undo'
            object.element.jSignature('events');
        };

        function getObject() {
            // $('#signature').on('change', function(e){
            //     var undef
            //     if ($(e.target).jSignature('getData','native').length) {
            //         $tools.find('input').prop('disabled', false)
            //     } else {
            //         $tools.find('input').prop('disabled', true)
            //     }
            //
            // })
            return object.element;
        }


    }
})();


/**
 * Created by hmendez on 07/05/2015.
 */
(function () {
    'use strict';

    angular.module('bluebookApp').factory('BBEventDataFactory', BBEventDataFactory);

    BBEventDataFactory.$inject = [];

    function BBEventDataFactory() {

        var observers = [];
        var data = {};

        var registerObserver = function (channels, observer) {
            observer.channels = channels;
            observers.push(observer);
        }

        var notifyObservers = function (channel, data) {
            for (var index = 0; index < observers.length; ++index) {
                if (observers[index].channels.indexOf(channel) != -1) {
                    observers[index].notify(data);
                }
            }
        }

        var setData = function (channel, data) {
            data.channel = channel;
            this.data = data;
            notifyObservers(channel, data);
        }

        var getData = function () {
            return this.data;
        }

        return {
            registerObserver: registerObserver,
            setData: setData,
            getData: getData
        };
    }
})();

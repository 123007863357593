(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('scroll', function () {
            return {
                link: function(scope, element, attrs) {
                    element.bind('scroll', function() {
                        //do something here
                    })
                }
            }
        })
})();

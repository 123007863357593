(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider.state('login', {
            url: '/login',
            templateUrl: 'app/login/login.html',
            controller: 'LoginController',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            resolve: {}
        }).state('consent', {
            url: '/consent',
            templateUrl: 'app/login/consent2.html',
            controller: 'SignController',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            resolve: {
                // employee: ['Employee', function (Employee) {
                //     return Employee.employeeAccount().$promise;
                // }]
            }
        }).state('collect-signature', {
            // parent: 'login',
            url: '/collect-signature',
            controller: 'SignController',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            templateUrl: 'app/login/signature.html',
            resolve: {}
        }).state('security-check', {
            url: '/security-check',
            controller: 'SecurityCheckController',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            templateUrl: 'app/login/security-check.html',
            resolve: {}
        }).state('security-code', {
            url: '/security-code',
            controller: 'SecurityCodeController',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            templateUrl: 'app/login/security-code.html',
            resolve: {}
        });
    }
})();

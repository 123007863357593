(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConcurrentReviewsChartNewController', ConcurrentReviewsChartNewController);

    ConcurrentReviewsChartNewController.$inject = ['chart', 'CoreService', 'AlertService', 'toastr', '$uibModal',
        'ConcurrentReviews', '$rootScope', '$scope', 'access', 'ACTIONS', 'Auth'];

    function ConcurrentReviewsChartNewController(chart, CoreService, AlertService, toastr, $uibModal,
                                                 ConcurrentReviews, $rootScope, $scope, access, ACTIONS, Auth) {
        var vm = this;

        vm.chart = chart;

        vm.page = 1;
        vm.itemsPerPage = 3;

        vm.transition = transition;
        vm.edit = edit;

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CONCURRENT_REVIEW_VIEW, access)) {
                return;
            }

            var filter = {
                id: chart.id,
                active: null,
                page: vm.page - 1,
                size: 3
            };

            ConcurrentReviews.findAllByChart(filter, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.concurrentReviews = data;
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function transition() {
            loadAll();
        }

        function add() {
            if (vm.chart.primary) {
                $uibModal.open({
                    templateUrl: 'app/entities/concurrent-reviews/update/concurrent-reviews-dialog.html',
                    controller: 'ConcurrentReviewsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: function () {
                            return {
                                authorizationDate: null,
                                numberDates: 0,
                                frequency: null,
                                typeLevelCare: null,
                                startDate: null,
                                endDate: null,
                                authorizationNumber: null,
                                nextReviewDate: null,
                                insuranceCompany: null,
                                notes: null,
                                id: null,
                                chart: {
                                    id: chart.id
                                },
                                status: null
                            }
                        },
                        chart: chart
                    }
                }).result.then(function () {
                    vm.page = 1;
                    loadAll();
                }, function () {
                });
            } else {
                toastr.error("The patient does not have any insurance.");
            }
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/concurrent-reviews/update/concurrent-reviews-dialog.html',
                controller: 'ConcurrentReviewsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ConcurrentReviews', function (ConcurrentReviews) {
                        return ConcurrentReviews.get({id: id}).$promise;
                    }],
                    chart: chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailAddConcurrentReview', function (event, result) {
            add();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

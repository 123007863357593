(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillDeleteController',SuperBillDeleteController);

    SuperBillDeleteController.$inject = ['$uibModalInstance', 'entity', 'SuperBill'];

    function SuperBillDeleteController($uibModalInstance, entity, SuperBill) {
        var vm = this;

        vm.superBill = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SuperBill.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
        .state('facility-billing', {
            parent: 'entity',
            url: '/facility-billing',
            data: {
                authorities: [ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN],
                pageTitle: 'FacilityBillings'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/facility-billing/list/facility-billing.html',
                    controller: 'FacilityBillingController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('facility-billing.new', {
            parent: 'facility-billing',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/facility-billing/facility-billing-dialog.html',
                    controller: 'FacilityBillingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    windowTopClass: 'custom-dialog-styles',
                    size: 'md',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                facility: CoreService.getCurrentFacility(),
                                status: 'PENDING',
                                date: null,
                                patients: 0,
                                total: 0,
                                id: null
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('facility-billing', null, { reload: 'facility-billing' });
                }, function() {
                    $state.go('facility-billing');
                });
            }]
        })
        .state('facility-billing.delete', {
            parent: 'facility-billing',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/facility-billing/facility-billing-delete-dialog.html',
                    controller: 'FacilityBillingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['FacilityBilling', function(FacilityBilling) {
                            return FacilityBilling.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('facility-billing', null, { reload: 'facility-billing' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConcurrentReviewsBoxController', ConcurrentReviewsBoxController);

    ConcurrentReviewsBoxController.$inject = ['DashboardService', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult', 'CoreService'];

    function ConcurrentReviewsBoxController(DashboardService, $scope, $state, $q, DTColumnBuilder, PatientResult, CoreService) {

        var vm = this;
        vm.total = 0;
        vm.countWithout = 0;
        vm.countExpired = 0;
        vm.countExpiring = 0;
        vm.percentWithout = 0;
        vm.percentExpired = 0;
        vm.percentExpiring = 0;
        vm.facility = CoreService.getCurrentFacility();

        countWithoutConcurrent();
        countExpiredConcurrent();
        countExpiringConcurrent();

        function countWithoutConcurrent () {
            DashboardService.countWithoutConcurrentReview({id:vm.facility.id}, function(data) {
                if (data.value) {
                    vm.countWithout = data.value;
                    total();
                }
            });
        }

        function countExpiredConcurrent () {
            DashboardService.countExpiredConcurrentReview({id:vm.facility.id}, function(data) {
                if (data.value) {
                    vm.countExpired = data.value;
                    total();
                }
            });
        }

        function countExpiringConcurrent () {
            DashboardService.countExpiringConcurrentReview({id:vm.facility.id}, function(data) {
                if (data.value) {
                    vm.countExpiring = data.value;
                    total();
                }
            });
        }

        function total () {
            vm.total = parseInt(vm.countWithout) + parseInt(vm.countExpired) + parseInt(vm.countExpiring);
            vm.percentWithout = (parseInt(vm.countWithout) * 100 / parseInt(vm.total));
            vm.percentWithout= vm.percentWithout.toFixed(2);
            vm.percentExpired = parseInt(vm.countExpired) * 100 / parseInt(vm.total);
            vm.percentExpired = vm.percentExpired.toFixed(2);
            vm.percentExpiring = parseInt(vm.countExpiring) * 100 / parseInt(vm.total);
            vm.percentExpiring = vm.percentExpiring.toFixed(2);
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Alcohol', Alcohol);

    Alcohol.$inject = ['$resource', 'DateUtils'];

    function Alcohol ($resource, DateUtils) {
        var resourceUrl =  'api/alcohol/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findLastByChartId': {url: 'api/alcohol/last/by-chart/:id', method: 'GET'},
            'findLastByChartById': {url: "api/alcohol/last/by-chart-id/:id/size/:size", method: 'GET', isArray: true},
            'findAllByChart': {url: "api/alcohol/by-chart-id/:id", method: 'GET', isArray: true}
        });
    }
})();

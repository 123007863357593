(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidIcd10Controller', CovidIcd10Controller);

    CovidIcd10Controller.$inject = ['CovidIcd10', 'CoreService', 'GenericEntityDatatableService'];

    function CovidIcd10Controller (CovidIcd10, CoreService, GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'covid-icd-10'

        vm.descriptor = {
            title: 'Covid Icd10',
            newButtonTitle: 'New Covid Icd10',
            entityClassHumanized: 'Covid Icd10',
            entityStateName: entityStateName,
            service: CovidIcd10,
            serviceMethod: 'findAllByFilter',
            params,
            columns: [
                {
                    title: 'Icd 10',
                    render: function (data) {
                        return data.icd10.code;
                    }
                },
                {
                    title: 'Description',
                    render: function (data) {
                        return data.icd10.description;
                    }
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction(entityStateName),
                GenericEntityDatatableService.getDeleteAction(entityStateName),
            ]
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('facility', {
                parent: 'entity',
                url: '/facility',
                data: {
                    authorities: [ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN],
                    pageTitle: 'Facilities'
                },
                ncyBreadcrumb: {
                    label: 'Facility'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/facility/list/facility.html',
                        controller: 'FacilityController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacesheetTplDialogController', FacesheetTplDialogController);

    FacesheetTplDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'FacesheetTpl', 'Facility', 'FacesheetSection', 'EvaluationTemplate',
        '$state', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', 'GUIUtils', '$filter',
        'DataTablesService', '$compile', 'CoreService'];

    function FacesheetTplDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                          entity, FacesheetTpl, Facility, FacesheetSection, EvaluationTemplate,
                                          $state, DTOptionsBuilder, DTColumnBuilder, $q, GUIUtils, $filter,
                                          DataTablesService, $compile, CoreService) {
        var vm = this;

        vm.facesheetTpl = entity;

        vm.groupSessionStatuses = FacesheetTpl.allGroupSessionStatus();
        vm.evaluationStatuses = FacesheetTpl.allEvaluationStatus();
        vm.facesheetSections = [];
        vm.evaluationTemplates = [];
        vm.dtInstanceFacesheetSections = {};
        vm.dtInstanceEvaluationTemplates = {};
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.selectedFacesheetSections = {};
        vm.selectedEvaluationTemplates = {};
        vm.selectAllFacesheetSections = false;
        vm.selectAllEvaluationTemplates = false;

        var titleHtmlSections = '<input type="checkbox" ng-model="vm.selectAllFacesheetSections" ng-change="vm.toggleAll(vm.selectedFacesheetSections, vm.selectAllFacesheetSections)">';
        var titleHtmlTemplates = '<input type="checkbox" ng-model="vm.selectAllEvaluationTemplates" ng-change="vm.toggleAll(vm.selectedEvaluationTemplates, vm.selectAllEvaluationTemplates)">';

        vm.clear = clear;
        vm.save = save;
        vm.search = search;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        init();

        function init() {
            vm.dtOptionsFacesheetSections = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();
                FacesheetSection.getAllByFacility({id: CoreService.getCurrentFacility().id}, function (result, headers) {
                    vm.facesheetSections = result;
                    defer.resolve(result);
                });
                return defer.promise;
            }).withBootstrap()
                .withDOM('ltp')
                .withOption("bPaginate", false)
                .withOption('headerCallback', function (header) {
                    if (!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        _.forEach(vm.facesheetTpl.sections, function (section) {
                            vm.selectedFacesheetSections[section.id] = true;
                        });
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('fnRowCallback',
                    function (nRow) {
                        $compile(nRow)($scope);
                    });

            vm.dtColumnsFacesheetSections = [
                DTColumnBuilder.newColumn(null).withTitle(titleHtmlSections).notSortable().renderWith(function (data, type, full, meta) {
                    if (vm.selectedFacesheetSections[full.id] === undefined) {
                        vm.selectedFacesheetSections[full.id] = false;
                    }
                    return '<input type="checkbox" ng-model="vm.selectedFacesheetSections[' + data.id + ']" ng-click="vm.toggleOne(vm.selectedFacesheetSections, vm.selectAllFacesheetSections)">';
                }),
                DTColumnBuilder.newColumn('title').withTitle('Title'),
                DTColumnBuilder.newColumn('facesheetSectionType').withTitle('Facesheet Section Type')
            ];

            vm.dtOptionsEvaluationTemplates = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();
                if (!vm.searchQuery || vm.searchQuery == '') {
                    EvaluationTemplate.findAllByFacility({id: CoreService.getCurrentFacility().id}, function (result, headers) {
                        vm.evaluationTemplates = result;
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve($filter('filter')(vm.evaluationTemplates, vm.searchQuery, undefined));
                }
                return defer.promise;
            }).withPaginationType('full_numbers').withBootstrap().withDOM('ltp').withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
                .withDisplayLength(15)
                .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
                .withOption('aaSorting', [[1, 'asc'], [0, 'asc']])
                .withOption('headerCallback', function (header) {
                    if (!vm.headerCompiled1) {
                        vm.headerCompiled1 = true;
                        _.forEach(vm.facesheetTpl.evaluationTemplates, function (section) {
                            vm.selectedEvaluationTemplates[section.id] = true;
                        });
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('fnRowCallback',
                    function (nRow) {
                        $compile(nRow)($scope);
                    });

            vm.dtColumnsEvaluationTemplates = [
                DTColumnBuilder.newColumn(null).withTitle(titleHtmlTemplates).notSortable().renderWith(function (data, type, full, meta) {
                    if (vm.selectedEvaluationTemplates[full.id] === undefined) {
                        vm.selectedEvaluationTemplates[full.id] = false;
                    }
                    return '<input type="checkbox" ng-model="vm.selectedEvaluationTemplates[' + data.id + ']" ng-click="vm.toggleOne(vm.selectedEvaluationTemplates, vm.selectAllEvaluationTemplates)">';
                }),
                DTColumnBuilder
                    .newColumn('name')
                    .withOption('class', 'names-col')
                    .withTitle('Name')
                    .notSortable(),
                DTColumnBuilder
                    .newColumn('enabled')
                    .withTitle('Enabled')
                    .notSortable()
                    .renderWith(function (data, type, full) {
                        return GUIUtils.colorHtmlYesNo(data);
                    }),
                DTColumnBuilder
                    .newColumn('delStatus')
                    .withTitle('Deleted')
                    .notSortable()
                    .renderWith(function (data, type, full) {
                        return GUIUtils.colorHtmlYesNo(data);
                    })
            ];
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var idsSections = vm.getSelectedIds(vm.selectedFacesheetSections);
            var idsTemplates = vm.getSelectedIds(vm.selectedEvaluationTemplates);

            vm.facesheetTpl.sections = _.filter(vm.facesheetSections, function (section) {
                return idsSections.indexOf("" + section.id) != -1;
            });

            vm.facesheetTpl.evaluationTemplates = _.filter(vm.evaluationTemplates, function (template) {
                return idsTemplates.indexOf("" + template.id) != -1;
            });

            if (vm.facesheetTpl.id !== null) {
                FacesheetTpl.update(vm.facesheetTpl, onSaveSuccess, onSaveError);
            } else {
                FacesheetTpl.save(vm.facesheetTpl, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:facesheetTplUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function search() {
            vm.dtInstanceEvaluationTemplates.reloadData();
        }
    }
})();

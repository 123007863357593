/**
 * Created by jesusrodriguez on 2/26/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('PopupService', PopupService);

    PopupService.$inject = ['$uibModal', 'CoreService'];

    function PopupService($uibModal, CoreService) {

        var service = {
            showCreatePatientPopup: showCreatePatientPopup,
            showPatientMedicationsDetailsPopup: showPatientMedicationsDetailsPopup,
            showPatientOrderDetailsPopup: showPatientOrderDetailsPopup,
            showDiagnosesEditPopup: showDiagnosesEditPopup,
            showDiagnosesAddPopup: showDiagnosesAddPopup,
            showSignaturePopup: showSignaturePopup,
            showPatientMedicationDiscontinueWizardPopup: showPatientMedicationDiscontinueWizardPopup,
            showContactAddressPickupPopup: showContactAddressPickupPopup,
            showPatientSignaturePopup: showPatientSignaturePopup,
            showNotingPopup: showNotingPopup,
            showPatientMedicationPopup: showPatientMedicationPopup,
            showEvaluationPatientMedicationPopup: showEvaluationPatientMedicationPopup,
            showGlucosePopup: showGlucosePopup,
            showContactPopup: showContactPopup,
            showDiagnosesDiscontinuePopup: showDiagnosesDiscontinuePopup,
            showLabRequisitionDetailPopup: showLabRequisitionDetailPopup,
            showCropImageChartPopup: showCropImageChartPopup,
            showCropImageFrontInsurancePopup: showCropImageFrontInsurancePopup,
            showCropImageBackInsurancePopup: showCropImageBackInsurancePopup,
            showCropImageLicensePopup: showCropImageLicensePopup,
            checkSigPlusExtLiteExtension: checkSigPlusExtLiteExtension,
            showSelectChartPopup: showSelectChartPopup,
            showSelectLaboratoryPopup: showSelectLaboratoryPopup,
            showSelectFacilityLabPopup: showSelectFacilityLabPopup,
            showPatientResultDet: showPatientResultDet,
            showDeleteConfirmation: showDeleteConfirmation
        };

        return service;

        function showCreatePatientPopup(copyChart, copyFacility) {
            return $uibModal.open({
                templateUrl: 'app/entities/chart/create/chart-create-dialog.html',
                controller: 'ChartCreateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            oneTimeOnly: false,
                            sobrietyDate: null,
                            phone: null,
                            altPhone: null,
                            email: null,
                            address: null,
                            addressTwo: null,
                            city: null,
                            zip: null,
                            livingArrangement: null,
                            referrerRequired: null,
                            admissionDate: new Date(),
                            dischargeDate: null,
                            employees: null,
                            repName: null,
                            facility: copyFacility ? copyFacility : CoreService.getCurrentFacility(),
                            status: 'WAITING',
                            occupancy: null,
                            employer: null,
                            employerPhone: null,
                            referrerRequiredContact: false,
                            dateFirstContact: null,
                            firstContactName: null,
                            firstContactRelationship: null,
                            firstContactPhone: null,
                            wayLiving: null,
                            typePaymentMethods: null,
                            patientLicenseOriginalId: null,
                            patientLicenseId: null,
                            pictureRefId: null,
                            pictureRefOriginalId: null,
                            firstName: null,
                            middleName: null,
                            lastName: null,
                            preferredName: null,
                            gender: null,
                            race: null,
                            social: null,
                            dateBirth: null,
                            patient: {
                            },
                            typeEthnicity: null,
                            allowReadmit: true,
                            readmitReason: null
                        };
                    }],
                    facility: ['CoreService', function (CoreService) {
                        return copyFacility ? copyFacility : CoreService.getCurrentFacility();
                    }],
                    filter: function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dob: null,
                            social: null,
                            preferredName: null,
                            middleName: null
                        };
                    },
                    copyChart: copyChart,
                }
            });
        }

        function showPatientMedicationsDetailsPopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication/detail/patient-medication-dialog.html',
                controller: 'PatientMedicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientMedication: ['PatientMedication', function (PatientMedication) {
                        return PatientMedication.get({id: id}).$promise;
                    }],
                    signAuthorities: ['BusinessCredential', 'BUSINESS_ROLES', 'CoreService', function (BusinessCredential, BUSINESS_ROLES, CoreService) {
                        return BusinessCredential.getCredentialByRole({
                            businessRole: BUSINESS_ROLES.PHYSICIAN, id: CoreService.getCurrentFacility().id
                        }).$promise;
                    }]
                }
            });
        }

        function showPatientOrderDetailsPopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-order/detail/patient-order-detail.html',
                controller: 'PatientOrderChartDetailController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientOrder: ['$stateParams', 'PatientOrder', function ($stateParams, PatientOrder) {
                        return PatientOrder.get({id: id}).$promise;
                    }]
                }
            });
        }

        function showDiagnosesEditPopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/diagnoses/update/diagnoses-dialog.html',
                controller: 'DiagnosesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Diagnoses', function (Diagnoses) {
                        return Diagnoses.get({id: id}).$promise;
                    }]
                }
            });
        }

        function showDiagnosesAddPopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/diagnoses/update/diagnoses-dialog.html',
                controller: 'DiagnosesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Chart', function (Chart) {
                        return {
                            comment: null,
                            id: null,
                            endDate: null,
                            startDate: null,
                            chart: Chart.get({id: id})
                        };
                    }]
                }
            });
        }

        function showSignaturePopup() {
            return $uibModal.open({
                templateUrl: 'app/components/udt/signature/signature-dialog.html',
                controller: 'SignatureDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {}
            });
        }

        function showPatientMedicationDiscontinueWizardPopup() {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication/chart/discontinue/patient-medication-discontinue-wizard-dialog.html',
                controller: 'PatientMedicationDiscontinueWizardController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.PHYSICIAN
                        }).$promise;
                    }]
                }
            });
        }

        function showContactAddressPickupPopup() {
            return $uibModal.open({
                templateUrl: 'app/entities/contact-address/pickup/contact-address-pickup-dialog.html',
                controller: 'ContactAddressPickupDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
            });
        }

        function showPatientSignaturePopup(chart) {
            return $uibModal.open({
                templateUrl: 'app/components/udt/signature/signature-patient-dialog.html',
                controller: 'SignaturePatientDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            });
        }

        function showNotingPopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication/mars/noting/list/noting-dialog.html',
                controller: 'NotingController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    filter: ['CoreService', function (CoreService) {

                        return {
                            orderBy: null,
                            facilityId: CoreService.getCurrentFacility().id,
                            chartId: id ? id : null
                        }
                    }]
                }
            });
        }

        function showPatientMedicationPopup(chart, admissionMedications, patientMedicationId) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication/create/patient-medication-chart2-dialog.html',
                controller: 'PatientMedicationChart2DialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard : false,
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart,
                    admissionMedications: {ids: admissionMedications},
                    patientMedication: ['PatientMedication', function (PatientMedication) {
                        if (patientMedicationId) {
                            return PatientMedication.get({id: patientMedicationId}).$promise;
                        } else {
                            return null;
                        }

                    }],
                    evaluation: null,
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.PHYSICIAN
                        }).$promise;
                    }]
                }
            });
        }

        function showEvaluationPatientMedicationPopup(evaluation) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication/create/patient-medication-chart2-dialog.html',
                controller: 'PatientMedicationChart2DialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard : false,
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: evaluation.chart,
                    admissionMedications: null,
                    patientMedication: null,
                    evaluation: evaluation,
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.PHYSICIAN
                        }).$promise;
                    }]
                }
            });
        }

        function showGlucosePopup(chart) {
            return $uibModal.open({
                templateUrl: 'app/entities/glucose/update/glucose-dialog.html',
                controller: 'GlucoseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            id: null,
                            chart: chart,
                            date: new Date()
                        }
                    }
                }
            });
        }

        function showContactPopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/contacts/update/contacts-dialog.html',
                controller: 'ContactsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Contacts', function (Contacts) {
                        return Contacts.get({id: id}).$promise;
                    }]
                }
            })
        }

        function showDiagnosesDiscontinuePopup(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/diagnoses/discontinue/diagnoses-discontinue-dialog.html',
                controller: 'DiagnosesDiscontinueDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Diagnoses', function (Diagnoses) {
                        return Diagnoses.get({id: id}).$promise;
                    }]
                }
            });
        }

        function showLabRequisitionDetailPopup(patientOrderId, barcode, date) {

            return $uibModal.open({
                templateUrl: 'app/entities/patient-order/lab-requisition/detail/lab-requisition-detail.html',
                controller: 'LabRequisitionDetailController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientOrder: ['PatientOrder', function (PatientOrder) {

                        return patientOrderId ? PatientOrder.get({id: patientOrderId}).$promise :
                            PatientOrder.getByBarcode({barcode: barcode}).$promise;
                    }],
                    barcode: {
                        barcode: barcode,
                        date: date
                    }
                    // chart: ['Chart', function (Chart) {
                    //     return Chart.get({id: chartId}).$promise;
                    // }],
                    // values: {
                    //     facilityId: vm.facility.id,
                    //     chartId: chartId,
                    //     date: vm.date,
                    //     physicianId: physicianId,
                    //     barcode: barcode == "null" ? null : barcode,
                    //     itemStatus: itemStatus
                    // },
                    // date: [function () {
                    //     return vm.date;
                    // }],
                    // barcode: [function () {
                    //     return barcode;
                    // }],
                    // insurances: ['Insurance', function (Insurance) {
                    //     return Insurance.findAllByChart({id: chartId});
                    // }]
                }
            });
        }

        function showCropImagePopup(image, defaultImage, cropType) {

            return $uibModal.open({
                templateUrl: 'app/components/udt/udt-crop-image/udt-crop-image-dialog.html',
                controller: 'UdtCropImageDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    image: image ? image : {image: ""},
                    defaultImage: defaultImage,
                    cropType: function () {
                        return cropType;
                    }
                }
            });
        }

        function showCropImageChartPopup(image) {
            var imageDefault = null;
            var cropType = "square";

            return showCropImagePopup(image, imageDefault, cropType);
        }

        function showCropImageLicensePopup(image) {
            var imageDefault = null;
            var cropType = "rectangle";

            return showCropImagePopup(image, imageDefault, cropType);
        }

        function showCropImageFrontInsurancePopup(image) {
            var imageDefault = CoreService.insuranceFrontPictureDefault();
            var cropType = "rectangle";

            return showCropImagePopup(image, imageDefault, cropType);
        }

        function showCropImageBackInsurancePopup(image) {
            var imageDefault = CoreService.insuranceBackPictureDefault();
            var cropType = "rectangle";

            return showCropImagePopup(image, imageDefault, cropType);
        }

        function checkSigPlusExtLiteExtension() {

            return document.documentElement.getAttribute('SigPlusExtLiteExtension-installed');
        }

        function showSelectChartPopup() {
            return $uibModal.open({
                templateUrl: 'app/entities/chart/select-chart/select-chart-dialog.html',
                controller: 'SelectChartDialogController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
            });
        }

        function showSelectLaboratoryPopup() {
            return $uibModal.open({
                templateUrl: 'app/entities/laboratory/select/select-laboratory-dialog.html',
                controller: 'SelectLaboratoryDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
            })
        }

        function showSelectFacilityLabPopup() {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-lab/select/select-facility-lab-dialog.html',
                controller: 'SelectFacilityLabDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
            })
        }

        function showPatientResultDet(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-result-det/detail/patient-result-det.html',
                controller: 'PatientResultDetController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientResult: ['PatientResult', function (PatientResult) {
                        return PatientResult.get({id: id}).$promise;
                    }],
                    signature: ['PatientResult', function (PatientResult) {
                        return PatientResult.getSignature({id: id}).$promise
                    }],
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.SIGN_PATIENT_RESULT
                        }).$promise;
                    }]
                }
            });
        }

        function showDeleteConfirmation(entity) {
            return $uibModal.open({
                templateUrl: 'app/components/delete-entity-dialog/delete-entity-dialog.html',
                controller: 'DeleteEntityController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: {name: entity}
                }
            })
        }
    }
})();

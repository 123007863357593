(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillDiagnosisDialogController', SuperBillDiagnosisDialogController);

    SuperBillDiagnosisDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SuperBillDiagnosis', 'SuperBill', 'Diagnoses', 'Acuity', 'Icd10Search', 'CoreService', 'Chart'];

    function SuperBillDiagnosisDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, SuperBillDiagnosis, SuperBill, Diagnoses, Acuity, Icd10Search, CoreService, Chart) {
        var vm = this;

        vm.superBillDiagnosis = entity;
        vm.chart = Chart.get({id:vm.superBillDiagnosis.superBill.chartId})
        vm.showDelete = false;
        vm.acuity = Acuity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.icd10 = [];
        vm.icd10Tmp = [];

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};

        vm.save = save;
        vm.clear = clear;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;
        vm.openCalendar = openCalendar;
        vm.getIcd10 = getIcd10;
        vm.confirmDelete = confirmDelete;

        init();

        function init() {
            if (vm.superBillDiagnosis.id) {
                vm.icd10Tmp = [vm.superBillDiagnosis.icd10];
            }
        }

        function getIcd10(query) {
            if (query && query.length > 2) {
                Icd10Search.query({query: query}, function (result) {
                    vm.icd10 = result;
                });
            }
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.superBillDiagnosis.endDate;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.superBillDiagnosis.startDate;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.close('dismiss');
        }

        function save() {
            vm.isSaving = true;

            if (vm.superBillDiagnosis.id) {
                SuperBillDiagnosis.update(vm.superBillDiagnosis, onSaveSuccess, onSaveError);
            } else {
                vm.superBillDiagnosis.icd10S = vm.icd10Tmp;
                SuperBillDiagnosis.create(vm.superBillDiagnosis, onSaveSuccess, onSaveError)
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function confirmDelete () {
            SuperBillDiagnosis.delete({id: vm.superBillDiagnosis.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('BusinessCredential', BusinessCredential);

    BusinessCredential.$inject = ['$resource', 'Utils'];

    function BusinessCredential($resource, Utils) {
        var resourceUrl = 'api/business-credentials/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    if (data.credentials) {
                        data.credentials = Utils.objectRoleToArray(data.credentials);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'byFacility': {method: 'GET', url: 'api/business-credentials/by-facility/:id', isArray: true},
            'getBusinessRoles': {method: 'GET', url: 'api/business-credentials/get-business-roles', isArray: true},
            'getCredentialByRole': {method: 'GET', url: 'api/business-credentials/get-by-role/:businessRole/:id', isArray: true},
            'getAvailableBusinessRoles': {method: 'GET', url: 'api/business-credentials/get-available-business-roles/:id', isArray: true},
        });
    }
})();

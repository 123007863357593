(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartPreviewDialogController', ChartPreviewDialogController);

    ChartPreviewDialogController.$inject = ['$uibModalInstance', 'chart', 'Chart'];

    function ChartPreviewDialogController($uibModalInstance, chart, Chart) {
        var vm = this;

        vm.chart = chart;

        vm.clear = clear;
        vm.checkChartState = checkChartState;
        vm.goToChart = goToChart;

        vm.charts = Chart.previousCharts({id: chart.id});

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Check chart state (ARCHIVE|CURRENT|WAITING)
         * @param chart
         * @returns {string}
         */
        function checkChartState(chart) {
            var state = '';
            if (moment().isAfter(moment(chart.dischargeDate).endOf('day'))) {
                state = 'ARCHIVE';
            }
            if (moment().isBefore(moment(chart.dischargeDate).endOf('day'))) {
                state = 'CURRENT';
            }
            if (chart.waitingRoom) {
                state = 'WAITING';
            }
            return state;
        }

        function goToChart(id) {
            $uibModalInstance.close(id);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('NotificationsDialogController', NotificationsDialogController);

    NotificationsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$uibModalInstance', 'entity', 'Notifications', 'Employee', 'CoreService'];

    function NotificationsDialogController ($timeout, $scope, $stateParams, $state, $uibModalInstance, entity, Notifications, Employee, CoreService) {
        var vm = this;

        vm.state = $state.current.name;
        vm.notifications = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.corporation = CoreService.getCorporation();
        vm.employees = Employee.byActive({id: vm.corporation.id});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.notifications.id !== null) {
                Notifications.update(vm.notifications, onSaveSuccess, onSaveError);
            } else {
                vm.notifications.state = $state.current.name;
                vm.notifications.employee = CoreService.getCurrentEmployee();
                Notifications.save(vm.notifications, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:notificationsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.reviewedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('NotingController', NotingController);

    NotingController.$inject = ['$uibModal', 'DTColumnBuilder', '$compile', '$scope', '$q', '$rootScope',
        'DTOptionsBuilder', '$filter', 'filter', 'CoreService', 'PatientMedication',
        'Medication', 'BusinessCredential', 'BUSINESS_ROLES', 'Employee', 'GUIUtils', 'DataTablesService',
        'toastr', 'DateUtils', 'MedicationSearch', 'PopupService', '$uibModalInstance',
        'ConfirmationService'];

    function NotingController($uibModal, DTColumnBuilder, $compile, $scope, $q, $rootScope,
                              DTOptionsBuilder, $filter, filter, CoreService, PatientMedication,
                              Medication, BusinessCredential, BUSINESS_ROLES, Employee, GUIUtils, DataTablesService,
                              toastr, DateUtils, MedicationSearch, PopupService, $uibModalInstance,
                              ConfirmationService) {
        var vm = this;

        vm.title = 'Noting';
        vm.entityClassHumanized = 'Noting';
        vm.filterTpl = angular.copy(filter);
        vm.filter = filter;
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.dtInstance = {};
        vm.selected = {};
        vm.selectAll = false;
        vm.patientMedications = [];
        vm.itHasCredentials = false;
        vm.medications = [];

        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;

        vm.search = search;
        vm.reload = reload;
        vm.updateStatusNoted = updateStatusNoted;
        vm.openConfirmationModal = openConfirmationModal;
        vm.clear = clear;
        vm.close = close;
        vm.fax = fax;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            PatientMedication.noting(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems

                vm.patientMedications = result;
                defer.resolve(vm.patientMedications);
            });

            vm.selected = {};
            vm.selectAll = false;

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withOption("bPaginate", false)
            .withOption('order', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withDisplayLength(15)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;
                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').notSortable().renderWith(function (data, type, full) {
                return data.patientLastName + ", " + data.patientFirstName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Date Birth').notSortable().renderWith(function (data, type, full) {
                return moment(data.dob).format($rootScope.dateFormat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('MrNo').renderWith(function (data, type, full) {
                return data.mrNo;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Medication').notSortable().renderWith(function (data, type, full) {
                return data.medicationName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').notSortable().renderWith(function (data, type, full) {
                return moment(data.startDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('durationDays').withTitle('Duration Days').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Route').notSortable().renderWith(function (data, type, full) {
                return data.routeName;
            }),
            DTColumnBuilder.newColumn('doseFormName').withTitle('Dose Form').notSortable(),
            DTColumnBuilder.newColumn('type').withTitle('Type').notSortable()

        ];

        init();

        function init() {
            BusinessCredential.getCredentialByRole({
                businessRole: BUSINESS_ROLES.NURSE,
                id: vm.filter.facilityId
            }, function (result) {
                vm.credentials = result;

                vm.itHasCredentials = _.intersection(CoreService.getCurrentEmployee().credentials,
                    _.map(vm.credentials, function (credential) {
                        return credential.name;
                    })
                ).length > 0;

                if (vm.itHasCredentials) {
                    vm.currentEmployee = CoreService.getCurrentEmployee();
                }
            });
        }

        function search() {
            vm.page = 1;

            reload();
        }

        function reload() {
            vm.dtInstance.reloadData();
        }

        function clear() {
            vm.searchQuery = null;
            vm.filter.chartId = null;

            vm.page = 1;

            reload();
        }

        function updateStatusNoted() {
            vm.isSaving = true;

            PatientMedication.note({
                ids: vm.getSelectedIds(vm.selected),
                facilityId: vm.filter.facilityId
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.dtInstance.reloadData();

            if (result) {
                toastr.success("The medications was noted");
            } else {
                toastr.error("Some medications weren't noted");
            }
        }

        function onSaveError() {
            alert("Error noted");
            vm.isSaving = false;
        }

        function openConfirmationModal() {
            var nurse = CoreService.getCurrentEmployee();

            ConfirmationService.open({
                title: 'Confirm',
                message: `I, <strong>${nurse.firstName} ${nurse.lastName}</strong> have noted and reviewed the selected order.`,
                actions: [
                    {
                        name: 'Noting',
                        color: 'btn btn-success',
                        icon: 'fa fa-check-circle',
                        value: 'SUCCESS'
                    },
                    {
                        name: 'Close',
                        color: 'btn btn-danger',
                        icon: 'fa fa-ban',
                        value: 'CANCEL'
                    }
                ]
            }).result.then(function () {
                vm.updateStatusNoted()
            });
        }

        function getFilter() {
            var filter = angular.copy(vm.filter);
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter;
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function fax() {
            PopupService.showContactAddressPickupPopup().result.then(function (result) {
                PatientMedication.fax({
                    ids: vm.getSelectedIds(vm.selected),
                    moreIds: result.ids
                }, function () {
                    toastr.success("Fax in queue");
                }, function () {
                    _.forEach(result, function (contactAddress) {
                        toastr.error('Error sending fax to ' + (contactAddress.name ? contactAddress.name : "") + " Fax: " + contactAddress.fax + ". Try again later.");
                    })
                });
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TypeMaritalStatusController', TypeMaritalStatusController);

    TypeMaritalStatusController.$inject = ['GenericEntityDatatableService', 'TypeMaritalStatus', 'CoreService', '$q',
        '$uibModal', 'ConfirmationService'];

    function TypeMaritalStatusController(GenericEntityDatatableService, TypeMaritalStatus, CoreService, $q,
                                         $uibModal, ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Marital Status',
            newButtonTitle: 'New Marital Status',
            entityClassHumanized: 'Marital Status',
            entityStateName: 'type-marital-status',
            service: TypeMaritalStatus,
            serviceMethod: 'findAllByCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Marital Status',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/type-marital-status/update/type-marital-status-dialog.html',
                controller: 'TypeMaritalStatusDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/type-marital-status/update/type-marital-status-dialog.html',
                controller: 'TypeMaritalStatusDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['TypeMaritalStatus', function (TypeMaritalStatus) {
                        return TypeMaritalStatus.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Marital Status').result.then(function () {
                    TypeMaritalStatus.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

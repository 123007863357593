(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacesheetTplReportController', FacesheetTplReportController);

    FacesheetTplReportController.$inject = ['FacesheetTpl', '$state', 'DTOptionsBuilder', 'DTColumnBuilder',
        'DataTablesService', 'chart', 'Chart', '$q', 'GUIUtils', '$filter', 'CoreService', '$compile', '$scope',
        '$timeout', '$uibModalInstance', 'toastr', 'PendingPdfChartReport', 'REPORT_TYPE'];

    function FacesheetTplReportController(FacesheetTpl, $state, DTOptionsBuilder, DTColumnBuilder, DataTablesService, chart, Chart,
                                          $q, GUIUtils, $filter, CoreService, $compile, $scope,
                                          $timeout, $uibModalInstance, toastr, PendingPdfChartReport, REPORT_TYPE) {
        var vm = this;
        vm.chart = chart;

        vm.facesheetTpls = [];
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.facility = CoreService.getCurrentFacility();

        vm.selected = {};
        vm.selectAll = false;
        vm.startDate = null;
        vm.endDate = null;
        vm.startDateOptions = {};
        vm.endDateOptions = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.form = {};

        vm.clear = clear;
        vm.print = print;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.search = search;
        vm.reload = reload;
        vm.openCalendar = openCalendar;
        vm.validateStartDate = validateStartDate;
        vm.validateEndDate = validateEndDate;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            FacesheetTpl.filter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.facesheetTpls = result;
                defer.resolve(result);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[1, 'desc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).renderWith(function (data) {

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }).withOption('width', '50px').notSortable(),
            DTColumnBuilder.newColumn('name').withOption('class', 'names-col').withTitle('Name')
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getFilter() {
            var filter = {};

            filter.facilityId = vm.facility.id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;
            filter.enable = true;

            return filter
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function reload() {
            vm.dtInstance.reloadData();
        }

        function print() {
            if (vm.getSelectedIds(vm.selected).length > 0) {
                vm.isPrinting = true;
                _.forEach(vm.getSelectedIds(vm.selected), function (id) {
                    PendingPdfChartReport.save({
                        chart: chart,
                        facesheetTpl: _.filter(vm.facesheetTpls, function (facesheetTpl) {
                            return "" + facesheetTpl.id == id;
                        })[0],
                        facility: vm.facility,
                        name: "report name",
                        reportType: REPORT_TYPE.FACESHEET,
                        startDate: vm.startDate,
                        endDate: vm.endDate
                    }, onPrintSuccess, onPrintError);
                });
            }

            function onPrintSuccess(result) {
                vm.isPrinting = false;
                $uibModalInstance.close(true);

                toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
            }

            function onPrintError() {
                vm.isPrinting = false;
                toastr.error("We couldn't generate the report.");
            }
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validateStartDate() {
            vm.startDateOptions.maxDate = vm.endDate;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.startDate;
        }
    }
})();

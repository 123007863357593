(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssuesUnknownController', UrgentIssuesUnknownController);

    UrgentIssuesUnknownController.$inject = ['$uibModalInstance', 'UrgentIssues', '$stateParams', 'CoreService',
        '$scope', 'chart'];

    function UrgentIssuesUnknownController($uibModalInstance, UrgentIssues, $stateParams, CoreService,
                                           $scope, chart) {

        var vm = this;

        vm.clear = clear;
        vm.check = check;

        loadAll();

        function loadAll() {
            UrgentIssues.unknown({id: chart.id}, function (result) {
                if (result.length == 0) {
                    clear();
                }

                vm.urgentIssues = result;
            });
        }

        function check(urgentIssue) {
            UrgentIssues.check({id: urgentIssue.id}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            loadAll();
            $scope.$emit('bluebookApp:urgentIssuesUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss(null);
        }
    }
})();

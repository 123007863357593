/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PendingByPatientBoxController', PendingByPatientBoxController);

    PendingByPatientBoxController.$inject = ['DashboardService', 'CoreService'];

    function PendingByPatientBoxController(DashboardService, CoreService) {

        var vm = this;

        vm.facility = CoreService.getCurrentFacility();
        vm.count = 0;

        DashboardService.countAllByFilter({
            facilityId: vm.facility.id,
            status: "Pending",
            empty: false,
            requiredPatientSignature: true,
            existPatientSignature: false
        }, function (data) {
            if (data.count) {
                vm.count = data.count;
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TypePaymentMethodsController', TypePaymentMethodsController);

    TypePaymentMethodsController.$inject = ['TypePaymentMethods', 'GUIUtils', 'CoreService',
        'GenericEntityDatatableService', '$q', '$uibModal', 'ConfirmationService'];

    function TypePaymentMethodsController(TypePaymentMethods, GUIUtils, CoreService,
                                          GenericEntityDatatableService, $q, $uibModal, ConfirmationService) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Payment Methods',
            newButtonTitle: 'New Payment Method',
            entityClassHumanized: 'Payment Methods',
            entityStateName: 'type-payment-methods',
            service: TypePaymentMethods,
            serviceMethod: 'byFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'category',
                    title: 'Category',
                    render: function (data) {
                        return data.category === 'No' ? 'No Insurance' : 'Insurance'
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Payment Method',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/type-payment-methods/update/type-payment-methods-dialog.html',
                controller: 'TypePaymentMethodsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            status: null,
                            category: null,
                            facility: CoreService.getCurrentFacility(),
                            id: null
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/type-payment-methods/update/type-payment-methods-dialog.html',
                controller: 'TypePaymentMethodsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['TypePaymentMethods', function (TypePaymentMethods) {
                        return TypePaymentMethods.get({id: id}).$promise;
                    }]
                }
            }).result
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Payment Method').result.then(function () {
                    TypePaymentMethods.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

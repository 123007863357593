(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Employee', Employee);

    Employee.$inject = ['$resource', 'Utils'];

    function Employee($resource, Utils) {
        var resourceUrl = 'api/employees/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fullName = data.firstName + ' ' + data.lastName;
                        if (data.credentials) {
                            data.credentials = Utils.objectRoleToArray(data.credentials);
                        }
                    }
                    return data;
                }
            },
            'employeeAccount': {
                url: 'api/employeeAccount', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fullName = data.firstName + ' ' + data.lastName;
                        if (data.credentials) {
                            data.credentials = Utils.objectRoleToArray(data.credentials);
                        }
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'createSystem': {url: 'api/employees/system', method: 'POST'},
            'updateSystem': {url: 'api/employees/system', method: 'PUT'},
            'employeeIsValidPin': {
                url: 'api/employees/is-valid-pin/:pin', method: 'GET'
            },
            'credentials': {url: 'api/employees/credentials', method: 'GET', isArray: true},
            'findAllByFilter': {
                url: 'api/employees/find-all-by-filter',
                method: 'GET',
                isArray: true
            },
            'findAllByCorporation': {
                url: 'api/employees/by-corporation',
                method: 'GET',
                isArray: true
            },
            'findAllByFilterAdmin': {
                url: 'api/employees/admin',
                method: 'GET',
                isArray: true
            },
            'findAllByFilterSystem': {
                url: 'api/employees/system',
                method: 'GET',
                isArray: true
            },
            'existUserName': {
                url: 'api/employees/exist/user', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = {value: data};
                    }

                    return data;
                }
            },
            'existEmail': {
                url: 'api/employees/exist/email', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = {value: data};
                    }

                    return data;
                }
            },
            'findAllWithoutChartBlocked': {url: 'api/employees/without-chart-blocked', method: 'GET', isArray: true},
            'restore': {url: 'api/employees/restore', method: 'PUT'},
            'checkBusinessRole': {
                url: 'api/employees/check-business-role/:facilityId/:businessRole', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = {value: data};
                    }

                    return data;
                }
            },
            'replaceSignature': {
                url: 'api/employees/replace-signature', method: 'POST'
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ELabsController', ELabsController);

    ELabsController.$inject = ['$uibModal', 'PatientResult', 'chart', 'ParseLinks', 'AlertService','CoreService', 'Auth',
        'PopupService', '$rootScope', '$state', '$scope', 'access', 'LabCompendium', 'ACTIONS', 'params', 'TAB'];

    function ELabsController($uibModal, PatientResult, chart, ParseLinks, AlertService, CoreService, Auth,
                             PopupService, $rootScope, $state, $scope, access, LabCompendium, ACTIONS, params, TAB) {
        var vm = this;
        vm.page = 1;
        vm.result = [];
        vm.itemsPerPage = 10;
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.E_LAB);
        vm.chart = chart;
        vm.access = access;
        vm.toggleAllRows = false;

        vm.details = details;
        vm.toggleItem = toggleItem;
        vm.transition = transition;
        vm.requisition = requisition;
        vm.printRangePDFs = printRangePDFs;
        vm.search = search;
        vm.toggleRow = toggleRow;
        vm.toggleAllRow = toggleAllRow;

        function toggleItem() {
            CoreService.togglePanel('eLabs');
        }

        function details(id) {
            PopupService.showPatientResultDet(id).result.then(function () {
                loadAll();
            });
        }

        function requisition(item) {
            PopupService.showLabRequisitionDetailPopup(null, item.accessionNumber, null);
        }

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_ELABS_VIEW, access) || !vm.active) {
                return;
            }

            PatientResult.getAllByFilter({
                chartId: chart.id,
                facilityId: chart.facility.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.result = data;

                if(vm.toggleAllRows){
                    for (var i = 0; i < vm.result.length; i++) {
                        vm.result[i].active = vm.toggleAllRows;

                        if (vm.result[i].id && vm.result[i].active && !vm.result[i].codes){
                            getTestCodesByBarcode(vm.result[i]);
                        }
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function printRangePDFs(id) {
            var ids;

            if (id) {
                ids = [id];
            }

            if (ids.length > 0) {
                PatientResult.getAllInOneRangePdf({ids: ids});
            }
        }

        function search() {
            vm.page = 1;
            transition();
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result == TAB.E_LAB) {
                vm.active = true;
                loadAll();
            }
        });

        $scope.$on('$destroy', unsubscribe);

        function toggleAllRow() {
            vm.toggleAllRows = !vm.toggleAllRows;
            for (var i = 0; i < vm.result.length; i++) {
                vm.result[i].active = vm.toggleAllRows;

                if (vm.result[i].id && vm.result[i].active && !vm.result[i].codes){
                    getTestCodesByBarcode(vm.result[i]);
                }
            }
        }

        function toggleRow(item) {
            item.active = !item.active;

            if (item.active) {
                getTestCodesByBarcode(item);
            }
        }

        function getTestCodesByBarcode(item){
            if(item.accessionNumber) {
                LabCompendium.findAllByBarcode({barcode: item.accessionNumber}, function (result) {
                    if (result.length > 0) {
                        item.codes =
                            _.join(
                                _.map(result, function (item) {
                                    return item.code;
                                }), ', ');
                    }
                })
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AdmissionMedicationController', AdmissionMedicationController);

    AdmissionMedicationController.$inject = ['$uibModal', '$compile', '$scope', 'PopupService', 'chart',
        'DataTablesService', 'AdmissionMedication', 'DTOptionsBuilder', 'DTColumnBuilder',
        '$q', 'filter', 'CoreService', 'toastr', 'TAB', 'ConfirmationService'];

    function AdmissionMedicationController($uibModal, $compile, $scope, PopupService, chart,
                                           DataTablesService, AdmissionMedication, DTOptionsBuilder, DTColumnBuilder,
                                           $q, filter, CoreService, toastr, TAB, ConfirmationService) {
        var vm = this;

        vm.title = "Admission Medications";
        vm.chart = chart;

        vm.admissionMedications = [];
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.dtInstance = {};
        vm.selected = {};
        vm.selectAll = false;
        vm.filter = filter;
        vm.filterTpl = angular.copy(filter);
        vm.facility = CoreService.getCurrentFacility();
        vm.employee = CoreService.getCurrentEmployee();
        vm.TAB = TAB;

        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.isSelected = isSelected;
        vm.search = search;
        vm.clear = clear;
        vm.transition = transition;
        vm.findAllByFilter = findAllByFilter;
        vm.sign = sign;
        vm.openModalAddMedication = openModalAddMedication;
        vm.openModalEditMedication = openModalEditMedication;
        vm.del = del;
        vm.verify = verify;
        vm.openModalVerify = openModalVerify;
        vm.view = view;

        init();

        function init() {
            findAllByFilter();
        }

        function findAllByFilter() {
            AdmissionMedication.findAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.admissionMedications = result;

                _.forEach(vm.admissionMedications, function (admissionMedication) {
                    vm.selected[admissionMedication.id] = false;
                });
            });
        }

        function search() {
            vm.page = 1;
            vm.findAllByFilter();
        }

        function clear() {
            vm.filter = angular.copy(vm.filterTpl);
            vm.searchQuery = null;
            vm.page = 1;

            vm.findAllByFilter();
        }

        function transition() {
            vm.findAllByFilter();
        }

        function getFilter() {
            var filter = angular.copy(vm.filter);

            filter.chartId = vm.chart.id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter
        }

        function isSelected() {
            return vm.getSelectedIds(vm.selected).length > 0;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Admission Medication').result.then(function () {
                    AdmissionMedication.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }

        function openModalAddMedication() {
            $uibModal.open({
                templateUrl: 'app/entities/admission-medication/update/admission-medication-dialog.html',
                controller: 'AdmissionMedicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.chart,
                    admissionMedication: {
                        id: null,
                        admissionMedicationAction: null,
                        asNeeded: false,
                        durationDays: null,
                        rxNumber: null,
                        lastDate: new Date(),
                        originalPrescriber: null,
                        amount: null,
                        instructions: null,
                        expirationDate: new Date(),
                        chart: vm.chart,
                        medication: null,
                        route: null,
                        doseForm: null,
                        witness: null,
                        verified: false,
                        relationals: [],
                        admissionFrequencies: [{
                            frequency: null,
                            xorder: 0,
                            isCollapsed: false
                        }],
                        signed: false,
                        collapsed: false
                    }
                }
            }).result.then(function () {
                transition();
            }, function () {

            });
        }

        function openModalEditMedication(id) {
            $uibModal.open({
                templateUrl: 'app/entities/admission-medication/update/admission-medication-dialog.html',
                controller: 'AdmissionMedicationDialogController',
                controllerAs: 'vm',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.chart,
                    admissionMedication: ['AdmissionMedication', function (AdmissionMedication) {

                        return AdmissionMedication.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                findAllByFilter();
            }, function () {
                findAllByFilter();
            });
        }

        function openModalVerify(id) {
            ConfirmationService.open({
                title: 'Confirm Verify Operation',
                message: 'Are you sure you want to <strong>Verify</strong> this <strong>Medication</strong>?',
                actions: [
                    {
                        name: 'Verify',
                        color: 'btn btn-success',
                        icon: 'fa fa-check-circle',
                        value: 'SUCCESS'
                    },
                    {
                        name: 'Cancel',
                        color: 'btn btn-danger',
                        icon: 'fa fa-ban',
                        value: 'CANCEL'
                    }
                ]
            }).result.then(function () {
                verify(id);
            });
        }

        function verify(id) {
            var ids = [];

            if (id) {
                ids = ["" + id];
            } else {
                ids = vm.getSelectedIds(vm.selected);
            }

            var admissionMedications = _.filter(vm.admissionMedications, function (admissionMedication) {
                return ids.indexOf("" + admissionMedication.id) != -1 && !admissionMedication.verified;
            });

            if (admissionMedications.length > 0) {
                AdmissionMedication.verify({
                    ids: _.map(admissionMedications, function (admissionMedication) {

                        return admissionMedication.id;
                    }),
                    facilityId: vm.facility.id,
                    employee: vm.employee
                }, function (result) {
                    if (result.length > 0) {
                        verifyAndAddMedication(result);
                    } else {
                        findAllByFilter();
                    }
                })
            }
        }

        function verifyAndAddMedication(result) {
            PopupService.showPatientMedicationPopup(vm.chart, result).result.then(function (data) {
                findAllByFilter();
            });
        }

        function sign(id) {
            var ids = [];

            if (id) {
                ids = ["" + id];
            } else {
                ids = vm.getSelectedIds(vm.selected);
            }

            var admissionMedications = _.filter(vm.admissionMedications, function (admissionMedication) {
                return ids.indexOf("" + admissionMedication.id) != -1 && admissionMedication.verified;
            });

            if (admissionMedications.length > 0) {
                PopupService.showSignaturePopup().result.then(function (data) {
                    AdmissionMedication.sign({
                        signature: data.employeeSignature,
                        pin: data.employeeSignaturePin,
                        ids: _.map(admissionMedications, function (admissionMedication) {

                            return admissionMedication.id;
                        }),
                        facilityId: vm.facility.id
                    }, function (result) {
                        findAllByFilter();
                        if (result) {
                            toastr.success("The Admission Medication was signed successfully.");
                        } else {
                            toastr.error("The Admission Medication wasn't signed.");
                        }
                    }, function () {
                        toastr.error("The Admission Medication wasn't signed.");
                    });
                }, function () {
                    // reload();
                });
            }
        }

        function view(id) {
            $uibModal.open({
                templateUrl: 'app/entities/admission-medication/detail/admission-medication-detail.html',
                controller: 'AdmissionMedicationDetailController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    admissionMedication: ['AdmissionMedication', function (AdmissionMedication) {
                        return AdmissionMedication.get({id: id}).$promise;
                    }]
                }
            });
        }
    }
})();

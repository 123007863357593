(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SignaturePatientDialogController', SignaturePatientDialogController);

    SignaturePatientDialogController.$inject = ['$uibModalInstance', 'chart'];

    function SignaturePatientDialogController($uibModalInstance, chart) {

        var vm = this;

        vm.signatureOpts = getSignatureOptions();
        vm.chart = chart;
        vm.odsSignatureNameId = Math.floor((Math.random() * 100) + 1);

        vm.clear = clear;
        vm.sign = sign;

        function getSignatureOptions() {

            return {
                width: 620,
                height: 250,
                cssClass: 'signature-canvas',
                color: '#00008B',
                'background-color': '#fff'
            };
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function sign() {
            $uibModalInstance.close(vm.patientSignature);
        }
    }
})();

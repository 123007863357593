(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabRequisitionConsentDialogController', LabRequisitionConsentDialogController);

    LabRequisitionConsentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'LabRequisitionConsent', 'Laboratory', 'CoreService'];

    function LabRequisitionConsentDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                                   LabRequisitionConsent, Laboratory, CoreService) {
        var vm = this;

        vm.labRequisitionConsent = entity;
        vm.clear = clear;
        vm.save = save;
        vm.laboratories = vm.labRequisitionConsent.id ?
            [vm.labRequisitionConsent.laboratory] :
            Laboratory.findAllWithoutConsent(CoreService.getFilterByCurrentCorporation());

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.labRequisitionConsent.id !== null) {
                LabRequisitionConsent.update(vm.labRequisitionConsent, onSaveSuccess, onSaveError);
            } else {
                LabRequisitionConsent.save(vm.labRequisitionConsent, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:labRequisitionConsentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

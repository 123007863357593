(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationDialogController', PatientMedicationDialogController);

    PatientMedicationDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'Via', 'CoreService',
        'Route', 'patientMedication', 'PatientMedication', 'signAuthorities',
        'Allergies', 'PatientMedicationTake'];

    function PatientMedicationDialogController($timeout, $scope, $uibModalInstance, Via, CoreService,
                                               Route, patientMedication, PatientMedication, signAuthorities,
                                               Allergies, PatientMedicationTake) {
        var vm = this;

        vm.currentEmployee = CoreService.getCurrentEmployee();
        vm.patientMedication = patientMedication;
        vm.chart = patientMedication.chart;

        vm.allergiesStr = "";
        vm.rationaleStr = "";
        vm.frequencies = [];
        vm.takes = [];

        vm.isEnabledToSign = false;
        vm.signature = "";
        vm.signatureDiscontinue = "";

        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.odsSignatureNameId = "" + odsSignatureNameId();
        vm.odsDiscontinueSignatureNameId = "" + odsSignatureNameId();

        vm.isEnabledToSign = _.intersection(vm.currentEmployee.credentials,
            _.map(signAuthorities, function (credential) {
                return credential.name;
            })
        ).length > 0;

        init();

        function init() {
            PatientMedication.findAllFrequencies({id: vm.patientMedication.id}, function (data) {
                vm.frequencies = _.sortBy(data, 'xorder');

                _.forEach(vm.frequencies, function (frequency) {
                    frequency.schedules = _.sortBy(frequency.schedules, 'xorder');

                    frequency.hideDay = false;

                    switch (frequency.frequency.id) {
                        case 'EVERY_H':
                        case 'EVERY_2H':
                        case 'EVERY_3H':
                        case 'EVERY_4H':
                        case 'EVERY_6H':
                        case 'EVERY_8H':
                        case 'EVERY_12H':
                        case 'EVERY_24H':
                        case 'EVERY_48H':
                        case 'EVERY_WEEK':
                        case 'EVERY_TWO_WEEKS':
                        case 'EVERY_MONTH': {
                            frequency.hideDay = true;

                            break;
                        }
                    }
                });
            });

            if (vm.patientMedication.relational.length > 0) {
                vm.rationaleStr =
                    _.join(
                        _.map(vm.patientMedication.relational, function (relational) {
                            return relational.name;
                        }), ', ');
            }

            PatientMedicationTake.findAllByPatientMedication({id: vm.patientMedication.id}, function (data) {
                vm.takes = data;
            })
        }

        vm.clear = clear;
        vm.physicianSign = physicianSign;
        vm.discontinueSign = discontinueSign;
        vm.checkDiscontinueAccess = checkDiscontinueAccess;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function physicianSign() {
            vm.isSaving = true;

            PatientMedication.sign({
                id: vm.patientMedication.id,
                signature: vm.signature,
                pin: vm.pin
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.patientMedication = result;

            $uibModalInstance.close();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function discontinueSign() {
            vm.isSaving = true;

            var patientMedicationProj = {
                ids: [vm.patientMedication.id],
                signature: vm.signatureDiscontinue,
                pin: vm.pin
            };

            PatientMedication.discontinue2(patientMedicationProj, onSaveSuccess, onSaveError);
        }

        function checkDiscontinueAccess() {
            return vm.patientMedication.medicationStatus === 'DISCONTINUED' &&
                (vm.patientMedication.discontinueSignature != null ||
                    (vm.isEnabledToSign && vm.patientMedication.discontinueSignature == null && (vm.patientMedication.discontinueByPhysician.id == vm.currentEmployee.id)));
        }

        function odsSignatureNameId() {
            return Math.floor((Math.random() * 100) + 1);
        }
    }
})();

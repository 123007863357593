(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'LoginService', '$state', 'CoreService'];

    function HomeController($scope, LoginService, $state, CoreService) {
        var vm = this;

        //vm.facility = CoreService.getCurrentFacility();
        // vm.employee = CoreService.getCurrentEmployee();
        //
        //if (vm.facility) {
       $state.go("dashboard");
        //}
        // else {
        //     setTimeout(function () {
        //         $state.go("dashboard");
        //     }, 2000);
        // }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('MessageHistoryChart', MessageHistoryChart);

    MessageHistoryChart.$inject = ['$resource'];

    function MessageHistoryChart ($resource) {
        var resourceUrl =  'api/message-history-charts/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllByFilter': { method: 'GET', url: "api/message-histories-charts/by-messages-history/filter", isArray: true}
        });
    }
})();

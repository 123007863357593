(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionDetailsController', GroupSessionDetailsController);

    GroupSessionDetailsController.$inject = ['chart', 'GroupSessionDetails', '$uibModal', 'GroupSession', 'PdfPrint',
        'PendingPdfChartReport', 'toastr', '$rootScope', '$state', '$scope', 'access',
        'REPORT_TYPE', 'ACTIONS', 'params', 'TAB', 'Auth', '$uibModalStack'];

    function GroupSessionDetailsController(chart, GroupSessionDetails, $uibModal, GroupSession, PdfPrint,
                                           PendingPdfChartReport, toastr, $rootScope, $state, $scope, access,
                                           REPORT_TYPE, ACTIONS, params, TAB, Auth, $uibModalStack) {
        var vm = this;

        vm.chart = chart;

        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.title = 'Group Session Details';
        vm.entityClassHumanized = 'Group Session Details';
        vm.entityStateName = 'group-session-details';
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.GROUP_SESSION);
        vm.chart = chart;
        vm.access = access;
        vm.toggleAllRows = false;

        vm.groupSessionDetails = [];

        vm.view = view;
        vm.loadAll = loadAll;
        vm.print = print;
        vm.statusClass = statusClass;
        vm.toggleRow = toggleRow;
        vm.toggleAllRow = toggleAllRow;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.audit = audit;

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_GROUP_SESSION_VIEW, access) || !vm.active) {
                return;
            }

            GroupSessionDetails.findAllByFilter(getFilter(),
                function (result, headers) {
                    if (result) {
                        vm.groupSessionDetails = result;
                        vm.totalItems = headers('X-Total-Count');

                        if (vm.toggleAllRows) {
                            for (var i = 0; i < vm.groupSessionDetails.length; i++) {
                                vm.groupSessionDetails[i].active = vm.toggleAllRows;

                                if (vm.groupSessionDetails[i].id && vm.groupSessionDetails[i].active) {
                                    getJsonTemplate(vm.groupSessionDetails[i]);
                                }
                            }
                        }
                    }
                });
        }

        function view(item) {
            $uibModal.open({
                templateUrl: 'app/entities/group-session-details/detail/group-session-details-dialog.html',
                controller: 'GroupSessionDetailsDialogController',
                windowTopClass: 'custom-dialog-styles',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: GroupSessionDetails.get({id: item.id}).$promise
                }
            }).result.then(function () {
                vm.loadAll();
            }, function () {
                vm.loadAll();
            });
        }

        function statusClass(data) {
            switch (data) {
                case 'REVIEW': {
                    return 'review';
                }
                case 'COMPLETE': {
                    return 'complete';
                }
                case 'STARTED': {
                    return 'started'
                }
            }
        }

        function print(groupSessionDetails) {
            vm.isPrinting = true;

            PendingPdfChartReport.save({
                chart: vm.chart,
                objectId: groupSessionDetails.id,
                name: "" + groupSessionDetails.id,
                reportType: REPORT_TYPE.GROUP_SESSION_DETAIL
            }, onPrintSuccess, onPrintError);

            function onPrintSuccess(result) {
                vm.isPrinting = false;

                toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
            }

            function onPrintError() {
                vm.isPrinting = false;
                toastr.error("We couldn't generate the report.");
            }
        }

        function toggleAllRow() {
            vm.toggleAllRows = !vm.toggleAllRows;
            for (var i = 0; i < vm.groupSessionDetails.length; i++) {
                vm.groupSessionDetails[i].active = vm.toggleAllRows;

                if (vm.groupSessionDetails[i].id && vm.groupSessionDetails[i].active && !vm.groupSessionDetails[i].json) {
                    getJsonTemplate(vm.groupSessionDetails[i]);
                }
            }

        }

        function toggleRow(item) {
            item.active = !item.active;

            if (item.active) {
                getJsonTemplate(item);
            }
        }

        function getJsonTemplate(item) {
            GroupSessionDetails.getJsonTemplate({id: item.id}, function (jsonTemplate) {
                item.jsonTemplate = jsonTemplate;
            });
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result == TAB.GROUP_SESSION) {
                vm.active = true;
                loadAll();
            }
        });

        $scope.$on('$destroy', unsubscribe);

        function search() {
            vm.page = 1;
            loadAll();
        }

        function getFilter() {
            var filter = {};

            filter.chartId = vm.chart.id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter
        }

        function audit(groupSession) {
            $uibModalStack.dismissAll();
            $uibModal.open({
                templateUrl: 'app/entities/group-session/history-audit/group-session-history-audit.html',
                controller: 'GroupSessionHistoryAuditController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                size: 'lg',
                resolve: {
                    groupSession: groupSession
                }
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pdf-chart-report', {
            parent: 'entity',
            url: '/pdf-chart-report',
            data: {
                authorities: [],
                pageTitle: 'PdfChartReports'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pdf-chart-report/list/pdf-chart-report.html',
                    controller: 'PdfChartReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                    var actions = [ACTIONS.ACTION_MAIN_MENU_REPORTS];
                    return Auth.loadActionsAccess(actions).$promise;
                }]
            },
            onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_REPORTS, access);
            }]
        })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('physician-review', {
                parent: 'entity',
                url: '/physician-review',
                data: {
                    authorities: [],
                    pageTitle: 'Physician Review'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/physician-review/list/physician-review.html',
                        controller: 'PhysicianReviewController',
                        controllerAs: 'vm'
                    },
                    'medications@physician-review': {
                        templateUrl: 'app/entities/physician-review/medications/physician-review-medications.html',
                        controller: 'PhysicianReviewMedicationsController',
                        controllerAs: 'vm'
                    },
                    'lab-orders@physician-review': {
                        templateUrl: 'app/entities/physician-review/lab-orders/physician-review-lab-orders.html',
                        controller: 'PhysicianReviewLabOrdersController',
                        controllerAs: 'vm',
                        resolve: {
                            filter: function () {
                                return {
                                    mrNo: null,
                                    chartSelected: null,
                                    dob: null,
                                    searchQuery: null
                                };
                            }
                        }

                    },
                    'e-labs@physician-review': {
                        templateUrl: 'app/entities/physician-review/e-labs/physician-review-e-labs.html',
                        controller: 'PhysicianReviewELabsController',
                        controllerAs: 'vm',
                        resolve: {
                            signPatientResultCredentials: ['BusinessCredential', 'CoreService', 'BUSINESS_ROLES',
                                function (BusinessCredential, CoreService, BUSINESS_ROLES) {
                                    return BusinessCredential.getCredentialByRole({
                                        businessRole: BUSINESS_ROLES.SIGN_PATIENT_RESULT,
                                        id: CoreService.getCurrentFacility().id
                                    }).$promise;
                                }],
                        }
                    },
                    'diagnoses@physician-review': {
                        templateUrl: 'app/entities/physician-review/diagnoses/physician-review-diagnoses.html',
                        controller: 'PhysicianReviewDiagnosesController',
                        controllerAs: 'vm',
                        resolve: {
                            signDiagnosesCredentials: ['BusinessCredential', 'CoreService', 'BUSINESS_ROLES',
                                function (BusinessCredential, CoreService, BUSINESS_ROLES) {
                                    return BusinessCredential.getCredentialByRole({
                                        businessRole: BUSINESS_ROLES.SIGN_DIAGNOSES,
                                        id: CoreService.getCurrentFacility().id
                                    }).$promise;
                                }],
                        }
                    }
                },
                resolve: {
                    physicianCredentials: ['BusinessCredential', 'CoreService', 'BUSINESS_ROLES',
                        function (BusinessCredential, CoreService, BUSINESS_ROLES) {
                            return BusinessCredential.getCredentialByRole({
                                businessRole: BUSINESS_ROLES.PHYSICIAN,
                                id: CoreService.getCurrentFacility().id
                            }).$promise;
                        }],
                },
            });
    }
})();

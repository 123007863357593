(function () {
    'use strict';

    angular
        .module('udt')
        .directive('jSignature', jSignature);

    jSignature.$inject = ['$timeout', 'JSignature'];

    function jSignature($timeout, JSignature) {

        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/j-signature.html',
            scope: {
                sig: '=',
                ngModel: '=',
                validators: '@',
                messageContainer: '@',
                width: '@',
                height: '@',
                color: '@',
                bgColor: '@',
                lineWidth: '@',
                cssclass: '@',
                undo: '@',
                save: '='
            },
            link: linkFunc
        };

        return directive;

        /* private helper methods*/

        function linkFunc($scope, $element) {
            //Setting and ID
            $scope.jSignatureId = 'jSignatureId-' + new Date().valueOf();
            var options = {
                width: $scope.width,
                height: $scope.height,
                color: $scope.color || '#00008B',
                'background-color': $scope.bgColor || '#EEEEEE',
                lineWidth: $scope.lineWidth,
                cssClass: $scope.cssClass,
                UndoButton: $scope.undo
            };

            $scope.bindSignature = function () {
                if ($scope && $scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                    $scope.ngModel = (JSignature.getData(JSignature.exportTypes.NATIVE).length) ? JSignature.getData(JSignature.exportTypes.IMAGE_PNG_BASE64) : undefined;
                    $scope.$apply();
                }
            }

            $timeout(function () {
                JSignature.initialize($scope, options)
                    .then(function (el) {
                        el.on('change', function (e) {
                            $scope.bindSignature();
                        })
                    });
            }, 20);

            $scope.reset = function () {
                JSignature.reset()
                    .then(function (result) {
                        $scope.ngModel = undefined;
                    });
            };


        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Verifytx', Verifytx);

    Verifytx.$inject = ['$resource'];

    function Verifytx ($resource) {
        var resourceUrl =  'api/verifytx';

        return $resource(resourceUrl, {}, {
            'getByChart': {url: 'api/verifytx/vob-by-chart/:id', method: 'GET'},
            'getFacilities': {url: 'api/verifytx/facilities/:corporationId', method: 'GET'},
            'getPayers': {url: 'api/verifytx/payers/:corporationId/:payer', method: 'GET'},
            'new': {url: 'api/verifytx/vob/new', method: 'POST'},
            'verify': {url: 'api/verifytx/vob/verify', method: 'POST'},
            'filter': {url: 'api/verifytx/filter', method: 'GET', isArray: true},
            'sendVobsByEmail': {url: 'api/verifytx/send-vobs-by-email/:facilityId', method: 'GET', isArray: false}
        });
    }
})();

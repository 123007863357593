(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LaboratoryDialogController', LaboratoryDialogController);

    LaboratoryDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Laboratory', 'CountryState'];

    function LaboratoryDialogController ($timeout, $scope, $uibModalInstance, entity, Laboratory, CountryState) {
        var vm = this;

        vm.laboratory = entity;
        vm.clear = clear;
        vm.save = save;
        vm.countryState = CountryState.query();
        vm.hl7Versions = ['v2_3', 'v2_7'];


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.laboratory.id !== null) {
                Laboratory.update(vm.laboratory, onSaveSuccess, onSaveError);
            } else {
                Laboratory.save(vm.laboratory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:laboratoriesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();

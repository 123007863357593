(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('Dashboard2Controller', DashboardController);

    DashboardController.$inject = ['CoreService', '$state'];

    function DashboardController(CoreService, $state) {
        var vm = this;

        if (CoreService.getCurrentFacility() == null) {
            $state.go('home');
        }
    }
})();

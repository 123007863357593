(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportEditController', IncidentReportEditController);

    IncidentReportEditController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'IncidentReport',
        'Building', 'IncidentType', 'Chart', 'Facility', 'CoreService', 'Employee',
        '$state', '$uibModal', 'DTColumnBuilder', 'TypeLevelCare', 'DTOptionsBuilder',
        'GUIUtils', '$q', '$compile', 'IncidentReportNote', 'toastr', 'PopupService',
        'IncidentDepartment', 'IncidentSeverity', 'INCIDENT_REPORT_STATUS',
        '$rootScope', 'ConfirmationService'];

    function IncidentReportEditController($timeout, $scope, $stateParams, entity, IncidentReport,
                                          Building, IncidentType, Chart, Facility, CoreService, Employee,
                                          $state, $uibModal, DTColumnBuilder, TypeLevelCare, DTOptionsBuilder,
                                          GUIUtils, $q, $compile, IncidentReportNote, toastr, PopupService,
                                          IncidentDepartment, IncidentSeverity, INCIDENT_REPORT_STATUS,
                                          $rootScope, ConfirmationService) {
        var vm = this;

        vm.title = 'Incident Report';
        vm.entityClassHumanized = 'Incident Report';
        vm.entityStateName = 'incident-report';
        vm.assignReviewers = [];
        vm.notifiedSupervisors = [];
        vm.onDutySupervisors = [];
        vm.charts = [];
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;
        vm.changes = false;
        vm.dtInstance = {};
        vm.employees = [];
        vm.incidentReport = entity;
        vm.facility = CoreService.getCurrentFacility();
        vm.incidentReportOriginal = angular.copy(entity);
        vm.incidentReportNotes = [];
        vm.incidentTypes = IncidentType.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.incidentDepartments = IncidentDepartment.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.incidentSeverities = IncidentSeverity.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.staffInvolves = [];
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.originalIncidentReport = angular.copy(vm.incidentReport);
        vm.isSaving = false;
        vm.buildings = Building.byFacility({id: vm.incidentReport.facility.id});
        vm.corporation = CoreService.getCorporation();
        vm.typeLevelCares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.employee = CoreService.getCurrentEmployee();
        vm.incidentReportSignatures = vm.incidentReport.id ? IncidentReport.getSignatures({id: vm.incidentReport.id}) : [];

        vm.addIncidentReportNotes = addIncidentReportNotes;
        vm.deleteIncidentReportNote = deleteIncidentReportNote;
        vm.viewIncidentReportNote = viewIncidentReportNote;
        vm.editIncidentReportNote = editIncidentReportNote;
        vm.getAssignReviewers = getAssignReviewers;
        vm.getNotifiedSupervisors = getNotifiedSupervisors;
        vm.getOnDutySupervisors = getOnDutySupervisors;
        vm.getCharts = getCharts;
        vm.getEmployees = getEmployees;
        vm.openCalendar = openCalendar;
        vm.openSingDialog = openSingDialog;
        vm.save = save;
        vm.search = search;
        vm.alreadySigned = alreadySigned;
        vm.signIncidentReportNote = signIncidentReportNote;
        vm.canAddIncidentReportNote = canAddIncidentReportNote;
        vm.allowSign = allowSign;
        vm.canSave = canSave;
        vm.allIncidentReportNotesSigned = allIncidentReportNotesSigned;
        vm.isFullIncidentReportNotes = isFullIncidentReportNotes;
        vm.isEmployeeWithoutIncidentReportNote = isEmployeeWithoutIncidentReportNote;
        vm.isAssignReviewer = isAssignReviewer;
        vm.isFinalized = isFinalized;
        vm.isFullIncidentReportNotesbyAssignedReviewers = isFullIncidentReportNotesbyAssignedReviewers;
        vm.isFullIncidentReportNotesbyEmployees = isFullIncidentReportNotesbyEmployees;

        init();

        function init() {
            vm.watcher =
                $scope.$watch('vm.incidentReport', function (newValue, oldValue, scope) {
                    vm.changes = !angular.equals(vm.incidentReport, vm.incidentReportOriginal);
                }, true);

            $scope.$on('$destroy', function () {
                if (vm.watcher) {
                    vm.watcher();
                }
            });

            vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();
                if (vm.incidentReport.id) {
                    IncidentReportNote.getAllByIncidentReport({
                        id: vm.incidentReport.id,
                        page: vm.page - 1,
                        size: vm.itemsPerPage,
                        facilityId: CoreService.getCurrentFacility().id
                    }, function (result, headers) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.incidentReportNotes = result;
                        defer.resolve(vm.incidentReportNotes);
                    });
                } else {
                    defer.resolve(vm.incidentReportNotes);
                }
                return defer.promise;
            }).withBootstrap()
                .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false).withOption('aaSorting', [[0, 'desc']])
                .withOption('headerCallback', function (header) {
                    if (!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('fnRowCallback',
                    function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        $compile(nRow)($scope);
                    });

            vm.dtColumns = [
                DTColumnBuilder.newColumn(null).withTitle('Employee Name').notSortable().renderWith(function (data, type, full) {
                    return data.employee.lastName + ', ' + data.employee.firstName;
                }),
                DTColumnBuilder.newColumn(null).withTitle('Date').notSortable().renderWith(function (data, type, full) {
                    return moment(data.createdDate).format($rootScope.amDateFormat);
                }),
                DTColumnBuilder.newColumn(null).withTitle('Note Name').notSortable().renderWith(function (data, type, full) {
                    return data.name;
                }),
                DTColumnBuilder.newColumn(null).withTitle('Signed').renderWith(function (data, type, full) {
                    return GUIUtils.colorHtmlYesNo(data.signed);
                }),
                DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable().renderWith(actionsHtml)
            ];

            $timeout(function () {
                angular.element('.form-group:eq(1)>input').focus();
            });
        }

        function save() {
            vm.isSaving = true;
            if (vm.incidentReport.id !== null) {
                IncidentReport.update(vm.incidentReport, function () {
                    $state.reload();
                }, onSaveError);
            } else {
                IncidentReport.save(vm.incidentReport, function (result) {
                    $state.go('incident-report.edit', {id: result.id});
                }, onSaveError);
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function reload() {
            vm.dtInstance.reloadData();
        }

        function isEmployee() {
            return (_.find(vm.incidentReport.employees, ['id', CoreService.getCurrentEmployee().id])) ? true : false;
        }

        function isAssignReviewer() {
            return (_.find(vm.incidentReport.assignReviewers, ['id', CoreService.getCurrentEmployee().id])) ? true : false;
        }

        function alreadySigned() {
            return (_.find(vm.incidentReportSignatures, ['employee.id', CoreService.getCurrentEmployee().id])) ? true : false;
        }

        function addIncidentReportNotes() {
            $uibModal.open({
                templateUrl: 'app/entities/incident-report-note-template/assign/incident-report-note-template-assign-dialog.html',
                controller: 'IncidentReportNoteTemplateAssignDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    incidentReport: vm.incidentReport
                }
            }).result.then(function (result) {
                reload();
            });
        }

        function deleteIncidentReportNote(id) {
            ConfirmationService.showDelete('Incident Report Note').result.then(function () {
                IncidentReportNote.delete({id: id}, function () {
                    vm.dtInstance.reloadData();
                    toastr.success("Auto save data");
                });
            })
        }

        function editIncidentReportNote(id) {
            $uibModal.open({
                templateUrl: 'app/entities/incident-report-note/update/incident-report-note-dialog.html',
                controller: 'IncidentReportNoteDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: IncidentReportNote.get({id: id}).$promise,
                    jsonTemplate: IncidentReportNote.getJsonTemplate({id: id}).$promise
                }
            });
        }

        function viewIncidentReportNote(id) {
            $uibModal.open({
                templateUrl: 'app/entities/incident-report-note/detail/incident-report-note-detail.html',
                controller: 'IncidentReportNoteDetailController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['IncidentReportNote', function (IncidentReportNote) {
                        return IncidentReportNote.get({id: id}).$promise;
                    }],
                    jsonTemplate: IncidentReportNote.getJsonTemplate({id: id}).$promise
                }
            }).result.then(function () {

            }, function (result) {
            });
        }

        function onSingSuccess() {
            IncidentReport.get({id: vm.incidentReport.id}, function (incidentReport) {
                vm.incidentReport = incidentReport;
                IncidentReport.getSignatures({id: vm.incidentReport.id}, function (incidentReportSignatures) {
                    vm.incidentReportSignatures = incidentReportSignatures;
                });

                if (vm.incidentReport.status == 'FINALIZED') {
                    $state.go('incident-report.detail', {id: vm.incidentReport.id});
                }
            });
        }

        function openSingDialog() {
            PopupService.showSignaturePopup().result.then(function (data) {
                IncidentReport.sign({
                    incidentReport: vm.incidentReport,
                    signature: data.employeeSignature,
                    pin: data.employeeSignaturePin
                }, onSingSuccess, onSaveError);
            });
        }

        function actionsHtml(data, type, full, meta) {

            var action =
                '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +

                '<li  role="menuitem">' +
                '<a href="" class="dd-link blue" ng-click="vm.viewIncidentReportNote(' + data.id + ')">' +
                '<i class="fa fa-eye" aria-hidden="true"></i>View' +
                '</a>' +
                '</li>';

            if (allowModifyIncidentReportNote(data) && !data.signed) {
                action +=
                    '<li role="menuitem">' +
                    '<a href="" class="dd-link green" ng-click="vm.signIncidentReportNote(' + data.id + ')">' +
                    '<i class="fa fa-pencil" aria-hidden="true"></i>Sign' +
                    '</a>' +
                    '</li>';
            }

            if (allowModifyIncidentReportNote(data)) {
                action +=
                    '<li role="menuitem">' +
                    '<a href="" class="dd-link orange" ng-click="vm.editIncidentReportNote(' + data.id + ')">' +
                    '<i class="fa fa-edit" aria-hidden="true"></i>Edit' +
                    '</a>' +
                    '</li>' +

                    '<li role="menuitem">' +
                    '<a href="" class="dd-link red" ng-click="vm.deleteIncidentReportNote(' + data.id + ')">' +
                    '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                    '</a>' +
                    '</li>'
            }

            action +=
                '</ul>' +
                '</div>';

            return action;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getAssignReviewers(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.assignReviewers = result;
                });
            }
        }

        function getNotifiedSupervisors(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.notifiedSupervisors = result;
                });
            }
        }

        function getOnDutySupervisors(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.onDutySupervisors = result;
                });
            }
        }

        function getCharts(query) {
            if (query && query.length > 3) {
                var filter = {};

                filter.page = 0;
                filter.size = 20;
                filter.facilityId = vm.incidentReport.facility.id;
                filter.searchQuery = query;
                filter.statuses = ['CURRENT'];

                vm.charts = Chart.fullFilter(filter);
            }
        }

        function allowModifyIncidentReportNote(incidentReportNote) {

            return vm.incidentReport.status == INCIDENT_REPORT_STATUS.PENDING &&
                vm.employee.id == incidentReportNote.employee.id;
        }

        function signIncidentReportNote(id) {
            PopupService.showSignaturePopup().result.then(function (data) {
                IncidentReportNote.sign({
                    signature: data.employeeSignature,
                    pin: data.employeeSignaturePin,
                    incidentReportNote: {id: id}
                }, function (result) {
                    if (result) {
                        toastr.success("The Incident Report Note was signed successfully.");
                    } else {
                        toastr.error("The Incident Report Note wasn't signed.");
                    }
                    $state.reload();
                }, function () {
                    toastr.error("The Incident Report Note wasn't signed.");
                    $state.reload();
                });
            });
        }

        function allowSign() {
            if (!vm.incidentReport.id || !isAssignReviewer()) {

                return false;
            }

            switch (vm.incidentReport.status) {
                case INCIDENT_REPORT_STATUS.PENDING:

                    return isFullIncidentReportNotes() && allIncidentReportNotesSigned();
                case INCIDENT_REPORT_STATUS.INREVIEW:

                    return !alreadySigned();
                case INCIDENT_REPORT_STATUS.FINALIZED:

                    return false;
            }
        }

        function isFullIncidentReportNotesbyAssignedReviewers() {
            var employeeFromNotes = _.map(vm.incidentReportNotes, function (incidentReportNote) {

                return incidentReportNote.employee.id;
            });

            return _.filter(vm.incidentReport.assignReviewers, function (employee) {

                return employeeFromNotes.indexOf(employee.id) == -1;
            }).length == 0;
        }

        function isFullIncidentReportNotesbyEmployees() {
            var employeeFromNotes = _.map(vm.incidentReportNotes, function (incidentReportNote) {

                return incidentReportNote.employee.id;
            });

            return _.filter(vm.incidentReport.employees, function (employee) {

                return employeeFromNotes.indexOf(employee.id) == -1;
            }).length == 0;
        }

        function isFullIncidentReportNotes() {
            var employeeFromNotes = _.map(vm.incidentReportNotes, function (incidentReportNote) {

                return incidentReportNote.employee.id;
            });

            var employees = vm.incidentReport.employees;

            _.forEach(vm.incidentReport.assignReviewers, function (employee) {
                if (employees.indexOf(employee) != -1) {
                    employees.push(employee);
                }
            })

            return _.filter(employees, function (employee) {

                return employeeFromNotes.indexOf(employee.id) == -1;
            }).length == 0;
        }

        function allIncidentReportNotesSigned() {
            return _.filter(vm.incidentReportNotes, function (incidentReportNote) {

                return !incidentReportNote.signed;
            }).length == 0;
        }

        function canAddIncidentReportNote() {
            if (!vm.incidentReport.id) {

                return false;
            }

            switch (vm.incidentReport.status) {
                case INCIDENT_REPORT_STATUS.PENDING: {

                    return isEmployee() || isAssignReviewer();
                }
                case INCIDENT_REPORT_STATUS.INREVIEW: {

                    return isAssignReviewer() && !alreadySigned();
                }
                case INCIDENT_REPORT_STATUS.FINALIZED:

                    return false;
            }

            return false;
        }

        function canSave() {
            if (!vm.incidentReport.id) {

                return true;
            }

            switch (vm.incidentReport.status) {
                case INCIDENT_REPORT_STATUS.PENDING: {

                    return isAssignReviewer();
                }
                case INCIDENT_REPORT_STATUS.INREVIEW: {

                    return isAssignReviewer() && !alreadySigned();
                }
                case INCIDENT_REPORT_STATUS.FINALIZED:

                    return false;
            }

            return false;
        }

        function isEmployeeWithoutIncidentReportNote(employee) {

            return _.map(vm.incidentReportNotes, function (incidentReportNote){
                return incidentReportNote.employee.id;
            }).indexOf(employee.id) == -1;
        }

        function isFinalized() {
            return vm.incidentReport.status == INCIDENT_REPORT_STATUS.FINALIZED;
        }
    }
})();

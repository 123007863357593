(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationAllergiesHistoryController', EvaluationAllergiesHistoryController);

    EvaluationAllergiesHistoryController.$inject = ['$uibModalInstance', 'Allergies', 'chart'];

    function EvaluationAllergiesHistoryController($uibModalInstance, Allergies, chart) {
        var vm = this;

        vm.allergiesInactive = [];
        Allergies.findAllByChartAndStatus({id: chart.id, status: false}, function (result) {
            vm.allergiesInactive = result;

            _.forEach(vm.allergiesInactive, function (allergy) {
                allergy.reaction = _.map(allergy.reactions, function (reaction) {

                    return reaction.name;
                }).join(', ');
            });
        });

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

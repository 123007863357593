(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DeleteEntityController',DeleteEntityController);

    DeleteEntityController.$inject = ['$uibModalInstance', 'entity'];

    function DeleteEntityController($uibModalInstance, entity) {
        var vm = this;

        vm.entity = entity.name;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            $uibModalInstance.close();
        }
    }
})();

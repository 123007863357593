(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SignatureGuarantorDialogController', SignatureGuarantorDialogController);

    SignatureGuarantorDialogController.$inject = ['$uibModalInstance', 'evaluation', 'Contacts'];

    function SignatureGuarantorDialogController($uibModalInstance, evaluation, Contacts) {
        var vm = this;

        vm.evaluation = evaluation;
        vm.guarantor = null;
        vm.signatureOpts = getSignatureOptions();
        vm.odsGuarantorSignatureNameId = Math.floor((Math.random() * 100) + 1);
        vm.guarantorSignature = "";

        vm.guarantors = Contacts.findAllGuarantors({id: vm.evaluation.chartId});

        vm.clear = clear;
        vm.sign = sign;

        function getSignatureOptions() {

            return {
                width: 620,
                height: 250,
                cssClass: 'signature-canvas',
                color: '#00008B',
                'background-color': '#fff'
            };
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function sign() {
            $uibModalInstance.close({signature: vm.guarantorSignature, guarantor: vm.guarantor});
        }
    }
})();

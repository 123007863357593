(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartExportDialogController', ChartExportDialogController);

    ChartExportDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'CoreService',
        'entity', 'ChartExport', 'Employee', 'FacesheetTpl', 'Corporation', 'Facility'];

    function ChartExportDialogController($timeout, $scope, $stateParams, $uibModalInstance, CoreService,
                                         entity, ChartExport, Employee, FacesheetTpl, Corporation, Facility) {
        var vm = this;

        vm.chartExport = entity;
        vm.facesheetTpls = [];
        vm.facilities = [];
        vm.corporation = null;
        vm.facility = null;
        vm.corporations = Corporation.query();

        vm.clear = clear;
        vm.save = save;
        vm.onCorporationSelect = onCorporationSelect;
        vm.onFacilitySelect = onFacilitySelect;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.chartExport.id !== null) {
                ChartExport.update(vm.chartExport, onSaveSuccess, onSaveError);
            } else {
                ChartExport.save(vm.chartExport, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:chartExportUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function onCorporationSelect() {
            vm.facesheetTpls = [];
            vm.facility = null;

            Facility.byCorporation({id: vm.corporation.id}, function (result) {
                vm.facilities = result;
            });
        }

        function onFacilitySelect() {
            vm.facesheetTpls = [];

            var filter = {
                facilityId: CoreService.getCurrentFacility().id,
                page: 0,
                size: 100000,
                enabled: true
            };

            FacesheetTpl.filter(filter, function (result) {
                vm.facesheetTpls = result;
            });
        }
    }
})();

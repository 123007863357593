(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReactionDialogController', ReactionDialogController);

    ReactionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Reaction'];

    function ReactionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Reaction) {
        var vm = this;

        vm.reaction = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.reaction.id !== null) {
                Reaction.update(vm.reaction, onSaveSuccess, onSaveError);
            } else {
                Reaction.save(vm.reaction, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:reactionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

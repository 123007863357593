(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientMedicationFaxLog', PatientMedicationFaxLog);

    PatientMedicationFaxLog.$inject = ['$resource', 'DataUtils'];

    function PatientMedicationFaxLog ($resource, DataUtils) {
        var resourceUrl =  'api/patient-medication-fax-logs/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'filter': {method: 'GET', isArray: true, url: 'api/patient-medication-fax-logs/filter'},
            'download': {
                url: 'api/patient-medication-fax-logs/download/:id',
                responseType: 'arraybuffer',
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('FacilityLab', FacilityLab);

    FacilityLab.$inject = ['$resource'];

    function FacilityLab($resource) {
        var resourceUrl = 'api/facility-labs/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'create': {method: 'POST', isArray: true},
            'filter': {url: 'api/facility-labs/filter', method: 'GET', isArray: true},
            'existAccount': {
                url: 'api/facility-labs/exist-account/:account/:laboratoryId', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = {value: data};
                    }

                    return data;
                }
            },
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateController', EvaluationTemplateController);

    EvaluationTemplateController.$inject = ['EvaluationTemplate', 'GUIUtils', 'CoreService', '$uibModal',
        'ConfirmationService', '$rootScope', 'GenericEntityDatatableService', 'Principal',
        'ROLES', '$q'];

    function EvaluationTemplateController(EvaluationTemplate, GUIUtils, CoreService, $uibModal,
                                          ConfirmationService, $rootScope, GenericEntityDatatableService, Principal,
                                          ROLES, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Care & Delivery Templates',
            newButtonTitle: 'New Care & Delivery Template',
            entityClassHumanized: 'Care & Delivery Templates',
            entityStateName: 'evaluation-template',
            service: EvaluationTemplate,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: true,
            options: [{key: 'aaSorting', value: [[1, 'desc']]}],
            withSelectionColumn: false,
            columns: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'createdDate',
                    title: 'Created Date',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
                {
                    name: 'createdBy',
                    title: 'Created By'
                },
                {
                    name: 'enabled',
                    title: 'Enabled',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(!!data.enabled);
                    }
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('evaluation-template'),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(),
                    {
                        action: function (data) {
                            return del(data.id);
                        },
                        reload: true
                    }),
                {
                    name: 'Clone',
                    action: function (data) {
                        return cloneEvaluationTemplate(data.id);
                    },
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-clone',
                    reload: true
                },
                {
                    name: 'Clone to Facility',
                    action: function (data) {
                        return cloneEvaluationTemplateToFacility(data.id);
                    },
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-clone',
                    ngIf: function () {
                        return Principal.hasAnyAuthority([ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN]);
                    },
                    reload: true
                },
            ]
        }

        function cloneEvaluationTemplate(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/evaluation-template/clone/evaluation-template-clone-dialog.html',
                    controller: 'EvaluationTemplateCloneController',
                    controllerAs: 'vm',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['EvaluationTemplate', function (EvaluationTemplate) {
                            return EvaluationTemplate.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function cloneEvaluationTemplateToFacility(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/evaluation-template/clone-to-facility/evaluation-template-clone-to-facility-dialog.html',
                    controller: 'EvaluationTemplateCloneToFacilityController',
                    controllerAs: 'vm',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['EvaluationTemplate', function (EvaluationTemplate) {
                            return EvaluationTemplate.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('EvaluationTemplate').result.then(function () {
                    EvaluationTemplate.delete({id: id}, function () {
                        return resolve();
                    });
                });
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartCopyController', ChartCopyController);

    ChartCopyController.$inject = ['$uibModalInstance', 'Facility', 'CoreService'];

    function ChartCopyController($uibModalInstance, Facility, CoreService) {
        var vm = this;

        vm.currentFacility = CoreService.getCurrentFacility();

        Facility.byCorporation({id: CoreService.getCorporation().id}, function (facilities) {
            vm.facilities = _.filter(facilities, function (facility) {

                return facility.id !== vm.currentFacility.id;
            });
        });

        vm.clear = clear;
        vm.copy = copy;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function copy() {
            $uibModalInstance.close(vm.facility);
        }
    }
})();

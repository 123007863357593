/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MarketersBoxController', MarketersBoxController);

    MarketersBoxController.$inject = ['$scope', '$state', 'CoreService', 'Chart'];

    function MarketersBoxController($scope, $state, CoreService, Chart) {
        var vm = this;

        vm.countChartsMarketers = null;
        vm.count = 0;

        loadData();
        vm.openCharts = openCharts;

        function loadData() {
            vm.countChartsMarketers = Chart.countChartsWithMarketers({id: CoreService.getCurrentFacility().id}, function (result) {
                _.forEach(result, function (el) {
                    vm.count += el.count;
                });
            });
        }

        function openCharts(employee){
            $state.go('charts-marketer', {marketer: employee.id});
        }
    }
})();

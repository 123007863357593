(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('StayBoxController', StayBoxController);

    StayBoxController.$inject = ['Chart', 'CoreService'];

    function StayBoxController(Chart, CoreService) {

        var vm = this;

        vm.count = 0;
        vm.facility = CoreService.getCurrentFacility();

        Chart.aveLengthOfStay({
            id: vm.facility.id,
        }, function (data) {
            if (data.value) {
                vm.count = data.value;
            }
        });
    }
})();

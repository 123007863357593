(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmInsuranceDialogController', CrmInsuranceDialogController);

    CrmInsuranceDialogController.$inject = ['$uibModalInstance', 'InsuranceCarrierSearch', 'InsuranceRelation', 'CountryState',
                                            'CrmInsurance', 'entity', 'CoreService'];

    function CrmInsuranceDialogController($uibModalInstance, InsuranceCarrierSearch, InsuranceRelation, CountryState,
                                          CrmInsurance, entity, CoreService) {
        var vm = this;

        vm.crmInsurance = entity;
        vm.insuranceRelations = InsuranceRelation.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.states = CountryState.query();
        vm.carriers = [];


        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getInsuranceCarrier = getInsuranceCarrier;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function save() {
            vm.isSaving = true;
            if (vm.crmInsurance.id !== null) {
                CrmInsurance.update(vm.crmInsurance, onSaveSuccess, onSaveError);
            } else {
                CrmInsurance.save(vm.crmInsurance, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        function getInsuranceCarrier(query) {
            if (query){
                vm.carriers = [carrierTagTransform(query)];
                if (query.length > 2) {
                    query = _.lowerCase(query);
                    InsuranceCarrierSearch.query({
                        query: query
                    }, function (result) {
                        vm.carriers = _.concat(vm.carriers, result);
                    });
                }
            }
        }

        function carrierTagTransform(newTag) {
            return {
                id: null,
                acctno: newTag,
                name: newTag
            };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentReportNoteDetailController', IncidentReportNoteDetailController);

    IncidentReportNoteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$uibModalInstance',
        'jsonTemplate'];

    function IncidentReportNoteDetailController($scope, $rootScope, $stateParams, entity, $uibModalInstance,
                                                jsonTemplate) {
        var vm = this;

        vm.incidentReportNote = entity;
        vm.jsonTemplate = jsonTemplate;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.close('dismiss');
        }
    }
})();

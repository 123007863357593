/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BedBoxController', BedBoxController);

    BedBoxController.$inject = ['$scope', '$state', 'Bed', 'CoreService', '$uibModal'];

    function BedBoxController($scope, $state, Bed, CoreService, $uibModal) {
        var vm = this;

        vm.beds = null;
        vm.count = 0;

        vm.assignBed = assignBed;
        loadData();

        function loadData() {
            vm.beds = Bed.findAllEmptyAndActive({id: CoreService.getCurrentFacility().id}, function (result) {
                vm.count = result.length;
            });
        }

        function assignBed(id) {
            $uibModal.open({
                templateUrl: 'app/entities/bed/bed-sheet/bed-sheet-assign.html',
                controller: 'BedSheetAssignDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    bed: ['Bed', function (Bed) {
                        return Bed.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadData()
            }, function () {
                loadData()
            });
        }
    }
})();

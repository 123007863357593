(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidQuestionController', CovidQuestionController);

    CovidQuestionController.$inject = ['GenericEntityDatatableService', 'CovidQuestion', 'CoreService', 'GUIUtils'];

    function CovidQuestionController (GenericEntityDatatableService, CovidQuestion, CoreService, GUIUtils) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Covid Questions',
            newButtonTitle: 'New Covid Question',
            entityClassHumanized: 'Covid Questions',
            entityStateName: 'covid-question',
            service: CovidQuestion,
            serviceMethod: 'findAllByFacility',
            params: params,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Enable',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.enable);
                    }
                },
                {
                    name: 'xorder',
                    title: 'Order',
                },
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('covid-question'),
                GenericEntityDatatableService.getDeleteAction('covid-question')
            ]
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Diagnoses', Diagnoses);

    Diagnoses.$inject = ['$resource','DateUtils'];

    function Diagnoses ($resource, DateUtils) {
        var resourceUrl =  'api/diagnoses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'create': {url: 'api/diagnoses/create',  method:'POST'},
            'findAllByChart': {url: 'api/diagnoses/by-chart/:id', method: 'GET', isArray: true},
            'findAllInactiveDiagnosesByChart': {url: 'api/diagnoses/inactive/by-chart/:id', method: 'GET', isArray: true},
            'findAllActiveDiagnosesByChartId': {url: 'api/diagnoses/active/by-chart/:id', method: 'GET', isArray: true},
            'findAllInactiveDiagnosesByChartWithSignature': {url: 'api/diagnoses/inactive/by-chart-with-signature/:id', method: 'GET', isArray: true},
            'findAllActiveDiagnosesByChartWithSignature': {url: 'api/diagnoses/active/by-chart-with-signature/:id', method: 'GET', isArray: true},
            'findAllPhysicianReview': {url: 'api/diagnoses/physician-review', method: 'GET', isArray: true},
            'lastsIcd10UsedByFacility': {url: 'api/diagnoses/lasts-icd10-used-by-facility', method: 'GET', isArray: true},
            'sign': {url: 'api/diagnoses/sign', method: 'POST'},
            'discontinue': {url: 'api/diagnoses/discontinue', method: 'POST'},
            'reorder': { method: 'POST', url: 'api/diagnoses/reorder', isArray: false }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('InsurancePicture', InsurancePicture);

    InsurancePicture.$inject = ['$resource'];

    function InsurancePicture ($resource) {
        var resourceUrl =  'api/insurance-pictures/:id';

        return $resource(resourceUrl, {}, {
            'upload': {url: 'api/insurance-pictures/upload', method: 'POST'},
            'getOriginalBase64ByInsurance': {url: 'api/insurance-pictures/get-original-base-64/by-insurance/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        var result = {image: data};
                    }

                    return result;
                }}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityLabDialogController', FacilityLabDialogController);

    FacilityLabDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'FacilityLab'];

    function FacilityLabDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                          FacilityLab) {
        var vm = this;

        vm.facilityLab = entity;
        vm.laboratories = [entity.laboratory];
        vm.employess = [entity.laboratory];
        vm.requestedBy = [entity.requestedBy];
        vm.accountExist = null;

        vm.pattern = "[a-zA-Z0-9_\-]+";

        vm.clear = clear;
        vm.save = save;
        vm.searchAccount = searchAccount;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.facilityLab.id !== null) {
                FacilityLab.update(vm.facilityLab, onSaveSuccess, onSaveError);
            } else {
                FacilityLab.save(vm.facilityLab, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:facilityLabUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function searchAccount(query) {
            if (query && query.length > 0) {
                FacilityLab.existAccount({account: query, laboratoryId: entity.laboratory.id}, function (result) {
                    vm.accountExist = result.value != null && result.value !== vm.facilityLab.id;
                });
            }
        }
    }
})();

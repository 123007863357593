(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('UrgentIssueEmployee', UrgentIssueEmployee);

    UrgentIssueEmployee.$inject = ['$resource'];

    function UrgentIssueEmployee($resource) {
        var resourceUrl = 'api/urgent-issue-employees/:id';

        return $resource(resourceUrl, {}, {
            'getAllEmployeeByUrgentIssue': {
                url: 'api/urgent-issue-employees/by-issue/:id',
                method: 'GET',
                isArray: true
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('campaign', {
                parent: 'entity',
                url: '/campaign',
                data: {
                    authorities: [],
                    pageTitle: 'Campaigns'
                },
                ncyBreadcrumb: {
                    label: 'Campaigns'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/campaign/list/campaign.html',
                        controller: 'CampaignController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CAMPAIGN];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_CAMPAIGN, access);
                }]
            })
            .state('outreach-campaign', {
                parent: 'entity',
                url: '/outreach-campaign',
                data: {
                    authorities: [],
                    pageTitle: 'OutreachCampaign'
                },
                ncyBreadcrumb: {
                    label: 'Outreach Campaigns',
                    parent: 'entity'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/campaign/outreach-campaign/outreach-campaign.html',
                        controller: 'OutreachCampaignController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: ['CoreService', function (CoreService) {
                        return CoreService.readCampaignOutreachFromLocalStore();
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_OUTREACH_CAMPAIGNS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_OUTREACH_CAMPAIGNS, access);
                }]
            })
    }
})();

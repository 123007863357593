(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Vitals', Vitals);

    Vitals.$inject = ['$resource', 'DateUtils', 'CoreService'];

    function Vitals($resource, DateUtils, CoreService) {
        var resourceUrl = 'api/vitals/:id';
        var facility = CoreService.getCurrentFacility();

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.date = new Date(DateUtils.convertDateTimeFromServer(data.date)
                            .toLocaleString("en-US", {timeZone: facility.timeZone}));

                        data.bpSystolic = parseFloat(data.bpSystolic);
                        data.bpDiastolic = parseFloat(data.bpDiastolic);
                        data.temperature = parseFloat(data.temperature);
                        data.pulse = parseFloat(data.pulse);
                        data.respiration = parseFloat(data.respiration);
                        data.o2Saturation = parseFloat(data.o2Saturation);
                    }
                    return data;
                }
            },
            'findAllByChart': {url: 'api/vitals/by-chart/:id', method: 'GET', isArray: true},
            'update': {method: 'PUT'},
            'findLastByChart': {
                url: 'api/vitals/last/by-chart/:id', method: 'GET', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.date = new Date(DateUtils.convertDateTimeFromServer(data.date)
                            .toLocaleString("en-US", {timeZone: facility.timeZone}));
                    }
                    return data;
                }
            },
            'findLastBp': {url: "api/vitals/last/bp/by-chart/:id/size/:size", method: 'GET', isArray: true},
            'findLastTemp': {url: "api/vitals/last/temp/by-chart/:id/size/:size", method: 'GET', isArray: true},
            'findLastRespiration': {
                url: "api/vitals/last/respiration/by-chart/:id/size/:size",
                method: 'GET',
                isArray: true
            },
            'findLastPulse': {url: "api/vitals/last/pulse/by-chart/:id/size/:size", method: 'GET', isArray: true},
            'findLastO2': {url: "api/vitals/last/o2/by-chart/:id/size/:size", method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('FacilityPhoneNumber', FacilityPhoneNumber);

    FacilityPhoneNumber.$inject = ['$resource'];

    function FacilityPhoneNumber ($resource) {
        var resourceUrl =  'api/facility-phone-numbers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'byFilter': {url: 'api/facility-phone-numbers/filter', method: 'GET', isArray: true},
            'getAvailablePhoneNumbers': {url: 'api/facility-phone-numbers/phone-numbers/filter', method: 'GET', isArray: true},
            'orderPhoneNumbers': {url: 'api/facility-phone-numbers/order-phone-numbers', method: 'GET', isArray: true}
        });
    }
})();

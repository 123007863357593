(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProtocolController', ProtocolController);

    ProtocolController.$inject = ['GenericEntityDatatableService', '$uibModal', '$q', 'Protocol',
                                   'CoreService', 'GUIUtils', 'toastr', 'ConfirmationService'];

    function ProtocolController(GenericEntityDatatableService, $uibModal, $q, Protocol,
                                CoreService, GUIUtils, toastr, ConfirmationService) {
        var vm = this;


        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Protocols',
            entityClassHumanized: 'Protocols',
            entityStateName: 'protocol',
            service: Protocol,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'type',
                    title: 'Type',
                },
                {
                    name: 'protocolGroupName',
                    title: 'Group',
                },
                {
                    name: 'durationDays',
                    title: 'Duration Days',
                },
                {
                    name: 'refills',
                    title: 'Refills',
                },
                {
                    name: 'dispense',
                    title: 'Dispense',
                },
                {
                    name: 'medicationName',
                    title: 'Medication',
                },
                {
                    name: 'routeName',
                    title: 'Route',
                },
                {
                    name: 'doseFormName',
                    title: 'Dose Form',
                },
                {
                    name: null,
                    title: 'Enable',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.enable);
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getCloneAction(), {
                    action: function (data) {
                        return clone(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Protocol',
                            action: function () {
                                return add();
                            }
                        }),
                    ],
                },
            ],
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/protocol/update/protocol-dialog.html',
                    controller: 'ProtocolNewDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        protocol: ['CoreService', function (CoreService) {

                            return {
                                name: null,
                                enable: true,
                                type: null,
                                durationDays: null,
                                refills: null,
                                dispense: null,
                                instructions: null,
                                facility: CoreService.getCurrentFacility(),
                                medication: null,
                                route: null,
                                doseForm: null,
                                relationals: [],
                                protocolFrequencies: [],
                            };
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/protocol/update/protocol-dialog.html',
                    controller: 'ProtocolNewDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        protocol: ['Protocol', function (Protocol) {
                            return Protocol.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function clone(id) {
            return $q(function (resolve) {
                ConfirmationService.open({
                    title: 'Clone Protocol',
                    message: 'This action is will <strong>clone</strong> this Protocol. Are you sure you want to <strong>clone</strong> this Protocol?',
                    actions: [
                        {
                            name: 'Clone',
                            color: 'btn btn-success',
                            icon: 'fa fa-times-circle-o',
                            value: 'SUCCESS'
                        },
                        {
                            name: 'Cancel',
                            color: 'btn btn-danger',
                            icon: 'fa fa-ban',
                            value: 'CANCEL'
                        }
                    ]
                }).result.then(function () {
                    Protocol.clone({id: id}, function (result) {
                        toastr.success("Protocol have been cloned");
                        resolve();
                    }, function () {
                        toastr.error("Error cloning the protocol");
                        resolve();
                    });
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Protocol').result.then(function () {
                    Protocol.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

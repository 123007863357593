(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VerifytxController', VerifytxController);

    VerifytxController.$inject = ['CoreService', '$uibModal', 'Verifytx', 'chart', 'Insurance', 'toastr', '$rootScope',
        '$scope', 'access', 'ACTIONS', 'Auth'];

    function VerifytxController(CoreService, $uibModal, Verifytx, chart, Insurance, toastr, $rootScope,
                                $scope, access, ACTIONS, Auth) {
        var vm = this;

        vm.toggleItem = toggleItem;
        vm.showHistory = showHistory;
        vm.newVerify = newVerify;
        vm.verify = verify;

        init();

        function init() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_VERIFYTX_VIEW, access)) {
                return;
            }

            if (chart.primary) {
                vm.isApplicable = true;
                Verifytx.getByChart({id: chart.id}, function (result) {
                    vm.verifytxVob = result;
                    showInformation(result);
                }, function (error) {
                    vm.verifytxVob = null;
                })
            } else {
                vm.isApplicable = false;
            }
        }

        function toggleItem() {
            CoreService.togglePanel('verificationBenefits');
            setTimeout(function () {
                $window.dispatchEvent(new Event('resize'));
            });
        }

        function showHistory() {
            $uibModal.open({
                templateUrl: 'app/entities/verifytx/chart/history/old/verifytx-history-dialog.html',
                controller: 'VerifytxHistoryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    verifytxVobId: vm.verifytxVob.id
                }
            }).result.then(function () {

            }, function () {
            });
        }

        function newVerify() {
            $uibModal.open({
                templateUrl: 'app/entities/verifytx/create/verifytx-new-dialog.html',
                controller: 'VerifytxNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {chartId: chart.id}
            }).result.then(function (result) {
                if (result.vobs && result.vobs.length > 0) {
                    vm.verifytxVob = result.vobs[0];

                    showInformation(vm.verifytxVob);
                }
            }, function (error) {
                if (error !== null) {
                    toastr.error(error.data.message);
                }
            });
        }

        function verify() {
            vm.isSaving = true;
            Verifytx.verify({
                chartId: chart.id
            }, function (result) {
                vm.isSaving = false;

                if (result.vobs && result.vobs.length > 0) {
                    vm.verifytxVob = result.vobs[0];

                    showInformation(vm.verifytxVob);
                    toastr.success('Verification of Benefits has been done')
                }

                if (result.fails && result.fails.length > 0) {
                    toastr.error(_.join(result.fails, ","));
                }

            }, function (error) {
                vm.isSaving = false;
                toastr.error(error.data.message)
            })
        }

        function showInformation(verifytxVob) {
            vm.chartDataOutDeductibles = [
                {
                    key: verifytxVob.outNetwork.deductibleAmountName,
                    y: verifytxVob.outNetwork.deductibleCalendarYear !== 0.0 ?
                        (verifytxVob.outNetwork.deductibleYearToDate === null ?
                            (verifytxVob.outNetwork.deductibleCalendarYear !== null && verifytxVob.outNetwork.deductibleRemaining !== null ? verifytxVob.outNetwork.deductibleCalendarYear - verifytxVob.outNetwork.deductibleRemaining : 1)
                            : verifytxVob.outNetwork.deductibleYearToDate)
                        : 1
                },
                {
                    key: verifytxVob.outNetwork.deductibleRemainingName,
                    y: verifytxVob.outNetwork.deductibleCalendarYear !== 0.0 ?
                        (verifytxVob.outNetwork.deductibleRemaining === null ?
                            (verifytxVob.outNetwork.deductibleCalendarYear !== null && verifytxVob.outNetwork.deductibleYearToDate !== null ? verifytxVob.outNetwork.deductibleCalendarYear - verifytxVob.outNetwork.deductibleYearToDate : null)
                            : verifytxVob.outNetwork.deductibleRemaining)
                        : 0
                }
            ];

            vm.chartDataOutPocket = [
                {
                    key: verifytxVob.outNetwork.outPocketAmountName,
                    y: verifytxVob.outNetwork.outPocketCalendarYear !== 0.0 ?
                        (verifytxVob.outNetwork.outPocketYearToDate === null ?
                            (verifytxVob.outNetwork.outPocketCalendarYear !== null && verifytxVob.outNetwork.outPocketRemaining !== null ? verifytxVob.outNetwork.outPocketCalendarYear - verifytxVob.outNetwork.outPocketRemaining : 1)
                            : verifytxVob.outNetwork.outPocketYearToDate)
                        : 1
                },
                {
                    key: verifytxVob.outNetwork.outPocketRemainingName,
                    y: verifytxVob.outNetwork.outPocketCalendarYear !== 0.0 ?
                        (verifytxVob.outNetwork.outPocketRemaining === null ?
                            (verifytxVob.outNetwork.outPocketCalendarYear !== null && verifytxVob.outNetwork.outPocketYearToDate !== null ? verifytxVob.outNetwork.outPocketCalendarYear - verifytxVob.outNetwork.outPocketYearToDate : null)
                            : verifytxVob.outNetwork.outPocketRemaining)
                        : 0
                }
            ];

            vm.chartDataInDeductibles = [
                {
                    key: verifytxVob.inNetwork.deductibleAmountName,
                    y: verifytxVob.inNetwork.deductibleCalendarYear !== 0.0 ?
                        (verifytxVob.inNetwork.deductibleYearToDate === null ?
                            (verifytxVob.inNetwork.deductibleCalendarYear !== null && verifytxVob.inNetwork.deductibleRemaining !== null ? verifytxVob.inNetwork.deductibleCalendarYear - verifytxVob.inNetwork.deductibleRemaining : 1)
                            : verifytxVob.inNetwork.deductibleYearToDate)
                        : 1
                },
                {
                    key: verifytxVob.inNetwork.deductibleRemainingName,
                    y: verifytxVob.inNetwork.deductibleCalendarYear !== 0.0 ?
                        (verifytxVob.inNetwork.deductibleRemaining === null ?
                            (verifytxVob.inNetwork.deductibleCalendarYear !== null && verifytxVob.inNetwork.deductibleYearToDate !== null ? verifytxVob.inNetwork.deductibleCalendarYear - verifytxVob.inNetwork.deductibleYearToDate : null)
                            : verifytxVob.inNetwork.deductibleRemaining)
                        : 0
                }
            ];

            vm.chartDataInPocket = [
                {
                    key: verifytxVob.inNetwork.outPocketAmountName,
                    y: verifytxVob.inNetwork.outPocketCalendarYear !== 0.0 ?
                        (verifytxVob.inNetwork.outPocketYearToDate === null ?
                            (verifytxVob.inNetwork.outPocketCalendarYear !== null && verifytxVob.inNetwork.outPocketRemaining !== null ? verifytxVob.inNetwork.outPocketCalendarYear - verifytxVob.inNetwork.outPocketRemaining : 1)
                            : verifytxVob.inNetwork.outPocketYearToDate)
                        : 1
                },
                {
                    key: verifytxVob.inNetwork.outPocketRemainingName,
                    y: verifytxVob.inNetwork.outPocketCalendarYear !== 0.0 ?
                        (verifytxVob.inNetwork.outPocketRemaining === null ?
                            (verifytxVob.inNetwork.outPocketCalendarYear !== null && verifytxVob.inNetwork.outPocketYearToDate !== null ? verifytxVob.inNetwork.outPocketCalendarYear - verifytxVob.inNetwork.outPocketYearToDate : null)
                            : verifytxVob.inNetwork.outPocketRemaining)
                        : 0
                }
            ];
        }

        vm.chartOptsOutDeductibles = {
            chart: {
                type: 'pieChart',
                height: 200,
                x: function (d) {
                    return d.key;
                },
                y: function (d) {
                    return d.y;
                },
                showLabels: false,
                duration: 5,
                legend: {
                    margin: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5
                    },
                    rightAlign: false
                },
                legendPosition: 'right',
                tooltip: {
                    enabled: false
                },
                color: ['#c7d8ef', '#4798ca']
            }
        };

        vm.chartOptsOutPocket = {
            chart: {
                type: 'pieChart',
                height: 200,
                x: function (d) {
                    return d.key;
                },
                y: function (d) {
                    return d.y;
                },
                showLabels: false,
                duration: 5,
                legend: {
                    margin: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5
                    },
                    rightAlign: false
                },
                legendPosition: 'right',
                tooltip: {
                    enabled: false
                },
                color: ['#c7d8ef', '#4798ca']
            }
        };

        vm.chartOptsInDeductibles = {
            chart: {
                type: 'pieChart',
                height: 200,
                x: function (d) {
                    return d.key;
                },
                y: function (d) {
                    return d.y;
                },
                showLabels: false,
                duration: 5,
                legend: {
                    margin: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5
                    },
                    rightAlign: false
                },
                legendPosition: 'right',
                tooltip: {
                    enabled: false
                },
                color: ['#c7d8ef', '#4798ca']
            }
        };

        vm.chartOptsInPocket = {
            chart: {
                type: 'pieChart',
                height: 200,
                x: function (d) {
                    return d.key;
                },
                y: function (d) {
                    return d.y;
                },
                showLabels: false,
                duration: 5,
                legend: {
                    margin: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5
                    },
                    rightAlign: false
                },
                legendPosition: 'right',
                tooltip: {
                    enabled: false
                },
                color: ['#c7d8ef', '#4798ca']
            }
        };

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailVerify', function (event, result) {
            if (vm.isApplicable) {
                vm.verifytxVob ? verify() : newVerify();
            } else {
                toastr.error("The patient is not applicable");
            }
        });

        $scope.$on('$destroy', unsubscribe);

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailVerifyTxHistory', function (event, result) {

            if (vm.verifytxVob) {
                $uibModal.open({
                    templateUrl: 'app/entities/verifytx/chart/history/new/verifytx-history-dialog.html',
                    controller: 'VerifytxHistoryNewDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles modal-bottom',
                    resolve: {
                        verifytxVobId: vm.verifytxVob.id
                    }
                }).result.then(function () {

                }, function () {
                });
            } else {
                toastr.error("The patient does not have verifications");
            }
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

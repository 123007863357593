(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ContactsDialogController', ContactsDialogController);

    ContactsDialogController.$inject = ['$timeout', '$scope', 'TypePatientContactTypes', '$uibModalInstance',
        'Contacts', 'entity', 'TypePatientContactsRelationship', 'CountryState', 'CoreService'];

    function ContactsDialogController($timeout, $scope, TypePatientContactTypes, $uibModalInstance,
                                      Contacts, entity, TypePatientContactsRelationship, CountryState, CoreService) {
        var vm = this;

        vm.entity = entity;
        vm.chart = entity.chart;
        vm.typeContacts = TypePatientContactTypes.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.typeRelationships = TypePatientContactsRelationship.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.form = {};
        vm.states = CountryState.query();
        vm.showDelete = false;

        vm.clear = clear;
        vm.save = save;
        vm.confirmDelete = confirmDelete;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.entity.id !== null) {
                Contacts.update(vm.entity, onSaveSuccess, onSaveError);
            } else {
                Contacts.save(vm.entity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:contactsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function confirmDelete () {
            Contacts.delete({id: vm.entity.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('dashboard', {
                parent: 'app',
                url: '/dashboard',
                ncyBreadcrumb: {
                    label: 'Dashboard',
                    parent: 'app'
                },
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/dashboard2/dashboard.html',
                        controller: 'Dashboard2Controller',
                        controllerAs: 'vm'
                    },
                    'pending-by-staff@dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-by-staff/pending-by-staff-box.html',
                        controller: 'PendingByStaffBoxController',
                        controllerAs: 'vm'
                    },
                    'concurrent-reviews@dashboard': {
                        templateUrl: 'app/dashboard2/layout/concurrent-reviews/concurrent-reviews-box.html',
                        controller: 'ConcurrentReviewsBoxController',
                        controllerAs: 'vm'
                    },
                    'pending-by-staff-review@dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-by-staff-review/pending-by-staff-review-box.html',
                        controller: 'PendingByStaffReviewBoxController',
                        controllerAs: 'vm'
                    },
                    'unassigned-results@dashboard': {
                        templateUrl: 'app/dashboard2/layout/unassigned-results/unassigned-results-top-box.html',
                        controller: 'UnassignedResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'partial-result@dashboard': {
                        templateUrl: 'app/dashboard2/layout/partial-results/partial-results-top-box.html',
                        controller: 'PartialResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'abnormal-result@dashboard': {
                        templateUrl: 'app/dashboard2/layout/abnormal-result/abnormal-results-top-box.html',
                        controller: 'AbnormalResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'pending-requisition@dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-requisition/pending-requisition-top-box.html',
                        controller: 'PendingRequisitionsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'critical-results@dashboard': {
                        templateUrl: 'app/dashboard2/layout/critical-results/box/critical-results-top-box.html',
                        controller: 'CriticalResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'late-care-delivery@dashboard': {
                        templateUrl: 'app/dashboard2/layout/late-care-delivery/late-care-delivery-box.html',
                        controller: 'LateCareDeliveryBoxController',
                        controllerAs: 'vm'
                    },
                    'stay@dashboard': {
                        templateUrl: 'app/dashboard2/layout/stay/stay-box.html',
                        controller: 'StayBoxController',
                        controllerAs: 'vm'
                    },
                    'pending-by-patients@dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-by-patient/pending-by-patient-box.html',
                        controller: 'PendingByPatientBoxController',
                        controllerAs: 'vm'
                    },
                    'empty-care-delivery-patient@dashboard': {
                        templateUrl: 'app/dashboard2/layout/empty-care-delivery/empty-care-delivery-box.html',
                        controller: 'EmptyCareDeliveryBoxController',
                        controllerAs: 'vm'
                    },
                    'physician-review@dashboard': {
                        templateUrl: 'app/dashboard2/layout/physician-review/physician-review-box.html',
                        controller: 'PhysicianReviewBoxController',
                        controllerAs: 'vm'
                    },
                    'chart-review@dashboard': {
                        templateUrl: 'app/dashboard2/layout/chart-review/chart-review-box.html',
                        controller: 'ChartReviewBoxController',
                        controllerAs: 'vm'
                    },
                    'payer-mix@dashboard': {
                        templateUrl: 'app/dashboard2/layout/payer-mix/payer-mix-box.html',
                        controller: 'PayerMixBoxController',
                        controllerAs: 'vm'
                    },
                    'discharge-type@dashboard': {
                        templateUrl: 'app/dashboard2/layout/discharge-type/discharge-type-box.html',
                        controller: 'DischargeTypeBoxController',
                        controllerAs: 'vm'
                    },
                    'incident-report@dashboard': {
                        templateUrl: 'app/dashboard2/layout/incident-report/incident-report-box.html',
                        controller: 'IncidentReportBoxController',
                        controllerAs: 'vm'
                    },
                    'level-care@dashboard': {
                        templateUrl: 'app/dashboard2/layout/level-care/level-care-box.html',
                        controller: 'LevelCareBoxController',
                        controllerAs: 'vm'
                    },
                    'marketers@dashboard': {
                        templateUrl: 'app/dashboard2/layout/marketers/marketers-box.html',
                        controller: 'MarketersBoxController',
                        controllerAs: 'vm'
                    },
                    'referral-source@dashboard': {
                        templateUrl: 'app/dashboard2/layout/referral-source/referral-source-box.html',
                        controller: 'ReferralSourceBoxController',
                        controllerAs: 'vm'
                    },
                    'case-load@dashboard': {
                        templateUrl: 'app/dashboard2/layout/case-load/case-load-box.html',
                        controller: 'CaseLoadBoxController',
                        controllerAs: 'vm'
                    },
                    'beds@dashboard': {
                        templateUrl: 'app/dashboard2/layout/bed/bed-box.html',
                        controller: 'BedBoxController',
                        controllerAs: 'vm'
                    },
                    'incoming-patients@dashboard': {
                        templateUrl: 'app/dashboard2/layout/incoming-patients/incoming-patients-box.html',
                        controller: 'IncomingPatientsBoxController',
                        controllerAs: 'vm'
                    },
                    'discharging-patients@dashboard': {
                        templateUrl: 'app/dashboard2/layout/discharging-patients/discharging-patinets-box.html',
                        controller: 'DischargingPatientsBoxController',
                        controllerAs: 'vm'
                    },
                    // 'mars-mors@dashboard': {
                    //     templateUrl: 'app/dashboard2/layout/mars-mors-box.html',
                    //     controller: 'MarsMorsBoxController',
                    //     controllerAs: 'vm'
                    // },
                    'print-requisition@dashboard': {
                        templateUrl: 'app/dashboard2/layout/print-requisition/print-requisition-box.html',
                        controller: 'PrintRequisitionBoxController',
                        controllerAs: 'vm'
                    }
                },
                onEnter: ['Auth', 'ACTIONS', 'access', '$state', function (Auth, ACTIONS, access, $state) {
                    if (!Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_DASHBOARD, access)) {
                        $state.go("dashboard-empty");
                    }
                }],
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_DASHBOARD];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    openModalCritical: ['CoreService', function (CoreService) {
                        return CoreService.getStorage('openModalCritical');
                    }],
                    filter: ['CoreService', function (CoreService) {
                        return {
                            facilityId: CoreService.getCurrentFacility().id,
                            date: null,
                            itemStatus: null,
                            firstName: null,
                            lastName: null,
                            dob: null,
                            barcode: null,
                            laboratory: null,
                            start: null,
                            end: null
                        };
                    }]
                }
            })
            .state('bht-dashboard', {
                parent: 'app',
                url: '/bht-dashboard',
                ncyBreadcrumb: {
                    label: 'Dashboard',
                    parent: 'app'
                },
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/dashboard2/bht/bht-dashboard.html',
                        controller: 'BHTDashboardController',
                        controllerAs: 'vm'
                    },
                    'pending-by-staff@bht-dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-by-staff/pending-by-staff-box.html',
                        controller: 'PendingByStaffBoxController',
                        controllerAs: 'vm'
                    },
                    'pending-by-staff-review@bht-dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-by-staff-review/pending-by-staff-review-box.html',
                        controller: 'PendingByStaffReviewBoxController',
                        controllerAs: 'vm'
                    },
                    'pending-by-patients@bht-dashboard': {
                        templateUrl: 'app/dashboard2/layout/pending-by-patient/pending-by-patient-box.html',
                        controller: 'PendingByPatientBoxController',
                        controllerAs: 'vm'
                    },
                    'empty-care-delivery-patient@bht-dashboard': {
                        templateUrl: 'app/dashboard2/layout/empty-care-delivery/empty-care-delivery-box.html',
                        controller: 'EmptyCareDeliveryBoxController',
                        controllerAs: 'vm'
                    },
                    'print-requisition@bht-dashboard': {
                        templateUrl: 'app/dashboard2/layout/print-requisition/print-requisition-box.html',
                        controller: 'PrintRequisitionBoxController',
                        controllerAs: 'vm'
                    }
                },
                onEnter: ['Auth', 'ACTIONS', 'access', '$state', function (Auth, ACTIONS, access, $state) {
                    if (!Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_DASHBOARD, access)) {
                        $state.go("dashboard-empty");
                    }
                }],
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_DASHBOARD];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    openModalCritical: ['CoreService', function (CoreService) {
                        return CoreService.getStorage('openModalCritical');
                    }],
                    filter: ['CoreService', function (CoreService) {
                        return {
                            facilityId: CoreService.getCurrentFacility().id,
                            date: null,
                            itemStatus: null,
                            firstName: null,
                            lastName: null,
                            dob: null,
                            barcode: null,
                            laboratory: null,
                            start: null,
                            end: null
                        };
                    }]
                }
            })
            .state('dashboard-empty', {
                parent: 'app',
                url: '/dashboard-empty',
                ncyBreadcrumb: {
                    label: 'Dashboard',
                    parent: 'app'
                },
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/dashboard2/empty/dashboard-empty.html',
                        controller: function () {

                        },
                        controllerAs: 'vm'
                    },
                },
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillProviderDialogController', SuperBillProviderDialogController);

    SuperBillProviderDialogController.$inject = ['$uibModalInstance', 'CoreService', 'Employee', 'entity', 'DateUtils',
        'Facility'];

    function SuperBillProviderDialogController($uibModalInstance, CoreService, Employee, entity, DateUtils,
                                               Facility) {
        var vm = this;

        vm.superBill = entity;
        vm.employees = Employee.byActive({id: CoreService.getCorporation().id});

        vm.clear = clear;
        vm.renderingProviderChange = renderingProviderChange;
        vm.billingProviderChange = billingProviderChange;
        vm.openCalendar = openCalendar;

        if (typeof vm.superBill.startDate === 'string') {
            vm.superBill.startDate = DateUtils.convertLocalDateFromServer(vm.superBill.startDate);
            vm.superBill.endDate = DateUtils.convertLocalDateFromServer(vm.superBill.endDate);
        }

        vm.endDateOptions = {};
        vm.startDateOptions = {};

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        Facility.getAllPlaceOfServices(function (result) {
            vm.placeOfService = result;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function renderingProviderChange(employee) {
            vm.superBill.providerNpi = employee.npiNumber;
        }

        function billingProviderChange(employee) {
            vm.superBill.billingProviderNpi = employee.npiNumber;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MessageHistoryController', MessageHistoryController);

    MessageHistoryController.$inject = ['$scope', '$state', 'MessageHistory', 'CoreService', 'DTOptionsBuilder',
        'DTColumnBuilder', '$uibModal', '$q', '$compile', 'Employee', 'filter',
        '$rootScope', 'DateUtils', 'GenericEntityDatatableService'];

    function MessageHistoryController($scope, $state, MessageHistory, CoreService, DTOptionsBuilder,
                                      DTColumnBuilder, $uibModal, $q, $compile, Employee, filter,
                                      $rootScope, DateUtils, GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
            abnormalRange: null
        }

        vm.descriptor = {
            title: 'Message History',
            entityClassHumanized: 'Message History',
            entityStateName: 'message-history',
            service: MessageHistory,
            serviceMethod: 'filter',
            params: params,
            pagination: 'remote',
            newAction: false,
            selectionColumn: 'id',
            columns: [
                {
                    title: 'Employee',
                    render: function(data) {
                        return data.employee.lastName + ', ' + data.employee.firstName;
                    }
                },
                {
                    title: 'Facility Phone Number',
                    render: function(data) {
                        return data.facilityPhoneNumber.phoneNumber;
                    }
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.createdDate).format($rootScope.amDateFormat);
                    }
                },
                {

                    title: 'Failed',
                    render: function (data) {
                        return data.failed ? data.failed : '0';
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        detailMessagesHistory(data.id);
                    },
                }),
            ],
            filters: [
                {
                    name: 'employeeId',
                    read: function (filter) {
                        return filter.employeeId ? filter.employeeId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        Employee.findAllByFilter(
                            {
                                page: 0,
                                size: 10,
                                facilityId: CoreService.getCurrentFacility().id,
                                query: search,
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select Employee...',
                    type: 'select',
                    visible: true,
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
                },
                {
                    name: 'phoneNumber',
                    placeholder: 'Facility Phone Number...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'date',
                    placeholder: 'Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.startDate ? moment(filter.startDate).format("YYYY-MM-DD") : null;
                    },
                }
            ]
        }

        function detailMessagesHistory(id) {
            $uibModal.open({
                templateUrl: 'app/entities/message-history/detail/message-history-detail.html',
                controller: 'MessageHistoryDetailController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['MessageHistory', function (MessageHistory) {
                        return MessageHistory.get({id: id}).$promise;
                    }]
                }
            });
        }
    }
})();


(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InstClaimConfDeleteController',InstClaimConfDeleteController);

    InstClaimConfDeleteController.$inject = ['$uibModalInstance', 'entity', 'InstClaimConf'];

    function InstClaimConfDeleteController($uibModalInstance, entity, InstClaimConf) {
        var vm = this;

        vm.instClaimConf = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            InstClaimConf.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConcurrentReviewsDialogController', ConcurrentReviewsDialogController);

    ConcurrentReviewsDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'ConcurrentReviewFrequency', 'GUIUtils',
        'ConcurrentReviews', 'chart', 'TypeLevelCare', 'Insurance', 'CoreService'];

    function ConcurrentReviewsDialogController ($timeout, $scope, $uibModalInstance, entity, ConcurrentReviewFrequency, GUIUtils,
                                                ConcurrentReviews, chart, TypeLevelCare, Insurance, CoreService) {
        var vm = this;

        vm.concurrentReviews = entity;
        vm.chart = chart;
        vm.datePickerOpenStatus = {};
        vm.showDelete = false;
        vm.startOptions = {
        };

        vm.endOptions = {
            minDate: vm.concurrentReviews.startDate
        };
        vm.typeLevelCares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));

        Insurance.get({id: vm.chart.primary.id}, function (primary) {
            vm.concurrentReviews.insurance = {id: primary.id, insuranceCarrier: primary.insuranceCarrier};
        })

        vm.frequencies = ConcurrentReviewFrequency.findAllByCorporation(CoreService.getFilterByCurrentCorporation());

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.updateDays = updateDays;
        vm.changeStartDate = changeStartDate;
        vm.changeEndDate = changeEndDate;
        vm.confirmDelete = confirmDelete;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if(vm.concurrentReviews.insurance) {
                vm.concurrentReviews.insurance = {
                    id: vm.concurrentReviews.insurance.id,
                    insuranceCarrier: vm.concurrentReviews.insurance.insuranceCarrier
                };
            }
            if(vm.concurrentReviews.id !== null) {
                ConcurrentReviews.update(vm.concurrentReviews, onSaveSuccess, onSaveError);
            } else {
                ConcurrentReviews.save(vm.concurrentReviews, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:concurrentReviewsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.authorizationDate = false;
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.nextReviewDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function updateDays() {
            if (!vm.concurrentReviews.startDate || !vm.concurrentReviews.endDate ||
                !vm.concurrentReviews.concurrentReviewFrequency || !vm.concurrentReviews.concurrentReviewFrequency.days) {
                return;
            }

            var stepDay = angular.copy(vm.concurrentReviews.startDate);
            var c = 0;
            var frequency = GUIUtils.weekStringJavaToJS(vm.concurrentReviews.concurrentReviewFrequency.days);

            while (stepDay <= vm.concurrentReviews.endDate) {
                if (frequency[stepDay.getDay()] == '1') {
                    c++;
                }

                stepDay.setDate(stepDay.getDate() + 1);
            }

            vm.concurrentReviews.numberDates = c;
        }

        function changeStartDate() {
            vm.endOptions.minDate = vm.concurrentReviews.startDate;
            if (vm.concurrentReviews.startDate > vm.concurrentReviews.endDate) {
                vm.concurrentReviews.endDate = null;
            }

            updateDays();
        }

        function changeEndDate() {
            vm.startOptions.maxDate = vm.concurrentReviews.endDate;
            if (vm.concurrentReviews.startDate > vm.concurrentReviews.endDate) {
                vm.concurrentReviews.startDate = null;
            }

            updateDays();
        }

        function confirmDelete () {
            ConcurrentReviews.delete({id: vm.concurrentReviews.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabRequisitionDialogController', LabRequisitionDialogController);

    LabRequisitionDialogController.$inject = ['$uibModalInstance', 'DTColumnBuilder', 'DTOptionsBuilder',
        'PatientOrder', '$q', 'CoreService', '$compile', '$scope', 'GUIUtils', 'date',
        'DataTablesService'];

    function LabRequisitionDialogController($uibModalInstance, DTColumnBuilder, DTOptionsBuilder,
                                            PatientOrder, $q, CoreService, $compile, $scope, GUIUtils, date,
                                            DataTablesService) {
        var vm = this;
        vm.date = date;
        vm.orders = [];
        vm.ordersAsNeeded = [];
        vm.isSaving = false;
        vm.facility = CoreService.getCurrentFacility();
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.clear = clear;
        vm.create = create;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;

        vm.dtInstance = {};
        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            PatientOrder.getOrderAsNeeded({facilityId: vm.facility.id, date: vm.date}, function (result) {
                vm.ordersAsNeeded = result;
                defer.resolve(result);

                vm.selected = {};
                vm.selectAll = false;
            });
            return defer.promise;
        }).withBootstrap().withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            }).withPaginationType('full_numbers').withBootstrap().withDOM('ltp').withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            }).withDisplayLength(10).withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]]);

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn('id').withTitle('Order #'),
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').renderWith(function (data, type, full) {
                return data.chart.lastName + ', '  + data.chart.firstName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.startDate).format('MM/DD/YYYY');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Signed').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.signed);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Order Type').renderWith(function (data, type, full) {
                return data.orderType;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(function (data, type, full) {
                return data.orderStatus;
            })
        ];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function create() {
            vm.isSaving = true;
            var ids = vm.getSelectedIds(vm.selected);

            if (ids.length > 0) {
                PatientOrder.createRequisitionWithOrderAsNeeded({ids: ids, date: vm.date}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InsuranceController', InsuranceController);

    InsuranceController.$inject = ['chart', 'Insurance', 'CoreService', '$uibModal', '$state',
        'InsurancePicture', 'InsuranceBackPicture', 'PopupService', 'access', 'ACTIONS', 'Auth'];

    function InsuranceController(chart, Insurance, CoreService, $uibModal, $state,
                                 InsurancePicture, InsuranceBackPicture, PopupService, access, ACTIONS, Auth) {
        var vm = this;

        vm.chart = chart;
        vm.genericCardImageBack = CoreService.insuranceBackPictureDefault();

        vm.primary = null;
        vm.secondary = null;

        vm.add = add;
        vm.edit = edit;
        vm.toggleItem = toggleItem;
        vm.editFrontImageInsurance = editFrontImageInsurance;
        vm.editBackImageInsurance = editBackImageInsurance;

        function toggleItem() {
            CoreService.togglePanel('insurance');
        }

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_INSURANCE_VIEW, access)) {
                return;
            }

            vm.primary = chart.primary ? Insurance.get({id: chart.primary.id}) : null;
            vm.secondary = chart.secondary ? Insurance.get({id: chart.secondary.id}) : null;
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/insurance/update/insurance-dialog.html',
                controller: 'InsuranceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return {
                            address: null,
                            address2: null,
                            zipCode: null,
                            city: null,
                            policyNumber: null,
                            groupNumber: null,
                            groupName: null,
                            planNumber: null,
                            lastName: null,
                            firstName: null,
                            middleInitial: null,
                            dob: null,
                            gender: null,
                            phone: null,
                            employer: null,
                            insuranceOrder: 1,
                            id: null,
                            chart: chart
                        };
                    },
                    chart: chart
                }
            }).result.then(function () {
                CoreService.showPanel('cReviews');
                $state.reload();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/insurance/update/insurance-dialog.html',
                controller: 'InsuranceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'xl',
                resolve: {
                    entity: ['Insurance', function (Insurance) {
                        return Insurance.get({id: id}).$promise;
                    }],
                    chart: chart
                }
            }).result.then(function () {
                $state.reload();
            }, function () {
            });
        }

        function editFrontImageInsurance(id) {
            InsurancePicture.getOriginalBase64ByInsurance({id: id}, function (result) {
                openCropFrontImageInsurancePopup(result, id);
            }, function () {
                openCropFrontImageInsurancePopup(null, id);
            });
        }

        function editBackImageInsurance(id) {
            InsuranceBackPicture.getOriginalBase64ByInsurance({id: id}, function (result) {
                openCropBackImageInsurancePopup(result, id);
            }, function () {
                openCropBackImageInsurancePopup(null, id);
            });
        }

        function openCropFrontImageInsurancePopup(result, id) {
            PopupService.showCropImageFrontInsurancePopup(result).result.then(function (error) {
            }, function (data) {
                if (data) {
                    InsurancePicture.upload({
                        insuranceId: id,
                        cropped: data.cropped,
                        image: data.image
                    }, function () {
                        $state.reload();
                    });
                }
            });
        }

        function openCropBackImageInsurancePopup(result, id) {
            PopupService.showCropImageBackInsurancePopup(result).result.then(function (error) {
            }, function (data) {
                if (data) {
                    InsuranceBackPicture.upload({
                        insuranceId: id,
                        cropped: data.cropped,
                        image: data.image
                    }, function () {
                        $state.reload();
                    });
                }
            });
        }
    }
})();

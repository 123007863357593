(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssuesDialogControllerNew', UrgentIssuesDialogControllerNew);

    UrgentIssuesDialogControllerNew.$inject = [ '$scope', '$uibModalInstance', 'UrgentIssues', 'CoreService', 'entity' ];

    function UrgentIssuesDialogControllerNew ( $scope, $uibModalInstance, UrgentIssues, CoreService,entity) {
        var vm = this;
        vm.urgentIssues = entity;

        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.urgentIssues.id !== null) {
                UrgentIssues.update(vm.urgentIssues, onSaveSuccess, onSaveError);
            } else {
                UrgentIssues.save(vm.urgentIssues, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:careCoordinationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

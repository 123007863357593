(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ChartBlocked', ChartBlocked);

    ChartBlocked.$inject = ['$resource'];

    function ChartBlocked ($resource) {
        var resourceUrl =  'api/chart-blockeds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByFilter': {url: 'api/chart-blockeds/filter', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.forEach(function (item) {
                            item.fullName = item.firstName + ' ' + item.lastName;
                        })
                    }
                    return data;
                }
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SessionTypeController', SessionTypeController);

    SessionTypeController.$inject = ['GenericEntityDatatableService', 'CoreService', 'SessionType', '$q', '$uibModal',
        'ConfirmationService'];

    function SessionTypeController (GenericEntityDatatableService, CoreService, SessionType, $q, $uibModal,
                                    ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Session Type',
            newButtonTitle: 'New Session Type',
            entityClassHumanized: 'Session Type',
            entityStateName: 'session-type',
            service: SessionType,
            serviceMethod: 'byCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Session Type',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/session-type/update/session-type-dialog.html',
                controller: 'SessionTypeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/session-type/update/session-type-dialog.html',
                controller: 'SessionTypeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['SessionType', function (SessionType) {
                        return SessionType.get({id: id}).$promise;
                    }]
                }
            }).result
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Session Type').result.then(function () {
                    SessionType.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('bed', {
                parent: 'entity',
                url: '/bed',
                data: {
                    authorities: [],
                    pageTitle: 'Beds'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bed/list/bed.html',
                        controller: 'BedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_BED];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_BED, access);
                }]
            })
            .state('bed-sheet', {
                parent: 'entity',
                url: '/bed-sheet',
                data: {
                    authorities: [],
                    pageTitle: 'Bed Sheet'
                },
                ncyBreadcrumb: {
                    label: 'Bed Sheet'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bed/bed-sheet/bed-sheet.html',
                        controller: 'BedSheetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filter: ['CoreService', function (CoreService) {
                        return CoreService.readBedSheetFromLocalStore();
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_BED_SHEET];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_BED_SHEET, access);
                }]
            })
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('procedure-modifier', {
            parent: 'entity',
            url: '/procedure-modifier',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ProcedureModifiers'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/procedure-modifier/procedure-modifiers.html',
                    controller: 'ProcedureModifierController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('procedure-modifier-detail', {
            parent: 'entity',
            url: '/procedure-modifier/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ProcedureModifier'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/procedure-modifier/procedure-modifier-detail.html',
                    controller: 'ProcedureModifierDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ProcedureModifier', function($stateParams, ProcedureModifier) {
                    return ProcedureModifier.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'procedure-modifier',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('procedure-modifier-detail.edit', {
            parent: 'procedure-modifier-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/procedure-modifier/procedure-modifier-dialog.html',
                    controller: 'ProcedureModifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProcedureModifier', function(ProcedureModifier) {
                            return ProcedureModifier.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('procedure-modifier.new', {
            parent: 'procedure-modifier',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/procedure-modifier/procedure-modifier-dialog.html',
                    controller: 'ProcedureModifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('procedure-modifier', null, { reload: 'procedure-modifier' });
                }, function() {
                    $state.go('procedure-modifier');
                });
            }]
        })
        .state('procedure-modifier.edit', {
            parent: 'procedure-modifier',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/procedure-modifier/procedure-modifier-dialog.html',
                    controller: 'ProcedureModifierDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProcedureModifier', function(ProcedureModifier) {
                            return ProcedureModifier.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('procedure-modifier', null, { reload: 'procedure-modifier' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('procedure-modifier.delete', {
            parent: 'procedure-modifier',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/procedure-modifier/procedure-modifier-delete-dialog.html',
                    controller: 'ProcedureModifierDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ProcedureModifier', function(ProcedureModifier) {
                            return ProcedureModifier.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('procedure-modifier', null, { reload: 'procedure-modifier' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

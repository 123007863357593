(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncidentSeverityController', IncidentSeverityController);

    IncidentSeverityController.$inject = ['GenericEntityDatatableService', 'CoreService', 'IncidentSeverity',
        '$q', '$uibModal', 'ConfirmationService'];

    function IncidentSeverityController(GenericEntityDatatableService, CoreService, IncidentSeverity,
                                        $q, $uibModal, ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Incident Severity',
            newButtonTitle: 'New Incident Severity',
            entityClassHumanized: 'Incident Severity',
            entityStateName: 'incident-severity',
            service: IncidentSeverity,
            serviceMethod: 'byCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Incident Severity',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/incident-severity/update/incident-severity-dialog.html',
                controller: 'IncidentSeverityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/insurance-type/insurance-type-dialog.html',
                controller: 'InsuranceTypeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['InsuranceType', function (InsuranceType) {
                        return InsuranceType.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Incident Severity').result.then(function () {
                    IncidentSeverity.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

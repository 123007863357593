(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InstClaimConfController', InstClaimConfController);

    InstClaimConfController.$inject = ['GenericEntityDatatableService', 'CoreService', 'InstClaimConf', 'GUIUtils'];

    function InstClaimConfController (GenericEntityDatatableService, CoreService, InstClaimConf, GUIUtils) {
        var vm = this;

        var params = { facilityId: CoreService.getCurrentFacility().id };

        vm.descriptor = {
            title: 'Inst Claim Conf',
            newButtonTitle: 'New Inst Claim Conf',
            entityClassHumanized: 'Inst Claim Conf',
            entityStateName: 'inst-claim-conf',
            service: InstClaimConf,
            serviceMethod: 'filter',
            params: params,
            pagination: 'remote',
            columns: [
                {
                    name: null,
                    title: 'Insurances',
                    render: function (data, type, full) {
                        var row = "";

                        _.forEach(full.insuranceCarriers, function(carrier) {
                            row += row.length === 0 ? carrier.name : ", " + carrier.name;
                        });

                        return row;
                    }
                },
                {
                    name: null,
                    title: 'Level Of Care',
                    render: function (data, type, full) {
                        var row = "";

                        _.forEach(full.levelOfCares, function(level) {
                            row += row.length === 0 ? level.name : ", " + level.name;
                        });

                        return row;
                    }
                },
                {
                    name: 'minTime',
                    title: 'Min Time',
                },
                {
                    name: 'maxTime',
                    title: 'Max Time',
                },
                {
                    name: null,
                    title: 'Default',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.isDefault);
                    }
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction('inst-claim-conf'),
                GenericEntityDatatableService.getDeleteAction('inst-claim-conf')
            ],
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SignatureChangeDialogController', SignatureChangeDialogController);

    SignatureChangeDialogController.$inject = ['$uibModalInstance', 'Employee'];

    function SignatureChangeDialogController($uibModalInstance, Employee) {
        var vm = this;

        vm.signature = "";

        vm.clear = clear;
        vm.replaceSignature = replaceSignature;

        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        function replaceSignature () {
            vm.isSaving = true;

            Employee.replaceSignature({signature: vm.signature}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationDiscontinueWizardController', PatientMedicationDiscontinueWizardController);

    PatientMedicationDiscontinueWizardController.$inject = ['$uibModalInstance', 'CoreService', 'Employee', 'Via',
        'BusinessCredential', 'BUSINESS_ROLES', 'itHasCredentials'];

    function PatientMedicationDiscontinueWizardController($uibModalInstance, CoreService, Employee, Via,
                                                          BusinessCredential, BUSINESS_ROLES, itHasCredentials) {
        var vm = this;

        vm.pin = "";
        vm.agree = false;
        vm.signature = "";
        vm.vias = Via.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.itHasCredentials = itHasCredentials.value;
        vm.discontinueVia = null;
        vm.discontinueCause = null;
        vm.discontinueByPhysician = null;
        vm.facility = CoreService.getCurrentFacility();
        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.odsSignatureNameId = Math.floor((Math.random() * 100) + 1);
        vm.employee = CoreService.getCurrentEmployee();

        vm.clear = clear;
        vm.discontinue = discontinue;
        vm.getEmployees = getEmployees;

        credentialsPermits();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function discontinue() {
            vm.isSaving = true;

            $uibModalInstance.close({
                employeeSignature: vm.signature,
                employeeSignaturePin: vm.pin,
                discontinueVia: vm.discontinueVia,
                discontinueCause: vm.discontinueCause,
                discontinueByPhysician: vm.discontinueByPhysician
            });
        }

        function credentialsPermits() {
            if (vm.itHasCredentials) {
                vm.employees = [vm.employee];
                vm.discontinueByPhysician = vm.employee;
            } else {
                vm.getEmployees();
            }
        }

        function getEmployees(query) {
            if (vm.itHasCredentials) {
                return;
            }

            var filter = {
                page: 0,
                size: 200000,
                query: query,
                facilityId: CoreService.getCurrentFacility().id,
                businessRole: BUSINESS_ROLES.PHYSICIAN
            };

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

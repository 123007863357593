(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationWeightDialogController', EvaluationWeightDialogController);

    EvaluationWeightDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EvaluationWeight', 'Evaluation', 'Weight'];

    function EvaluationWeightDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, EvaluationWeight, Evaluation, Weight) {
        var vm = this;

        vm.evaluationWeight = entity;
        vm.weight = vm.evaluationWeight.weight;

        vm.height = {feet: null, inches: null};
        vm.datePickerOpenStatus = {};

        if (vm.weight.id && !isNaN(vm.weight.height)) {
            vm.height = {feet: Math.floor(vm.weight.height / 12), inches: vm.weight.height % 12};
        }

        vm.openCalendar = openCalendar;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            vm.weight.height = vm.height.feet * 12 + vm.height.inches;

            vm.weight.bmi = (vm.weight.weight / (vm.weight.height * vm.weight.height)) * 703;

            vm.evaluationWeight.weight = vm.weight;

            if (vm.evaluationWeight.id !== null) {
                EvaluationWeight.update(vm.evaluationWeight, onSaveSuccess, onSaveError);
            } else {
                EvaluationWeight.save(vm.evaluationWeight, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:evaluationWeightUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.time = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationEditController', EvaluationEditController);

    EvaluationEditController.$inject = ['entity', 'jsonTemplate', 'Evaluation', 'Chart', '$timeout', '$scope', 'toastr', 'tokens',
        'PopupService', 'Contacts', '$uibModal', 'CoreService', '$rootScope', 'Assistant',
        'PendingPdfChartReport', '$state', 'params', '$window', 'REPORT_TYPE', 'TAB', 'access', 'ACTIONS',
        '$interval', 'ConfirmationService', 'TypeLevelCare'];

    function EvaluationEditController(entity, jsonTemplate, Evaluation, Chart, $timeout, $scope, toastr, tokens,
                                      PopupService, Contacts, $uibModal, CoreService, $rootScope, Assistant,
                                      PendingPdfChartReport, $state, params, $window, REPORT_TYPE, TAB, access, ACTIONS,
                                      $interval, ConfirmationService, TypeLevelCare) {
        var vm = this;

        vm.evaluation = entity;
        vm.jsonTemplate = jsonTemplate;
        vm.chart = vm.evaluation.chart;
        vm.contact = vm.evaluation.contact;

        vm.params = params;
        vm.dateDiff = "";
        vm.enablePatientSignature = false;
        vm.enableGuarantorSignature = false;
        vm.enableStaffSignature = false;
        vm.enableStaffReviewerSignature = false
        vm.enableRevokeSignature = false;
        vm.compareEvaluation = null;
        vm.compareTokens = null;
        vm.compareWith = null;

        vm.guarantorSignatureButtonStyle = "";
        vm.staffSignatureButtonStyle = "";
        vm.staffReviewSignatureButtonStyle = "";

        vm.employee = CoreService.getCurrentEmployee();
        vm.evaluations = [];
        vm.signType = null;

        init();

        $rootScope.assistantList = Assistant.findAllBySection({
            section: 'CARE_DELIVERY',
            facility: vm.chart.facility.id
        });
        $rootScope.showAssitant = true;
        vm.openStepSelector = false;
        vm.sideBySide = false;
        vm.sideForm = false;
        vm.type = {
            PATIENT: 'patient',
            GUARANTOR: 'guarantor',
            STAFF: 'staff',
            STAFF_REVIEW: 'staff_review',
            REVOKE: 'revoke'
        }

        vm.accessCareCoordinationView = access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW] ?
            access[ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW].roles.join() : null;

        vm.TAB = TAB;
        vm.interval = null;
        vm.typeLevelCare = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));

        vm.toggleSideBySide = toggleSideBySide;
        vm.editContact = editContact;
        vm.showBillingDatesPopup = showBillingDatesPopup;
        vm.showDateOfServicePopup = showDateOfServicePopup;
        vm.showChartSignPopup = showChartSignPopup;
        vm.showGuarantorSignPopup = showGuarantorSignPopup;
        vm.showStaffSignPopup = showStaffSignPopup;
        vm.showStaffReviewSignPopup = showStaffReviewSignPopup;
        vm.showChartRevokeSignPopup = showChartRevokeSignPopup;
        vm.search = search;
        vm.compare = compare;
        vm.print = print;
        vm.restart = restart;
        vm.getHumanTitle = getHumanTitle;
        vm.getCredentials = getCredentials;
        vm.autoSaveLoc = autoSaveLoc;

        function init() {
            $window.scrollTo(0, 0);

            switch (vm.evaluation.status) {
                case "Finalized": {
                    if (vm.params.processId) {
                        Evaluation.noSignedPendingOrLate({
                            evaluationId: vm.evaluation.id, typePatientProcessId: vm.params.processId
                        }).$promise.then(function (data) {
                            if (data && data.id) {
                                $state.go('care-delivery', {id: data.id, processId: vm.params.processId});
                            } else {
                                $state.go("care-delivery-view", {id: vm.evaluation.id});
                            }
                        });
                    } else {
                        $state.go("care-delivery-view", {id: vm.evaluation.id});
                    }

                    break;
                }
                case "Revoked":
                case "Expired":
                case "Amended": {
                    $state.go("care-delivery-view", {id: vm.evaluation.id});

                    return;
                }
            }

            if (vm.watcher) {
                vm.watcher();
            }

            getDateDiff();

            vm.enablePatientSignature = isEnablePatientSignature();
            vm.enableGuarantorSignature = isEnableGuarantorSignature();
            vm.enableStaffSignature = isEnableStaffSignature();
            vm.enableStaffReviewerSignature = isEnableStaffReviewerSignature();

            vm.guarantorSignatureButtonStyle = getGuarantorSignatureButtonStyle();
            vm.staffSignatureButtonStyle = getStaffSignatureButtonStyle();
            vm.staffReviewSignatureButtonStyle = getStaffReviewSignatureButtonStyle();

            vm.formCKEditorTokensConfig = {
                ckeditor: {
                    tokens: tokens
                }
            };

            Chart.get({id: vm.evaluation.chartId}, function (chart) {
                vm.chart = chart;
            });

            if (vm.evaluation.contact) {
                Contacts.get({id: vm.evaluation.contact.id}, function (result) {
                    vm.contact = result;
                })
            }

            $timeout(function () {
                vm.watcher = $scope.$watch('vm.jsonTemplate', function (newValue, oldValue, scope) {
                    if (oldValue !== newValue) {
                        if (!vm.autoSaveId) {
                            vm.interval = $interval(autoSave, 1500);
                        }

                        vm.lastUpdate = new Date();
                        vm.autoSaveId = Math.random();
                    }
                }, true);
            }, 1000);
        }

        function autoSave() {
            if (vm.isSaving || !vm.autoSaveId) {

                return;
            }

            if (vm.savedId === vm.autoSaveId) {
                vm.autoSaveId = null;
                vm.savedId = null;

                $interval.cancel(vm.interval);

                return;
            }

            var now = new Date();

            if (((now.getTime() - vm.lastUpdate.getTime()) / 1000) < 1.5) {

                return;
            }

            vm.isSaving = true;
            vm.savedId = angular.copy(vm.autoSaveId);

            var jsonTemplate = angular.copy(vm.jsonTemplate);

            if (vm.evaluation.status == 'Pending' || vm.evaluation.status == 'Late') {
                vm.evaluation.measurements = getMeasurements(jsonTemplate);
            }

            if (vm.evaluation.addTitle) {
                vm.evaluation.title = vm.evaluation.addTitle;
                vm.evaluation.title = getTitle(vm.evaluation, jsonTemplate);
            }

            Evaluation.autoSave({evaluation: vm.evaluation, jsonTemplate: jsonTemplate}, function (data) {
                toastr.success("Saved: " + getHumanTitle());

                vm.isSaving = false;
                getDateDiff();
            }, function () {
                toastr.error("Error saving: " + getHumanTitle());

                vm.isSaving = false;
                getDateDiff();
            });
        }

        function toggleSideBySide() {
            vm.sideBySide = !vm.sideBySide;
            $rootScope.showAssitant = !vm.sideBySide;

            if (vm.sideBySide) {
                vm.searchQuery = vm.evaluation.name;
                vm.search();
            }
        }

        function getMeasurements(jsonTemplate) {
            var countByTables = [];

            _.forEach(jsonTemplate.layout, function (layout) {
                _.forEach(layout.rows, function (row) {
                    _.forEach(row.cols, function (col) {
                        _.filter(col.fields, {'type': "table", 'totals': true})
                            .forEach(function (table) {
                                var count = 0;
                                var colWithTotal = [];
                                //row
                                for (var i = 0; i < table.matrix.length; i++) {
                                    //column
                                    for (var j = 0; j < table.matrix[i].length; j++) {
                                        if (table.matrix[i][j].total == true || colWithTotal.indexOf(j) != -1) {
                                            if (table.matrix[i][j].total == true) {
                                                colWithTotal.push(j);
                                            }

                                            _.forEach(table.matrix[i][j].fields, function (field) {
                                                switch (field.type) {
                                                    case 'select': {
                                                        if (field.value != null && !isNaN(field.value[field.valueField])) {
                                                            count += parseInt(field.value[field.valueField]);
                                                        }

                                                        break;
                                                    }
                                                    case 'number':
                                                    case 'radio':
                                                    case 'text': {
                                                        if (field.value != null && !isNaN(field.value)) {
                                                            count += parseInt(field.value);
                                                        }

                                                        break;
                                                    }
                                                }
                                            })
                                        }
                                    }
                                }

                                countByTables.push(count);
                            })
                    });
                });
            });

            var measurements = _.mean(countByTables);

            return isNaN(measurements) ? null : measurements;
        }

        function getTitle(evaluation, jsonTemplate) {

            var tokes = getTokens(evaluation.addTitle);

            if (tokes.length == 0) {

                return;
            }

            _.forEach(jsonTemplate.layout, function (layout) {
                _.forEach(layout.rows, function (row) {
                    _.forEach(row.cols, function (col) {
                        _.forEach(col.fields, function (field) {
                            if (field.type == 'table') {
                                for (var i = 0; i < field.matrix.length; i++) {
                                    for (var j = 0; j < field.matrix[i].length; j++) {
                                        _.forEach(field.matrix[i][j].fields, function (_field) {
                                            if (tokes.indexOf(_field.name) != -1 && _field.value) {
                                                replaceField(_field, evaluation);
                                            }
                                        });
                                    }
                                }
                            } else {
                                if (tokes.indexOf(field.name) != -1 && field.value) {
                                    replaceField(field, evaluation);
                                }
                            }
                        });
                    });
                });
            });

            return evaluation.title;
        }

        function getTokens(addTitle) {
            var results = [], re = /{([^}]+)}/g, text;

            while (text = re.exec(addTitle)) {
                results.push(text[1]);
            }

            return results;
        }

        function replaceField(field, evaluation) {
            switch (field.type) {
                case 'select': {
                    evaluation.title = evaluation.title.replace("{" + field.name + "}", field.value.name);

                    break;
                }
                case 'text': {
                    evaluation.title = evaluation.title.replace("{" + field.name + "}", field.value);

                    break;
                }
                default: {
                    evaluation.title = evaluation.title.replace("{" + field.name + "}", "NOT IMPLEMENTED");
                }
            }
        }

        function editContact(id) {
            PopupService.showContactPopup(id).result.then(function (result) {
                vm.contact = result;
            }, function () {
            });
        }

        function getDateDiff() {
            if (vm.evaluation.billable && vm.evaluation.startBilling && vm.evaluation.endBilling) {
                var startD = moment(vm.evaluation.startBilling);
                var endD = moment(vm.evaluation.endBilling);

                vm.dateDiff = endD.diff(startD, 'minutes');
            }
        }

        function showBillingDatesPopup() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation/billing-dates/evaluation-billing-dates.html',
                controller: 'EvaluationBillingDatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: vm.evaluation
                }
            }).result.then(function (result) {
                vm.evaluation.startBilling = result.startBilling;
                vm.evaluation.endBilling = result.endBilling;

                vm.isSaving = true;
                Evaluation.autoSaveDates({evaluation: vm.evaluation}, function (data) {
                    getDateDiff();
                    toastr.success("Saved: " + getHumanTitle());
                    vm.isSaving = false;
                }, function () {
                    toastr.error("Error saving: " + getHumanTitle());
                    vm.isSaving = false;
                });
            });
        }

        function autoSaveLoc() {
            vm.isSaving = true;
            Evaluation.autoSaveLoc({evaluation: vm.evaluation}, function (data) {
                toastr.success("Saved: " + getHumanTitle());
                vm.isSaving = false;
            }, function () {
                toastr.error("Error saving: " + getHumanTitle());
                vm.isSaving = false;
            });
        }

        function showDateOfServicePopup() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation/day-of-service/evaluation-day-of-service.html',
                controller: 'EvaluationDayOfServiceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: vm.evaluation
                }
            }).result.then(function (result) {
                vm.evaluation.dayOfService = result.dayOfService;

                vm.isSaving = true;
                Evaluation.autoSaveDates({evaluation: vm.evaluation}, function (data) {
                    toastr.success("Saved: " + getHumanTitle());
                    vm.isSaving = false;
                }, function () {
                    toastr.error("Error saving: " + getHumanTitle());
                    vm.isSaving = false;
                });
            });
        }

        function isEnablePatientSignature() {
            return vm.evaluation.requiredPatientSignature && vm.evaluation.chartSignatureId == null;
        }

        function isEnableGuarantorSignature() {
            return vm.evaluation.chartSignatureId;
        }

        function isEnableStaffSignature() {
            vm.haveStaffCredential = vm.evaluation.staffSignatureCredentials == null ||
                vm.evaluation.staffSignatureCredentials.length === 0 ||
                _.intersection(vm.employee.credentials, vm.evaluation.staffSignatureCredentials).length > 0;

            return vm.haveStaffCredential;
        }

        function isEnableStaffReviewerSignature() {
            vm.haveStaffReviewCredential = vm.evaluation.staffReviewSignatureCredentials == null ||
                vm.evaluation.staffReviewSignatureCredentials.length === 0 ||
                _.intersection(vm.employee.credentials, vm.evaluation.staffReviewSignatureCredentials).length > 0;

            return vm.haveStaffReviewCredential;
        }

        function getGuarantorSignatureButtonStyle() {
            if (isEnableGuarantorSignature()) {
                return "fa fa-pencil-square-o";
            } else {
                return "fa fa-ban";
            }
        }

        function getStaffSignatureButtonStyle() {
            if (isEnableStaffSignature()) {
                return "fa fa-pencil-square-o";
            } else {
                return "fa fa-ban";
            }
        }

        function getStaffReviewSignatureButtonStyle() {
            if (isEnableStaffReviewerSignature()) {
                return "fa fa-pencil-square-o";
            } else {
                return "fa fa-ban";
            }
        }

        function signPatient(signature) {
            Evaluation.chartSign({ids: [vm.evaluation.id], signature: signature}, function (result) {
                $state.reload();
            });
        }

        function signRevoke(signature) {
            Evaluation.revoke({ids: [vm.evaluation.id], signature: signature}, function (result) {
                $state.reload();
            });
        }

        function signStaff(signature, pin) {
            Evaluation.staffSign({
                ids: [vm.evaluation.id], signature: signature, pin: pin
            }, function () {
                $state.reload();
            }, function () {
                toastr.error("Error signing Care & Delivery form");
            });
        }

        function signStaffReviewer(signature, pin) {
            Evaluation.staffReviewSign({
                ids: [vm.evaluation.id], signature: signature, pin: pin
            }, function () {
                $state.reload();
            }, function () {
                toastr.error("Error signing Care & Delivery form");
            });
        }

        function showChartSignPopup() {
            if (PopupService.checkSigPlusExtLiteExtension()) {
                startSign(vm.type.PATIENT);
            } else {
                PopupService.showPatientSignaturePopup(vm.chart).result.then(function (data) {
                    signPatient(data);
                });
            }
        }

        function showChartRevokeSignPopup() {
            if (PopupService.checkSigPlusExtLiteExtension()) {
                startSign(vm.type.REVOKE);
            } else {
                PopupService.showPatientSignaturePopup(vm.chart).result.then(function (data) {
                    signRevoke(data)
                });
            }
        }

        function showGuarantorSignPopup() {
            return $uibModal.open({
                templateUrl: 'app/components/udt/signature/signature-contact-dialog.html',
                controller: 'SignatureGuarantorDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    evaluation: vm.evaluation
                }
            }).result.then(function (data) {
                Evaluation.guarantorSign({
                    id: vm.evaluation.id,
                    signature: data.signature,
                    guarantor: data.guarantor
                }, function () {
                    $state.reload();
                });
            });
        }

        function showStaffSignPopup() {
            if (PopupService.checkSigPlusExtLiteExtension()) {
                startSign(vm.type.STAFF);
            } else {
                PopupService.showSignaturePopup().result.then(function (data) {
                    signStaff(data.employeeSignature, data.employeeSignaturePin);
                });
            }
        }

        function showStaffReviewSignPopup() {
            if (PopupService.checkSigPlusExtLiteExtension()) {
                startSign(vm.type.STAFF_REVIEW);
            } else {
                PopupService.showSignaturePopup().result.then(function (data) {
                    signStaffReviewer(data.employeeSignature, data.employeeSignaturePin);
                });
            }
        }

        function search() {
            if (vm.searchQuery && vm.searchQuery.length > 3)
                Evaluation.findAllByFilter({
                    chartId: vm.evaluation.chart.id,
                    facilityId: vm.evaluation.chart.facility.id,
                    searchQuery: vm.searchQuery,
                    page: 0,
                    size: 20
                }, function (result) {
                    vm.compareWith = null;
                    vm.evaluations = result;
                })
        }

        function compare() {
            if (vm.compareWith) {
                Evaluation.get({id: vm.compareWith}, function (result) {
                    vm.compareEvaluation = result;

                    Evaluation.getEvaluationTokensValues({id: vm.compareWith}, function (result) {
                        vm.compareTokens = result;

                        vm.sideBySide = false;
                        vm.sideForm = true;
                    });

                    Evaluation.getJsonTemplate({id: vm.compareWith}, function (result) {
                        vm.compareJsonTemplate = result;
                    })
                });
            }
        }

        function print() {
            vm.isPrinting = true;

            PendingPdfChartReport.save({
                chart: vm.chart,
                objectId: vm.evaluation.id,
                name: vm.evaluation.name,
                reportType: REPORT_TYPE.EVALUATION
            }, onPrintSuccess, onPrintError);

            function onPrintSuccess(result) {
                vm.isPrinting = false;

                toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
            }

            function onPrintError() {
                vm.isPrinting = false;
                toastr.error("We couldn't generate the report.");
            }
        }

        $scope.$on('$destroy', function () {
            if (vm.watcher) {
                vm.watcher();
            }

            $rootScope.showAssitant = false;
        });

        function startSign(type) {
            var message = {
                "firstName": "",
                "lastName": "",
                "eMail": "",
                "location": "",
                "imageFormat": 1,
                "imageX": 500,
                "imageY": 100,
                "imageTransparency": false,
                "imageScaling": false,
                "maxUpScalePercent": 0.0,
                "rawDataFormat": "ENC",
                "minSigPoints": 25
            };

            vm.signType = type;

            top.document.addEventListener('SignResponse', SignResponse, false);
            var messageData = JSON.stringify(message);
            var element = document.createElement("MyExtensionDataElement");
            element.setAttribute("messageAttribute", messageData);
            document.documentElement.appendChild(element);
            var evt = document.createEvent("Events");
            evt.initEvent("SignStartEvent", true, false);
            element.dispatchEvent(evt);
        }

        function SignResponse(event) {
            var str = event.target.getAttribute("msgAttribute");
            var objResponse = JSON.parse(str);
            var obj = null;

            if (typeof (objResponse) === 'string') {
                obj = JSON.parse(objResponse);
            } else {
                obj = JSON.parse(JSON.stringify(objResponse));
            }

            if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined") {
                toastr.error(obj.errorMsg);
            } else {
                if (obj.isSigned) {
                    switch (vm.signType) {
                        case vm.type.PATIENT : {
                            signPatient("data:image/jpg;base64," + obj.imageData);

                            break;
                        }
                        case vm.type.STAFF: {
                            signStaff("data:image/jpg;base64," + obj.imageData, null);

                            break;
                        }
                        case vm.type.STAFF_REVIEW: {
                            signStaffReviewer("data:image/jpg;base64," + obj.imageData, null);

                            break;
                        }
                    }
                }
            }

            top.document.removeEventListener('SignResponse', SignResponse, false);
        }

        function restart() {
            ConfirmationService.open({
                title: 'Restore Employee',
                message: 'Are you sure you want to <strong class="text-uppercase">restart</strong> this Evaluation?',
                actions: [
                    {
                        name: 'Restart',
                        color: 'btn btn-success',
                        icon: 'fa fa-times-circle-o',
                        value: 'SUCCESS'
                    },
                    {
                        name: 'Cancel',
                        color: 'btn btn-danger',
                        icon: 'fa fa-ban',
                        value: 'CANCEL'
                    }
                ]
            }).result.then(function () {
                Evaluation.restart({id: vm.evaluation.id}, function () {
                    $state.reload();
                });
            });
        }

        function getHumanTitle() {
            return vm.evaluation.name + (vm.evaluation.title ? vm.evaluation.title : "");
        }

        function getCredentials(credentials) {
            return credentials === null || credentials.length === 0 ? 'Does not require any credentials' : _.join(credentials, ", ");
        }
    }
})();

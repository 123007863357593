(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionController', GroupSessionController);

    GroupSessionController.$inject = ['$uibModal', 'CoreService', 'Building', 'GroupSessionTemplate', '$state',
        'Employee', 'filterRes', 'access'];

    function GroupSessionController($uibModal, CoreService, Building, GroupSessionTemplate, $state,
                                    Employee, filterRes, access) {
        var vm = this;

        vm.filterRes = filterRes;
        vm.toggleSidebar = vm.filterRes.toggleSidebar;

        vm.title = 'Sessions';
        vm.building = null;
        vm.buildings = Building.byFacility({id: CoreService.getCurrentFacility().id});
        vm.corporation = CoreService.getCorporation();
        vm.eventSources = [];
        vm.employees = [];
        vm.employeesSelection = [];
        vm.employee = null;
        vm.groupSessionStatusesSelect = [];
        vm.groupSessionStatuses = [
            {value: 'STARTED', label: 'Started', nameClass: 'event-type started'},
            {value: 'PENDING', label: 'Pending', nameClass: 'event-type pending'},
            {value: 'REVIEW', label: 'Needs Review', nameClass: 'event-type needs-review'},
            {value: 'COMPLETE', label: 'Complete', nameClass: 'event-type complete'},
            {value: 'DISABLED', label: 'No longer Available', nameClass: 'event-type not-available'}
        ];

        vm.calendarConfig = {
            calendar: {
                defaultView: 'month',
                editable: false,
                selectable: true,
                contentHeight: 'auto',
                header: {
                    left: 'month,agendaWeek,agendaDay',
                    center: 'title',
                    right: 'today prevYear,prev,next,nextYear'
                },
                timeFormat: 'h:mm a',
                weekNumbers: true,
                defaultDate: vm.filterRes.end ? vm.filterRes.end - 1296000000 : new Date(),
                /*
                * - dayClick event --> https://fullcalendar.io/docs/v3/dayClick
                * - date : holds a Moment for the clicked day. If an all-day area has been clicked, the moment will be ambiguously-timed. If a slot in the agendaWeek or agendaDay views has been clicked, date will have the slot’s time.
                * - jsEvent : holds the jQuery event with low-level information such as click coordinates.
                * - view : is set to the current View Object.
                * - Viw Object --> https://fullcalendar.io/docs/v3/view-object
                * */
                // dayClick: function (date, jsEvent, view) {
                //     $uibModal.open({
                //         templateUrl: 'app/entities/group-sessions-v2/group-session-new-session-dialog.html',
                //         controller: 'GroupSessionNewSessionDialog',
                //         controllerAs: 'vm',
                //         size: 'lg',
                //         windowTopClass: 'custom-dialog-styles',
                //         resolve: {}
                //     }).result.then(function (result) {
                //
                //     }, function () {
                //
                //     });
                // },
                eventClick: eventClickCallback
            }
        };
        vm.access = access;

        vm.toggleSelection = toggleSelection;
        vm.toggleStatusSelection = toggleStatusSelection;
        vm.getEmployees = getEmployees;
        vm.addEmployee = addEmployee;
        vm.delEmployee = delEmployee;
        vm.reloadCalendar = reloadCalendar;
        vm.eventsF = eventsF;
        vm.checkedBuilding = checkedBuilding;
        vm.checkedStatus = checkedStatus;

        function addEmployee() {
            if (vm.employee != null) {
                vm.filterRes.employeesSelection.push(vm.employee);
            }

            vm.employee = null;
            vm.employees = null;

            reloadCalendar();
        }

        function delEmployee(employee) {
            var idx = vm.filterRes.employeesSelection.indexOf(employee);
            vm.filterRes.employeesSelection.splice(idx, 1);

            reloadCalendar();
        }

        function checkedBuilding(item) {
            var idx = vm.filterRes.buildingSelection.indexOf(item);
            if (idx > -1) {
                return true;
            } else {
                return false;
            }
        }

        function checkedStatus(item) {
            var idx = vm.filterRes.groupSessionStatusesSelect.indexOf(item);
            if (idx > -1) {
                return true;
            } else {
                return false;
            }
        }

        function toggleSelection(item) {
            var idx = vm.filterRes.buildingSelection.indexOf(item);
            if (idx > -1) {
                vm.filterRes.buildingSelection.splice(idx, 1);
            } else {
                vm.filterRes.buildingSelection.push(item);
            }

            reloadCalendar();
        }

        function toggleStatusSelection(item) {
            var idx = vm.filterRes.groupSessionStatusesSelect.indexOf(item);
            if (idx > -1) {
                vm.filterRes.groupSessionStatusesSelect.splice(idx, 1);
            } else {
                vm.filterRes.groupSessionStatusesSelect.push(item);
            }

            reloadCalendar();
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function reloadCalendar() {
            CoreService.setStorage('filterCalendar', vm.filterRes);
            $('#groupSessionsCalendar').fullCalendar('refetchEvents');
        }

        function eventsF(start, end, timezone, callback) {
            vm.filterRes.start = start;
            vm.filterRes.end = end;
            CoreService.setStorage('filterCalendar', vm.filterRes);

            GroupSessionTemplate.schedule({
                    start: start.toDate(),
                    end: end.toDate(),
                    facilityId: CoreService.getCurrentFacility().id,
                    buildings: vm.filterRes.buildingSelection ? vm.filterRes.buildingSelection : null,
                    employees: vm.filterRes.employeesSelection ? _.map(vm.filterRes.employeesSelection, 'id') : null,
                    searchQuery: vm.filterRes.searchQuery ? vm.filterRes.searchQuery : null,
                    groupSessionStatuses: vm.filterRes.groupSessionStatusesSelect ? vm.filterRes.groupSessionStatusesSelect : null
                },
                function (data) {
                    callback(data);
                });
        }

        vm.eventSources = [vm.eventsF];

        function eventClickCallback(calEvent, jsEvent, view) {
            if (calEvent.template) {
                // $state.go('group-session-init', {id: calEvent.template.id, startDate: calEvent.start._d});
                initGroupSession(calEvent.template.id, calEvent.start._d);
            } else if (calEvent.groupSession) {
                $state.go('group-session-edit', {id: calEvent.groupSession.id});
            }
            // if (calEvent.template) {
            //     $state.go('event-wizard', {groupSessionTemplate: calEvent.template.id, calEvent: calEvent});
            // }
            // if (calEvent.groupSession) {
            //     // if (calEvent.groupSession.status !== 'COMPLETE') {
            //     $state.go('event-wizard', {groupSession: calEvent.groupSession.id, calEvent: calEvent});
            //     // } else {
            //     //     $state.go('group-session-view', {groupSession: calEvent.groupSession.id});
            //     // }
            // }
        }

        function initGroupSession(templateId, startDate) {
            $uibModal.open({
                templateUrl: 'app/entities/group-session/init/group-session-init.html',
                controller: 'GroupSessionInitController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['GroupSessionTemplate', function (GroupSessionTemplate) {
                        return GroupSessionTemplate.get({id: templateId}).$promise;
                    }],
                    startDate: startDate
                }
            });
        }
    }
})();

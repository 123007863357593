(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('insurance-type', {
                parent: 'entity',
                url: '/insurance-type',
                data: {
                    authorities: [],
                    pageTitle: 'InsuranceTypes'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/insurance-type/list/insurance-type.html',
                        controller: 'InsuranceTypeController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Insurance Types'
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_INSURANCE_TYPE];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_INSURANCE_TYPE, access);
                }]
            });
    }
})();

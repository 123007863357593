(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationVitalController', EvaluationVitalController);

    EvaluationVitalController.$inject = ['$scope', '$state', 'EvaluationVital', 'entity', '$uibModal', 'Vitals'];

    function EvaluationVitalController($scope, $state, EvaluationVital, entity, $uibModal, Vitals) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationVitals = null;
        vm.lastVitals = null;

        vm.loadAll = loadAll;
        vm.add = add;
        vm.history = history;

        loadAll();

        function loadAll() {
            if (!vm.evaluation.vital) {
                return;
            }

            vm.lastVitals = null;

            EvaluationVital.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                if (result.length > 0) {
                    vm.evaluationVitals = result[0];
                } else {
                    Vitals.findLastByChart({id: vm.evaluation.chart.id}, function (result) {
                        if (result) {
                            if (moment(new Date()).diff(moment(result.date), 'hours', true) > 24) {
                                vm.lastVitals = result;
                            }
                        }
                    });
                }
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/vitals/update/vitals-dialog.html',
                controller: 'EvaluationVitalDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            evaluation: {id: vm.evaluation.id},
                            vital: {
                                name: null,
                                status: null,
                                id: null,
                                chart: vm.evaluation.chart
                            }
                        }
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/vitals/history/vitals.html',
                controller: 'VitalsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.evaluation.chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AllergiesController', AllergiesController);


    AllergiesController.$inject = ['chart', 'Allergies', 'CoreService', '$sessionStorage', '$uibModal', 'access',
        'ACTIONS', 'Auth', 'ConfirmationService'];

    function AllergiesController(chart, Allergies, CoreService, $sessionStorage, $uibModal, access,
                                 ACTIONS, Auth, ConfirmationService) {
        var vm = this;
        vm.chart = chart;

        vm.activeTab = $sessionStorage.activePatientTabAllergies ? $sessionStorage.activePatientTabAllergies.activeTab : 0;
        vm.name = "  ";//chart.patient.lastName + " " + chart.patient.firstName;
        vm.mrNumber = chart.mrNo;

        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.keepActiveTab = keepActiveTab;

        function toggleItem() {
            CoreService.togglePanel('allergies');
        }

        function keepActiveTab(index) {
            $sessionStorage.activePatientTabAllergies = {activeTab: index};
            vm.activeTab = index;
        }

        loadData();

        function loadData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_ALLERGIES_VIEW, access)) {

                return;
            }

            vm.allergies = Allergies.findAllByChart({id: chart.id});
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/allergies/update/allergies-dialog.html',
                controller: 'AllergiesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            reaction: null,
                            treatment: null,
                            id: null,
                            active: false,
                            chart: chart
                        };
                    }
                }
            }).result.then(function () {
                loadData()
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/allergies/update/allergies-dialog.html',
                controller: 'AllergiesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Allergies', function (Allergies) {
                        return Allergies.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadData()
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Allergies').result.then(function () {
                Allergies.delete({id: id}, function () {
                    loadData()
                });
            })
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmTag', CrmTag);

    CrmTag.$inject = ['$resource'];

    function CrmTag ($resource) {
        var resourceUrl =  'api/crm-tags/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByCrmOpportunity': {url: 'api/crm-tags/by-crm-opportunity/:id', method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ProtocolGroup', ProtocolGroup);

    ProtocolGroup.$inject = ['$resource'];

    function ProtocolGroup ($resource) {
        var resourceUrl =  'api/protocol-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByCorporation': {url: 'api/protocol-groups/by-corporation/:id', method: 'GET', isArray: true}
        });
    }
})();

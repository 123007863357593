(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('covid-question', {
            parent: 'entity',
            url: '/covid-question',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'CovidQuestions'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/covid-question/covid-questions.html',
                    controller: 'CovidQuestionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('covid-question.new', {
            parent: 'covid-question',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/covid-question/covid-question-dialog.html',
                    controller: 'CovidQuestionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                name: null,
                                enable: null,
                                xorder: null,
                                id: null,
                                facility :CoreService.getCurrentFacility()
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('covid-question', null, { reload: 'covid-question' });
                }, function() {
                    $state.go('covid-question');
                });
            }]
        })
        .state('covid-question.edit', {
            parent: 'covid-question',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/covid-question/covid-question-dialog.html',
                    controller: 'CovidQuestionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CovidQuestion', function(CovidQuestion) {
                            return CovidQuestion.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('covid-question', null, { reload: 'covid-question' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('covid-question.delete', {
            parent: 'covid-question',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/covid-question/covid-question-delete-dialog.html',
                    controller: 'CovidQuestionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CovidQuestion', function(CovidQuestion) {
                            return CovidQuestion.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('covid-question', null, { reload: 'covid-question' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('crm-opportunity-status', {
                parent: 'entity',
                url: '/crm-opportunity-status',
                data: {
                    authorities: [],
                    pageTitle: 'CrmOpportunityStatus'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/crm-opportunity-status/crm-opportunity-status.html',
                        controller: 'CrmOpportunityStatusController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Crm Opportunity Status'
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS, access);
                }]
            })
            .state('crm-opportunity-status.new', {
                parent: 'crm-opportunity-status',
                url: '/new',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-opportunity-status/crm-opportunity-status-dialog.html',
                            controller: 'CrmOpportunityStatusDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CoreService', function (CoreService) {
                                    return {
                                        name: null,
                                        xorder: null,
                                        id: null,
                                        corporation: CoreService.getCorporation()
                                    };
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-opportunity-status', null, {reload: 'crm-opportunity-status'});
                        }, function () {
                            $state.go('crm-opportunity-status');
                        });
                    }
                }]
            })
            .state('crm-opportunity-status.edit', {
                parent: 'crm-opportunity-status',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-opportunity-status/crm-opportunity-status-dialog.html',
                            controller: 'CrmOpportunityStatusDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CrmOpportunityStatus', function (CrmOpportunityStatus) {
                                    return CrmOpportunityStatus.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-opportunity-status', null, {reload: 'crm-opportunity-status'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            })
            .state('crm-opportunity-status.delete', {
                parent: 'crm-opportunity-status',
                url: '/{id}/delete',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_CRM_OPPORTUNITY_STATUS, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/crm-opportunity-status/crm-opportunity-status-delete-dialog.html',
                            controller: 'CrmOpportunityStatusDeleteController',
                            controllerAs: 'vm',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CrmOpportunityStatus', function (CrmOpportunityStatus) {
                                    return CrmOpportunityStatus.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('crm-opportunity-status', null, {reload: 'crm-opportunity-status'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('RelationalSelectDialogController', RelationalSelectDialogController);

    RelationalSelectDialogController.$inject = ['$scope', 'Relational', '$uibModalInstance', '$timeout',
        'CoreService', '$q', 'DTOptionsBuilder', 'DTColumnBuilder', 'GUIUtils',
        '$compile', 'DataTablesService', 'selectedRelationals', 'RATIONALE_TYPE'];

    function RelationalSelectDialogController($scope, Relational, $uibModalInstance, $timeout,
                                              CoreService, $q, DTOptionsBuilder, DTColumnBuilder, GUIUtils,
                                              $compile, DataTablesService, selectedRelationals, RATIONALE_TYPE) {
        var vm = this;

        vm.relationals = [];
        vm.dtInstance = {};
        vm.selected = {};

        vm.clear = clear;
        vm.save = save;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Relational.findAllByFilter(getFilterByCorporationAndType(), function (result, headers) {
                vm.relationals = result;
                defer.resolve(result);
            });

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('ltp').withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        })
            .withDisplayLength(15)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('aaSorting', [0, 'asc'])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).notSortable().renderWith(function (data, type, full, meta) {
                if (_.find(selectedRelationals, function (o) {
                    return o.id === full.id;
                })) {
                    vm.selected[full.id] = true;
                } else {
                    vm.selected[full.id] = false;
                }

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $uibModalInstance.close(vm.getSelectedIds(vm.selected));
        }

        function getFilterByCorporationAndType() {
            var filter = {};

            filter.corporationId = CoreService.getCorporation().id;
            filter.relationalType = RATIONALE_TYPE.LABORATORY;

            return filter
        }

    }
})();

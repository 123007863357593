(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EmployeeSystemDialogController', EmployeeSystemDialogController);

    EmployeeSystemDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Employee',
        'CoreService', 'Facility', 'ROLES', 'GUIUtils', 'AuthItem', 'AUTH_ITEM_TYPE', 'Corporation'];

    function EmployeeSystemDialogController($timeout, $scope, $uibModalInstance, entity, Employee,
                                      CoreService, Facility, ROLES, GUIUtils, AuthItem, AUTH_ITEM_TYPE, Corporation) {
        var vm = this;

        vm.employee = entity;
        vm.authorities = [];
        vm.userExist = false;
        vm.emailExist = false;
        vm.gender = GUIUtils.getGender();
        vm.credentials = Employee.credentials();
        vm.ips = angular.copy(vm.employee.employeeInetAddresses);
        vm.signatureOpts = getSignatureOptions();
        vm.odsSignatureNameId = Math.floor((Math.random() * 100) + 1);
        vm.employeeSignature = '';

        vm.clear = clear;
        vm.save = save;
        vm.searchUser = searchUser;
        vm.searchEmail = searchEmail;
        vm.ipTagTransform = ipTagTransform;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.employee.signature = vm.employeeSignature;

            if (vm.employee.id !== null) {
                Employee.updateSystem(vm.employee, onSaveSuccess, onSaveError);
            } else {
                Employee.createSystem(vm.employee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:employeeUpdate', result);
            $uibModalInstance.close(result);

            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function searchUser(query) {
            if (query && query.length > 2) {
                Employee.existUserName({query: query}, function (result) {

                    vm.userExist = result.value;
                });
            }
        }

        function searchEmail(query) {
            if (query && query.length > 2) {
                Employee.existEmail({query: query}, function (result) {

                    vm.emailExist = result.value;
                });
            }
        }

        function ipTagTransform(newTag) {
            var item = {
                id: null,
                value: newTag,
            };

            return item;
        }

        function getSignatureOptions() {

            return {
                width: 620,
                height: 250,
                cssClass: 'signature-canvas',
                color: '#00008B',
                'background-color': '#fff'
            };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgEligCorpDialogController', ProgEligCorpDialogController);

    ProgEligCorpDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProgEligCorp', 'Corporation'];

    function ProgEligCorpDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProgEligCorp, Corporation) {
        var vm = this;

        vm.progEligCorp = entity;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.endDateOptions = {};
        vm.startDateOptions = {};
        vm.openCalendar = openCalendar;
        vm.validateEndDate = validateEndDate;
        vm.validateStartDate = validateStartDate;

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.progEligCorp.id !== null) {
                ProgEligCorp.update(vm.progEligCorp, onSaveSuccess, onSaveError);
            } else {
                ProgEligCorp.save(vm.progEligCorp, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:progEligCorpUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.progEligCorp.startDate;
        }

        function validateStartDate(){
            vm.startDateOptions.maxDate = vm.progEligCorp.endDate;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('group-session', {
                parent: 'entity',
                url: '/group-session',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Sessions'
                },
                ncyBreadcrumb: {
                    label: 'Sessions'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group-session/calendar/group-sessions.html',
                        controller: 'GroupSessionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: ['$stateParams', 'CoreService', function ($stateParams, CoreService) {
                        var filterCalendar = CoreService.getStorage('filterCalendar');

                        if (filterCalendar == undefined) {
                            return CoreService.initCalendarFilter();
                        } else {
                            return CoreService.readCalendarFilterFromLocalStore(filterCalendar);
                        }

                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_GROUP_SESSIONS];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_GROUP_SESSIONS, access);
                }]
            })
            .state('group-session-edit', {
                parent: 'entity',
                url: '/group-session/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Sessions'
                },
                ncyBreadcrumb: {
                    label: 'Sessions'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group-session/edit/group-session-edit.html',
                        controller: 'GroupSessionEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'GroupSession', function($stateParams, GroupSession) {
                        return GroupSession.get({id : $stateParams.id}).$promise;
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_GROUP_SESSIONS, ACTIONS.ACTION_MASTER_EDIT_GROUP_SESSION];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_GROUP_SESSIONS, access);
                }]
            })
    }
})();

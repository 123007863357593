(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Insurance', Insurance);

    Insurance.$inject = ['$resource', 'DateUtils'];

    function Insurance($resource, DateUtils) {
        var resourceUrl = 'api/insurances/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    return angular.toJson(copy);
                }
            },
            'findAllByChart': {
                url: 'api/insurances/by-chart/:id', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    var copy = angular.fromJson(data);

                    if (copy && copy.length > 0) {
                        copy.forEach(function (insurance) {
                            insurance.frontPicture = insurance.frontPicture ? insurance.frontPicture : {};
                            insurance.frontPictureOriginal = insurance.frontPictureOriginal ? insurance.frontPictureOriginal : {};
                            insurance.backPicture = insurance.backPicture ? insurance.backPicture : {};
                            insurance.backPictureOriginal = insurance.backPictureOriginal ? insurance.backPictureOriginal : {};
                        });
                    }

                    return copy;
                }
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('covid-physician', {
                parent: 'entity',
                url: '/covid-physician',
                data: {
                    authorities: [],
                    pageTitle: 'CovidPhysicians'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/covid-physician/covid-physicians.html',
                        controller: 'CovidPhysicianController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                        Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN, access);
                    }]
                }
            })
            .state('covid-physician.new', {
                parent: 'covid-physician',
                url: '/new',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'CoreService', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, CoreService, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/covid-physician/covid-physician-dialog.html',
                            controller: 'CovidPhysicianDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            windowTopClass: 'custom-dialog-styles',
                            size: 'md',
                            resolve: {
                                entity: function () {
                                    return {
                                        id: null,
                                        facility: CoreService.getCurrentFacility(),
                                        physician: null
                                    };
                                },
                                itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                                    return Employee.checkBusinessRole({
                                        facilityId: CoreService.getCurrentFacility().id,
                                        businessRole: BUSINESS_ROLES.PHYSICIAN
                                    }).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('covid-physician', null, {reload: 'covid-physician'});
                        }, function () {
                            $state.go('covid-physician');
                        });
                    }
                }]
            })
            .state('covid-physician.edit', {
                parent: 'covid-physician',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/covid-physician/covid-physician-dialog.html',
                            controller: 'CovidPhysicianDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            windowTopClass: 'custom-dialog-styles',
                            size: 'md',
                            resolve: {
                                entity: ['CovidPhysician', function (CovidPhysician) {
                                    return CovidPhysician.get({id: $stateParams.id}).$promise;
                                }],
                                itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                                    return Employee.checkBusinessRole({
                                        facilityId: CoreService.getCurrentFacility().id,
                                        businessRole: BUSINESS_ROLES.PHYSICIAN
                                    }).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('covid-physician', null, {reload: 'covid-physician'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            })
            .state('covid-physician.delete', {
                parent: 'covid-physician',
                url: '/{id}/delete',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_COVID_PHYSICIAN, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/covid-physician/covid-physician-delete-dialog.html',
                            controller: 'CovidPhysicianDeleteController',
                            controllerAs: 'vm',
                            windowTopClass: 'custom-dialog-styles',
                            size: 'md',
                            resolve: {
                                entity: ['CovidPhysician', function (CovidPhysician) {
                                    return CovidPhysician.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('covid-physician', null, {reload: 'covid-physician'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            });
    }

})();

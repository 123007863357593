(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DietFoodMemoController', DietFoodMemoController);

    DietFoodMemoController.$inject = ['$uibModalInstance', 'chart', 'Allergies', 'Diet'];

    function DietFoodMemoController($uibModalInstance, chart, Allergies, Diet) {
        var vm = this;
        vm.clear = clear;
        vm.chartImage = chart.pictureRef.picture;
        vm.name = chart.lastName + " " + chart.firstName;
        vm.mrNumber = chart.mrNo;

        var dd = {
            content: [],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'justify'
                },
                subheader: {
                    fontSize: 15,
                    bold: true
                },
                quote: {
                    italics: true
                },
                small: {
                    fontSize: 8
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },

                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            }
        }

        var header = {
            text: 'Food Memo',
            style: 'header'
        };
        var information = {
            text: 'Patient special dietary',
            style: 'subheader',
            alignment: 'center'
        };
        var personalInfo = {
            style: 'tableExample',
            table: {
                headerRows: 1,
                body: [[{text: 'Name: ', style: 'tableHeader'}, vm.name],
                    [{text: 'MR Number: ', style: 'tableHeader'}, vm.mrNumber]]
            }, layout: 'noBorders'
        };
        var image = {image: vm.chartImage, alignment: 'center', width: 450, height: 450};

        var foodAllergies = {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: ['*'],
                body: []
            },
            alignment: 'center',
            layout: 'headerLineOnly'
        };

        var activeDiets = {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: ['*'],
                body: []
            },
            alignment: 'center',
            layout: 'headerLineOnly'
        };

        dd.content.push(header);
        dd.content.push(information);
        dd.content.push(personalInfo);
        dd.content.push(image);

        loadL();

        function loadL() {
            Allergies.findAllAllergiesByChart({id: chart.id, allergenType: 'Food'}, function (result) {
                vm.allergies = result;
                foodAllergies.table.body.push([{text: 'Food Allergies', style: 'tableHeader'}]);
                for (var i = 0; i < vm.allergies.length; i++) {
                    foodAllergies.table.body.push([{text: vm.allergies[i].name}]);
                }
                if (vm.allergies.length) {
                    dd.content.push(foodAllergies);
                }

                Diet.findAllActiveDietsByChart({id: chart.id}, function (result) {
                    vm.diets = result;
                    activeDiets.table.body.push([{text: 'Diets', style: 'tableHeader'}]);
                    for (var i = 0; i < vm.diets.length; i++) {
                        activeDiets.table.body.push([{text: vm.diets[i].typeFoodDiets.name}]);
                    }
                    if (vm.diets.length) {
                        dd.content.push(activeDiets);
                    }
                    vm.pdf = pdfMake.createPdf(dd);

                    vm.pdf.getBase64(function (output) {
                        vm.pdfBase64 = 'data:application/pdf;base64,' + output;
                    });

                });

            });
        }


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

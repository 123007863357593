(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationChart2Controller', PatientMedicationChart2Controller);

    PatientMedicationChart2Controller.$inject = ['CoreService', '$uibModal', 'chart', 'PatientMedication',
        'AlertService', 'ParseLinks', 'PopupService', 'DataTablesService', 'toastr',
        '$rootScope', '$state', '$scope', 'access', 'discontinued', '$uibModalStack',
        'ACTIONS', 'TAB', 'params', 'Auth', 'PendingPdfChartReport', 'REPORT_TYPE'];

    function PatientMedicationChart2Controller(CoreService, $uibModal, chart, PatientMedication,
                                               AlertService, ParseLinks, PopupService, DataTablesService, toastr,
                                               $rootScope, $state, $scope, access, discontinued, $uibModalStack,
                                               ACTIONS, TAB, params, Auth, PendingPdfChartReport, REPORT_TYPE) {
        var vm = this;

        vm.chart = chart;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.patientMedications = [];
        vm.selected = {};
        vm.selectAll = false;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.MEDICATIONS);
        vm.chart = chart;
        vm.access = access;

        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.transition = transition;
        vm.view = view;
        vm.fax = fax;
        vm.discontinue = discontinue;
        vm.search = search;
        vm.history = history;
        vm.print = print;
        vm.edit = edit;
        vm.changeOnlyChart = changeOnlyChart;

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_MEDICATION_VIEW, access) || !vm.active) {
                return;
            }

            PatientMedication.findAllByFilter({
                chartId: chart.id,
                searchQuery: vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                discontinued: discontinued
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientMedications = data;

                initSelected();
            }

            function onError(error) {
                AlertService.error(error.data);
            }
        }

        function toggleItem() {
            CoreService.togglePanel('patientMeds');
        }

        function add() {
            PopupService.showPatientMedicationPopup(chart).result.then(function () {
                reload();
            }, function () {

            });
        }

        function reload() {
            vm.page = 1;
            loadAll();
        }

        function view(id) {
            PopupService.showPatientMedicationsDetailsPopup(id).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function print(id) {
            PendingPdfChartReport.save({
                chart: {id: vm.chart.id},
                objectId: id,
                name: "" + id,
                reportType: REPORT_TYPE.PATIENT_MEDICATION
            }, onPrintSuccess, onPrintError);
        }

        function onPrintSuccess(result) {
            toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
        }

        function onPrintError() {
            toastr.error("We couldn't generate the report.");
        }

        function fax(id) {
            PopupService.showContactAddressPickupPopup().result.then(function (result) {
                PatientMedication.fax({
                    ids: (id ? [id] : vm.getSelectedIds(vm.selected)),
                    moreIds: result.ids
                }, function () {
                    toastr.success("Fax in queue");
                }, function () {
                    _.forEach(result, function (contactAddress) {
                        toastr.error('Error sending fax to ' + (contactAddress.name ? contactAddress.name : "") + " Fax: " + contactAddress.fax + ". Try again later.");
                    })
                });
            });
        }

        function discontinue(id) {
            PopupService.showPatientMedicationDiscontinueWizardPopup().result.then(function (data) {
                PatientMedication.discontinue2({
                    signature: data.employeeSignature,
                    pin: data.employeeSignaturePin,
                    ids: (id ? [id] : vm.getSelectedIds(vm.selected)),
                    chartId: vm.chart.id,
                    discontinueVia: data.discontinueVia,
                    discontinueCause: data.discontinueCause,
                    physician: data.discontinueByPhysician,
                    facilityId: vm.chart.facility.id
                }, function (result) {
                    loadAll();

                    if (result) {
                        toastr.success("The Patient Medication was discontinued.");
                    } else {
                        toastr.error('The Patient Medication wasn\'t discontinued.');
                    }
                }, function () {
                    toastr.error('The Patient Medication wasn\'t discontinued.');
                });
            });
        }

        function transition() {
            loadAll();
        }

        function initSelected() {
            _.forEach(vm.patientMedications, function (patientMedication) {
                vm.selected[patientMedication.id] = false;
                vm.selectAll = false;
            })
        }

        function search() {
            vm.page = 1;
            transition();
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result == TAB.MEDICATIONS) {
                vm.active = true;
                loadAll();
            }
        });

        $scope.$on('$destroy', unsubscribe);

        function history() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/patient-medication/chart/history/patient-medication-history.html',
                controller: 'PatientMedicationHistoryNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles modal-bottom-xl',
                animation: false,
                size: 'lg',
                resolve: {
                    chart: vm.chart
                }
            })
        }

        function edit(patientMedicationId) {
            PopupService.showPatientMedicationPopup(vm.chart, null, patientMedicationId).result.then(function (data) {
                loadAll();
            });
        }

        function changeOnlyChart(id) {
            PatientMedication.changeOnlyChart(id,
                function () {
                    loadAll();
                    toastr.success("The Only In Chart was changed.");
                }, function () {
                    toastr.error("The Only In Chart wasn\'t changed.");
                });
        }
    }
})();

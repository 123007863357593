(function () {
        'use strict';

        angular
            .module('bluebookApp')
            .controller('PatientResultAbnormalController', PatientResultAbnormalController);

        PatientResultAbnormalController.$inject = ['CoreService', 'PatientResult', '$q', 'PatientResultDet', 'moment',
            '$rootScope', '$uibModal', 'GUIUtils', 'DataTablesService', 'GenericEntityDatatableService', 'PopupService'];

        function PatientResultAbnormalController(CoreService, PatientResult, $q, PatientResultDet, moment,
                                                 $rootScope, $uibModal, GUIUtils, DataTablesService,
                                                 GenericEntityDatatableService, PopupService) {
            var vm = this;

            var params = {
                facilityId: CoreService.getCurrentFacility().id,
            }

            vm.descriptor = {
                title: 'Abnormal Lab Results',
                entityClassHumanized: 'Abnormal Lab Results',
                entityStateName: 'patient-result',
                service: PatientResult,
                serviceMethod: 'abnormal',
                params: params,
                pagination: 'remote',
                newAction: false,
                options: [
                    {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
                ],
                selectionColumn: 'id',
                withSelectionColumn: true,
                disableSelectionColumn: function (filter) {
                    return filter.acknowledge !== false;
                },
                disableAllSelectionColumn: function (filter) {
                    return filter.acknowledge !== false;
                },
                columns: [
                    {
                        name: 'id',
                        title: 'ID',
                    },
                    {
                        name: 'patientName',
                        title: 'Patient Name',
                    },
                    {
                        name: 'dob',
                        title: 'DOB',
                        render: function (data) {
                            return moment(data.dob).format("MM/DD/YYYY");
                        }
                    },
                    {
                        name: null,
                        title: 'Barcode',
                        render: function (data) {
                            return data.accessionNumber ? data.accessionNumber : '';
                        }
                    },
                    {
                        name: null,
                        title: 'Collection Date',
                        render: function (data) {
                            return moment(data.collectionDate).format("MM/DD/YYYY");
                        }
                    },
                    {
                        name: null,
                        title: 'Received Date',
                        render: function (data) {
                            return moment(data.lastModifiedDate).format("MM/DD/YYYY");
                        }
                    },
                    {
                        name: 'testName',
                        title: 'Test Name'
                    },
                    {
                        name: 'testCode',
                        title: 'Test Code'
                    },
                    {
                        name: null,
                        title: 'Result',
                        render: function (data) {
                            return GUIUtils.getStatusTemplate(data.result + ' ' + data.status, 'danger');
                        }
                    },
                    {
                        name: 'status',
                        title: 'Status',
                        render: function (data) {
                            if (data.ackAbnormal) {
                                return GUIUtils.getStatusTemplate("Acknowledge", 'success');
                            } else {
                                return GUIUtils.getStatusTemplate("Unacknowledged", 'danger');
                            }
                        }
                    },
                    {
                        name: null,
                        title: 'Assigned',
                        render: function (data) {
                            return GUIUtils.colorHtmlYesNo(data.chartId ? true : false);
                        }
                    }
                ],
                rowActions: [
                    angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                        action: function (data) {
                            details(data.patientResultId);
                        },
                    }),
                    angular.extend({}, GenericEntityDatatableService.getViewPdfAction(), {
                        action: function (data) {
                            printRangePDFs(data.patientResultId);
                        },
                    })
                ],
                leftActions: [
                    {
                        type: 'group',
                        buttons: [
                            angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                                name: 'Acknowledge Selected',
                                disabledOnEmpty: true,
                                ngClass: function () {
                                    return ['bg-green']
                                },
                                action: function (filter, selected) {
                                    if (selected.length > 0) {
                                        return acknowledge(selected);
                                    }
                                },
                                ngIf: function (filter) {
                                    return filter.acknowledge === false;
                                }
                            }),
                        ]
                    },
                ],
                actions: [
                    {
                        type: 'group',
                        buttons: [
                            {
                                name: 'All Results',
                                iClass: 'fa fa-flask',
                                ngClass: function (filter) {
                                    return filter.acknowledge === null ?
                                        ['active', 'bg-green'] :
                                        [];
                                },
                                action: function (filter) {
                                    filter.acknowledge = null;
                                }
                            },
                            {
                                name: 'Acknowledge',
                                iClass: 'fa fa-spinner',
                                ngClass: function (filter) {
                                    return filter.acknowledge ?
                                        ['active', 'bg-green'] :
                                        [];
                                },
                                action: function (filter) {
                                    filter.acknowledge = true;
                                }
                            },
                            {
                                name: 'Unacknowledged',
                                iClass: 'fa fa-circle-o',
                                ngClass: function (filter) {
                                    return filter.acknowledge === false ?
                                        ['active', 'bg-red'] :
                                        [];
                                },
                                action: function (filter, selected) {
                                    filter.acknowledge = false;
                                }
                            }
                        ],
                    },
                ],
                filters: [
                    {
                        name: 'acknowledge',
                        visible: false,
                        defaultValue: function () {
                            return false;
                        }
                    }
                ]
            }

            function acknowledge(selected) {
                var abnormalResults = _.map(selected, function (item) {
                    return item.id;
                });

                return $q(function (resolve) {
                    PatientResultDet.acknowledgeAbnormal({ids: abnormalResults}, function () {
                        resolve();
                    });
                });
            }

            function printRangePDFs(id) {
                var ids;

                if (id) {
                    ids = [id];
                } else {
                    ids = getSelectedIds();
                }

                if (ids.length > 0) {
                    PatientResult.getAllInOneRangePdf({ids: ids});
                }
            }

            function details(id) {
                PopupService.showPatientResultDet(id);
            }
        }
    }
)();

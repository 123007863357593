(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('AutoFax', AutoFax);

    AutoFax.$inject = ['$resource'];

    function AutoFax ($resource) {
        var resourceUrl =  'api/auto-faxes/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByFacility': {url: 'api/auto-faxes/facility/:id', method: 'GET', isArray: true}
        });
    }
})();

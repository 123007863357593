(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('MessageHistory', MessageHistory);

    MessageHistory.$inject = ['$resource'];

    function MessageHistory ($resource) {
        var resourceUrl =  'api/message-histories/:id';

        return $resource(resourceUrl, {}, {
            'filter': {
                url: 'api/message-histories/filter',
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();

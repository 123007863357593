(function (){
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AppointmentsController', AppointmentsController);

    AppointmentsController.$inject = ['$uibModal'];

    function AppointmentsController($uibModal) {
        var vm = this;

        vm.title = 'Appointments';
        vm.createAppointment = createAppointment;

        vm.appointmentFilters = {
            inLobby: 0,
            inRoom: 0,
            canceled: 0,
            noShow: 0,
            seen: 0,
            pendingArrival: 0
        };


        vm.calendarConfig = {
            calendar: {
                defaultView: 'agendaWeek',
                height: 700,
                minTime: '07:00:00',
                maxTime: '18:00:00',
                editable: true,
                selectable: true,
                contentHeight: 'auto',
                header: {
                    left: 'title',
                    // center: 'title',
                    right: 'prev,today,next agendaWeek,agendaDay list'
                },
                timeFormat: 'h:mm a',
                weekNumbers: true,
                businessHours: [
                    {
                        dow: [1, 2, 3, 4, 5],
                        start: '08:00',
                        end: '17:00'
                    }
                ],
                events: [
                    {
                        title: 'Albert Einstein',
                        start: '2019-11-18T10:00:55.008',
                        end: '2019-11-19T04:00:55.008',
                        className: 'in-room',
                        description: 'This a cool event!',
                        status: 'In Room',
                        type: 'Wellness Exam'
                    },
                    {
                        title: 'Rene Descartes',
                        start: '2019-11-19T09:00:55-0400',
                        end: '2019-11-19T12:00:55-0400',
                        className: 'in-lobby',
                        description: 'Lorem Ipsum Dollor sit amet',
                        status: 'In Lobby',
                        type: 'Lorem Ipsum'
                    },
                    {
                        title: 'Smith Vatherman',
                        start: '2019-11-23T07:00:00-0400',
                        end: '2019-11-23T10:00:00-0400',
                        className: 'cancelled',
                        description: 'Lorem Ipsum Dollor',
                        status: 'Cancelled',
                        type: 'Drugs Exam'
                    },
                    {
                        title: 'Michelle Carmant',
                        start: '2019-11-22T09:07:00-0400',
                        end: '2019-11-22T10:15:00-0400',
                        className: 'no-show',
                        description: 'Hippotoxotas assimilant',
                        status: 'No Show',
                        type: 'Meeting'
                    },
                    {
                        title: 'Amelie Toulousse',
                        start: '2019-11-21T06:00:00-0400',
                        end: '2019-11-21T08:45:00-0400',
                        className: 'seen',
                        description: 'Messors tolerare',
                        status: 'Seen',
                        type: 'Wellness Exam'
                    },
                    {
                        title: 'Charlotte de Witte',
                        start: '2019-11-22T09:07:00-0400',
                        end: '2019-11-22T10:15:00-0400',
                        className: 'pending-arrival',
                        description: 'Elevatuss credere.',
                        status: 'Pending Arrival',
                        type: 'Meeting'
                    }
                ],
                eventRender: function(eventObj, $elem) {
                    $elem
                        .find('.fc-time')
                        .append("<strong class='fc-event-status'>[" + eventObj.status + "]</strong>");
                    $elem
                        .find('.fc-title')
                        .append("<strong class='fc-event-status'> [" + eventObj.type + "]</strong>");
                    $elem
                        .find('.fc-content')
                        .append("<div class='fc-desc'>" + eventObj.description + "</div>");
                },
                /*
                * - dayClick event --> https://fullcalendar.io/docs/v3/dayClick
                * - date : holds a Moment for the clicked day. If an all-day area has been clicked, the moment will be ambiguously-timed. If a slot in the agendaWeek or agendaDay views has been clicked, date will have the slot’s time.
                * - jsEvent : holds the jQuery event with low-level information such as click coordinates.
                * - view : is set to the current View Object.
                * - Viw Object --> https://fullcalendar.io/docs/v3/view-object
                * */
                dayClick: function(date, jsEvent, view) {
                    $uibModal.open({
                        templateUrl: 'app/entities/appointments/appointments-new-dialog.html',
                        controller: 'AppointmentsNewDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        windowTopClass: 'custom-dialog-styles',
                        resolve: {

                        }
                    }).result.then(function(result) {

                    }, function () {

                    });
                }
            }
        };

        function createAppointment() {
            $uibModal.open({
                templateUrl: 'app/entities/appointments/appointments-new-dialog.html',
                controller: 'AppointmentsNewDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {

                }
            }).result.then(function(result) {

            }, function () {

            });
        }
    }
})();

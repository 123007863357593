(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AddLabRequisitionResultDialogController', AddLabRequisitionResultDialogController);

    AddLabRequisitionResultDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'data', 'PatientResult', 'LabCompendium', 'patientOrder'];

    function AddLabRequisitionResultDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                                     data, PatientResult, LabCompendium, patientOrder) {
        var vm = this;

        vm.data = data;
        vm.results = [];
        vm.abnormal = false;
        vm.patientOrder = patientOrder;

        vm.clear = clear;
        vm.save = save;
        vm.addOther = addOther;
        vm.remove = remove;
        vm.getLabCompendium = getLabCompendium;

        LabCompendium.findAllByBarcode({barcode: data.barcode}, function (result) {
            vm.labCompendiums = result;

            _.forEach(result, function (labCompendium) {
                vm.results.push({labCompendium: labCompendium, abnormal: false, disabled: true})
            })
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            var patientResultDets = _.map(vm.results, function(result) {
                return {
                    testCode: result.labCompendium.code,
                    abnormal: result.abnormal,
                    result: result.result
                }
            });

            PatientResult.add({accessionNumber: data.barcode, resultDets: patientResultDets}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function addOther() {
            vm.results.push({});
        }

        function remove(index) {
            vm.results.splice(index, 1);
        }

        function getLabCompendium(query) {
            if (query && query.length > 1) {
                LabCompendium.filter({
                    laboratoryId: vm.patientOrder.facilityLab.laboratoryId ,
                    searchQuery: query,
                    page: 0,
                    size: 40,
                    sort: 'code,asc',
                    facilityId: vm.patientOrder.chart.facilityId
                }, function (result) {
                    vm.labCompendiums = result;
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BedController', BedController);

    BedController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Bed', '$uibModal', 'ConfirmationService', '$q'];

    function BedController(GenericEntityDatatableService, CoreService, Bed, $uibModal, ConfirmationService, $q) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Beds',
            newButtonTitle: 'New Bed',
            entityClassHumanized: 'Beds',
            entityStateName: 'bed',
            service: Bed,
            serviceMethod: 'getAllByFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Room',
                    render: function (data) {
                        return data.room.name + " [" + data.room.sex + "]";
                    }
                },
                {
                    name: 'status',
                    title: 'Status',
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Bed',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/bed/upload/bed-dialog.html',
                controller: 'BedDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            notes: null,
                            status: null,
                            actualChart: null,
                            facility: CoreService.getCurrentFacility(),
                            id: null
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/bed/upload/bed-dialog.html',
                controller: 'BedDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Bed', function (Bed) {
                        return Bed.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Bed').result.then(function () {
                    Bed.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('DashboardService', DashboardService);

    DashboardService.$inject = ['$resource'];

    function DashboardService($resource) {
        var resourceUrl = 'api/evaluations/:id';

        function transformCountResponse(data) {
            if (data) {
                return {count: data};
            }
            return {count: 0};
        }

        return $resource(resourceUrl, {}, {
            'countWithoutDiagnosis': {
                url: 'api/charts/count-without-diagnosis/:id', method: 'GET', isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countWithoutInsurance': {
                url: 'api/charts/count-without-insurance/:id', method: 'GET', isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countWithoutCareTeam': {
                url: 'api/charts/count-without-care-team/:id', method: 'GET', isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countWithoutConcurrentReview': {url: 'api/charts/count-without-concurrent/:id', method: 'GET', isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countExpiredConcurrentReview': {url: 'api/charts/count-expired-concurrent/:id', method: 'GET', isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countExpiringConcurrentReview': {url: 'api/charts/count-expiring-concurrent/:id', method: 'GET', isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data) {
                        return {value: data};
                    }
                    return {value: 0};
                }
            },
            'countAllByFilter': {url: 'api/evaluations/count-all-by-filter', method: 'GET',  isArray: false,
                ignoreLoadingBar: true,
                transformResponse: transformCountResponse
            },
            'countAllPendingByStaffFilter': {url: 'api/evaluations/count-all-pending-by-staff-filter', method: 'GET',  isArray: false,
                ignoreLoadingBar: true,
                transformResponse: transformCountResponse
            }
        });
    }
})();

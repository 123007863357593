(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Laboratory', Laboratory);

    Laboratory.$inject = ['$resource'];

    function Laboratory ($resource) {
        var resourceUrl =  'api/laboratories/:id';

        return $resource(resourceUrl, {}, {
            'filter': { url: "api/laboratories/filter", method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllWithoutRequestByFacility': {url: 'api/laboratories/find-all-without-request-by-facility/:id', method: 'GET', isArray: true},
            'findAllWithoutConsent': {url: 'api/laboratories/find-all-without-consent/:id', method: 'GET', isArray: true}
        });
    }
})();

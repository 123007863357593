(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TypeSpecialityController', TypeSpecialityController);

    TypeSpecialityController.$inject = ['GenericEntityDatatableService', 'CoreService', 'TypeSpeciality',
        '$q', '$uibModal', 'ConfirmationService'];

    function TypeSpecialityController(GenericEntityDatatableService, CoreService, TypeSpeciality,
                                      $q, $uibModal, ConfirmationService) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Speciality Types',
            newButtonTitle: 'New Speciality',
            entityClassHumanized: 'Speciality Types',
            entityStateName: 'type-speciality',
            service: TypeSpeciality,
            serviceMethod: 'byCorporation',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Speciality',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/type-speciality/update/type-speciality-dialog.html',
                controller: 'TypeSpecialityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            description: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/type-speciality/update/type-speciality-dialog.html',
                controller: 'TypeSpecialityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['TypeSpeciality', function (TypeSpeciality) {
                        return TypeSpeciality.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Speciality').result.then(function () {
                    TypeSpeciality.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

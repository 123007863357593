(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityLabNewDialogController', FacilityLabNewDialogController);

    FacilityLabNewDialogController.$inject = ['$scope', '$uibModalInstance', 'FacilityLab', 'Facility', 'Laboratory',
        'CoreService'];

    function FacilityLabNewDialogController($scope, $uibModalInstance, FacilityLab, Facility, Laboratory,
                                            CoreService) {
        var vm = this;

        vm.facility = CoreService.getCurrentFacility();

        vm.laboratories = Laboratory.findAllWithoutRequestByFacility({
            id: vm.facility.id
        });
        vm.laboratoriesSelected = [];

        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            FacilityLab.create({
                laboratories: vm.laboratoriesSelected,
                facility: {id: vm.facility.id}
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:facilityLabUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();

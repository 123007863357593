(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillViewController', SuperBillViewController);

    SuperBillViewController.$inject = ['entity', 'Chart', 'Procedure', 'ProcedureDiagnosis', 'ProcedureModifier',
        'dialogs', 'CptSearch', '$uibModal', 'Diagnoses', 'Icd10Search', 'ModifierSearch',
        'ProClaimConf', 'SuperBill', '$state', 'SuperBillDiagnosis'];

    function SuperBillViewController(entity, Chart, Procedure, ProcedureDiagnosis, ProcedureModifier,
                                     $dialogs, CptSearch, $uibModal, Diagnoses, Icd10Search, ModifierSearch,
                                     ProClaimConf, SuperBill, $state, SuperBillDiagnosis) {
        var vm = this;

        vm.superBill = entity;
        vm.chart = null;
        vm.procedures = [];
        vm.cpts = [];
        vm.superBillDiagnoses = [];
        vm.tpl = 'app/entities/procedure/tpl/procedure-form-professional.html';
        vm.isProfessional = true;

        vm.removeProcedureModifier = removeProcedureModifier;
        vm.clone = clone;
        vm.onDragEnd = onDragEnd;
        vm.removeProcedureDiagnoses = removeProcedureDiagnoses;
        vm.removeCpt = removeCpt;
        vm.changeProcedure = changeProcedure;
        vm.getCpts = getCpts;
        vm.editProvider = editProvider;
        vm.addProcedure = addProcedure;
        vm.editProcedure = editProcedure;
        vm.addProcedure = addProcedure;
        vm.delProcedure = delProcedure;

        vm.icd10Selected = [];
        vm.icd10SearchQuery = '';
        vm.modifierSearchQuery = '';
        vm.modifierSelected = [];
        vm.lastsIcd10ByFacility = [];
        vm.lastsModifiersByFacility = [];
        vm.onSelectIcd10 = onSelectIcd10;
        vm.renderIcd10 = renderIcd10;
        vm.getIcd10Options = getIcd10Options;
        vm.getIcd10FrequentlyOptions = getIcd10FrequentlyOptions;
        vm.onSelectModifier = onSelectModifier;
        vm.renderModifier = renderModifier;
        vm.getModifierOptions = getModifierOptions;
        vm.getModifierFrequentlyOptions = getModifierFrequentlyOptions;
        vm.save = save;

        vm.icd10TabsConf = [
            {
                name: 'Search Results',
                icon: 'fa fa-list',
                func: getIcd10Options,
                options: []
            },
            {
                name: 'Frequently added',
                icon: 'fa fa-clock-o',
                func: getIcd10FrequentlyOptions,
                options: []
            }
        ];

        vm.modifierTabsConf = [
            {
                name: 'Search Results',
                icon: 'fa fa-list',
                func: getModifierOptions,
                options: []
            },
            {
                name: 'Frequently added',
                icon: 'fa fa-clock-o',
                func: getModifierFrequentlyOptions,
                options: []
            }
        ];

        init();

        function init() {
            Chart.get({id: vm.superBill.chartId}, function (result) {
                vm.chart = result;
            });

            if (vm.superBill.billingType !== 'PROFESSIONAL') {
                vm.tpl = 'app/entities/procedure/tpl/procedure-form-institutional.html';
                vm.isProfessional = false;
            }

            Procedure.getProcedures({id: vm.superBill.id}, function (result) {
                vm.procedures = result;

                _.forEach(vm.procedures, function (procedure) {
                    ProcedureDiagnosis.findAllByProcedure({id: procedure.id}, function (procedureDiagnosis) {
                        procedure.diagnoses = procedureDiagnosis;
                    });

                    ProcedureModifier.findAllByProcedure({id: procedure.id}, function (procedureModifier) {
                        procedure.modifiers = procedureModifier;
                    })
                })
            });
        }

        function clone(index) {
            var newProcedure = angular.copy(vm.procedures[index]);
            newProcedure.id = null;
            newProcedure.diagnoses.forEach(function (el) {
                el.id = null;
            });
            newProcedure.modifiers.forEach(function (el) {
                el.id = null;
            });
            vm.procedures.push(newProcedure);
        }

        function onDragEnd(list) {
            for (var i = 0; i < list.length; i++) {
                list[i].xorder = i + 1;
            }
        }

        function removeProcedureModifier(modifierList, indexModifier) {
            var dialog = $dialogs.confirm('Please Confirm', 'Are you sure you want to remove this Procedure Modifier?', {size: 'md'});
            dialog.result.then(function () {
                _.pullAt(modifierList, indexModifier);
            });
        }

        function removeProcedureDiagnoses(diagnosesList, indexDiagnoses) {
            var dialog = $dialogs.confirm('Please Confirm', 'Are you sure you want to remove this Procedure Diagnose?', {size: 'md'});
            dialog.result.then(function () {
                _.pullAt(diagnosesList, indexDiagnoses);
            });
        }

        function getCpts(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.cpts = CptSearch.query({query: query});
            }
        }

        function changeProcedure(index, cpt) {
            if (cpt) {
                vm.procedures[index].cpt = cpt;
            }
        }

        function removeCpt(procedureIndex) {
            var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to remove this Cpt?', {size: 'md'});
            dlg.result.then(function () {
                vm.procedures[procedureIndex].cpt = null;
                vm.cpts.selected = undefined;
            });
        }

        function editProvider() {
            $uibModal.open({
                templateUrl: 'app/entities/super-bill/super-bill-provider-dialog.html',
                controller: 'SuperBillProviderDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: vm.superBill
                }
            }).result.then(function () {
            }, function () {
            });
        }

        function onSelectIcd10(prod) {
            _.forEach(vm.icd10Selected[prod.id], function (icd10) {
                if (!_.find(prod.diagnoses, function (o) {
                    return o.icd10.id === icd10.id;
                })) {
                    prod.diagnoses.push({
                        id: null,
                        icd10: icd10,
                        xorder: prod.diagnoses.length + 1
                    })
                }
            });
        }

        function renderIcd10(element) {
            if (element) {
                return '<strong>' + element['code'] + '</strong> -' + element['description'];
            }
        }

        function getIcd10Options(searchQuery, tab) {
            if (searchQuery && searchQuery.length > 2 && searchQuery !== vm.icd10SearchQuery) {
                Icd10Search.query({query: searchQuery}, function (result) {
                    tab.options = result;
                    vm.icd10SearchQuery = searchQuery;
                });
            }
        }

        function getIcd10FrequentlyOptions(searchQuery, tab) {
            if (!vm.lastsIcd10ByFacility.length > 0) {
                Diagnoses.lastsIcd10UsedByFacility({facilityId: vm.chart.facilityId}, function (icd10s) {
                    vm.lastsIcd10ByFacility = icd10s;
                    tab.options = icd10s;
                });
            }
        }

        function onSelectModifier(prod) {
            _.forEach(vm.modifierSelected[prod.id], function (modifier) {
                if (!_.find(prod.modifiers, function (o) {
                    return o.modifier.id === modifier.id;
                })) {
                    prod.modifiers.push({
                        id: null,
                        modifier: modifier,
                        xorder: prod.modifiers.length + 1
                    })
                }
            });
        }

        function renderModifier(element) {
            if (element) {
                return '<strong>' + element['code'] + '</strong> -' + element['shortDesc'];
            }
        }

        function getModifierOptions(searchQuery, tab) {
            if (searchQuery && searchQuery.length > 0 && searchQuery !== vm.modifierSearchQuery) {
                searchQuery = _.lowerCase(searchQuery);
                ModifierSearch.query({query: searchQuery}, function (result) {
                    tab.options = result;
                    vm.modifierSearchQuery = searchQuery;
                });
            }
        }

        function getModifierFrequentlyOptions(searchQuery, tab) {
            if (!vm.lastsModifiersByFacility.length > 0) {
                ProClaimConf.lastsModifiersUsedByFacility({facilityId: vm.chart.facilityId}, function (modifiers) {
                    vm.lastsModifiersByFacility = modifiers;
                    tab.options = modifiers;
                });
            }
        }

        function save() {
            vm.isSaving = true;
            var procedures = []

            vm.procedures.forEach(function (procedure) {
                procedures.push({
                    'procedure': procedure,
                    'diagnoses': procedure.diagnoses,
                    'modifiers': procedure.modifiers
                });
            });

            SuperBill.update({
                superBill: vm.superBill,
                procedures: procedures
            }, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go("chart-new", {id: result.chartId});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function addProcedure() {
            $uibModal.open({
                templateUrl: vm.tpl,
                controller: 'ProcedureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    superBill: vm.superBill,
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            }).result.then(function () {
                init();
            });
        }

        function editProcedure(id) {
            $uibModal.open({
                templateUrl: vm.tpl,
                controller: 'ProcedureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    superBill: vm.superBill,
                    entity: ['Procedure', function (Procedure) {
                        return Procedure.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                init();
            });
        }

        function delProcedure(id) {
            $uibModal.open({
                templateUrl: 'app/entities/procedure/procedure-delete-dialog.html',
                controller: 'ProcedureDeleteController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Procedure', function (Procedure) {
                        return Procedure.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                init();
            });
        }
    }
})();

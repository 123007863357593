(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('reports', {
                parent: 'entity',
                url: '/reports',
                data: {
                    authorities: [ROLES.ROLE_USER],
                    pageTitle: 'Reports'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report/list/report.html',
                        controller: 'ReportController',
                        controllerAs: 'vm'
                    }
                },
            })
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProgEligCorpDeleteController',ProgEligCorpDeleteController);

    ProgEligCorpDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProgEligCorp'];

    function ProgEligCorpDeleteController($uibModalInstance, entity, ProgEligCorp) {
        var vm = this;

        vm.progEligCorp = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProgEligCorp.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EvaluationTemplateMarsMors', EvaluationTemplateMarsMors);

    EvaluationTemplateMarsMors.$inject = ['$resource'];

    function EvaluationTemplateMarsMors ($resource) {
        var resourceUrl =  'api/evaluation-template-mars-mors/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'byFacility': {method: 'GET', url: 'api/evaluation-template-mars-mors/by-facility/:id', isArray: true},
        });
    }
})();

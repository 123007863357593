(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmTagDeleteController',CrmTagDeleteController);

    CrmTagDeleteController.$inject = ['$uibModalInstance', 'entity', 'CrmTag'];

    function CrmTagDeleteController($uibModalInstance, entity, CrmTag) {
        var vm = this;

        vm.crmTag = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CrmTag.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SampleDialogController', SampleDialogController);

    SampleDialogController.$inject = ['$uibModalInstance'];

    function SampleDialogController($uibModalInstance) {
        var vm = this;

        vm.title = "Sample Title Detail";

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

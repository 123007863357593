(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('facility-icd-10', {
            parent: 'entity',
            url: '/facility-icd-10',
            data: {
                authorities: [],
                pageTitle: 'FacilityIcd10S'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/facility-icd-10/list/facility-icd-10.html',
                    controller: 'FacilityIcd10Controller',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                    var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_FACILITY_ICD10];
                    return Auth.loadActionsAccess(actions).$promise;
                }],
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_FACILITY_ICD10, access);
                }]
            }
        });
    }
})();

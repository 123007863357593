(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .controller('LabOrderCreateDialogController', LabOrderCreateDialogController);

    LabOrderCreateDialogController.$inject = ['$uibModal', '$uibModalInstance', 'filter', 'chart', 'patientOrder', 'DateUtils', 'Chart', 'facility', 'ParseLinks',
        'WizardHandler', 'TypePaymentMethods', 'CountryState', 'GUIUtils', 'CoreService',
        'InsuranceCarrierSearch', 'InsuranceRelation', 'InsuranceType', 'Via',
        'Relational', 'FacilityLab', 'BUSINESS_ROLES', 'Icd10Search',
        'PatientOrder', 'LabCompendium', 'Diagnoses', 'Employee', 'Insurance', 'TypeRace',
        'TypeEthnicity', 'DYMO', '$scope', 'RATIONALE_TYPE', 'itHasCredentials', 'toastr'];

    function LabOrderCreateDialogController($uibModal, $uibModalInstance, filter, chart, patientOrder, DateUtils, Chart, facility, ParseLinks,
                                            WizardHandler, TypePaymentMethods, CountryState, GUIUtils, CoreService,
                                            InsuranceCarrierSearch, InsuranceRelation, InsuranceType, Via,
                                            Relational, FacilityLab, BUSINESS_ROLES, Icd10Search,
                                            PatientOrder, LabCompendium, Diagnoses, Employee, Insurance, TypeRace,
                                            TypeEthnicity, DYMO, $scope, RATIONALE_TYPE, itHasCredentials, toastr) {

        var vm = this;
        vm.chart = chart;
        vm.charts = [];
        vm.filter = filter;
        vm.facility = facility;
        vm.showChartList = false;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateBirth = false;
        vm.datePickerOpenStatus.insuranceDob = false;
        vm.chartSelected = false;
        vm.labOrderTitle = 'Lab Order';
        vm.getStaredTitle = 'Get Started';

        vm.page = 1;
        vm.itemsPerPage = 5;

        vm.patientOrder = patientOrder;
        vm.activeIcd10 = [];

        vm.patientOrderTest = {
            id: null,
            staringDate: null,
            endDate: null,
            icd10s: angular.copy(vm.activeIcd10),
            labCompendium: null,
            orderFrequency: null
        };

        vm.pin = "";
        vm.icd10s = [];
        vm.agree = false;
        vm.signature = "";
        vm.diagnoses = angular.copy(vm.activeIcd10);
        vm.employees = [];
        vm.hideTest = false;
        vm.frequency = null;
        vm.credentials = [];
        vm.via = Via.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.today = new Date();
        vm.labCompendiums = [];
        vm.activateSign = false;
        vm.itHasCredentials = itHasCredentials.value;
        vm.testCodes = [];
        vm.currentEmployee = CoreService.getCurrentEmployee();
        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.endDateOptions = {};
        vm.staringDateOptions = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.staringDate = false;

        vm.relationals = Relational.findAllByFilter(getFilterByCorporationAndType());
        vm.laboratories = [];

        FacilityLab.filter({facilityId: vm.facility.id, page: 0, size: 1000, enabled: true}, function (result) {
            vm.facilityLabs = result;
        });

        vm.staringDateOptions = {
            minDate: new Date()
        };

        vm.cancel = cancel;
        vm.save = save;
        vm.search = search;
        vm.clearFilter = clearFilter;
        vm.getChartsPage = getChartsPage;
        vm.filterNextStep = filterNextStep;
        vm.activateLeftArrow = activateLeftArrow;
        vm.addSecondaryInsurance = addSecondaryInsurance;
        vm.clearPrimaryInsurance = clearPrimaryInsurance;
        vm.removeSecondaryInsurance = removeSecondaryInsurance;
        vm.setValidationClass = setValidationClass;
        vm.disableInsuranceSteps = disableInsuranceSteps;
        vm.getInsuranceCarrier = getInsuranceCarrier;
        vm.openCalendar = openCalendar;
        vm.isLastStep = isLastStep;

        vm.generateTest = generateTest;
        vm.getDiagnoses = getDiagnoses;
        vm.validateEndDate = validateEndDate;
        vm.changeOrderType = changeOrderType;
        vm.getLabCompendium = getLabCompendium;
        vm.changeLaboratory = changeLaboratory;
        vm.removeRelational = removeRelational;
        vm.selectRelational = selectRelational;
        vm.getEmployees = getEmployees;

        credentialsPermits();

        function loadDiagnosesByChart() {
            if (vm.chart.id) {
                vm.activeIcd10 = [];
                vm.patientOrder.patientOrderTests = [];
                Diagnoses.findAllActiveDiagnosesByChartId({id: vm.chart.id}, function (diagnoses) {
                    _.forEach(diagnoses, function (element) {
                        vm.activeIcd10.push(element.icd10);

                        vm.patientOrderTest.icd10s = angular.copy(vm.activeIcd10);
                    })
                });
            }
        }

        $scope.$watch('vm.chartSelected', function () {
            if (vm.chartSelected === true) {
                var steps = WizardHandler.wizard('createLabOrderWizard').getEnabledSteps();
                _.forEach(steps, function (step) {
                    if (step.title === vm.getStaredTitle) {
                        step.completed = true;
                        loadDiagnosesByChart()
                    }
                });
                WizardHandler.wizard('createLabOrderWizard').goTo(vm.labOrderTitle);
            }
        });

        $scope.$on('wizard:stepChanged', function (event, args) {
            if (args.index === 0) {
                vm.chartSelected = false;
            }
        });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            getFilter();
        }

        function checkVoidParam(param) {
            if (!param || param === '') {
                param = null;
            }
        }

        function clearFilter() {
            vm.filter.firstName = null;
            vm.filter.lastName = null;
            vm.filter.dob = null;
            vm.filter.social = null;
            vm.filter.preferredName = null;
            vm.filter.middleName = null;
        }

        function getChartsPage(value) {
            if (value === 0) {
                vm.page++;
                getFilter();
            } else {
                vm.page--;
                getFilter();
            }
        }

        function getFilter() {
            var filter = angular.copy(vm.filter);

            filter.statuses = ['CURRENT'];
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.facilityId = vm.facility.id;

            if (filter.dob) {
                filter.dob = DateUtils.convertLocalDateToServer(filter.dob);
            } else {
                checkVoidParam(filter.dob);
            }

            checkVoidParam(filter.firstName);
            checkVoidParam(filter.middleName);
            checkVoidParam(filter.lastName);
            checkVoidParam(filter.preferredName);
            checkVoidParam(filter.social);

            Chart.filterLastClosedOrArchive(filter, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.charts = data;
                vm.showChartList = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function filterNextStep(item) {
            if (item == null) {
                vm.chart = chart;
                vm.disabledInsuranceSteps = true;
                vm.primaryInsurance = null;
                vm.secondaryInsurance = null;

                disableInsuranceSteps();
                WizardHandler.wizard('createLabOrderWizard').next();
            } else {
                Chart.get({id: item.id}, function (result) {
                    vm.chart = result;
                    vm.chartSelected = true;

                    if (vm.chart.primary) {
                        Insurance.get({id: vm.chart.primary.id}, function (insurance) {
                            insurance.id = null;

                            vm.primaryInsurance = insurance;
                        })
                    }

                    if (vm.chart.secondary) {
                        Insurance.get({id: vm.chart.secondary.id}, function (insurance) {
                            insurance.id = null;

                            vm.secondaryInsurance = insurance;
                        })
                    }

                    disableInsuranceSteps();
                })
            }

            vm.gender = GUIUtils.getGender();
            vm.states = CountryState.query();
            vm.race = TypeRace.byCorporation(CoreService.getFilterByCurrentCorporation());
            vm.ethnicity = TypeEthnicity.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
            TypePaymentMethods.byFacility(CoreService.getFilterByFacility(vm.facility), function (result) {
                vm.paymentMethod = result;
            });
            vm.insuranceCarriers = [];
            vm.insuranceTypes = InsuranceType.byCorporation(CoreService.getFilterByCurrentCorporation());
            vm.insuranceRelations = InsuranceRelation.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        }

        function activateLeftArrow() {
            return vm.totalItems / vm.itemsPerPage > vm.page
        }

        function addSecondaryInsurance() {
            vm.secondaryInsurance = {};
        }

        function clearPrimaryInsurance() {
            vm.primaryInsurance = null;
            vm.primaryInsurance = vm.secondaryInsurance;
            vm.secondaryInsurance = null;
        }

        function removeSecondaryInsurance() {
            WizardHandler.wizard('createLabOrderWizard').previous();

            vm.secondaryInsurance = null;
        }

        function setValidationClass(elem) {
            if (elem.$invalid) {
                return 'has-error'
            } else if (elem.$touched || elem.$dirty) {
                return 'has-success'
            } else {
                return ''
            }
        }

        function save() {
            vm.isSaving = true;

            if (vm.primaryInsurance) {
                vm.primaryInsurance.insuranceOrder = 1;
            }

            if (vm.secondaryInsurance) {
                vm.secondaryInsurance.insuranceOrder = 2;
            }

            var chart = angular.copy(vm.chart);
            chart.primary = vm.primaryInsurance;
            chart.secondary = vm.secondaryInsurance;

            var req = angular.extend({}, vm.patientOrder, {
                pin: vm.pin,
                signature: vm.signature,
                chart: chart
            });

            _.forEach(req.patientOrderTests, function (result) {
                result.staringDate = moment(result.staringDate).format('YYYY-MM-DD');
                result.endDate = moment(result.staringDate).format('YYYY-MM-DD');
            });

            if (req.patientOrderTests.length > 0) {
                PatientOrder.patientOrderChartCreate(req, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            PatientOrder.printAndManifest({
                facilityId: vm.facility.id,
                printBarcode:
                    _.map(result, function (printAndManifestDTO) {
                        return {
                            chartId: printAndManifestDTO.chartId,
                            patientOrderId: printAndManifestDTO.patientOrderId,
                            barcode: printAndManifestDTO.barcode
                        };
                    })
            }, function (data) {
                if (DYMO.loadPrinters()) {
                    _.forEach(data, function (label) {
                        DYMO.printPatientOrderLabel({
                            barcode: label.barcode,
                            name: label.firstName + (label.middleName ? label.middleName + " " : " ") + label.lastName,
                            dob: label.dateBirth,
                            collectedDate: label.scheduleDate,
                            tube: label.tube,
                            account: label.accountNo
                        });
                    });
                } else {
                    toastr.error('No DYMO printers are installed. Install DYMO printers.');
                }
                $uibModalInstance.close(true);
            }, function () {
                vm.isSaving = false;
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function disableInsuranceSteps() {
            vm.disabledInsuranceSteps = vm.chart.typePaymentMethods == null || vm.chart.typePaymentMethods.category !== 'Insurance';

            if (vm.disabledInsuranceSteps) {
                vm.primaryInsurance = null;
                vm.secondaryInsurance = null;
            }
        }

        function getInsuranceCarrier(query) {
            if (query) {
                if (query.length > 2) {
                    query = _.lowerCase(query);
                    InsuranceCarrierSearch.query({
                        query: query
                    }, function (result) {
                        if (result.length == 0) {
                            vm.carriers = [carrierTagTransform(query)];
                        } else {
                            vm.carriers = result;
                        }
                    });
                }
            }

        }

        function carrierTagTransform(newTag) {
            return {
                id: null,
                acctno: newTag,
                name: newTag
            };
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function isLastStep() {
            return WizardHandler.wizard('createLabOrderWizard').totalStepCount() === WizardHandler.wizard('createLabOrderWizard').currentStepNumber();
        }

        function validateEndDate() {
            vm.endDateOptions.minDate = vm.patientOrderTest.staringDate
        }

        function credentialsPermits() {
            if (vm.itHasCredentials) {
                vm.employees = [vm.currentEmployee];
                vm.patientOrder.signedBy = vm.currentEmployee;
                vm.patientOrder.signed = true;
            } else {
                vm.getEmployees();
            }
        }

        function getDiagnoses(query) {
            if (query && query.length > 1) {
                Icd10Search.query({query: query}, function (result) {
                    vm.diagnoses = result;
                });
            }
        }

        function generateTest() {
            vm.labCompendiums.forEach(function (element) {
                var testTemp = {
                    id: null,
                    labCompendium: element,
                    icd10s: vm.patientOrderTest.icd10s,
                    labCompendiumName: element.description,
                    staringDate: vm.patientOrderTest.staringDate,
                    relationals: angular.copy(vm.patientOrderTest.relationals),
                    orderFrequency: vm.patientOrderTest.orderFrequency,
                    endDate: vm.patientOrderTest.endDate ? vm.patientOrderTest.endDate : vm.patientOrderTest.staringDate
                };
                vm.patientOrder.patientOrderTests.push(testTemp);
            });

            clearTest();
        }

        function clearTest() {
            vm.labCompendiums = [];
            vm.patientOrderTest.id = null;
            //vm.patientOrderTest.icd10s = vm.activeIcd10;
            vm.patientOrderTest.labCompendium = null;
        }

        function changeOrderType() {
            PatientOrder.getAllOrderFrequency(function (results) {
                if (results) {
                    if (vm.patientOrder.orderType === 'ONE_TIME')
                        vm.orderFrequencies = _.filter(results, function (result) {
                            return result.id === 'ONCE';
                        });
                    else vm.orderFrequencies = results;
                }
            });
        }

        function getLabCompendium(query) {
            if (vm.patientOrder.facilityLab) {
                LabCompendium.filter({
                    laboratoryId: vm.patientOrder.facilityLab.labId,
                    searchQuery: query,
                    page: vm.page - 1,
                    size: 40,
                    sort: 'code,asc',
                    facilityId: vm.facility.id
                }, function (result, headers) {
                    vm.testCodes = result;
                });
            }
        }

        function changeLaboratory() {
            vm.patientOrder.patientOrderTests = [];
            vm.testCodes = [];
            vm.labCompendiums = [];

            vm.getLabCompendium();
        }

        function removeRelational(relationals, index) {
            relationals.splice(index, 1);
        }

        function selectRelational(patientOrderTest) {
            $uibModal.open({
                templateUrl: 'app/entities/relational/select/relational-select-dialog.html',
                controller: 'RelationalSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl ',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    selectedRelationals: function () {
                        return patientOrderTest.relationals;
                    }
                }
            }).result.then(function (result) {
                patientOrderTest.relationals = _.filter(vm.relationals, function (relational) {
                    return _.find(result, function (id) {
                        return id === relational.id.toString();
                    });
                });
            });
        }

        function getFilterByCorporationAndType() {
            var filter = {};

            filter.corporationId = CoreService.getCorporation().id;
            filter.relationalType = RATIONALE_TYPE.LABORATORY;

            return filter;
        }

        function getEmployees(query) {
            if (vm.itHasCredentials) {
                return;
            }

            var filter = {
                page: 0,
                size: 200000,
                query: query,
                facilityId: CoreService.getCurrentFacility().id,
                businessRole: BUSINESS_ROLES.PHYSICIAN
            };

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

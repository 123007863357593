(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
        .state('reconnect-care-credential', {
            parent: 'entity',
            url: '/reconnect-care-credential',
            data: {
                authorities: [ROLES.ROLE_SYSTEM, ROLES.ROLE_SYSTEM_ADMIN],
                pageTitle: 'ReconnectCareCredentials'
            },
            ncyBreadcrumb: {
                label: 'Reconnect Care Credential'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/reconnect-care-credential/list/reconnect-care-credential.html',
                    controller: 'ReconnectCareCredentialController',
                    controllerAs: 'vm'
                }
            }
        })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GroupSessionEditController', GroupSessionEditController);

    GroupSessionEditController.$inject = ['$uibModal', 'entity', 'GroupSession', 'SessionType', 'CoreService',
        'Employee', '$scope', '$state', 'GroupSessionDetails', 'Chart', 'Building', '$timeout',
        'PopupService', 'BUSINESS_ROLES', 'toastr', 'OdsFormService', '$rootScope', 'Assistant',
        'access', 'ACTIONS'];

    function GroupSessionEditController($uibModal, entity, GroupSession, SessionType, CoreService,
                                        Employee, $scope, $state, GroupSessionDetails, Chart, Building, $timeout,
                                        PopupService, BUSINESS_ROLES, toastr, OdsFormService, $rootScope, Assistant,
                                        access, ACTIONS) {
        var vm = this;

        vm.groupSession = entity;
        vm.employees = vm.groupSession.employees;
        vm.reviewers = [];
        vm.charts = [];
        vm.selectedCharts = [];
        vm.groupSessionDetails = [];
        vm.updates = {};
        vm.leaderSignature = null;
        vm.reviewerSignature = null;
        vm.allowSign = false;
        vm.allowReview = false;
        vm.watchers = [];

        vm.sessionTypes = SessionType.byCorporation(CoreService.getFilterByCurrentCorporation());
        vm.corporation = CoreService.getCorporation();
        vm.buildings = Building.byFacility({id: vm.groupSession.facilityId});
        vm.employee = CoreService.getCurrentEmployee();

        $rootScope.assistantList = Assistant.findAllBySection({
            section: 'GROUP_SESSION',
            facility: vm.groupSession.facilityId
        });
        $rootScope.showAssitant = true;
        vm.edit = access[ACTIONS.ACTION_MASTER_EDIT_GROUP_SESSION] ? access[ACTIONS.ACTION_MASTER_EDIT_GROUP_SESSION].roles.join() : null;

        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.getReviewers = getReviewers;
        vm.getCharts = getCharts;
        vm.addCharts = addCharts;
        vm.leaderSign = leaderSign;
        vm.reviewerSign = reviewerSign;
        vm.editSignature = editSignature;

        init();

        function init() {
            vm.employees = vm.groupSession.employees;
            if (vm.groupSession.reviewer) {
                vm.reviewers.push(vm.groupSession.reviewer);
            }

            vm.updates = {};
            vm.leaderSignature = null;
            vm.reviewerSignature = null;
            vm.allowSign = allowSign();
            vm.allowReview = allowReview();

            if (vm.groupSession && vm.groupSession.start) {
                vm.groupSession.start = moment(vm.groupSession.start).format('YYYY-MM-DDTHH:mm:00');
                vm.groupSession.start = moment(vm.groupSession.start).toDate();
            }

            if (vm.groupSession && vm.groupSession.stop) {
                vm.groupSession.stop = moment(vm.groupSession.stop).format('YYYY-MM-DDTHH:mm:00');
                vm.groupSession.stop = moment(vm.groupSession.stop).toDate();
            }

            if (vm.groupSession.leaderSignatureId) {
                initLeaderSignature();
            }

            if (vm.groupSession.reviewerSignatureId) {
                initReviewerSignature()
            }

            getGroupSessionDetail();
            getCharts();
        }

        function initLeaderSignature() {
            GroupSession.getSignature({id: vm.groupSession.id, type: 'STAFF'}, function (data) {
                vm.leaderSignature = data;
            });
        }

        function initReviewerSignature() {
            GroupSession.getSignature({id: vm.groupSession.id, type: 'REVIEW'}, function (data) {
                vm.reviewerSignature = data;
            });
        }

        function getGroupSessionDetail() {
            vm.groupSessionDetails = [];

            GroupSessionDetails.findAllByGroupSessionAndGroupSessionTemplate({id: vm.groupSession.id}, function (result) {
                vm.groupSessionDetails = result;
            });
        }

        function save() {
            vm.isSaving = true;
            if (vm.groupSession.id !== null) {
                GroupSession.update(vm.groupSession, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:groupSession', result);
            vm.isSaving = false;
            vm.groupSession = result;

            init();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, facilityId: vm.groupSession.facilityId};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getReviewers(query) {
            if (query && query.length > 3) {
                var filter = {
                    page: 0,
                    size: 10,
                    query: query,
                    facilityId: CoreService.getCurrentFacility().id,
                    businessRole: BUSINESS_ROLES.GROUP_SESSION_REVIWER
                };

                Employee.findAllByFilter(filter, function (result) {
                    vm.reviewers = result;
                });
            }
        }

        function getCharts() {
            vm.charts = [];
            // vm.selectedCharts = [];
            Chart.findAllNotInGroupSession({
                buildingId: vm.building ? vm.building.id : null,
                facilityId: vm.groupSession.facilityId,
                groupSessionId: vm.groupSession.id
            }, function (result) {
                vm.charts = result;
            })
        }

        function addCharts() {
            var charts = angular.copy(vm.selectedCharts);

            _.forEach(charts, function (chart) {
                GroupSessionDetails.addToGroupSession({
                    chart: chart,
                    groupSession: {id: vm.groupSession.id, start: vm.groupSession.start, stop: vm.groupSession.stop}
                }, function () {
                    getGroupSessionDetail();
                    vm.selectedCharts = [];
                })
            });
        }

        function leaderSign() {
            PopupService.showSignaturePopup().result.then(function (data) {
                GroupSession.leaderSign({
                    signature: data.employeeSignature,
                    pin: data.employeeSignaturePin,
                    id: vm.groupSession.id
                }, function (result) {
                    if (result) {
                        vm.groupSession = result;
                        init();
                    }
                }, function () {
                });
            });
        }

        function reviewerSign() {
            PopupService.showSignaturePopup().result.then(function (data) {
                GroupSession.reviewerSign({
                    signature: data.employeeSignature,
                    pin: data.employeeSignaturePin,
                    id: vm.groupSession.id
                }, function (result) {
                    if (result) {
                        vm.groupSession = result;
                        init();
                    }
                }, function () {
                });
            });
        }

        function allowSign() {

            return _.findIndex(vm.groupSession.employees, {'id': vm.employee.id}) >= 0;
        }

        function allowReview() {

            return vm.groupSession.reviewer && vm.groupSession.reviewer.id == vm.employee.id;
        }

        $scope.$on('$destroy', function () {
            _.forEach(vm.watchers, function (watcher) {
                if (watcher) {
                    watcher();
                }
            })
        });

        function editSignature(signature) {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation/edit-signature/evaluation-edit-signature.html',
                controller: 'EvaluationEditSignatureDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: signature
                }
            }).result.then(function (result) {
                GroupSession.masterSignature({groupSessionId: vm.groupSession.id, id: result.id, endDate: result.createdDate}, function (data) {
                    if (data) {
                        toastr.success("Signature date changed successful");

                        // init();
                        $state.reload();
                    }
                }, function () {
                    toastr.error("Failure to change the signature date");
                });
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller( 'ActivitiesNewNoteDialogController', ActivitiesNewNoteDialogController);

    ActivitiesNewNoteDialogController.$inject = ['$uibModalInstance'];

    function ActivitiesNewNoteDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

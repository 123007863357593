(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateEditController', EvaluationTemplateEditController);

    EvaluationTemplateEditController.$inject = ['entity', '$rootScope', 'OdsFormService',
        'EvaluationTemplate', 'TypePatientProcess', 'Employee', '$state',
        'suggestions', 'CoreService', 'TypeLevelCare'];

    function EvaluationTemplateEditController(entity, $rootScope, OdsFormService,
                                              EvaluationTemplate, TypePatientProcess, Employee, $state,
                                              suggestions, CoreService, TypeLevelCare) {
        var vm = this;

        vm.evaluationTemplate = entity;
        vm.title = 'Care & Delivery Template';

        //init schema if null
        vm.evaluationTemplate.jsonTemplate = !vm.evaluationTemplate.jsonTemplate ?
            OdsFormService.newSchema() : vm.evaluationTemplate.jsonTemplate;

        //Show debug var if it's running in development profile.
        // vm.debugMode = !$rootScope.inProduction ? true : false;
        vm.debugMode = true;

        vm.credentials = Employee.credentials();
        vm.typePatientProcesses = TypePatientProcess.byFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.levelCares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.readmissionBasedOn = EvaluationTemplate.readmissionBasedOn();

        //We load this from server using /ckeditor-suggestions rest service.
        vm.formCKEditorConfig = {
            ckeditor: {
                suggestions: suggestions
            }
        };
        vm.form = {};

        vm.save = save;
        vm.patientSignatureChange = patientSignatureChange;
        vm.staffSignatureChange = staffSignatureChange;
        vm.staffReviewSignatureChange = staffReviewSignatureChange;
        vm.updateTitle = updateTitle;

        function save() {
            vm.isSaving = true;
            if (vm.evaluationTemplate.id) {
                EvaluationTemplate.update(vm.evaluationTemplate, onSaveSuccess, onSaveError);
            } else {
                EvaluationTemplate.save(vm.evaluationTemplate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go('evaluation-template');
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function patientSignatureChange() {
            if (!vm.evaluationTemplate.requiredPatientSignature) {
                vm.evaluationTemplate.requiredGuarantorSignature = false;
            }
        }

        function staffSignatureChange() {
            if (!vm.evaluationTemplate.forceStaffSignature) {
                vm.evaluationTemplate.forceStaffReviewSignature = false;
                vm.evaluationTemplate.staffReviewSignatureCredentials = [];
                vm.evaluationTemplate.staffSignatureCredentials = [];
            }
        }

        function staffReviewSignatureChange() {
            if (!vm.evaluationTemplate.forceStaffReviewSignature) {
                vm.evaluationTemplate.staffReviewSignatureCredentials = [];
            }
        }

        function updateTitle() {
            vm.evaluationTemplate.jsonTemplate.label = angular.copy(vm.evaluationTemplate.name);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ActivitiesLogEmailDialogController', ActivitiesLogEmailDialogController);

    ActivitiesLogEmailDialogController.$inject = ['$uibModalInstance'];

    function ActivitiesLogEmailDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateLogEmail = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

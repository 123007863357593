(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationMarsMissedDialogController', PatientMedicationMarsMissedDialogController);

    PatientMedicationMarsMissedDialogController.$inject = ['take', 'takes', 'chart', '$uibModalInstance',
        'PatientMedicationTakeComment', 'toastr', 'ParseLinks', 'AlertService', 'PatientMedicationTake',
        'PatientMedicationTakeMissedReason', 'CoreService',
        'WizardHandler'];

    function PatientMedicationMarsMissedDialogController(take, takes, chart, $uibModalInstance,
                                                         PatientMedicationTakeComment, toastr, ParseLinks, AlertService,
                                                         PatientMedicationTake,
                                                         PatientMedicationTakeMissedReason, CoreService,
                                                         WizardHandler) {
        var vm = this;

        vm.takes = takes;
        vm.take = take;
        vm.chart = chart;

        vm.title = "Missed Medication";

        vm.isPatientSigned = false;
        vm.isEmployeeSigned = false;
        vm.showReasons = true;
        vm.odsPatientSignatureNameId = Math.floor((Math.random() * 100) + 1);
        vm.odsEmployeeSignatureNameId = Math.floor((Math.random() * 100) + 1);
        vm.employeeSignatureAgree = false;
        vm.patientSignatureAgree = false;
        vm.employee = CoreService.getCurrentEmployee();
        vm.facility = CoreService.getCurrentFacility();

        vm.missedReasons = take == null ? null : take.missedReasons;

        vm.status = {
            SCHEDULED: 'SCHEDULED',
            SYSTEM_MISS: 'SYSTEM_MISS',
            HOLD: 'HOLD',
            ADMINISTERED: 'ADMINISTERED',
            OBSERVED: 'OBSERVED',
            MISSED: 'MISSED'
        };

        vm.signatureOpts = {
            width: 620,
            height: 250,
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.patientSignature = "";
        vm.employeeSignature = "";

        vm.reasons = PatientMedicationTakeMissedReason.findAllByCorporation(CoreService.getFilterByCurrentCorporation());

        vm.clear = clear;
        vm.addReasons = addReasons;
        vm.signTakeByPatient = signTakeByPatient;
        vm.signTakeByEmployee = signTakeByEmployee;

        init();

        function init() {
            if (takes.ids.length > 1) {
                vm.showReasons = true;
                vm.isPatientSigned = false;
                vm.isEmployeeSigned = false;
            } else {
                vm.showReasons = true;
                vm.isPatientSigned = take.patientSignatureId != null;
                vm.isEmployeeSigned = take.missedSignatureId != null;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function addReasons() {
            vm.isSaving = true;
            PatientMedicationTake.changeStatus({
                status: takes.status,
                takes: takes.ids,
                missedReasons: vm.missedReasons,
                facilityId: vm.facility.id
            }, function (result) {
                vm.showReasons = false;
                vm.isSaving = false;

                if (vm.isPatientSigned && vm.isEmployeeSigned) {
                    clear();
                } else {
                    WizardHandler.wizard('patientMedicationMissWizard').next();
                }
            });
        }

        function signTakeByPatient() {
            PatientMedicationTake.signTakeByPatient({
                takes: takes.ids,
                patientSignature: vm.patientSignature,
                facilityId: vm.facility.id
            }, function (result) {
                vm.take = result;
                vm.isPatientSigned = true;

                toastr.success('Patient Sign Successfully');
                if (vm.isPatientSigned && vm.isEmployeeSigned) {
                    clear();
                } else {
                    WizardHandler.wizard('patientMedicationMissWizard').next();
                }
            }, function () {
                toastr.error('Error signing medication');
            });
        }

        function signTakeByEmployee() {
            PatientMedicationTake.signTakeByEmployee({
                takes: takes.ids,
                employeeSignature: vm.employeeSignature,
                facilityId: vm.facility.id
            }, function (result) {
                vm.take = result;
                vm.isEmployeeSigned = true;

                toastr.success('Employee Sign Successfully');
                if (vm.isPatientSigned && vm.isEmployeeSigned) {
                    clear();
                } else {
                    WizardHandler.wizard('patientMedicationMissWizard').previous();
                }
            }, function () {
                toastr.error('Error signing medication');
            });
        }
    }
})();

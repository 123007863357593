(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VitalsController', VitalsController);

    VitalsController.$inject = ['$uibModalInstance', 'chart', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$compile',
        '$scope', '$rootScope', '$uibModal', 'CoreService', 'Vitals', 'ConfirmationService',
        '$uibModalStack'];

    function VitalsController($uibModalInstance, chart, $q, DTColumnBuilder, DTOptionsBuilder, $compile,
                              $scope, $rootScope, $uibModal, CoreService, Vitals, ConfirmationService,
                              $uibModalStack) {
        var vm = this;

        vm.vitals = [];
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.dtInstance = {};
        vm.chart = chart;
        vm.facility = CoreService.getCurrentFacility();

        vm.clear = clear;
        vm.search = search;
        vm.edit = edit;
        vm.del = del;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Vitals.findAllByChart(
                {
                    id: vm.chart.id,
                    page: vm.page - 1,
                    size: vm.itemsPerPage
                }, function (result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.vitals = result;
                    defer.resolve(vm.vitals);
                });

            return defer.promise;

        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            .withOption('aaSorting', [[0, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notVisible().renderWith(function (data, type, full) {
                return data ? moment(data.date).format("YYYYMMDD") : "";
            }),
            DTColumnBuilder.newColumn('date').withTitle('Date').renderWith(function (data, type, full) {
                // new Date(DateUtils.convertDateTimeFromServer(data.date)
                //     .toLocaleString("en-US", {timeZone: facility.timeZone}));

                return data ? moment(new Date(data)
                    .toLocaleString("en-US", {timeZone: vm.facility.timeZone})).format($rootScope.amDateFormat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Blood Presure').renderWith(function (data, type, full) {
                return data.bpSystolic && data.bpDiastolic ? data.bpSystolic + " / " + data.bpDiastolic : " - ";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Temperature').renderWith(function (data, type, full) {
                return data.temperature ? data.temperature : " - ";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Pulse').renderWith(function (data, type, full) {
                return data.pulse ? data.pulse : " - ";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Respiration').renderWith(function (data, type, full) {
                return data.respiration ? data.respiration : " - ";
            }),
            DTColumnBuilder.newColumn(null).withTitle('O<sup>2</sup> Saturation').renderWith(function (data, type, full) {
                return data.o2Saturation ? data.o2Saturation : " - ";
            }),
            DTColumnBuilder.newColumn('createdBy').withTitle('Created by'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="dt-btn warning" ng-click="vm.edit(' + data.id + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            stButtons += '<a class="dt-btn danger" ng-click="vm.del(' + data.id + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function edit(id) {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/vitals/update/vitals-dialog.html',
                controller: 'VitalsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    entity: ['Vitals', function (Vitals) {
                        return Vitals.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                search();
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Vitals').result.then(function () {
                Vitals.delete({id: id}, function () {
                    search();
                });
            })
        }
    }
})();

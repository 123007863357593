(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabCompendiumDialogController', LabCompendiumDialogController);

    LabCompendiumDialogController.$inject = ['Tube', '$uibModalInstance', 'entity', 'LabCompendium',
        'CompendiumReportGroup', 'Specimen', 'RptMetadata', 'Laboratory'];

    function LabCompendiumDialogController (Tube, $uibModalInstance, entity, LabCompendium,
                                            CompendiumReportGroup, Specimen, RptMetadata, Laboratory) {
        var vm = this;

        vm.rptMetadatas = [];
        vm.tubes = Tube.findAllByLaboratory({laboratoryId: entity.laboratory.id, page: 0, size: 10000});
        vm.labCompendium = entity;
        vm.specimens = Specimen.findAllByLaboratory({laboratoryId: entity.laboratory.id, page: 0, size: 10000});
        vm.laboratories = [entity.laboratory];
        vm.rptMetadatas = RptMetadata.query();
        vm.compendiumReportGroups = CompendiumReportGroup.findAllByLaboratory({laboratoryId: entity.laboratory.id, page: 0, size: 10000});

        vm.save = save;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.labCompendium.id !== null) {
                LabCompendium.update(vm.labCompendium, onSaveSuccess, onSaveError);
            } else {
                LabCompendium.save(vm.labCompendium, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

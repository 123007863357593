(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CampaignDialogController', CampaignDialogController);

    CampaignDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Campaign', 'InsuranceCarrierSearch', 'TypePaymentMethods', 'EvaluationTemplate', 'CoreService', 'GUIUtils'];

    function CampaignDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                      Campaign, InsuranceCarrierSearch, TypePaymentMethods, EvaluationTemplate, CoreService, GUIUtils) {
        var vm = this;

        vm.campaign = entity;
        vm.typePaymentMethods = TypePaymentMethods.byFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.gender = GUIUtils.getGender();
        vm.policyNumberName = null;
        vm.campaignChartStatus = [{id: '', chartStatus: "CURRENT"},
            {id: '', chartStatus: "CLOSED"},
            {id: '', chartStatus: "WAITING"},
            {id: '', chartStatus: "ARCHIVE"}];
        vm.campaignEvaluationStatus = _.map(GUIUtils.getEvaluationStatus(), function (campaignEvaluationStatus) {
            return{id:'', evaluationStatus: campaignEvaluationStatus}
        });

        vm.clear = clear;
        vm.save = save;
        vm.getInsuranceCarriers = getInsuranceCarriers;
        vm.getEvaluationTemplates = getEvaluationTemplates;
        vm.setPolicyNumber = setPolicyNumber;
        vm.checkCampaignChartStatus = checkCampaignChartStatus
        vm.checkCampaignEvaluationStatus = checkCampaignEvaluationStatus

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.campaign.id !== null) {
                Campaign.update(vm.campaign, onSaveSuccess, onSaveError);
            } else {
                Campaign.save(vm.campaign, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:campaignUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getFilter(query) {
            var filter = {};

            filter.facilityId = CoreService.getCurrentFacility().id;
            filter.page = 0;
            filter.size = 10;
            filter.searchQuery = query;

            return filter
        }

        function checkCampaignChartStatus() {
            vm.campaign.campaignChartStatus = _.uniqBy(vm.campaign.campaignChartStatus, 'chartStatus')
        }

        function checkCampaignEvaluationStatus() {
            vm.campaign.campaignEvaluationStatus = _.uniqBy(vm.campaign.campaignEvaluationStatus, 'evaluationStatus')
        }

        function getInsuranceCarriers(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);

                vm.insuranceCarriers = InsuranceCarrierSearch.query({
                    query: query
                });
            }
        }

        function getEvaluationTemplates(query) {
            if (query && query.length > 3) {
                EvaluationTemplate.findAllByFilter(getFilter(query), function (result) {
                    vm.evaluationTemplates = result;
                })
            }
        }

        function setPolicyNumber(query) {
            vm.campaign.campaignPolicyNumbers.push({'id': "", 'policyNumber': query});
            vm.policyNumberName = null;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('UrgentIssuesDetailController', UrgentIssuesDetailController);

    UrgentIssuesDetailController.$inject = ['entity', '$uibModalInstance', 'UrgentIssueEmployee', 'DTOptionsBuilder',
        '$q', '$compile', '$scope', 'DTColumnBuilder'];

    function UrgentIssuesDetailController(entity, $uibModalInstance, UrgentIssueEmployee, DTOptionsBuilder,
                                          $q, $compile, $scope, DTColumnBuilder) {
        var vm = this;

        vm.urgentIssues = entity;
        vm.employees = [];

        vm.clear = clear;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            UrgentIssueEmployee.getAllEmployeeByUrgentIssue({id: entity.id}, function (result) {
                vm.employees = result;

                defer.resolve(result);
            });

            return defer.promise;
        }).withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('aaSorting', [[1, 'asc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle("First Name").notSortable().renderWith(function (data, type, full, meta) {
                return data.firstName;
            }),
            DTColumnBuilder.newColumn(null).withTitle("Last Name").notSortable().renderWith(function (data, type, full, meta) {
                return data.lastName;
            }),
            DTColumnBuilder.newColumn(null).withTitle("Phone").notSortable().renderWith(function (data, type, full, meta) {
                return data.mobile;
            }),
            DTColumnBuilder.newColumn(null).withTitle("Email").notSortable().renderWith(function (data, type, full, meta) {
                return data.email;
            })
        ];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

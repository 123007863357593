(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('bin', {
                parent: 'entity',
                url: '/bin',
                data: {
                    authorities: [],
                    pageTitle: 'Bins'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bin/bins.html',
                        controller: 'BinController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Bin'
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_CONFIG_BIN];
                        return Auth.loadActionsAccess(actions);
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_BIN, access);
                }]
            })
            .state('bin.new', {
                parent: 'bin',
                url: '/new',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_BIN, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/bin/bin-dialog.html',
                            controller: 'BinDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['CoreService', function (CoreService) {
                                    return {
                                        name: null,
                                        id: null,
                                        corporation: CoreService.getCorporation()
                                    };
                                }]
                            }
                        }).result.then(function () {
                            $state.go('bin', null, {reload: 'bin'});
                        }, function () {
                            $state.go('bin');
                        });
                    }
                }]
            })
            .state('bin.edit', {
                parent: 'bin',
                url: '/{id}/edit',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_BIN, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/bin/bin-dialog.html',
                            controller: 'BinDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['Bin', function (Bin) {
                                    return Bin.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('bin', null, {reload: 'bin'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            })
            .state('bin.delete', {
                parent: 'bin',
                url: '/{id}/delete',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Auth', 'ACTIONS', 'access', function ($stateParams, $state, $uibModal, Auth, ACTIONS, access) {
                    if (Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_CONFIG_BIN, access)) {
                        $uibModal.open({
                            templateUrl: 'app/entities/bin/bin-delete-dialog.html',
                            controller: 'BinDeleteController',
                            controllerAs: 'vm',
                            size: 'md',
                            windowTopClass: 'custom-dialog-styles',
                            resolve: {
                                entity: ['Bin', function (Bin) {
                                    return Bin.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('bin', null, {reload: 'bin'});
                        }, function () {
                            $state.go('^');
                        });
                    }
                }]
            });
    }
})();

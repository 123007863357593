(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Procedure', Procedure);

    Procedure.$inject = ['$resource'];

    function Procedure ($resource) {
        var resourceUrl =  'api/procedures/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getProcedures': {
                url: 'api/procedures/super-bill/:id',
                method: 'GET',
                isArray: true
            },
            'getCpts': {
                url: 'api/procedures/cpts/:id',
                method: 'GET',
                isArray: true
            },
            'updateProcedure': {method: 'POST', url: 'api/procedures/update-procedure'},
            'deleteProcedure': {method: 'DELETE', url: 'api/procedures/delete-procedure/:id'}
        });
    }
})();

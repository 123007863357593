(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Vessel', Vessel);

    Vessel.$inject = ['$resource'];

    function Vessel ($resource) {
        var resourceUrl =  'api/vessels/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findAllByCorporation': {url: 'api/vessels/by-corporation/:id', method: 'GET', isArray: true}
        });
    }
})();

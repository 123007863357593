(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityPhoneNumberController', FacilityPhoneNumberController);

    FacilityPhoneNumberController.$inject = ['GenericEntityDatatableService', 'CoreService', 'FacilityPhoneNumber',
        '$uibModal', '$q', 'toastr', 'ConfirmationService'];

    function FacilityPhoneNumberController(GenericEntityDatatableService, CoreService, FacilityPhoneNumber,
                                           $uibModal, $q, toastr, ConfirmationService) {
        var vm = this;

        var params = {facilityId: CoreService.getCurrentFacility().id};

        vm.descriptor = {
            title: 'Facility Phone Numbers',
            newAction: false,
            entityClassHumanized: 'Facility Phone Numbers',
            entityStateName: 'facility-phone-number',
            service: FacilityPhoneNumber,
            serviceMethod: 'byFilter',
            params: params,
            pagination: 'remote',
            columns: [
                {
                    name: 'phoneNumber',
                    title: 'Phone Number',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'Order Phone Number',
                            action: function () {
                                return order();
                            }
                        })
                    ]
                },
            ]
        }

        function order() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/facility-phone-number/order/facility-phone-number-dialog.html',
                    controller: 'FacilityPhoneNumberDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    keyboard: false,
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {}
                }).result.then(function () {
                    toastr.success('Phone Number was saved', 'Success');

                    resolve();
                });
            })
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Phone Number').result.then(function () {
                    FacilityPhoneNumber.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

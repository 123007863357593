(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartExportController', ChartExportController);

    ChartExportController.$inject = ['$scope', 'ChartExport', 'GenericEntityDatatableService', 'CoreService',
        '$uibModal', '$q'];

    function ChartExportController($scope, ChartExport, GenericEntityDatatableService, CoreService,
                                   $uibModal, $q) {
        var vm = this;

        var params = {
            sort: 'id,desc',
            size: 10
        };

        vm.descriptor = {
            title: 'Chart Export',
            newButtonTitle: 'New Export',
            entityClassHumanized: 'Chart Export',
            entityStateName: 'chart-export',
            service: ChartExport,
            serviceMethod: 'query',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'status',
                    title: 'Status',
                },
                {
                    name: 'charts',
                    title: 'Charts',
                },
                {
                    name: 'progress',
                    title: 'Progress',
                },
                {
                    name: 'fails',
                    title: 'Fails',
                },
                {
                    name: 'facility.name',
                    title: 'Facility',
                    render: function (entity) {
                        return entity.facility.name;
                    }
                },
                {
                    name: 'facesheetTpl.id',
                    title: 'Facesheet ID',
                    render: function (entity) {
                        return entity.facesheetTpl.id;
                    }
                },
                {
                    name: 'facesheetTpl.name',
                    title: 'Facesheet',
                    render: function (entity) {
                        return entity.facesheetTpl.name;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Export',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/chart-export/chart-export-dialog.html',
                controller: 'ChartExportDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            corporation: CoreService.getCorporation()
                        };
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Acuity').result.then(function () {
                    Acuity.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationMarsNewController', PatientMedicationMarsNewController);

    PatientMedicationMarsNewController.$inject = ['PopupService', 'filter', 'PatientMedication', 'CoreService', 'Chart',
        'Vitals', 'Glucose', 'Evaluation', '$uibModal', 'DataTablesService', 'toastr',
        'PatientMedicationTake', 'BusinessCredential', 'BUSINESS_ROLES', "Allergies",
        '$state', 'PendingPdfChartReport', 'REPORT_TYPE', 'UrgentIssues',
        'ConfirmationService', '$q'];

    function PatientMedicationMarsNewController(PopupService, filter, PatientMedication, CoreService, Chart,
                                                Vitals, Glucose, Evaluation, $uibModal, DataTablesService, toastr,
                                                PatientMedicationTake, BusinessCredential, BUSINESS_ROLES, Allergies,
                                                $state, PendingPdfChartReport, REPORT_TYPE, UrgentIssues,
                                                ConfirmationService, $q) {
        var vm = this;

        vm.title = 'MARs';
        vm.searchQuery = "";
        vm.filter = filter;
        vm.charts = [];
        vm.filterStatus = CoreService.getPatientMedicationFilterStatus();
        vm.itemsPerPage = 10;
        vm.takesPerPage = 10;
        vm.itHasNotingCredentials = false;
        vm.facility = CoreService.getCurrentFacility();

        vm.radioModel = '';
        vm.status = {
            SCHEDULED: 'SCHEDULED',
            SYSTEM_MISS: 'SYSTEM_MISS',
            HOLD: 'HOLD',
            ADMINISTERED: 'ADMINISTERED',
            OBSERVED: 'OBSERVED',
            MISSED: 'MISSED',
            PATIENT_REFUSE: 'PATIENT_REFUSE'
        };

        vm.changeFilter = changeFilter;
        vm.clear = clear;
        vm.getMedications = getMedications;
        vm.isToday = isToday;
        vm.loadAll = loadAll;

        vm.showFilter = showFilter;
        vm.toggleRow = toggleRow;
        vm.getTakeStatusClass = getTakeStatusClass;
        vm.getEvaluationStatusClass = getEvaluationStatusClass;
        vm.addAsNeeded = addAsNeeded;
        vm.administer = administer;
        vm.firstDoseSideEffect = firstDoseSideEffect;
        vm.addComment = addComment;
        vm.sign = sign;
        vm.getTakeAsNeededAvailableClass = getTakeAsNeededAvailableClass;
        vm.getTakes = getTakes;
        vm.getAsNeededTakes = getAsNeededTakes;
        vm.showGlucosePopup = showGlucosePopup;
        vm.openChart = openChart;
        vm.showMissingReasons = showMissingReasons;
        vm.getMissingReasons = getMissingReasons;
        vm.print = print;

        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;

        //evaluations functions
        vm.amended = amended;
        vm.assignEvaluation = assignEvaluation;
        vm.del = del;
        vm.view = view;
        vm.openNotingDialog = openNotingDialog;
        vm.openPatientRefuseConfirmDialog = openPatientRefuseConfirmDialog;

        init();

        function init() {
            BusinessCredential.getCredentialByRole({
                businessRole: BUSINESS_ROLES.NURSE,
                id: vm.facility.id
            }, function (result) {
                vm.credentials = result;

                vm.itHasNotingCredentials = _.intersection(CoreService.getCurrentEmployee().credentials,
                    _.map(vm.credentials, function (credential) {
                        return credential.name;
                    })
                ).length > 0;
            });
        }

        loadAll();

        function loadAll() {
            PatientMedication.mars(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.charts = result;


                var chartIds = _.map(vm.charts, 'id');

                if (chartIds.length > 0) {
                    UrgentIssues.countByChartIds({chartIds: chartIds}, function (dataChartIdCountUI) {
                        _.forEach(dataChartIdCountUI, function (item) {
                            var chartProj_ = _.find(vm.charts, function (chartProj) {
                                return chartProj.id == item.chartId;
                            });

                            if (chartProj_) {
                                chartProj_.urgentIssues = item.count;
                            }
                        });
                    });
                }
                _.forEach(vm.charts, function (chart) {
                    chart.takesTotalItems = 0;
                    chart.takesAsNeededTotalItems = 0;
                    chart.takesPage = 1;
                    chart.takesAsNeededPage = 1;
                    chart.pendingNoting = 0;
                });
            });
        }

        function showFilter() {
            angular.element('#filter_as_needed').toggleClass('active');
            if (angular.element('#filter_as_needed').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('#filter_as_needed').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('#filter_as_needed').slideUp();
            }
        }

        function changeFilter(att, value) {
            vm.filter.page = 1;

            vm.charts = [];

            if (att) {
                vm.filter[att] = value;
            }

            loadAll();
        }

        function clear() {
            vm.page = 1;
            vm.searchQuery = null;

            loadAll();
        }

        function getMedications(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);
                vm.medications = MedicationSearch.query({
                    query: query
                });
            }
        }

        function toggleRow(chartProj) {
            chartProj.active = !chartProj.active;
            chartProj.takesPage = 1;
            chartProj.takesAsNeededPage = 1;

            chartProj.pendingNoting = PatientMedication.countByChartAndNotedIsFalse({"id": chartProj.id})

            if (chartProj.active) {
                Chart.get({id: chartProj.id}, function (chart) {
                    chartProj.chart = chart;
                })

                Allergies.findAllByChartAndStatus({id: chartProj.id, status: true}, function (allergies) {
                    if (allergies.length > 0) {
                        chartProj.allergies =
                            _.join(
                                _.map(allergies, function (allergy) {
                                    return allergy.name;
                                }), ', ');
                    }
                });

                chartProj.vitals = Vitals.findLastByChart({id: chartProj.id});
                chartProj.glucose = Glucose.findLastByChart({id: chartProj.id});
                chartProj.takes = [];
                chartProj.asNeeded = [];

                getEvaluations(chartProj);

                getEvaluationsMeasurement(chartProj)

                getTakes(chartProj);

                if (vm.filter.filterStatus == vm.filterStatus.CURRENT) {
                    getAsNeededTakes(chartProj);
                }
            }
        }

        function getTakes(chartProj) {
            PatientMedication.marsTakes({
                chartId: chartProj.id,
                filterStatus: vm.filter.filterStatus,
                facilityId: vm.filter.facilityId,
                size: vm.takesPerPage,
                page: chartProj.takesPage - 1
            }, function (takes, headers) {
                chartProj.takesTotalItems = headers('X-Total-Count');

                chartProj.takes = takes;
                chartProj.selected = {};
                chartProj.selectAll = false;
            });
        }

        function getAsNeededTakes(chartProj) {
            PatientMedication.marsTakes({
                chartId: chartProj.id,
                filterStatus: "AS_NEEDED",
                facilityId: vm.filter.facilityId,
                size: vm.takesPerPage,
                page: chartProj.takesAsNeededPage - 1
            }, function (takes, headers) {
                chartProj.takesAsNeededTotalItems = headers('X-Total-Count');

                chartProj.asNeeded = _.map(takes, function (data) {
                    if (data.lastScheduleDate) {
                        var newDate = moment(data.lastScheduleDate).toDate();
                        newDate.setHours(newDate.getHours() + data.frequency.hours);

                        data.untilDate = newDate;
                        data.available = newDate <= new Date();
                    } else {
                        data.untilDate = new Date();
                        data.available = true;
                    }

                    return data;
                });

                chartProj.selectedAsNeeded = {};
                chartProj.selectAllAsNeeded = false;
            });
        }

        function getEvaluations(chartProj) {
            chartProj.evaluations = [];
            chartProj.evaluations = Evaluation.findAllChartAndMars({id: chartProj.id})
        }

        function getEvaluationsMeasurement(chartProj) {
            chartProj.measurements = [];
            chartProj.measurements = Evaluation.findAllLastMeasurementsByChart({
                chartId: chartProj.id,
                facilityId: vm.facility.id
            });
        }

        function getFilter() {
            var filter = angular.copy(vm.filter);

            filter.page -= 1;
            filter.medication = filter.medication ? filter.medication.id : null;
            filter.searchQuery = vm.searchQuery;

            return filter;
        }

        function isToday(dateStr) {
            if (dateStr) {
                var date = new Date(dateStr);
                var today = new Date();

                return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
            }

            return false;
        }

        function getTakeStatusClass(take) {
            switch (take.medicationTakeStatus) {
                case 'SCHEDULED' :
                    return 'label-success';
                case 'HOLD':
                    return 'label-danger';
                case 'OBSERVED': {
                    if (take.patientSignatureId && take.observedSignatureId) {
                        return 'label-success';
                    } else if (!take.patientSignatureId && !take.observedSignatureId) {
                        return 'label-danger';
                    } else {
                        return 'label-warning';
                    }
                }
                case 'ADMINISTERED':
                    return 'label-primary';
                case 'MISSED': {
                    if (take.patientSignatureId && take.missedSignatureId) {
                        return 'label-success';
                    } else if (!take.patientSignatureId && !take.missedSignatureId) {
                        return 'label-danger';
                    } else {
                        return 'label-warning';
                    }
                }
                case 'SYSTEM_MISS':
                    return 'label-warning';

                case 'PATIENT_REFUSE':
                    return 'label-danger';
            }

            return "";
        }

        function getTakeAsNeededAvailableClass(take) {
            return take.untilDate < new Date() ? 'label-success' : 'label-danger';
        }

        function getEvaluationStatusClass(evaluation) {
            return CoreService.getEvaluationStatusClass(evaluation.status);
        }

        function assignEvaluation(chartProj) {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-template/mars-assign/evaluation-template-mars-assign-dialog.html',
                controller: 'EvaluationTemplateMarsAssignDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chartProj.chart
                }
            }).result.then(function () {
                getEvaluations(chartProj);
            }, function (result) {
                getEvaluations(chartProj);
            });
        }

        function amended(chartProj, evaluation) {
            Evaluation.amended({id: evaluation.id}, function () {
                getEvaluations(chartProj);
            });
        }

        function del(chartProj, evaluation) {
            ConfirmationService.showDelete('Evaluation').result.then(function () {
                Evaluation.delete({id: evaluation.id}, function () {
                    getEvaluations(chartProj);
                }, function (result) {
                    getEvaluations(chartProj);
                });
            })
        }

        function addAsNeeded(chartProj, status) {
            var ids = vm.getSelectedIds(chartProj.selectedAsNeeded);
            vm.isSaving = true;

            PatientMedication.addAsNeeded({ids: ids, takeStatus: status}, function (result) {
                vm.isSaving = false;

                if (result.length > 0) {
                    if (status == vm.status.ADMINISTERED) {
                        toastr.success('The medications has been administered');
                    }
                    if (status == vm.status.OBSERVED) {
                        toastr.success('The medications has been observed');
                        openSigDialog(chartProj, status, result)
                    }
                } else {
                    if (status == vm.status.ADMINISTERED) {
                        toastr.error("Some medications weren't administered");
                    }
                    if (status == vm.status.OBSERVED) {
                        toastr.error("Some medications weren't observed");
                    }
                }

                chartProj.active = false;
                toggleRow(chartProj);
            }, function () {
                vm.isSaving = false;
            });
        }

        function administer(chartProj, status) {
            var takes = vm.getSelectedIds(chartProj.selected);

            switch (status) {
                case vm.status.MISSED: {
                    takes = _.filter(takes, function (take) {
                        var takeObj = _.filter(chartProj.takes, function (item) {
                            return "" + item.takeId == take;
                        })[0];

                        if (takeObj.medicationTakeStatus == vm.status.MISSED) {
                            chartProj.selected[take] = false;
                        }

                        return chartProj.selected[take];
                    });

                    if (takes.length > 0) {
                        if (vm.filter.filterStatus == vm.filterStatus.MISSED) {
                            openSystemMissDialog(chartProj, status, takes).result.then(function () {
                                openMissWizard(chartProj, status, takes);
                            });
                        } else {
                            openMissWizard(chartProj, status, takes);
                        }
                    } else {
                        toastr.info('All selected medications are missed');
                    }

                    break;
                }
                case vm.status.OBSERVED: {
                    takes = _.filter(takes, function (take) {
                        var takeObj = _.filter(chartProj.takes, function (item) {
                            return "" + item.takeId == take;
                        })[0];

                        if (takeObj.medicationTakeStatus != vm.status.SCHEDULED && takeObj.medicationTakeStatus != vm.status.SYSTEM_MISS) {
                            chartProj.selected[take] = false;
                        }

                        return chartProj.selected[take];
                    });

                    if (takes.length > 0) {
                        if (vm.filter.filterStatus == vm.filterStatus.MISSED) {
                            openSystemMissDialog(chartProj, status, takes).result.then(function () {
                                PatientMedicationTake.changeStatus({
                                    status: status,
                                    takes: takes,
                                    facilityId: vm.facility.id
                                }, function (result) {
                                    chartProj.active = false;
                                    toggleRow(chartProj);

                                    openSigDialog(chartProj, status, takes);
                                });
                            });
                        } else {
                            PatientMedicationTake.changeStatus({
                                status: status,
                                takes: takes,
                                facilityId: vm.facility.id
                            }, function (result) {
                                chartProj.active = false;
                                toggleRow(chartProj);

                                openSigDialog(chartProj, status, takes);
                            });
                        }
                    } else {
                        toastr.info('All selected medications are invalid for Observe');
                    }

                    break;
                }
                case vm.status.HOLD: {
                    takes = _.filter(takes, function (take) {
                        var takeObj = _.filter(chartProj.takes, function (item) {
                            return "" + item.takeId == take;
                        })[0];

                        if (takeObj.medicationTakeStatus != vm.status.SCHEDULED) {
                            chartProj.selected[take] = false;
                        }

                        return chartProj.selected[take];
                    });

                    if (takes.length > 0) {
                        PatientMedicationTake.changeStatus({
                            status: status,
                            takes: takes,
                            facilityId: vm.facility.id
                        }, function (result) {
                            chartProj.active = false;
                            toggleRow(chartProj);
                        });
                    } else {
                        toastr.info('All selected medications are invalid for Hold');
                    }

                    break;
                }
                case vm.status.ADMINISTERED: {
                    takes = _.filter(takes, function (take) {
                        var takeObj = _.filter(chartProj.takes, function (item) {
                            return "" + item.takeId == take;
                        })[0];

                        if (takeObj.medicationTakeStatus != vm.status.SCHEDULED &&
                            takeObj.medicationTakeStatus != vm.status.HOLD &&
                            takeObj.medicationTakeStatus != vm.status.SYSTEM_MISS) {
                            chartProj.selected[take] = false;
                        }

                        return chartProj.selected[take];
                    });

                    if (takes.length > 0) {
                        if (vm.filter.filterStatus == vm.filterStatus.MISSED) {
                            openSystemMissDialog(chartProj, status, takes).result.then(function () {
                                PatientMedicationTake.changeStatus({
                                    status: status,
                                    takes: takes,
                                    facilityId: vm.facility.id
                                }, function (result) {
                                    chartProj.active = false;
                                    toggleRow(chartProj);
                                });
                            });
                        } else {
                            PatientMedicationTake.changeStatus({
                                status: status,
                                takes: takes,
                                facilityId: vm.facility.id
                            }, function (result) {
                                chartProj.active = false;
                                toggleRow(chartProj);
                            });
                        }
                    } else {
                        toastr.info('All selected medications are invalid for Administer');
                    }

                    break;
                }
                case vm.status.PATIENT_REFUSE: {
                    takes = _.filter(takes, function (take) {
                        var takeObj = _.filter(chartProj.takes, function (item) {
                            return "" + item.takeId == take;
                        })[0];

                        if (takeObj.medicationTakeStatus != vm.status.SCHEDULED &&
                            takeObj.medicationTakeStatus != vm.status.HOLD) {
                            chartProj.selected[take] = false;
                        }

                        return chartProj.selected[take];
                    });

                    if (takes.length > 0) {
                        openPatientRefuseConfirmDialog(chartProj, status, takes).then(function () {
                            chartProj.active = false;
                            toggleRow(chartProj);
                        });

                    } else {
                        toastr.info('All selected medications are invalid for Refuse');
                    }

                    break;
                }
            }
        }

        function firstDoseSideEffect(chartProj) {
            var takes = vm.getSelectedIds(chartProj.selected);

            $uibModal.open({
                templateUrl: 'app/entities/patient-medication/mars/first-dose-side-effect/first-dose-side-effect.dialog.html',
                controller: 'FirstDoseSideEffectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    takes: {
                        ids: takes
                    },
                    chart: chartProj.chart
                }
            }).result.then(function () {
                chartProj.active = false;
                toggleRow(chartProj);
            }, function (result) {
                chartProj.active = false;
                toggleRow(chartProj);
            });
        }

        function addComment(chartProj) {
            var takes = vm.getSelectedIds(chartProj.selected);

            takes = _.filter(takes, function (take) {
                var takeObj = _.filter(chartProj.takes, function (item) {
                    return "" + item.takeId == take;
                })[0];

                if (takeObj.medicationTakeStatus != vm.status.ADMINISTERED &&
                    takeObj.medicationTakeStatus != vm.status.OBSERVED &&
                    takeObj.medicationTakeStatus != vm.status.SYSTEM_MISS &&
                    takeObj.medicationTakeStatus != vm.status.MISSED &&
                    takeObj.medicationTakeStatus != vm.status.PATIENT_REFUSE) {

                    chartProj.selected[take] = false;
                }

                return chartProj.selected[take];
            });

            if (takes.length > 0) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-medication/mars/comment/patient-medication-mars-comment-dialog.html',
                    controller: 'PatientMedicationMarsCommentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        takes: {
                            ids: takes
                        },
                        chart: chartProj.chart
                    }
                }).result.then(function () {
                    chartProj.active = false;
                    toggleRow(chartProj);
                }, function (result) {
                    chartProj.active = false;
                    toggleRow(chartProj);
                });
            } else {
                toastr.info('All selected medications do not require comments');
            }
        }

        function sign(chartProj) {
            var takes = vm.getSelectedIds(chartProj.selected);

            takes = _.filter(takes, function (take) {
                var takeObj = _.filter(chartProj.takes, function (item) {
                    return "" + item.takeId == take;
                })[0];

                if (takeObj.medicationTakeStatus != vm.status.MISSED && takeObj.medicationTakeStatus != vm.status.OBSERVED) {
                    chartProj.selected[take] = false;
                }

                return chartProj.selected[take];
            });

            if (takes.length > 0) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-medication/mars/sign/patient-medication-mars-sign-wizard-dialog.html',
                    controller: 'PatientMedicationMarsSignDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        takes: {
                            ids: takes,
                            status: null
                        },
                        chart: chartProj.chart,
                        take: ['PatientMedicationTake', function (PatientMedicationTake) {
                            if (takes.length == 1) {

                                return PatientMedicationTake.get({id: takes[0]}).$promise;
                            } else {

                                return null;
                            }
                        }]
                    }
                }).result.then(function () {
                    chartProj.active = false;
                    toggleRow(chartProj);
                }, function (result) {
                    chartProj.active = false;
                    toggleRow(chartProj);
                });
            } else {
                toastr.info('All selected medications do not require signatures');
            }
        }

        function openSystemMissDialog(chartProj, status, takes) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-medication/mars/system-miss/patient-medication-mars-system-miss-reason-dialog.html',
                controller: 'PatientMedicationMarsSystemMissDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    takes: {
                        ids: takes,
                        status: vm.status.SYSTEM_MISS
                    },
                    chart: chartProj.chart,
                    take: ['PatientMedicationTake', function (PatientMedicationTake) {
                        return takes.length > 1 ? null : PatientMedicationTake.get({id: takes[0]}).$promise;
                    }]
                }
            })
        }

        function openMissWizard(chartProj, status, takes) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-medication/mars/missed/patient-medication-mars-missed-wizard-dialog.html',
                controller: 'PatientMedicationMarsMissedDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    takes: {
                        ids: takes,
                        status: status
                    },
                    chart: chartProj.chart,
                    take: ['PatientMedicationTake', function (PatientMedicationTake) {
                        return takes.length > 1 ? null : PatientMedicationTake.get({id: takes[0]}).$promise;
                    }]
                }
            }).result.then(function () {
                chartProj.active = false;
                toggleRow(chartProj);
            }, function (result) {
                chartProj.active = false;
                toggleRow(chartProj);
            });
        }

        function openSigDialog(chartProj, status, takes) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-medication/mars/sign/patient-medication-mars-sign-wizard-dialog.html',
                controller: 'PatientMedicationMarsSignDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    takes: {
                        ids: takes,
                        status: status
                    },
                    chart: chartProj.chart,
                    take: ['PatientMedicationTake', function (PatientMedicationTake) {
                        if (takes.length == 1) {

                            return PatientMedicationTake.get({id: takes[0]}).$promise;
                        } else {

                            return null;
                        }
                    }]
                }
            });
        }

        function view(id) {
            PopupService.showPatientMedicationsDetailsPopup(id);
        }

        function getMissingReasons(take) {
            if (!take.missedReasons) {
                ConfirmationService.open({
                    title: 'Missed Reason',
                    message: take.missedReasons,
                    actions: [
                        {
                            name: 'Close',
                            color: 'btn btn-danger',
                            icon: 'fa fa-ban',
                            value: 'CANCEL'
                        }
                    ]
                });
            } else {
                PatientMedicationTake.get({id: take.takeId}, function (result) {
                    take.missedReasons = _.join(
                        _.map(result.missedReasons, function (missedReason) {
                            return missedReason.name;
                        }), ', ');

                    return ConfirmationService.open({
                        title: 'Missed Reason',
                        message: take.missedReasons,
                        actions: [
                            {
                                name: 'Close',
                                color: 'btn btn-danger',
                                icon: 'fa fa-ban',
                                value: 'CANCEL'
                            }
                        ]
                    });
                });
            }
        }

        function showMissingReasons(take) {
            if (!take.missedReasons) {
                PatientMedicationTake.get({id: take.takeId}, function (result) {
                    take.missedReasons = _.join(
                        _.map(result.missedReasons, function (missedReason) {
                            return missedReason.name;
                        }), ', ');
                });
            }
        }

        function openNotingDialog(chartProj) {
            if (vm.itHasNotingCredentials) {
                PopupService.showNotingPopup(chartProj ? chartProj.id : null).result.then(function () {
                    if (chartProj) {
                        chartProj.active = false;
                        toggleRow(chartProj);
                    }

                    loadAll();
                }, function () {
                    if (chartProj) {
                        chartProj.active = false;
                        toggleRow(chartProj);
                    }
                    loadAll();
                });
            }
        }

        function showGlucosePopup(chartProj) {
            PopupService.showGlucosePopup(chartProj.chart).result.then(function () {
                chartProj.glucose = Glucose.findLastByChart({id: chartProj.id});
            });
        }

        function openChart(chartId) {
            if (chartId != null) {
                $state.go('chart', {id: chartId})
            }
        }

        function print(id, chart) {
            PendingPdfChartReport.save({
                chart: {id: chart.id},
                objectId: id,
                name: "" + id,
                reportType: REPORT_TYPE.PATIENT_MEDICATION
            }, onPrintSuccess, onPrintError);
        }

        function onPrintSuccess(result) {
            toastr.success('Your report "' + result.name + '" is been generated please go to storage to download it');
        }

        function onPrintError() {
            toastr.error("We couldn't generate the report.");
        }

        function openPatientRefuseConfirmDialog(chartProj, status, takes) {
            return $q(function(resolve) {
                ConfirmationService.open({
                    title: 'Patient Refuse',
                    message: 'This action will <strong>refused</strong> the Medications. Are you sure you want to <strong>refused</strong> these Medications?',
                    actions: [
                        {
                            name: 'Refuse',
                            color: 'btn btn-success',
                            icon: 'fa fa-check-circle',
                            value: 'SUCCESS'
                        },
                        {
                            name: 'Close',
                            color: 'btn btn-danger',
                            icon: 'fa fa-ban',
                            value: 'CANCEL'
                        }
                    ]
                }).result.then(function () {
                    PatientMedicationTake.changeStatus({
                        status: status,
                        takes: takes,
                        facilityId: CoreService.getCurrentFacility().id
                    }, function (result) {
                        resolve();
                    });
                });
            });
        }
    }
})();

/**
 * Created by jesusrodriguez on 2/26/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('ConfirmationService', ConfirmationService);

    ConfirmationService.$inject = ['$uibModal'];

    function ConfirmationService($uibModal) {
        var _defaultConfig = {
            title: '',
            message: '',
            icon: {},
            leftAction: null,
            // leftAction: {
            //     name: 'Info',
            //     color: 'btn btn-warning',
            //     icon: 'fa fa-info',
            //     value: 'INFO'
            // },
            actions: [
                {
                    name: 'Delete',
                    color: 'btn btn-success',
                    icon: 'fa fa-trash',
                    value: 'SUCCESS'
                },
                {
                    name: 'Cancel',
                    color: 'btn btn-danger',
                    icon: 'fa fa-ban',
                    value: 'CANCEL'
                }
            ]
        }

        var service = {
            showDelete: showDelete,
            showCredentials: showCredentials,
            open: open
        };

        return service;

        function showDelete(entity) {
            var config = angular.extend({}, _defaultConfig, {
                title: 'Delete ' + entity,
                message:
                    `This action is <strong>final</strong> and will <strong>remove</strong>
                        this ${entity} from the database. Are you sure you want to <strong>delete</strong> this ${entity}?`,
            })

            return open(config);
        }

        function showCredentials(credentials) {
            var config = angular.extend({}, _defaultConfig, {
                title: 'Care and Delivery Credentials',
                message:
                    `<div class="row">
                        <div class="col-lg-12" style="font-size: 18px;">` +
                    (credentials === null || credentials.length === 0 ?
                            `<ul class="list-inline" style="padding-left: 0;">
                                    <li style="margin-bottom: 5px">
                                        <span class="label label-info">Does not require any credentials</span>
                                    </li>
                                </ul>`
                            :
                            `<ul class="list-inline" style="padding-left: 0;">` +
                            _.join(
                                _.map(credentials, function (credential) {
                                    return `<li style="margin-bottom: 5px">
                                                <span class="label label-warning">${credential}</span>
                                            </li>`
                                }), " ") +
                            `</ul>`
                    ) +
                    `    </div>
                    </div>`,
                actions: [
                    {
                        name: 'Close',
                        color: 'btn btn-danger',
                        icon: 'fa fa-ban',
                        value: 'CANCEL'
                    }
                ]
            })

            return open(config);
        }

        function open(config) {
            return $uibModal.open({
                templateUrl: 'app/components/confirmation/confirmation.html',
                controller: 'ConfirmationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles thanos-z-index',
                resolve: {
                    config: config
                }
            })
        }
    }
})();

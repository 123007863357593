(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('WeightDialogController', WeightDialogController);

    WeightDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Weight', 'DateUtils'];

    function WeightDialogController ($timeout, $scope, $uibModalInstance, entity, Weight, DateUtils) {
        var vm = this;

        vm.weight = entity;
        vm.clear = clear;
        vm.save = save;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.height = {feet: null, inches: null};

        if (vm.weight.id && !isNaN(vm.weight.height)) {
            vm.height = {feet: Math.floor(vm.weight.height / 12) , inches: vm.weight.height % 12};
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            vm.weight.height = vm.height.feet * 12 + vm.height.inches;

            vm.weight.bmi = (vm.weight.weight / ( vm.weight.height * vm.weight.height)) * 703;

            if (vm.weight.id !== null) {
                Weight.update(vm.weight, onSaveSuccess, onSaveError);
            } else {
                Weight.save(vm.weight, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:weightUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.time = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

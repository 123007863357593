(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('InitValue', InitValue);

    InitValue.$inject = ['$resource'];

    function InitValue ($resource) {
        var resourceUrl =  'api/init-values/:id';

        return $resource(resourceUrl, {}, {
            'findAllByFilter': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'loadEntitiesByType': { method: 'GET', url: 'api/init-values/entities-by-type', isArray: true}
        });
    }
})();

/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .controller('LateCareDeliveryBoxController', LateCareDeliveryBoxController);

    LateCareDeliveryBoxController.$inject = ['DashboardService', 'CoreService'];

    function LateCareDeliveryBoxController(DashboardService, CoreService) {

        var vm = this;

        vm.facility = CoreService.getCurrentFacility();

        vm.total = 0;
        vm.countClosed = 0;
        vm.countArchive = 0;
        vm.countCurrent = 0;
        vm.percentClosed = 0;
        vm.percentArchive = 0;
        vm.percentCurrent = 0;

        DashboardService.countAllByFilter({
            facilityId: vm.facility.id,
            status: "Late",
            requiredPatientSignature: false,
            empty: false
        }, function (result) {
            vm.total = result.count ? result.count : 0;

            if (vm.total > 0) {
                DashboardService.countAllByFilter({
                    facilityId: vm.facility.id,
                    chartStatus: 'CLOSED',
                    status: "Late",
                    requiredPatientSignature: false,
                    empty: false
                }, function (result) {
                    vm.countClosed = result.count ? result.count : 0;
                    vm.percentClosed = (parseInt(vm.countClosed) * 100 / parseInt(vm.total));
                    vm.percentClosed = vm.percentClosed.toFixed(2);
                });

                DashboardService.countAllByFilter({
                    facilityId: vm.facility.id,
                    chartStatus: 'ARCHIVE',
                    status: "Late",
                    requiredPatientSignature: false,
                    empty: false
                }, function (result) {
                    vm.countArchive = result.count ? result.count : 0;
                    vm.percentArchive = (parseInt(vm.countArchive) * 100 / parseInt(vm.total));
                    vm.percentArchive = vm.percentArchive.toFixed(2);
                });

                DashboardService.countAllByFilter({
                    facilityId: vm.facility.id,
                    chartStatus: 'CURRENT',
                    status: "Late",
                    requiredPatientSignature: false,
                    empty: false
                }, function (result) {
                    vm.countCurrent = result.count ? result.count : 0;
                    vm.percentCurrent = (parseInt(vm.countCurrent) * 100 / parseInt(vm.total));
                    vm.percentCurrent = vm.percentCurrent.toFixed(2);
                });
            }
        });
    }
})();

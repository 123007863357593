(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AppointmentsNewDialogController', AppointmentsNewDialogController);

    AppointmentsNewDialogController.$inject = ['$uibModalInstance'];

    function AppointmentsNewDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.addCustomDate = addCustomDate;
        vm.deleteCustomDate = deleteCustomDate;

        vm.datePicked = null;
        vm.customRecurrences = [];

        vm.calendarOpts = {
            showWeeks: true
        };

        vm.calendarOptsCustom = {
            showWeeks: true
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function addCustomDate(date) {
            if (vm.datePicked) {
                vm.customRecurrences.push(date);
                vm.datePicked = null;
            }
        }

        function deleteCustomDate(index) {
            vm.customRecurrences.splice(index, 1);
        }
    }
})();

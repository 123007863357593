(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ConsentBoostController', ConsentBoostController);

    ConsentBoostController.$inject = ['$uibModal', 'PopupService', 'toastr', 'Evaluation', 'chart', 'process',
        'DataTablesService', '$window', '$uibModalStack', 'TAB'];

    function ConsentBoostController($uibModal, PopupService, toastr, Evaluation, chart, process,
                                    DataTablesService, $window, $uibModalStack, TAB) {
        var vm = this;

        vm.title = 'Consent Boost';

        vm.evaluations = [];
        vm.chart = chart;
        vm.process = process;
        vm.selected = {};
        vm.selectAll = false;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.TAB = TAB;

        vm.openModalSignature = openModalSignature;
        vm.openModalAttachments = openModalAttachments;
        vm.openModalGenerateNew = openModalGenerateNew;
        vm.transition = transition;
        vm.search = search;
        vm.generateConsent = generateConsent;

        loadAll();

        function loadAll() {
            $window.scrollTo(0, 0);

            Evaluation.findAllPendingByIdAndPatientProcess(getFilter(), function (result) {
                vm.evaluations = result;

                initSelected();
            })
        }

        function signPatient(signature) {
            Evaluation.chartSign({
                ids: DataTablesService.getSelectedIds(vm.selected),
                signature: signature
            }, function () {
                transition();
                vm.selectAll = false;
            })
        }

        function openModalSignature() {
            if (PopupService.checkSigPlusExtLiteExtension()) {
                startSign();
            } else {
                PopupService.showPatientSignaturePopup(vm.chart).result.then(function (data) {
                    signPatient(data);
                });
            }
        }

        function openModalAttachments(evaluationId) {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation/consent-boost/attachment/consent-boost-attachment-dialog.html',
                controller: 'ConsentBoostAttachmentDialogController',
                controllerAs: 'vm',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    evaluationId: evaluationId
                }
            });
        }

        function openModalGenerateNew() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation/consent-boost/generate/consent-boost-new-dialog.html',
                controller: 'ConsentBoostNewDialogController',
                controllerAs: 'vm',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    process: vm.process,
                    chart: vm.chart
                }
            }).result.then(function () {
                transition();
                toastr.success("Consent have been generated");
            }, function () {
                toastr.error("Consent have not been generated");
            });
        }

        function generateConsent() {
            $uibModalStack.dismissAll();

            $uibModal.open({
                templateUrl: 'app/entities/evaluation/consent-boost/generate-consent/consent-boost-generate-consent-dialog.html',
                controller: 'ConsentBoostGenerateConsentDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'lg',
                windowTopClass: 'custom-dialog-styles modal-bottom',
                animation: false,
                resolve: {
                    chart: chart,
                    process: vm.process
                }
            }).result.then(function () {
                transition();
                toastr.success("Consent have been generated");
            }, function () {
                toastr.error("Consent have not been generated");
            });
        }

        function getFilter() {
            var filter = {};

            filter.chartId = vm.chart.id;
            filter.processId = vm.process.id;
            filter.searchQuery = vm.searchQuery;

            return filter;
        }

        function transition() {
            Evaluation.findAllPendingByIdAndPatientProcess(getFilter(), function (result) {
                vm.evaluations = result;
                initSelected();
            });
        }

        function search() {
            transition();
        }

        function initSelected() {
            vm.select = {};
            _.forEach(vm.evaluations, function (evaluation) {
                vm.selected[evaluation.id] = false;
                vm.selectAll = false;
            })
        }

        function startSign() {
            var message = {
                "firstName": "",
                "lastName": "",
                "eMail": "",
                "location": "",
                "imageFormat": 1,
                "imageX": 500,
                "imageY": 100,
                "imageTransparency": false,
                "imageScaling": false,
                "maxUpScalePercent": 0.0,
                "rawDataFormat": "ENC",
                "minSigPoints": 25
            };

            top.document.addEventListener('SignResponse', SignResponse, false);
            var messageData = JSON.stringify(message);
            var element = document.createElement("MyExtensionDataElement");
            element.setAttribute("messageAttribute", messageData);
            document.documentElement.appendChild(element);
            var evt = document.createEvent("Events");
            evt.initEvent("SignStartEvent", true, false);
            element.dispatchEvent(evt);
        }

        function SignResponse(event) {
            var str = event.target.getAttribute("msgAttribute");
            var objResponse = JSON.parse(str);
            var obj = null;

            if (typeof (objResponse) === 'string') {
                obj = JSON.parse(objResponse);
            } else {
                obj = JSON.parse(JSON.stringify(objResponse));
            }

            if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined") {
                toastr.error(obj.errorMsg);
            } else {
                if (obj.isSigned) {
                    signPatient("data:image/jpg;base64," + obj.imageData);
                }
            }

            top.document.removeEventListener('SignResponse', SignResponse, false);
        }
    }
})();

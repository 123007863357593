(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('super-bill-view', {
                parent: 'entity',
                url: '/super-bill-view/{id}',
                data: {
                    authorities: []
                },
                ncyBreadcrumb: {
                    label: 'Super Bill View'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/super-bill/super-bill-view.html',
                        controller: 'SuperBillViewController',
                        controllerAs: 'vm'
                    },
                    'diagnoses@super-bill-view': {
                        templateUrl: 'app/entities/super-bill-diagnosis/super-bill-diagnoses.html',
                        controller: 'SuperBillDiagnosisController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    entity: ['SuperBill', '$stateParams', function(SuperBill, $stateParams) {
                        return SuperBill.get({id : $stateParams.id}).$promise;
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_SUPER_BILL];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_SUPER_BILL, access);
                }]
            })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('GlucoseChartController', GlucoseChartController);

    GlucoseChartController.$inject = ['chart', 'Glucose', 'CoreService', '$uibModal', 'PopupService', '$scope',
        '$rootScope', 'access', 'ACTIONS', 'Auth'];

    function GlucoseChartController(chart, Glucose, CoreService, $uibModal, PopupService, $scope,
                                    $rootScope, access, ACTIONS, Auth) {
        var vm = this;
        vm.chart = chart;

        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.history = history;

        vm.dataGlu = [{
            values: [],
            key: 'Glucose',
            color: '#ffffff'
        }];

        vm.optionsGlu = {
            chart: {
                type: 'lineChart',
                showLegend: false,
                height: 340,
                margin: {
                    top: 20,
                    right: 50,
                    bottom: 100,
                    left: 50
                },
                x: function (d) {
                    return d.x;
                },
                y: function (d) {
                    return d.y;
                },
                useInteractiveGuideline: true,
                xAxis: {
                    tickFormat: function (d) {
                        return d3.time.format('%m/%d/%y-%H:%M')(new Date(d));
                    },
                    showMaxMin: false,
                    rotateLabels: (-70)
                },
                yAxis: {
                    tickFormat: function (d) {
                        return d3.format('.02f')(d);
                    },
                    showMaxMin: false,
                }
            }
        };

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_GLUCOSE_VIEW, access)) {
                return;
            }

            Glucose.findLastByChart({id: chart.id}, function (results) {
                vm.glucose = results;
                vm.glucoseInterventions = _.map(results.glucoseInterventions, function (glucoseIntervention) {
                    return glucoseIntervention.name;
                }).join(',');
            });

            Glucose.findAllByChartPageable({id: chart.id, size: 10}, function (data) {
                for (var i = data.length - 1; i >= 0; i--) {
                    vm.dataGlu[0].values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].reading)});
                }
            });
        }

        function toggleItem() {
            CoreService.togglePanel('glucose');
        }

        function add() {
            PopupService.showGlucosePopup(chart).result.then(function () {
                loadAll();
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/glucose/history/glucose-history.html',
                controller: 'GlucoseHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartGlucoseHistory', function (event, result) {
            history();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LevelCareBoxController', LevelCareBoxController);

    LevelCareBoxController.$inject = ['$scope', '$state', 'CoreService', 'Chart'];

    function LevelCareBoxController($scope, $state, CoreService, Chart) {
        var vm = this;

        vm.countLevelOfCare = null;
        vm.count = 0;

        vm.openCharts = openCharts;
        loadData();

        function loadData() {
            vm.countLevelOfCare = Chart.countTypeLevelOfCare({id: CoreService.getCurrentFacility().id}, function (result) {
                _.forEach(result, function (el) {
                    vm.count += el.count;
                });
            });
        }

        function openCharts(levelCare) {
            $state.go('charts', {levelCare: levelCare});
        }
    }
})();

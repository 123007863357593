(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartBlockedDialogController', ChartBlockedDialogController);

    ChartBlockedDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'ChartBlocked', 'Employee', 'Chart', 'Corporation', 'facility', 'CoreService'];

    function ChartBlockedDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                          ChartBlocked, Employee, Chart, Corporation, facility, CoreService) {
        var vm = this;

        vm.chartBlocked = entity;
        vm.corporation = CoreService.getCorporation();

        vm.employees = vm.chartBlocked.id == null ? [] : [vm.chartBlocked.employee];

        vm.clear = clear;
        vm.save = save;
        vm.getCharts = getCharts;
        vm.getEmployees = getEmployees;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getCharts(query) {
            if (query && query.length > 3) {
                var filter = {};

                filter.page = 0;
                filter.size = 20;
                filter.corporationId = vm.corporation.id;
                filter.searchQuery = query;

                Chart.byCorporation(filter, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.charts = data;
            }

            function onError(error) {
            }
        }

        function save() {
            vm.isSaving = true;
            if (vm.chartBlocked.id !== null) {
                ChartBlocked.update(vm.chartBlocked, onSaveSuccess, onSaveError);
            } else {
                ChartBlocked.save(vm.chartBlocked, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:chartBlockedUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getEmployees(query) {
            if (vm.chartBlocked.id) {
                return;
            }

            var filter = {page: 0, size: 20, query: query, corporationId: vm.corporation.id};

            Employee.findAllWithoutChartBlocked(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmNoteController', CrmNoteController);

    CrmNoteController.$inject = ['CrmNote', 'AlertService', 'entity', '$uibModal', 'CoreService', '$sce'];

    function CrmNoteController(CrmNote, AlertService, entity, $uibModal, CoreService, $sce) {
        var vm = this;

        vm.crmOpportunity = entity;
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.crmNotes = [];
        vm.pinCrmNotes = [];
        vm.groupedCrmNotes = [];
        vm.employee = CoreService.getCurrentEmployee();

        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.isOwner = isOwner;
        vm.pin = pin;
        vm.getUnpin = getUnpin;
        vm.trustAsHtml = trustAsHtml;

        loadAll();

        function loadAll() {
            CrmNote.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                pin: true,
                unpaged: true
            }, function (data, headers) {
                vm.pinCrmNotes = data;
            }, onError);

            getUnpin();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-note/crm-note-dialog.html',
                controller: 'CrmNoteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            note: null,
                            pin: false,
                            id: null,
                            crmOpportunity: vm.crmOpportunity,
                            employee: CoreService.getCurrentEmployee(),
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-note/crm-note-dialog.html',
                controller: 'CrmNoteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CrmNote', function (CrmNote) {
                        return CrmNote.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function del(id) {
            CrmNote.delete({id: id}, function () {
                loadAll();
            });
        }

        function isOwner(note) {
            return vm.employee.id == note.employee.id;
        }

        function pin(note) {
            CrmNote.pin(note, function () {
                loadAll();
            })
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getUnpin() {
            CrmNote.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                pin: false,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, function (data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.crmNotes = data;
            }, onError);
        }

        function trustAsHtml(note) {
            return $sce.trustAsHtml(note);
        }
    }
})();

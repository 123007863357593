(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Chart', Chart);

    Chart.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function Chart($resource, DateUtils, DataUtils) {
        var resourceUrl = 'api/charts/:id';

        function transformCountResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data = {value: data};
            }
            return data;
        }

        function transformChartResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.admissionDate = DateUtils.convertDateTimeFromServer(data.admissionDate);
                data.dischargeDate = DateUtils.convertDateTimeFromServer(data.dischargeDate);
                data.dischargeTime = DateUtils.convertDateTimeFromServer(data.dischargeTime);
                data.dateFirstContact = DateUtils.convertDateTimeFromServer(data.dateFirstContact);
                data.sobrietyDate = DateUtils.convertDateTimeFromServer(data.sobrietyDate);
                data.sailingDate = DateUtils.convertDateTimeFromServer(data.sailingDate);
                // data.phone = parseInt(data.phone);
                // data.altPhone = parseInt(data.altPhone);

                if (data.insurances) {
                    for (var i = 0; i < data.insurances.length; i++) {
                        data.insurances[i].dob = DateUtils.convertLocalDateFromServer(data.insurances[i].dob);
                    }
                }

                data.dateBirth = DateUtils.convertLocalDateFromServer(data.dateBirth);
                data.fullName = data.lastName + ', ' + data.firstName + ' ' + (data.middleName ? data.middleName : '');
            }
            return data;
        }

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: transformChartResponse
            },
            'update': {method: 'PUT'},
            'previousCharts': {'url': 'api/charts/previous/:id', method: 'GET', isArray: true},
            'countByFacilities': {'url': 'api/charts/count-by-facilities', method: 'GET', isArray: true},
            'notInShift': {url: 'api/charts/not-in-shift/:shiftId', method: 'GET', isArray: true},
            'countTypeLevelOfCare': {url: 'api/charts/count-type-level-care/:id', method: 'GET', isArray: true},
            'withoutDiagnosis': {url: 'api/charts/without-diagnosis', method: 'GET', isArray: true},
            'withoutInsurance': {url: 'api/charts/without-insurance', method: 'GET', isArray: true},
            'withoutCareTeam': {url: 'api/charts/without-care-team', method: 'GET', isArray: true},
            'countDischargeType': {url: 'api/charts/count-discharge-type/:id', method: 'GET', isArray: true},
            'countChartsWithMarketers': {url: 'api/charts/count-charts-marketer/:id', method: 'GET', isArray: true},
            'countReferrers': {url: 'api/charts/count-referrers/:id', method: 'GET', isArray: true},
            'countCaseLoad': {url: 'api/charts/count-case-load/:id', method: 'GET', isArray: true},
            'withoutConcurrentReview': {url: 'api/charts/without-concurrent-reviews/:id', method: 'GET', isArray: true},
            'expiredConcurrentReview': {url: 'api/charts/expired-concurrent-reviews/:id', method: 'GET', isArray: true},
            'expiringConcurrentReview': {
                url: 'api/charts/expiring-concurrent-reviews/:id',
                method: 'GET',
                isArray: true
            },
            'filterLastClosedOrArchive': {
                'url': 'api/charts/filter-last-closed-or-archive',
                method: 'GET',
                isArray: true
            },
            'getCurrentCensusPdf': {
                url: 'api/charts/pdf-current-census/:id',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'getAllPayers': {url: 'api/charts/payer/:id', method: 'GET', isArray: true},
            'getAllChartsPayerNoInsurance': {url: 'api/charts/payer-no-insurance/filter', method: 'GET', isArray: true},
            'getAllChartsPayerInsurancePrimary': {
                url: 'api/charts/payer-insurance-primary/filter',
                method: 'GET',
                isArray: true
            },
            'getAllChartsPayerInsuranceNoPrimary': {
                url: 'api/charts/payer-insurance-no-primary/filter',
                method: 'GET',
                isArray: true
            },
            'create': {method: 'POST', url: 'api/charts/create'},
            'countAllByChart': {
                url: 'api/charts/count-all-by-chart/:id',
                method: 'GET',
                isArray: false,
                transformResponse: transformCountResponse
            },
            'getFacesheetTplPdf': {
                url: 'api/charts/pdf-facesheet-tpl/:chartId/:id',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'getBedsheetPdf': {
                url: 'api/charts/pdf-bedsheet/:id',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'getAllByCampaign': {url: 'api/charts/by-campaign/filter', method: 'GET', isArray: true},
            'getAllReferrerChartByFilter': {url: 'api/charts/referrer/filter', method: 'GET', isArray: true},
            'discharging': {'url': 'api/charts/discharging', method: 'GET', isArray: true},
            'incoming': {'url': 'api/charts/incoming', method: 'GET', isArray: true},
            'fullFilter': {'url': 'api/charts/full-filter', method: 'GET', isArray: true},
            'chartsDischarge': {'url': 'api/charts/charts-discharge', method: 'GET', isArray: true},
            'marketer': {'url': 'api/charts/by-marketer', method: 'GET', isArray: true},
            'byCorporation': {url: 'api/charts/by-corporation', method: 'GET', isArray: true},
            'dischargeDateHistory': {url: 'api/charts/discharge-date-history', method: 'GET', isArray: true},
            'copy': {url: 'api/charts/copy', method: 'POST'},
            'findAllNotInGroupSession': {url: 'api/charts/not-in-group-session', method: 'GET', isArray: true},
            'byEvaluation': {
                url: 'api/charts/by-evaluation/:id',
                method: 'GET',
                transformResponse: transformChartResponse
            },
            'aveLengthOfStay': {
                url: 'api/charts/count-ave-length/:id',
                method: 'GET',
                transformResponse: transformCountResponse
            },
            'uploadRc': {url: 'api/charts/upload-rc', method: 'POST', isArray: true},
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('IncidentReportNote', IncidentReportNote);

    IncidentReportNote.$inject = ['$resource', 'OdsFormService'];

    function IncidentReportNote($resource, OdsFormService) {
        var resourceUrl = 'api/incident-report-notes/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.jsonTemplate = null
                    }
                    return data;
                }
            },
            'autoSave': {
                url: 'api/incident-report-notes/auto-save',
                method: 'POST',
                ignoreLoadingBar: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jsonTemplate = angular.toJson(copy.jsonTemplate);

                    return angular.toJson(copy);
                }
            },
            'getAllByIncidentReport': {
                url: 'api/incident-report-notes/by-incident-report/:id',
                method: 'GET',
                isArray: true
            },
            'create': {url: 'api/incident-report-notes/create', method: 'POST', isArray: true},
            'getJsonTemplate': {
                url: 'api/incident-report-notes/get-json-template/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        // data = angular.fromJson(data);
                        data = OdsFormService.convertFormSchemaFromServer(data);
                        // data = angular.fromJson(data);
                    }

                    return data;
                },
            },
            'sign': {method: 'POST', url: 'api/incident-report-notes/sign'},
        });
    }
})();

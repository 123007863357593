(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CovidFacilityLabController', CovidFacilityLabController);

    CovidFacilityLabController.$inject = ['$state', 'CovidFacilityLab', 'CoreService', 'GenericEntityDatatableService'];

    function CovidFacilityLabController ($state, CovidFacilityLab, CoreService, GenericEntityDatatableService) {
        var vm = this;

        vm.totalItems = 0;

        var params = {
            id: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'covid-facility-lab'

        vm.descriptor = {
            title: 'Covid Lab Integration',
            entityClassHumanized: 'Covid Lab Integration',
            entityStateName: entityStateName,
            service: CovidFacilityLab,
            serviceMethod: 'findAllByFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    title: 'Laboratory',
                    render: function (data) {
                        return data.facilityLab.laboratory.name;
                    }
                },
                {
                    title: 'Facility Lab Account',
                    render: function (data) {
                        return data.facilityLab.account;
                    }
                }
            ],
            rowActions: [
                GenericEntityDatatableService.getEditAction(entityStateName),
                GenericEntityDatatableService.getDeleteAction(entityStateName),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Covid FacilityLab',
                            action: function () {
                                $state.go(entityStateName + '.new');
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0;
                            }
                        }),
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('udt')
        .filter('ccNumberFormat', function() {
            //this slice the number in 4 groups of 4 digits
            var ccFormat = /^(\d{4})?(\d{4})?(\d{4})?(\d{4})$/;

            return function(ccNumber) {
                var parsed = ccFormat.exec(ccNumber);

                return (!parsed) ? ccNumber : ((parsed[1]) ? parsed[1] + ' ' : '') + parsed[2] + ' ' + parsed[3] + ' ' + parsed[4];
            }
        });
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('Assistant', Assistant);

    Assistant.$inject = ['$resource', 'Utils'];

    function Assistant ($resource, Utils) {
        var resourceUrl =  'api/assistants/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.sections) {
                            data.sections = Utils.objectRoleToArray(data.sections);
                        }
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'sections': {url: 'api/assistants/sections', method: 'GET', isArray: true},
            'findAllBySection': {url: 'api/assistants/by-sections/:section/facility/:facility', method: 'GET', isArray: true},
            'search': {url: 'api/assistants/search', method: 'GET', isArray: true},
        });
    }
})();

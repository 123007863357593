(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EmployeeNewChart', EmployeeNewChart);

    EmployeeNewChart.$inject = ['$resource'];

    function EmployeeNewChart($resource) {
        var resourceUrl = 'api/employee-new-charts/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getShowNewChart': {
                method: 'GET', url: 'api/employee-new-charts/show-new-chart', isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = {value: data};
                    }

                    return data;
                }
            }
        });
    }
})();

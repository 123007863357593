(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PdfChartReport', PdfChartReport);

    PdfChartReport.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function PdfChartReport($resource, DateUtils, DataUtils) {
        var resourceUrl = 'api/pdf-chart-reports/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateTimeRequested = DateUtils.convertDateTimeFromServer(data.dateTimeRequested);
                    }
                    return data;
                }
            },
            'getPdfChartReport': {url: 'api/pdf-chart-reports', method: 'GET', isArray: true},
            'getPdfChartReportSelected': {url: 'api/pdf-chart-reports-selected/:listId', method: 'GET', isArray: true},
            'deletePdfChartReport': {url: 'api/pdf-chart-reports-delete/:listId', method: 'GET', isArray: true},
            'download': {
                url: 'api/pdf-chart-reports/download/:id',
                responseType: 'arraybuffer',
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'downloadPdfObject': {
                url: 'api/pdf-chart-reports/download/:id',
                method: 'GET',
                isArray: false
            },
            'filter': {url: 'api/pdf-chart-reports/filter', method: 'GET', isArray: true},
            'renew': {url: 'api/pdf-chart-reports/renew/:id', method: 'GET'}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EmptyController', EmptyController);

    EmptyController.$inject = ['$state', 'Evaluation', 'GUIUtils', '$q', 'Employee', 'CoreService', '$uibModal',
        'DataTablesService', 'DateUtils', '$rootScope', 'TypeLevelCare', 'ConfirmationService', 'Chart',
        'GenericEntityDatatableService', 'toastr'];

    function EmptyController($state, Evaluation, GUIUtils, $q, Employee, CoreService, $uibModal,
                             DataTablesService, DateUtils, $rootScope, TypeLevelCare, ConfirmationService, Chart,
                             GenericEntityDatatableService, toastr) {
        var vm = this;

        vm.all = false;
        vm.mine = true;
        vm.credentials = CoreService.getCurrentEmployee().credentials;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
            empty: true,
            status: ['Pending', 'Late'],
        }

        vm.descriptor = {
            title: 'Empty Care & Delivery',
            entityClassHumanized: 'Empty Care & Delivery',
            entityStateName: 'evaluation',
            service: Evaluation,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[1, 'asc']]}
            ],
            selectionColumn: 'id',
            withSelectionColumn: true,
            disableSelectionColumn: function (filter) {
                return !vm.mine;
            },
            disableAllSelectionColumn: function (filter) {
                return !vm.mine;
            },
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: null,
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + " " + data.lastName;
                    }
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    options: [{key: 'width', value: '130px'}],
                    render: function (data) {
                        return moment(data.dob).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: 'width', value: '130px'}],
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    name: 'name',
                    title: "Care and Delivery"
                },
                {
                    name: null,
                    title: "Status",
                    render: function (data) {
                        return '<span class="' + CoreService.getEvaluationStatusLabelClass(data.status) + '">' + data.status + '</span>';
                    }
                },
                {
                    title: 'Created Date',
                    options: [{key: 'width', value: '200px'}],
                    render: function (data) {
                        return data.createdDate ? moment(data.createdDate).format($rootScope.dateFormat) : "";
                    }
                },
                {
                    title: 'Created By',
                    options: [{key: 'width', value: '200px'}],
                    render: function (data) {
                        return data.createdByEmployee ? data.createdByEmployee.firstName + " " + data.createdByEmployee.lastName : '-';
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getInfoAction(), {
                    name: 'See Credentials',
                    action: function (data) {
                        listCredentials(data.id);
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        $state.go('care-delivery', {id: data.id});
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return deleteEmpties([data.id]);
                    },
                })
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                            name: 'Delete Selected',
                            disabledOnEmpty: true,
                            ngClass: function () {
                                return ['bg-red']
                            },
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    var ids = _.map(selected, function (item) {
                                        return item.id;
                                    });

                                    return deleteEmpties(ids);
                                }
                            },
                            ngIf: function (filter) {
                                return vm.mine;
                            }
                        }),
                    ]
                },
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'All',
                            iClass: 'fa fa-users',
                            ngClass: function (filter) {
                                return vm.all === true ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                vm.all = true;
                                vm.mine = false;
                                vm.others = false;
                            }
                        },
                        {
                            name: 'My Care & Delivery',
                            iClass: 'fa fa-user',
                            ngClass: function (filter) {
                                return vm.mine === true ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                vm.mine = true;
                                vm.all = false;
                                vm.others = false;
                            }
                        },
                        {
                            name: 'Other Care & Delivery',
                            iClass: 'fa fa-object-ungroup',
                            ngClass: function (filter) {
                                return vm.others === true ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter, selected) {
                                vm.others = true;
                                vm.all = false;
                                vm.mine = false;
                            }
                        }
                    ],
                },
            ],
            filters: [
                {
                    name: 'staffCredentials',
                    visible: false,
                    read: function (filter) {
                        if (vm.all || vm.others) {
                            return null;
                        }

                        if (vm.mine) {
                            return vm.credentials;
                        }
                    }
                },
                {
                    name: 'excludeStaffCredentials',
                    visible: false,
                    read: function (filter) {
                        if (vm.all || vm.mine) {
                            return null;
                        }

                        if (vm.others) {
                            return vm.credentials;
                        }
                    }
                },
                {
                    name: 'name',
                    type: 'string',
                    placeholder: 'Care & Delivery Name...',
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12',
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                },
                {
                    name: 'mrno',
                    type: 'string',
                    placeholder: 'Mr Number',
                },
                {
                    name: 'dob',
                    // title: 'Day of Birth',
                    placeholder: 'Day of Birth...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                },
                {
                    name: 'createdDate',
                    // title: 'Day of Birth',
                    placeholder: 'Created date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.createdDate ? moment(filter.createdDate).format("YYYY-MM-DD") : null;
                    },
                },
                {
                    name: 'typeLevelCare',
                    read: function (filter) {
                        return filter.typeLevelCare ? filter.typeLevelCare.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeLevelCare.findAllByFacility(
                            CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Level of Care ...',
                    type: 'select',
                },
                {
                    name: 'createdByEmployeeId',
                    read: function (filter) {
                        return filter.createdByEmployeeId ? filter.createdByEmployeeId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    refresh: function (search, values) {
                        var filter = {
                            page: 0,
                            size: 10,
                            query: search,
                            facilityId: CoreService.getCurrentFacility().id
                        };

                        Employee.findAllByFilter(filter, function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    placeholder: 'Select Employee ...',
                    type: 'select',
                },
            ]
        }

        function deleteEmpties(selected) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Empty Care & Delivery(s)').result.then(function () {
                    Evaluation.deleteEmpties({ids: selected},
                        function () {
                            toastr.success("The Empty Care and Delivery was deleted successfully.");
                            resolve();
                        }, function () {
                            if (selected.length > 1) {
                                toastr.success("The selected Empty Care and Delivery have been deleted.");
                            } else {
                                toastr.error("The selected Empty Care and Delivery did not was deleted, see info.");
                            }

                            resolve()
                        });
                })
            });
        }

        function listCredentials(id) {
            Evaluation.get({id: id}, function (result) {
                ConfirmationService.showCredentials(result.staffSignatureCredentials);
            })
        }
    }
})();

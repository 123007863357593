(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('care-delivery', {
                parent: 'entity',
                url: '/care-delivery?id&processId',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                params: {
                    id: null,
                    processId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/update/evaluation-edit.html',
                        controller: 'EvaluationEditController',
                        controllerAs: 'vm'
                    },
                    'diagnoses@care-delivery': {
                        templateUrl: 'app/entities/evaluation-diagnosis/list/evaluation-diagnosis.html',
                        controller: 'EvaluationDiagnosisController',
                        controllerAs: 'vm'
                    },
                    'allergies@care-delivery': {
                        templateUrl: 'app/entities/evaluation-allergy/list/evaluation-allergy.html',
                        controller: 'EvaluationAllergyController',
                        controllerAs: 'vm'
                    },
                    'diets@care-delivery': {
                        templateUrl: 'app/entities/evaluation-diet/list/evaluation-diets.html',
                        controller: 'EvaluationDietController',
                        controllerAs: 'vm'
                    },
                    'vitals@care-delivery': {
                        templateUrl: 'app/entities/evaluation-vital/list/evaluation-vital.html',
                        controller: 'EvaluationVitalController',
                        controllerAs: 'vm'
                    },
                    'glucose@care-delivery': {
                        templateUrl: 'app/entities/evaluation-glucose/list/evaluation-glucoses.html',
                        controller: 'EvaluationGlucoseController',
                        controllerAs: 'vm'
                    },
                    'weight@care-delivery': {
                        templateUrl: 'app/entities/evaluation-weight/list/evaluation-weights.html',
                        controller: 'EvaluationWeightController',
                        controllerAs: 'vm'
                    },
                    'comments@care-delivery': {
                        templateUrl: 'app/entities/evaluation-comment/list/evaluation-comment.html',
                        controller: 'EvaluationCommentController',
                        controllerAs: 'vm'
                    },
                    'medications@care-delivery': {
                        templateUrl: 'app/entities/evaluation-medication/list/evaluation-medications.html',
                        controller: 'EvaluationMedicationController',
                        controllerAs: 'vm'
                    },
                    'chart-urgent-issue@care-delivery': {
                        templateUrl: 'app/entities/urgent-issues/chart/new/list/urgent-issues.html',
                        controller: 'UrgentIssuesController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    entity: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.start({id: $stateParams.id}).$promise;
                    }],
                    jsonTemplate: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.getJsonTemplate({id: $stateParams.id}).$promise;
                    }],
                    tokens: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.getEvaluationTokensValues({id: $stateParams.id}).$promise;
                    }],
                    params: ['$location', '$stateParams', function ($location, $stateParams) {
                        return {
                            processId: $stateParams.processId,
                            processPage: $stateParams.processPage
                        };
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [
                            ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW
                        ];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    chart: ['$stateParams', 'Chart', function ($stateParams, Chart) {
                        return Chart.byEvaluation({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('care-delivery-view', {
                parent: 'entity',
                url: '/care-delivery-view/{id}',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/detail/evaluation-view.html',
                        controller: 'EvaluationViewController',
                        controllerAs: 'vm'
                    },
                    'comments@care-delivery-view': {
                        templateUrl: 'app/entities/evaluation-comment/list/evaluation-comment.html',
                        controller: 'EvaluationCommentController',
                        controllerAs: 'vm'
                    },
                    'medications@care-delivery-view': {
                        templateUrl: 'app/entities/evaluation-medication/list/evaluation-medications-view.html',
                        controller: 'EvaluationMedicationController',
                        controllerAs: 'vm'
                    },
                    'chart-urgent-issue@care-delivery-view': {
                        templateUrl: 'app/entities/urgent-issues/chart/new/list/urgent-issues.html',
                        controller: 'UrgentIssuesController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    entity: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.get({id: $stateParams.id}).$promise;
                    }],
                    jsonTemplate: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.getJsonTemplate({id: $stateParams.id}).$promise;
                    }],
                    tokens: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.getEvaluationTokensValues({id: $stateParams.id}).$promise;
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [
                            ACTIONS.ACTION_PATIENT_CARE_COORDINATION_VIEW
                        ];
                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    chart: ['$stateParams', 'Chart', function ($stateParams, Chart) {
                        return Chart.byEvaluation({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('evaluation-maintenance', {
                parent: 'entity',
                url: '/evaluation-maintenance',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/maintenance/evaluation-maintenance.html',
                        controller: 'EvaluationMaintenanceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('master-edit', {
                parent: 'entity',
                url: '/master-edit?id&processId',
                data: {},
                params: {
                    id: null,
                    processId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/master/evaluation-master.html',
                        controller: 'EvaluationMasterEditController',
                        controllerAs: 'vm'
                    },
                    'diagnoses@master-edit': {
                        templateUrl: 'app/entities/evaluation-diagnosis/list/evaluation-diagnosis.html',
                        controller: 'EvaluationDiagnosisController',
                        controllerAs: 'vm'
                    },
                    'allergies@master-edit': {
                        templateUrl: 'app/entities/evaluation-allergy/list/evaluation-allergy.html',
                        controller: 'EvaluationAllergyController',
                        controllerAs: 'vm'
                    },
                    'diets@master-edit': {
                        templateUrl: 'app/entities/evaluation-diet/list/evaluation-diets.html',
                        controller: 'EvaluationDietController',
                        controllerAs: 'vm'
                    },
                    'vitals@master-edit': {
                        templateUrl: 'app/entities/evaluation-vital/list/evaluation-vital.html',
                        controller: 'EvaluationVitalController',
                        controllerAs: 'vm'
                    },
                    'glucose@master-edit': {
                        templateUrl: 'app/entities/evaluation-glucose/list/evaluation-glucoses.html',
                        controller: 'EvaluationGlucoseController',
                        controllerAs: 'vm'
                    },
                    'weight@master-edit': {
                        templateUrl: 'app/entities/evaluation-weight/list/evaluation-weights.html',
                        controller: 'EvaluationWeightController',
                        controllerAs: 'vm'
                    },
                    'comments@master-edit': {
                        templateUrl: 'app/entities/evaluation-comment/list/evaluation-comment.html',
                        controller: 'EvaluationCommentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MASTER_EDIT];

                        return Auth.loadActionsAccess(actions).$promise;
                    }],
                    entity: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.get({id: $stateParams.id}).$promise;
                    }],
                    jsonTemplate: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.getJsonTemplate({id: $stateParams.id}).$promise;
                    }],
                    tokens: ['$stateParams', 'Evaluation', function ($stateParams, Evaluation) {
                        return Evaluation.getEvaluationTokensValues({id: $stateParams.id}).$promise;
                    }],
                    params: ['$location', '$stateParams', function ($location, $stateParams) {
                        return {
                            processId: $stateParams.processId,
                            processPage: $stateParams.processPage
                        };
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MASTER_EDIT, access);
                }]
            })
            .state('consent-boost', {
                parent: 'entity',
                url: '/consent-boost?chartId&processId',
                data: {
                    authorities: [],
                    pageTitle: 'Consent Boost'
                },
                ncyBreadcrumb: {
                    parent: 'chart',
                    label: 'Consent Boost'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/evaluation/consent-boost/list/consent-boost.html',
                        controller: 'ConsentBoostController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    chart: null,
                    chartId: null,
                    process: null,
                    processId: null
                },
                resolve: {
                    chart: ['$stateParams', 'Chart', function ($stateParams, Chart) {
                        if ($stateParams.chart == null) {

                            return Chart.get({id: $stateParams.chartId}).$promise;
                        } else {

                            return $stateParams.chart;
                        }
                    }],
                    process: ['$stateParams', 'TypePatientProcess', function ($stateParams, TypePatientProcess) {
                        if ($stateParams.chart == null) {

                            return TypePatientProcess.get({id: $stateParams.processId}).$promise;
                        } else {

                            return $stateParams.process;
                        }
                    }]
                }
            });
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('AuthItem', AuthItem);

    AuthItem.$inject = ['$resource'];

    function AuthItem ($resource) {
        var resourceUrl =  'api/auth-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getRole': {
                url: 'api/auth-items/role/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryByType': {
                url: 'api/auth-items/type/:type/:id',
                method: 'GET',
                isArray: true
            },
            'loadActions': {
                url: '/api/authorization/actions',
                method: 'POST',
                isArray: false
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateCloneToFacilityController',EvaluationTemplateCloneToFacilityController);

    EvaluationTemplateCloneToFacilityController.$inject = ['$uibModalInstance', 'entity', 'EvaluationTemplate', 'Facility'];

    function EvaluationTemplateCloneToFacilityController($uibModalInstance, entity, EvaluationTemplate, Facility) {
        var vm = this;

        vm.evaluationTemplate = entity;
        vm.showDelete = false;

        vm.clear = clear;
        vm.confirmClone = confirmClone;

        Facility.getAll(function (result) {
            vm.facilities = result;
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmClone () {
            EvaluationTemplate.cloneToFacility({id: vm.evaluationTemplate.id, facilityId: vm.selectedFacility.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('VitalsChartController', VitalsChartController);

    VitalsChartController.$inject = ['chart', 'Vitals', 'CoreService', '$uibModal', '$scope', '$rootScope', 'access',
        'ACTIONS', 'Auth'];

    function VitalsChartController(chart, Vitals, CoreService, $uibModal, $scope, $rootScope, access,
                                   ACTIONS, Auth) {
        var vm = this;
        vm.chart = chart;

        vm.selectGraphData = selectGraphData;
        vm.toggleItem = toggleItem;
        vm.add = add;
        vm.history = history;

        vm.dataVitals = [];

        vm.systolicData = {
            values: [],
            key: 'Systolic',
            color: '#ffffff'
        };

        vm.diastolicData = {
            values: [],
            key: 'Diastolic',
            color: '#ffffff'
        };

        vm.tempData = {
            values: [],
            key: 'Temperature',
            color: '#ffffff'
        };

        vm.pulseData = {
            values: [],
            key: 'Pulse',
            color: '#ffffff'
        };

        vm.respirationData = {
            values: [],
            key: 'Respiration',
            color: '#ffffff'
        };

        vm.o2Data = {
            values: [],
            key: 'O2 Saturation',
            color: '#ffffff'
        };

        vm.optionsVitals = {
            chart: {
                type: 'lineChart',
                showLegend: false,
                height: 340,
                margin: {
                    top: 20,
                    right: 50,
                    bottom: 100,
                    left: 50
                },
                x: function (d) {
                    return d.x;
                },
                y: function (d) {
                    return d.y;
                },
                useInteractiveGuideline: true,
                xAxis: {
                    tickFormat: function (d) {
                        return d3.time.format('%m/%d/%y-%H:%M')(new Date(d));
                    },
                    showMaxMin: false,
                    rotateLabels:(-70)
                },
                yAxis: {
                    tickFormat: function (d) {
                        return d3.format('.02f')(d);
                    },
                    showMaxMin: false
                }
            }
        };

        loadAll();

        function loadAll() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_VITALS_VIEW, access)) {
                return;
            }

            vm.vitals = Vitals.findLastByChart({id: chart.id}, function(result) {
                if (result) {
                    vm.isVitalsDayOld = moment(new Date()).diff(moment(result.date), 'hours', true) > 24;
                }
            });

            Vitals.findLastBp({id: chart.id, size: 10}, function (data) {
                for (var i = data.length - 1; i >= 0; i--) {
                    vm.systolicData.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value1)});
                    vm.diastolicData.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value2)});
                }

                vm.selectGraphData('blood');
            });

            Vitals.findLastTemp({id: chart.id, size: 10}, function (data) {
                deployList(vm.tempData, data);
            });

            Vitals.findLastRespiration({id: chart.id, size: 10}, function (data) {
                deployList(vm.respirationData, data);
            });

            Vitals.findLastPulse({id: chart.id, size: 10}, function (data) {
                deployList(vm.pulseData, data);
            });

            Vitals.findLastO2({id: chart.id, size: 10}, function (data) {
                deployList(vm.o2Data, data);
            });
        }

        function selectGraphData(value) {
            switch (value) {
                case 'blood' : {
                    vm.dataVitals = [];
                    vm.dataVitals.push(vm.systolicData);
                    vm.dataVitals.push(vm.diastolicData);

                    break;
                }
                case 'temp' : {
                    vm.dataVitals = [];
                    vm.dataVitals.push(vm.tempData);

                    break;
                }
                case 'pulse' : {
                    vm.dataVitals = [];
                    vm.dataVitals.push(vm.pulseData);

                    break;
                }
                case 'respiration' : {
                    vm.dataVitals = [];
                    vm.dataVitals.push(vm.respirationData);
                    break;
                }
                case 'o2' : {
                    vm.dataVitals = [];
                    vm.dataVitals.push(vm.o2Data);
                    break;
                }
            }
        }

        function deployList(list, data) {
            for (var i = data.length - 1; i >= 0; i--) {
                list.values.push({x: new Date(data[i].date).getTime(), y: parseFloat(data[i].value1)});
            }
        }

        function toggleItem() {
            CoreService.togglePanel('vitals');
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/vitals/update/vitals-dialog.html',
                controller: 'VitalsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            status: null,
                            id: null,
                            chart: chart,
                            date: new Date()
                        };
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/vitals/history/vitals.html',
                controller: 'VitalsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartVitalsHistory', function (event, result) {
           history();
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();

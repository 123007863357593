(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ContactsFacilityController', ContactsFacilityController);

    ContactsFacilityController.$inject = ['ContactsFacility', 'CoreService', 'GenericEntityDatatableService',
        '$uibModal', 'ConfirmationService', '$q'];

    function ContactsFacilityController(ContactsFacility, CoreService, GenericEntityDatatableService,
                                        $uibModal, ConfirmationService, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Contacts',
            newButtonTitle: 'New Contact',
            entityClassHumanized: 'Contact Facility',
            entityStateName: 'contacts-facility',
            service: ContactsFacility,
            serviceMethod: 'filter',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Contact Type',
                    render: function (data) {
                        return data ? data.name : '-';
                    }
                },
                {
                    name: 'cellphone',
                    title: 'Phone'
                },
                {
                    name: 'fax',
                    title: 'Fax'
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Contact',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/contacts-facility/update/contacts-facility-dialog.html',
                controller: 'ContactsFacilityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            countryState: null,
                            typeContactFacilityType: null,
                            id: null
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/contacts-facility/update/contacts-facility-dialog.html',
                controller: 'ContactsFacilityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['ContactsFacility', function (ContactsFacility) {
                        return ContactsFacility.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Contact').result.then(function () {
                    ContactsFacility.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('LabRequisitionController', LabRequisitionController);

    LabRequisitionController.$inject = ['$q', 'GUIUtils', 'PatientOrder', 'DYMO', 'CoreService', 'dialogs', '$uibModal',
        'DateUtils', '$rootScope', 'PopupService', 'access', 'ACTIONS', 'LabRequisitionSignature',
        'Chart', 'toastr', 'Principal', 'GenericEntityDatatableService'];

    function LabRequisitionController($q, GUIUtils, PatientOrder, DYMO, CoreService, dialogs, $uibModal,
                                      DateUtils, $rootScope, PopupService, access, ACTIONS, LabRequisitionSignature,
                                      Chart, toastr, Principal, GenericEntityDatatableService) {

        var vm = this;

        vm.rmConfig = {
            mondayStart: false,
            initState: "month", /* decade || year || month */
            maxState: "decade",
            minState: "month",
            decadeSize: 12,
            monthSize: 42, /* "auto" || fixed nr. (35 or 42) */
            min: new Date("2016-01-01"),
            max: null,
            format: "yyyy-MM-dd",
            markDates: function (date) {
                return PatientOrder.markDates({facilityId: CoreService.getCurrentFacility().id, date: date})
            },
            redraw: 'bluebookApp:redrawPatientOrderCalendar'
        }

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Lab Requisitions',
            entityClassHumanized: 'Requisition',
            entityStateName: 'requisition',
            service: PatientOrder,
            serviceMethod: 'filter',
            params: params,
            // pagination: 'local',
            newAction: false,
            options: [{key: 'aaSorting', value: [[0, 'asc']]}],
            localSearch: false,
            withSelectionColumn: true,
            selectionColumn: 'patientOrderId',
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + (data.middleName == null ? " " : " " + data.middleName + " ") + data.lastName;
                    }
                },
                {
                    name: 'patientOrderId',
                    title: 'Order',
                },
                {
                    name: 'dateBirth',
                    title: 'D.O.B',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY");
                    }
                },
                {
                    name: 'barcode',
                    title: 'Barcode',
                    render: function (data) {
                        return data.barcode ? GUIUtils.getStatusTemplate(data.barcode.toString(), 'primary') : null;
                    }
                },
                {
                    name: 'laboratory',
                    title: 'Laboratory',
                    render: function (data) {
                        if (data.laboratoryName) {
                            return data.laboratoryName && data.laboratoryName.length > 20 ? data.laboratoryName.substr(0, 20) + "..." : data.laboratoryName;
                        } else {
                            return "-";
                        }
                    }
                },
                {
                    name: 'status',
                    title: 'Status',
                    render: function (data) {
                        return data.itemStatus ? GUIUtils.colorHtmlStatusRequisition(data.itemStatus) : null;
                    }
                },
                {
                    name: null,
                    title: 'Signed',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.signed ? true : false);
                    }
                }
            ],
            rowActions: [
                // GenericEntityDatatableService.getEditAction('acuity'),
                // GenericEntityDatatableService.getDeleteAction('acuity')
                {
                    name: 'Sign',
                    action: function (data) {
                        return vm.addSignature(data.chartId, data.barcode);
                    },
                    aClass: 'dd-link green',
                    iClass: 'fa fa-edit',
                    ngIf: function (data) {
                        return !data.signatureId && data.barcode;
                    }
                },
                {
                    name: 'Requisition PDF',
                    aClass: 'dd-link orange',
                    iClass: 'fa fa-file-pdf-o',
                    ngIf: function (data) {
                        return data.barcode;
                    },
                    action: function (data) {
                        PatientOrder.getPDFLabRequisition({barcode: data.barcode});
                    }
                },
                {
                    name: 'Details',
                    aClass: 'dd-link blue',
                    iClass: 'fa fa-file-text',
                    action: function (data) {
                        PopupService.showLabRequisitionDetailPopup(data.patientOrderId, data.barcode, data.scheduleDate);
                    }
                },
                {
                    name: 'Add Results',
                    aClass: 'dd-link red',
                    iClass: 'fa fa-barcode',
                    ngIf: function (data) {
                        return data.barcode && !data.patientResultId;
                    },
                    action: function (data) {
                        return vm.addPatientResult(data.barcode);
                    }
                },
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Requisition',
                            disabledOnEmpty: false,
                            action: function () {
                                return vm.create();
                            }
                        })
                    ]
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'Add Order',
                            ngIf: function () {
                                var accessAddPatientOrder = access[ACTIONS.ACTION_ADD_PATIENT_ORDER_LAB_REQUISITION].roles;

                                return Principal.hasAnyAuthority(accessAddPatientOrder);
                            },
                            action: function () {
                                return vm.addLabOrder();
                            }
                        })
                    ]
                }
            ],
            actions: [
                {
                    type: 'split',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getPrintAction(), {
                            disabledOnEmpty: true,
                            ngClass: function (filter) {
                                return ['bg-green'];
                            },
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.printLabels(selected)
                                }
                            },
                        }),
                        angular.extend({}, GenericEntityDatatableService.getPrintAction(), {
                            name: 'Print in DYMO Printer',
                            disabledOnEmpty: true,
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.printDYMOLabels(selected)
                                }
                            }
                        })
                    ]
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getHourGlassAction(), {
                            name: 'Manifest',
                            disabledOnEmpty: true,
                            ngClass: function () {
                                return ['label-primary']
                            },
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.manifest(selected)
                                }
                            }
                        }),
                    ]
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCalendarAction(), {
                            name: 'Change Date',
                            disabledOnEmpty: true,
                            ngClass: function () {
                                return ['label-warning']
                            },
                            action: function (filter, selected) {
                                if (selected.length > 0) {
                                    return vm.changeDrawDate(selected)
                                }
                            }
                        })
                    ]
                },
            ],
            filters: [
                {
                    name: 'firstName',
                    type: 'string',
                    placeholder: 'First Name',
                    visible: true
                },
                {
                    name: 'lastName',
                    type: 'string',
                    placeholder: 'Last Name',
                    visible: true
                },
                {
                    name: 'dob',
                    placeholder: 'D.O.B...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'barcode',
                    // title: 'Barcode',
                    placeholder: 'Barcode...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'status',
                    placeholder: 'Status...',
                    type: 'select',
                    values: GUIUtils.getItemStatus(),
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                    visible: true
                },
                {
                    name: 'laboratory',
                    // title: 'Barcode',
                    placeholder: 'Laboratory...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'date',
                    type: 'rm2-datepicker',
                    format: $rootScope.inputDateFormat,
                    rmConfig: vm.rmConfig,
                    read: function (filter) {
                        return filter.date ? moment(filter.date).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date()
                    },
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12',
                    visible: true
                }
            ],
            filterCallback: function (filter) {
                vm.date = filter.date;
            }
        }

        vm.date = new Date();
        vm.itemStatus = GUIUtils.getItemStatus();
        vm.facility = CoreService.getCurrentFacility();

        vm.detail = detail;
        vm.create = create;
        vm.manifest = manifest;
        vm.printLabels = printLabels;
        vm.changeDrawDate = changeDrawDate;
        vm.labRequisitionPDF = labRequisitionPDF;
        vm.getSelectedBarcodes = getSelectedBarcodes;
        vm.printDYMOLabels = printDYMOLabels;
        vm.addLabOrder = addLabOrder;
        vm.addPatientResult = addPatientResult;
        vm.addSignature = addSignature;

        function getSelectedBarcodes2(selected) {
            return _.map(selected, function (item) {
                return {
                    chartId: item.chartId,
                    date: DateUtils.convertLocalDateToServer(item.scheduleDate),
                    patientOrderId: item.patientOrderId,
                    barcode: item.barcode,
                    itemStatus: item.itemStatus
                }
            });
        }

        function create() {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-order/lab-requisition/create/lab-requisition-dialog.html',
                controller: 'LabRequisitionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    date: [function () {
                        return vm.date;
                    }]
                }
            }).result;
        }

        function changeDrawDate(selected) {
            return $q(function (resolve) {
                var dlg = dialogs.confirm('Please Confirm', 'Are you sure you want to change the draw day? Items with results data will not be changed', {size: 'md'});

                dlg.result.then(function (btn) {
                    $uibModal.open({
                        templateUrl: 'app/entities/window-picker/date-picker-dialog.html',
                        controller: 'DatePickerDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        windowTopClass: 'custom-dialog-styles',
                        resolve: {
                            date: new Date()
                        }
                    }).result.then(function (result) {
                        var values = _.map(getSelectedBarcodes2(selected), function (value) {
                            value.newDate = DateUtils.convertLocalDateToServer(result.date);

                            return value;
                        });

                        values = _.filter(values, function (value) {
                            return value.itemStatus === 'SCHEDULED' || value.itemStatus === 'PRINTED';
                        });

                        if (values.length > 0) {
                            PatientOrder.changeDrawDay({drawDays: values}, function (result) {
                                $rootScope.$broadcast('bluebookApp:redrawPatientOrderCalendar');
                                resolve();
                            }, function () {
                                alert("Error change draw days orders");
                                resolve();
                            });
                        }
                    });
                })
            })
        }

        function printLabels(selected) {
            var values = getSelectedBarcodes2(selected);

            if (values.length > 0) {
                return $q(function (resolve, reject) {
                    PatientOrder.pdfBarcodeLabels({
                        facilityId: vm.facility.id,
                        printBarcode: values
                    }, function () {
                        resolve();
                    });
                });
            }
        }

        function printDYMOLabels(selected) {
            var values = getSelectedBarcodes2(selected);

            if (values.length > 0) {
                return $q(function (resolve, reject) {
                    PatientOrder.getBarcodeLabels({
                        facilityId: vm.facility.id,
                        printBarcode: values
                    }, function (result) {
                        _.forEach(result, function (label) {
                            DYMO.printPatientOrderLabel({
                                barcode: label.barcode,
                                name: label.firstName + (label.middleName ? label.middleName + " " : " ") + label.lastName,
                                dob: label.dateBirth,
                                collectedDate: label.scheduleDate,
                                tube: label.tube,
                                account: label.accountNo
                            })
                        });

                        resolve();
                    });
                });
            }
        }

        function getSelectedBarcodes() {
            var values = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var index = _.findIndex(vm.orders, function (order) {
                        return order.patientOrderId == parseInt(id);
                    });
                    values.push({
                        chartId: vm.orders[index].chartId,
                        date: DateUtils.convertLocalDateToServer(vm.orders[index].scheduleDate),
                        patientOrderId: vm.orders[index].patientOrderId,
                        barcode: vm.orders[index].barcode,
                        itemStatus: vm.orders[index].itemStatus
                    });
                }
            }

            return values;
        }

        function manifest(selected) {
            var values = _.map(getSelectedBarcodes2(selected), function (value) {
                return value.barcode;
            });

            return $q(function (resolve, reject) {
                PatientOrder.getPDFManifest({facilityId: vm.facility.id, barcodes: values}, function () {
                    resolve();
                });
            });
        }

        function labRequisitionPDF(barcode) {
            PatientOrder.getPDFLabRequisition({barcode: barcode});
        }

        function detail(patientOrderId, barcode, date) {
            PopupService.showLabRequisitionDetailPopup(patientOrderId, barcode, date);
        }

        function addLabOrder() {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-order/lab-requisition/create-order/lab-order-create-dialog.html',
                controller: 'LabOrderCreateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    filter: function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dob: null,
                            social: null,
                            preferredName: null,
                            middleName: null
                        };
                    },
                    chart: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            oneTimeOnly: false,
                            sobrietyDate: null,
                            phone: null,
                            altPhone: null,
                            email: null,
                            address: null,
                            addressTwo: null,
                            city: null,
                            zip: null,
                            livingArrangement: null,
                            referrerRequired: null,
                            admissionDate: new Date(),
                            dischargeDate: null,
                            dischargeTo: 'N/A',
                            employees: null,
                            repName: null,
                            facility: CoreService.getCurrentFacility(),
                            status: 'WAITING',
                            occupancy: null,
                            employer: null,
                            employerPhone: null,
                            referrerRequiredContact: false,
                            dateFirstContact: null,
                            firstContactName: null,
                            firstContactRelationship: null,
                            firstContactPhone: null,
                            typePaymentMethods: null,
                            patientLicenseOriginalId: null,
                            patientLicenseId: null,
                            pictureRefId: null,
                            pictureRefOriginalId: null,
                            patient: {
                                firstName: null,
                                middleName: null,
                                lastName: null,
                                preferredName: null,
                                gender: null,
                                race: null,
                                social: null,
                                dateBirth: null
                            },
                            typeEthnicity: null,
                            allowReadmit: true,
                            readmitReason: null
                        };
                    }],
                    patientOrder: {
                        id: null,
                        chart: null,
                        signatureDate: null,
                        signed: false,
                        via: null,
                        signedBy: null,
                        discontinueBy: null,
                        discontinueCause: null,
                        staringDate: null,
                        endDate: null,
                        orderType: null,
                        icd10Code: null,
                        patientOrderTests: [],
                        patientOrderSignature: null
                    },
                    facility: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentFacility();
                    }],
                    itHasCredentials: ['Employee', 'CoreService', 'BUSINESS_ROLES', function (Employee, CoreService, BUSINESS_ROLES) {
                        return Employee.checkBusinessRole({
                            facilityId: CoreService.getCurrentFacility().id,
                            businessRole: BUSINESS_ROLES.PHYSICIAN
                        }).$promise;
                    }]
                }
            }).result;
        }

        function addPatientResult(barcode) {
            return $uibModal.open({
                templateUrl: 'app/entities/patient-order/lab-requisition/add-result/add-lab-requisition-result-dialog.html',
                controller: 'AddLabRequisitionResultDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'lg',
                resolve: {
                    data: {barcode: barcode},
                    patientOrder: ['PatientOrder', function (PatientOrder) {
                        return PatientOrder.getByBarcode({barcode: barcode}).$promise;
                    }]
                }
            }).result;
        }

        function addSignature(chartId, barcode) {
            return $q(function (resolve, reject) {
                Chart.get({id: chartId}, function (chart) {
                    if (PopupService.checkSigPlusExtLiteExtension()) {
                        startSign(barcode, resolve);
                    } else {
                        PopupService.showPatientSignaturePopup(chart).result.then(function (signature) {
                            LabRequisitionSignature.save({
                                barcode: barcode,
                                signature: signature
                            }, function () {
                                resolve();
                            }, function () {
                                resolve();
                            });
                        });
                    }
                })
            });
        }

        function startSign(barcode, resolve) {
            var message = {
                "firstName": "",
                "lastName": "",
                "eMail": "",
                "location": "",
                "imageFormat": 1,
                "imageX": 500,
                "imageY": 100,
                "imageTransparency": false,
                "imageScaling": false,
                "maxUpScalePercent": 0.0,
                "rawDataFormat": "ENC",
                "minSigPoints": 25
            };

            var SignResponseEventListener = function (event) {
                var str = event.target.getAttribute("msgAttribute");
                var objResponse = JSON.parse(str);
                var obj = null;

                if (typeof (objResponse) === 'string') {
                    obj = JSON.parse(objResponse);
                } else {
                    obj = JSON.parse(JSON.stringify(objResponse));
                }

                if (obj.errorMsg != null && obj.errorMsg != "" && obj.errorMsg != "undefined") {
                    toastr.error(obj.errorMsg);

                    resolve();
                } else {
                    if (obj.isSigned) {
                        LabRequisitionSignature.save({
                            barcode: barcode,
                            signature: {signature: "data:image/jpg;base64," + obj.imageData}
                        }, function () {
                            resolve();
                        }, function () {
                            resolve();
                        });
                    }
                }

                top.document.removeEventListener('SignResponse', SignResponseEventListener, false);
            }

            top.document.addEventListener('SignResponse', SignResponseEventListener, false);

            var messageData = JSON.stringify(message);
            var element = document.createElement("MyExtensionDataElement");
            element.setAttribute("messageAttribute", messageData);
            document.documentElement.appendChild(element);
            var evt = document.createEvent("Events");
            evt.initEvent("SignStartEvent", true, false);
            element.dispatchEvent(evt);
        }
    }
})();

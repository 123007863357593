(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SecurityCodeController', SecurityCodeController);

    SecurityCodeController.$inject = ['CoreService', '$state', 'User', 'toastr', '$cookies'];//, 'previousState'

    function SecurityCodeController(CoreService, $state, User, toastr, $cookies) {
        var vm = this;

        vm.rememberMe = true;
        vm.toastr = toastr;
        vm.$cookies = $cookies;
        vm.code = null;
        vm.rememberMe = true;
        vm.employee = CoreService.getCurrentEmployee();
        vm.phoneNumberMasked = maskPhone(vm.employee.mobile);

        vm.verify = verify;

        function verify() {
            vm.isSaving = true;

            User.verifyCode({code: vm.code, browserUuid: CoreService.getUUID(), rememberMe: vm.rememberMe}, onSuccess, onError);

            function onSuccess(data) {
                if (data.value == true) {
                    if (!vm.rememberMe) {
                        CoreService.removeUUID();
                    }

                    if (vm.employee && !vm.employee.signed) {
                        $state.go('consent');
                    } else {
                        $state.go('home');
                    }
                } else {
                    toastr.error('Error checking code, try again');
                }

                vm.isSaving = false;
            }

            function onError(error) {
                vm.isSaving = false;
                $state.go('security-check');
                toastr.error('Error verifying code, try again')
            }
        }

        function maskPhone (number) {
            return '(***) ***-' + number.slice(-4);
        }
    }
})();

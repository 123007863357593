(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProcedureModifierDetailController', ProcedureModifierDetailController);

    ProcedureModifierDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProcedureModifier', 'Procedure', 'Modifier'];

    function ProcedureModifierDetailController($scope, $rootScope, $stateParams, previousState, entity, ProcedureModifier, Procedure, Modifier) {
        var vm = this;

        vm.procedureModifier = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bluebookApp:procedureModifierUpdate', function(event, result) {
            vm.procedureModifier = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

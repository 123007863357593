(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SendLabCompendiumController', SendLabCompendiumController);

    SendLabCompendiumController.$inject = ['SendLabCompendium', 'CoreService', '$q', 'GenericEntityDatatableService',
        'PopupService', '$uibModal', 'ConfirmationService'];

    function SendLabCompendiumController(SendLabCompendium, CoreService, $q, GenericEntityDatatableService,
                                         PopupService, $uibModal, ConfirmationService) {
        var vm = this;

        vm.laboratory = null;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Send Lab Compendiums',
            newAction: false,
            entityClassHumanized: 'Send Lab Compendiums',
            entityStateName: 'send-lab-compendium',
            service: SendLabCompendium,
            serviceMethod: 'filter',
            pagination: 'remote',
            params: params,
            options: [{key: 'aaSorting', value: [[1, 'asc']]}],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'code',
                    title: 'Test Code',
                },
                {
                    name: 'description',
                    title: 'Description',
                },
                {
                    title: 'Laboratory',
                    render: function () {
                        return vm.laboratory.name;
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('send-lab-compendium'), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Send LabCompendium',
                            action: function () {
                                return add();
                            },
                            disabled: function () {
                                return vm.laboratory === null;
                            }
                        })
                    ]
                }
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Laboratory',
                            action: function (filter) {
                                return $q(function (resolve) {
                                    PopupService.showSelectFacilityLabPopup(filter).result.then(function (result) {
                                        filter.laboratoryId = {id: result.labId, name: result.name};

                                        vm.laboratory = {id: result.labId, name: result.name};
                                        vm.descriptor.title = 'Send Lab Compendium - ' + vm.laboratory.name

                                        resolve();
                                    });
                                })
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'laboratoryId',
                    visible: false,
                    read: function (filter) {
                        return filter.laboratoryId ? filter.laboratoryId.id : null;
                    }
                }
            ],
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/send-lab-compendium/update/send-lab-compendium-dialog.html',
                    controller: 'SendLabCompendiumDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                id: null,
                                facility: CoreService.getCurrentFacility(),
                                laboratory: vm.laboratory
                            };
                        }]
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDeleteConfirmation('Lab Compendium').result.then(function () {
                    SendLabCompendium.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientResult', PatientResult);

    PatientResult.$inject = ['$resource', 'DateUtils', 'DataUtils', 'Utils'];

    function PatientResult ($resource, DateUtils, DataUtils, Utils) {
        var resourceUrl =  'api/patient-results/:id';

        function transformCountResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data = {value: data};
            }
            return data;
        }

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        data.collectionData = DateUtils.convertLocalDateFromServer(data.collectionData);
                        data.reviewedOn = DateUtils.convertLocalDateFromServer(data.reviewedOn);
                        if (data.signedBy != null) {
                            data.signedBy.credentials = Utils.objectRoleToArray(data.signedBy.credentials);
                        }
                    }
                    return data;
                }
            },
            'unassigned': {url : 'api/patient-results/unassigned', method: 'GET', isArray: true},
            'assign': {
                url: 'api/patient-results/assign', method: 'POST', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'deleteUnassigned': {url: 'api/patient-result/delete-unassigned', method: 'POST'},
            'getAllInOneRangePdf': {
                url: 'api/patient-results/get-all-in-one-range-pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadFile(data, headers);
                }
            },
            'saveResultReviewSignature': {url: "api/patient-results/save-sign", method: 'POST'},
            'getSignature': {url: 'api/patient-results/signature/:id', method: 'GET'},
            'countPartial': {url: 'api/patient-results/count-partial/:id', method: 'GET',
                transformResponse: transformCountResponse},
            'countAbnormal': {url: 'api/patient-results/count-abnormal-result/:id', method: 'GET',
                transformResponse: transformCountResponse},
            'countCritical': {url: 'api/patient-results/count-critical/:id', method: 'GET',
                transformResponse: transformCountResponse
            },
            'countUnassigned': {url: 'api/patient-results/count-unassigned/:id', method: 'GET',
                transformResponse: transformCountResponse
            },
            'pending': {url: 'api/patient-results/pending/:id', method: 'GET', isArray: true},
            'deletePending': {url: 'api/patient-result/delete-pending', method: 'POST'},
            'critical': {url: 'api/patient-results/critical', method: 'GET', isArray: true},
            'abnormal': {url: 'api/patient-results/abnormal', method: 'GET', isArray: true},
            'sign': {url: 'api/patient-results/sign', method: 'POST'},
            'getAllByFilter': {url: 'api/patient-results/filter', method: 'GET', isArray: true},
            'add': {url : 'api/patient-results/add', method: 'POST'},
            'resendAll': {url: 'api/patient-results/resend-all', method: 'POST', isArray: false},
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartsPayerController', ChartsPayerController);

    ChartsPayerController.$inject = ['Chart', 'CoreService', '$state', 'insCarrier', 'typePay', 'TypeLevelCare',
        '$rootScope', 'GenericEntityDatatableService'];

    function ChartsPayerController(Chart, CoreService, $state, insCarrier, typePay, TypeLevelCare,
                                   $rootScope, GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        function getServiceMethod() {
            if (insCarrier != null) {
                return 'getAllChartsPayerInsurancePrimary'
            } else if (typePay != null) {
                return 'getAllChartsPayerNoInsurance'
            } else {
                return 'getAllChartsPayerInsuranceNoPrimary'
            }
        }

        vm.descriptor = {
            title: 'Payer Mix',
            entityClassHumanized: 'Payer Mix',
            entityStateName: 'charts',
            service: Chart,
            serviceMethod: getServiceMethod(),
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[0, 'desc']]}
            ],
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    },
                    options: [{key: "width", value: "200px"}]
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY")
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: "width", value: "130px"}]
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return moment(data.admissionDate).format("MM/DD/YYYY");
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    }
                },
                {
                    name: null,
                    title: "Insurance Carrier",
                    render: function (data) {
                        return data.primary == null ? " " : data.primary.insuranceCarrier.name;
                    }
                },
                {
                    name: null,
                    title: "Policy Number",
                    render: function (data) {
                        return data.primary == null ? " " : data.primary.policyNumber;
                    }
                },
                {
                    title: 'Referral',
                    render: function (data) {
                        return data.referrer ? data.referrer : "-";
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.id});
                    },
                })
            ],
            filters: [
                {
                    name: 'insCarrier',
                    visible: false,
                    read: function (){
                        return insCarrier ? insCarrier.id : null
                    }
                },
                {
                    name: 'typePay',
                    visible: false,
                    read: function () {
                        return typePay ? typePay.id: null;
                    }
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                    visible: true
                },
                {
                    name: 'typeLevelOfCareId',
                    read: function (filter) {
                        return filter.typeLevelOfCareId ? filter.typeLevelOfCareId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeLevelCare.findAllByFacility(
                            CoreService.getFilterByFacility(CoreService.getCurrentFacility()), function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Level of Care ...',
                    type: 'select',
                },
                {
                    name: 'startDate',
                    // title: 'Day of Birth',
                    placeholder: 'From Discharge Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                },
                {
                    name: 'endDate',
                    // title: 'Day of Birth',
                    placeholder: 'To Discharge Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true
                }
            ]
        }
    }
})();

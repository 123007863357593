(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BedSheetController', BedSheetController);

    BedSheetController.$inject = ['$scope', '$state', '$q', '$filter', 'CoreService', 'Building', 'Bed', 'filter',
        '$log', '$uibModal', 'Chart', 'dialogs'];

    function BedSheetController($scope, $state, $q, $filter, CoreService, Building, Bed, filter,
                                $log, $uibModal, Chart, $dialogs) {
        var vm = this;

        vm.title = 'Bed Sheet';
        vm.entityClassHumanized = 'Bed Sheet';
        vm.filter = filter;
        vm.building = null;
        vm.bedsFilter = {};
        vm.countAllEmpty = 0;
        vm.countAllOccupied = 0;

        vm.search = search;
        vm.openChart = openChart;
        vm.assignBed = assignBed;
        vm.filterGender = filterGender;
        vm.changeStatusBed = changeStatusBed;
        vm.updateCurrentBuilding = updateCurrentBuilding;
        vm.getBedSheetPdf = getBedSheetPdf;
        vm.remove = remove;

        loadBuildings();

        function loadBuildings() {
            Building.byFacility({id: CoreService.getCurrentFacility().id}, function (buildings) {
                vm.buildings = buildings;

                if (vm.buildings != null) {
                    if (vm.filter.buildingId != null) {
                        vm.building = _.find(buildings, function (building) {
                            return building.id === vm.filter.buildingId;
                        });
                    } else {
                        vm.building = buildings[0];
                        vm.filter.buildingId = vm.building.id;
                    }
                    loadBeds();
                }
            })
        }

        function updateCurrentBuilding() {
            vm.filter.buildingId = vm.building.id;
            CoreService.setStorage('filterBedSheet', vm.filter);
            loadBeds();
        }

        function loadBeds() {
            Bed.sheetFilter(vm.filter, function (result) {
                result = addFullName(result);
                vm.beds = result;
                vm.bedsFilter = vm.beds;

                if (!vm.filter.searchQuery || vm.filter.searchQuery === '') {
                    vm.rooms = groupByRooms(result);
                } else {
                    vm.rooms = groupByRooms($filter('filter')(vm.bedsFilter, vm.filter.searchQuery, undefined))
                }
            });
            Bed.countAllEmptyAndActive({id: CoreService.getCurrentFacility().id}, function (data) {
                if (data.value) {
                    vm.countAllEmpty = data.value;
                }
            });
            Bed.countAllOccupiedAndActive({id: CoreService.getCurrentFacility().id}, function (data) {
                if (data.value) {
                    vm.countAllOccupied = data.value;
                }
            });
        }

        function filterGender(gender) {
            vm.filter.gender = gender;
            CoreService.setStorage('filterBedSheet', vm.filter);
            vm.initial = true;
            loadBeds();
        }

        function search() {
            CoreService.setStorage('filterBedSheet', vm.filter);

            if (vm.filter.searchQuery && vm.filter.searchQuery !== '') {
                vm.rooms = groupByRooms($filter('filter')(vm.bedsFilter, vm.filter.searchQuery, undefined))
            }
        }

        function groupByRooms(result) {
            return _.chain(result)
                .groupBy("roomName")
                .map(function (beds, roomName) {
                    return {beds: beds, roomName: roomName};
                })
                .value();
        }

        function addFullName(result) {
            return _.forEach(result, function (data) {
                data.fullName = data.firstName + " " + data.lastName;
            });
        }

        function openChart(chartId) {
            if (chartId != null) {
                $state.go('chart', {id: chartId})
            }
        }

        function assignBed(id) {
            $uibModal.open({
                templateUrl: 'app/entities/bed/bed-sheet/bed-sheet-assign.html',
                controller: 'BedSheetAssignDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    bed: ['Bed', function (Bed) {
                        return Bed.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadBeds();
            }, function () {
                loadBeds();
            });
        }

        function changeStatusBed(id) {
            $uibModal.open({
                templateUrl: 'app/entities/bed/bed-sheet/bed-sheet-status.html',
                controller: 'BedSheetStatusDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    bed: ['Bed', function (Bed) {
                        return Bed.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadBeds();
            }, function () {
                loadBeds();
            });
        }

        function getBedSheetPdf() {
            Chart.getBedsheetPdf({id: vm.building.id});
        }

        function remove(chartId) {
            var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to remove this Bed?', {size: 'md'});
            dlg.result.then(function (btn) {
                Bed.remove({id: chartId}, function () {
                    loadBeds();
                }, function () {
                    loadBeds();
                });
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('EmployeeMenu', EmployeeMenu);

    EmployeeMenu.$inject = ['$resource', '$rootScope'];

    function EmployeeMenu($resource, $rootScope) {
        var resourceUrl = 'api/employee-menus';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.menu = angular.fromJson(data.jsonData);

                        $rootScope.employeeMenu = data;
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = {};

                    copy.id = data.id;
                    copy.employee = data.employee;
                    copy.jsonData = angular.toJson(data.menu);

                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.menu = angular.fromJson(data.jsonData);
                    }

                    return data;
                }
            }
        });
    }
})();

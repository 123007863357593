(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BinDeleteController',BinDeleteController);

    BinDeleteController.$inject = ['$uibModalInstance', 'entity', 'Bin'];

    function BinDeleteController($uibModalInstance, entity, Bin) {
        var vm = this;

        vm.bin = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Bin.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartsDischargeController', ChartsDischargeController);

    ChartsDischargeController.$inject = ['CoreService', 'TypeDischargeType', '$state', 'dischargeType', '$rootScope',
        'Chart', 'GenericEntityDatatableService'];

    function ChartsDischargeController(CoreService, TypeDischargeType, $state, dischargeType, $rootScope,
                                       Chart, GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Charts Discharge',
            entityClassHumanized: 'Charts Discharge',
            entityStateName: 'charts',
            service: Chart,
            serviceMethod: 'chartsDischarge',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[0, 'desc']]}
            ],
            columns: [
                {
                    name: 'patientName',
                    title: 'Patient Name',
                    render: function (data) {
                        return data.firstName + ' ' + data.lastName;
                    },
                    options: [{key: "width", value: "220px"}]
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dateBirth).format("MM/DD/YYYY")
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: 'mrNo',
                    title: "Mr Number",
                    options: [{key: "width", value: "130px"}]
                },
                {
                    name: null,
                    title: 'Admission Date',
                    render: function (data) {
                        return moment(data.admissionDate).format("MM/DD/YYYY");
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: 'Discharge Date',
                    render: function (data) {
                        return data.dischargeDate != null ? moment(data.dischargeDate).format($rootScope.dateFormat) : " ";
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: 'Discharge',
                    render: function (data) {
                        return data.typeDischargeType == null ? "" : data.typeDischargeType.name;
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: 'Treatment Days',
                    render: function (data) {
                        return moment(data.dischargeDate).diff(moment(data.admissionDate), 'days');
                    },
                    options: [{key: "width", value: "120px"}]
                },
                {
                    name: null,
                    title: "LOC",
                    render: function (data) {
                        return data.typeLevelCare == null ? " " : data.typeLevelCare.name;
                    },
                    options: [{key: "width", value: "220px"}]
                },
                {
                    title: 'Referral',
                    options: [{key: "width", value: "220px"}],
                    render: function (data) {
                        return data.referrer ? data.referrer : "-"
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewChartAction(), {
                    action: function (data) {
                        $state.go('chart', {id: data.id});
                    },
                }),
            ],
            filters: [
                {
                    name: 'typeDischargeId',
                    read: function (filter) {
                        return filter.typeDischargeId ? filter.typeDischargeId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.name : "";
                    },
                    refresh: function (search, values) {
                        TypeDischargeType.findAllByCorporation(CoreService.getFilterByCurrentCorporation(), function (result) {
                            values.length = 0;
                            values.push(...result);
                        });
                    },
                    placeholder: 'Select discharge type...',
                    type: 'select',
                    defaultValue: function () {
                        return dischargeType;
                    },
                    ngClass: 'col-xs-12 col-sm-12 col-md-12 col-lg-12',
                },
                {
                    name: 'chartId',
                    read: function (filter) {
                        return filter.chartId ? filter.chartId.id : null;
                    },
                    values: [],
                    getDisplayValue: function (entity) {
                        return entity ? entity.firstName + ' ' + entity.lastName : "";
                    },
                    getSmallDisplayValue: function (entity) {
                        return '<strong>DOB: </strong>' + moment(entity.dateBirth).format("MM/DD/YYYY") + ' <strong>MrNo:</strong> ' + entity.mrNo;
                    },
                    refresh: function (search, values) {
                        Chart.fullFilter(
                            {
                                page: 0,
                                size: 20,
                                facilityId: CoreService.getCurrentFacility().id,
                                searchQuery: search,
                                statuses: ['CURRENT', 'ARCHIVE']
                            }, function (result) {
                                values.length = 0;
                                values.push(...result);
                            });
                    },
                    placeholder: 'Select patient ...',
                    type: 'select',
                },
                {
                    name: 'referrer',
                    type: 'string',
                    placeholder: 'Referral...',
                },
                {
                    name: 'dischargeDateFrom',
                    // title: 'Day of Birth',
                    placeholder: 'To Discharge Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true,
                    defaultValue: function () {
                        var now = new Date();
                        now.setDate(now.getDate() - 30);

                        return now;
                    }
                },
                {
                    name: 'dischargeDateTo',
                    // title: 'Day of Birth',
                    placeholder: 'From Discharge Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    visible: true,
                    defaultValue: function () {
                        return new Date();
                    }
                }
            ]
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationFaxLogController', PatientMedicationFaxLogController);

    PatientMedicationFaxLogController.$inject = ['PatientMedicationFaxLog', 'CoreService', '$rootScope',
        'GenericEntityDatatableService'];

    function PatientMedicationFaxLogController(PatientMedicationFaxLog, CoreService, $rootScope,
                                               GenericEntityDatatableService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
        }

        vm.descriptor = {
            title: 'Fax Logs',
            entityClassHumanized: 'Fax Logs',
            entityStateName: 'ppatient-medication-fax-log',
            service: PatientMedicationFaxLog,
            serviceMethod: 'filter',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                // {key: 'aaSorting', value: [[1, 'desc']]}
            ],
            selectionColumn: 'id',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: null,
                    title: 'Patient',
                    render: function (data) {
                        return data.firstName + " " + data.lastName + " [ " + data.mrNo + " ]";
                    }
                },
                {
                    name: "objectName",
                    title: "Object"
                },
                {
                    name: "faxType",
                    title: 'Fax Type',
                },
                {
                    name: null,
                    title: 'Employee',
                    render: function (data) {
                        return data.employeeFirstName + " " + data.employeeLastName;
                    }
                },
                {
                    name: "name",
                    title: 'Fax To'
                },
                {
                    name: "fax",
                    title: 'Fax Number'
                },
                {
                    name: null,
                    title: 'Send Date',
                    render: function (data) {
                        return data.sentDate ? moment(data.sentDate).format($rootScope.amDateFormat) : ''
                    }
                },
                {
                    name: "status",
                    title: 'Api Status'
                },
                {
                    name: 'status',
                    title: 'Status',
                },
                {
                    name: null,
                    title: 'Created Date',
                    render: function (data) {
                        return data.createdDate ? moment(data.createdDate).format($rootScope.amDateFormat) : ''
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getViewPdfAction(), {
                    action: function (data) {
                        vm.download(data.id);
                    },
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
            ],
        }

        vm.download = download;

        function download(id) {
            PatientMedicationFaxLog.download({id: id});
        }
    }
})();

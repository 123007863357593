(function(){
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ActivitiesPaymentPlanDialogController', ActivitiesPaymentPlanDialogController);

    ActivitiesPaymentPlanDialogController.$inject = ['$uibModalInstance'];

    function ActivitiesPaymentPlanDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();

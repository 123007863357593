(function (){
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOpportunityOutboundReferralController', CrmOpportunityOutboundReferralController);

    CrmOpportunityOutboundReferralController.$inject = ['$uibModalInstance'];

    function CrmOpportunityOutboundReferralController($uibModalInstance) {
        var vm = this;

        vm.title = 'Outbound Referral';
        vm.clear = clear;

        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateOfAdmission = false;
        vm.dateOfBirthOptions = {
            maxDate: new Date(),
            minDate: new Date(1900, 1, 1)
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InstClaimConfDialogController', InstClaimConfDialogController);

    InstClaimConfDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'InstClaimConf', 'InsuranceCarrierSearch', 'TypeLevelCare',
        'Facility', 'CoreService', 'CptSearch',
        'ModifierSearch', 'RevCodeSearch'];

    function InstClaimConfDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                           entity, InstClaimConf, InsuranceCarrierSearch, TypeLevelCare,
                                           Facility, CoreService, CptSearch,
                                           ModifierSearch, RevCodeSearch) {
        var vm = this;

        vm.instClaimConfs = [entity];
        vm.facility = CoreService.getCurrentFacility();
        vm.typelevelcares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.insuranceCarriers = entity && entity.insuranceCarriers ? angular.copy(entity.insuranceCarriers) : [];
        vm.revCodes = [];
        vm.cpts = [];

        vm.save = save;
        vm.addConf = addConf;
        vm.clear = clear;
        vm.deleteInstClaimConf = deleteInstClaimConf;
        vm.deleteInstCptConf = deleteInstCptConf;
        vm.getInsuranceCarriers = getInsuranceCarriers;
        vm.addCpt = addCpt;
        vm.getCpts = getCpts;
        vm.getModifiers = getModifiers;
        vm.getRevCodes = getRevCodes;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.instClaimConfs.length == 1 && vm.instClaimConfs[0].id !== null) {
                InstClaimConf.update(vm.instClaimConfs[0], onSaveSuccess, onSaveError);
            } else {
                addNewInstClaimConf();
            }
        }

        function addNewInstClaimConf() {
            var instClaimConfUploadProj = {
                instClaimConfs: [],
                facilityId: vm.facility.id
            };

            _.forEach(vm.instClaimConfs, function (instClaimConf, index) {
                var instClaimConf = angular.copy(instClaimConf);

                var icc = {
                    xorder: index,
                    minTime: instClaimConf.minTime,
                    maxTime: instClaimConf.maxTime,
                    isDefault: instClaimConf.isDefault,
                    insuranceCarriers: instClaimConf.insuranceCarriers,
                    instCptConfs: instClaimConf.instCptConfs,
                    levelOfCares: instClaimConf.levelOfCares
                };

                instClaimConfUploadProj.instClaimConfs.push(icc);
            });

            InstClaimConf.save(instClaimConfUploadProj, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function addConf() {
            vm.insuranceCarriers = null;
            vm.instClaimConfs.push({
                collapsed: false,
                facility: vm.facility,
                isDefault: false,
                instCptConfs: [{}]
            })
        }

        function addCpt(instClaimConf) {
            instClaimConf.instCptConfs.push({});
        }

        function deleteInstClaimConf(index) {
            vm.instClaimConfs.splice(index, 1);
        }

        function deleteInstCptConf(instClaimConf, index) {
            instClaimConf.instCptConfs.splice(index, 1);
        }

        function getInsuranceCarriers(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);

                vm.insuranceCarriers = InsuranceCarrierSearch.query({
                    query: query
                });
            }
        }

        function getCpts(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.cpts = CptSearch.query({query: query});
            }
        }

        function getRevCodes(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.revCodes = RevCodeSearch.query({page: 0, query: query});
            }
        }

        function getModifiers(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.modifiers = ModifierSearch.query({query: query});
            }
        }
    }
})();

/**
 * Created by srodriguez on 4/06/18.
 */

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PdfChartReportDownloadController', PdfChartReportDownloadController);

    PdfChartReportDownloadController.$inject = ['$uibModalInstance', 'entity', 'PdfChartReport', 'OdsParamType', 'CoreService'];

    function PdfChartReportDownloadController($uibModalInstance, entity, PdfChartReport, OdsParamType, CoreService) {
        var vm = this;

        vm.pdfChartReport = entity;
        vm.pdf = {};
        vm.pdfBase64 = "";
        vm.employeeName = "Printed by: " + CoreService.getCurrentEmployee().firstName + " " + CoreService.getCurrentEmployee().lastName;

        vm.listPdfChartReportsSelected = [];
        vm.listPdfChartReportsSelectedDownload = [];

        vm.countDownloadedReports = 0;

        vm.clear = clear;

        if (entity != null) {
            if (vm.pdfChartReport.json) {
                PdfChartReport.downloadPdfObject({id: vm.pdfChartReport.id}, function (data, headers) {
                    vm.pdf = data;
                    data.footer = pdfFooterWithFilters(data);
                    pdfMake.createPdf(data).download(vm.pdfChartReport.name);
                    clear();
                });
            } else {
                PdfChartReport.download({id: vm.pdfChartReport.id}, function () {
                    clear();
                });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function pdfFooterWithFilters(report) {

            if (report.content.length > 0) {

                var filters = '';
                for (var i = 0; i < report.content.length; i++) {
                    var hideParam = report.content[i].hideInFooter !== undefined ?
                        report.content[i].hideInFooter : false;
                    if (!hideParam) {
                        switch (report.content[i].type) {
                            case OdsParamType.DATE:
                                filters += report.content[i].title + ': ' +
                                    OdsDateUtils.formatter(report.content[i].value, 'MM/dd/yyyy') + '\n';
                                break;
                            case OdsParamType.NUMBER:
                                filters += report.content[i].title + ': ' + report.content[i].value + '\n';
                                break;
                            default:
                                filters += report.content[i].title + ': ' + report.content[i].value + '\n';
                                break;
                        }
                    }
                }

                return function (currentPage, pageCount) {

                    var result;
                    if (report.footerLogo) {
                        result = {
                            columns: [
                                {
                                    text: filters,
                                    margin: [20, 0],
                                    fontSize: 8
                                },
                                {
                                    image: report.footerLogo,
                                    fit: [100, 100],
                                    margin: [0, 0, 0, 0]
                                },
                                {
                                    text: 'Date: ' + moment().format('MM/DD/YYYY hh:mm') + '\n' +
                                        'Page ' + currentPage.toString() + ' of ' + pageCount,
                                    alignment: 'right',
                                    margin: [0, 0, 20, 0],
                                    fontSize: 8
                                }
                            ]
                        }
                    } else {
                        result = {
                            columns: [
                                {
                                    text: "",//filters,
                                    margin: [20, 0],
                                    fontSize: 8
                                },
                                {
                                    text: vm.employeeName,//filters,
                                    margin: [20, 0],
                                    fontSize: 8,
                                    alignment: 'center'
                                },
                                {
                                    text: 'Date: ' + moment().format('MM/DD/YYYY hh:mm') + '\n' +
                                        'Page ' + currentPage.toString() + ' of ' + pageCount,
                                    alignment: 'right',
                                    margin: [0, 0, 20, 0],
                                    fontSize: 8
                                }
                            ]
                        }
                    }
                    return result;
                };
            } else {
                return function (currentPage, pageCount) {
                    return {
                        columns: [
                            {
                                text: 'No Filters',
                                margin: [20, 0],
                                fontSize: 8
                            },
                            {
                                text: 'Date: ' + moment().format('MM/DD/YYYY hh:mm') + '\n' +
                                    'Page ' + currentPage.toString() + ' of ' + pageCount,
                                alignment: 'right',
                                margin: [0, 0, 20, 0],
                                fontSize: 8
                            }
                        ]
                    };
                };
            }

        }

    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartCareTeamController', ChartCareTeamController);

    ChartCareTeamController.$inject = ['chart', 'ChartCareTeam', 'CoreService', '$sessionStorage', '$uibModal',
        'access', 'ACTIONS', 'Auth', 'ConfirmationService'];

    function ChartCareTeamController(chart, ChartCareTeam, CoreService, $sessionStorage, $uibModal,
                                     access, ACTIONS, Auth, ConfirmationService) {
        var vm = this;

        vm.chart = chart;

        vm.toggleItem = toggleItem;

        function toggleItem() {
            CoreService.togglePanel('careTeam');
        }

        vm.activeTab = $sessionStorage.activePatientTabCareTeam ? $sessionStorage.activePatientTabCareTeam.activeTab : 0;

        vm.keepActiveTab = function (index) {
            $sessionStorage.activePatientTabCareTeam = {activeTab: index};
            vm.activeTab = index;
        }

        vm.name = chart.lastName + " " + chart.firstName;
        vm.mrNumber = chart.mrNo;

        vm.add = add;
        vm.edit = edit;
        vm.del = del;

        loadData();

        function loadData() {
            if (!Auth.hasActionAuthorization(ACTIONS.ACTION_PATIENT_CARE_TEAM_VIEW, access)) {
                return;
            }

            vm.careTeamsActive = ChartCareTeam.findAllActiveByChart({id: chart.id});
            vm.careTeamsInactive = ChartCareTeam.findAllInactiveByChart({id: chart.id});
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/chart-care-team/update/chart-care-team-dialog.html',
                controller: 'ChartCareTeamDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            allergen: null,
                            allergen_type: null,
                            reaction: null,
                            treatment: null,
                            status: null,
                            id: null,
                            endDate: null,
                            startDate: null,
                            chart: chart
                        };
                    }
                }
            }).result.then(function () {
                loadData()
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/chart-care-team/update/chart-care-team-dialog.html',
                controller: 'ChartCareTeamDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['ChartCareTeam', function (ChartCareTeam) {
                        return ChartCareTeam.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadData();
            }, function () {
            });
        }

        function del(id) {
            ConfirmationService.showDelete('Care Team').result.then(function () {
                ChartCareTeam.delete({id: id}, function () {
                    loadData();
                });
            })
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SuperBillController', SuperBillController);

    SuperBillController.$inject = ['$state', '$uibModal', 'SuperBill', 'chart', '$scope', '$rootScope', 'Procedure',
        'TAB', 'params', 'access'];

    function SuperBillController($state, $uibModal, SuperBill, chart, $scope, $rootScope, Procedure,
                                 TAB, params, access) {
        var vm = this;

        vm.chart = chart;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.active = ($state.current.name !== 'chart-new') || (params != null && parseInt(params.tabId) == TAB.SUPER_BILL);
        vm.superBills = [];
        vm.access = access;

        vm.add = add;

        loadAll();

        function loadAll() {
            if (!vm.active) {
                return;
            }

            SuperBill.byChart({id: chart.id, page: vm.page - 1, size: vm.itemsPerPage}, function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.superBills = result;

                _.forEach(vm.superBills, function (superBill) {
                    Procedure.getCpts({id: superBill.id}, function (cpts) {
                        superBill.cpts = _.join(cpts, ', ');
                    })
                })
            });
        }

        var unsubscribe = $rootScope.$on('bluebookApp:chartDetailActiveTab', function (event, result) {
            if (!vm.active && result == TAB.SUPER_BILL) {
                vm.active = true;
                loadAll();
            }
        });

        $scope.$on('$destroy', unsubscribe);

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/super-bill/super-bill-dialog.html',
                controller: 'SuperBillDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.chart
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

    }
})();

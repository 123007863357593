(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityIcd10Controller', FacilityIcd10Controller);

    FacilityIcd10Controller.$inject = ['FacilityIcd10', 'CoreService', 'GenericEntityDatatableService', '$uibModal',
        'ConfirmationService', '$q'];

    function FacilityIcd10Controller(FacilityIcd10, CoreService, GenericEntityDatatableService, $uibModal,
                                     ConfirmationService, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'facility-icd-10'

        vm.descriptor = {
            title: 'Facility Icd10s',
            entityClassHumanized: 'Facility Icd10s',
            newButtonTitle: 'Add Icd10',
            entityStateName: entityStateName,
            service: FacilityIcd10,
            serviceMethod: 'findAllByFilter',
            params: params,
            newAction: false,
            columns: [
                {
                    title: 'Name',
                    render: function (data) {
                        return data.icd10.code;
                    }
                },
                {
                    title: 'Description',
                    render: function (data) {
                        return data.icd10.description;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Icd10',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-icd-10/update/facility-icd-10-dialog.html',
                controller: 'FacilityIcd10DialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            icd10: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-icd-10/update/facility-icd-10-dialog.html',
                controller: 'FacilityIcd10DialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['FacilityIcd10', function (FacilityIcd10) {
                        return FacilityIcd10.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Facility Icd10').result.then(function () {
                    FacilityIcd10.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$breadcrumbProvider', 'IdleProvider', 'IDLE'];

    function stateConfig($stateProvider, $breadcrumbProvider, IdleProvider, IDLE) {

        $breadcrumbProvider.setOptions({
            prefixStateName: 'home',
            templateUrl: 'app/components/tpl/breadcrumb.tpl.html'
        });

        $stateProvider
            .state('app', {
                abstract: true,
                views: {
                    'globalmessage@': {
                        templateUrl: 'app/entities/global-message/notification/global-message-notification.html',
                        controller: 'GlobalMessageNotificationController',
                        controllerAs: 'vm'
                    },
                    'headBar@': {
                        templateUrl: 'app/layouts/header/header.tpl.html',
                        controller: 'HeaderController',
                        controllerAs: 'vm'
                    },
                    'sideBar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.tpl.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.tpl.html',
                        controller: 'FooterController',
                        controllerAs: 'vm'
                    },
                    'controlBar@': {
                        templateUrl: 'app/layouts/controlbar/controlsidebar.tpl.html',
                        controller: 'ControlBarController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    employee: ['Employee', function (Employee) {
                        return Employee.employeeAccount().$promise;
                    }],
                    authorize: ['Auth', function (Auth) {
                        return Auth.authorize();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };

                        return currentStateData;
                    }],
                    showNewChart: ['EmployeeNewChart', '$window', function (EmployeeNewChart, $window) {
                        if ($window.outerWidth > 1150) {
                            return {value: true};
                        } else {
                            return {value: false};
                        }
                    }],
                    dymo: ['DymoLabel', function (DymoLabel) {

                        return DymoLabel.get();
                    }]
                },
            });

        IdleProvider.idle(IDLE.idle);
        IdleProvider.timeout(IDLE.timeout);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmOutboundReferralDialogController', CrmOutboundReferralDialogController);

    CrmOutboundReferralDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'CrmOutboundReferral', 'Employee', 'CrmOutboundReferralStatusSearch',
        'CrmReferralSourceTypeSearch', 'CoreService', 'CrmReferralSourceSearch',
        'CrmReferralContact'];

    function CrmOutboundReferralDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                                 CrmOutboundReferral, Employee, CrmOutboundReferralStatusSearch,
                                                 CrmReferralSourceTypeSearch, CoreService, CrmReferralSourceSearch,
                                                 CrmReferralContact) {
        var vm = this;

        vm.crmOutboundReferral = entity;
        vm.datePickerOpenStatus = {};

        vm.crmreferralsourcetypes = CrmReferralSourceTypeSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.employees = [];
        vm.crmOutboundReferralStatuses = CrmOutboundReferralStatusSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.crmReferralSources = CrmReferralSourceSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.corporation = CoreService.getCorporation();
        vm.referralsTo = null;

        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.openCalendar = openCalendar;
        vm.getCrmReferralContacts = getCrmReferralContacts;
        vm.changeCrmReferralSource = changeCrmReferralSource;

        vm.getCrmReferralContacts();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.crmOutboundReferral.id !== null) {
                CrmOutboundReferral.update(vm.crmOutboundReferral, onSaveSuccess, onSaveError);
            } else {
                CrmOutboundReferral.save(vm.crmOutboundReferral, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:crmOutboundReferralUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.doa = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.corporation.id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function getCrmReferralContacts(query) {
            if (vm.crmOutboundReferral.crmReferralSource) {
                CrmReferralContact.findAllByFilter({
                    page: 0,
                    size: 25,
                    crmReferralSourceId: vm.crmOutboundReferral.crmReferralSource.id,
                    corporationId: vm.corporation.id,
                    searchQuery: query
                }, function (result) {
                    vm.referralsTo = result;
                });
            }
        }

        function changeCrmReferralSource() {
            vm.referralsTo = [];
            vm.crmOutboundReferral.crmReferralContact = null;
            getCrmReferralContacts();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProClaimConfDialogController', ProClaimConfDialogController);

    ProClaimConfDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'ProClaimConf', 'InsuranceCarrier', 'InsuranceCarrierSearch', 'TypeLevelCare', 'CoreService',
        'CptSearch', 'ModifierSearch'];

    function ProClaimConfDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                          ProClaimConf, InsuranceCarrier, InsuranceCarrierSearch,
                                          TypeLevelCare, CoreService, CptSearch, ModifierSearch) {
        var vm = this;

        vm.title = 'Professional Claim Configuration';
        vm.evaluationTemplate = entity.evaluationTemplate;
        vm.facility = entity.facility;
        vm.proClaimConfs = [entity];
        vm.insuranceCarriers = entity && entity.insuranceCarriers ? angular.copy(entity.insuranceCarriers) : [];
        vm.cpts = [];
        vm.typelevelcares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));

        vm.clear = clear;
        vm.save = save;
        vm.addConf = addConf;
        vm.deleteProClaimConf = deleteProClaimConf;
        vm.deleteProCptConf = deleteProCptConf;
        vm.addCpt = addCpt;
        vm.getInsuranceCarriers = getInsuranceCarriers;
        vm.getCpts = getCpts;
        vm.getModifiers = getModifiers;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.proClaimConfs.length == 1 && vm.proClaimConfs[0].id !== null) {
                ProClaimConf.update(vm.proClaimConfs[0], onSaveSuccess, onSaveError);
            } else {
                addNewProClaimConf();
            }
        }

        function addNewProClaimConf() {
            var proClaimConfProj = {
                proClaimConfs: [],
                facilityId: vm.facility.id,
                evaluationTemplateId: vm.evaluationTemplate.id
            };

            _.forEach(vm.proClaimConfs, function (proClaimConf, index) {
                var proClaimConfTmp = angular.copy(proClaimConf);

                var pcc = {
                    xorder: index,
                    minTime: proClaimConfTmp.minTime,
                    maxTime: proClaimConfTmp.maxTime,
                    isDefault: proClaimConfTmp.isDefault,
                    insuranceCarriers: proClaimConfTmp.insuranceCarriers,
                    levelOfCares: proClaimConfTmp.levelOfCares,
                    rate: proClaimConfTmp.rate,
                    quantity: proClaimConfTmp.quantity,
                    unit: proClaimConfTmp.unit,
                    proCptConfs: proClaimConfTmp.proCptConfs,
                };

                proClaimConfProj.proClaimConfs.push(pcc);
            });

            ProClaimConf.create(proClaimConfProj, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function addConf() {
            vm.proClaimConfs.push({
                collapsed: false,
                isDefault: false,
                proCptConfs: [{}]
            })
        }

        function addCpt(proClaimConf) {
            proClaimConf.proCptConfs.push({});
        }

        function deleteProClaimConf(index) {
            vm.proClaimConfs.splice(index, 1);
        }

        function deleteProCptConf(proClaimConf, index) {
            proClaimConf.proCptConfs.splice(index, 1);
        }

        function getInsuranceCarriers(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);

                vm.insuranceCarriers = InsuranceCarrierSearch.query({
                    query: query
                });
            }
        }

        function getCpts(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.cpts = CptSearch.query({query: query});
            }
        }

        function getModifiers(query) {
            if (query && query.length > 0) {
                query = _.lowerCase(query);

                vm.modifiers = ModifierSearch.query({query: query});
            }
        }
   }
})();

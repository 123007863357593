(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .factory('DYMO', DYMO);

    DYMO.$inject = ['Base64', 'moment'];

    function DYMO(Base64, moment) {
        var service = {
            loadPrinters: loadPrinters,
            printPatientOrderLabel: printPatientOrderLabel,
            testPatientLabel: testPatientLabel,
            printPatientBarcodes: printPatientBarcodes,
            setTemplate: setTemplate
       };

        return service;

        var printersSelect = '';
        var orderTemplate;

        function loadPrinters() {
            var printers = dymo.label.framework.getLabelWriterPrinters();
            if (printers.length == 0) {
                // alert("No DYMO printers are installed. Install DYMO printers.");
                return false;
            }

            for (var i = 0; i < printers.length; i++) {
                var printer = printers[i];

                var printerName = printer.name;
                printersSelect = printerName;
            }

            return true;
        }

        function printPatientOrderLabel(data) {
            orderTemplate.setObjectText('barcode', data.barcode);
            orderTemplate.setObjectText('name', data.name);
            orderTemplate.setObjectText('dob', moment(data.dob).format("MM/DD/YYYY"));
            orderTemplate.setObjectText('collectedDate', moment(data.collectedDate).format("MM/DD/YYYY"));
            orderTemplate.setObjectText('account', data.account ? data.account : "");
            if (data.tube) {
                orderTemplate.setObjectText('tube', data.tube);
                orderTemplate.setObjectText('tube_label', "Tube:");
            } else {
                orderTemplate.setObjectText('tube', "");
                orderTemplate.setObjectText('tube_label', "");
            }

            orderTemplate.print(printersSelect);
        }

        function testPatientLabel() {
            var template = orderTemplate;

            template.setObjectText('barcode', 'P000001');
            template.setObjectText('name', 'Jhon Doe');
            template.setObjectText('dob', moment(new Date()).format("MM/DD/YYYY"));
            template.setObjectText('collectedDate', moment(new Date()).format("MM/DD/YYYY"));
            template.setObjectText('tube', 'Some tube name');
            template.setObjectText('account', 'DXX001');

            template.print(printersSelect);
        }

        function printPatientBarcodes(result) {
            for (var l = 0; l < result.length; l++) {
                printPatientOrderLabel({
                    barcode: result[l].barcode,
                    name: result[l].patientName,
                    dob: result[l].dob,
                    collectedDate: result[l].date,
                    tube: result[l].tube,
                    account: result[l].account
                });
            }
        }

        function setTemplate(label) {
            if (label) {
                orderTemplate = dymo.label.framework.openLabelXml(label);
            }
        }
    }
})();

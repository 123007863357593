(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmActivityDialogController', CrmActivityDialogController);

    CrmActivityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'CrmActivity', 'CrmNote', 'CrmCall', 'CrmEmail', 'CrmTask', 'Corporation', 'CrmOpportunity'];

    function CrmActivityDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, CrmActivity, CrmNote, CrmCall, CrmEmail, CrmTask, Corporation, CrmOpportunity) {
        var vm = this;

        vm.crmActivity = entity;
        vm.clear = clear;
        vm.save = save;
        vm.crmnotes = CrmNote.query({filter: 'crmactivity-is-null'});
        $q.all([vm.crmActivity.$promise, vm.crmnotes.$promise]).then(function() {
            if (!vm.crmActivity.crmNote || !vm.crmActivity.crmNote.id) {
                return $q.reject();
            }
            return CrmNote.get({id : vm.crmActivity.crmNote.id}).$promise;
        }).then(function(crmNote) {
            vm.crmnotes.push(crmNote);
        });
        vm.crmcalls = CrmCall.query({filter: 'crmactivity-is-null'});
        $q.all([vm.crmActivity.$promise, vm.crmcalls.$promise]).then(function() {
            if (!vm.crmActivity.crmCall || !vm.crmActivity.crmCall.id) {
                return $q.reject();
            }
            return CrmCall.get({id : vm.crmActivity.crmCall.id}).$promise;
        }).then(function(crmCall) {
            vm.crmcalls.push(crmCall);
        });
        vm.crmemails = CrmEmail.query({filter: 'crmactivity-is-null'});
        $q.all([vm.crmActivity.$promise, vm.crmemails.$promise]).then(function() {
            if (!vm.crmActivity.crmEmail || !vm.crmActivity.crmEmail.id) {
                return $q.reject();
            }
            return CrmEmail.get({id : vm.crmActivity.crmEmail.id}).$promise;
        }).then(function(crmEmail) {
            vm.crmemails.push(crmEmail);
        });
        vm.crmtasks = CrmTask.query({filter: 'crmactivity-is-null'});
        $q.all([vm.crmActivity.$promise, vm.crmtasks.$promise]).then(function() {
            if (!vm.crmActivity.crmTask || !vm.crmActivity.crmTask.id) {
                return $q.reject();
            }
            return CrmTask.get({id : vm.crmActivity.crmTask.id}).$promise;
        }).then(function(crmTask) {
            vm.crmtasks.push(crmTask);
        });
        vm.corporations = Corporation.query();
        vm.crmopportunities = CrmOpportunity.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.crmActivity.id !== null) {
                CrmActivity.update(vm.crmActivity, onSaveSuccess, onSaveError);
            } else {
                CrmActivity.save(vm.crmActivity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:crmActivityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ProtocolGroupDialogController', ProtocolGroupDialogController);

    ProtocolGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProtocolGroup'];

    function ProtocolGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProtocolGroup) {
        var vm = this;

        vm.protocolGroup = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.protocolGroup.id !== null) {
                ProtocolGroup.update(vm.protocolGroup, onSaveSuccess, onSaveError);
            } else {
                ProtocolGroup.save(vm.protocolGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:protocolGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmTaskDialogController', CrmTaskDialogController);

    CrmTaskDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'CrmTask', 'Employee', 'CoreService'];

    function CrmTaskDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                     entity, CrmTask, Employee, CoreService) {
        var vm = this;

        vm.crmTask = entity;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dueDate = false;
        vm.datePickerOpenStatus.reminder = false;
        vm.corporation = CoreService.getCorporation();

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getEmployees = getEmployees;
        vm.editorCreated = editorCreated;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.crmTask.id !== null) {
                CrmTask.update(vm.crmTask, onSaveSuccess, onSaveError);
            } else {
                CrmTask.save(vm.crmTask, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:crmTaskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getEmployees(query) {
            if (query && query.length > 3) {
                var filter = {page: 0, size: 10, query: query, corporationId: vm.corporation.id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function editorCreated(editor, htmlText) {
            editor.root.innerHTML = htmlText;
        }
    }
})();

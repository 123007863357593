(function (){
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('appointments', {
                parent: 'entity',
                url: '/appointments',
                data: {
                    authorities: [],
                    pageTitle: 'Appointments'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/appointments/appointments.html',
                        controller: 'AppointmentsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                }
            })
            .state('appointment-encounter', {
                parent: 'entity',
                url: '/appointment-encounter',
                data: {
                    authorities: [],
                    pageTitle: 'Appointments Encounter'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/appointment-encounter/appointment-encounter.html',
                        controller: 'AppointmentEncounterController',
                        controllerAs: 'vm'
                    },
                    'super-bill@appointment-encounter': {
                        templateUrl: 'app/entities/super-bill/super-bill.html',
                        controller: 'SuperBillController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TubeController', TubeController);

    TubeController.$inject = ['Tube', '$q', 'Laboratory', '$uibModal', 'GUIUtils', 'CoreService',
        'GenericEntityDatatableService', 'PopupService', 'ConfirmationService'];

    function TubeController(Tube, $q, Laboratory, $uibModal, GUIUtils, CoreService,
                            GenericEntityDatatableService, PopupService, ConfirmationService) {
        var vm = this;

        vm.laboratory = null;

        vm.descriptor = {
            title: 'Tubes',
            newAction: false,
            entityClassHumanized: 'Tubes',
            entityStateName: 'tube',
            service: Tube ,
            serviceMethod: 'findAllByLaboratory',
            pagination: 'remote',
            options: [{key: 'aaSorting', value: [[1, 'asc']]}],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    title: 'Laboratory',
                    render: function () {
                        return vm.laboratory.name;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction('specimen'), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('specimen'), {
                    action: function (data) {
                        return del(data.id);
                    }
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Tube',
                            action: function () {
                                return add();
                            },
                            disabled: function () {
                                return vm.laboratory === null;
                            }
                        })
                    ]
                }
            ],
            leftActions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getCheckAction(), {
                            name: 'Select Laboratory',
                            action: function (filter) {
                                return $q(function (resolve) {
                                    PopupService.showSelectLaboratoryPopup(filter).result.then(function (result) {
                                        filter.laboratoryId = result;

                                        vm.laboratory = result;
                                        vm.descriptor.title = 'Tubes - ' + vm.laboratory.name

                                        resolve();
                                    });
                                })
                            }
                        })
                    ]
                }
            ],
            filters: [
                {
                    name: 'laboratoryId',
                    visible: false,
                    read: function (filter) {
                        return filter.laboratoryId ? filter.laboratoryId.id : null;
                    }
                }
            ],
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/tube/update/tube-dialog.html',
                    controller: 'TubeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null,
                                laboratory: vm.laboratory
                            };
                        },
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/tube/update/tube-dialog.html',
                    controller: 'TubeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: Tube.get({id: id}).$promise
                    }
                }).result.then(function () {
                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Tube').result.then(function () {
                    Tube.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .directive('assistantClipboard', assistantClipboard);

    assistantClipboard.$inject = [];

    function assistantClipboard() {
        return {
            restrict: 'E',
            templateUrl: 'app/components/assistant/assistant-clipboard.html',
            scope: {
                model: '=ngModel'
            },
            link: linkFunc
        };

        function linkFunc($scope) {
            $scope.query = "";

            $scope.copy = function (item) {
                if (window.clipboardData && window.clipboardData.setData) {
                    // IE specific code path to prevent textarea being shown while dialog is visible.
                    return clipboardData.setData("Text", item.note);

                } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                    var textarea = document.createElement("textarea");
                    textarea.textContent = item.note;
                    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                    document.body.appendChild(textarea);
                    textarea.select();
                    try {
                        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                    } catch (ex) {
                        console.warn("Copy to clipboard failed.", ex);
                        return false;
                    } finally {
                        document.body.removeChild(textarea);
                    }
                }
            };

            angular.element('#clipboard-toggle').on('click', function() {
                if (angular.element('#clipboard-toggle').hasClass('open')) {
                    angular.element('#clipboard-toggle').removeClass('open');
                    angular.element('#clipboard-toggle + .clipboard-content').slideUp('fast')
                } else {
                    angular.element('#clipboard-toggle').addClass('open');
                    angular.element('#clipboard-toggle + .clipboard-content').slideDown('fast')
                }
            })
        }
    }
})();

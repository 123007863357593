(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patient-medication-fax-log', {
            parent: 'entity',
            url: '/patient-medication-fax-log',
            data: {
                authorities: [],
                pageTitle: 'Fax Logs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/patient-medication-fax-log/list/patient-medication-fax-logs.html',
                    controller: 'PatientMedicationFaxLogController',
                    controllerAs: 'vm'
                }
            }
        })
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartController', ChartController);

    ChartController.$inject = ['facility', 'Chart', 'filter', 'AlertService', '$stateParams',
        'ParseLinks', '$q', 'DateUtils', 'CoreService', 'TypeLevelCare', 'ACTIONS', 'access', 'PopupService',
        '$localStorage', 'UrgentIssues', '$state'];

    function ChartController(facility, Chart, filter, AlertService, $stateParams,
                             ParseLinks, $q, DateUtils, CoreService, TypeLevelCare, ACTIONS, access, PopupService,
                             $localStorage, UrgentIssues, $state) {

        var vm = this;

        vm.access = access[ACTIONS.ACTION_PATIENT_NEW].roles.join();
        vm.facility = facility;
        vm.filter = filter;
        vm.charts = [];
        // vm.page = 1;
        vm.showFilter = false;
        vm.toggleLayout = $stateParams.levelCare != null || $localStorage.chartToggleLayout || false;
        vm.typeLevelCares = TypeLevelCare.findAllByFacility(CoreService.getFilterByFacility(CoreService.getCurrentFacility()));
        vm.title = 'Patients';
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.admissionDateFrom = false;
        vm.datePickerOpenStatus.admissionDateTo = false;
        vm.datePickerOpenStatus.dischargeDateFrom = false;
        vm.datePickerOpenStatus.dischargeDateTo = false;
        vm.itemsPerPage = 30;
        angular.element('.filter-box').hide();
        vm.filtersDropdown = false;
        vm.sorted = "Patient First Name";
        vm.sortDirection = "Ascending";
        vm.urgentIssues = {};

        vm.transition = transition;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.treatmentDays = treatmentDays;
        vm.showFilters = showFilters;
        vm.filterState = filterState;
        vm.popoverFilterEnable = popoverFilterEnable;
        vm.openCalendar = openCalendar;
        vm.searchByFilter = searchByFilter;
        vm.showCreatePatientPopup = showCreatePatientPopup;
        vm.getCurrentCensusPdf = getCurrentCensusPdf;
        vm.changeToggleLayoutState = changeToggleLayoutState;

        function getFilter() {
            var filter = {};

            filter.page = vm.filter.page - 1;
            filter.size = vm.itemsPerPage;
            filter.typeLevelCareId = vm.filter.typeLevelCare != null ? vm.filter.typeLevelCare.id : null;
            filter.facilityId = vm.filter.facilityId;
            filter.status = vm.filter.status;
            filter.statuses = vm.filter.statuses;
            filter.firstName = vm.filter.firstName;
            filter.lastName = vm.filter.lastName;
            filter.mrNo = vm.filter.mrNo;
            filter.dischargeDateFrom = vm.filter.dischargeDateFrom;
            filter.dischargeDateTo = vm.filter.dischargeDateTo;
            filter.admissionDateFrom = vm.filter.admissionDateFrom;
            filter.admissionDateTo = vm.filter.admissionDateTo;
            filter.searchQuery = vm.filter.searchQuery;
            filter.sort = vm.filter.sort;
            filter.direction = vm.filter.direction;
            filter.externalId = vm.filter.externalId;

            checkVoidParam(filter.sort);
            checkVoidParam(filter.dischargeDateFrom);
            checkVoidParam(filter.dischargeDateTo);
            checkVoidParam(filter.admissionDateFrom);
            checkVoidParam(filter.admissionDateTo);
            checkVoidParam(filter.searchQuery);

            CoreService.setChartFilter(vm.filter);

            return filter;
        }

        loadAll();

        function loadAll() {
            Chart.fullFilter(getFilter(), onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.charts = [];
                vm.charts = data;

                _.forEach(vm.charts, function (chart) {
                    return chart.urgentIssues = 0;
                })

                UrgentIssues.countByChartIds({
                    chartIds: _.map(vm.charts, function (chart) {
                            return chart.id;
                        })
                }, function (result) {
                    _.forEach(result, function (uissues) {
                        _.find(vm.charts, ['id', uissues.chartId]).urgentIssues = uissues.count;
                    })
                })
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function treatmentDays(dateString) {
            var date = moment(dateString),
                currentDate = moment();
            return currentDate.diff(date, 'days');
        }

        function showFilters() {
            angular.element('.filter-box').toggleClass('active');

            if (angular.element('.filter-box').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('.filter-box').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('.filter-box').slideUp();
            }
        }

        function searchByFilter() {
            loadAll();
            switch (vm.filter.sort) {
                case "firstName":
                    vm.sorted = "Patient First Name";
                    break;
                case "lastName":
                    vm.sorted = "Patient Last Name";
                    break;
                case "admissionDate":
                    vm.sorted = "Admission Date";
                    break;
                case "typeLevelCare.name":
                    vm.sorted = "Level Of Care";
                    break;
                case "referrer":
                    vm.sorted = "Referral Source";
                    break;
                case "dischargeDate":
                    vm.sorted = "Discharge Date";
                    break;
                case "primary.insuranceCarrier.name":
                    vm.sorted = "Insurance Carrier";
                    break;
            }
            switch (vm.filter.direction) {
                case "ASC":
                    vm.sortDirection = "Ascending";
                    break;
                case "DESC":
                    vm.sortDirection = "Descending";
                    break;

            }
        }

        function clear() {
            vm.filter.page = 1;
            vm.filter.firstName = null;
            vm.filter.lastName = null;
            vm.filter.mrNo = null;
            vm.filter.dischargeDateFrom = null;
            vm.filter.dischargeDateTo = null;
            vm.filter.admissionDateFrom = null;
            vm.filter.admissionDateTo = null;
            vm.filter.typeLevelCare = null;
            vm.filter.sort = "firstName";
            vm.filter.direction = "ASC";
            vm.filter.externalId = null;
            vm.sorted = "Patient First Name";
            vm.sortDirection = "Ascending";

            loadAll();
        }

        function filterState(status) {
            vm.filter.status = status;
            vm.filter.page = 1;

            switch (status) {
                case 'CURRENT':
                case 'WAITING': {
                    vm.filter.statuses = status;

                    break;
                }
                case 'ARCHIVE': {
                    vm.filter.statuses = ['ARCHIVE', 'CLOSED'];

                    break;
                }
                default: {
                    vm.filter.statuses = null;
                }
            }

            loadAll();
        }

        function popoverFilterEnable() {
            vm.showFilter = !vm.showFilter;
        }

        function checkVoidParam(param) {
            if (!param || param === '') {
                param = null;
            }
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function showCreatePatientPopup() {
            PopupService.showCreatePatientPopup().result.then(function (result) {
                $state.go("chart", {id: result.id});
            });
        }

        function getCurrentCensusPdf() {
            Chart.getCurrentCensusPdf({id: vm.facility.id});
        }

        function changeToggleLayoutState() {
            vm.toggleLayout = !vm.toggleLayout;
            $localStorage.chartToggleLayout = vm.toggleLayout;
        }
    }
})();

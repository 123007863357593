(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InsuranceBillingConfigDialogController', InsuranceBillingConfigDialogController);

    InsuranceBillingConfigDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity',
        'InsuranceBillingConfig', 'InsuranceCarrierSearch', 'TypeLevelCare',
        'CoreService'];

    function InsuranceBillingConfigDialogController ($timeout, $scope, $uibModalInstance, entity,
                                                     InsuranceBillingConfig, InsuranceCarrierSearch, TypeLevelCare,
                                                     CoreService) {
        var vm = this;

        vm.insuranceBillingConfig = entity;
        vm.facility = CoreService.getCurrentFacility();
        vm.typelevelcares = TypeLevelCare.findAllByFacility(
            CoreService.getFilterByFacility(CoreService.getCurrentFacility()));

        vm.getInsuranceCarriers = getInsuranceCarriers
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.insuranceBillingConfig.id !== null) {
                InsuranceBillingConfig.update(vm.insuranceBillingConfig, onSaveSuccess, onSaveError);
            } else {
                InsuranceBillingConfig.save(vm.insuranceBillingConfig, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:insuranceBillingConfigUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getInsuranceCarriers(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);

                vm.insuranceCarriers = InsuranceCarrierSearch.query({
                    query: query
                });
            }
        }
    }
})();

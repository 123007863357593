(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationDiagnosisController', EvaluationDiagnosisController);

    EvaluationDiagnosisController.$inject = ['$scope', '$state', 'EvaluationDiagnosis', '$uibModal', 'entity',
        'PopupService', 'Diagnoses'];

    function EvaluationDiagnosisController($scope, $state, EvaluationDiagnosis, $uibModal, entity,
                                           PopupService, Diagnoses) {
        var vm = this;

        vm.evaluation = entity;
        vm.evaluationDiagnoses = [];

        vm.loadAll = loadAll;
        vm.add = add;
        vm.history = history;
        vm.edit = edit;

        loadAll();

        function loadAll() {
            if (!vm.evaluation.diagnosis) {
                return;
            }

            EvaluationDiagnosis.findAllByEvaluation({id: vm.evaluation.id}, function (result) {
                vm.evaluationDiagnoses = result;
            });
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/diagnoses/update/diagnoses-dialog.html',
                controller: 'EvaluationDiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            evaluation: {id: vm.evaluation.id},
                            diagnosisDTO: {
                                diagnoses: {
                                    name: null,
                                    status: null,
                                    id: null,
                                    chart: vm.evaluation.chart
                                },
                                icd10S: null
                            }
                        }
                    }
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function history() {
            $uibModal.open({
                templateUrl: 'app/entities/evaluation-diagnosis/history/evaluation-diagnoses-history.html',
                controller: 'EvaluationDiagnosesHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    chart: vm.evaluation.chart
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function edit(evaluationDiagnosis) {
            PopupService.showDiagnosesEditPopup(evaluationDiagnosis.diagnosesId).result.then(function (result) {
                Diagnoses.findAllActiveDiagnosesByChartId({id: vm.evaluation.chartId}, function (diagnosisActive) {
                    if (result.id && !_.find(diagnosisActive, {id: result.id})) {
                        EvaluationDiagnosis.delete({id: evaluationDiagnosis.id}, function () {
                            loadAll();
                        })
                    } else {
                        loadAll();
                    }
                });
            });
        }

        vm.onDragEnd = onDragEnd;

        function onDragEnd(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].xorder !== i + 1) {
                    list[i].xorder = i + 1;
                    EvaluationDiagnosis.reorder({id: list[i].id, xorder: list[i].xorder})

                } else {
                    list[i].xorder = i + 1;
                }
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PhysicianReviewController', PhysicianReviewController);

    PhysicianReviewController.$inject = ['$scope'];

    function PhysicianReviewController($scope) {
        var vm = this;

        vm.title = 'Physician Reviews';
        vm.entityClassHumanized = 'Physician Review';
        vm.entityStateName = 'physician-review';

        vm.emitReload = emitReload;

        function emitReload(tab) {
            $scope.$emit('bluebookApp:physicianReviews' + tab);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ActivitiesLogCallsDialogController', ActivitiesLogCallsDialogController);

    ActivitiesLogCallsDialogController.$inject = ['$uibModalInstance'];

    function ActivitiesLogCallsDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateLogCall = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('FacilityBillingConfigController', FacilityBillingConfigController);

    FacilityBillingConfigController.$inject = ['$scope', '$state', 'FacilityBillingConfig', '$compile',
        'DTOptionsBuilder', 'DTColumnBuilder', '$q', 'CoreService', '$uibModal',
        'GenericEntityDatatableService', 'ConfirmationService'];

    function FacilityBillingConfigController($scope, $state, FacilityBillingConfig, $compile,
                                             DTOptionsBuilder, DTColumnBuilder, $q, CoreService, $uibModal,
                                             GenericEntityDatatableService, ConfirmationService) {
        var vm = this;

        vm.totalItems = 0;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Facility Billing Config',
            entityClassHumanized: 'Facility Billing Config',
            entityStateName: 'facility-billing-config',
            service: FacilityBillingConfig,
            serviceMethod: 'getAllByFacility',
            newAction: false,
            params: params,
            enableSearch: false,
            columns: [
                {
                    name: null,
                    title: 'Price',
                    render: function (data) {
                        return '$ ' + data.price;
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Config',
                            action: function () {
                                return add();
                            },
                            ngIf: function (filter) {
                                return vm.totalItems === 0
                            }
                        }),
                    ]
                },
            ],
            filterCallback: function (filter) {
                vm.totalItems = filter.totalItems;
            }
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-billing-config/update/facility-billing-config-dialog.html',
                controller: 'FacilityBillingConfigDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            id: null,
                            price: 0,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/facility-billing-config/update/facility-billing-config-dialog.html',
                controller: 'FacilityBillingConfigDialogController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['FacilityBillingConfig', function (FacilityBillingConfig) {
                        return FacilityBillingConfig.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Facility Billing Config').result.then(function () {
                    FacilityBillingConfig.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

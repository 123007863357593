(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ROLES'];

    function stateConfig($stateProvider, ROLES) {
        $stateProvider
            .state('group-session-form-tpl', {
                parent: 'entity',
                url: '/group-session-form-tpl',
                data: {
                    authorities: [],
                    pageTitle: 'GroupSessionFormTpls'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group-session-form-tpl/list/group-session-form-tpl.html',
                        controller: 'GroupSessionFormTplController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL, access);
                }]
            })
            .state('group-session-form-tpl.new', {
                parent: 'group-session-form-tpl',
                url: '/new',
                data: {
                    authorities: []
                },
                ncyBreadcrumb: {
                    label: 'New Group Session Form',
                    parent: 'group-session-form-tpl'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group-session-form-tpl/update/group-session-form-tpl-edit.html',
                        controller: 'GroupSessionFormTplEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            facility: CoreService.getCurrentFacility(),
                            name: null,
                            description: null,
                            jsonTemplate: null,
                            id: null
                        };
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL, access);
                }]
            })
            .state('group-session-form-tpl.edit', {
                parent: 'group-session-form-tpl',
                url: '/{id}/edit',
                data: {
                    authorities: [ROLES.ROLE_USER]
                },
                ncyBreadcrumb: {
                    label: 'Edit Group Session Form',
                    parent: 'group-session-form-tpl'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group-session-form-tpl/update/group-session-form-tpl-edit.html',
                        controller: 'GroupSessionFormTplEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['GroupSessionFormTpl', '$stateParams', function (GroupSessionFormTpl, $stateParams) {
                        return GroupSessionFormTpl.get({id: $stateParams.id}).$promise;
                    }],
                    access: ['Auth', 'ACTIONS', function (Auth, ACTIONS) {
                        var actions = [ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL];
                        return Auth.loadActionsAccess(actions).$promise;
                    }]
                },
                onEnter: ['Auth', 'ACTIONS', 'access', function (Auth, ACTIONS, access) {
                    Auth.checkActionAuthorization(ACTIONS.ACTION_MAIN_MENU_TEMPLATES_GROUP_SESSIONS_FORM_TPL, access);
                }]
            });
    }
})();

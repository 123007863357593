(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('PatientResultDet', PatientResultDet);

    PatientResultDet.$inject = ['$resource'];

    function PatientResultDet ($resource) {
        var resourceUrl =  'api/patient-result-dets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getAllResultsDetByResult': {url: 'api/patient-result-dets/by-result/:id', method: 'GET', isArray: true},
            'getComments': {url: 'api/patient-result-dets/comments/:id', method: 'GET', isArray: true},
            'getEmployeeComments': {url: 'api/patient-result-dets/emp-comments/:id', method: 'GET', isArray: true},
            'acknowledge': {url: 'api/patient-result-dets/acknowledge', method: 'POST'},
            'acknowledgeAbnormal': {url: 'api/patient-result-dets/acknowledge-abnormal', method: 'POST'},
            'get6M': {url: 'api/patient-result-dets/6M/:detailId', method: 'GET', isArray: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('MessageCampaignDialogController', MessageCampaignDialogController);

    MessageCampaignDialogController.$inject = ['$uibModalInstance', 'Greeting', 'CoreService', 'FacilityPhoneNumber',
        'chartIds', 'Campaign', 'toastr', 'campaignId', 'all'];

    function MessageCampaignDialogController($uibModalInstance, Greeting, CoreService, FacilityPhoneNumber,
                                             chartIds, Campaign, toastr, campaignId, all) {
        var vm = this;

        vm.message = {};
        vm.message.greeting = {}
        vm.greetings = Greeting.byFilter({facilityId: CoreService.getCurrentFacility().id});
        vm.facilitiesPhoneNumbers = FacilityPhoneNumber.byFilter({facilityId: CoreService.getCurrentFacility().id});

        vm.clear = clear;
        vm.sendMessage = sendMessage;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function sendMessage() {
            vm.isSaving = true;

            Campaign.sendMessage({
                chartIds: chartIds,
                facilityPhoneNumberId: vm.message.facilityPhoneNumber.id,
                greetingId: vm.message.greeting.id,
                message: vm.message.message,
                campaignId: campaignId,
                all: all
            }, function (data) {
                if (data.value == 0) {
                    toastr.success('Messages have been sent successfully');
                } else if (data.value == chartIds.length) {
                    toastr.error('Messages have not been sent');
                } else {
                    toastr.info(data.value + ' messages have not been sent');
                }
                $uibModalInstance.close();
            })
        }
    }
})();

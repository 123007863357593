/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('IncomingPatientsBoxController', IncomingPatientsBoxController);

    IncomingPatientsBoxController.$inject = ['PopupService', 'facility', 'Chart', '$state', 'AlertService'];

    function IncomingPatientsBoxController(PopupService, facility, Chart, $state, AlertService) {
        var vm = this;

        vm.filter = {};
        vm.charts = [];
        vm.facility = facility;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.queryCount = 0;

        vm.loadAll = loadAll;
        vm.showCreatePatientPopup = showCreatePatientPopup;
        vm.transition = transition;
        vm.openChart = openChart;
        vm.levelCare = levelCare;
        vm.referral = referral;
        vm.searchByQuery = searchByQuery;

        function showCreatePatientPopup() {
            PopupService.showCreatePatientPopup().result.then(function () {
                loadAll();
            });
        }

        loadAll();

        function loadAll() {
            Chart.incoming(getFilter(), onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.charts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            loadAll();
        }

        function getFilter() {
            var filter = {};
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.facilityId = vm.facility.id;
            filter.searchQuery = vm.searchQuery;

            return filter;
        }

        function searchByQuery() {
            vm.page = 1;
            vm.filter.status = null;
            vm.transition();
        }

        function openChart(chartId) {
            $state.go('chart', {id: chartId});
        }

        function levelCare(data) {
            if (data && data.length > 0) {
                return data && data.length > 15 ? data.substr(0, 15) + "..." : data;
            } else {
                return "-";
            }
        }

        function referral(data) {
            if (data && data.length > 0) {
                return data && data.length > 15 ? data.substr(0, 15) + "..." : data;
            } else {
                return "-";
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PortController', PortController);

    PortController.$inject = ['GenericEntityDatatableService', 'Port', 'CoreService', 'ConfirmationService', '$q', '$uibModal'];

    function PortController(GenericEntityDatatableService, Port, CoreService, ConfirmationService, $q, $uibModal) {
        var vm = this;

        var params = CoreService.getFilterByCurrentCorporation();

        vm.descriptor = {
            title: 'Ports',
            newButtonTitle: 'New Port',
            entityClassHumanized: 'Ports',
            entityStateName: 'port',
            service: Port,
            serviceMethod: 'findAllByCorporation',
            options: [{key: 'aaSorting', value: [[1, 'asc']]}],
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'xorder',
                    title: 'Order',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Port',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/port/update/port-dialog.html',
                controller: 'PortDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            xorder: null,
                            id: null,
                            corporation: CoreService.getCorporation(),
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/port/update/port-dialog.html',
                controller: 'PortDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowTopClass: 'custom-dialog-styles',
                size: 'md',
                resolve: {
                    entity: ['Port', function (Port) {
                        return Port.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Port').result.then(function () {
                    Port.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('NewChartPreviewController', NewChartPreviewController);

    NewChartPreviewController.$inject = ['$uibModalInstance', '$rootScope', 'EmployeeNewChart', '$state'];

    function NewChartPreviewController($uibModalInstance, $rootScope, EmployeeNewChart, $state) {
        var vm = this;

        vm.clear = clear;
        vm.change = change;

        function clear() {
            $uibModalInstance.close();
        }

        function change() {
            EmployeeNewChart.save({showNewChart: !$rootScope.showNewChart}, function () {
                $rootScope.showNewChart = !$rootScope.showNewChart;
                if ($state.current.name === 'chart-new' || $state.current.name === 'chart-old') {
                    $state.go('chart', {id: $state.params.id});
                }
            })
            $uibModalInstance.close();
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('CrmOpportunity', CrmOpportunity);

    CrmOpportunity.$inject = ['$resource', 'DateUtils'];

    function CrmOpportunity($resource, DateUtils) {
        var resourceUrl = 'api/crm-opportunities/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.dob = DateUtils.convertDateTimeFromServer(data.dob);
                        data.contactDate = DateUtils.convertDateTimeFromServer(data.contactDate);
                        data.admissionDate = DateUtils.convertDateTimeFromServer(data.admissionDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findAllByFilter': {
                url: 'api/crm-opportunities/filter',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        _.forEach(data, function (item) {
                            item.name = renderName(item.firstName, item.lastName);
                            item.marketerName = renderName(item.marketerFirstName, item.marketerLastName);
                        })
                    }

                    function renderName(firstName, lastName) {
                        return (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
                    }

                    return data;
                }
            },
            'crmOpportunityMarketers': {
                url: 'api/crm-opportunities/marketers/corporation/:id',
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        _.forEach(data, function (item) {
                            item.fullName = item.firstName + ' ' + item.lastName;
                        })
                    }
                    return data;
                }
            },
            'getAllOpportunities': {
                url: 'api/crm-opportunities/by-account/:id',
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        _.forEach(data, function (item) {
                            item.fullName = item.firstName + ' ' + item.lastName;
                        })
                    }
                    return data;
                }
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('EvaluationTemplateMarsAssignDialogController', EvaluationTemplateMarsAssignDialogController);

    EvaluationTemplateMarsAssignDialogController.$inject = ['$timeout', 'chart', '$uibModalInstance', 'EvaluationTemplate', 'Evaluation',
        'toastr', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$filter', '$compile', '$scope'];

    function EvaluationTemplateMarsAssignDialogController($timeout, chart, $uibModalInstance, EvaluationTemplate, Evaluation,
                                                          toastr, DTOptionsBuilder, DTColumnBuilder, $q, $filter, $compile, $scope) {
        var vm = this;
        vm.chart = chart;

        vm.urgentIssues = [];
        vm.urgentIssue = {};
        vm.dtInstance = {};
        vm.reload = false;

        vm.clear = clear;
        vm.assign = assign;

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                EvaluationTemplate.findAllByMars({
                    id: chart.id
                }, function (result) {
                    vm.evaluationTemplate = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.evaluationTemplate, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('aaSorting', [[1, 'asc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Template Name'),
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function assign() {
            var assign = {};
            assign.ids = [];
            assign.chart = chart;

            _.forEach(vm.selected, function (value, key) {
                if (value) {
                    assign.ids.push(key);
                }
            });

            if (assign.ids.length > 0) {
                vm.isSaving = true;
                save(assign);
            }
        }

        function save(assign) {
            Evaluation.assign(assign, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                $uibModalInstance.close(true);
                toastr.success("Care & Delivery Add to the Chart");
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }

            vm.selectAll = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartDischargeDateHistoryController', ChartDischargeDateHistoryController);

    ChartDischargeDateHistoryController.$inject = ['DTOptionsBuilder', 'DTColumnBuilder', 'DataTablesService', 'chart',
        'Chart', '$q', '$compile', '$scope', '$timeout', '$uibModalInstance', '$rootScope'];

    function ChartDischargeDateHistoryController(DTOptionsBuilder, DTColumnBuilder, DataTablesService, chart,
                                                 Chart, $q, $compile, $scope, $timeout, $uibModalInstance, $rootScope) {
        var vm = this;
        vm.chart = chart;

        vm.facesheetTpls = [];
        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.selected = {};
        vm.selectAll = false;

        vm.clear = clear;
        vm.print = print;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getSelectedIds = DataTablesService.getSelectedIds;
        vm.search = search;
        vm.reload = reload;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Chart.dischargeDateHistory(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                defer.resolve(result);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withOption("bLengthChange", false)
            .withOption("bPaginate", false)
            // .withOption('aaSorting', [[0, 'desc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn("dischargeDate").withTitle('Discharge Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.amDateFormat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Employee').renderWith(function (data, type, full) {
                return data.employee.firstName + " " + data.employee.lastName;
            })
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getFilter() {
            var filter = {};

            filter.chartId = vm.chart.id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;

            return filter
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function reload() {
            vm.dtInstance.reloadData();
        }
    }
})();

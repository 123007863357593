/**
 * Created by jesusrodriguez on 2/23/19.
 */
(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('DischargeTypeBoxController', DischargeTypeBoxController);

    DischargeTypeBoxController.$inject = ['facility', '$scope', '$state', 'CoreService', 'Chart'];

    function DischargeTypeBoxController(facility, $scope, $state, CoreService, Chart) {

        var vm = this;
        vm.dischargeType = null;
        vm.count = 0;
        vm.openCharts = openCharts;

        vm.dischargeTypeAdjust = dischargeTypeAdjust;
        loadData();

        function loadData() {
            vm.dischargeType = Chart.countDischargeType({id: CoreService.getCurrentFacility().id}, function (result) {
                _.forEach(result, function (el) {
                    vm.count += el.count;
                });
            });
        }

        function dischargeTypeAdjust(data) {
            return data && data.length > 0 ? (data && data.length > 35 ? data.substr(0, 35) + " ..." : data) : "-";
        }

        function openCharts(typeDischargeType) {
            $state.go('charts-discharge', {dischargeType: typeDischargeType ? typeDischargeType.id : null});
        }
    }
})();

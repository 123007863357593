(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CareCoordinationTemplateController', CareCoordinationTemplateController);

    CareCoordinationTemplateController.$inject = ['GenericEntityDatatableService', 'CoreService',
        'CareCoordinationTemplate', 'GUIUtils', '$uibModal', 'ConfirmationService',
        '$q'];

    function CareCoordinationTemplateController(GenericEntityDatatableService, CoreService,
                                                CareCoordinationTemplate, GUIUtils, $uibModal, ConfirmationService,
                                                $q) {
        var vm = this;

        var params = {facilityId: CoreService.getCurrentFacility().id};

        vm.descriptor = {
            title: 'Care Coordination Templates',
            newButtonTitle: 'New Care Coordination Template',
            entityClassHumanized: 'Care Coordination Templates',
            entityStateName: 'care-coordination-template',
            service: CareCoordinationTemplate,
            serviceMethod: 'findAllByFilter',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'title',
                    title: 'Title',
                },
                {
                    name: 'description',
                    title: 'Description',
                },
                {
                    name: 'days',
                    title: 'Days',
                },
                {
                    name: 'notes',
                    title: 'Notes',
                },
                {
                    name: null,
                    title: 'Enabled',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.active);
                    }
                },
                {
                    name: 'typeAction',
                    title: 'Type Action',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Care Coordination Template',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/care-coordination-template/update/care-coordination-template-dialog.html',
                controller: 'CareCoordinationTemplateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            title: null,
                            description: null,
                            days: null,
                            notes: null,
                            active: false,
                            typeAction: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/care-coordination-template/update/care-coordination-template-dialog.html',
                controller: 'CareCoordinationTemplateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CareCoordinationTemplate', function (CareCoordinationTemplate) {
                        return CareCoordinationTemplate.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Care Coordination Template').result.then(function () {
                    CareCoordinationTemplate.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

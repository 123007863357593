(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientResultController', PatientResultController);

    PatientResultController.$inject = ['GUIUtils', '$q', 'PatientResult', '$uibModal', 'CoreService', '$rootScope',
        'GenericEntityDatatableService', 'PopupService'];

    function PatientResultController(GUIUtils, $q, PatientResult, $uibModal, CoreService, $rootScope,
                                     GenericEntityDatatableService, PopupService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
            abnormalRange: null
        }

        vm.descriptor = {
            title: 'Lab Results',
            entityClassHumanized: 'Patient Results',
            entityStateName: 'patient-result',
            service: PatientResult,
            serviceMethod: 'getAllByFilter',
            params: params,
            pagination: 'remote',
            newAction: false,
            options: [
                {key: 'aaSorting', value: [[5, 'desc'], [2, 'asc']]}
            ],
            selectionColumn: 'id',
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'patientName',
                    title: 'Patient Name',
                },
                {
                    name: 'dob',
                    title: 'DOB',
                    render: function (data) {
                        return moment(data.dob).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Barcode',
                    render: function (data) {
                        return data.accessionNumber ? data.accessionNumber : '';
                    }
                },
                {
                    name: null,
                    title: 'Collection Date',
                    render: function (data) {
                        return moment(data.collectionDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Received Date',
                    render: function (data) {
                        return moment(data.lastModifiedDate).format("MM/DD/YYYY");
                    }
                },
                {
                    name: null,
                    title: 'Alert',
                    options: [
                        {key: 'width', value: '175px'}
                    ],
                    render: function (data) {
                        if (data.abnormalRange) {
                            return GUIUtils.getStatusTemplate('ABNORMAL', 'danger');
                        } else {
                            return GUIUtils.getStatusTemplate('NORMAL', 'success');
                        }
                    }
                },
                {
                    name: 'status',
                    title: 'Status',
                    render: function (data) {
                        if (data.status == 'PARTIAL') {
                            return GUIUtils.getStatusTemplate(data.status, 'warning');
                        } else {
                            return GUIUtils.getStatusTemplate(data.status, 'primary');
                        }
                    }
                },
                {
                    name: null,
                    title: 'Assigned',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.chartId ? true : false);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getDetailsAction(), {
                    action: function (data) {
                        details(data.id);
                    },
                }),
                angular.extend({}, GenericEntityDatatableService.getViewPdfAction(), {
                    action: function (data) {
                        printRangePDFs(data.id);
                    },
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        {
                            name: 'All Results',
                            iClass: 'fa fa-flask',
                            ngClass: function (filter) {
                                return filter.abnormalRange == null ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.abnormalRange = null;
                            }
                        },
                        {
                            name: 'Abnormal',
                            iClass: 'fa fa-thumbs-down',
                            ngClass: function (filter) {
                                return filter.abnormalRange ?
                                    ['active', 'label-danger'] :
                                    [];
                            },
                            action: function (filter) {
                                filter.abnormalRange = true;
                            }
                        },
                        {
                            name: 'Normal',
                            iClass: 'fa fa-thumbs-up',
                            ngClass: function (filter) {
                                return filter.abnormalRange === false ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter, selected) {
                                filter.abnormalRange = false;
                            }
                        }
                    ],
                },
                {
                    type: 'group',
                    buttons: [
                        {
                            title: 'Current Month',
                            iClass: 'fa fa-calendar',
                            ngClass: function (filter) {
                                return isCurrentMonth(filter.startDate, filter.endDate) ?
                                    ['active', 'bg-green'] :
                                    [];
                            },
                            action: function (filter) {
                                if (isCurrentMonth(filter.startDate, filter.endDate)) {
                                    var endDate = new Date();
                                    var startDate = new Date();
                                    startDate.setDate(endDate.getDate() - 30);

                                    filter.startDate = startDate;
                                    filter.endDate = endDate;
                                } else {
                                    filter.startDate = getCurrentMonthStartDay();
                                    filter.endDate = getCurrentMonthEndDay();
                                }
                            }
                        }
                    ]
                }
            ],
            filters: [
                {
                    name: 'abnormalRange',
                    visible: false
                },
                {
                    name: 'patientName',
                    // title: 'Patient Name',
                    placeholder: 'Patient Name...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'dob',
                    // title: 'Day of Birth',
                    placeholder: 'Day of Birth...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.dob ? moment(filter.dob).format("YYYY-MM-DD") : null;
                    },
                    visible: true
                },
                {
                    name: 'accessionNumber',
                    // title: 'Barcode',
                    placeholder: 'Barcode...',
                    type: 'string',
                    visible: true
                },
                {
                    name: 'status',
                    placeholder: 'Status...',
                    type: 'select',
                    values: ['PARTIAL', 'FINAL'],
                    getValue: function (entity) {
                        return entity;
                    },
                    getDisplayValue: function (entity) {
                        return entity;
                    },
                    visible: true
                },
                {
                    name: 'startDate',
                    // title: 'Start Date',
                    placeholder: 'Start Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.startDate ? moment(filter.startDate).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        var startDate = new Date();
                        startDate.setDate(startDate.getDate() - 30);

                        return startDate;
                    },
                    visible: true
                },
                {
                    name: 'endDate',
                    // title: 'End Date',
                    placeholder: 'End Date...',
                    type: 'date',
                    format: $rootScope.inputDateFormat,
                    read: function (filter) {
                        return filter.endDate ? moment(filter.endDate).format("YYYY-MM-DD") : null;
                    },
                    defaultValue: function () {
                        return new Date();
                    },
                    visible: true
                },
            ]
        }

        function printRangePDFs(id) {
            var ids;

            if (id) {
                ids = [id];
            } else {
                ids = getSelectedIds();
            }

            if (ids.length > 0) {
                PatientResult.getAllInOneRangePdf({ids: ids});
            }
        }

        function details(id) {
            PopupService.showPatientResultDet(id);
        }

        function getCurrentMonthStartDay() {
            var nowDate = new Date();

            return new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
        }

        function getCurrentMonthEndDay() {
            var nowDate = new Date();

            return new Date(nowDate.getFullYear(), nowDate.getMonth() + 1, 0);
        }

        function isCurrentMonth(startDate, endDate) {

            return moment(startDate).format("MM/DD/YYYY") === moment(getCurrentMonthStartDay()).format("MM/DD/YYYY") &&
                moment(endDate).format("MM/DD/YYYY") === moment(getCurrentMonthEndDay()).format("MM/DD/YYYY");
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ChartExportFileController', ChartExportFileController);

    ChartExportFileController.$inject = ['$scope', '$state', 'ChartExportFile'];

    function ChartExportFileController($scope, $state, ChartExportFile) {
        var vm = this;

        vm.chartExportFiles = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ChartExportFile.query(function (result) {
                vm.chartExportFiles = result;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            // ChartExportFileSearch.query({query: vm.searchQuery}, function (result) {
            //     vm.chartExportFiles = result;
            // });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BuildingController', BuildingController);

    BuildingController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Building', '$uibModal',
        'ConfirmationService', '$q'];

    function BuildingController(GenericEntityDatatableService, CoreService, Building, $uibModal,
                                ConfirmationService, $q) {
        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Buildings',
            newButtonTitle: 'New Building',
            entityClassHumanized: 'Buildings',
            entityStateName: 'building',
            service: Building,
            serviceMethod: 'byFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Building',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/building/update/building-dialog.html',
                controller: 'BuildingDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/building/update/building-dialog.html',
                controller: 'BuildingDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Building', function (Building) {
                        return Building.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Building').result.then(function () {
                    Building.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

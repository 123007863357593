(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmReferralSourceDetailController', CrmReferralSourceDetailController);

    CrmReferralSourceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CrmReferralSource', 'CountryState', 'Employee', 'CrmReferralSourceType', 'Corporation', 'TypeLevelCare'];

    function CrmReferralSourceDetailController($scope, $rootScope, $stateParams, previousState, entity, CrmReferralSource, CountryState, Employee, CrmReferralSourceType, Corporation, TypeLevelCare) {
        var vm = this;

        vm.crmReferralSource = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bluebookApp:crmReferralSourceUpdate', function(event, result) {
            vm.crmReferralSource = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('BusinessCredentialController', BusinessCredentialController);

    BusinessCredentialController.$inject = ['GenericEntityDatatableService', 'BusinessCredential', 'CoreService',
        '$uibModal', '$q', 'ConfirmationService'];

    function BusinessCredentialController(GenericEntityDatatableService, BusinessCredential, CoreService,
                                          $uibModal, $q, ConfirmationService) {

        var vm = this;

        var params = {
            id: CoreService.getCurrentFacility().id
        };

        vm.descriptor = {
            title: 'Business Credential',
            newButtonTitle: 'New Business Credential',
            entityClassHumanized: 'Business Credential',
            entityStateName: 'business-credential',
            service: BusinessCredential,
            serviceMethod: 'byFacility',
            params: params,
            newAction: false,
            columns: [
                {
                    name: 'businessRole',
                    title: 'Business Role',
                },
                {
                    name: null,
                    title: 'Credentials',
                    render: function (data) {
                        return _.map(data.credentials, 'name').join(', ');
                    }
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction('business-credential'), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction('business-credential'), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Business Credential',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/business-credential/update/business-credential-dialog.html',
                controller: 'BusinessCredentialDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            role: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/business-credential/update/business-credential-dialog.html',
                controller: 'BusinessCredentialDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['BusinessCredential', function (BusinessCredential) {
                        return BusinessCredential.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Business Credential').result.then(function () {
                    BusinessCredential.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmReferralContactDialogController', CrmReferralContactDialogController);

    CrmReferralContactDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'CrmReferralContact', 'Corporation', 'CrmReferralSourceSearch', 'CoreService'];

    function CrmReferralContactDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CrmReferralContact,
                                                 Corporation, CrmReferralSourceSearch, CoreService) {
        var vm = this;

        vm.crmReferralContact = entity;
        vm.crmreferralsources = CrmReferralSourceSearch.query(CoreService.getFilterByCurrentCorporation());
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.crmReferralContact.id !== null) {
                CrmReferralContact.update(vm.crmReferralContact, onSaveSuccess, onSaveError);
            } else {
                CrmReferralContact.save(vm.crmReferralContact, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bluebookApp:crmReferralContactUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AssistantController', AssistantController);

    AssistantController.$inject = ['GenericEntityDatatableService', 'CoreService', 'Assistant', 'GUIUtils', '$uibModal',
        'ConfirmationService', '$q'];

    function AssistantController(GenericEntityDatatableService, CoreService, Assistant, GUIUtils, $uibModal,
                                 ConfirmationService, $q) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        vm.descriptor = {
            title: 'Assistants',
            newButtonTitle: 'New Assistant',
            entityClassHumanized: 'Assistants',
            entityStateName: 'assistant',
            service: Assistant,
            serviceMethod: 'search',
            params: params,
            newAction: false,
            pagination: 'remote',
            columns: [
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: null,
                    title: 'Active',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.active);
                    }
                },
                {
                    name: null,
                    title: 'Sections',
                    render: function (data) {
                        return _.map(data.sections, 'name').join(',');
                    }
                },
                {
                    name: 'note',
                    title: 'Note',
                },
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Assistant',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/assistant/update/assistant-dialog.html',
                controller: 'AssistantDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            note: null,
                            active: true,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/assistant/update/assistant-dialog.html',
                controller: 'AssistantDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['Assistant', function (Assistant) {
                        return Assistant.get({id: id}).$promise;
                    }]
                }
            }).result;
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Assistant').result.then(function () {
                    Assistant.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();

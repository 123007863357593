(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ShiftDetailController', ShiftDetailController);

    ShiftDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity','Shift', 'Building', 'ShiftType',
        'Employee', 'ShiftChart', '$uibModalInstance'];

    function ShiftDetailController($scope, $rootScope, $stateParams, entity, Shift, Building, ShiftType,
                                   Employee, ShiftChart, $uibModalInstance) {

        var vm = this;

        vm.clear = clear;

        vm.entity = entity;
        vm.shift = entity;
        vm.totalItems = null;
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.loadPage =loadPage;

        loadShiftCharts();

        function loadShiftCharts() {
            vm.shift.shiftCharts = [];
            ShiftChart.pageableByFindAllShiftCharts({
                id: vm.shift.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, function (result, headers) {
                if (result) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.shift.shiftCharts = result;
                }
            }).$promise.then(function (data) {

            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel')
        }

        function loadPage() {
            loadShiftCharts();
        }
    }
})();

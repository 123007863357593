(function() {
    'use strict';
    angular
        .module('bluebookApp')
        .factory('ShiftChart', ShiftChart);

    ShiftChart.$inject = ['$resource'];

    function ShiftChart ($resource) {
        var resourceUrl =  'api/shift-charts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT', ignoreLoadingBar: true },
            'pageableByFindAllShiftCharts': { url: 'api/shift-charts/shiftId/:id',
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(data) {
                        data = angular.fromJson(data);
                        var result = [];
                        data.forEach(function (element) {
                            element[0].shift = element[1];
                            result.push(element[0]);
                        });
                        return result;
                    }
                }
                },
            'findOneById': { url: 'api//shift-charts/custom/:id', method: 'GET',ignoreLoadingBar: true}
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReportEditDialogController', ReportEditDialogController);

    ReportEditDialogController.$inject = ['$uibModalInstance', 'entity', 'Report', 'CoreService', 'DateUtils',
        'GUIUtils', 'Employee'];

    function ReportEditDialogController($uibModalInstance, entity, Report, CoreService, DateUtils,
                                        GUIUtils, Employee) {
        var vm = this;

        vm.report = entity
        vm.options = [];
        vm.optionsRequired = [];
        vm.form = {};
        vm.conditions = [];
        vm.reportCategories = [];
        vm.conditionValues = [];
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = {};
        vm.datePickerOpenStatus.date2 = {};
        vm.datePickerOpenStatus.datetime = {};
        vm.days = GUIUtils.getDays();
        vm.schedule = GUIUtils.weekCodeStringToDays(vm.report.schedule);
        vm.reportCategories = Report.getAllReportCategory();
        vm.columns = [
            {name: "Options", items: [], dragging: false},
            {name: "Selected", items: [], dragging: false}
        ];

        vm.onDrop = onDrop;
        vm.onMoved = onMoved;
        vm.onDragStart = onDragStart;
        vm.nextToConditions = nextToConditions;
        vm.closeWizard = closeWizard;
        vm.finishedWizard = finishedWizard;
        vm.getSelectedItemsIncluding = getSelectedItemsIncluding;
        vm.initializeValuesByFieldType = initializeValuesByFieldType;
        vm.getValuesByFieldType = getValuesByFieldType;
        vm.addCondition = addCondition;
        vm.removeCondition = removeCondition;
        vm.openCalendar = openCalendar;
        vm.initDatePicker = initDatePicker;
        vm.getEmployees = getEmployees;
        vm.getReportFields = getReportFields;
        vm.searchValuesByService = searchValuesByService;
        vm.getCategoryLabel = getCategoryLabel;

        init();

        function init() {
            if (vm.report.id) {
                vm.conditions = vm.report.reportConditions;

                vm.columns[1].items = _.map(vm.report.reportColumns, 'reportField');

                Report.getAllReportFieldsByCategory({category: vm.report.category.id}, function (result) {
                    vm.columns[0].items = result;

                    _.forEach(vm.columns[1].items, function (item) {
                        _.remove(vm.columns[0].items, {'id': item.id});
                    });
                });

                Report.getAllReportFieldsRequiredByCategory({category: vm.report.category.id}, function (result) {
                    if (result.length == 0) {

                        return;
                    }

                    _.forEach(result, function (required) {
                        vm.optionsRequired.push(required);
                    })
                });
            }
        }

        function getReportFields() {
            vm.columns[0].items = [];
            vm.columns[1].items = [];
            vm.conditions = [];
            vm.optionsRequired = [];

            Report.getAllReportFieldsByCategory({category: vm.report.category.id}, function (result) {
                vm.columns[0].items = result;
            });

            Report.getAllReportFieldsRequiredByCategory({category: vm.report.category.id}, function (result) {
                if (result.length == 0) {

                    return;
                }

                _.forEach(result, function (required) {
                    vm.optionsRequired.push(required);

                    addCondition(required);
                })
            });
        }

        function getSelectedItemsIncluding(list, item) {
            item.selected = true;
            return list.items.filter(function (item) {
                return item.selected;
            });
        }

        function onDragStart(list, event) {
            list.dragging = true;
            if (event.dataTransfer.setDragImage) {
                var img = new Image();
                img.src = 'content/images/copy-icon.png';
                event.dataTransfer.setDragImage(img, 0, 0);
            }
        }

        function onDrop(list, items, index) {
            angular.forEach(items, function (item) {
                item.selected = false;
            });
            list.items = list.items.slice(0, index)
                .concat(items)
                .concat(list.items.slice(index));
            return true;
        }

        function onMoved(list) {
            list.items = list.items.filter(function (item) {
                return !item.selected;
            });
        }

        function save() {
            vm.isSaving = true;
            vm.report.reportColumns = getColumns();
            vm.report.reportConditions = _.forEach(vm.conditions, function (condition) {
                condition.id = null;

                switch (condition.reportField.type) {
                    case 'VALUE':
                    case 'ENUM':
                    case 'VALUE_SEARCH': {
                        condition.reportValue = condition.reportValueObject.id;

                        break;
                    }
                    case 'DATE': {
                        condition.reportValue = condition.reportValue ? moment(condition.reportValue).format("YYYY-MM-DD") : null;
                        condition.reportValue2 = condition.reportValue2 ? moment(condition.reportValue2).format("YYYY-MM-DD") : null;
                    }
                }
            });
            vm.report.schedule = GUIUtils.daysToWeekCodeString(vm.schedule);

            if (vm.report.id !== null) {
                Report.update(vm.report, onSaveReportSuccess, onSaveReportError);
            } else {
                Report.save(vm.report, onSaveReportSuccess, onSaveReportError);
            }
        }

        function onSaveReportSuccess() {
            vm.isSaving = false;
            $uibModalInstance.close();
        }

        function onSaveReportError() {
            vm.isSaving = false;
        }

        function nextToConditions() {
            vm.options = _.filter(vm.columns[1].items, function (item) {
                return item.type !== 'NONE';
            });

            // _.remove(vm.conditions, function (condition) {
            //     return _.findIndex(vm.options, function (option) {
            //         return condition.reportField.id === option.id;
            //     }) === -1;
            // });

            _.forEach(vm.conditions, function (item, index) {
                initializeValuesByFieldType(item, index);
            });
        }

        function getColumns() {
            var columns = [];

            _.map(vm.columns[1].items, function (item, key) {
                return columns.push({
                    reportField: item,
                    xorder: key
                });
            })

            return columns;
        }

        function addCondition(reportField) {
            vm.conditions.push({
                id: null,
                reportField: reportField,
                reportCondition: null,
                reportValue: '',
                reportValueObject: null,
                required: reportField != null
            })
        }

        function removeCondition(index, item) {
            vm.conditions.splice(index, 1);

            if (item.reportField && item.reportField.type && (item.reportField.type === 'DATE' || item.reportField.type === 'DATETIME')) {
                vm.datePickerOpenStatus.date = {};
                vm.datePickerOpenStatus.datetime = {};

                _.forEach(vm.conditions, function (value, key) {
                    if (value.reportField.type === 'DATE' || value.reportField.type === 'DATETIME') {
                        initDatePicker(value.reportField.type.toLocaleLowerCase(), key);
                    }
                })
            }
        }

        function getValuesByFieldType(item, index) {
            item.reportValue = ''
            item.reportCondition = null;

            initializeValuesByFieldType(item, index);
        }

        function initializeValuesByFieldType(item, index) {

            switch (item.reportField.type) {
                case 'VALUE': {
                    if (!vm.conditionValues[item.reportField.id]) {
                        Report.getValuesByService({
                            facilityId: CoreService.getCurrentFacility().id,
                            value: item.reportField.id
                        }, function (result) {
                            vm.conditionValues[item.reportField.id] = result;
                            var index = _.findIndex(vm.conditionValues[item.reportField.id], function (conditionValue) {
                                return item.reportValue === conditionValue.id.toString();
                            })

                            item.reportValueObject = vm.conditionValues[item.reportField.id][index];
                        })
                    }
                    break;
                }
                case 'ENUM': {
                    if (!vm.conditionValues[item.reportField.id]) {
                        Report.getValuesByEnum({
                            value: item.reportField.id
                        }, function (result) {
                            vm.conditionValues[item.reportField.id] = _.map(result, function (item) {
                                return {'id': item, 'value': item};
                            });
                            var index = _.findIndex(vm.conditionValues[item.reportField.id], function (conditionValue) {
                                return item.reportValue === conditionValue.id.toString();
                            })

                            item.reportValueObject = vm.conditionValues[item.reportField.id][index];
                        });
                    }
                    break;
                }
                case 'DATE': {
                    item.reportValue = item.reportValue ? moment(item.reportValue).toDate() : null;
                    item.reportValue2 = item.reportValue2 ? moment(item.reportValue2).toDate() : null;

                    initDatePicker(item.reportField.type.toLocaleLowerCase(), index);
                    break;
                }
                case 'DATETIME': {
                    item.reportValue = moment(item.reportValue).toDate();
                    item.reportValue2 = item.reportValue2 ? moment(item.reportValue2).toDate() : null;

                    initDatePicker(item.reportField.type.toLocaleLowerCase(), index);
                    break;
                }
                case 'VALUE_SEARCH': {
                    if (vm.report.id === null) {
                        vm.conditionValues[item.reportField.id] = [];
                        vm.conditionValues[item.reportField.id][index] = [];
                    } else if (!vm.conditionValues[item.reportField.id]) {
                        Report.searchValuesByService(
                            {
                                facilityId: CoreService.getCurrentFacility().id,
                                value: item.reportField.id,
                                query: null,
                                id: Number(item.reportValue)
                            }, function (result) {
                                if (!vm.conditionValues[item.reportField.id]) {
                                    vm.conditionValues[item.reportField.id] = [];
                                }

                                vm.conditionValues[item.reportField.id][index] = result;
                                item.reportValueObject = vm.conditionValues[item.reportField.id][index][0];
                            }
                        )
                    }
                    break;
                }
            }
        }

        function finishedWizard() {
            save();
        }

        function closeWizard() {
            $uibModalInstance.dismiss();
        }

        function openCalendar(date, id) {
            vm.datePickerOpenStatus[date][id] = true;
        }

        function initDatePicker(date, index) {
            vm.datePickerOpenStatus[date][index] = false;
        }

        function getEmployees(query) {
            if (query) {
                var filter = {page: 0, size: 10, query: query, facilityId: CoreService.getCurrentFacility().id};

                Employee.findAllByFilter(filter, function (result) {
                    vm.employees = result;
                });
            }
        }

        function searchValuesByService(item, index, query) {
            if (query.length > 3) {

                Report.searchValuesByService(
                    {
                        facilityId: CoreService.getCurrentFacility().id,
                        value: item.reportField.id,
                        query: query,
                        id: null
                    }, function (result) {
                        vm.conditionValues[item.reportField.id][index] = result;
                    }
                )
            }
        }

        function getCategoryLabel(category) {
            return category.charAt(0) + category.replaceAll('_', ' ').slice(1).toLocaleLowerCase();
        }
    }
})();

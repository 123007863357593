(function() {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmReferralSourceTypeController', CrmReferralSourceTypeController);

    CrmReferralSourceTypeController.$inject = ['$scope', '$state', 'CrmReferralSourceType', 'CrmReferralSourceTypeSearch', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', '$compile', '$filter', 'CoreService'];

    function CrmReferralSourceTypeController ($scope, $state, CrmReferralSourceType, CrmReferralSourceTypeSearch, $q, DTColumnBuilder,
                                              DTOptionsBuilder, $compile, $filter, CoreService) {
        var vm = this;

        vm.crmReferralSourceTypes = [];
        vm.dtInstance = {};

        vm.title = 'Crm Referral Source Types';
        vm.entityClassHumanized = 'Crm Referral Source Types';
        vm.entityStateName = 'crm-referral-source-type';

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery === '') {
                CrmReferralSourceTypeSearch.query(CoreService.getFilterByCurrentCorporation(), function (result, headers) {
                    vm.crmReferralSourceTypes = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.crmReferralSourceTypes, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withDisplayLength(15)
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('aaSorting', [[1, 'asc'], [0, 'asc']])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return '<div class="btn-group dd-buttons" uib-dropdown dropdown-append-to-body>' +
                '<button type="button" class="btn btn-default btn-sm dd-toggle" uib-dropdown-toggle>' +
                '<i class="fa fa-ellipsis-v" aria-hidden="true"></i>' +
                '</button>' +
                '<ul class="dropdown-menu dropdown-menu-right buttons-menu" uib-dropdown-menu>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link orange" ' +
                'ui-sref="' + $state.current.name + '.edit({id:' + data.id + '})">' +
                '<i class="fa fa-edit" aria-hidden="true"></i>Edit' +
                '</a>' +
                '</li>' +
                '<li role="menuitem">' +
                '<a href="" class="dd-link red" ' +
                'ui-sref="' + $state.current.name + '.delete({id:' + data.id + '})">' +
                '<i class="fa fa-trash-o" aria-hidden="true"></i>Delete' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();

(function () {
    'use strict';

    angular.module('bluebookApp')
        .directive('udtXeditable', udtXeditable);

    udtXeditable.$inject = ['$uibModal'];

    function udtXeditable($uibModal) {
        var directive = {
            restrict: 'E',
            templateUrl: 'app/components/udt/udt-xeditable/udt-xeditable.html',
            scope: {//all this scope value defined, are attr for the directive. There use are explained below
                itemList: '=',//list of items
                onSave: '&', // function to execute after of
            },
            link: linkFunc
        };

        return directive;
        function linkFunc($scope) {

            $scope.checkName = function(data, id) {
                if (id === 2 && data !== 'awesome') {
                    return "Username 2 should be `awesome`";
                }
            };
                //save item
            $scope.saveItem = function(data, id) {
                //vm.user not updated yet
                angular.extend(data, {id: id});
                $scope.itemList.find(function (element) {
                    if (element.id  == id)
                        element.name = data.name;
                })
                return $scope.itemList;
            };

            // remove item
            $scope.removeItem = function(index) {
                $scope.itemList.splice(index, 1);
            };

            // add item
            $scope.addItem = function() {
                $scope.inserted = {
                    id: $scope.itemList.length+1,
                    name: ''
                    //status: null,
                    // group: null
                };
                $scope.itemList.push($scope.inserted);
            };
        }
    }
})();


(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('TelehealthMeetingDetailController', TelehealthMeetingDetailController);

    TelehealthMeetingDetailController.$inject = ['$uibModalInstance', 'entity', '$filter', 'TelehealthMeeting', 'CoreService', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', '$rootScope'];

    function TelehealthMeetingDetailController($uibModalInstance, entity, $filter, TelehealthMeeting, CoreService, $q, DTColumnBuilder,
                                               DTOptionsBuilder, $rootScope) {
        var vm = this;

        vm.telehealthMeeting = entity;
        vm.dtInstance = {};

        vm.close = close;
        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                TelehealthMeeting.getParticipants({
                    id: vm.telehealthMeeting.id,
                    facilityId: CoreService.getCurrentFacility().id
                }, function (result) {
                    vm.participants = result;
                    vm.telehealthMeeting.participants = result.length;
                    defer.resolve(result);
                });
            }else {
                defer.resolve($filter('filter')(vm.participants, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('tp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withDisplayLength(5)

        vm.dtColumns = [
            DTColumnBuilder.newColumn('identity').withTitle('Participant'),
            DTColumnBuilder.newColumn(null).withTitle('Join Time').notSortable().renderWith(function (data, type, full) {
                return moment(data.startDate).format($rootScope.amDateFormat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Time').notSortable().renderWith(function (data, type, full) {
                return data.endDate ? (moment(data.endDate).format($rootScope.amDateFormat)) : '-';
            })
        ];

        function close() {
            $uibModalInstance.close();
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})
();

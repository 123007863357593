(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('CrmTaskController', CrmTaskController);

    CrmTaskController.$inject = ['CrmTask', 'AlertService', 'entity', '$uibModal', 'CoreService', '$sce'];

    function CrmTaskController(CrmTask, AlertService, entity, $uibModal, CoreService, $sce) {
        var vm = this;

        vm.crmOpportunity = entity;
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.crmTasks = [];
        vm.pinCrmTasks = [];
        vm.groupedCrmTasks = [];
        vm.employee = CoreService.getCurrentEmployee();

        vm.add = add;
        vm.edit = edit;
        vm.del = del;
        vm.isOwner = isOwner;
        vm.pin = pin;
        vm.completed = completed;
        vm.getUnpin = getUnpin;
        vm.trustAsHtml = trustAsHtml;

        loadAll();

        function loadAll() {
            CrmTask.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                pin: true,
                unpaged: true
            }, function (data, headers) {
                vm.pinCrmTasks = data;
            }, onError);

            getUnpin();
        }

        function add() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-task/crm-task-dialog.html',
                controller: 'CrmTaskDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            name: null,
                            completed: false,
                            description: null,
                            dueDate: new Date(),
                            reminder: new Date(),
                            pin: false,
                            id: null,
                            crmOpportunity: vm.crmOpportunity,
                            assignedTo: null,
                            corporation: CoreService.getCorporation()
                        }
                    }]
                }
            }).result.then(function () {
                vm.page = 1;
                loadAll();
            }, function () {
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-task/crm-task-dialog.html',
                controller: 'CrmTaskDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CrmTask', function (CrmTask) {
                        return CrmTask.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function del(id) {
            CrmTask.delete({id: id}, function () {
                loadAll();
            });
        }

        function isOwner(task) {
            return vm.employee.id == task.employee.id;
        }

        function pin(task) {
            CrmTask.pin(task, function () {
                loadAll();
            })
        }

        function completed(task) {
            CrmTask.completed(task, function () {
                loadAll();
            })
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getUnpin() {
            CrmTask.findAllByFilter({
                crmOpportunityId: vm.crmOpportunity.id,
                corporationId: vm.crmOpportunity.corporation.id,
                pin: false,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, function (data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.crmTasks = data;

            }, onError);
        }

        function trustAsHtml(task) {
            return $sce.trustAsHtml(task);
        }
    }
})();

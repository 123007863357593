(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SelectChartDialogController', SelectChartDialogController);

    SelectChartDialogController.$inject = ['$uibModalInstance', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$filter',
        '$compile', '$scope', 'CoreService', 'DataTablesService', 'Chart'];

    function SelectChartDialogController($uibModalInstance, DTOptionsBuilder, DTColumnBuilder, $q, $filter,
                                         $compile, $scope, CoreService, DataTablesService, Chart) {
        var vm = this;

        vm.dtInstance = {};
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.selectChart = selectChart;
        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.setChartId = setChartId;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            Chart.fullFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.charts = result;

                defer.resolve(result);
            });

            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp').withOption("bLengthChange", false).withOption("bPaginate", false).withOption('aaSorting', [[1, 'desc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('').notSortable().renderWith(function (data, type, full, meta) {
                return '<input type="radio" name="chart_' + data.id + '" ng-click="vm.setChartId(' + data.id + ')">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').notSortable().renderWith(function (data, type, full) {
                return data.lastName + ", " + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn('mrNo').withTitle('MrNo').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('D. Birth').renderWith(function (data, type, full) {
                return moment(data.dateBirth).format("MM/DD/YYYY");
            }),
        ];

        function getFilter() {
            var filter = {};
            filter.searchQuery = vm.searchQuery;
            filter.statuses = ['CURRENT', 'ARCHIVE'];
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;
            filter.facilityId = CoreService.getCurrentFacility().id;

            return filter;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function search() {
            vm.page = 1;
            vm.chartId = null;

            transition();
        }

        function transition() {
            vm.dtInstance.reloadData();
        }

        function selectChart() {
            $uibModalInstance.close(vm.chartId);
        }

        function setChartId(id) {
            vm.chartId = id;
        }
    }
})();

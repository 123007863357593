(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('SecurityCheckController', SecurityCheckController);

    SecurityCheckController.$inject = ['CoreService', '$state', 'User', 'toastr', '$cookies'];

    function SecurityCheckController(CoreService, $state, User, toastr, $cookies) {
        var vm = this;

        vm.rememberMe = true;
        vm.toastr = toastr;
        vm.$cookies = $cookies;
        vm.employee = CoreService.getCurrentEmployee();
        vm.phoneNumberMasked = maskPhone(vm.employee.mobile);

        vm.sendCode = sendCode;

        function sendCode() {
            var fromCookie = CoreService.getUUID();

            User.sendSmsCode({browserUuid: fromCookie}, function (data) {
                $state.go('security-code');
            }, function (error) {
                toastr.error('Error sending code, try later');
            });
        }

        function maskPhone (number) {
            return '(***) ***-' + number.slice(-4);
        }
    }
})();
